import tableData from "./state_totals_bypop.json";
import { truncateFloat } from "../../../../util/helper";
import addThousandsSeparator from "util/addThousandsSeparator";

const unavailable = "-";

const hasData = (dataSet) => (typeof dataSet === "number");

const USTerritories = ["American Samoa","Guam","Northern Mariana Islands","Puerto Rico","Virgin Islands"];

export const formatTableData = (data, topLimit = null) => {
  let formattedData = data.filter((region) => !USTerritories.includes(region.state)
    || (hasData(region.data?.raw_full_vac) || hasData(region.data?.doses_admin) || hasData(region.data?.percent_full_vac)));

  // if (topLimit){
  //   formattedData = formattedData.sort((a, b) => b?.data?.percent_full_vac - a?.data?.percent_full_vac).slice(0,topLimit);
  // };

  return formattedData.map((region) => ({
    regionName: region.state || region.country,
    ...region.data,

    raw_full_vac: hasData(region.data?.raw_full_vac)
      ? addThousandsSeparator(region.data?.raw_full_vac) : unavailable,

    doses_admin: hasData(region.data?.doses_admin)
      ? addThousandsSeparator(region.data?.doses_admin) : unavailable,

    per100k_full_val: hasData(region.data?.per100k_full_val)
      ? addThousandsSeparator(truncateFloat(region.data?.per100k_full_val)) : unavailable,

    percent_full_vac: hasData(region.data?.percent_full_vac)
      ? `${addThousandsSeparator(truncateFloat(region.data?.percent_full_vac))}%` : unavailable,

    date: region.data.date? new Date(region.data?.date * 1000)?.toLocaleDateString() : unavailable,
  })
  );
};

const cleanTableData = formatTableData(tableData);

export default cleanTableData;
