import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import styles from "./DataCenter.module.scss";
import DataCenterHome from "./DataCenterHome";
import MapView from "../Map/MapView";
import MapFAQ from "../Map/MapFAQ";
import USMapView from "../Map/USMapView";
import USMapFAQ from "../Map/USMapFAQ";

/* Chart Components: */
import AnimatedWorldMap from "./charts/AnimatedWorldMap";
import CumulativeCases from "./charts/CumulativeCases";
import NewCases from "./charts/NewCases";
import HubeiCases from "./charts/HubeiCases";
import MortalityRates from "./charts/MortalityRates";
import USStateDataAvailability from "./charts/USStateDataAvailability";
import NewCases50States from "./charts/NewCases50States";
import VariantData from "./charts/VariantData";
import Hospitalization7DayTrend from "./charts/Hospitalization7DayTrend";
import StateTimeline from "./charts/StateTimeline";
import { BASE_STATE_TIMELINE_ROUTE } from "./charts/StateTimeline/constants";
import DemographicUpdate from "./DemographicUpdate";
import StateReporting from "./StateReporting";
import DetailedDemographic from "./DetailedDemographic";
import TrackerNewCasesMap from "../Testing/TrackerNewCasesMap";
import TrackerWeeklyChangeMap from "../Testing/TrackerWeeklyChangeMap";
// import DraftGuard from "components/common/DraftGuard";

const DataCenter = ({ location }) => {
  return (
    <div className={styles.base}>
      <Switch>
        <Redirect
          from="/data/us-state-data-availability"
          to="/data/racial-data-transparency"
        />
        <Route path="/data/demographic" component={DemographicUpdate} />,
        <Route
          path="/data/disparity-explorer"
          component={DetailedDemographic}
        />
        <Route
          path="/data/state-reporting-frequencies"
          component={StateReporting}
        />
        <Route path="/data/variant-data" component={VariantData} />
        <Route path="/data/new-cases" component={NewCases} />
        <Route path="/data/mortality" component={MortalityRates} />
        <Route path="/data/cumulative-cases" component={CumulativeCases} />
        <Route path="/data/animated-world-map" component={AnimatedWorldMap} />
        <Route
          path="/data/racial-data-transparency"
          component={USStateDataAvailability}
        />
        <Route path="/data/hubei-timeline" component={HubeiCases} />
        <Route
          path="/data/new-cases-50-states/:stateId?"
          component={NewCases50States}
        />
        <Route
          path="/data/hospitalization-7-day-trend/inpatient-capacity"
          component={Hospitalization7DayTrend}
        />
        <Route
          path="/data/hospitalization-7-day-trend/:stateId?"
          component={Hospitalization7DayTrend}
        />
        
        <Route
          path="/data/tracker/new-confirmed-per-100k-map"
          component={TrackerNewCasesMap}
        />
        <Route
          path="/data/tracker/weekly-change-in-new-cases-map"
          component={TrackerWeeklyChangeMap}
        />

        <Route path={BASE_STATE_TIMELINE_ROUTE} component={StateTimeline} />
        <Route path="/data" exact component={DataCenterHome} />
        <Route path="/map.html" component={MapView} />
        <Route path="/map-faq" component={MapFAQ} />
        <Route path="/us-map" component={USMapView} />
        <Route path="/us-map-faq" component={USMapFAQ} />
        <Redirect to="/404" />
      </Switch>
    </div>
  );
};

export default DataCenter;
