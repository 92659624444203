import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    line: {
        strokeWidth: '3px',
        fill: 'none',
        pointerEvents: "none"
    }
}));

const Line = ({d, stroke}) => {
    const classes = useStyles();

    return (
        <g className="line__group">
            <path className={classes.line} d={d} style={{stroke}}/>
        </g>
    );
};
export default Line;
