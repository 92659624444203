import React from "react";
import cx from "classnames";

import styles from "./TourPoint.module.scss";

function TourPoint({ active, onClick, onClickParam }) {
  const handleClick = (e) => {
    e.stopPropagation();
    !!onClick && onClick(onClickParam);
  }
  
  return (
    <div
      className={cx(styles.base, {
        [styles.active]: active,
        [styles.clickable]: !!onClick,
      })}
      onClick={handleClick}
    >
      <div className={styles.pointOuter} />
      <div className={styles.pointInner} />
    </div>
  );
}

export default TourPoint;
