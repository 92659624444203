import React from "react";
import styles from "./USStatesTable.module.scss";
import TFormat from "components/common/TFormat";
import stateList from "data/data-viz/vaccine-us-states/state-list.json";
import stateNoPageList from "./no-page-list.json";
import { formatTableData } from "data/data-viz/vaccine-us-states/state-totals-bypop";
import cx from "classnames";
import { useFetch } from "util/hooks/useFetch";
import { TFormatCommonProps } from "components/common/TFormat/util";

const USStatesTable = ({
  useTable = "default",
  regionNameLinkStyle,
  limit = Infinity,
  hideViewAll = false,
  customSortConfig,
  mobileOptions = { useRegionCodeType: null, useNameShortening: true },
  className = "",
  overflowClassName,
}) => {
  const [loadedStateData, isLoading] = useFetch("state_vaccination_rates");

  if (isLoading || !loadedStateData) {
    return <div />;
  }

  const data = formatTableData(loadedStateData);
  const dataSet = {
    default: {
      title: "Totals By Population",
      tableData: {
        data: data,
        tableColumns: {
          regionName: "State Name",
          doses_admin: "Doses\nAdministered",
          raw_full_vac: "People\nFully Vaccinated",
          percent_full_vac: "% of People\nFully Vaccinated",
        },
      },
    },
    blade: {
      title: "",
      tableData: {
        data: data,
        tableColumns: {
          regionName: "State",
          doses_admin: "Total Doses\nAdministered",
          percent_full_vac: "% of People\nFully Vaccinated",
        },
      },
    },
  };

  const useTableData = dataSet[useTable]?.tableData;
  return (
    <div className={cx(styles.base, className)}>
      <TFormat
        tableData={useTableData?.data}
        tableColumns={useTableData?.tableColumns}
        regionList={stateList}
        regionNoLinkList={stateNoPageList}
        mobileOptions={mobileOptions}
        customSortConfig={customSortConfig}
        path="/region/us/"
        pageSection="#data-timeline"
        rowLimit={limit}
        regionNameLinkStyle={regionNameLinkStyle}
        hideViewAll={hideViewAll}
        overflowClassName={overflowClassName}
        hideButtonProps={{ className: styles.hideButton }}
      />
    </div>
  );
};

USStatesTable.propTypes = {
  ...TFormatCommonProps,
};

export default USStatesTable;
