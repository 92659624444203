import Page from "components/layout/Page";
import React from "react";

import styles from "./WeeklyNewsletterSignUp.module.scss";

const WeeklyNewsletterSignUp = () => {
  return (
    <Page>
      <div className={styles.container}>
        <iframe
          width="100%"
          height="500"
          title="sendgrid-embed"
          className={styles.iframe}
          frameBorder="no"
          src="https://cdn.forms-content.sg-form.com/81a64886-873d-11eb-92c7-92b84209fcc0"
        />
      </div>
    </Page>
  );
};

export default WeeklyNewsletterSignUp;
