import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import useDimensions from "util/hooks/useDimensions";
import styles from "./RaceToVaccine.module.scss";
import { countryData } from "data/geo";
import Graph from "../Graph";
import Button from "components/common/Button";
import LayoutPlaceholder from "components/common/LayoutPlaceholder";
import CustomSelect from "components/common/CustomSelect";
import { percentFullVacLabels, rawFullVacLabels } from "../constants";

const RaceToVaccine = ({ dataset, baseData, mobile, fields, key, loading }) => {
  const [useOne, setUseOne] = useState(window.innerWidth > 1279);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const [width1] = useDimensions(ref1);
  const [width2] = useDimensions(ref2);

  const [filteredData, setFilteredData] = useState(null);
  const [, setSelectedRegion] = useState("");
  const allRegions = [...new Set(countryData.map((d) => d.region))].sort();
  const regionOptions = allRegions.slice(1).map((d) => {
    return { label: d, value: d };
  });

  useEffect(() => {
    let rafId;
    const handleResize = () => {
      window.cancelAnimationFrame(rafId);
      rafId = window.requestAnimationFrame(() => {
        if (window.innerWidth > 1279) {
          setUseOne(true);
          setFilteredData(null);
        } else {
          setIsOpen2(isOpen1);
          setUseOne(false);
          if (window.innerWidth > 768) {
            setFilteredData(null);
          }
        }
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.cancelAnimationFrame(rafId);
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen1]);
  const handleToggleClick = (event) => {
    event.preventDefault();
    setIsOpen1(!isOpen1);
    setIsOpen2(!isOpen1);
  };
  const handleToggle1Click = (event) => {
    event.preventDefault();
    setIsOpen1(!isOpen1);
  };
  const handleToggle2Click = (event) => {
    event.preventDefault();
    setIsOpen2(!isOpen2);
  };

  const handleRegionSelect = (selected) => {
    if (selected?.value === "all-content") {
      setFilteredData(null);
      setSelectedRegion("");
    } else {
      let regionCountries = countryData
        .filter((d) => d.region === selected.value)
        .map((d) => d.country);
      let filtered = baseData.filter(
        (d) => regionCountries.includes(d.country) || d.country === "World"
      );
      setSelectedRegion(selected.value);
      setFilteredData(filtered);
    }
  };

  return (
    <div className={styles.base}>
      <div key={key} className={styles.main}>
        {dataset.name === "International" && mobile && (
          <div className={styles.selectContainer}>
            <CustomSelect
              label="Select a Region to filter"
              allOptionLabel="All Regions"
              noAllOption={false}
              multi={false}
              options={regionOptions}
              onSelect={handleRegionSelect}
            />
          </div>
        )}
        <div
          className={cx(
            styles.graphContainer,
            dataset.name === "US States" ? styles.us : styles.world,
            isOpen1 && styles.open
          )}
          ref={ref1}
        >
          <h2>
            {dataset.name === "International"
              ? percentFullVacLabels.country
              : percentFullVacLabels.state}
          </h2>
          {loading ? (
            <LayoutPlaceholder className={styles.placeholder} />
          ) : (
            <Graph
              dataSet={dataset}
              field={fields[0]}
              data={filteredData ? filteredData : baseData}
              mobile={mobile}
              svgWidth={width1}
            />
          )}
          {!useOne && (
            <Button
              className={styles.button}
              buttonStyle="bordered"
              icon={isOpen1 ? "up" : "down"}
              onClick={handleToggle1Click}
            >
              {isOpen1 ? "View Less" : "View All"}
            </Button>
          )}
        </div>
        <div
          className={cx(
            styles.graphContainer,
            dataset.name === "US States" ? styles.us : styles.world,
            isOpen2 && styles.open
          )}
          ref={ref2}
        >
          <h2>
            {dataset.name === "International"
              ? rawFullVacLabels.country
              : rawFullVacLabels.state}
          </h2>

          {loading ? (
            <LayoutPlaceholder className={styles.placeholder} />
          ) : (
            <Graph
              dataSet={dataset}
              field={fields[2]}
              data={filteredData ? filteredData : baseData}
              mobile={mobile}
              svgWidth={width2}
            />
          )}
          {!useOne && (
            <Button
              className={styles.button}
              buttonStyle="bordered"
              icon={isOpen2 ? "up" : "down"}
              onClick={handleToggle2Click}
            >
              {isOpen2 ? "View Less" : "View All"}
            </Button>
          )}
        </div>
      </div>
      {useOne && (
        <Button
          className={styles.button}
          buttonStyle="bordered"
          icon={isOpen1 ? "up" : "down"}
          onClick={handleToggleClick}
        >
          {isOpen1 ? "View Less" : "View All"}
        </Button>
      )}
    </div>
  );
};

export default RaceToVaccine;
