import React from "react";
import cx from "classnames";

import Icon from "../../../components/Icon";
import styles from "./AcceleratedTimelineMap.module.scss";
import phases from "../../../data/phases";
import badge from "./time-badge.svg";

const AcceleratedTimelineMap = ({ currentPhase }) => {
  return (
    <div className={styles.base}>
      <div className={styles.heading}>
        <Icon icon="fast" light />
        <span>Accelerated Timeline</span>
      </div>
      <div className={styles.timeline}>
        {phases.map((phase) => (
          <div
            key={phase.id}
            id={styles[phase.id]}
            className={cx(styles.phase, {
              [styles.active]: phase.id === currentPhase,
            })}
          >
            <Icon icon={phase.icon} light={phase.id !== currentPhase} />
          </div>
        ))}
      </div>
      <div className={styles.timeSpan}>
        <img src={badge} alt="timespan"/>
        <span>1-2 Years In Total</span>
      </div>
    </div>
  );
};

export default AcceleratedTimelineMap;
