import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import SquareMap from "./SquareMap";
import MercatorMap from "./MercatorMap";

function USStateMap({ width, height, dataSrc, className, transformData, mode = "mercator" }) {
  const [colorData, setColorData] = useState(null);

  useEffect(() => {
    let mounted = true;

    const colorFetcher = fetch(dataSrc).then((res) => res.json());

    colorFetcher.then((colorData) => {
      if (!mounted || !colorData) return;

      if (transformData) {
        colorData = transformData(colorData);
      }

      setColorData(colorData?.map);
    });

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSrc]);

  return (
    <>
      {mode === "mercator" &&
        <MercatorMap
          colorData={colorData}
          width={width}
          height={height}
          className={className}
        />
      }
      {mode === "square" &&
        <SquareMap
          colorData={colorData}
          width={width}
          height={height}
          className={className}
          mode="square"
        />
      }
    </>
  );
}

USStateMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  dataSrc: PropTypes.string.isRequired,
  className: PropTypes.string,
  transformData: PropTypes.func,
  mode: PropTypes.oneOf([
    "mercator",
    "square",
  ]),
};

export default USStateMap;
