export type TFrequency = {
  frequency: "daily" | "weekdays" | "limited";
};

export const ReportFrequency = {
  daily: "Daily",
  weekdays: "Monday-Friday",
  weekdaysMobile: "Mon-Fri",
};

export const labelText = "Reporting data: ";

export const daysLabelsMobile = [
  "Mon",
  "Tue",
  "Wed",
  "Thur",
  "Fri",
  "Sat",
  "Sun",
];
export const daysLabels = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const isYes = (day: string) => day?.toLowerCase() === "y";

export const getFrequency = (datapoint, isMobile = false) => {
  let firstFilled = null;
  const labels = isMobile ? daysLabelsMobile : daysLabels;

  const result = daysLabelsMobile
    .map((day, i) => {
      const prevDay = i === 0 ? null : daysLabelsMobile[i - 1];
      const nextDay = i === 6 ? null : daysLabelsMobile[i + 1];
      if (isYes(datapoint[day?.toLowerCase()])) {
        if (firstFilled === null) {
          firstFilled = i;
        }
        if (!isYes(datapoint[prevDay?.toLowerCase()])) {
          if (isYes(datapoint[nextDay?.toLowerCase()])) {
            return firstFilled === i ? `${labels[i]}-` : `, ${labels[i]}-`;
          } else {
            return firstFilled === i ? `${labels[i]}` : `, ${labels[i]}`;
          }
        } else {
          if (!isYes(datapoint[nextDay?.toLowerCase()])) {
            return labels[i];
          }
          return "";
        }
      } else {
        return null;
      }
    })
    .filter((r) => !!r)
    .join("");
  return result;
};

export const frequencyText = (datapoint, isMobile = false) => {
  const { days, sat, sun } = datapoint;
  if (days === 7) {
    return ReportFrequency["daily"];
  } else if (days === "0") {
    return "Does not publish";
  } else {
    if (days === 5 && !isYes(sat) && !isYes(sun)) {
      return isMobile
        ? ReportFrequency["weekdaysMobile"]
        : ReportFrequency["weekdays"];
    } else {
      if (days === 1) {
        return daysLabelsMobile.map((day, i) => {
          return datapoint[day.toLowerCase()] === "Y" ? daysLabels[i] : null;
        });
      } else {
        return getFrequency(datapoint, isMobile);
      }
    }
  }
};
