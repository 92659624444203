import table from "./events";
const tableColumns = Object.keys(table);
const tableIds = Object.keys(table[tableColumns[0]]);

export default tableIds.map((id) => {
  const obj = {};
  for (let i = 0; i < tableColumns.length; i++) {
    obj[tableColumns[i]] = table[tableColumns[i]][id];
  }
  return obj;
});
