import { HealthPolicyForum } from "./HealthPolicyForum";
import { Covid19Symposium_11_20 } from "./Covid19Symposium_11_20";
import { Covid19Briefing_11_20 } from "./Covid19Briefing_11_20";
import { CommunityForum_1_27_21 } from "./CommunityForum_1_27_21";
import { FromDiscovery_1_25_21 } from "./FromDiscovery_1_25_21";
import { HealthPolicyForum_2021 } from "./HealthPolicyForum_2021";

const eventMods = [
  HealthPolicyForum,
  HealthPolicyForum_2021,
  Covid19Symposium_11_20,
  Covid19Briefing_11_20,
  CommunityForum_1_27_21,
  FromDiscovery_1_25_21,
];
export default eventMods;

// Fetch a custom EventMod component by eventId and populate with event fields
export const getEventMod = (eventId, event) => {
  if (!eventId || !event) return null;
  const eventMOD = eventMods.find((mod) => mod({}).slug === eventId);
  return eventMOD ? eventMOD({ ...event, contentSrc: event.content }) : null;
};
