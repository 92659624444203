import React, { useEffect } from "react";
import cx from "classnames";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import "stylesheets/main.scss";
import styles from "./Application.module.scss";
import { fetchSettings } from "redux/settings";
import DraftGuard from "components/common/DraftGuard";

/* Shell */
import Header from "components/layout/shell/Header";
import Footer from "components/layout/shell/Footer";
import NavigationScroller from "components/layout/shell/NavigationScroller";
import Meta from "components/common/Meta";
import StyleGuide from "components/misc/StyleGuide";

/* Content */
import Home from "components/content/Home";
import DataCenter from "components/content/DataCenter";
import Testing from "components/content/Testing";
import Tracing from "components/content/Tracing";
import WebCastView from "components/content/Webcast/WebcastView";
import Learn from "components/content/Learn";
import News from "components/content/News";
import NotFound from "components/layout/shell/NotFound";
import Article from "components/layout/Article";
import FromOurExperts from "components/content/FromOurExperts";
import HeaderBuff from "components/layout/shell/Header/HeaderBuff";
import About from "components/content/About";
import ByRegion from "components/content/ByRegion";

import DataInMotion from "components/content/DataInMotion";
import RegionNotes from "components/content/ByRegion/RegionNotes";
import Webcast from "components/content/Webcast";
import Vaccine from "components/content/Vaccine";
import WeeklyNewsletterSignUp from "components/content/WeeklyNewsletterSignUp";
import VaccineTimeline from "components/content/Vaccine/VaccineTimeline";
import VaccineStory from "components/content/Vaccine/VaccineStory";
import InternalPositivity from "components/content/Testing/InternalPositivity";
import DataSources from "components/content/DataSources";
import AboutTestingData from "components/content/AboutTestingData";
import VaccineArticle from "components/content/Vaccine/VaccineArticle";
import PandemicDataInitiative from "components/content/PandemicDataInitiative";
import ResearchPapers from "components/content/ResearchPapers";
import LiveBriefingSignUp from "components/content/Webcast/LiveBriefingSignUp";
import SunsetBanner from "components/sunset/SunsetBanner/SunsetBanner";

const Application = () => {
  // Load the settings data, just once
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  return (
    <div className={cx(styles.base)}>
      <Meta />
      <NavigationScroller />
      <Header />
      <HeaderBuff />

      <SunsetBanner
        intro={"JHU has stopped collecting data as of"}
        date={"03 / 10 / 2023"}
        details={
          "After three years of around-the-clock tracking of COVID-19 data from around the world, Johns Hopkins has discontinued the Coronavirus Resource Center’s operations. The site’s two raw data repositories will remain accessible for information collected from 1/22/20 to 3/10/23 on cases, deaths, vaccines, testing and demographics."
        }
        closeButton={false}
      />

      <div className={styles.pageContent}>
        <Switch>
          {/* Redirect Legacy Urls */}
          <Redirect from="/live.html" to="/live" />
          <Redirect from="/map-faq.html" to="/map-faq" />
          <Redirect exact from="/map" to="/map.html" />
          <Redirect
            from="/embracing-distancing-and-cushioning-the-blow-to-the-economy.html"
            to="/from-our-experts/embracing-distancing-and-cushioning-the-blow-to-the-economy"
          />
          <Redirect
            from="/the-unequal-cost-of-social-distancing.html"
            to="/from-our-experts/the-unequal-cost-of-social-distancing"
          />
          <Redirect exact from="/live/events" to="/live" />
          {/* Site Routes */}
          {/* Articles */}
          <Route path="/tracking/q-n-a/:articleId" component={VaccineArticle} />
          <Route path="/from-our-experts" component={FromOurExperts} />
          <Route
            path="/covid-19-basics/understanding-covid-19"
            component={Learn}
          />
          <Route path="/covid-19-basics/:articleId" component={Article} />
          {/* Custom Pages */}
          <Route path="/webcasts/:webcastId" component={WebCastView} />
          <Route exact path="/covid-19-daily-video" component={DataInMotion} />
          {/* Events */}
          <Route path="/live-briefing-sign-up" component={LiveBriefingSignUp} />
          <Route path="/live/:videoId?" component={Webcast} />
          {/* Research Papers */}
          <Route
            key="research-papers"
            path={`/research-papers`}
            component={ResearchPapers}
          />
          <Route path="/about/how-to-use-our-data" component={DataSources} />,
          <Route
            path="/testing/jhu-covid-19-testing-data"
            component={AboutTestingData}
          />
          ,
          {DraftGuard({
            children: [
              <Route
                key="jhu-covid-19-testing-data-review"
                path="/testing/jhu-covid-19-testing-data-review"
                component={AboutTestingData}
              />,
              <Route
                key="internal-positivity-review"
                path="/internal/positivity-review"
                component={InternalPositivity}
              />,
            ],
          })}
          {/* Verticals */}
          <Route path="/testing" component={Testing} />
          <Route path="/contact-tracing" component={Tracing} />
          <Route
            key="vaccine-story"
            path="/vaccines/story"
            component={VaccineStory}
          />
          <Route key="/vaccines" path="/vaccines" component={Vaccine} />
          children: [
          <Route
            key="vaccine-timeline"
            path="/vaccines/timeline"
            component={VaccineTimeline}
          />
          ,
          <Route
            key="/pandemic-data-initiative"
            path="/pandemic-data-initiative"
            component={PandemicDataInitiative}
          />
          ],
          {/* Data Center */}
          <Route path="/data" component={DataCenter} />
          <Route path="/map.html" exact component={DataCenter} />
          <Route path="/map-faq" component={DataCenter} />
          <Route path="/us-map-faq" component={DataCenter} />
          <Route path="/us-map" component={DataCenter} />
          <Route
            path="/the-week-in-covid-19-sign-up"
            component={WeeklyNewsletterSignUp}
          />
          {/* Region Pages */}
          <Route key="by-region" path="/region" component={ByRegion} />
          <Route
            key="/region-data-notes"
            path="/region-data-notes/:regionName?"
            component={RegionNotes}
          />
          {/* Standard Pages */}
          <Route path="/news" component={News} />
          <Route path="/about" component={About} />
          <Route path="/404" component={NotFound} />
          <Route path="/" exact component={Home} />
          {/* Internal pages */}
          {DraftGuard({
            children: [
              // Add all internal pages to this array
              <Route
                key="style-guide"
                path="/style-guide"
                component={StyleGuide}
              />,
            ],
          })}
          <Route component={NotFound} />
        </Switch>
      </div>
      <Footer />
    </div>
  );
};

export default Application;
