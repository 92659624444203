import React, { useState } from 'react';

// Components
import Button from 'components/common/Button';
import Paginator from 'components/common/Paginator';
import SectionHeading from 'components/layout/shell/SectionHeading';

// Page styles
import classNames from 'classnames/bind';
import styles from './KitchenSink.module.scss';
const cx = classNames.bind(styles);

export default function KitchenSink() {
  const [paginatorValue, setPaginatorValue] = useState(1);

  return (
    <div className={cx('container')}>
      <h1 className={cx('styleguide-annotation')}>Kitchen Sink</h1>
      <p className={cx('styleguide-annotation')}>
        Welcome to JHU Design System. This page gives an overview of all
        important styles and elements that defines UI Design System. Everything
        is linked, thus, changes will propagate throughout the file.
      </p>

      {/* Colors */}
      <h2 className={cx('styleguide-annotation')}>Colors</h2>
      <p className={cx('styleguide-annotation')}>
        The color scheme aims to be simple without too many variations. The
        color scheme aims to be simple without too many variations. Displayed in
        HEX and a production variable (VAR).
      </p>

      <div className={cx('color-palette')}>
        {[
          'color-dark-blue',
          'color-blue',
          'color-light-blue',
          'color-yellow',
          'color-black',
          'color-dark-gray',
          'color-medium-gray',
          'color-light-gray',
        ].map((colorName) => (
          <div
            key={colorName}
            className={cx('color', 'styleguide-annotation', colorName)}
          >
            <div className={cx('color-tile')}></div>
            <div className={cx('color-detail-row', 'value')}></div>
            <div className={cx('color-detail-row', 'name')}></div>
          </div>
        ))}
      </div>

      {/* Typography */}
      <h2 className={cx('styleguide-annotation')}>Typography</h2>
      <p className={cx('styleguide-annotation')}>
        We use Gentona as primary typeface. The hierarchy is divided in three
        parts: headings, bold and regular body styles. There's also a paragraph
        style with larger font size and line-height.
      </p>
      <div style={{ maxWidth: '550px' }}>
        <h3 className={cx('styleguide-annotation')}>h1</h3>
        <h1>Tracking</h1>

        <h3 className={cx('styleguide-annotation')}>h2</h3>
        <h2>Overview</h2>

        <h3 className={cx('styleguide-annotation')}>h3</h3>
        <h3>Rhode Island has conducted more Testing than any other state</h3>

        <h3 className={cx('styleguide-annotation')}>Major SectionHeading</h3>
        <SectionHeading
          isMajor
          title="Testing"
          description="Providing public access to COVID-19 testing data, resources, and expert analysis"
        />

        <h3 className={cx('styleguide-annotation')}>Minor SectionHeading</h3>
        <SectionHeading
          isMajor={false}
          title="Testing"
          description="Johns Hopkins experts are working to fill the void of publicly accessible COVID-19 testing data. This initiative reflects an interdisciplinary collaboration between several groups at Johns Hopkins university."
        />

        <h3 className={cx('styleguide-annotation')}>Paragraph</h3>
        <p>
          The COVID-19 crisis has created an unprecedented need for contact
          tracing across the country, requiring thousands of people to learn key
          skills quickly. The job qualifications for contact tracing positions
          differ throughout the country and the world, with some new positions
          open to individuals with a high school diploma or equivalent.{' '}
        </p>
      </div>

      {/* Buttons */}
      <h2 className={cx('styleguide-annotation')}>
        Buttons / Dropdowns / Badges / Links
      </h2>
      <p className={cx('styleguide-annotation')}>
        Buttons are defined{' '}
        <nobr>
          by <strong>icon type</strong> (<code>right</code>, <code>down</code>,{' '}
          <code>download</code>, <code>play</code>, <code>x</code>),
        </nobr>{' '}
        <nobr>
          by <strong>style</strong> (<code>filled</code>, <code>bordered</code>,{' '}
          <code>plain</code>, <code>underlined</code>),
        </nobr>{' '}
        <nobr>
          and by <strong>shape</strong> (<code>square</code>,{' '}
          <code>rounded</code>).
        </nobr>
      </p>

      <h3 className={cx('styleguide-annotation')}>Default</h3>
      <Button href="/style-guide/kitchen-sink" activeClassName="foo">I’m a button</Button>

      {[
        { buttonStyle: 'filled', shape: 'square' },
        { buttonStyle: 'bordered', shape: 'square' },
        { buttonStyle: 'plain', shape: 'square' },
        { buttonStyle: 'underlined', shape: 'square' },
        { buttonStyle: 'filled', shape: 'rounded' },
        { buttonStyle: 'bordered', shape: 'rounded' },
      ].map((buttonProps) => {
        const { buttonStyle, shape } = buttonProps;
        const Btn = (props) => <Button {...buttonProps} {...props} />;
        const sep = '\u00a0'.repeat(buttonStyle === 'plain' ? 6 : 4);
        return (
          <div className={cx('button-row')} key={`${buttonStyle}-${shape}`}>
            <h3 className={cx('styleguide-annotation')}>
              Style: <code>{buttonStyle}</code>; shape: <code>{shape}</code>
            </h3>
            <div className={cx('buttons')}>
              <Btn>I’m a button</Btn>
              {sep}
              <Btn icon="right">Select a country</Btn>
              {sep}
              <Btn icon="down">Massachusetts</Btn>
              {sep}
              <Btn icon="download">Download PDF</Btn>
              {sep}
              {/* Don't render icon-only buttons for “plain” style */}
              {buttonStyle !== 'plain' && buttonStyle !== 'underlined' && (
                <React.Fragment>
                  <Btn icon="down" />
                  {sep}
                  <Btn icon="x"></Btn>
                  {sep}
                  <Btn icon="play"></Btn>
                </React.Fragment>
              )}
            </div>
          </div>
        );
      })}

      {/* Pagination */}
      <h2 className={cx('styleguide-annotation')}>Pagination</h2>
      <p className={cx('styleguide-annotation')}>
        Use <code>Paginator</code> for content that is listed across multiple
        pages. The standard control scheme is as a controlled component, using
        the <code>value</code> and <code>onChange</code> props.
      </p>

      <h3 className={cx('styleguide-annotation')}>Default</h3>
      <Paginator value={paginatorValue} max={10} onChange={setPaginatorValue} />

      <h3 className={cx('styleguide-annotation')}>
        With <code>hideNumbers</code> prop
      </h3>
      <Paginator
        value={paginatorValue}
        max={10}
        onChange={setPaginatorValue}
        hideNumbers
      />

      <h3 className={cx('styleguide-annotation')}>URL control</h3>
      <p className={cx('styleguide-annotation')}>
        You can also control a <code>Paginator</code> with the{' '}
        <code>getPathForPage</code> function, which should take a number
        argument and return a string representing what the URL path should look
        like for the page with that number. For example:
      </p>

      <p
        className={cx('styleguide-annotation')}
        style={{ margin: '1rem 0 1.5rem' }}
      >
        <code style={{ width: '100%', padding: '.3em .6em' }}>
          function getPathForPage(pageNumber) {'{'}
          <br />
          &nbsp;&nbsp;return window.location.pathname + '?page=' + pageNumber;
          <br />
          {'}'}
        </code>
      </p>

      <p
        className={cx('styleguide-annotation')}
        style={{ marginBottom: '1.5rem' }}
      >
        Passing this function to a <code>Paginator</code> creates a paginator
        that is controlled by a query parameter:
      </p>

      <Paginator
        max={10}
        getPathForPage={(pageNumber) => {
          return `${window.location.pathname}?page=${pageNumber}`;
        }}
      />
    </div>
  );
}
