import React from "react";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import { mapContentsToCards } from "components/layout/ArticleCard/utils";
import getPlaceholderImage from "util/placeholder-image";
import classNames from "classnames/bind";
import styles from "./GreatestHits.module.scss";

import greatestHits from "./greatestHits.json";

const cx = classNames.bind(styles);

type TGreatestHitsProps = {
  title?: string;
  sliceStart?: number;
  contents?: any;
  page?: number;
  pageSize?: number;
  excludeList?: string[];
  className?: string;
};

const GreatestHits = ({
  title = "CRC in the Media",
  page = 1,
  pageSize = 4,
  sliceStart = 0,
  excludeList = [],
}: TGreatestHitsProps) => {
  const results = greatestHits;
  const contentCards = mapContentsToCards(
    results.map((content) => ({
      ...content,
      image: content.image || getPlaceholderImage(content.id),
      date: "",
    }))
  );

  const visibleContents = contentCards;

  return (
    <div className={cx("base")}>
      {title && <h2 className={styles.title}>{title}</h2>}
      <div className={cx("content")}>
        {results.length ? (
          <ArticleGridLayouts.Custom
            articles={visibleContents}
            columnLayout={[[3, 3, 3, 3], [6, 6], [12]]}
            limit={null}
            dividers={{ row: false }}
          />
        ) : (
          <p className={styles.noResults}>There is nothing to display.</p>
        )}
      </div>
    </div>
  );
};

export default GreatestHits;
