import React, { useRef, useEffect, useState } from "react";
import styles from "./SelectedStateArea.module.scss";
import { select, mouse } from "d3-selection";
import { scaleOrdinal, scaleLinear, scaleTime } from "d3-scale";
import { legendColor } from "d3-svg-legend";
import moment from "moment";
import { axisLeft, axisBottom } from "d3-axis";
import { timeFormat } from "d3-time-format";
import { area, curveBasis } from "d3-shape";
import { format } from "d3-format";

const SelectedStateArea = ({ data, timeExtent, svgWidth }) => {
  const [tip1content, setTip1content] = useState(null);
  // const [tip2content, setTip2content] = useState(null);
  const [tip1X, setTip1X] = useState(0);
  const [tip1Y, setTip1Y] = useState(0);
  // const [tip2X, setTip2X] = useState(0);
  // const [tip2Y, setTip2Y] = useState(0);
  const [showTip1, setShowTip1] = useState(false);
  // const [showTip2, setShowTip2] = useState(false);
  const svgRef = useRef(null);
  const groupRef = useRef(null);
  const tipHeight = 20;
  const tipWidth = 200;
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [height, setHeight] = useState(450);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHeight(mobile ? 300 : 450);
    setShowTip1(false);
    // setShowTip2(false)
  }, [data, mobile]);

  useEffect(() => {
    const labelTimeFormat = timeFormat("%Y-%m-%d");
    const xvar = "date";

    // const y1stack = "percent_partial_vacc_only";
    const y2stack = "percent_full_vacc_only";

    const margin = { top: 20, right: 20, bottom: 40, left: mobile ? 50 : 80 };
    const width = svgWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;

    const svg = select(groupRef.current);
    // svg.selectAll("g").remove();
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const color = scaleOrdinal()
      .domain(["Fully Vaccinated People"])
      .range(["#00808088"]);
    const legendOrdinal = legendColor().scale(color);
    const legendG = group
      .append("g")
      .attr("transform", "translate(15,5)")
      .call(legendOrdinal);

    legendG.selectAll(".label").attr("fill", "#555");

    const timeScale = scaleTime()
      .domain([moment(timeExtent[0]), moment(timeExtent[1])])
      .range([0, width]);

    const timeAxis = (g) =>
      g
        .attr("transform", `translate(0,${innerHeight})`)
        .call(axisBottom(timeScale).ticks(mobile ? 3 : 8, timeFormat("%b %Y")));

    const y = scaleLinear().domain([0, 1]).range([innerHeight, 0]).nice();

    const yAxis = (g) =>
      g
        .call(
          axisLeft(y)
            .ticks(mobile ? 5 : 10, format(".0%"))
            .tickSizeInner(-width)
            .tickSizeOuter(0)
        )
        .call((g) =>
          g
            .append("text")
            .attr(
              "transform",
              `translate(-${margin.left - 20},${innerHeight / 2}) rotate(270)`
            )
            .attr("text-anchor", "middle")
            .attr("fill", "#aaa")
            .style("font-size", mobile ? "110%" : "140%")
            .text("Percent of Eligible Population Receiving Doses")
        );

    const yG = group.append("g").call(yAxis);
    yG.selectAll(".tick line").attr("stroke", "#eee");
    group.append("g").call(timeAxis);
    group.selectAll(".domain").raise().attr("stroke", "#aaa");

    // const series = stack().keys([y1stack, y2stack])(data.data);

    const areaGen = area()
      .x((d) => timeScale(moment(d[xvar])))
      .y0((d) => (isNaN(y(0)) ? 0 : y(0)))
      .y1((d) => (isNaN(y(d[y2stack])) ? innerHeight : y(d[y2stack])))
      .curve(curveBasis);

    const areaGroup = group.append("g").attr("transform", "translate(0,0)");
    const areas = areaGroup
      .append("path")
      .attr("d", areaGen(data.data))
      .attr("fill", "#00808088");

    const hoverG = group.append("g").attr("pointer-events", "none");

    const circle1 = hoverG
      .append("circle")
      .attr("display", "none")
      .attr("r", 4);

    // const circle2 = hoverG
    //   .append("circle")
    //   .attr("display", "none")
    //   .attr("r", 4);

    const connector1 = hoverG
      .append("line")
      .attr("display", "none")
      .attr("stroke", "#555")
      .attr("stroke-width", 0.5);

    // const connector2 = hoverG
    //   .append("line")
    //   .attr("display", "none")
    //   .attr("stroke", "#555")
    //   .attr("stroke-width", 0.5);

    if (mobile) {
      let end = data.data.find((d) => d.date === timeExtent[1]);
      setTip(end);
    } else {
      areas.on("mousemove", mousemoved).on("mouseleave", left);
    }

    function mousemoved() {
      let m = mouse(this),
        p = closestPoint(this, m),
        dp = data.data.find(
          (d) => d.date === labelTimeFormat(timeScale.invert(p[0]))
        );
      setTip(dp);
    }

    function left() {
      circle1.attr("display", "none");
      // circle2.attr("display", "none");
      connector1.attr("display", "none");
      // connector2.attr("display", "none");
      setShowTip1(false);
      // setShowTip2(false);
    }

    function setTip(info) {
      if (
        info.percent_full_or_partial_vacc !== "Not Available" &&
        isFinite(info[y2stack])
      ) {
        circle1
          .attr("cx", timeScale(moment(info.date)))
          .attr("cy", y(info[y2stack]))
          .attr("display", null);
        connector1
          .attr("display", null)
          .attr("x1", timeScale(moment(info.date)) - (mobile ? 40 : 80))
          .attr("y1", y(info[y2stack]))
          .attr("x2", timeScale(moment(info.date)))
          .attr("y2", y(info[y2stack]));
        let content1 = <p>{format(".1%")(info[y2stack])} Fully Vaccinated</p>;
        setTip1content(content1);
        setTip1Y(y(info[y2stack]) + tipHeight / 2);
        setTip1X(timeScale(moment(info.date)) - tipWidth + (mobile ? 40 : 0));
        setShowTip1(true);
      } else {
        setShowTip1(false);
        circle1.attr("display", "none");
        connector1.attr("display", "none");
      }

      // if (info[y1stack] !== "Not Available"){
      //   circle2.attr("cx", timeScale(moment(info.date)))
      //     .attr("cy",y(info[y1stack]))
      //     .attr("display",null);
      //   connector2.attr("display", null)
      //     .attr("x1",timeScale(moment(info.date))-(mobile ? 90 : 130))
      //     .attr("y1",y(info[y1stack]))
      //     .attr("x2",timeScale(moment(info.date)))
      //     .attr("y2",y(info[y1stack]))
      //   let content2 = (
      //       <p>
      //         {format(".1%")(info[y1stack])} Partially Vaccinated
      //       </p>
      //   )
      //   setTip2content(content2);
      //   setTip2Y(y(info[y1stack])+tipHeight/2);
      //   setTip2X(timeScale(moment(info.date))-tipWidth-(mobile ? 10 : 50));
      //   setShowTip2(true);

      //   let off = Math.max(0, (y(info[y1stack])+tipHeight/2) - y(info.percent_full_or_partial_vacc)+tipHeight/2)
      //   if (off < 35) {
      //     setTip1Y(y(info.percent_full_or_partial_vacc)+(tipHeight/2)-15)
      //     connector1.attr("y1",y(info.percent_full_or_partial_vacc)-15)
      //   }
      // } else {
      //   setShowTip2(false);
      //   circle2.attr("display", "none");
      //   connector2.attr("display", "none");
      // }
    }
    // credit: https://bl.ocks.org/mbostock/8027637
    function closestPoint(pathNode, point) {
      let pathLength = pathNode.getTotalLength(),
        precision = 8,
        best,
        bestLength,
        bestDistance = Infinity;

      // linear scan for coarse approximation
      for (
        let scan, scanLength = 0, scanDistance;
        scanLength <= pathLength;
        scanLength += precision
      ) {
        if (
          (scanDistance = distance2(
            (scan = pathNode.getPointAtLength(scanLength))
          )) < bestDistance
        ) {
          best = scan;
          bestLength = scanLength;
          bestDistance = scanDistance;
        }
      }

      // binary search for precise estimate
      precision /= 2;
      while (precision > 0.5) {
        let before,
          after,
          beforeLength,
          afterLength,
          beforeDistance,
          afterDistance;
        if (
          (beforeLength = bestLength - precision) >= 0 &&
          (beforeDistance = distance2(
            (before = pathNode.getPointAtLength(beforeLength))
          )) < bestDistance
        ) {
          best = before;
          bestLength = beforeLength;
          bestDistance = beforeDistance;
        } else if (
          (afterLength = bestLength + precision) <= pathLength &&
          (afterDistance = distance2(
            (after = pathNode.getPointAtLength(afterLength))
          )) < bestDistance
        ) {
          best = after;
          bestLength = afterLength;
          bestDistance = afterDistance;
        } else {
          precision /= 2;
        }
      }

      best = [best.x, best.y];
      best.distance = Math.sqrt(bestDistance);
      return best;

      function distance2(p) {
        var dx = p.x - point[0],
          dy = p.y - point[1];
        return dx * dx + dy * dy;
      }
    }

    return () => {
      group.remove();
    };
  }, [data, timeExtent, svgWidth, mobile, height]);

  return (
    <svg ref={svgRef} width={svgWidth} height={height}>
      <g ref={groupRef}></g>
      <g>
        <g
          transform={`translate(${tip1X}, ${tip1Y})`}
          style={{
            visibility: showTip1 ? "visible" : "hidden",
            pointerEvents: "none",
          }}
        >
          <foreignObject height={tipHeight} width={tipWidth}>
            <div
              className={styles.tooltip}
              xmlns="http://www.w3.org/1999/xhtml"
            >
              {tip1content}
            </div>
          </foreignObject>
        </g>
      </g>
    </svg>
  );
};

export default SelectedStateArea;
