import React from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { SCALE_TYPES } from '../../../../../constants';
import { useSelector } from "react-redux";
import TooltipIcon from "../../../../../../../../common/TooltipBadge/TooltipIcon";
import { IconLookup } from "../../../../../utils";
import stoppedReportingStates from "data/__mocks__/stopped-reporting-states.json";
import StoppedAlert from "components/common/StoppedAlert";

const useStyles = makeStyles(() => ({
    text: {
        fontSize: `17px`,
        color: '#56586E',
        lineHeight: `29px`,
        fontWeight: 500,
    },
    subText: {
        fontSize: '17px',
        color: '#56586E',
        fontWeight: "bold",
        lineHeight: `26px`,
    },
    approachContainer: {
        paddingTop: '5px',
        borderTop: `1px solid #56586E`,
        display: 'flex',
        flexDirection: 'column'
    },
    approachHeader: {
        fontSize: '12px',
        color: '#588CC3',
        lineHeight: `14px`,
        textTransform: 'uppercase'
    },
    approachInnerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '5px',
        alignItems: 'center'
    },
    approachText: {
        fontSize: '16px',
        color: '#0E3071',
        lineHeight: `18px`,
        marginLeft: '5px',
        textTransform: 'uppercase'
    },
    approachIcon: {
        width: '40px',
        height: '40px',
        marginRight: '5px'
    },
    stopped: {
        position: 'relative',
        boxShadow: 'none',
        padding: 0
    }
}));

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: '#fff',
        maxWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        textAlign: 'left',
    },
}))(Tooltip);

const Cell = ({ cell, selectedDataSet }) => {
    const classes = useStyles();
    const metadata = useSelector(state => state.testingTracker.metadata);

    // If an ordinal scale, we want to show in tooltip the correct scale name
    let cellValue;
    let color = '#56586e';
    if (selectedDataSet.scaleType === SCALE_TYPES.ORDINAL) {
        cellValue = selectedDataSet.colorMap[cell.value];
        color = selectedDataSet.colorScale(cell.value);
    } else {
        cellValue = selectedDataSet.formatter(cell.value);
    }

    const [mouseOpen, setMouseOpen] = React.useState(false);
    const [focusOpen, setFocusOpen] = React.useState(false);

    const isPercentPositive = selectedDataSet.name === 'Percent positive';
    let denominator = '';
    let numerator = '';
    let iconType = ''
    if (isPercentPositive && cell.approachId) {
        const matchingMetadata = metadata.find(d => d.approachId === cell.approachId);
        denominator = matchingMetadata.denominator;
        numerator = matchingMetadata.numerator;
        iconType = IconLookup.get(cell.approachId)
    }

    const isStopped = !!stoppedReportingStates.filter(v => v.STATE_NAME === cell.name)?.length;

    return (
        <div>
            <HtmlTooltip
                placement="top"
                disableHoverListener
                disableTouchListener
                disableFocusListener
                open={mouseOpen || focusOpen}
                title={
                    <React.Fragment>
                        {
                            isStopped ? (
                                <StoppedAlert className={classes.stopped} state={cell.name.toUpperCase()} category={"TESTING"} stoppedDate={stoppedReportingStates.filter(v => v.STATE_NAME === cell.name)[0]?.STOPPED_DATE} />
                            ) : (
                                <>
                                    <div className={classes.text}>{cell.name}</div>
                                    <div style={{ color }} className={classes.subText}>
                                        {
                                            isPercentPositive && !cell.approachId ? 'N/A' :
                                                `${cellValue} ${isPercentPositive && cell.approachId ? 'positive' : ''}`
                                        }
                                    </div>
                                    {isPercentPositive && cell.approachId ?
                                        (<div className={classes.approachContainer}>
                                            <div className={classes.approachHeader}>
                                                Positivity Calculation:
                                            </div>
                                            <div className={classes.approachInnerContainer}>
                                                <TooltipIcon type={iconType} className={classes.approachIcon} />
                                                <div className={classes.approachText}>
                                                    {`${numerator} /`}
                                                    <br />
                                                    {denominator}
                                                </div>
                                            </div>
                                        </div>) : <>No data available</>
                                    }
                                </>
                            )}
                    </React.Fragment>
                }
            >
                <div
                    tabIndex="0"
                    onMouseEnter={() => setMouseOpen(true)}
                    onMouseLeave={() => setMouseOpen(false)}
                    onFocus={() => setFocusOpen(true)}
                    onBlur={() => setFocusOpen(false)}
                    className={clsx('map__cell', cell.id && 'is-state')}
                    style={cell.style}
                >
                    <span className="map__abbr">{cell.id}</span>
                </div>
            </HtmlTooltip>
        </div>
    );
};
Cell.defaultProps = {
    cell: {},
    setState: () => null,
    selectedDataSet: {},
};
export default Cell;
