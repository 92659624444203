import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomSelect from '../../CustomSelect';
import { valueAccessor } from '../../../../constants';

const RegionSelect = () => {
  const { region = 'all' } = useParams();
  const regions = useSelector(state => state.testingTracker.regions);
  const selectedRegion = regions.find(r => valueAccessor(r) === region);

  const history = useHistory();
  const routeToSelectedRegion = (id) =>
    history.push(`/testing/tracker/overview/${id}`, {
      noScroll: true,
    });

  return (
    <div className="region__select">
      <CustomSelect
        items={regions}
        selectedItem={selectedRegion}
        label="Filter by region"
        id="region-select"
        onChange={routeToSelectedRegion}
      />
    </div>
  );
};
RegionSelect.defaultProps = {};
export default RegionSelect;
