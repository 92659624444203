import React, { useRef, useEffect, useState } from "react";
import Button from "components/common/Button";
import { scaleLog } from "d3-scale";
import { format } from "d3-format";
import { extent } from "d3-array";
import { line, curveBasis } from "d3-shape";
import { zoom, zoomIdentity } from "d3-zoom";
import { select, event as d3event } from "d3-selection";
import { axisLeft, axisBottom } from "d3-axis";
import { forceSimulation, forceX, forceY, forceCollide } from "d3-force";
import styles from "./SequencingByGDP.module.scss";

const ScatterPlot = ({ data, trendData, selectedCountry, width }) => {
  const svgRef = useRef(null);

  const breakpoint = 970;
  const xvar = "GDP_percap";
  const yvar = "percentSequenced";
  const radius = 10;

  const [resetFunc, setResetFunc] = useState(() => {});
  const [, setMobile] = useState(window.innerWidth < breakpoint);

  const [{ clipWidth, clipHeight }, setClipDims] = useState({
    clipWidth: window.innerWidth,
    clipHeight: window.innerHeight,
  });

  useEffect(() => {
    const margin = { top: 40, right: 20, bottom: 50, left: 60 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = width - margin.top - margin.bottom;

    setClipDims({ clipWidth: innerWidth, clipHeight: innerHeight });

    let zooms = zoom().on("zoom", null);

    const filtered = data
      .filter((e) => e[yvar] > 0)
      .filter((f) => isFinite(f.GDP_percap) && f.GDP_percap > 0)
      .sort((a, b) => a[xvar] - b[xvar]);
    const trendFiltered = trendData
      .filter((e) => e[yvar] > 0)
      .filter((f) => isFinite(f.GDP_percap) && f.GDP_percap > 0)
      .sort((a, b) => a[xvar] - b[xvar]);

    const yScale = scaleLog()
      .base(Math.E)
      .domain(extent(trendFiltered.map((d) => d[yvar])))
      .range([innerHeight, 0])
      .nice();
    const xScale = scaleLog()
      .base(Math.E)
      .domain(extent(trendFiltered.map((d) => d[xvar])))
      .range([0, innerWidth])
      .nice();
    const simulation = forceSimulation(filtered)
      .force(
        "x",
        forceX().x((d) => xScale(d[xvar]))
      )
      .force(
        "y",
        forceY().y((d) => yScale(d[yvar]))
      )
      .force("collision", forceCollide().radius(radius).strength(0.5));

    const yFormat = yScale.tickFormat(Infinity, ".3p");
    const xFormat = xScale.tickFormat(Infinity, ",.0s");

    const trendline = line()
      .x((d) => xScale(d[xvar]))
      .y((d) => yScale(d.yTrend))
      .curve(curveBasis);

    const highlight =
      !selectedCountry || selectedCountry === "all-content"
        ? "United States"
        : selectedCountry;
    const svg = select(svgRef.current);

    svg.selectAll("g").remove();
    svg.selectAll("foreignObject").remove();

    const yAxis = svg
      .append("g")
      .attr("id", "yaxis")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    yAxis.call(axisLeft(yScale).ticks(5, (d) => yFormat(d)));
    yAxis
      .append("text")
      .attr("x", 0)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("Percent Sequenced");
    yAxis
      .append("text")
      .attr("x", 0)
      .attr("y", -15)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("(natural log scale)");

    const xAxis = svg
      .append("g")
      .attr("id", "xaxis")
      .attr(
        "transform",
        `translate(${margin.left},${innerHeight + margin.top})`
      );
    xAxis.call(axisBottom(xScale).ticks(5, (d) => xFormat(d)));
    xAxis
      .append("text")
      .attr("x", innerWidth - 20)
      .attr("y", margin.bottom / 2 + 5)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("GDP Per Capita");
    xAxis
      .append("text")
      .attr("x", innerWidth - 20)
      .attr("y", margin.bottom / 2 + 20)
      .attr("text-anchor", "middle")
      .attr("fill", "black")
      .text("(natural log scale)");

    svg
      .append("g")
      .attr("id", "trendline")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .style("clip-path", "url(#clip)")
      .append("path")
      .attr("d", trendline(trendFiltered))
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", "1.5px");

    const nodes = svg
      .append("g")
      .attr("id", "nodes")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .style("clip-path", "url(#clip)")
      .selectAll("circle")
      .data(filtered)
      .join("circle")
      .attr("r", radius)
      .style("fill", (d) => (d.Country === highlight ? "#0E2C74" : "#ddd"))
      .attr("fill-opacity", 0.8);

    const labels = svg
      .append("g")
      .attr("id", "labels")
      .attr("transform", `translate(${margin.left},${margin.top})`)
      .style("clip-path", "url(#clip)")
      .selectAll(".labels")
      .data(filtered)
      .join("text")
      .text((d) => d.ISO3)
      .attr("dy", "3px")
      .style("text-anchor", "middle")
      .style("font-weight", "bold")
      .style("cursor", "default")
      .style("font-size", "6px")
      .style("font-family", "Arial")
      .style("font-weight", "900")
      .style("fill", (d) => (d.Country === highlight ? "white" : "black"));

    const tooltip = svg
      .append("foreignObject")
      .attr("x", 150)
      .attr("y", 20)
      .attr("width", 240)
      .attr("height", 90)
      .attr("class", styles.tooltip);

    nodes
      .on("mouseenter", (d) => {
        tooltip.transition().duration(300).style("opacity", 1);
        tooltip.html(`<h5 class="${styles.tooltipH5}">${d.Country}</h5>
                  <p class="${
                    styles.tooltipText
                  }">Percent Cases Sequenced: ${format(".3p")(d[yvar])}</p>
                  <p class="${styles.tooltipText}">GDP Per Capita: ${format(
          "$,.0f"
        )(d.GDP_percap)}</p>
                  <p class="${styles.tooltipText}">Cases: ${format(",.0f")(
          d.cases
        )}</p>`);
      })
      .on("mouseleave", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    labels.on("mouseenter", (d) => {
      tooltip.transition().duration(300).style("opacity", 1);
      tooltip.html(`<h5 class="${styles.tooltipH5}">${d.Country}</h5>
                  <p class="${
                    styles.tooltipText
                  }">Percent Cases Sequenced: ${format(".3p")(d[yvar])}</p>
                  <p class="${styles.tooltipText}">GDP Per Capita: ${format(
        "$,.0f"
      )(d.GDP_percap)}</p>
                  <p class="${styles.tooltipText}">Cases: ${format(",.0f")(
        d.cases
      )}</p>`);
    });

    simulation.on("tick", () => {
      nodes.attr("transform", (d) => `translate(${d.x},${d.y})`);
      labels.attr("transform", (d) => `translate(${d.x},${d.y})`);
    });

    zooms = zoom()
      .scaleExtent([0.8, 4])
      .translateExtent([
        [0, 0],
        [width, width],
      ])
      .on("zoom", function () {
        if (!d3event.active) simulation.alphaTarget(0.3).restart();
        let new_xScale = d3event.transform.rescaleX(xScale);
        let new_yScale = d3event.transform.rescaleY(yScale);
        yAxis.call(axisLeft(new_yScale).ticks(5, (d) => yFormat(d)));
        xAxis.call(axisBottom(new_xScale).ticks(5, (d) => xFormat(d)));
        simulation
          .force(
            "x",
            forceX().x((d) => new_xScale(d[xvar]))
          )
          .force(
            "y",
            forceY().y((d) => new_yScale(d[yvar]))
          );
        const new_trendline = line()
          .x((d) => new_xScale(d[xvar]))
          .y((d) => new_yScale(d.yTrend))
          .curve(curveBasis);
        select("#trendline path").attr("d", new_trendline(trendFiltered));
      });

    const reset = () => {
      simulation.alphaTarget(0.5).restart();
      svg.call(zooms.transform, zoomIdentity);
    };
    setResetFunc(() => reset);

    svg.call(reset);
    svg.call(zooms);

    return () => {
      // remove zoom event listener
      zooms = zoom().on("zoom", null);
      // remove tick event listener
      simulation.on("tick", null);
      // reset and restart simulation
      simulation.alphaTarget(0).restart();
    };
  }, [data, trendData, selectedCountry, width]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={styles.resetButton}>
        <Button buttonStyle="plain" onClick={resetFunc}>
          Reset Zoom
        </Button>
      </div>
      <svg width={width} height={width} ref={svgRef}>
        <defs>
          <clipPath id="clip">
            <rect height={clipHeight} width={clipWidth} />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ScatterPlot;
