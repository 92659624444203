import Button from "components/common/Button";
import classNames from "classnames/bind";
import styles from "./GeneralNotes.module.scss";
import HFormat from "components/common/HFormat";
import { getRegionGeneralNotes } from "data/geo";
import React, { useState } from "react";
import NoteContainer from "../NoteContainer";
import DataNote from "../DataNote";
import { getFormatedNoteData } from "../util";
const cx = classNames.bind(styles);

export type TGeneralNotesProps = {
  limit?: number;
};

const GeneralNotes = ({ limit = 1 }: TGeneralNotesProps) => {
  const [allGeneralNotes] = useState(getRegionGeneralNotes());
  const [currentPage, setCurrentPage] = useState(1);
  const incrementPage = () => setCurrentPage(currentPage + 1);
  const decrementPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  if (!allGeneralNotes?.length) {
    return null;
  }

  const visibleContents = allGeneralNotes.slice(0, limit * currentPage);
  const isMore = allGeneralNotes.length - visibleContents.length > 0;
  const result = getFormatedNoteData(visibleContents);
  return (
    <div className={styles.base}>
      <h3 className={styles.header}>General Notes</h3>
      <NoteContainer>
        {Object.keys(result).map((k, i) => (
          <div key={`${k}-${i}`}>
            <HFormat size={4} noMargins>
              {k}
            </HFormat>
            {result[k].map((data, i) => (
              <DataNote
                className={styles.dataNote}
                key={`${k}-${data.name}${i}`}
                {...data}
              />
            ))}
          </div>
        ))}
      </NoteContainer>
      <br />
      {allGeneralNotes.length > limit && (
        <div className={styles.showMoreContainer}>
          <Button
            className={cx("paginator")}
            icon={isMore ? "down" : "up"}
            shape="rounded"
            onClick={isMore ? incrementPage : decrementPage}
          >
            {isMore ? "Show More" : "Show Less"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default GeneralNotes;
