import React, { useEffect } from "react";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import styles from "./TestingFAQ.module.scss";
import questionSheets from "./questions";
import TestingFAQSheet from "./TestingFAQSheet";
import TestingInfographic from "./TestingInfographic";

const TestingFAQ = () => {
  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"]
    });
  }, []);
  return (
    <Page id={styles.pageId} path="/testing/testing-faq">
      <div className={styles.sheetMenu}>
        <div className={styles.container}>
          {questionSheets.map(({ slug, title }) => (
            <NavLink
              key={slug}
              to={`/testing/testing-faq/${slug}`}
              className={styles.sheetLink}
              activeClassName={styles.active}
            >
              {title}
            </NavLink>
          ))}
        </div>
      </div>
      <Switch>
        <Redirect
          from="/testing/testing-faq/general-public"
          to="/testing/testing-faq/overview"
        />
        <Route
          path="/testing/testing-faq/:sheetId"
          component={TestingFAQContent}
        />
        <Redirect to={`/testing/testing-faq/${questionSheets[0].slug}`} />
      </Switch>
    </Page>
  );
};

export default TestingFAQ;

const TestingFAQContent = ({ match, location }) => {
  const components = {
    overview: <TestingFAQSheet match={match} location={location} />,
    "facts-viral-and-serology-tests": <TestingInfographic />,
  };
  return components[match.params.sheetId];
};
