import { request } from "../util/fetch";

export function fetchSettings () {
  const settingsUrl = "/settings.json";
  return (dispatch) => {
    dispatch(fetchSettingsBegin());
    return request(settingsUrl)
      .then(res => {
        dispatch(fetchSettingsSuccess(res));
        return res;
      })
      .catch(error => dispatch(fetchSettingsFailure(error)));
  };
}

// ------------------------------------
// CONSTANTS:
// ------------------------------------
export const FETCH_SETTINGS_BEGIN   = 'FETCH_SETTINGS_BEGIN';
export const FETCH_SETTINGS_SUCCESS = 'FETCH_SETTINGS_SUCCESS';
export const FETCH_SETTINGS_FAILURE = 'FETCH_SETTINGS_FAILURE';

// ------------------------------------
// ACTION CREATORS:
// ------------------------------------
export const fetchSettingsBegin = () => ({
  type: FETCH_SETTINGS_BEGIN
});

export const fetchSettingsSuccess = (data) => ({
  type: FETCH_SETTINGS_SUCCESS,
  payload: { data }
});

export const fetchSettingsFailure = error => ({
  type: FETCH_SETTINGS_FAILURE,
  payload: { error }
});

// ------------------------------------
// Reducer:
// ------------------------------------
const initialState = {
  data: {
    enableWorldMapWarning: false,
    enableLive: false
  },
  loading: false,
  error: null
};

const ACTION_HANDLERS = {
  [FETCH_SETTINGS_BEGIN]: state => ({
    ...state,
    loading: true,
    error: null
  }),
  [FETCH_SETTINGS_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    error: null,
    data: action.payload.data
  }),
  [FETCH_SETTINGS_FAILURE]: (state, action) => ({
    ...state,
    loading: false,
    error: action.payload.error,
    // data: initialState.data
  })
};

export default function settingsReducer (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
};
