export const formatLastUpdatedDate = (date) => {
  const tsDate = new Date(date);
  const lastUpdated = tsDate.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
    timeZone: "America/New_York",
  });
  return lastUpdated;
};
