import React from "react";
import classNames from "classnames";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import styles from "./VaccinesNavContent.module.scss";
import countriesImage from "./images/Vaccine-Tracker-Countries.jpg";
import statesImage from "./images/Vaccine-Tracker-States.jpg";
import statePlansImage from "./images/vaccine-state-plans.png";
import storyImage from "./images/vaccine-story.png";
import ArticleCard from "components/layout/ArticleCard";

const cx = classNames.bind(styles);

const VaccinesNavContent = ({ dark = true, drawer = true }) => {
  const statePlans = {
    key: "vaccine-state-plans",
    href: "/vaccines/vaccine-state-plans",
    title: "Vaccine State Plans",
    body: (
      <p>
        Compare the various strategies U.S. states used for rolling out
        vaccinations. While states all agreed health care workers should be
        first in line for vaccines, the states quickly diverged on who came
        next.
      </p>
    ),
    new: false,
    vertical: true,
    containImage: true,
    dark: dark,
    verticals: ["vaccines"],
    imageSrc: statePlansImage,
  };
  const articles = [
    {
      title: "U.S. Vaccination Efforts",
      href: "/vaccines/us-states",
      body: <p>Explore Vaccination Progress by U.S. State</p>,
      new: false,
      dark: dark,
      verticals: ["vaccines"],
      imageSrc: statesImage,
    },
    {
      title: "International Vaccination Efforts",
      href: "/vaccines/international",
      body: <p>Explore Vaccination Progress by Country</p>,
      new: false,
      dark: dark,
      verticals: ["vaccines"],
      imageSrc: countriesImage,
    },

    {
      title: "Vaccine Story",
      href: "/vaccines/story",
      body: (
        <p>
          Explore how U.S. states delivered their supplies of COVID-19 vaccines
          to their residents at vastly different speeds that led to vastly
          different results.
        </p>
      ),
      new: false,
      dark: dark,
      verticals: ["vaccines"],
      containImage: true,
      imageSrc: storyImage,
    },
    statePlans,
  ];
  return (
    <div className={cx("base")}>
      {!drawer ? (
        <>
          <ArticleGridLayouts.Flexible articles={articles} />
          <ArticleCard
            key={"vaccine-state-plans"}
            {...statePlans}
            major
            containImage
          />
        </>
      ) : (
        <>
          <ArticleGridLayouts.Custom articles={articles} />
        </>
      )}
    </div>
  );
};

export default VaccinesNavContent;
