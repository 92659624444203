import {initialState} from '../state/global';
import { getData } from '../api';

// Actions
const TOGGLE_TIMELINE_LOADING_ICON = 'TOGGLE_TIMELINE_LOADING_ICON';
const UPDATE_TIMELINE_DATA = 'UPDATE_TIMELINE_DATA';
const SHOW_ERROR = 'SHOW_ERROR';
const TOGGLE_LEGEND_CATEGORY = 'TOGGLE_LEGEND_CATEGORY';
const TOGGLE_ZOOM_EFFECT = 'TOGGLE_ZOOM_EFFECT';
const RESET_ZOOM_EFFECT = 'RESET_ZOOM_EFFECT';

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_TIMELINE_DATA:
            return ({
                ...state,
                data: action.data
            });
        case SHOW_ERROR:
            return {
                ...state,
                showError: true,
            };
        case TOGGLE_LEGEND_CATEGORY:
            return {
                ...state,
                legendItems: state.legendItems.map(d => {
                    if (d.id === action.id) {
                        return {
                            ...d,
                            isActive: !d.isActive
                        }
                    }
                    return d;
                })
            };
        case TOGGLE_ZOOM_EFFECT:
            return {
                ...state,
                zoomEffect: !state.zoomEffect
            };
        case RESET_ZOOM_EFFECT:
            return {
                ...state,
                zoomEffect: false
            }
        case TOGGLE_TIMELINE_LOADING_ICON:
            return {
            ...state,
            showError: false,
            isLoading: action.x,
            };
        default:
            return state;
    }
}

function toggleLoadingIcon(x) {
    return {
      type: TOGGLE_TIMELINE_LOADING_ICON,
      x,
    };
  }

export function toggleLegendVisibility(id) {
    return {
        type: TOGGLE_LEGEND_CATEGORY,
        id
    }
}

export function toggleZoom() {
    return {
        type: TOGGLE_ZOOM_EFFECT
    }
}

export function resetZoom() {
    return {
        type: RESET_ZOOM_EFFECT
    }
}

export function retrieveData() {
    return async dispatch => {  
        dispatch(toggleLoadingIcon(true));

        try {
            const data = await getData();
            dispatch({
                type: UPDATE_TIMELINE_DATA,
                data,
            });
        } catch (err) {
            console.log(err);
            dispatch({ type: SHOW_ERROR });
        }

        dispatch(toggleLoadingIcon(false));
    };

  }
