import Button from "components/common/Button";
import classNames from "classnames/bind";
import styles from "./PrimaryNotes.module.scss";
import HFormat from "components/common/HFormat";
import React from "react";
import NoteContainer from "../NoteContainer";
import DataNote from "../DataNote";
import { TRegionNote } from "data/geo/helpers";
const cx = classNames.bind(styles);

type TPrimaryNotesProps = {
  noteData: { [key: string]: TRegionNote[] };
  onShowMore?: () => void;
  isMore?: boolean;
};

const PrimaryNotes = ({ noteData, isMore, onShowMore }: TPrimaryNotesProps) => {
  const handleOnShowMore = () => onShowMore?.();
  return (
    <div className={styles.base}>
      <NoteContainer>
        {Object.keys(noteData).map((k, i) => (
          <div key={`${k}-${i}`}>
            <HFormat size={4} noMargins>
              {k}
            </HFormat>
            {noteData[k].map((data, i) => (
              <DataNote
                className={styles.dataNote}
                key={`${k}-${data.name}${i}`}
                {...data}
              />
            ))}
          </div>
        ))}
      </NoteContainer>
      <br />
      {isMore && (
        <div className={styles.showMoreContainer}>
          <Button
            className={cx("paginator")}
            icon="down"
            shape="rounded"
            onClick={handleOnShowMore}
          >
            Show More
          </Button>
        </div>
      )}
    </div>
  );
};

export default PrimaryNotes;
