import React, {useCallback} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import CustomSelect from '../../CustomSelect';
import {BASE_STATE_TIMELINE_ROUTE, DEFAULT_DATASET} from '../../../../constants';
import {resetZoom} from "../../../../redux/modules/global";

const StateSelect = ({selectedState}) => {
    const {dataset = DEFAULT_DATASET} = useParams();
    const states = useSelector(state => state.stateTimeline.states);
    const dispatch = useDispatch();
    const resetZoomEffect = useCallback(() => dispatch(resetZoom()), [dispatch]);
    const history = useHistory();
    const routeToSelectedState = (id) => {
        resetZoomEffect();
        history.push(`${BASE_STATE_TIMELINE_ROUTE}/${dataset}/${id}`, {noScroll: true});
    }

    return (
        <div className="state__select">
            <CustomSelect
                items={states}
                selectedItem={selectedState}
                label="Select another state"
                id="state-select"
                onChange={routeToSelectedState}
            />
        </div>
    );
};
StateSelect.defaultProps = {
    selectedState: {},
};
export default StateSelect;
