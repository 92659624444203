import React from "react";
import { useSelector } from "react-redux";
import { matchName } from "data/geo";
import classNames from "classnames/bind";
import styles from "./StateCountyInsights.module.scss";
import Button from "components/common/Button";
import HFormat from "components/common/HFormat";
const cx = classNames.bind(styles);

/* MOVE TO REDUX, which will become: */
// import stateTimelineData from "../public/datasets/state-timeline/index";
const StateCountyInsights = ({ stateName, stateSlug, renderAsEmbed }) => {
  /* MOVE TO REDUX: */
  const stateTimelineData = useSelector((state) => state.stateTimeline.data);
  const timelineDataKey = Object.keys(stateTimelineData).find((k) =>
    matchName(k, stateName)
  );
  const timelineData = stateTimelineData[timelineDataKey];
  const timelineContent =
    timelineData && timelineData.events
      ? timelineData.events.sort(
          (a, b) => new Date(b.dt || null) - new Date(a.dt || null)
        )[0]
      : null;
  const hasTimelineContent = !!timelineContent?.values?.length;

  return (
    <div className={cx("insightSection")}>
      <h2>{hasTimelineContent ? "State & County Insights" : "State Map"}</h2>
      <div className={cx("columns", { single: true })}>
        <div>
          {hasTimelineContent && (
            <>
              <HFormat size={3} noMarginTop>
                Recent Opening and Closing Policy Decisions:
              </HFormat>
              {!renderAsEmbed && (
                <Button
                  icon="right"
                  href={`/data/state-timeline/new-confirmed-cases/${stateSlug}`}
                >
                  All Policy Decisions
                </Button>
              )}
              <br />
              <br />
              <div className={styles.insightContent}>
                <div className="small-heading">
                  {new Date(timelineContent.dt).toLocaleDateString(undefined, {
                    timeZone: "UTC",
                    dateStyle: "long",
                  })}
                </div>
                <ul>
                  {!!timelineContent.values &&
                    timelineContent.values.map((ele, i) => (
                      <li key={`${ele.type}_${i}`}>
                        <p>{ele.description}</p>
                      </li>
                    ))}
                </ul>
              </div>
            </>
          )}
          {/* <ReactMarkdown source={disclaimerBlock?.content} /> */}
        </div>
      </div>
    </div>
  );
};

export default StateCountyInsights;
