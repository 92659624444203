// ------------------------------------
// CONSTANTS:
// ------------------------------------
export const TOGGLE_MAP_INFO = "TOGGLE_MAP_INFO";
export const SET_MAP_WARNING = "SET_MAP_WARNING";

// ------------------------------------
// ACTION CREATORS:
// ------------------------------------
export const toggleMapInfo = () => ({ type: TOGGLE_MAP_INFO });

// ------------------------------------
// Reducer:
// ------------------------------------
const initial_state = {
  displayMapInfo: false
};

const ACTION_HANDLERS = {
  [TOGGLE_MAP_INFO]: (state) => ({
    ...state,
    displayMapInfo: !state.displayMapInfo,
  })
};

export default function sessionReducer(state = initial_state, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
