import React from "react";
import TooltipIcon from "../../../../../../../../common/TooltipBadge/TooltipIcon";
import { IconLookup } from "../../../../../utils";
import HtmlTooltip from "../../HtmlTooltip";

const CalcMethod = ({ approach }) => {
  const { approachId } = approach;

  return (
    <HtmlTooltip approach={approach}>
      <div className="calc__method">
        <TooltipIcon
          type={IconLookup.get(approachId)}
          className="approach__icon actionable"
        />
      </div>
    </HtmlTooltip>
  );
};
CalcMethod.defaultProps = {
  approach: {
    approachId: 1,
    info: " ",
  },
};
export default CalcMethod;
