import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  dashedLine: {
    strokeDasharray: '5, 5',
    stroke: 'black',
    fill: 'none',
  },
}));

const DashedLine = ({ x1, x2, y1, y2 }) => {
  const classes = useStyles();
  return <line x1={x1} x2={x2} y1={y1} y2={y2} className={classes.dashedLine} />;
};

export default DashedLine;
