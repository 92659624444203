import React from "react";
import ReactMarkdown from "react-markdown";
import Speakers from "../../Speakers";
import baseStyles from "../EventMod.module.scss";

export const CommunityForum_1_27_21 = ({
  slug = "community-forum-with-dr-kizzmekia-corbett",
  subtitle,
  contentSrc,
}) => {
  const path = `/images/vaccine-events/speakers/${slug}`;

  const moderators = [
    {
      name: "Risha Irvin, M.D., M.P.H,",
      title: "Assistant Professor of Medicine",
      image: `${path}/risha-irvin.jpg`,
    },
  ];

  const speakers = [
    {
      name: "Kizzmekia Corbett, Ph.D.",
      title:
        "Research Fellow and Scientific Lead for the Coronavirus Vaccines & Immunopathogenesis Team",
      image: `${path}/kizzmekia-corbett.jpg`,
    },
    {
      name: "Sherita Hill Golden, M.D., M.H.S.",
      title:
        "Vice President and Chief Diversity Officer of the Office of Diversity, Inclusion, and Health Equity,  Professor of Medicine",
      image: `${path}/sherita-golden.jpeg`,
    },
    {
      name: "Chidinma Ibe, Ph.D",
      title: "Assistant Professor of Medicine",
      image: `${path}/ibe.jpg`,
    },
  ];

  const panelists = [
    {
      name: "Chris Beyrer, MD",
      title: "Professor of Public Health and Human Rights",
      image: `${path}/chris-b.jpg`,
    },
    {
      name: "Rev. Debra Hickman, MDiv",
      title: "Co-Founder/CEO",
      image: `${path}/debra-Hickman.jpg`,
    },
    {
      name: "Freeman A. Hrabowski, III, Ph.D. ",
      title: "President",
      image: `${path}/hrabowski-freeman.jpeg`,
    },
    {
      name: "Kathleen Page, M.D.",
      title: "Associate Professor of Medicine",
      image: `${path}/kathleen-page.jpeg`,
    },
  ];

  const content = () =>
    !!contentSrc && (
      <div>
        <ReactMarkdown
          className={baseStyles.embedDescription}
          source={contentSrc}
        />
        <Speakers title={"Moderator"} authors={moderators} />
        <Speakers authors={speakers} />
        <Speakers title={"Panelists"} authors={panelists} />
      </div>
    );

  return {
    slug,
    content,
  };
};
