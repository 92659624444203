import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomSelect from '../../../Shared/CustomSelect';
import {updateSelectedBubbleLayer} from "../../../../../../redux/modules/global";

const BubbleLayerSelect = ({selectedBubbleLayer}) => {
    const dispatch = useDispatch();
    const bubbleLayers = useSelector(state => state.equityAccessToTesting.bubbleLayers);
    const updateBubbleLayer = useCallback(id => dispatch(updateSelectedBubbleLayer(id)), [dispatch]);

    return (
        <div className="bubble__layer__select">
            <CustomSelect
                items={bubbleLayers}
                selectedItem={selectedBubbleLayer}
                labels={["COVID-19 data points can be chosen from the drop down menu below, All data reflect a 7-day moving average."]}
                id="bubble-layer-select"
                onChange={updateBubbleLayer}
            />
        </div>
    );
};
export default BubbleLayerSelect;
