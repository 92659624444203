import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./RegionOverview.module.scss";
import ColumnLayout from "components/layout/ColumnLayout";
import TabBar from "components/layout/shell/TabBar";
import CustomSelect from "components/common/CustomSelect";
import RegionVaccineTracker from "../RegionVaccineTracker";
import ScrollTarget from "components/common/PageNavigator/ScrollTarget";
import HFormat from "components/common/HFormat";
import { ReactComponent as OverviewIcon } from "./Icons/state-overview.svg";
import { OverviewBlock } from "./OverviewBlock";
import { accessibilityProps_IMG } from "util/accessibility";

const RegionOverview = ({
  data,
  movingAvgData,
  vaccineData,
  renderStats,
  regionSlug,
  regionName,
  regionCode,
  regionType,
  isEmbed,
}) => {
  // Load the settings data, just once
  const [currentWidth, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const tabs = ["All Time", "Past Day", "Past Week", "Past Month"];
  const tabValues = ["all", "day", "week", "month"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const currentTabValue = tabValues[tabs.indexOf(selectedTab)];
  const isAllTimeTab = selectedTab === tabs[0];
  const loading = !data;
  const isCountry = regionType === "country";
  
  const mainHeader = `${isCountry ? "" : "State "}Overview`;
  const overviewBlockProps = {
    loading,
    timeScale: currentTabValue,
  };

  const handleTabChange = (tab) => {
    if (typeof tab === "object") {
      setSelectedTab(tab.label);
      return;
    }
    setSelectedTab(tab);
  };
  return (
    <>
      <HFormat classNames={styles.iconHeader} size={2} noMarginTop>
        <OverviewIcon {...accessibilityProps_IMG(mainHeader)} />
        {mainHeader}
      </HFormat>
      <div className={classNames(styles.mainSections, styles.noBorder)}>
        <div className={classNames(isCountry && styles.countryOverview)}>
          <div className={styles.tabSection}>
            {/* Tab bar / select dropdown */}
            {!isEmbed &&
              (currentWidth >= 970 ? (
                <TabBar
                  tabs={tabs}
                  onChange={handleTabChange}
                  forcedTab={selectedTab}
                />
              ) : (
                <CustomSelect
                  noAllOption
                  allOptionLabel={selectedTab}
                  multi={false}
                  options={tabs.map((tab, i) => {
                    return { label: tab, value: tabValues[i] };
                  })}
                  onSelect={handleTabChange}
                  isSearchable={false}
                />
              ))}

            <br />
            <ColumnLayout
              layout={[[3, 3, 3, 3], [6, 6], [12]]}
              gap={{ row: 64, column: 32 }}
              dividers={{ row: "WHEN_FULL_WIDTH" }}
              breakpoint={180}
            >
              {renderStats.includes("confirmed_cases") && (
                <OverviewBlock
                  {...overviewBlockProps}
                  title={isAllTimeTab ? "Confirmed Cases" : "New Cases"}
                  record={data?.confirmed_cases_records?.[currentTabValue]}
                  value={data?.confirmed_cases?.[currentTabValue]}
                  averages={
                    selectedTab === "Past Day" ? movingAvgData?.["cases"] : null
                  }
                />
              )}
              {renderStats.includes("deaths") && (
                <OverviewBlock
                  {...overviewBlockProps}
                  title={isAllTimeTab ? "Deaths" : "New Deaths"}
                  record={data?.deaths_records?.[currentTabValue]}
                  value={data?.deaths?.[currentTabValue]}
                  averages={
                    selectedTab === "Past Day"
                      ? movingAvgData?.["deaths"]
                      : null
                  }
                />
              )}
              {renderStats.includes("total_tests") && (
                <OverviewBlock
                  {...overviewBlockProps}
                  stoppedDate="09/21/2022"
                  title={isAllTimeTab ? "Total Tested" : "Test Results"}
                  record={data?.total_tests_records?.[currentTabValue]}
                  value={data?.total_tests?.[currentTabValue]}
                  averages={
                    selectedTab === "Past Day" ? movingAvgData?.["tests"] : null
                  }
                />
              )}
              {renderStats.includes("positivity") &&
                selectedTab !== "Past Day" && (
                  <OverviewBlock
                    {...overviewBlockProps}
                    stoppedDate="09/21/2022"
                    title="Testing Positivity"
                    record={data?.positivity_records?.[currentTabValue]}
                    value={data?.positivity?.[currentTabValue]}
                    isPercentage
                  />
                )}
            </ColumnLayout>
          </div>
          {isCountry && (
            <div
              className={styles["country-image"]}
              style={{
                backgroundImage: `url(/images/countries/${regionCode.toLowerCase()}.svg`,
              }}
            />
          )}
        </div>
        {!!vaccineData && (
          <>
            <ScrollTarget id={`vaccine-tracker`} />
            <RegionVaccineTracker
              vaccineData={vaccineData.data}
              isCountry={isCountry}
            />
          </>
        )}
      </div>
    </>
  );
};

RegionOverview.propTypes = {
  data: PropTypes.object,
  renderStats: PropTypes.arrayOf(
    PropTypes.oneOf(["confirmed_cases", "deaths", "total_tests", "positivity"])
  ),
};
RegionOverview.defaultProps = {
  renderStats: ["confirmed_cases", "deaths", "total_tests", "positivity"],
};

export default RegionOverview;
