import React from "react";
import styles from "./DataBanner.module.scss";
import classNames from "classnames/bind";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

export type TDataBannerProps = {
  images?: string[];
  title?: string;
  content: string;
  links?: {
    href?: string;
    label?: string;
  }[];
  targetBlank?: boolean;
  className?: string;
};

const DataBanner = ({
  images = [],
  title,
  content,
  links,
  targetBlank = true,
  className,
}: TDataBannerProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.contentBlock}>
        <div
          className={cx(styles.imageBlock, images.length > 1 && styles.multi)}
        >
          {images.map((image, i) => (
            <div key={i} className={styles.imageContainer}>
              <img
                key={i}
                className={styles.image}
                src={image}
                alt={"title"}
                loading="lazy"
              />
            </div>
          ))}
        </div>
        <div className={styles.textBlock}>
          {title && <h3 className={styles.title}>{title}</h3>}
          {content && <p className={styles.content}>{content}</p>}
        </div>
      </div>
      {links.length > 0 && (
        <div className={styles.buttonBlock}>
          {links.map((link, i) => (
            <Button
              key={i}
              className={styles.button}
              href={link.href}
              targetBlank={targetBlank}
              icon={"right"}
            >
              {link.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DataBanner;
