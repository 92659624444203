import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import ColumnLayout from 'components/layout/ColumnLayout';
import {
  ArticleGridLayouts,
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
} from 'components/layout/ArticleGrid';

import classNames from 'classnames/bind';
import styles from './ArticleGrid.module.scss';
const cx = classNames.bind(styles);

export default function ArticleGridStyleGuide() {
  const [colDividers, setColDividers] = useState(false);
  const [rowDividers, setRowDividers] = useState(false);
  const dividers = {
    column: colDividers,
    row: rowDividers,
  };

  const articleContent = {
    category: 'Understanding COVID-19',
    title: 'Insights from Johns Hopkins University Experts',
    body: (
      <p>
        Johns Hopkins University is hosting a free, publicly available course
        titled Understanding the COVID-19 Pandemic: Insights from Johns Hopkins
        University Experts. This course is set up as a series of short modules
        to explore the COVID-19 pandemic. Registration is not required.
      </p>
    ),
    imageSrc: '/images/people-visual.png',
  };
  const articles = (num) => new Array(num).fill(articleContent);

  return (
    <div className={cx('container')}>
      <h1 className={cx('styleguide-annotation')}>ArticleGrid</h1>
      <p className={cx('styleguide-annotation')}>
        ArticleGrid can render multiple articles from their JSON representation
        into a variety of pre-defined layouts. Additionally, custom layouts are
        possible using a built-in markup system.
      </p>
      <p className={cx('styleguide-annotation')}>
        There are multiple ArticleGrid components built-in for different common
        layouts. Each can define three aspects of layout:
      </p>
      <ol className={cx('styleguide-annotation')}>
        <li>
          The layout of the columns of the grid, using the ColumnLayout system
          described below
        </li>
        <li>
          The layout of cards within the columns, i.e. how many cards will
          appear stacked in each column?
        </li>
        <li>
          Layout-related props to pass down to individual{' '}
          <Link to="/style-guide/article-card">ArticleCards</Link>, for example
          “major” or “vertical.”
        </li>
      </ol>
      <p className={cx('styleguide-annotation')}>
        A simple example of such a built-in ArticleGrid layout is the OffCenter
        layout. In the following example, an OffCenter layout is passed an array
        of two articles.
      </p>
      <h3 className={cx('styleguide-annotation')}>
        ArticleGridLayouts.OffCenter
      </h3>
      <p className={cx('styleguide-annotation')}>
        The OffCenter ArticleGrid layout uses the ‘off-center’ column layout,
        places one ArticleCard in each column, and defines that the ArticleCard
        in the first column is ‘major’ while the ArticleCard in the second
        column is ‘minor’ and horizontal.
      </p>
      <ArticleGridLayouts.OffCenter articles={articles(2)} />

      <h3 className={cx('styleguide-annotation')}>
        ArticleGridLayouts.Flexible
      </h3>
      <p className={cx('styleguide-annotation')}>
        The Flexible ArticleGrid layout can display an unlimited number of
        ArticleCards a standard wrapping grid layout. It uses the 'flexible'
        ColumnLayout and formats its children as minor and veritcal.
      </p>
      <ArticleGridLayouts.Flexible articles={articles(10)} />

      <h2 className={cx('styleguide-annotation')}>Building custom layouts</h2>
      <p className={cx('styleguide-annotation')}>
        In order to create new ArticleGrid layouts, you can interact directly
        with the <code>ArticleGridLayoutEngine</code> API. The{' '}
        <code>ArticleGridLayoutEngine</code> is responsible for creating
        ArticleCards and distributing them on a ColumnLayout grid. In addition
        to taking ArticleCards’ content through its <code>articles</code> prop,
        it takes a two-part grid layout definition:
      </p>
      <ol className={cx('styleguide-annotation')}>
        <li>
          The layout of the columns of the grid is defined by the{' '}
          <code>columnLayout</code> prop, and it’s passed directly to the
          ColumnLayout system described below.
        </li>
        <li>
          The layout of the cards inside each column is dictated by
          <code>ArticleGridLayoutTemplate</code> components passed as direct
          children to <code>ArticleGridLayoutEngine</code>. These template
          components create 'groups' of cards among which the layout engine
          distributes ArticleCards. Then, the groups are distributed across the
          column grid.
        </li>
      </ol>
      <p className={cx('styleguide-annotation')}>
        As an example, let’s create a layout that has a stack of four minor
        ArticleCards on the left and right, and a large major ArticleCard in the
        center. The code we need to create this custom layout looks like this:
      </p>
      <p className={cx('styleguide-annotation')}>
        <code
          style={{
            whiteSpace: 'pre',
            display: 'block',
            transform: 'none',
            padding: '.4em .6em',
          }}
        >{`// Near top of file...

import {
  ArticleGridLayoutEngine, ArticleGridLayoutTemplate
} from 'components/layout/ArticleGrid';

const { SingleCard, CardStack, CustomGroup } = ArticleGridLayoutTemplate;

// In markup...

<ArticleGridLayoutEngine
  columnLayout={[[3, 6, 3], [4, 4, 4], [12]]}
  articles={[/* article content here */]}
>
  <CustomGroup content={<p>Your own arbitrary content here</p>} />
  <SingleCard articleCardProps={{ major: true }} />
  <CardStack
    withDividers
    limit={4}
    articleCardProps={{
      major: false,
      vertical: true,
      imageSrc: null,
      body: [],
    }}
  />
</ArticleGridLayoutEngine>`}</code>
      </p>
      <p className={cx('styleguide-annotation')}>
        We've just defined a layout with:
      </p>
      <ul className={cx('styleguide-annotation')}>
        <li>
          a <code>CardStack</code> in the first position and the last position,
          whilch will contain three cards in a vertical minor layout with no
          image or body
        </li>
        <li>
          a <code>SingleCard</code> in the second, center position, which is
          formatted as 'major'
        </li>
      </ul>
      <p className={cx('styleguide-annotation')}>
        Read the second half of this page for details on the column layout
        system, and to learn how to interpret the value passed to the{' '}
        <code>columnLayout</code> prop.
      </p>
      <p className={cx('styleguide-annotation')}>
        The final result of the above example is the following layout:
      </p>

      <ArticleGridLayoutEngine
        columnLayout={[[3, 6, 3], [4, 4, 4], [12]]}
        articles={articles(9)}
        style={{ marginTop: '2rem' }}
      >
        <ArticleGridLayoutTemplate.CustomGroup
          content={<p>Your own arbitrary content here</p>}
        />
        <ArticleGridLayoutTemplate.SingleCard
          articleCardProps={{ major: true }}
        />
        <ArticleGridLayoutTemplate.CardStack
          limit={4}
          withDividers
          articleCardProps={{
            major: false,
            vertical: true,
            imageSrc: null,
            body: [],
          }}
        />
      </ArticleGridLayoutEngine>

      <hr style={{ marginTop: '56px' }} />

      <h2 className={cx('styleguide-annotation')}>ColumnLayout</h2>
      <p className={cx('styleguide-annotation')}>
        ColumnLayout is the layout engine on top of which ArticleCards are
        built. A ColumnLayout arranges its children on a pre-defined horizontal
        grid and handles wrapping automatically. There are a few built-in
        ColumnLayout configurations (<code>off-center</code>, <code>solo</code>,{' '}
        <code>duet</code>, <code>trio</code>, <code>quartet</code>, and{' '}
        <code>flexible</code>), as well as an easy interface for defining custom
        layouts.
      </p>
      <p className={cx('styleguide-annotation')}>
        Each layout configuration is defined by one or more “layout
        definitions,” each of which is an array of relative column widths.
        Layout definitions can contain any number of elements, and each element
        represents a single column in the layout. Every layout definition’s
        elements must sum to 12, and the magnitude of the individual values
        dictates the columns’ relative width. For example, a layout definition{' '}
        <code>[6, 6]</code> would define two columns of equal width, and,
        similarly <code>[4, 4, 4]</code> defines three such columns.
      </p>
      <p className={cx('styleguide-annotation')}>
        If multiple layout definitions are given for a single layout,
        ColumnLayout will choose the first layout definition for which the{' '}
        <em>smallest</em> column in the resulting layout is wider than{' '}
        <code>breakpoint</code> pixels (default 240). In this way, ColumnLayout
        can define responsive layouts. The following set of layout definitions
        would create an “off-center” two-column layout that falls back to a
        single column layout when space gets tight: <code>[[8, 4], [12]]</code>
      </p>
      {/* Checkbox options to apply to all examples */}
      <p className={cx('styleguide-annotation')}>
        <label>
          <input
            type="checkbox"
            value={dividers.column}
            onChange={(e) => setColDividers(e.target.checked)}
          />
          &nbsp;&nbsp;
          <span style={{ fontWeight: 600 }}>Render column dividers</span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value={dividers.row}
            onChange={(e) => setRowDividers(e.target.checked)}
          />
          &nbsp;&nbsp;
          <span style={{ fontWeight: 600 }}>Render row dividers</span>
        </label>
      </p>

      {[
        ['off-center', 2],
        ['solo', 1],
        ['duet', 2],
        ['trio', 3],
        ['quartet', 4],
      ].map(([layout, numColumns]) => (
        <React.Fragment key={layout}>
          <h3 className={cx('styleguide-annotation')}>
            Layout: <code>{layout}</code>
          </h3>
          <ColumnLayout layout={layout} dividers={dividers}>
            {new Array(Math.ceil(numColumns * 1.6)).fill(null).map((_, i) => (
              <div key={i} className={cx('column-placeholder')} />
            ))}
          </ColumnLayout>
        </React.Fragment>
      ))}

      <h3 className={cx('styleguide-annotation')}>
        Layout: <code>flexible</code> (3 children = up to 3 columns)
      </h3>
      <p className={cx('styleguide-annotation')}>
        The “flexible” layout can have as many columns as it has children, and
        reduces the number of columns one by one as the window shrinks.
      </p>
      <ColumnLayout layout={'flexible'} dividers={dividers}>
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
      </ColumnLayout>
      <h3 className={cx('styleguide-annotation')}>
        Layout: <code>flexible</code> (5 children = up to 5 columns)
      </h3>
      <ColumnLayout layout={'flexible'} dividers={dividers}>
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
      </ColumnLayout>

      <h3 className={cx('styleguide-annotation')}>
        Custom layout: <code>[3, 6, 3]</code>
      </h3>
      <ColumnLayout layout={[3, 6, 3]} dividers={dividers}>
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
      </ColumnLayout>

      <h3 className={cx('styleguide-annotation')} style={{ maxWidth: 'none' }}>
        Custom layout with responsive breakdown:{' '}
        <code>[[3, 6, 3], [4, 4, 4], [6, 6], [12]]</code>
      </h3>
      <ColumnLayout
        layout={[[3, 6, 3], [4, 4, 4], [6, 6], [12]]}
        dividers={dividers}
      >
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
        <div className={cx('column-placeholder')} />
      </ColumnLayout>
    </div>
  );
}
