import React from 'react';
import InteriorPageHeader from 'components/layout/shell/InteriorPageHeader';
import styles from './InteriorPageHeaderStyleGuide.module.scss';

export default function InteriorPageHeaderStyleGuide() {
  return (
    <div className={styles.container}>
      <h1 className={styles['styleguide-annotation']}>Interior Page Headers</h1>

      <InteriorPageHeader
        section="Insights"
        title="Testing Positivity"
        subtitle="Only 27 U.S. States Meet WHO Recommended Positivity Level Recommendations"
        description="On May 12, 2020 the World Health Organization (WHO) advised governments that before reopening,
                    rates of positivity in testing (ie, out of all tests conducted, how many came back positive for
                    COVID-19) of should remain at 5% or lower for at least 14 days."
      />

      <br />

      <InteriorPageHeader
        imgSrc="/images/COVID-19-1600x800.jpg"
        section="Insights"
        title="Testing Positivity"
        subtitle="Only 27 U.S. States Meet WHO Recommended Positivity Level Recommendations"
        description="On May 12, 2020 the World Health Organization (WHO) advised governments that before reopening,
                    rates of positivity in testing (ie, out of all tests conducted, how many came back positive for
                    COVID-19) of should remain at 5% or lower for at least 14 days."
      />
      <br />
      <br />
    </div>
  );
};
