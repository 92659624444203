import React, {useEffect, useRef} from 'react';
import styles from "./BarAxes.module.scss";
import {select} from 'd3-selection';
import {axisBottom, axisRight, axisTop} from 'd3-axis';
import {nameAccessor} from '../../../../../../Shared/constants';
import { ReactComponent as Warning } from "images/icons/warning.svg";

const Axes = ({xScale, yScale, xScaleTickFormat, selectedDataset, yScaleTickFormat, height, width, isOnTop, offset, stoppedDate}) => {
    const xAxisRef = useRef(null);
    const yAxisRef = useRef(null);

    useEffect(() => {
        renderXAxis();
        if (!isOnTop) {
            renderYAxis();
        }
    });

    const renderYAxis = () => {
        const yAxis = select(yAxisRef.current);

        yAxis
            .call(
                axisRight(yScale)
                    .ticks(4)
                    .tickSize(width)
                    .tickFormat(yScaleTickFormat),
            )
            .call(g =>
                g
                    .selectAll('.tick text')
                    .attr('x', 0)
                    .attr('dx', '-5px')
                    .attr('dy', '5px'),
            );

        yAxis.select('.domain').remove();
    };

    const renderXAxis = () => {
        const xAxis = select(xAxisRef.current);

        if (!isOnTop) {
            xAxis
                .call(
                    axisBottom(xScale)
                        .tickFormat(xScaleTickFormat)
                        .ticks(5),
                );
        } else {
            xAxis
                .call(
                    axisTop(xScale)
                        .tickFormat(xScaleTickFormat)
                        .ticks(5),
                );
        }

        xAxis.select('.domain').remove();
        xAxis.selectAll('.tick line').remove();
        xAxis.selectAll('.tick')
            .filter((d, i, list) => i === list.length - 1)
            .select('text')
            .attr("transform", "translate(-16)");
    };

    return (
        <g className="axis">
            <g className="x--axis" transform={`translate(0,${height - offset})`} ref={xAxisRef} clipPath="url(#bar-chart-clip)"/>
            {!isOnTop && <g className="y--axis" ref={yAxisRef}/>}
            {!isOnTop && (
                <text className="axis-title left" transform={`translate(-60,${yScale.range()[0] / 2})rotate(-90)`}>
                    {nameAccessor(selectedDataset)}
                </text>
            )}
            <g>
                {!!stoppedDate && (
                    <foreignObject y={offset} x={width - 188} width="200" height={height}>
                        <div className={styles.alert}>
                            <div className={styles.stoppedDate}>
                                <Warning />
                                <h4>STOPPED REPORTING: <span>{stoppedDate}</span></h4>
                            </div>
                            <div className={styles.line} style={{ height }}>
                                <span></span>
                            </div>
                        </div>
                    </foreignObject>
                )}
            </g>
        </g>
    );
};

const defaultProps = {
    xScale: null,
    yScale: null,
    height: 0,
    width: 0,
    xScaleTickFormat: null,
    yScaleTickFormat: null,
    isOnTop: false,
    offset: 0,
};

Axes.defaultProps = defaultProps;

export default Axes;
