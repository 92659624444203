import React from "react";
import styles from "./FOEArticle.module.scss";
import Blog from "components/layout/Blog";
import { getArticle } from "data/util";

const FOEArticle = (props) => {
  const article = getArticle(props?.location.pathname);
  return (
    <div className={styles.container}>
      <Blog article={article} {...props} />
    </div>
  );
};

export default FOEArticle;
