import React, { FC } from "react";
import styles from "./Tab.module.scss";
import cx from "classnames";
import { TFilterOption } from "../../util";

export type TTabProps = {
  option: TFilterOption;
  onChange: (option: TFilterOption, currentlyActive: boolean) => void;
  isActive?: boolean;
  isNew?: boolean;
  className?: string;
};

const Tab: FC<TTabProps> = ({
  option,
  onChange,
  isActive,
  isNew,
  className,
}) => {
  const handleClick = () => onChange?.(option, isActive);
  return (
    <div
      className={cx(styles.base, isActive? styles.active : styles.inactive, className)}
      onClick={handleClick}
    >
      {isNew && <div className={styles.newTag}>New</div>}
      <button>{option.label}</button>
      {/* <button>
        //The labelPlaceholder is just a space filler not intended to be visible
        <span className={styles.labelPlaceholder}>{option.label}</span>
        <span className={styles.label}>{option.label}</span>
      </button> */}
    </div>
  );
};

export default Tab;
