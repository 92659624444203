import React from "react";
import styles from "./InternationalTable.module.scss";
import TFormat from "components/common/TFormat";
import cx from "classnames";
import { formatTableCountryData } from "components/common/TFormat/util";
import { percentFullVacLabels } from "../../VaccineDataVisualization/constants";

const InternationalTable = ({
  useTable = "default",
  regionNameLinkStyle,
  limit = Infinity,
  hideViewAll = false,
  customSortConfig,
  mobileOptions = { useRegionCodeType: null, useNameShortening: true },
  className = "",
  overflowClassName,
  data,
}) => {
  const { regionList, formattedData, noPageList } = formatTableCountryData(
    data
  );
  const dataSet = {
    default: {
      title: "Totals By Population",
      tableData: {
        data: formattedData,
        tableColumns: {
          regionName: "Country Name",
          doses_admin: "Doses\nAdministered",
          raw_full_vac: "People Receiving\nat Least One Dose",
          percent_full_vac: percentFullVacLabels.country,
        },
      },
    },
    blade: {
      title: "",
      tableData: {
        data: formattedData,
        tableColumns: {
          regionName: "Country",
          doses_admin: "Total Doses\nAdministered",
          percent_full_vac: percentFullVacLabels.country,
        },
      },
    },
  };

  const useTableData = dataSet[useTable]?.tableData;
  return (
    <div className={cx(styles.base, className)}>
      <TFormat
        tableData={useTableData?.data}
        tableColumns={useTableData?.tableColumns}
        regionList={regionList}
        regionNoLinkList={noPageList}
        customSortConfig={customSortConfig}
        mobileOptions={mobileOptions}
        path="/region/"
        pageSection="#vaccine-tracker"
        rowLimit={limit}
        regionNameLinkStyle={regionNameLinkStyle}
        hideViewAll={hideViewAll}
        overflowClassName={overflowClassName}
      />
    </div>
  );
};

export default InternationalTable;
