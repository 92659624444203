import React from "react";
import HFormat from "components/common/HFormat";
import styles from "./PositivityHierarchyModal.module.scss";
import InfoModalWrapper from "../InfoModalWrapper";
import TooltipIcon from "components/common/TooltipBadge/TooltipIcon";
import { ReactComponent as HighlightSquares } from "../Graphics/highlighted_squares.svg";
import { ReactComponent as NoPublicReport } from "../Graphics/no_report.svg";

const percentPositiveContent = [
  {
    image: "",
    content: (
      <p>
        <strong>7-day moving average</strong> — Green lines represent rates
        below 5%; Red lines represent rates above 5%.s
      </p>
    ),
  },
  {
    image: "",
    content: (
      <p>
        The highlighted square indicates the CRC-preferred calculation that can
        be made based on a state’s publicly reported data.
      </p>
    ),
  },
  {
    image: "",
    content: (
      <p>The state does not publicly report the variables for this approach.</p>
    ),
  },
];

const icons = [
  {
    type: "positive-specimens-total-specimens",
    content: "#1: Positive Specimen / TOTAL SPECIMEN",
  },
  {
    type: "positive-specimens-total-encounters",
    content: "#2: Positive Specimen / TOTAL ENCOUNTERS",
  },
  {
    type: "positive-people-total-people",
    content: "#3: Positive People / TOTAL PEOPLE",
  },
  {
    type: "positive-people-total-encounters",
    content: "#4: Positive PEOPLE / TOTAL ENCOUNTERS",
  },
  {
    type: "positive-people-total-specimens",
    content: "#5: Positive PEOPLE / TOTAL SPECIMENS",
  },
];

const PositivityHierarchyModal = () => {
  return (
    <InfoModalWrapper title="How to read this graphic">
      <div className={styles.main}>
        <div className={styles.percPositiveContainer}>
          <HFormat size={3} noMargin>
            Percent Positive
          </HFormat>
          <div className={styles.row}>
            <div className={styles.columnHead}>
              <img
                src="/testing-tracker/images/how-to-read-testing-tracker-perc-positive.png"
                alt="how-to"
              />
              {percentPositiveContent[0].content}
            </div>
            <div className={styles.infoSet}>
              <div className={styles.highlightImageContainer}>
                <HighlightSquares className={styles.highlightImage} />
              </div>
              {percentPositiveContent[1].content}
            </div>
            <div className={styles.infoSet}>
              <NoPublicReport className={styles.noReportIcon} />
              {percentPositiveContent[2].content}
            </div>
          </div>
        </div>

        <div className={styles.fiveApproachesContainer}>
          <h3 className={styles.header}>
            Five Approaches to Positivity Calculations
          </h3>
          <div className={styles.row}>
            <div className={styles.detailsContainer}>
              <p>
                Different positivity calculations are possible based on each
                state’s publicly-available testing data. The five possible
                approaches are presented in order of the most informative (#1)
                to the least informative (#5).
              </p>
            </div>
            <div className={styles.toolTipIcons}>
              {icons.map((tooltip, i) => (
                <div className={styles.iconContainer} key={i}>
                  <TooltipIcon
                    className={styles.icon}
                    key={i}
                    type={tooltip.type}
                  />
                  <div className={styles.tooltipContent}>{tooltip.content}</div>
                </div>
              ))}
            </div>
            <div className={styles.definitionsContainer}>
              <p>
                <strong>Specimens:</strong> Cumulative number of completed PCR
                tests (or specimens tested) or other approved nucleic acid
                amplification test (NAAT)
              </p>
              <p>
                <strong>Encounters:</strong> People tested per day (or other
                specified time interval) via PCR testing or other approved
                nucleic acid amplification test (NAAT)
              </p>
              <p>
                <strong>People:</strong> Unique people tested at least once via
                PCR testing or other approved nucleic acid amplification test
                (NAAT)
              </p>
            </div>
          </div>
        </div>
      </div>
    </InfoModalWrapper>
  );
};
export default PositivityHierarchyModal;
