import React from 'react';
import clsx from 'clsx';

const HiddenCell = ({ cell }) => {
  return (
    <div className={clsx('map__cell', cell.id && 'is-state')} style={cell.style}>
      <span className="map__abbr">{cell.id}</span>
    </div>
  );
};
HiddenCell.defaultProps = {
  cell: {},
};
export default HiddenCell;
