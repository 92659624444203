import React, { useState } from "react";
import styles from "./SunsetBanner.module.scss";
import { ReactComponent as ChevronDownIcon } from "images/icons/button/chevron-down_2.svg";
import { ReactComponent as CrossIcon } from "images/icons/cross.svg";
import classNames from "classnames/bind";
import Button from "components/common/Button";
import { limitCharacters } from "util/helper";
import { useLocation } from "react-router-dom";

const cx = classNames.bind(styles);

type TSunsetBannerProps = {
  intro: string;
  date: string;
  details: string;
  closeButton?: boolean;
  className?: string;
};

const excludedRoutes = [
  "/from-our-experts/",
  "/pandemic-data-initiative/",
  "/data/disparity-explorer/",
  "/data/disparity-explorer",
  "/data/racial-data-transparency/",
  "/data/racial-data-transparency",
  "/testing",
  "/testing/",
];

const SunsetBanner = ({
  closeButton = true,
  intro,
  date,
  details,
  className,
}: TSunsetBannerProps) => {
  const location = useLocation();
  const [expand, setExpanded] = useState(false);
  const [active, setActive] = useState(true);

  const compressedCharacterLimit = 68;

  const handleClose = () => {
    setActive(false);
  };
  // console.log("location.pathname", location.pathname);

  const shouldExclude = excludedRoutes.some(
    (route) =>
      location.pathname.startsWith(route) || location.pathname === route
  );
  if (!active || shouldExclude) return null;

  return (
    <div className={cx(styles.base, className)}>
      <div className={cx(styles.innerContainer)}>
        <div className={cx(styles.contentBlock)}>
          <div className={cx(styles.introBlock, expand && styles.expand)}>
            {intro && <h2 className={styles.intro}>{intro}</h2>}

            <div className={styles.dateBlock}>
              {date && <div className={styles.date}>{date}</div>}

              <div className={cx(styles.buttonBlock, styles.mobile)}>
                <button
                  className={styles.button}
                  onClick={() => setExpanded(!expand)}
                >
                  <div>{expand ? "Show Less" : "Show More"}</div>
                  <ChevronDownIcon
                    className={cx(styles.chevronDown, expand && styles.expand)}
                  />
                </button>
              </div>
            </div>
          </div>

          <div className={cx(styles.detailsBlock, expand && styles.expand)}>
            {details && (
              <p className={styles.details}>
                {expand
                  ? details
                  : limitCharacters(details, compressedCharacterLimit * 1.01)}
              </p>
            )}
          </div>
        </div>

        <div className={cx(styles.buttonBlock, styles.right)}>
          <button
            className={styles.button}
            onClick={() => setExpanded(!expand)}
          >
            <div>{expand ? "Show Less" : "Show More"}</div>
            <ChevronDownIcon
              className={cx(styles.chevronDown, expand && styles.expand)}
            />
          </button>
        </div>

        {closeButton && (
          <div className={styles.closeButtonBlock}>
            <Button
              className={styles.closeButton}
              buttonStyle="plain"
              onClick={handleClose}
              tabIndex={0}
            >
              <CrossIcon className={styles.crossIcon} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SunsetBanner;
