import React, { useRef, useEffect, useState } from "react";
// import {useDimensions} from '../../../../DataCenter/charts/Shared/utils';
// import {zoom as d3_zoom, zoomIdentity} from 'd3-zoom';
import { max } from "d3-array";
import { scaleBand, scaleLinear } from "d3-scale";
import { format } from "d3-format";
import Axes from "./Axes";
import Lollipops from "./Lollipops";
import Legend from "./Legend";
import { findStateCode, findCountryCode, states } from "data/geo";
import styles from "./VaccineDataGraph.module.scss";
import { request } from "redux/util/fetch";

const Graph = ({ dataSet, field, data, mobile, svgWidth }) => {
  const [loading, setLoading] = useState(false);
  const [threshold, setThreshold] = useState(null);

  useEffect(() => {
    if (field.value === "percent_full_vac") {
      if (dataSet.name === "International") {
        const worldData = data.filter((d) => d.country === "World")[0];

        if (worldData?.data) {
          setThreshold(
            data.filter((d) => d.country === "World")[0].data.percent_full_vac /
              100
          );
        }
      } else {
        setLoading(true);
        request(
          "https://jhucoronavirus.azureedge.net/api/v3/vaccines/global.json"
        ).then((response) => {
          setThreshold(
            response.filter((d) => d.country === "United States")[0]?.data
              .percent_full_vac / 100
          );
          setLoading(false);
        });
      }
    }
  }, [dataSet, field, data]);

  const filteredData =
    dataSet.name === "International"
      ? data.filter(
          (d) => !(d.country === "World" || d.country === "European Union")
        )
      : data.filter((d) => states.includes(d.state));

  const graphdata = filteredData.map((p) => {
    const place = Object.assign({}, p);

    if (field.value === "percent_full_vac") {
      place.data = {
        ...place.data,
        sorted:
          place.data[field.value] === "Not Available"
            ? 0
            : place.data[field.value] / 100,
      };
    } else {
      place.data = {
        ...place.data,
        sorted:
          place.data[field.value] === "Not Available"
            ? 0
            : place.data[field.value],
      };
    }

    if (mobile) {
      if (dataSet.key === "state") {
        place[dataSet.key] = findStateCode(place[dataSet.key]);
      } else {
        const missingCodes = {
          Bermuda: "BM",
          "Cayman Islands": "KY",
          Gibraltar: "GI",
          Greenland: "GL",
          "Hong Kong": "HK",
          "Isle of Man": "IM",
          "Turks and Caicos Islands": "TC",
        };
        const code =
          findCountryCode(place[dataSet.key]) ||
          missingCodes[place[dataSet.key]];
        if (code) {
          place[dataSet.key] = code;
        }
      }
    }

    return place;
  });

  graphdata.sort((a, b) => b.data.sorted - a.data.sorted);

  const groupRef = useRef(null);
  const innerHeight = graphdata.length * 20;
  //   height = limit === "Infinity" ? data.length * 30 : limit * 30;
  const margin = {
    left: mobile ? 50 : 175,
    right: mobile ? 80 : 175, // 45,
    top: mobile && field.value === "percent_full_vac" ? 220 : mobile ? 90 : 40,
    bottom: 15,
  };
  const innerWidth = svgWidth - margin.left - margin.right;
  const height = innerHeight + margin.top + margin.bottom;

  // if (sort) {
  // sort.direction === "descending"
  //   ? b.data.sorted - a.data.sorted
  //   : a.data.sorted - b.data.sorted
  // } else {
  //   data.forEach(
  //     (d) =>
  //       (d.data.sorted =
  //         d.data.percent_full_vac === "Not Available"
  //           ? 0
  //           : d.data.percent_full_vac)
  //   );
  // }
  //
  // if (limit !== "Infinity") {
  //   data = data.slice(0, limit);
  // }

  const yScale = scaleBand()
    .range([0, innerHeight])
    .domain(graphdata.map((d) => d[dataSet.key]));

  const xScale = scaleLinear()
    .range([0, innerWidth])
    .domain(
      field.value === "percent_full_vac"
        ? [0, max(graphdata, (d) => d.data[field.value]) / 100]
        : [0, max(graphdata, (d) => d.data[field.value])]
    )
    .nice();

  const xScaleTickFormat =
    field.value === "percent_full_vac"
      ? max(graphdata, (d) => d.data[field.value]) < 12
        ? ".1%"
        : ".0%"
      : ".2s";
  const annotationFormat = field.value === "percent_full_vac" ? ".2%" : ",.0f";

  return (
    <>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <svg width={svgWidth} height={height} className={styles.graph}>
          {mobile ? (
            <text style={{ textAnchor: "middle", fontSize: "100%" }}>
              <tspan x={innerWidth / 2 + margin.right} y={10}>
                {dataSet.name}
              </tspan>
              <tspan x={innerWidth / 2 + margin.right} y={30}>
                {field.label}
              </tspan>
              <tspan
                x={innerWidth / 2 + margin.right}
                y={50}
              >{`by ${dataSet.key.charAt(0).toUpperCase()}${dataSet.key.slice(
                1
              )}`}</tspan>
            </text>
          ) : (
            <text
              x={svgWidth / 2}
              y={margin.top / 2 - 6}
              style={{ textAnchor: "middle", fontSize: "70%" }}
            >
              {`${dataSet.name} ${field.label} by ${dataSet.key
                .charAt(0)
                .toUpperCase()}${dataSet.key.slice(1)}`}
            </text>
          )}
          {field.value === "percent_full_vac" && (
            <Legend
              datakey={dataSet.key}
              svgWidth={svgWidth}
              gDims={[innerWidth, innerHeight]}
              margin={margin}
              mobile={mobile}
            />
          )}
          {field.value === "percent_full_vac" && dataSet.name === "US States" && (
            <text
              x={xScale(threshold) + margin.left}
              y={mobile ? margin.top - 28 : height}
              style={{
                textAnchor: `${mobile ? "start" : "end"}`,
                fontSize: `70%`,
              }}
            >
              {`Average: ${format(".2%")(threshold)}`}
            </text>
          )}
          <g
            transform={`translate(${margin.left},${margin.top})`}
            ref={groupRef}
          >
            <Axes
              xScale={xScale}
              xScaleTickFormat={xScaleTickFormat}
              xTicks={5}
              yScale={yScale}
              height={innerHeight}
              width={innerWidth}
              data={graphdata}
              datakey={dataSet.key}
              fieldkey={field.value}
            />
            <line
              x1={xScale(graphdata[0].data.sorted)}
              y1={yScale(0)}
              x2={xScale(graphdata[0].data.sorted)}
              y2={innerHeight}
              className={styles.maxLine}
            />
            <g>
              {graphdata
                .filter((d) => d.data.sorted > 0)
                .map((d, i) => {
                  return (
                    <Lollipops
                      key={`data-point-${i}`}
                      r={6}
                      y={yScale(d[dataSet.key]) + yScale.bandwidth() / 2}
                      x1={xScale(0)}
                      x2={xScale(d.data.sorted)}
                      labelFormat={annotationFormat}
                      labelText={d.data.sorted}
                      first={i === 0 ? true : false}
                      threshold={
                        threshold
                          ? d.data.sorted >= threshold
                            ? "above"
                            : "below"
                          : null
                      }
                      mobile={mobile}
                      region={d[dataSet.key]}
                      regionType={dataSet.key}
                    />
                  );
                })}
            </g>
            {field.value === "percent_full_vac" &&
              dataSet.name === "US States" && (
                <line
                  x1={xScale(threshold)}
                  y1={0}
                  x2={xScale(threshold)}
                  y2={innerHeight}
                  className={styles.thresholdLine}
                />
              )}
          </g>
        </svg>
      )}
    </>
  );
};

export default Graph;
