const basePath = "/images/sunset/headshots/";
const headshots = [
  `${basePath}AlisedaAlonso_Angel.jpg`,
  `${basePath}Duva-Joseph.jpg`,
  `${basePath}Goyea-Tamara.jpg`,
  `${basePath}Kennell-Lauren.jpg`,
  `${basePath}Medina-Santiago-Kevin.jpg`,
  `${basePath}Poplawski-Joshua.jpg`,
  `${basePath}Shah-Krushi.jpg`,
  `${basePath}Asher-Laura.jpg`,
  `${basePath}Emily_Pond_headshot.jpg`,
  `${basePath}Graff-Phil.jpg`,
  `${basePath}LaBarge-Janice.jpg`,
  `${basePath}Meiners-Cody.jpg`,
  `${basePath}Porterfield-Headshot.jpg`,
  `${basePath}Smallwood-Mitch.jpg`,
  `${basePath}Bhatnagar-Sarthak.jpg`,
  `${basePath}Ensheng_Dong.jpg`,
  `${basePath}Gray_Miriam_McKinney.jpg`,
  `${basePath}La-Cholter-William.jpg`,
  `${basePath}Moore-Michael.jpg`,
  `${basePath}Prata-Sarah.jpg`,
  `${basePath}Stewart-Miles.jpg`,
  `${basePath}Bolt-Evan.jpg`,
  `${basePath}Evelo-Alexander.jpg`,
  `${basePath}Gupta-Nishant.jpg`,
  `${basePath}Lana-Milman-Headshot.jpg`,
  `${basePath}Murphy-Laura.jpg`,
  `${basePath}Price-Ian.jpg`,
  `${basePath}Talwar-Sana.jpg`,
  `${basePath}Brunner-Nick.jpg`,
  `${basePath}Fardin_Ganjkhanloo.jpg`,
  `${basePath}Gus_Sentementes.jpg`,
  `${basePath}Lau-Ryan.jpg`,
  `${basePath}Nicholas-Paul.jpg`,
  `${basePath}PromiseM-Headshot.jpg`,
  `${basePath}Vaughan_Mary_Conway.jpg`,
  `${basePath}Camacho-Emily.jpg`,
  `${basePath}Farzin-Ahmadi-Headshot.jpg`,
  `${basePath}Hongru_Du_cropped.jpg`,
  `${basePath}Lee_Adam.jpg`,
  `${basePath}Olson-John.jpg`,
  `${basePath}Raimi-Zlatic-Daniel.jpg`,
  `${basePath}Watenpool-Christopher.jpg`,
  `${basePath}Cassamajor-Kailande.jpg`,
  `${basePath}Galante-Amanda.jpg`,
  `${basePath}Hurley-Cathy.jpg`,
  `${basePath}Leeds-Melissa.jpg`,
  `${basePath}Oren-Tirschwell-Headshot.jpg`,
  `${basePath}Ramachandran-Raghav.jpg`,
  `${basePath}Weiss-Yael.jpg`,
  `${basePath}Colella-Teresa.jpg`,
  `${basePath}Garcia-Beatrice.jpg`,
  `${basePath}JasonWilliams.jpg`,
  `${basePath}Lewis-Sheri.jpg`,
  `${basePath}Pang-Louis.jpg`,
  `${basePath}Ratcliff-Jeremy.jpg`,
  `${basePath}Wesley-Jordan.jpg`,
  `${basePath}Collins-Marissa.jpg`,
  `${basePath}Gehr-Elaine.jpg`,
  `${basePath}Johnson-Breanna.jpg`,
  `${basePath}Mahmood-Amina.jpg`,
  `${basePath}Peterson-Joseph.jpg`,
  `${basePath}Ravitz-Alan.jpg`,
  `${basePath}Wilson-Shelby.jpg`,
  `${basePath}Cooley-Samantha.jpg`,
  `${basePath}Gion-Timothy.jpg`,
  `${basePath}Kalikian-Arman.jpg`,
  `${basePath}Marianne_von_Nordeck.jpg`,
  `${basePath}Pikas-Christina.jpg`,
  `${basePath}Reina_Murray.jpg`,
  `${basePath}Xiaoxue_Headshot.jpg`,
  `${basePath}Doug_Donovan.jpg`,
  `${basePath}GovEx_MegBurke.jpg`,
  `${basePath}Katz-Aaron.jpg`,
  `${basePath}McCarty-Cory.jpg`,
  `${basePath}Piorkowski-John.jpg`,
  `${basePath}SaraBdL_headshot.jpg`,
  `${basePath}Zhao-Grant.jpg`,
  `${basePath}Tim-Ng.jpg`,
  `${basePath}group-image.jpg`,
  `${basePath}Taylor-Martin.jpg`,
];

export default headshots;
