import React, { useState } from "react";
import Page from "components/layout/Page";
import { getPage } from "data/util";
import { getArticleCardsBySection } from "components/layout/ArticleCard/utils";
import ArticleFeed from "components/content/Vaccine/ArticleFeed";
import styles from "./ResearchPapersHome.module.scss";
import { getNormalizedPathname } from "util/helper";

const ResearchPapersHome = ({ location }) => {
  const path = getNormalizedPathname(location.pathname);
  const page = getPage(path);
  const [articles] = useState(getArticleCardsBySection("Research Papers"));
  return (
    <Page page={page} wide>
      <div className={styles.container}>
        <ArticleFeed
          classNames={styles.articleFeed}
          articles={articles}
          limit={8}
          blade={false}
          hasMajor={false}
          columnLayouts={{
            default: [[12]] as any,
            topCards: null,
          }}
          defaultCardProps={
            ({
              vertical: true,
              containImage: false,
              major: false,
              imageSrc: null,
            } as unknown) as any
          }
        />
      </div>
    </Page>
  );
};

export default ResearchPapersHome;
