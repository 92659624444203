import React, { FC, useState } from "react";
import styles from "./PositivityDropdown.module.scss";
import cx from "classnames";
import Option from "./Option";
import useSingleSelect, { TFilterOption } from "util/hooks/useSingleSelect";
import DropdownOverlay from "../DropdownOverlay/DropdownOverlay";
import TooltipIcon, {
  TTooltipIconProps,
} from "components/common/TooltipBadge/TooltipIcon";

export type TPositivityDropdownProps = {
  options?: string[] | TFilterOption[];
  currentSelect?: TFilterOption["value"] | number;
  placeholder?: string;
  onChange?: (selected: TFilterOption) => void;
  allOption?: boolean;
  children?: any;
  className?: string;
  optionClassName?: string;
};

const icons: TTooltipIconProps["type"][] = [
  "positive-specimens-total-specimens",
  "positive-specimens-total-encounters",
  "positive-people-total-people",
  "positive-people-total-encounters",
  "positive-people-total-specimens",
];

const selectOptions: TFilterOption[] = [
  {
    label: "Viral Positive Specimens/\n Viral Total Specimens",
    value: "0",
    data: {
      content:
        "The number of positive molecular (PCR) tests divided by the total number of molecular tests.",
      icon: icons[0],
      href: "/",
    },
  },
  {
    label: "Viral Positive Specimens/\n Viral Total Encounters",
    value: "1",
    data: {
      content:
        "The number of positive molecular (PCR) tests divided by the total number of molecular tests given within a specified timeframe.*",
      icon: icons[1],
      href: "/",
    },
  },
  {
    label: "Viral Positive People/\n Viral Total People",
    value: "2",
    data: {
      content:
        "The number of people who test positive with molecular (PCR) tests divided by the total number of people tested with molecular tests.",
      icon: icons[2],
      href: "/",
    },
  },
  {
    label: "Viral Positive People/\n Viral Total Specimens",
    value: "3",
    data: {
      content:
        "The number of people who test positive with molecular (PCR) tests divided by total number of molecular (PCR) tests.",
      icon: icons[3],
      href: "/",
    },
  },
  {
    label: "Viral Positive People /\n Viral Total Encounters",
    value: "4",
    data: {
      content:
        "The number of people who test positive with molecular (PCR) tests divided by total number of molecular (PCR) tests given within a specified timeframe.*",
      icon: icons[4],
      href: "/",
      defaultMetric: true,
    },
  },
];

const PositivityDropdown: FC<TPositivityDropdownProps> = ({
  allOption,
  currentSelect = allOption ? null : 0,
  options = selectOptions,
  placeholder,
  onChange,
  className,
  optionClassName,
}) => {
  const [isOpen, setOpenState] = useState(false);
  const {
    allOptions,
    requestSelect,
    selected,
    // placeholderText,
  } = useSingleSelect(options, {
    allOption,
    currentSelect,
    placeholder,
    onSelect: (selectedOption) => {
      setOpenState(false);
      if (onChange) onChange(selectedOption);
    },
  });

  return (
    <div
      className={cx(
        {
          [styles.base]: true,
          [styles.open]: isOpen,
        },
        className
      )}
    >
      <div
        className={cx({
          [styles.selectContainer]: true,
          [styles.open]: isOpen,
        })}
      >
        <div
          className={styles.selectButton}
          onClick={() => setOpenState(!isOpen)}
        >
          <div className={styles.selectDisplay}>
            {/* {placeholderText} */}
            Positivity Calculation
          </div>
          {selected?.data?.icon && (
            <div className={styles.icon}>
              <TooltipIcon type={selected?.data?.icon} />
            </div>
          )}
          <div className={cx(styles.triangleIcon, isOpen ? styles.open : "")} />
        </div>
        <div className={styles.optionsContainer}>
          {allOptions
            .sort(
              (a, b) =>
                ((b.value === selected?.value) as any) -
                ((a.value === selected?.value) as any)
            )
            .map((option, i) => (
              <Option
                key={`select-option#${option.value}#${i}`}
                className={optionClassName}
                option={option}
                onClick={requestSelect}
                isActive={option.value === selected?.value}
              />
            ))}
        </div>
      </div>
      <DropdownOverlay isOpen={isOpen} onClick={() => setOpenState(false)} />
    </div>
  );
};

export default PositivityDropdown;
