/**
 * Simple hash function to return positive integers
 */
function hash(input) {
  // Turn non-string inputs into strings
  let str;
  // strings are easy
  if (typeof input === 'string') str = input;
  // JSON-encode objects/arrays
  // This comes before a toString check so that we can avoid converting all
  // objects to the uniform [object Object] representation that toString yields
  else if (typeof input === 'object') {
    try {
      str = JSON.stringify(input);
    } catch (e) {
      str = 'unconvertible object';
    }
  // Non-objects that can be converted to strings should be
  } else if (input?.toString) str = input.toString();
  // Fall back to empty string for everything else
  else str = '';

  // Hash the string. adapted from https://stackoverflow.com/a/34842797/4414003
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) >>> 0,
      0
    );
}

/**
 * Get a placeholder image URL to go with a certain input “seed” string,
 * ensuring constant inputs yield consistent outputs.
 */
export default function getPlaceholderImage(seed) {
  const images = new Array(15)
    .fill(null)
    .map((_, i) => `/images/virus-placeholders/covid-${i + 1}.jpg`);

  // Try a few methods to get an integer from the passed seed
  let intSeed;
  if (typeof seed === 'number') intSeed = Math.floor(seed);
  else {
    try {
      intSeed = hash(JSON.stringify(seed));
    } catch (e) {
      if (seed.toString) intSeed = hash(seed.toString);
      else intSeed = 0;
    }
  }

  return images[intSeed % images.length];
}
