import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import MediaQuery from "react-responsive";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./MortalityRates.module.scss";
import SideNarrative from "components/layout/SideNarrative";
import MortalityTable from "./MortalityTable";
import { DataTimeFetched } from "components/common/DataMeta";
import EmbedFrame from "components/common/EmbedFrame";
import { static_asset_path, useFetch } from "util/hooks/useFetch";
import {
  getMortalityDataset,
  mortalityTableDataURL,
} from "data/data-viz/mortality-rates";

const MortalityRates = (props) => {
  const page = getPage(props.location.pathname);
  const { content_blocks } = page;

  const [tableData, isLoading] = useFetch(mortalityTableDataURL);
  let processedData;
  if (!isLoading) {
    processedData = getMortalityDataset(tableData);
  }

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "mortality-rates",
    });
  }, []);

  return (
    <SideNarrative
      narrative={
        <>
          <DataTimeFetched
            URL={"https://jhucoronavirus.azureedge.net/data/ts/mortality.json"}
          />

          {/* TODO: h4's in this content should be h2's */}
          <ReactMarkdown
            source={
              content_blocks["how-does-mortality-differ-across-countries"]
                .content
            }
          />
        </>
      }
    >
      <div className={styles.container}>
        <ReactMarkdown
          source={
            content_blocks["mortality-in-the-most-affected-countries"].content
          }
        />
        <div id={styles.chartA} className={styles.plotCarriage}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}mortality_top10_360.html`}
              width={360}
              height={355}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}mortality_top10_720.html`}
              width={720}
              height={500}
              resize={false}
            />
          </MediaQuery>
        </div>
        <ReactMarkdown source={content_blocks["worldwide-mortality"].content} />
        <div id={styles.chartB} className={styles.plotCarriage}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}mortality_all_360.html`}
              width={360}
              height={275}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}mortality_all_720.html`}
              width={720}
              height={450}
              resize={false}
            />
          </MediaQuery>
        </div>
        <br />
        <br />
        {!isLoading && !!processedData && (
          <MortalityTable tableData={processedData} />
        )}
      </div>
    </SideNarrative>
  );
};

export default MortalityRates;
