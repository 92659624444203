import React from "react";
import styles from "./PDIHome.module.scss";
import Page from "components/layout/Page";
import Button from "components/common/Button";
import { getPage } from "data/util";
import classNames from "classnames/bind";
import ArticleCard from "components/layout/ArticleCard";
import ColumnLayout from "components/layout/ColumnLayout";
import questionLists from "../PDIFAQ/questions.json";
import PDIFeed from "../PDIFeed";
import { getArticleCardsByVerticals } from "components/layout/ArticleCard/utils";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";

export const pdiBasePath = "/pandemic-data-initiative";

const { questions: faqs } = questionLists[0];
const cx = classNames.bind(styles);

const PDIHome = ({ location }) => {
  const pageData = getPage(location.pathname);
  const faqBasePath = `${pdiBasePath}/pandemic-data-faq`;
  const contents = getArticleCardsByVerticals(["pandemic-data-initiative"], {
    overrides: {},
    limit: Infinity,
    authorsInBody: false,
    sectionToCaption: true,
  }).map((card) => ({
    ...card,
    date: "",
    new: card.new,
  }));

  // const pdiEvent = getFilteredContent({ types: ["webcasts"] }).filter(
  //   (content) => content.slug === "pandemic-data-initiative-expert-forum"
  // )?.[0];

  return (
    <Page pageData={pageData} wide>
      <div className={cx("container")}>
        {/* {pdiEvent && (
          <>
            <h2>Expert Forum</h2>
            <div className={styles.topBlade}>
              <ArticleCard
                className={cx(styles.topCard, styles.articleReverse)}
                key={pdiEvent.slug}
                {...contentToCard(pdiEvent)}
                vertical={false}
                major={true}
                containImage={true}
              />
            </div>
          </>
        )} */}

        <h2>Pandemic Data Outlook</h2>
        <div className={styles.topBlade}>
          <ArticleCard
            className={styles.topCard}
            key={"pandemic-data-weekly"}
            {...contents[0]}
            vertical={false}
            major={true}
            containImage={true}
          />
        </div>

        <div className={cx(styles.topBlade, styles.blade)}>
          <ArticleGridLayouts.Flexible articles={contents.slice(1, 3)} />
        </div>

        <div className={styles.blade}>
          <PDIFeed articles={contents.slice(3)} limit={4} />
        </div>

        <br />
        <br />

        <h2>QUESTIONS ABOUT DATA STANDARDS</h2>
        <ColumnLayout layout={[[3, 3, 3, 3], [6, 6], [12]]}>
          {faqs.slice(0, 4).map(({ slug, question }) => (
            <Button
              key={slug}
              className={cx("faq-button")}
              buttonStyle="plain"
              icon="right"
              href={`${faqBasePath}/${questionLists[0].slug}#${slug}`}
            >
              {question}
            </Button>
          ))}
        </ColumnLayout>
        <Button
          buttonStyle="bordered"
          icon="right"
          href={`${faqBasePath}/${questionLists[0].slug}`}
          style={{ paddingTop: "2px", marginTop: "1rem" }}
        >
          View all
        </Button>

        <br />
        <br />
        <br />
        <br />
      </div>
    </Page>
  );
};

export default PDIHome;
