import Button from "components/common/Button";
import cx from "classnames";
import React from "react";
import styles from "./CompareButton.module.scss";
import PropTypes from "prop-types";

function CompareButton({
  title,
  caption,
  direction,
  light,
  className,
  onClick,
}) {
  const iconLeft = direction === "left";
  return (
    <Button
      className={cx(styles.base, {
        [styles.light]: light,
        [styles.left]: iconLeft,
      })}
      shape="square"
      icon={null}
      onClick={onClick}
    >
      <div className={styles.textContainer}>
        <p>{title}</p>
        <p>{caption}</p>
      </div>
      {!!direction && (
        <span className={`${styles.icon} ${iconLeft ? styles.left : null}`}>
          {iconLeft ? "<" : ">"}
        </span>
      )}
    </Button>
  );
}

CompareButton.propTypes = {
  title: PropTypes.string,
  caption: PropTypes.string,
  direction: PropTypes.oneOf(["right", "left", null]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CompareButton.defaultProps = {
  direction: "right",
};

export default CompareButton;
