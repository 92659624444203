import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { color } from "d3-color";

const Bar = ({x, y, height, width, fill, tippyContent, regionLink}) => {
  const history = useHistory();
  const [hover, setHover] = useState(false);

  return (
    <Tippy content={tippyContent} maxWidth={200} theme={'light'} placement={'auto'}>
      <rect
        x={x}
        y={y}
        height={height}
        width={width}
        fill={hover ? color(fill).darker() : fill}
        onClick={() => history.push(regionLink)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </Tippy>
  )
}

export default Bar
