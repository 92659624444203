import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomSelect from '../../../Shared/CustomSelect';
import {updateSelectedDemographicLayer} from "../../../../../../redux/modules/global";

const DemographicLayerSelect = ({selectedDemographicLayer}) => {
    const dispatch = useDispatch();
    const demographicLayers = useSelector(state => state.equityAccessToTesting.demographicLayers);
    const updateDemographicLayer = useCallback(id => dispatch(updateSelectedDemographicLayer(id)), [dispatch]);

    return (
        <div className="demographic__layer__select">
            <CustomSelect
                items={demographicLayers}
                selectedItem={selectedDemographicLayer}
                labels={["Demographic data can be selected from the menu below."]}
                id="demographic-layer-select"
                onChange={updateDemographicLayer}
            />
        </div>
    );
};
export default DemographicLayerSelect;
