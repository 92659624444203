import React from "react";

import ReactMarkdown from "react-markdown";
import { getPage } from "data/util";

import styles from "./LearnHome.module.scss";
import Page from "components/layout/Page";
import ModuleCard from "./ModuleCard";
import modules from "../modules";

const LearnHome = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  return (
    <Page
      header
      interiorHeadMediaSrc={{
        mediaSrc: "https://player.vimeo.com/video/406301037",
      }}
    >
      <div className={styles.container}>
        <div className={styles.intro}>
          <ReactMarkdown
            className={styles.introText}
            source={
              content_blocks["understanding-the-covid-19-pandemic-introduction"]
                .content
            }
          />
          <div className={styles.introRight}>
            <div className={styles.introVideo}>
              <a
                className={styles.button}
                href="https://jhuedu.co1.qualtrics.com/jfe/form/SV_e33ByfYINxBz6Lj"
                target="_blank"
                rel="noopener noreferrer"
              >
                For updates on future learning opportunities{" "}
                <strong>subscribe here</strong>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.modules}>
        <div className={styles.container}>
          <h2>Course Modules:</h2>
          <div className={styles.moduleCards}>
            {modules.map((module) => (
              <ModuleCard key={module.slug} {...module} />
            ))}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default LearnHome;
