import React, { useRef, useEffect, useState } from "react";
import { findStateCode } from "data/geo";
import { select } from "d3-selection";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisLeft, axisBottom, axisTop } from "d3-axis";
import { format } from "d3-format";
import Bar from "./Bar";
import { slugify } from "data/geo";

const AllStatesBar = ({data, svgWidth, annotation}) => {
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [height, setHeight] = useState(450);
  const [outerHeight, setOuterHeight] = useState(null);
  const [margin, setMargin] = useState({ top: 20, right: 90, bottom: 40, left: 80 });
  const [barData, setBarData] = useState(null)
  const svgRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (mobile) {
      setMargin({ top: 80, right: 90, bottom: 20, left: 60 })
    } else {
      setMargin({ top: 20, right: 90, bottom: 40, left: 80 })
    }
  }, [mobile])

  useEffect(() => {
    setHeight(mobile ? data.length * 13 : 450);
  }, [data, mobile]);

  useEffect(() => {
    setOuterHeight(mobile ? height + margin.top + margin.bottom : null);
  }, [mobile, margin, height]);

  useEffect(() => {
    const svg = select(svgRef.current);
    const innerWidth = svgWidth - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom;
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    if (mobile) {
      let sorted = data.sort((a,b)=>b.positivity_7day - a.positivity_7day)
      let xScale = scaleLinear()
        .domain([0, 1])
        .range([0, innerWidth])
        .nice()
      let yScale = scaleBand()
        .domain(sorted.map((s) => s.region))
        .range([innerHeight, 0])
        .padding(0.2)

      let barData = []
      sorted.forEach(d => {
        let content = <>
          {d.region}: {format(".0%")(d.positivity_7day)}<br/>
          {d.positivity_type}
        </>
        barData.push(
          {
            x: xScale(0),
            y: yScale(d.region),
            height: yScale.bandwidth(),
            width: xScale(d.positivity_7day),
            fill: d.positivity_7day <= .05 ? "#719949" : "#f8a833",
            tippyContent: content,
            regionLink: `/region/us/${slugify(d.region)}`
          }
        )
      })
      setBarData(barData)

      let xAxis = (g) => g.call(axisTop(xScale).tickFormat(format(".0%")));
      let yAxis = (g) =>
        g.call(
          axisLeft(yScale).tickFormat((d) => findStateCode(d))
        );

      group
        .append("line")
        .attr("y1", 0)
        .attr("x1", xScale(.05))
        .attr("y2", innerHeight)
        .attr("x2", xScale(.05))
        .attr("stroke", "#719949")
        .attr("stroke-width", 1);
      group
        .append("text")
        .text("5%")
        .attr("x",xScale(.05))
        .attr("y", 30)
        .attr("dx", ".275em")
        .attr("text-anchor", "start")
        .attr("fill", "#719949")
        .style("font-weight", "600");

      group.append("text")
        .text(annotation)
        .attr("x", innerWidth)
        .attr("y", 20)
        .style("fill", "#aaa")
        .style("font-size", "90%")
        .style("text-anchor", "end")
      group.append("text")
        .text("higher than")
        .attr("x", innerWidth)
        .attr("y", 35)
        .style("fill", "#aaa")
        .style("font-size", "90%")
        .style("text-anchor", "end")
      group.append("text")
        .text("recommended")
        .attr("x", innerWidth)
        .attr("y", 50)
        .style("fill", "#aaa")
        .style("font-size", "90%")
        .style("text-anchor", "end")
      group.append("text")
        .text("positivity")
        .attr("x", innerWidth)
        .attr("y", 65)
        .style("fill", "#aaa")
        .style("font-size", "90%")
        .style("text-anchor", "end")

      let yG = group.append("g").call(yAxis);
      let xG = group.append("g").call(xAxis);

      xG.append("text")
        .attr("transform", `translate(${innerWidth / 2},-30)`)
        .attr("text-anchor", "middle")
        .attr("fill", "#aaa")
        .style("font-size", "120%")
        .text("Postivity Percentage (average of the last 7 days)");

      group.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", "#719949")
        .attr("transform", `translate(10, ${innerHeight+15})`)
      group.append("text")
        .text("States with positivity equal to or below 5%")
        .attr("transform",`translate(25,${innerHeight+24})`)
        .style("font-size", "80%")
        .style("font-weight", "300")
      group.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", "#f8a833")
        .attr("transform", `translate(10, ${innerHeight+35})`)
      group.append("text")
        .text("States with positivity above 5%")
        .attr("transform",`translate(25,${innerHeight+44})`)
        .style("font-size", "80%")
        .style("font-weight", "300")

      yG.select("text").attr("style", "font-weight:300");

      xG.selectAll(".tick line").attr("stroke", "#aaa");
      xG.selectAll(".tick text").style("font-size", "80%");

      group.selectAll(".domain").raise().attr("stroke", "#aaa");
    } else {
      let sorted = data.sort((a,b)=>a.positivity_7day - b.positivity_7day)
      let xScale = scaleBand()
        .domain(sorted.map((s) => s.region))
        .range([0, innerWidth])
        .padding(0.45)
      let yScale = scaleLinear()
        .domain([0, 1])
        .range([innerHeight, 0])
        .nice()

      let barData = []
      sorted.forEach(d => {
        let content = <>
          {d.region}: {format(".0%")(d.positivity_7day)}<br/>
          {d.positivity_type}
        </>
        barData.push(
          {
            x: xScale(d.region),
            y: yScale(d.positivity_7day),
            height: yScale(0) - yScale(d.positivity_7day),
            width: xScale.bandwidth(),
            fill: d.positivity_7day <= .05 ? "#719949" : "#f8a833",
            tippyContent: content,
            regionLink: `/region/us/${slugify(d.region)}`
          }
        )
      })
      setBarData(barData)

      let xAxis = (g) =>
        g.call(
          axisBottom(xScale).tickFormat((d) => findStateCode(d))
        );
      let yAxis = (g) => g.call(axisLeft(yScale).tickFormat(format(".0%")));

      group
        .append("line")
        .attr("x1", 0)
        .attr("y1", yScale(.05))
        .attr("x2", innerWidth)
        .attr("y2", yScale(.05))
        .attr("stroke", "#719949")
        .attr("stroke-width", 1);
      group
        .append("text")
        .text("5%")
        .attr("y", yScale(.05))
        .attr("x", innerWidth+25)
        .attr("text-anchor", "end")
        .attr("fill", "#719949");

      let yG = group.append("g").call(yAxis);
      yG.append("text")
        .attr(
          "transform",
          `translate(-${margin.left - 20},${innerHeight / 2}) rotate(270)`
        )
        .attr("text-anchor", "middle")
        .attr("fill", "#aaa")
        .style("font-size", "140%")
        .text("Positivity Percentage (average of the last 7 days)");
      let xG = group
        .append("g")
        .attr("transform", `translate(0,${innerHeight})`)
        .call(xAxis);
      xG.select("text").attr("style", "font-weight:300");

      group.append("text")
        .text(annotation)
        .attr("x", 10)
        .attr("y", 10)
        .style("fill", "#aaa")
        .style("font-size", "90%")
      group.append("text")
        .text("higher than")
        .attr("x", 10)
        .attr("y", 25)
        .style("fill", "#aaa")
        .style("font-size", "90%")
      group.append("text")
        .text("recommended")
        .attr("x", 10)
        .attr("y", 40)
        .style("fill", "#aaa")
        .style("font-size", "90%")
      group.append("text")
        .text("positivity")
        .attr("x", 10)
        .attr("y", 55)
        .style("fill", "#aaa")
        .style("font-size", "90%")

      yG.selectAll(".tick line").attr("stroke", "#aaa");
      group.selectAll(".domain").raise().attr("stroke", "#aaa");

      group.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", "#719949")
        .attr("transform", `translate(10, -20)`)
      group.append("text")
        .text("States with positivity equal to or below 5%")
        .attr("transform",`translate(25, -11)`)
        .style("font-size", "80%")
        .style("font-weight", "300")
      group.append("rect")
        .attr("width", 10)
        .attr("height", 10)
        .attr("fill", "#f8a833")
        .attr("transform", `translate(310, -20)`)
      group.append("text")
        .text("States with positivity above 5%")
        .attr("transform",`translate(325, -11)`)
        .style("font-size", "80%")
        .style("font-weight", "300")
    }

    return () => {
      group.remove();
    };
  }, [svgWidth, mobile, height, margin, annotation, data]);

  return (
    <svg id="positivity-all-states-bar" ref={svgRef} width={svgWidth} height={mobile ? outerHeight : height} >
      <g transform={`translate(${margin.left},${margin.top})`}>
        {barData && barData
          .map((d,i) => {
            return (
              <Bar
                key={`stateBar${i}`}
                x={d.x}
                y={d.y}
                height={d.height}
                width={d.width}
                fill={d.fill}
                tippyContent={d.tippyContent}
                regionLink={d.regionLink}
              />
            )
          })
        }
      </g>
    </svg>
  );
};

export default AllStatesBar;
