import React from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import InfoPopover from '../../InfoPopover';

function TableCellInner({ hasSort, text, sortId, order, orderBy, createSortHandler, infoText }) {
  return hasSort ? (
    <TableSortLabel
      active={orderBy === sortId}
      direction={orderBy === sortId ? order : 'asc'}
      onClick={createSortHandler(sortId)}
      className="table__sort"
    >
      <span>{text}</span>
      <TableCellInfo text={infoText} />
    </TableSortLabel>
  ) : (
    <span>
      <span>{text}</span>
      <TableCellInfo text={infoText} />
    </span>
  );
}

function TableCellInfo({ text }) {
  return !!text && text.length ? <InfoPopover className="table__icon table__icon--info" text={text} /> : '';
}

const StateHeader = props => {
  const { order, orderBy, onRequestSort, HEADER_CELLS } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className="table__head">
      <TableRow className="table__row">
        {HEADER_CELLS.map(({ heading, subHeading, info }, i) => {
          return (
            <TableCell className="table__cell" key={i}>
              <div className="table__heading">
                <TableCellInner
                  text={heading}
                  hasSort={true}
                  sortId={heading}
                  order={order}
                  orderBy={orderBy}
                  createSortHandler={createSortHandler}
                  infoText={info}
                />
              </div>
              <div className="table__sub-heading">
                {subHeading.map((sHeading, k) => {
                  return (
                    <TableCellInner
                      key={k}
                      text={sHeading}
                      hasSort={false}
                      sortId={heading}
                      order={order}
                      orderBy={orderBy}
                      createSortHandler={createSortHandler}
                      infoText={''}
                    />
                  );
                })}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

StateHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

StateHeader.defaultProps = {};
export default StateHeader;
