import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  COLORS,
  COMMA_FORMATTER,
  SINGLE_DECIMAL_FORMATTER,
} from "../../../../../../../Shared/constants";
import Bar from "./Bar";
import styles from "./RacialBreakdown.module.scss";

const useStyles = makeStyles((theme) => ({
  racialBreakdown: {
    paddingRight: "30px",
    "&:last-child": {
      paddingRight: 0,
    },
    display: "flex",
    flexDirection: "column",
    "& h3": {
      color: COLORS.blueDark,
      fontSize: "20px",
    },
    width: "33%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    color: "#56586e",
    fontSize: "16px",
    "& span": {
      fontWeight: "bold",
    },
    "& :first-child": {
      width: "40%",
    },
    marginBottom: "5px",
  },
}));

const racialBreakdownPercentFormatter = (value) => {
  if (value < 1 && value > 0) {
    return SINGLE_DECIMAL_FORMATTER(value);
  }

  return COMMA_FORMATTER(value);
};

const RacialBreakdown = ({ row }) => {
  const classes = useStyles();

  return (
    <div className={classes.racialBreakdown}>
      <h3>{row.label}</h3>
      {row.values.map((value) => {
        // Handle when a value is not available
        const available = !!value.percent;

        return (
          <div
            className={classes.row}
            key={`row-for-${row.label}-${value.suffix}`}
          >
            {!available ? (
              <>
                <span className={styles.suffix}>*{value.suffix}</span> data not
                available.
              </>
            ) : (
              <>
                <div>
                  <span>
                    {`${racialBreakdownPercentFormatter(value.percent)}% `}
                  </span>
                  {`of ${value.suffix}`}
                </div>
                <Bar percent={value.percent} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default RacialBreakdown;
