import React, { useState, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './HeaderBuff.module.scss';

// HeaderBuff sits under the Header and acts as dynamic padding,
// auto-adjusting its height to match the headers
const HeaderBuff = () => {
  const [buffHeight, setBuffHeight] = useState(97);

  const location = useLocation(); // track location so that we can recompute on route changes

  // Update header size on route changes and window resizes
  useLayoutEffect(() => {
    const handleResize = () => {
      // All elements labeled with 'headerbuff-include' will be considered as
      // elements which HeaderBuff is responsible to offset content below.
      const className = 'headerbuff-include';
      const includedElements = [...document.getElementsByClassName(className)];
      if (includedElements.length) {
        // The height we need to offset is the height up to the most extreme
        // bottom edge of all the elements we're examining.
        const bottomEdge = Math.max(
          ...includedElements
            .map((el) => {
              const bottomEdge = el.getBoundingClientRect().bottom;
              const marginBottom = parseFloat(
                getComputedStyle(el).getPropertyValue('margin-bottom')
              );
              return bottomEdge + marginBottom;
            })
            .filter((bottom) => !!bottom)
        );
        setBuffHeight(bottomEdge);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [location]);

  return (
    <div
      className={styles.base}
      style={{
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        height: `${buffHeight}px`,
      }}
    />
  );
};

export default HeaderBuff;
