import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Page.module.scss';
import { getPage } from 'data/util';
import Meta from 'components/common/Meta';
import InteriorPageHeader from '../shell/InteriorPageHeader';

class Page extends Component {
  static propTypes = {
    id: PropTypes.string,
    page: PropTypes.object,
    children: PropTypes.node,
    wide: PropTypes.bool,
    flush: PropTypes.bool,
    header: PropTypes.bool,
    interiorHeader: PropTypes.node,
    interiorHeadMediaSrc: PropTypes.shape({
      imgSrc: PropTypes.string,
      mediaSrc: PropTypes.string,
    }),
    router: PropTypes.object,
    share: PropTypes.bool,
  };

  static defaultProps = {
    share: true,
    header: true,
    interiorHeader: null,
  };

  render() {
    const {
      children,
      wide,
      header,
      interiorHeader,
      interiorHeadMediaSrc,
      match
    } = this.props;

    const page =
      this.props.page ||
      getPage(this.props.path || this.props.location.pathname);

    const { meta } = page;

    const pageData = {
      section: this.props.section || page.section,
      title: this.props.title || page.title,
      subtitle: this.props.subtitle || page.subtitle,
      caption: this.props.caption || page.caption,
      description:
        this.props.caption || page.caption // || meta?.description,
    };

    return (
      <div
        id={this.props.id}
        className={cx(styles.base, { [styles.wide]: wide })}
      >
        <Meta {...meta} match={match} />
        {header ? (
          !!interiorHeader ? (
            React.cloneElement(interiorHeader, pageData)
          ) : (
            <InteriorPageHeader
              imgSrc={this.props.imgSrc || meta?.image}
              mediaSrc={
                !!interiorHeadMediaSrc ? interiorHeadMediaSrc.mediaSrc : ''
              }
              section={pageData.section || ''}
              title={pageData.title || ''}
              subtitle={pageData.subtitle || ''}
              description={pageData.description || ''}
              showShare={this.props.share}
            />
          )
        ) : null}

        {!!children && (
          <div
            className={cx(styles.content, { [styles.flush]: this.props.flush })}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Page);
