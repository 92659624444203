import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { partialMatch } from "data/geo";
import styles from "./RegionMenu.module.scss";
import SearchField from "components/common/SearchField";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

const RegionMenu = ({
  items,
  searchable,
  title,
  limit,
  onSelectIndex,
  selected,
}) => {
  const [query, setQuery] = useState("");
  const [limited, setLimited] = useState(limit !== -1);
  const change = (value) => setQuery(value);
  const filteredItems = items.filter(({ name }) => partialMatch(query, name));
  const filteredLimitedItems = limited
    ? filteredItems.slice(0, limit)
    : filteredItems;

  const toggleLimit = () => setLimited(!limited);

  const handleOptionSelect = (i, item) => {
    if (onSelectIndex) {
      onSelectIndex(i, item.slug);
      setQuery("");
    }
  };

  return (
    <div className={cx("base")}>
      {!!title && <h2 className={cx("title")}>{title}</h2>}
      {!!searchable && <SearchField defaultQuery={query} onChange={change} />}
      <div className={cx("items")}>
        {filteredLimitedItems.map((item, i) => (
          <Button
            key={item.to}
            href={item.to}
            icon="right"
            buttonStyle="filled-light"
            active={
              item.slug ? item.slug === selected?.slug : selected?.index === i
            }
            onClick={() => handleOptionSelect(i, item)}
          >
            <span>{item.name}</span>
          </Button>
        ))}
      </div>
      {filteredLimitedItems.length === 0 && (
        <p className={cx("empty")}>
          We're sorry, no results were found. Please check your spelling and try
          again.
        </p>
      )}
      {limit !== -1 && filteredLimitedItems.length < filteredItems.length && (
        <Button
          buttonStyle="bordered"
          icon={limited ? "down" : "x"}
          onClick={toggleLimit}
        >
          {limited ? "Show More" : "Show Less"}
        </Button>
      )}
    </div>
  );
};

RegionMenu.propTypes = {
  items: PropTypes.array,
  searchable: PropTypes.bool,
  title: PropTypes.string,
  limit: PropTypes.number,
};

RegionMenu.defaultProps = {
  items: [],
  searchable: true,
  title: "",
  limit: -1,
};

export default RegionMenu;
