import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import Icon from "../Icon";
import styles from "./ComparisonNote.module.scss";

const ComparisonNote = ({ content, compare }) => {
  const go = (e) => {
    e.preventDefault();
    compare();
  };

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <Icon icon="fast" />
        <span>Accelerated</span>
      </div>
      <div className={styles.content}>
        <ReactMarkdown source={content} />
        <a href="/vaccines/timeline" onClick={go}>
          Compare Timelines ›
        </a>
      </div>
    </div>
  );
};

ComparisonNote.propTypes = {
  content: PropTypes.string,
  compare: PropTypes.func,
};

export default ComparisonNote;
