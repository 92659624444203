import React from "react";
import classNames from "classnames/bind";
import styles from "./MobileSubNav.module.scss";
import { navItemMatches } from "../../Header";
import SectionLink from "../SectionLink";
import { sectionNav } from "../util";

const cx = classNames.bind(styles);

/* Mobile-only subnav */
const MobileSubNav = ({ section, pathname, onClose }) =>
  !!(navItemMatches(pathname, section) && section.sub?.length) && (
    <div className={cx("subNav")}>
      <ul>
        {section.sub.map(
          (subSection) =>
            subSection.to && (
              <li key={subSection.id}>
                <SectionLink
                  section={subSection}
                  pathname={pathname}
                  onClose={onClose}
                />
              </li>
            )
        )}
      </ul>
    </div>
  );

MobileSubNav.propTypes = sectionNav;

export default MobileSubNav;
