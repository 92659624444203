import React from "react";
import HFormat from "components/common/HFormat";
import styles from "./TestingTrackerModal.module.scss";
import InfoModalWrapper from "../InfoModalWrapper";
import TooltipIcon from "components/common/TooltipBadge/TooltipIcon";

const TRACKER_IMG_PATH = "/testing-tracker/images";

const iconNoChange = (
  <img
    alt="The trend direction"
    src={`${TRACKER_IMG_PATH}/no_change.svg`}
    width="31px"
    height="31px"
  />
);

const casesStatusIcons = [
  <img
    alt="The trend direction"
    src={`${TRACKER_IMG_PATH}/cases_trend_up.svg`}
    width="31px"
    height="31px"
  />,
  <img
    alt="The trend direction"
    src={`${TRACKER_IMG_PATH}/cases_trend_down.svg`}
    width="31px"
    height="31px"
  />,
  iconNoChange,
];

const testStatusIcons = [
  <img
    alt="The trend direction"
    src={`${TRACKER_IMG_PATH}/testing_trend_up.svg`}
    width="31px"
    height="31px"
  />,
  <img
    alt="The trend direction"
    src={`${TRACKER_IMG_PATH}/testing_trend_down.svg`}
    width="31px"
    height="31px"
  />,
  iconNoChange,
];

const average7DayContent = [
  <p>
    <strong>7-day moving average</strong> a green line segment represents a
    day-over-day decrease in cases, and a red line segment represents a
    day-over-day increase in cases
  </p>,

  <p>
    <strong>7-day moving average</strong> — a green line segment represents a
    day-over-day increase in testing, and a red line segment represents a
    day-over-day decrease in testing
  </p>,
  <p>
    <strong>7-day moving average</strong> — a green line segment is shown when
    below a 5% threshold, and a red line segment when above a 5% threshold
  </p>,
];

const weeklyTrendContent = [
  <p>
    <strong>Trending up</strong> — from the previous week to this week the trend
    is going up and greater than 5%
  </p>,

  <p>
    <strong>Trending down</strong> — from the previous week to this week the
    trend is going down and less than -5%
  </p>,
  <p>
    <strong>No Change</strong> — from the previous week to this week the trend
    is staying relatively flat
    <span style={{ whiteSpace: "nowrap" }}>(between -5% and 5%)</span>
  </p>,
];

const icons = [
  "positive-specimens-total-specimens",
  "positive-specimens-total-encounters",
  "positive-people-total-people",
  "positive-people-total-encounters",
  "positive-people-total-specimens",
];

const StateOverviewModal = () => {
  return (
    <InfoModalWrapper title="How to read this graphic">
      <div className={styles.main}>
        <HFormat size={3} noMargin>
          New Cases
        </HFormat>
        <div className={styles.row}>
          <div>
            <HFormat size={4} noMarginTop>
              New daily cases
            </HFormat>
            <div className={styles.columnHead}>
              <img
                src="/testing-tracker/images/how-to-read-testing-tracker-new-cases.png"
                alt="how-to"
              />
              {average7DayContent[0]}
            </div>
          </div>
          <div>
            {weeklyTrendContent.map((content, i) => {
              return (
                <div key={i}>
                  {i === 0 && (
                    <HFormat size={4} noMarginTop>
                      Weekly trend
                    </HFormat>
                  )}
                  <div className={styles.trendDescription}>
                    <div className={styles.svgs}>{casesStatusIcons[i]}</div>
                    {content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <HFormat size={3}>Tests per 100k people</HFormat>
        <div className={styles.row}>
          <div>
            <HFormat size={4} noMarginTop>
              New daily cases
            </HFormat>
            <div className={styles.columnHead}>
              <img
                src="/testing-tracker/images/how-to-read-testing-tracker-tests-per.png"
                alt="how-to"
              />
              {average7DayContent[1]}
            </div>
          </div>

          <div>
            {weeklyTrendContent.map((content, i) => {
              return (
                <div key={i}>
                  {i === 0 && (
                    <HFormat size={4} noMarginTop>
                      Weekly trend
                    </HFormat>
                  )}
                  <div className={styles.trendDescription}>
                    <div className={styles.svgs}>{testStatusIcons[i]}</div>
                    {content}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <HFormat size={3}>Percent Positive</HFormat>
        <div className={styles.row}>
          <div>
            <HFormat size={4} noMarginTop>
              Daily % positive
            </HFormat>
            <div className={styles.columnHead}>
              <img
                src="/testing-tracker/images/how-to-read-testing-tracker-perc-positive.png"
                alt="how-to"
              />
              {average7DayContent[2]}
            </div>
          </div>
          <div>
            <div>
              <HFormat size={4} noMarginTop>
                Positivity Calculation{" "}
              </HFormat>
              <div className={styles.trendDescription}>
                <div className={styles.toolTipIconContainer}>
                  {icons.map((tooltip, i) => (
                    <div className={styles.iconContainer} key={i}>
                      <TooltipIcon key={i} type={tooltip} />
                    </div>
                  ))}
                </div>
                <p>
                  Different positivity calculations are possible based on each
                  state’s publicly-available testing data. To see all possible
                  calculations, click the Positivity Comparison button.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InfoModalWrapper>
  );
};
export default StateOverviewModal;
