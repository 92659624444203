import React from 'react';
import Axes from '../Axes';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';

const ThresholdLegend = ({ selectedDataSet, width }) => {
  const { colorScale, formatter, hasNoDataOption = false } = selectedDataSet;

  // Create an artificial x-scale for displaying the legend
  const xScale = scaleLinear()
    .domain([0, max(colorScale.domain()) + 0.05])
    .rangeRound([0, width]);

  return (
    <div className="map__legend-wrapper">
      <div className="map__legend">
        <svg width={width} height={35}>
          <g className="legend" transform={`translate(${0},${0})`}>
            {colorScale.range().map(color => {
              const d = colorScale.invertExtent(color);
              if (d[0] == null) d[0] = xScale.domain()[0];
              if (d[1] == null) d[1] = xScale.domain()[1];
              return (
                <rect
                  key={`legend-rect-${color}`}
                  height={11.3}
                  x={xScale(d[0])}
                  width={xScale(d[1]) - xScale(d[0])}
                  fill={colorScale(d[0])}
                />
              );
            })}
            <Axes colorScale={colorScale} xScale={xScale} xScaleTickFormat={formatter} height={8} />
          </g>
        </svg>
      </div>
        {hasNoDataOption && <div className="no__data">
        <div className="color__block"/>
        No data
      </div>}
    </div>
  );
};

export default ThresholdLegend;
