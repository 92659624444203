import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { color } from "d3-color";

const Lollipop = ({r, cxNow, cxLast, x1, x2, y, fill, tippyContent, regionURL, markerStart, mobile}) => {
  const history = useHistory();
  const [hover, setHover] = useState(false);

  return (
    <Tippy content={tippyContent} maxWidth={200} theme={'light'} placement={'auto'}>
      <g className="lolli">
          <line x1={x1} y1={y} x2={x2} y2={y}
            markerStart={markerStart}
            stroke={fill}
            strokeWidth={1.5}
            onMouseOver={()=>setHover(true)}
            onMouseOut={()=>setHover(false)}
            onClick={()=> mobile ? {} : history.push(regionURL)}/>
          <circle r={r} cx={cxNow} cy={y}
            fill={hover ? color(fill).darker() : fill}
            onMouseOver={()=>setHover(true)}
            onMouseOut={()=>setHover(false)}
            onClick={()=> mobile ? {} : history.push(regionURL)}/>
          <circle r={r} cx={cxLast} cy={y}
            fill={hover ? color("#ccc").darker() : "#ccc"}
            onMouseOver={()=>setHover(true)}
            onMouseOut={()=>setHover(false)}
            onClick={()=> mobile ? {} : history.push(regionURL)}/>
      </g>
    </Tippy>
  );
};

export default Lollipop;
