import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import Button from "components/common/Button";
import { NavLink, useLocation } from "react-router-dom";
import { anyMatches } from "../../Header";

import classNames from "classnames/bind";
import styles from "./ContentNav.module.scss";
const cx = classNames.bind(styles);

/**
 * The ContentNav appears as a subnav under the main nav
 * It can render a set of subnav routes, some of which can support expanding to
 * display a dynamic navigation experience that drops down from the navigation
 */
export default function ContentNav({ routes, className }) {
  // If one of the subnav routes is to the “base” page of this nav section, then
  // that's going to come first
  const baseRoute = routes.find((r) => r.isBase) || null;
  // Expanding “contentful” subnav routes will come after that
  const contentRoutes = routes.filter((r) => !!r.navContent);
  // “Direct” subnav routes come last
  const directRoutes = routes.filter(
    (r) => !r.isBase && !contentRoutes.includes(r)
  );

  const location = useLocation();

  // A contentful subnav route can be “opened”
  const [openedItem, setOpenedItem] = useState(null);
  const isOpened = (id) => openedItem === id;
  const close = () => setOpenedItem(null);
  const toggleOpened = (id) => {
    if (isOpened(id)) close();
    else setOpenedItem(id);
  };
  const openedContent =
    openedItem && contentRoutes.find(({ id }) => id === openedItem)?.navContent;
  // The drawer closes on navigation
  useEffect(close, [location]);

  // Route matching functions
  const { pathname } = location;

  // Hide on mobile when none of the content routes are active
  const mobileDisplay = contentRoutes.some(({ matchesRoutes, exact }) =>
    anyMatches(pathname, matchesRoutes, exact)
  );

  const renderLink = ({ to, exact, name }) => (
    <NavLink {...{ to, exact }} activeClassName={cx("active")}>
      {name}
    </NavLink>
  );

  return (
    <div
      className={classNames(
        className,
        cx(
          "base",
          { "content-nav": openedContent },
          { "mobile-hide": !mobileDisplay }
        )
      )}
    >
      <ul className={classNames(cx("links-container"), "headerbuff-include")}>
        {/* Base route first */}
        {baseRoute && <li>{renderLink(baseRoute)}</li>}

        {/* Then buttons to expand contentful subnav items */}
        {contentRoutes.map(({ id, name, matchesRoutes, exact }) => (
          <li key={id} className={cx("has-button", { open: isOpened(id) })}>
            <Button
              key={id}
              className={cx({
                active: anyMatches(pathname, matchesRoutes, exact),
              })}
              buttonStyle="plain"
              icon="down"
              onClick={() => toggleOpened(id)}
            >
              {name}
            </Button>
          </li>
        ))}

        {/* Divider if there’s content on both sides */}
        {(baseRoute || contentRoutes.length || null) &&
          !!directRoutes.length && <li className={cx("divider")} />}

        {/* Finally, direct links */}
        {directRoutes.map(({ id, ...route }) => (
          <li key={id}>{renderLink(route)}</li>
        ))}
      </ul>

      <div className={cx("content-size")}>
        <div className={cx("content-area", { open: openedItem !== null })}>
          {/* Bar at the top has a title and a close button */}
          <div className={cx("top-bar")}>
            <h2>{openedContent?.title}</h2>
            <div className={cx("button-container")}>
              <Button
                className={cx("close-button")}
                onClick={close}
                buttonStyle="bordered"
                shape="rounded"
                icon="x"
              />
            </div>
          </div>

          {/* Horizontal line in between top bar and main content */}
          <div className={cx("hr-container")}>
            <hr />
          </div>

          {/* Main content! */}
          <div className={cx("main")}>
            {openedContent && <openedContent.component />}
          </div>
        </div>
      </div>
    </div>
  );
}

ContentNav.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      // “Direct” (non-contentful) subnav routes
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        to: PropTypes.string.isRequired,
        exact: PropTypes.bool,
        isBase: PropTypes.bool,
      }),
      // Contentful subnav routes
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        matchesRoutes: PropTypes.arrayOf(PropTypes.string).isRequired,
        navContent: PropTypes.shape({
          title: PropTypes.string.isRequired,
          component: PropTypes.elementType.isRequired,
        }),
      }),
    ])
  ),
  className: PropTypes.string,
};
