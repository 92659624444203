import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import styles from "./TestingStates.module.scss";
import { getPage } from "data/util";
import StateGraph from "./StateGraph";
import { DataSources, DataTimeFetched } from "components/common/DataMeta";
import SideNarrative from "components/layout/SideNarrative";
import { Accordion, AccordionItem } from "components/common/Accordion";

const pushGTM = (stateId) => {
  GTM.clear();
  GTM.push({
    pageVerticals: ["testing"],
    contentDataVisualization: "testing-individual-states",
    contentCountry:
      stateId === "usa" || stateId === undefined ? "united-states" : undefined,
    contentState:
      stateId !== "usa" && stateId !== undefined ? stateId : undefined,
  });
};

const TestingStates = ({ match }) => {
  const page = getPage("/testing/individual-states");
  const { content_blocks } = page;
  const { stateId } = match.params;

  useEffect(() => {
    pushGTM(stateId);
  }, [stateId]);
  pushGTM(stateId);

  return (
    <SideNarrative
      classNames={{
        sidebar: styles.buffer,
      }}
      pageProps={{ path: "/testing/individual-states" }}
      narrative={
        <>
          <DataTimeFetched URL="https://jhucoronavirus.azureedge.net/data/ts/testing_v.json" />

          <ReactMarkdown
            source={content_blocks["testing-viz-intro-1"].content}
          />
          <Accordion className={styles.accordionContainer} autoOpen={false}>
            <AccordionItem
              topContent={
                <div className={styles.accordionTriggerContent}>
                  How we calculate positivity
                </div>
              }
            >
              <ReactMarkdown
                source={content_blocks["postitivity-sidebar-explainer"].content}
                linkTarget="_blank"
              />
            </AccordionItem>
          </Accordion>
        </>
      }
    >
      <div className={styles.container}>
        <StateGraph stateId={match.params.stateId} />

        <ReactMarkdown
          source={
            "***Track changes in the number of administered tests and positivity rates. To narrow the view of the larger chart drag the horizontal arrows bookending the smaller gray bar beneath it.***"
          }
        />

        <DataSources dataSet="testing-state-data" />
        <br />
        <ReactMarkdown
          source={content_blocks["testing-data-disclaimer"].content}
        />
      </div>
    </SideNarrative>
  );
};

export default TestingStates;
