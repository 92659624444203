import React, { useEffect } from "react";
import * as GTM from "util/gtm";

import { DataSources, DataTimeFetched } from "components/common/DataMeta";
import TrandGraphs from "./TrendGraphs";
import SideNarrative from "components/layout/SideNarrative";

import styles from "./Hospitalization7DayTrend.module.scss";
import ReactMarkdown from "react-markdown";
import { getPage } from "data/util";

const Hospitalization7DayTrend = ({ match }) => {
  const stateId = match.params.stateId;
  const page = getPage("/data/hospitalization-7-day-trend");
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "hospitalization-7-day-trend",
      contentCountry: stateId ? undefined : "united-states",
      contentState: stateId ? stateId : undefined,
    });
  }, [stateId]);

  return (
    <SideNarrative
      pageProps={{ path: "/data/hospitalization-7-day-trend" }}
      narrative={
        <>
          <div className={styles.contentTop}>
            <DataTimeFetched
              URL={
                "https://jhucoronavirus.azureedge.net/data/ts/testing_tracker.json"
              }
            />
          </div>
          <div className={styles.details}>
            <div>
              <ReactMarkdown
                source={content_blocks?.["7-day-trend-explainer"]?.content}
              />
              <ReactMarkdown
                source={
                  content_blocks?.["weekly-data-trends-data-source"]?.content
                }
              />
            </div>
          </div>
        </>
      }
    >
      <div className={styles.container}>
        <TrandGraphs stateId={stateId} />
        <DataSources dataSet="hospitalization-7-day-trend" />
      </div>
    </SideNarrative>
  );
};

export default Hospitalization7DayTrend;
