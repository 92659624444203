import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import * as GTM from "util/gtm";
import styles from "./NotFound.module.scss";
import Page from "components/layout/Page";

export default () => {
  useEffect(GTM.clear, []);

  return (
    <Page
      title="We’re sorry…"
      subtitle="The page you are looking for could not be found."
      share={false}
    >
      <div className={styles.base}>
        <Link className={styles.button} to="/">
          Return to the home page
        </Link>
      </div>
    </Page>
  );
};
