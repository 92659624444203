import { initialState } from '../state/global';
import { getData, getMetaData } from '../api';
import {COLUMNS, IS_SELECTED, PERCENT_POSITIVE, ROWS, STATE_META, STATE_NAME, valueAccessor} from '../../constants';
import kebabCase from 'lodash.kebabcase';
import { ascending } from 'd3-array';

// Actions
const TOGGLE_LOADING_ICON = 'TOGGLE_LOADING_ICON';
const UPDATE_DATA = 'UPDATE_DATA';
const UPDATE_METADATA = 'UPDATE_METADATA';
const SHOW_ERROR = 'SHOW_ERROR';
const UPDATE_SELECTED_REGION = 'UPDATE_SELECTED_REGION';
const UPDATE_SELECTED_VISUALIZATION = 'UPDATE_SELECTED_VISUALIZATION';
const UPDATE_SELECTED_DATASET = 'UPDATE_SELECTED_DATASET';
const TOGGLE_TABLE_TOOLTIP = 'TOGGLE_TABLE_TOOLTIP';

const updateGrid = (data, selectedDataset) => {
  const grid = [];
  const domain = [];
  for (let row = 0; row < ROWS; row++) {
    grid.push([]);
    for (let col = 0; col < COLUMNS; col++) {
      let output = {};

      // Find the state for this row/column combination
      const match = STATE_META.find(r => r.row === row && r.col === col);

      if (match) {
        // Get the state value from the data
        const state = data.find(d => d[STATE_NAME].toLowerCase() === match.name.toLowerCase());
        const value = selectedDataset.accessor(state);
        const isPercentPositive = selectedDataset.name === 'Percent positive';
        const selectedApproach = state.values[PERCENT_POSITIVE].find(d => d.selected);
        domain.push(value);
        output = {
          ...match,
          id: match.abbr,
          value,
          approachId: isPercentPositive ? selectedApproach?.approachId : 1,
        };
      }
      grid[row].push(output);
    }
  }
  return { grid, domain };
};

// Reducer
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_DATA:
      // Retrieve and update regions
      let regions = state.regions;
      const stateDivisions = Array.from(new Set(action.data.map(d => d.region)))
        .map(region => {
          return {
            id: kebabCase(region),
            name: region,
            [IS_SELECTED]: false,
          };
        })
        .sort((a, b) => ascending(a.name, b.name));

      return {
        ...state,
        data: action.data,
        ...updateGrid(
          action.data,
          state.datasets.find(d => d[IS_SELECTED]),
        ),
        regions: regions.concat(stateDivisions),
      };
    case UPDATE_METADATA:
      // Retrieve and update positivity comparison metadata
      return {
        ...state,
        metadata: action.metadata.approaches,
      };
    case SHOW_ERROR:
      return {
        ...state,
        showError: true,
      };
    case TOGGLE_TABLE_TOOLTIP:
      return {
        ...state,
        tableTooltip: action.tooltip,
      };
    case UPDATE_SELECTED_DATASET:
      return {
        ...state,
        ...updateGrid(state.data, action.dataset),
      };
    case UPDATE_SELECTED_REGION:
      return {
        ...state,
        regions: state.regions.map(region => {
          return {
            ...region,
            [IS_SELECTED]: valueAccessor(region) === action.id,
          };
        }),
      };
    case UPDATE_SELECTED_VISUALIZATION:
      return {
        ...state,
        visualizations: state.visualizations.map(visualization => {
          return {
            ...visualization,
            [IS_SELECTED]: valueAccessor(visualization) === action.id,
          };
        }),
      };
    case TOGGLE_LOADING_ICON:
      return {
        ...state,
        showError: false,
        isLoading: action.x,
      };
    default:
      return state;
  }
}

function toggleLoadingIcon(x) {
  return {
    type: TOGGLE_LOADING_ICON,
    x,
  };
}

export function updateRegion(id) {
  return {
    type: UPDATE_SELECTED_REGION,
    id,
  };
}

export function updateDataset(dataset) {
  return {
    type: UPDATE_SELECTED_DATASET,
    dataset,
  };
}

export function updateVisualization(id) {
  return {
    type: UPDATE_SELECTED_VISUALIZATION,
    id,
  };
}

export function updateTableTooltip(tooltip) {
  return {
    type: TOGGLE_TABLE_TOOLTIP,
    tooltip,
  };
}

export function retrieveData() {
  return async dispatch => {
    dispatch(toggleLoadingIcon(true));

    try {
      const data = await getData();
      dispatch({
        type: UPDATE_DATA,
        data,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SHOW_ERROR });
    }

    try {
      const metadata = await getMetaData();
      dispatch({
        type: UPDATE_METADATA,
        metadata,
      });
    } catch (err) {
      console.log(err);
      dispatch({ type: SHOW_ERROR });
    }

    dispatch(toggleLoadingIcon(false));
  };
}
