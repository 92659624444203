import { format } from "d3-format";

export const STATE_META = [
  { row: 6, col: 7, abbr: "AL", name: "Alabama" },
  { row: 0, col: 0, abbr: "AK", name: "Alaska" },
  // {row: 0, col: 0, abbr: 'AS', name: 'American Samoa'},
  { row: 5, col: 2, abbr: "AZ", name: "Arizona" },
  { row: 5, col: 5, abbr: "AR", name: "Arkansas" },
  { row: 4, col: 1, abbr: "CA", name: "California" },
  { row: 4, col: 3, abbr: "CO", name: "Colorado" },
  { row: 3, col: 10, abbr: "CT", name: "Connecticut" },
  { row: 4, col: 10, abbr: "DE", name: "Delaware" },
  { row: 5, col: 9, abbr: "DC", name: "District of Columbia" },
  // {row: 0, col: 0, abbr: 'FM',name: 'Federated States Of Micronesia'},
  { row: 7, col: 9, abbr: "FL", name: "Florida" },
  { row: 6, col: 8, abbr: "GA", name: "Georgia" },
  // {row: 0, col: 0, abbr: 'GU', name: 'Guam'},
  { row: 7, col: 0, abbr: "HI", name: "Hawaii" },
  { row: 2, col: 2, abbr: "ID", name: "Idaho" },
  { row: 2, col: 6, abbr: "IL", name: "Illinois" },
  { row: 3, col: 6, abbr: "IN", name: "Indiana" },
  { row: 3, col: 5, abbr: "IA", name: "Iowa" },
  { row: 5, col: 4, abbr: "KS", name: "Kansas" },
  { row: 4, col: 6, abbr: "KY", name: "Kentucky" },
  { row: 6, col: 5, abbr: "LA", name: "Louisiana" },
  { row: 0, col: 11, abbr: "ME", name: "Maine" },
  // {row: 0, col: 0, abbr: 'MH', name: 'Marshall Islands'},
  { row: 4, col: 9, abbr: "MD", name: "Maryland" },
  { row: 2, col: 11, abbr: "MA", name: "Massachusetts" },
  { row: 2, col: 8, abbr: "MI", name: "Michigan" },
  { row: 2, col: 5, abbr: "MN", name: "Minnesota" },
  { row: 6, col: 6, abbr: "MS", name: "Mississippi" },
  { row: 4, col: 5, abbr: "MO", name: "Missouri" },
  { row: 2, col: 3, abbr: "MT", name: "Montana" },
  { row: 4, col: 4, abbr: "NE", name: "Nebraska" },
  { row: 3, col: 2, abbr: "NV", name: "Nevada" },
  { row: 1, col: 11, abbr: "NH", name: "New Hampshire" },
  { row: 3, col: 9, abbr: "NJ", name: "New Jersey" },
  { row: 5, col: 3, abbr: "NM", name: "New Mexico" },
  { row: 2, col: 9, abbr: "NY", name: "New York" },
  { row: 5, col: 7, abbr: "NC", name: "North Carolina" },
  { row: 2, col: 4, abbr: "ND", name: "North Dakota" },
  // {row: 0, col: 0, abbr: 'MP', name: 'Northern Mariana Islands'},
  { row: 3, col: 7, abbr: "OH", name: "Ohio" },
  { row: 6, col: 4, abbr: "OK", name: "Oklahoma" },
  { row: 3, col: 1, abbr: "OR", name: "Oregon" },
  // {row: 0, col: 0, abbr: 'PW', name: 'Palau'},
  { row: 3, col: 8, abbr: "PA", name: "Pennsylvania" },
  // {row: 7, col: 11, abbr: 'PR', name: 'Puerto Rico'},
  { row: 2, col: 10, abbr: "RI", name: "Rhode Island" },
  { row: 5, col: 8, abbr: "SC", name: "South Carolina" },
  { row: 3, col: 4, abbr: "SD", name: "South Dakota" },
  { row: 5, col: 6, abbr: "TN", name: "Tennessee" },
  { row: 7, col: 4, abbr: "TX", name: "Texas" },
  { row: 4, col: 2, abbr: "UT", name: "Utah" },
  { row: 1, col: 10, abbr: "VT", name: "Vermont" },
  // {row: 0, col: 0, abbr: 'VI', name: 'Virgin Islands'},
  { row: 4, col: 8, abbr: "VA", name: "Virginia" },
  { row: 2, col: 1, abbr: "WA", name: "Washington" },
  { row: 4, col: 7, abbr: "WV", name: "West Virginia" },
  { row: 2, col: 7, abbr: "WI", name: "Wisconsin" },
  { row: 3, col: 3, abbr: "WY", name: "Wyoming" },
];
export const IS_SELECTED = "IS_SELECTED";
export const valueAccessor = (d) => d.id;
export const nameAccessor = (d) => d.name;
export const MAP_VIEW = "MAP_VIEW";
export const TABLE_VIEW = "TABLE_VIEW";
export const POSITIVITY_COMPARISON_VIEW = "POSITIVITY_COMPARISON_VIEW";
export const NEW_CASES = "NEW_CASES";
export const TESTS = "TESTS";
export const PERCENT_POSITIVE = "PERCENT_POSITIVE";
export const IS_CURRENT = "IS_CURRENT";
export const COMMA_FORMATTER = format(",.0f");
export const SINGLE_DECIMAL_FORMATTER = format(",.1f");
export const DOUBLE_DECIMAL_FORMATTER = format(",.2f");
export const PERCENT_FORMATTER = format(".1%");
export const STATE_NAME = "STATE_NAME";
export const POSITIVE_COLOR = "#60C6C6";
export const NEGATIVE_COLOR = "#E39260";
export const PERCENT_POSITIVE_BREAK_POINT = 0.05;
export const THIS_WEEK = "THIS_WEEK";
export const LAST_WEEK = "LAST_WEEK";
export const NEGATIVE_TREND = "NEGATIVE_TREND";
export const POSITIVE_TREND = "POSITIVE_TREND";
export const NEUTRAL_TREND = "NEUTRAL_TREND";
export const ALL_REGIONS_VISIBLE = "all";

// Map variables
export const MAP_NEW_CASES = "MAP_NEW_CASES";
export const MAP_WEEKLY_CHANGE_IN_NEW_CASES = "WEEKLY_CHANGE_IN_NEW_CASES";
export const MAP_PERCENT_POSITIVE = "MAP_PERCENT_POSITIVE";
export const MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE =
  "MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE";
export const MAP_NEW_TESTS = "MAP_NEW_TESTS";
export const MAP_WEEKLY_CHANGE_IN_TESTING = "MAP_WEEKLY_CHANGE_IN_TESTING";
export const ROWS = 8;
export const COLUMNS = 12;
export const MAX_CELL_SIZE = 66.25;
export const DEFAULT_MAP_DATASET = "percent-positive";

export const SCALE_TYPES = {
  THRESHOLD: "THRESHOLD",
  ORDINAL: "ORDINAL",
  QUANTILE: "QUANTILE",
};

export const GLOBAL_ROOT_ID = "jh-testing-tool";

export const SUB_ROUTES = [
  "/testing/tracker/overview",
  "/testing/tracker/overview/all",
  "/testing/tracker/overview/great-lakes",
  "/testing/tracker/overview/mid-atlantic",
  "/testing/tracker/overview/midwest",
  "/testing/tracker/overview/mountain-west",
  "/testing/tracker/overview/new-england",
  "/testing/tracker/overview/northeast-pr-and-usvi",
  "/testing/tracker/overview/northwest",
  "/testing/tracker/overview/southeast",
  "/testing/tracker/overview/southwest",
  "/testing/tracker/overview/west",
  "/testing/tracker/map",
  "/testing/tracker/map/new-cases-per-100-k-people",
  "/testing/tracker/map/weekly-change-in-new-cases",
  "/testing/tracker/map/percent-positive",
  "/testing/tracker/map/weekly-change-percent-positive",
  "/testing/tracker/map/new-tests-per-100-k-people",
  "/testing/tracker/map/weekly-change-in-testing",
  "/testing/tracker/positivity-comparison",
  "/testing/tracker/positivity-comparison/all",
  "/testing/tracker/positivity-comparison/great-lakes",
  "/testing/tracker/positivity-comparison/mid-atlantic",
  "/testing/tracker/positivity-comparison/midwest",
  "/testing/tracker/positivity-comparison/mountain-west",
  "/testing/tracker/positivity-comparison/new-england",
  "/testing/tracker/positivity-comparison/northeast-pr-and-usvi",
  "/testing/tracker/positivity-comparison/northwest",
  "/testing/tracker/positivity-comparison/southeast",
  "/testing/tracker/positivity-comparison/southwest",
  "/testing/tracker/positivity-comparison/west",
];
