export const push = (data, eventLabel) => {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      event: eventLabel || undefined,
      ...data,
    });
  }
};

export const clear = () => {
  if (window.dataLayer !== undefined) {
    window.dataLayer.push({
      event: undefined,
      pageVerticals: undefined,
      contentVerticals: undefined,
      contentDataVisualization: undefined,
      contentCountry: undefined,
      contentState: undefined
    });
  }
};