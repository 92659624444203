/* eslint-disable import/no-webpack-loader-syntax */
import React from "react";
import { Link, useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import cx from "classnames";

import styles from "./TrendGraphs.module.scss";
import stateList from "data/data-viz/new-cases-50-states/state-list";
import { findStateCode } from "data/geo";

import EmbedFrame from "components/common/EmbedFrame";
import Button from "components/common/Button";
import { static_asset_path } from "util/hooks/useFetch";

const TrendGraphs = ({ stateId }) => {
  const history = useHistory();

  const isInpatientRoute =
    history.location.pathname.indexOf("/inpatient-capacity") > -1;

  if (!stateId) {
    stateId = "usa";
  }

  const mainPath = `/data/hospitalization-7-day-trend`;
  const toMainGrid = () => {
    history.push(mainPath, {
      noScroll: true,
    });
  };
  const handleChoice = (e) => {
    history.push(`${mainPath}/${e.currentTarget.value}`, {
      noScroll: true,
    });
  };

  const state = stateId || "usa";

  const stateCode = state === "usa" ? state : findStateCode(state);
  const hasStatePage = !!stateCode;

  const allStates = state === "usa";

  const renderGrid = (state) => {
    return (
      <>
        <MediaQuery maxWidth={767}>
          {!isInpatientRoute ? (
            <EmbedFrame
              src={`${static_asset_path}mobile_icu_per_state_${stateCode.toUpperCase()}.html`}
              width={340}
              height={400}
            />
          ) : (
            <EmbedFrame
              src={`${static_asset_path}mobile_inpatient_per_state_${stateCode.toUpperCase()}.html`}
              width={340}
              height={400}
            />
          )}
        </MediaQuery>
        <MediaQuery minWidth={768}>
          {!isInpatientRoute ? (
            <EmbedFrame
              src={`${static_asset_path}web_icu_per_state_${stateCode.toUpperCase()}.html`}
              width={720}
              height={400}
            />
          ) : (
            <EmbedFrame
              src={`${static_asset_path}web_inpatient_per_state_${stateCode.toUpperCase()}.html`}
              width={720}
              height={400}
            />
          )}
        </MediaQuery>
      </>
    );
  };

  return (
    <div className={cx(styles.base, state === "usa" ? styles.baseUSA : null)}>
      <div className={styles.buttonRow}>
        <div>
          <button
            className={allStates ? styles.activeState : styles.inactiveState}
            onClick={toMainGrid}
          >
            U.S.
          </button>
        </div>
        <div
          className={`${styles.chooser} " " ${
            !allStates ? styles.activeState : styles.inactiveState
          }`}
        >
          <select onChange={handleChoice} value={state}>
            <option value="usa" disabled>
              Choose A State
            </option>
            {Object.keys(stateList).map((s) => (
              <option key={s} value={s}>
                {stateList[s]}
              </option>
            ))}
          </select>
        </div>
        {!!hasStatePage && (
          <Button
            buttonStyle="underlined"
            className={styles.statePageLink}
            icon="right"
            href={allStates ? "/region/united-states" : `/region/us/${state}`}
          >
            {allStates ? (
              <span>United States Country Profile</span>
            ) : (
              <span>{stateList[state]} State Profile</span>
            )}
          </Button>
        )}
      </div>
      <div className={styles.chartToggles}>
        <Link
          to={
            allStates
              ? `/data/hospitalization-7-day-trend`
              : `/data/hospitalization-7-day-trend/${state}`
          }
          className={cx(styles.button, !isInpatientRoute && styles.active)}
        >
          ICU Capacity
        </Link>
        <Link
          to={
            allStates
              ? `/data/hospitalization-7-day-trend/inpatient-capacity`
              : `/data/hospitalization-7-day-trend/${state}/inpatient-capacity`
          }
          className={cx(styles.button, isInpatientRoute && styles.active)}
        >
          Inpatient Capacity
        </Link>
      </div>
      <div className={styles.chartInstruction}>
        <p>Hover for more detail.</p>
      </div>
      <div className={cx(styles.chartContainer, styles[state])}>
        {renderGrid(state)}
      </div>
      <div className={styles.snapshotMenu}>
        {Object.keys(stateList).map((s) => (
          <Link key={s} to={`${mainPath}/${s}`}>
            {stateList[s]}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default TrendGraphs;
