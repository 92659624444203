import React, {
  cloneElement,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { zoom as d3_zoom, zoomIdentity } from "d3-zoom";
import { event, select } from "d3-selection";
import { scaleBand } from "d3-scale";
import "d3-transition";
import { dateAccessor } from "../constants";
import { DURATION } from "components/content/DataCenter/charts/Shared/constants";

const BaseChart = ({ zoomEffect, dateExtent, margin, className, children }) => {
  const groupRef = useRef(null);
  const svgContainerRef = useRef(null);
  const visualizationRef = useRef(null);
  const [width, setWidth] = useState(1216);
  const [height, setHeight] = useState(580);

  /**
   * Screen and window resize manager
   */
  useEffect(() => {
    function handleResize() {
      requestAnimationFrame(() => {
        // If we're able to get the SVG's parent,
        // set the height and width to be the same as the parent's
        if (svgContainerRef?.current) {
          const parent = svgContainerRef.current.parentElement;
          const parentSize = {
            width: parent.offsetWidth,
            height: parent.offsetHeight - 20
          }
          setWidth(parentSize.width);
          setHeight(parentSize.height);
        } else {
          // Else, set it to attempt to fit the chart
          // based on the size of the window
          setWidth(typeof window === "undefined"
            ? 100
            : window.innerWidth >= 1248
              ? 1216
              : window.innerWidth - 32
          );
          setHeight(
            className.includes("facetSVG") ? 580 : 50
          );
        }
      })
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [svgContainerRef, className]);

  if (!margin) {
    className.includes("facetSVG")
      ? margin = {
        top: 0,
        right: 0,
        bottom: 35,
        left: 75
      }
      : margin = {
        left: 75,
        right: 5, // 45,
        top: 75,
        bottom: 25,
      };
  }
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const [xScaleRange, setXScaleRange] = useState([0, innerWidth]);

  const xScale = useMemo(() => {
    return scaleBand()
      .paddingInner(0.25)
      .range([0, innerWidth])
      .domain(
        children.props.enumeratedDates
          ? children.props.enumeratedDates
          : children.props.data.map(dateAccessor)
      )
  }, [innerWidth, children.props.data, children.props.enumeratedDates])

  const backgroundXScale = useMemo(() => {
    return scaleBand()
      .range([0, innerWidth])
      .domain(
        children.props.enumeratedDates
          ? children.props.enumeratedDates
          : children.props.data.map(dateAccessor)
      )
  }, [innerWidth, children.props.data, children.props.enumeratedDates])

  useEffect(() => {
    const zoomed = () => {
      const t = event.transform;
      setXScaleRange([0, innerWidth].map((d) => t.applyX(d)));
    };

    const zoom = d3_zoom()
      .scaleExtent([1, 32])
      .translateExtent([
        [0, 0],
        [innerWidth, height],
      ])
      .extent([
        [0, 0],
        [innerWidth, height],
      ])
      .on("zoom", zoomed);

    const g = select(groupRef.current);
    const svg = g.select(function () {
      return this.parentNode;
    });

    const _svg = svg
      .call(zoom)
      .on("wheel.zoom", null)
      .on("dblclick.zoom", null)
      .on("mousedown.zoom", null)
      .on("touchstart.zoom", null)
      .on("touchmove.zoom", null)
      .on("touchend.zoom", null)
      .transition()
      .duration(DURATION);

    if (!zoomEffect) {
      _svg.call(zoom.transform, zoomIdentity.scale(1).translate(0, 0));
    } else {
      _svg.call(
        zoom.transform,
        zoomIdentity
          .scale(innerWidth / (xScale(dateExtent[1]) - xScale(dateExtent[0])))
          .translate(-xScale(dateExtent[0]), 0)
      );
    }

    return () => {
      svg.interrupt();
      zoom.on("zoom", null);
    };
  }, [
    children.props,
    width,
    height,
    xScale,
    innerWidth,
    dateExtent,
    zoomEffect
  ]);

  // Handle clip path and event marker position
  const offset = margin.top / 2;

  useEffect(() => {
    setXScaleRange([0, innerWidth]);
  }, [innerWidth]);

  return (
    <div
      ref={svgContainerRef}
    >
      <svg
        width={width}
        height={height}
        ref={visualizationRef}
        className={className}
      >
        <defs>
          <clipPath id="bar-chart-clip">
            {className === "facetSVG"
              ? <rect width={innerWidth + 30} height={height} x={0} y={0} />
              : <rect width={innerWidth} height={height} x={0} y={-offset} />
            }
          </clipPath>
        </defs>
        <g transform={`translate(${margin.left},${margin.top})`} ref={groupRef}>
          {
            cloneElement(children, {
              innerWidth,
              innerHeight,
              margin,
              xScale,
              backgroundXScale,
              xScaleRange,
              offset,
            })}
        </g>
      </svg>
    </div>
  );
};

BaseChart.propTypes = {
  zoomEffect: PropTypes.bool,
  dateExtent: PropTypes.any,
  margin: PropTypes.any,
  className: PropTypes.string,
};

export default BaseChart;
