import { createSelector } from 'reselect';
import { valueAccessor } from '../../../Shared/constants';

const datasetsSelector = state => state.stateTimeline.datasets;

const selectedDatasetSelector = selectedDataset =>
  createSelector([datasetsSelector], datasets => {
    return datasets.find(dataset => valueAccessor(dataset) === selectedDataset);
  });

export default selectedDatasetSelector;
