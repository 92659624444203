import React from "react";
import styles from "./TooltipBadge.module.scss";
import cx from "classnames";
import TooltipIcon, { TTooltipIconProps } from "./TooltipIcon";

type TTooltipBadgeProps = {
  type: TTooltipIconProps["type"];
  category: string;
  title: string;
  content: string;
  linkText?: string;
  href?: string;
  className?: string;
};

const TooltipBadge = ({
  type,
  category,
  title,
  content,
  linkText,
  href,
}: TTooltipBadgeProps) => {
  return (
    <div className={styles.base}>
      <div className={styles.tooltipWrap}>
        <TooltipIcon className={styles.icon} type={type}>
          <div
            className={cx({
              [styles.tooltip]: true,
            })}
          >
            <p className={styles.category}>{category}</p>
            <h2>{title}</h2>
            <p className={styles.content}>{content}</p>

            {href && (
              <p className={styles.linkWrap}>
                <a href={href}>{linkText}</a>
              </p>
            )}
          </div>
        </TooltipIcon>
      </div>
    </div>
  );
};

export default TooltipBadge;
