import React, { useState } from "react";
import { scaleLinear } from "d3-scale";
import { max } from "d3-array";
import { Delaunay } from "d3-delaunay";
import VoronoiCell from "../Voronoi";
import { SINGLE_DECIMAL_FORMATTER } from "../../../../../constants";
import TrendLine from "../TrendLine";
import Marker from "../Marker";

const Tests = ({ innerHeight, innerWidth, xScale, data }) => {
  const defaultCurrentPoint = data[data.length - 1];
  const [currentPoint, setCurrentPoint] = useState(defaultCurrentPoint);
  const yScale = scaleLinear()
    .range([innerHeight, 0])
    .domain([0, max(data, (d) => d.average)]);

  const voronoi = Delaunay.from(
    data,
    (d) => xScale(d.dt),
    (d) => yScale(d.average)
  ).voronoi([0, 0, innerWidth, innerHeight]);

  if (!defaultCurrentPoint) return null;

  return (
    <g className="spark__line_group">
      <Marker
        marker={{
          cx: xScale(currentPoint.dt) + xScale.bandwidth() / 2,
          cy: yScale(currentPoint.average),
        }}
      />
      <g className="trend__line__group">
        <TrendLine
          data={data.slice(0, data.length - 1).map((d, idx) => {
            const nextDataPoint = data[idx + 1];
            return {
              ...d,
              x1: xScale(d.dt) + xScale.bandwidth() / 2,
              x2: xScale(nextDataPoint.dt) + xScale.bandwidth() / 2,
              y1: yScale(d.average),
              y2: yScale(nextDataPoint.average),
              direction: nextDataPoint.change < 0 ? "negative" : "positive",
            };
          })}
        />
      </g>
      {data.map((d, i) => {
        return (
          <VoronoiCell
            key={`voronoi-cell-for-${i}`}
            formatter={SINGLE_DECIMAL_FORMATTER}
            d={voronoi.renderCell(i)}
            defaultCurrentPoint={defaultCurrentPoint}
            setCurrentPoint={setCurrentPoint}
            item={d}
            additionalText={"tests"}
          />
        );
      })}
    </g>
  );
};
Tests.defaultProps = {
  innerHeight: 100,
  innerWidth: 100,
  xScale: () => null,
  filteredDataPoints: [],
};
export default Tests;
