import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

const DownloadLink = ({ href }) => {
  return (
    <a href={href} fontSize="small" className="download-link" download>
      <GetAppIcon className="download-link__icon" />
    </a>
  );
};

DownloadLink.defaultProps = {};
export default DownloadLink;
