import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import BubbleLayerSelect from "./BubbleLayerSelect";
import Legend from "./Legend";

const useStyles = makeStyles((theme) => ({
    bubbleContainer: {
        height: 'auto',
        margin: '20px 0 0 0',
        padding: '3px 15px 0 15px'
    }
}));

const BubbleLayerContainer = ({selectedBubbleLayer}) => {
    const classes = useStyles();

    return (
        <div className={classes.bubbleContainer}>
            <BubbleLayerSelect selectedBubbleLayer={selectedBubbleLayer}/>
            <Legend selectedBubbleLayer={selectedBubbleLayer}/>
        </div>
    );
};
export default BubbleLayerContainer;
