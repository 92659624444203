import { request } from "redux/util/fetch";
import addThousandsSeparator from "util/addThousandsSeparator";
import { static_asset_path } from "util/hooks/useFetch";

export const newCasesCountryInfoURL =
  "https://jhucoronavirus.azureedge.net/data/new_cases_country_info.json";

export const getNewCasesCountryInfo = async () => {
  const data = await request(newCasesCountryInfoURL).catch((error) =>
    console.log(error)
  );
  return data;
};

export const newCasesCountryData = (countryInfo) => {
  return Object.keys(countryInfo.trend).map((country, i) => ({
    name: country,
    trend: countryInfo.trend[country],
    days_since_1st_conf: countryInfo.days_since_1st_conf[country],
    date_first_confirmed: new Date(
      countryInfo.date_first_confirmed[country]
    ).toLocaleDateString(),
    confirmed: addThousandsSeparator(countryInfo.confirmed[country]),
    deaths: addThousandsSeparator(countryInfo.deaths[country]),
    src: `${static_asset_path}timeline_newcases_date_${i}.html`,
  }));
};
