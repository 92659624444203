import React, { useRef, useState } from "react";
import styles from "./TabBar.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Tab, { TabBaseProps } from "./Tab";
const cx = classNames.bind(styles);

const TabBar = ({
  type = "highlight",
  tabs = [],
  newTabs = [],
  onChange,
  className,
  forcedTab = null,
}) => {
  const containerRef = useRef(null);
  const [activeTab, setActiveTab] = useState(forcedTab ? forcedTab : tabs[0]);
  const handleChange = (selectedTab) => {
    setActiveTab(selectedTab);
    if (onChange) onChange(selectedTab);
  };
  return (
    <div
      ref={containerRef}
      className={cx(styles.base, styles[type], className)}
      onChange={handleChange}
    >
      {!!tabs &&
        tabs.map((tab, i) => {
          // const props = typeof tab === "string" ? { label: tab } : { ...tab };
          return (
            <Tab
              key={i}
              label={tab}
              isNew={!!newTabs?.find((t) => t === tab)}
              type={type}
              activeTab={activeTab}
              onChange={handleChange}
            />
          );
        })}
    </div>
  );
};

TabBar.propTypes = {
  ...TabBaseProps,
  tabs: PropTypes.array.isRequired,
};

export default TabBar;
