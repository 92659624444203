import { json } from 'd3-fetch';

export const getData = () => {
  return new Promise((resolve, reject) => {
    json('/datasets/state_timeline.json')
      .then(data => {
        return resolve(data);
      })
      .catch(err => {
        console.log(err);
        return reject(err);
      });
  });
};
