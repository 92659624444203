import React from 'react';

import Page from 'components/layout/Page';
import { Accordion, AccordionItem } from 'components/common/Accordion';
import Button from 'components/common/Button';
import ReactMarkdown from 'react-markdown';
import { getPage } from 'data/util';

import classNames from 'classnames/bind';
import styles from './TracingPrinciples.module.scss';
const cx = classNames.bind(styles);

const isDownloadLink = ({ children }) =>
  (children?.[0]?.props?.children || '').toLowerCase().includes('download');

const TracingPrinciples = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  return (
    <Page>
      <div className={cx('container')}>
        <ReactMarkdown
          // TODO: replace with CMS content
          source={`
[Download Principles PDF](/downloads/JHU_Digital_Tech_Guiding_Principles.pdf)
[Full Report](https://muse.jhu.edu/book/75831)
          `}
          renderers={{
            link: ({ href, children }) => (
              <Button
                icon={isDownloadLink({ children }) ? 'download' : 'right'}
                targetBlank={isDownloadLink({ children })}
                href={href}
              >
                {children}
              </Button>
            ),
          }}
        />

        <Accordion className={cx('accordion')}>
          {[1, 2, 3, 4, 5]
            .map((i) => `contact-tracing-accordion-${i}`)
            .map((slug) => content_blocks[slug])
            .map(
              (block) =>
                block && (
                  <AccordionItem
                    key={block.slug}
                    topContent={
                      <div className={cx('accordion-trigger-content')}>
                        {block.media[0] && <img src={block.media[0]} alt="" />}
                        <h3>{block.title}</h3>
                      </div>
                    }
                  >
                    <ReactMarkdown
                      className={cx('accordion-content')}
                      source={block.content}
                      renderers={{
                        listItem: ({ children }) => (
                          <li>
                            <span>{children}</span>
                          </li>
                        ),
                      }}
                    />
                  </AccordionItem>
                )
            )}
        </Accordion>

        <ReactMarkdown
          source={content_blocks['tracing-ethics-data-source']?.content}
        />
      </div>
    </Page>
  );
};

export default TracingPrinciples;
