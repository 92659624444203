import React from "react";
import PropTypes from "prop-types";
import styles from "./OverviewNotes.module.scss";
import HFormat from "components/common/HFormat";
import { getRegionDataNotes, getRegionGeneralNotes } from "data/geo";
import OverviewDataNote from "./OverviewDataNote";
import { ReactComponent as NotesIcon } from "./Icons/notes.svg";
import ScrollTarget from "components/common/PageNavigator/ScrollTarget";
import { accessibilityProps_IMG } from "util/accessibility";

const OverviewNotes = ({ regionName, regionCode, regionType, isEmbed }) => {
  const allDataNotes =
    getRegionDataNotes(regionCode, regionType === "state") || [];
  const generalNotes = getRegionGeneralNotes(1) || [];
  // Get the last region data note
  const latestNote = allDataNotes[0];
  // Extract the permanotes
  const permaNotes = allDataNotes.filter(
    (note) => note !== latestNote && !note.date
  );
  const shownNotes = permaNotes.concat(latestNote);

  if (shownNotes?.length || generalNotes?.length) {
    const isCountry = regionType === "country";
    return (
      <>
        <ScrollTarget id={`data-notes`} />
        <div
          className={`${styles.base} ${isCountry ? styles.noMarginTop : null}`}
        >
          <HFormat classNames={styles.iconHeader} size={2} noMarginTop>
            <NotesIcon {...accessibilityProps_IMG("Notes")} />
            Notes
          </HFormat>
          <div className={styles.notesContainer}>
            {!!shownNotes &&
              shownNotes?.[0] &&
              shownNotes.map((note, i) => (
                <OverviewDataNote
                  key={`overview-data-note${regionName}${i}`}
                  {...note}
                  regionName={regionName}
                  regionCode={regionCode}
                  isCountry={isCountry}
                  isEmbed={isEmbed}
                  viewAllLink={i === shownNotes.length - 1}
                />
              ))}
            {!!generalNotes && (
              <>
                {shownNotes?.[0] && (
                  <div className={styles.generalNotesHeader}>General Notes</div>
                )}
                {/* If no region-specific data notes above, dont render this header */}
                {generalNotes.map((note, i) => (
                  <OverviewDataNote
                    key={`overview-data-general-note${regionName}${i}`}
                    {...note}
                    isEmbed={isEmbed}
                    viewAllLink={true}
                  />
                ))}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
  return null;
};

OverviewNotes.prototype = {
  regionName: PropTypes.string.isRequired,
  regionCode: PropTypes.string.isRequired,
  regionType: PropTypes.string.isRequired,
  isEmbed: PropTypes.bool,
};

export default OverviewNotes;
