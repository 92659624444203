import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import styles from "./VaccinesFAQ.module.scss";
import cx from "classnames";
import { getPage } from "data/util";

const VaccinesFAQ = ({ match, location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["vaccines"],
    });
  }, []);

  useEffect(() => {
    // Abort early in testing
    if (navigator.userAgent.indexOf("jsdom") > -1) return;

    if (!!location.hash) {
      const el = document.getElementById(location.hash.slice(1));
      if (el === null) {
        return;
      }

      const offsetTop = el
        ? el.getBoundingClientRect().top + window.pageYOffset
        : 0;

      window.scrollTo({
        top: offsetTop - 160,
        behavior: "smooth",
      });
    }
  }, [location.hash]);

  const pageContent = {
    title: "Vaccines FAQ",
    description:
      "These FAQs provide answers to some of the most frequently asked questions on COVID-19 vaccines.",
  };

  return (
    <Page
      id={styles.pageId}
      page={{
        section: "Vaccines",
        title: pageContent.title,
        subtitle: pageContent.description,
        meta: {title: pageContent.title},
      }}
      imgSrc="/images/virus-placeholders/covid-13.jpg"
    >
      <div className={cx(styles.container, styles.question)}>
        <ReactMarkdown source={content_blocks["vaccine-faq-qa-content"]?.content}/>
      </div>
    </Page>
  );
};

export default VaccinesFAQ;
