import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomSelect from "../../CustomSelect";
import { DEFAULT_MAP_DATASET, valueAccessor } from "../../../../constants";

const DatasetSelect = () => {
  const { dataset = DEFAULT_MAP_DATASET } = useParams();
  const datasets = useSelector(
    (state) => state.testingTracker.datasets
  )?.filter(
    (d) =>
      d.id !== "new-cases-per-100-k-people" &&
      d.id !== "weekly-change-in-new-cases"
  );
  const selectedDataset = datasets.find((d) => valueAccessor(d) === dataset);

  const history = useHistory();
  const routeToSelectedDataset = (id) =>
    history.push(`/testing/tracker/map/${id}`, {
      noScroll: true,
    });

  return (
    <div className="dataset__select">
      <CustomSelect
        items={datasets}
        selectedItem={selectedDataset}
        label="Display a different dataset"
        id="dataset-select"
        onChange={routeToSelectedDataset}
      />
    </div>
  );
};
DatasetSelect.defaultProps = {};
export default DatasetSelect;
