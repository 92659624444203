import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {NEGATIVE_COLOR, POSITIVE_COLOR} from '../../../../../constants';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
    line: {
        strokeWidth: '2px',
        stroke: POSITIVE_COLOR,
        fill: 'none',
    },
    negative: {
        stroke: NEGATIVE_COLOR,
    },
}));

const ClipPathLine = ({d, direction, stateId}) => {
    const classes = useStyles();
    return (
        <path
            className={clsx(classes.line, direction === 'above-threshold' ? classes.negative : '')}
            d={d}
            clipPath={`url(#clip-${direction}-for-${stateId}`}
        />
    );
};
ClipPathLine.defaultProps = {
    d: '',
    stateId: '',
    direction: ''
};
export default ClipPathLine;
