import { slugify } from "data/geo";
import { useState } from "react";

export type TFilterOption = {
  label: string;
  value: string;
  placholder?: string;
  data?: any;
};

export type TGenericSingleSelectProps = {
  options: string[] | TFilterOption[];
  currentSelect?: TFilterOption["value"] | number;
  allOption?: boolean;
  placeholder?: string;
};

export const allSelectOption: TFilterOption = { label: "All", value: "*" };

export const arrayToFilterOption = (array: string[]): TFilterOption[] =>
  array.map((title) => {
    return {
      value: `${slugify(title)}`,
      label: title,
      placholder: null,
    } as TFilterOption;
  });

export const createMockSelectOptions: (
  num: number,
  label?: string
) => TFilterOption[] = (num, label = "Option"): TFilterOption[] => {
  return arrayToFilterOption(
    new Array(num).fill(null).map((_, index) => `${label} ${index + 1}`)
  );
};

export type TUseSingleSelectProps = {
  allOption?: boolean;
  currentSelect?: TFilterOption["value"] | number;
  onSelect?: (selectedOption: TFilterOption) => void;
  placeholder?: string;
};

const useSingleSelect = (
  options: string[] | TFilterOption[],
  props: TUseSingleSelectProps = {
    allOption: true,
    currentSelect: null,
    onSelect: null,
    placeholder: null,
  }
) => {
  const { allOption, currentSelect, onSelect } = props;

  //If array of strings is received, format to select options
  const selectOptions =
    typeof options[0] === "string"
      ? arrayToFilterOption(options as Array<string>)
      : (options as TFilterOption[]);
  const allOptions = allOption
    ? [allSelectOption].concat(selectOptions)
    : selectOptions;

  //Convert currentSelect to option value if number received,
  //if currentSelect is fed a number, ignore the allSelectOption
  //use modulus to prevent invalid array index
  const preSelected: TFilterOption["value"] =
    typeof currentSelect === "number"
      ? selectOptions[Math.abs(currentSelect) % selectOptions.length].value
      : currentSelect;

  //Use pre-select or alloption if enabled
  const [selected, setSelected] = useState<TFilterOption>(
    allOptions.find((option) => option.value === preSelected) ||
      (allOption ? allOptions[0] : null)
  );

  if (selected && (!props.placeholder || selected !== allSelectOption)) {
    //If the option has placeholder text favor that
    props.placeholder = selected.placholder || selected.label;
  }

  const requestSelect = (selectedOption: TFilterOption) => {
    if (!selectedOption) return;
    setSelected(selectedOption);
    if (onSelect) onSelect(selectedOption);
  };

  return {
    allOptions,
    requestSelect,
    selected,
    placeholderText: props.placeholder,
  };
};

export default useSingleSelect;
