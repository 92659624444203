import React from "react";
import classNames from "classnames";

import { getPage, getSnapshotForRoute } from "data/util";
import { getCards } from "data/data-viz";
import { mapChartsToCards } from "components/layout/ArticleCard/utils";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import styles from "./ChartsNavContent.module.scss";

const cx = classNames.bind(styles);

const ChartsNavContent = () => {
  const page = getPage("/data");
  const { content_blocks } = page;
  const chartsBlock = content_blocks["critical-trends"];
  const cards = getCards({ section: "Trends" });
  const articles = mapChartsToCards(cards).map((card) => ({
    ...card,
    dark: true,
    imageSrc: card.imageSrc || getSnapshotForRoute(card.href, true),
  }));
  return (
    <div className={cx("base")}>
      <p>{chartsBlock.content}</p>
      <ArticleGridLayouts.Flexible articles={articles} />
    </div>
  );
};

export default ChartsNavContent;
