import React, {useCallback} from 'react';
import { makeStyles } from '@material-ui/core';
import {EVENT_TYPE_COLORS, RADIUS} from '../../../constants';
import {COLORS} from '../../../../Shared/constants';
import {useDispatch, useSelector} from "react-redux";
import {toggleLegendVisibility} from "../../../redux/modules/global";

const useStyles = makeStyles(() => ({
  legendWrapper: {
  },
  legendHeading: {
    margin: '5px 0 10px',
    fontSize:'15px'
  },
  legendItems: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '-5px',
  },
  legendItem: {
    display: 'flex',
    marginBottom: '5px',
    fontSize: '14px',
    // cursor: 'pointer',
    color: COLORS.gray,
    '&:not(:last-child)': {
      marginRight: '20px'
    }
  },
  legendItemKey: {
    height: RADIUS * 2,
    width: RADIUS * 2,
    borderRadius: '100%',
    margin: '6px 5px 6px 0',
  },
  legendItemLabel: {

  }
}));

const Legend = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const legendItems = useSelector(state => state.stateTimeline.legendItems);
  const updateLegendVisibility = useCallback(id => dispatch(toggleLegendVisibility(id)), [dispatch]);

  return (
    <div className={classes.legendWrapper}>
      <h2 className={classes.legendHeading}>
        Recent Opening and Closing Policy Decisions
      </h2>
      <div className={classes.legendItems}>
        {legendItems.map(({ label, id }) => {
          return (
            <div
              className={classes.legendItem}
              onClick={() => updateLegendVisibility(id)}
              key={`legend-item-for-${id}`}
            >
              <div
                className={classes.legendItemKey}
                style={{ backgroundColor: EVENT_TYPE_COLORS[id] }}
              ></div>
              <span className={classes.legendItemLabel}>{label}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

Legend.defaultProps = {};
export default Legend;
