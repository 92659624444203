import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {
    PERCENT_FORMATTER,
    PERCENT_POSITIVE,
    STATE_NAME,
} from '../../../../constants';
import BaseChart from "../../Table/StateRow/BaseChart";
import PercentPositive from "../../Table/StateRow/PercentPositive";
import NoData from "../../NoData";

const StateRow = ({filteredData, stateCell = true}) => {
    return (
        <TableRow className="table__row">
            {stateCell && (
                <TableCell className="table__cell table__cell--state positivity">
                    <span className="table__state-name">{filteredData[STATE_NAME]}</span>
                </TableCell>
            )}
            {filteredData.values[PERCENT_POSITIVE].map(row => {
                const {selected, approachId, values} = row;

                const percentPositive = values.length ? values[values.length - 1].average: NaN;

                return values.length ? <TableCell className={`table__cell ${selected ? 'selected' : ''} positivity`}
                                                      key={`table-cell-chart-for-approach-${approachId}`}>
                    <div className="table__chart">
                        <BaseChart className="visualization__container">
                            <PercentPositive data={values}
                                             stateName={filteredData[STATE_NAME]}/>
                        </BaseChart>
                        <div className="number number--teal">{`${PERCENT_FORMATTER(percentPositive)} positive`}</div>
                    </div>
                </TableCell> : <NoData key={`no-data-for-approach-${approachId}`}/>
            })}
        </TableRow>
    );
};
StateRow.defaultProps = {
    filteredData: {},
    stateCell: true
};
export default StateRow;
