import React, { useRef, useEffect, useState } from "react";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisLeft, axisBottom } from "d3-axis";
import { select } from "d3-selection";
import styles from "./Bar.module.scss";

const Bar = ({ data, dataset: { value, label }, selectedTab }) => {
  const ref = useRef(null);
  const svgContainerRef = useRef(null);
  const [width, setWidth] = useState(600);
  const height = 600;
  /**
   * Screen and window resize manager
   */
  useEffect(() => {
    function handleResize() {
      requestAnimationFrame(() => {
        // If we're able to get the SVG's parent,
        // set the height and width to be the same as the parent's
        if (svgContainerRef?.current) {
          const parent = svgContainerRef.current.parentElement;
          const parentSize = {
            width:
              window.innerWidth < 970
                ? parent.offsetWidth * 0.9
                : parent.offsetWidth * 0.5,
          };

          setWidth(parentSize.width);
        } else {
          // Else, set it to attempt to fit the chart
          // based on the size of the window
          setWidth(
            typeof window === "undefined"
              ? 100
              : window.innerWidth >= 1248
              ? 600
              : window.innerWidth < 970
              ? window.innerWidth
              : window.innerWidth * 0.5
          );
        }
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [svgContainerRef]);

  useEffect(() => {
    const days = ["mon", "tue", "wed", "thur", "fri", "sat", "sun"];
    const daysLabels = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];
    const margin = { top: 80, right: 30, bottom: 60, left: 80 };
    const innerwidth = width - margin.left - margin.right;
    const innerheight = height - margin.top - margin.bottom;
    const values = days.map(
      (d) => data.filter((p) => p[d] === "Y" && p.dataset === label).length
    );
    const svg = select(ref.current);
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    const x = scaleBand()
      .domain(daysLabels)
      .range([0, innerwidth])
      .padding(0.2);
    const y = scaleLinear().domain([0, 60]).range([innerheight, 0]).nice();
    group
      .selectAll(".rect")
      .data(values)
      .join("rect")
      .attr("x", (d, i) => x(daysLabels[i]))
      .attr("y", (d) => y(d))
      .attr("width", x.bandwidth())
      .attr("height", (d) => innerheight - y(d))
      .attr("fill", "#2670B7")
      .attr("fill-opacity", 0.6);
    group
      .selectAll(".labels")
      .data(values)
      .join("text")
      .attr("x", (d, i) => x(daysLabels[i]) + x.bandwidth() / 2)
      .attr("y", (d) => y(d) - 10)
      .attr("text-anchor", "middle")
      .attr("fill", "#666")
      .text((d) => d);
    group.append("g").call(axisLeft(y).ticks(6));
    const xAxis = group
      .append("g")
      .attr("transform", `translate(0,${innerheight})`)
      .call(axisBottom(x));
    group
      .append("text")
      .attr("transform", `translate(-40,${innerheight / 2}) rotate(270)`)
      .attr("text-anchor", "middle")
      .attr("fill", "#aaa")
      .text("Number of States/Territories Reporting Data");
    if (innerwidth < 400) {
      xAxis
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-65)");
    }
    return () => {
      group.remove();
    };
  }, [width, height, data, label]);

  return (
    <div className={styles.base} ref={svgContainerRef}>
      <svg width={width} height={height} ref={ref}></svg>
    </div>
  );
};

export default Bar;
