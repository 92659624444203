import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import MediaQuery from "react-responsive";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./NewCases.module.scss";
import CountryPlot from "./CountryPlot";
import { DataTimeFetched } from "components/common/DataMeta";
import EmbedFrame from "components/common/EmbedFrame";
import SideNarrative from "components/layout/SideNarrative";
import Button from "components/common/Button";
import { static_asset_path, useFetch } from "util/hooks/useFetch";
import {
  newCasesCountryData,
  newCasesCountryInfoURL,
} from "data/data-viz/new-cases";

const NewCases = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;
  const [limit, setLimit] = useState(10);

  const [countryInfo, isLoading] = useFetch(newCasesCountryInfoURL);
  let data;
  if (!isLoading) {
    data = newCasesCountryData(countryInfo);
  }

  const expandOrCollapse = data ? !limit < data.length : true;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "new-cases",
    });
  }, []);

  return (
    <SideNarrative
      narrative={
        <>
          <DataTimeFetched
            URL={"https://jhucoronavirus.azureedge.net/data/ts/new_cases.json"}
          />
          <ReactMarkdown
            source={content_blocks["has-the-curve-flattened"].content}
          />
        </>
      }
    >
      <div className={styles.container}>
        <div className={styles.mainPlotHeader}>
          <ReactMarkdown
            source={
              content_blocks["new-cases-in-world-countries-plot-header"].content
            }
          />
        </div>
        <div className={styles.mainPlot}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}timeline_newcases_date_all_360.html`}
              width={360}
              height={275}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}timeline_newcases_date_all_720.html`}
              width={720}
              height={450}
              resize={true}
            />
          </MediaQuery>
        </div>
        {!isLoading && !!data && (
          <div className={styles.countryPlots}>
            {data.slice(0, limit).map((country) => (
              <CountryPlot key={country.name} {...country} />
            ))}
          </div>
        )}
        <Button
          icon={expandOrCollapse ? "down" : "x"}
          onClick={() => setLimit(expandOrCollapse ? Infinity : 10)}
        >
          {expandOrCollapse ? "View all" : "View less"}
        </Button>
        <br />
        <br />
      </div>
    </SideNarrative>
  );
};

export default NewCases;
