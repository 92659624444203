import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as GTM from "util/gtm";
import { request } from "redux/util/fetch";
import styles from "./USState.module.scss";
import RegionOverview from "../RegionOverview";
import { states, findStateCode, expandStateCode, slugify } from "data/geo";
import racialData from "data/data-viz/racial-data-transparency/index";
import { retrieveData as retrieveTestingData } from "components/content/Testing/TestingTracker/redux/modules/global";
import { retrieveData as retrieveTimelineData } from "components/content/DataCenter/charts/StateTimeline/redux/modules/global";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";
import EquitableAccessToTesting from "components/content/DataCenter/charts/EquitableAccessToTesting";
import RegionPage from "../RegionPage";
import { shouldRenderAsEmbed } from "../RegionPage/util";
import ScrollTarget from "components/common/PageNavigator/ScrollTarget";
import { useFetch } from "util/hooks/useFetch";
import LearnMore from "./LearnMore";
import StateCountyInsights from "./StateCountyInsights";
import DataTimeline from "components/content/DataCenter/charts/RegionDataTimeline";
import RegionHospitalization from "../../ByRegion/RegionHospitalization";
import OverviewNotes from "../RegionNotes/OverviewNotes";

const USStateValidator = (props) => {
  // Bounce if state not found...
  const searchStateSlug = props.match.params.stateId;
  const stateCode = findStateCode(searchStateSlug);
  const history = useHistory();
  if (stateCode === null) {
    history.replace("/404");
    return null;
  } else {
    return <USState key={`${stateCode}-${searchStateSlug}`} {...props} />;
  }
};

const setGTM = (searchStateSlug) => {
  GTM.push({
    contentCountry: undefined,
    contentState: searchStateSlug,
  });
};

const USState = ({ match }) => {
  const { pathname } = useLocation();

  // Assign state
  const searchStateSlug = match.params.stateId;
  const stateCode = findStateCode(searchStateSlug);
  const stateName = expandStateCode(stateCode);
  const stateSlug = slugify(stateName);
  const dispatch = useDispatch();
  setGTM(searchStateSlug);

  const disclaimerBlock = getPage("/region/us")?.content_blocks[
    "region-disclaimer"
  ];

  const renderAsEmbed = shouldRenderAsEmbed();

  // Fetch data that’s common to all states only once
  useEffect(() => {
    dispatch(retrieveTestingData());
    dispatch(retrieveTimelineData());
  }, [dispatch]);

  // Fetch state-specific data every time the state changes
  const [data, setData] = useState(null);
  const [movingAvgData, setMovingAvgData] = useState(null);

  useEffect(() => {
    let mounted = true;
    setGTM(searchStateSlug);
    // setData(null); // stop displaying old state's data

    (async () =>
      await request(
        `https://jhucoronavirus.azureedge.net/api/v2/regions/us/${stateCode.toLowerCase()}.json`
      ).then((response) => {
        if (mounted) setData(response);
      }))();

    (async () => {
      const sets = [
        { name: "cases", key: "7-day_avg" },
        { name: "deaths", key: "7-day_avg" },
        { name: "testing", key: "7-day_avg_positivity" },
      ];
      const data = {};
      await Promise.all(
        sets.map((d) =>
          fetch(
            `https://jhucoronavirus.azureedge.net/api/v3/timeseries/us/${d.name}/${stateCode}.json`
          ).then((response) => response.json())
        )
      ).then((resp) => {
        if (mounted) {
          for (let index = 0; index < resp.length; index++) {
            const subLength = Object.keys(resp[index]).length;
            data[sets[index].name] = Object.values(resp[index])[subLength - 1][
              sets[index].key
            ];
          }
          setMovingAvgData(data);
        }
      });
    })();

    return () => {
      mounted = false;
    };
  }, [dispatch, stateCode, searchStateSlug, stateName]);

  const [allVaccData, isAllVaccLoading] = useFetch("state_vaccination_rates");
  const vaccineData = !isAllVaccLoading
    ? allVaccData.find((item) => item.state === stateName)
    : null;

  return (
    <RegionPage
      regionCode={stateCode}
      regionName={stateName}
      regionSlug={stateSlug}
      isState={true}
      regionMenuProps={{
        title: "United States",
        regions: states,
        path: "/region/us/",
      }}
    >
      <ScrollTarget id={`state-overview`} path={pathname} />
      <RegionOverview
        data={data}
        movingAvgData={movingAvgData}
        vaccineData={vaccineData}
        regionName={stateName}
        regionSlug={stateSlug}
        regionCode={stateCode}
        regionType={"state"}
        isEmbed={renderAsEmbed}
      />

      <div className={styles.sectionsContainer}>
        {/* DATA TIMELINE */}
        <ScrollTarget id={`data-timeline`} />
        <DataTimeline isGlobal={false} regionCode={stateCode} />

        {/* HOSPITALIZATIONS */}
        <ScrollTarget id={`hospitalizations`} />
        <RegionHospitalization regionName={stateName} />

        {/* DATA NOTES */}
        <OverviewNotes
          key={`region-about-${stateName}`}
          regionName={stateName}
          regionCode={stateCode}
          regionType={"state"}
          isEmbed={renderAsEmbed}
        />

        {/* COUNTY MAPS */}
        {data && (
          <>
            <ScrollTarget id={`county-data-and-demographics`} path={pathname} />
            <EquitableAccessToTesting data={data} stateCode={stateCode} />
          </>
        )}

        {/* LEARN MORE */}
        <ScrollTarget id={`civic-data`} path={pathname} />
        <LearnMore
          stateName={stateName}
          racialData={racialData}
          renderAsEmbed={renderAsEmbed}
        />

        {/* STATE COUNTY INSIGHTS  */}
        <ScrollTarget id={`state-county-insights`} path={pathname} />
        <StateCountyInsights
          stateName={stateName}
          stateSlug={stateSlug}
          renderAsEmbed={renderAsEmbed}
        />
      </div>

      <ReactMarkdown source={disclaimerBlock?.content} />
    </RegionPage>
  );
};

export default USStateValidator;
