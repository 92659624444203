import React, { Component } from "react";

import { getPage } from "data/util";

import Meta from "components/common/Meta";
import * as GTM from "util/gtm";

import SunsetHomepage from "components/sunset/SunsetHomepage/SunsetHomepage";

class Home extends Component {
  componentDidMount = () => {
    GTM.clear();
  };

  render() {
    const page = getPage(this.props.location.pathname);
    return (
      <>
        <Meta {...page} />
        <SunsetHomepage />
      </>
    );
  }
}

export default Home;
