export const demographicTableData = [
  "https://jhucoronavirus.azureedge.net/api/v2/demographics/age_demographics_table.json",
  "https://jhucoronavirus.azureedge.net/api/v2/demographics/ethnicity_demographics_table.json",
  "https://jhucoronavirus.azureedge.net/api/v2/demographics/gender_sex_demographics_table.json",
  "https://jhucoronavirus.azureedge.net/api/v2/demographics/race_demographics_table.json",
  "https://jhucoronavirus.azureedge.net/api/v2/demographics/race_ethnicity_demographics_table.json",
];

export const demographicOptions = [
  {
    value: "age",
    label: "Age",
    url:
      "https://jhucoronavirus.azureedge.net/api/v2/demographics/age_demographics.json",
  },
  {
    value: "ethnicity",
    label: "Ethnicity",
    url:
      "https://jhucoronavirus.azureedge.net/api/v2/demographics/ethnicity_demographics.json",
  },
  {
    value: "gender_sex",
    label: "Gender/Sex",
    url:
      "https://jhucoronavirus.azureedge.net/api/v2/demographics/gender_sex_demographics.json",
  },
  {
    value: "race",
    label: "Race",
    url:
      "https://jhucoronavirus.azureedge.net/api/v2/demographics/race_demographics.json",
  },
  {
    value: "race_ethnicity",
    label: "Race & Ethnicity",
    url:
      "https://jhucoronavirus.azureedge.net/api/v2/demographics/race_ethnicity_demographics.json",
  },
];

export const datasets = [
  {
    value: "cases",
    label: "Cases",
    color: "#e88610", //'#F2A241'
  },
  {
    value: "deaths",
    label: "Deaths",
    color: "#dc3a20", //'#E87563'
  },
  {
    value: "tests",
    label: "Tests",
    color: "#3042c7", //'#7A86DF'
  },
  {
    value: "vaccines",
    label: "Vaccines",
    color: "#7a9138", //'#A4BF58'
  },
];
