import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styles from "./RenderVideoFrame.module.scss";

const RenderVideoFrame = ({
  videoLinkType,
  videoUrl,
  mediaHeight,
  iframeProps,
  isImage
}) => {

  const frameRef = useRef(null);
  const ifrmProps = {
    style: { border: 0, height: `${mediaHeight}px`},
    allowFullScreen: true,
    allow:
      "webkitallowfullscreen accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
    frameBorder: "0",
    gesture: "media",
    ...iframeProps,
  };

  useEffect(() => {
    if (isImage && frameRef?.current) {
      frameRef.current.style.background = `url(${videoUrl})`;
      frameRef.current.style.backgroundRepeat = "no-repeat";
      frameRef.current.style.backgroundSize = "contain";
      frameRef.current.style.backgroundPosition = "center";
    };
  },[frameRef, isImage, ifrmProps, videoUrl])

  if (!videoUrl) return null;
  if (isImage) return <div ref={frameRef} className={styles.image} {...ifrmProps}/>;
  
  switch (videoLinkType) {
    case 0: //IMB
      return (
        <iframe
          id="UStreamPlayer"
          title="IBM"
          src={videoUrl}
          {...ifrmProps}
          frameBorder="no"
        />
      );
    default:
      let lk = videoUrl.replace("youtu.be/", "youtube.com/embed/");
      // Fix the embed link to start from the supplied time
      lk = lk.replace("t=", "start=");
      return <iframe title="Youtube/Vimeo" src={lk} {...ifrmProps}></iframe>;
  };
};

RenderVideoFrame.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  mediaHeight: PropTypes.number.isRequired,
  videoLinkType: PropTypes.number,
  iframeProps: PropTypes.object
};

export default RenderVideoFrame;