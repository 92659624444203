import React from "react";
import ReactMarkdown from "react-markdown";
import Speakers from "../../Speakers";
import baseStyles from "../EventMod.module.scss";

export const FromDiscovery_1_25_21 = ({
  slug = "covid-19-vaccine-from-discovery-to-delivery",
  contentSrc,
}) => {
  const path = `/images/vaccine-events/speakers/${slug}`;

  const speakers = [
    {
      name: "Bill Enright",
      title: "Chief Executive Officer of Vaccitech",
      image: `${path}/bill-enright.jpg`,
    },
    {
      name: "Abigail Jenkins",
      title: "Senior Vice President",
      image: `${path}/abigail-jenkins.jpeg`,
    },
    {
      name: "Daniel Polsky, PhD",
      title: "Professor in Health Economics",
      image: `${path}/daniel-polsky.jpg`,
    },
    {
      name: "Reed Tuckson, MD, FACP",
      title: "Director of Tuckson Health Connections",
      image: `${path}/reed-tuckson.jpg`,
    },
  ];

  const content = () => (
    <div>
      {!!contentSrc && (
        <ReactMarkdown
          className={baseStyles.embedDescription}
          source={contentSrc}
        />
      )}
      <Speakers authors={speakers} />
    </div>
  );

  return {
    slug,
    content,
  };
};
