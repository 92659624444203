import React, { useEffect, useRef } from 'react';
import { select } from 'd3-selection';
import { axisBottom } from 'd3-axis';

const Axes = ({ xScale, colorScale, xScaleTickFormat, height }) => {
  const xAxisRef = useRef(null);

  useEffect(() => {
    renderXAxis();
  });

  const renderXAxis = () => {
    const xAxis = select(xAxisRef.current);

    // Ensure the starting tick values are included in the legend
    let tickValues = colorScale.range().map(d => colorScale.invertExtent(d)[0]);
    if (tickValues[0] === undefined) {
      tickValues[0] = 0;
    }

    xAxis.call(
      axisBottom(xScale)
        .tickFormat(xScaleTickFormat)
        .tickValues(tickValues),
    );

    xAxis.select('.domain').remove();
    xAxis.selectAll('.tick line').remove();
  };

  return (
    <g className="axis">
      <g className="x--axis" transform={`translate(0,${height})`} ref={xAxisRef} />
    </g>
  );
};

const defaultProps = {
  xScale: null,
  colorScale: null,
  height: 0,
  xScaleTickFormat: null,
};

Axes.defaultProps = defaultProps;

export default Axes;
