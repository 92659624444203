import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLORS, DURATION} from "../../../../../../Shared/constants";

const useStyles = makeStyles(() => ({
    path: {
        strokeWidth: '1px',
        fill: 'white',
        cursor: 'pointer',
        transition: `${DURATION}ms fill`
    }
}));

const Path = ({feature, d, setActiveMarker, isClickable = false}) => {
    const classes = useStyles();
    const [currentColor, setCurrentColor] = useState('white');

    const {color} = feature;

    useEffect(() => {
        setCurrentColor(color)
    }, [color]);

    const onMouseOver = (evt) => {
        setActiveMarker({
            ...feature,
            top: evt.clientY,
            left: evt.clientX
        });
    }

    const onClick = () => {
        // If the path is clickable, open a new tab for the ESRI infographic
        if (isClickable) {
            window.open(`https://bao.arcgis.com/covid-19/jhu/county/${feature.GEOID}.html`, "_blank");
        }
    }

    return (
        <path
            className={classes.path}
            d={d}
            onMouseOver={onMouseOver}
            onClick={onClick}
            style={{fill: currentColor, stroke: feature.stroke || COLORS.white}}
        />
    );
};

export default Path;
