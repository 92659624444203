import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COMMA_FORMATTER} from "../../../../../../Shared/constants";

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 8
    },
    legendItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10
    },
    square: {
        width: 29,
        height: 29,
        marginRight: 10
    },
    text: {
        fontSize: 18,
        fontWeight: 'normal',
        color: '#56586e',
        lineHeight: `24px`
    }
}));

const Legend = ({colorScale}) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            {
                colorScale
                    .range()
                    .reverse()
                    .map(d => colorScale.invertExtent(d))
                    .map((d, i) => {
                        const [start, end] = d;
                        let text = `${COMMA_FORMATTER(start)} - ${COMMA_FORMATTER(end)}`;
                        if (!i) {
                            text = `> ${COMMA_FORMATTER(start)}`
                        }
                        return (
                            <div key={`legend-item-for-${i}`} className={classes.legendItem}>
                                <div className={classes.square} style={{backgroundColor: colorScale(start)}}/>
                                <div className={classes.text}>{text}</div>
                            </div>
                        )
                    })
            }
        </div>
    );
};
export default Legend;
