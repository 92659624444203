import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import styles from "./NewCases50States.module.scss";
import { getPage } from "../../../../../data/util";
import { DataSources, DataTimeFetched } from "components/common/DataMeta";
import StateGrid from "./StateGrid";
import StateLegend from "./Legend/new_cases_individual_legend_7day.png";
import USLegend1 from "./Legend/USLegend1.png";
import USLegend2 from "./Legend/USLegend2.png";
import USLegend3 from "./Legend/USLegend3.png";
import HFormat from "components/common/HFormat";
import SideNarrative from "components/layout/SideNarrative";
import NewCasesTable from "./NewCasesTable";

const NewCases50States = ({ match }) => {
  const page = getPage("/data/new-cases-50-states");
  const { content_blocks } = page;
  const stateId = match.params.stateId;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "new-cases-50-states",
      contentCountry: stateId ? undefined : "united-states",
      contentState: stateId ? stateId : undefined,
    });
  }, [stateId]);

  return (
    <SideNarrative
      pageProps={{ path: "/data/new-cases-50-states" }}
      narrative={
        <>
          <div className={styles.contentTop}>
            <HFormat size={3} noMarginTop>
              {content_blocks["new-cases-50-states-intro"].title}
            </HFormat>
            <HFormat size={3} noMarginTop>
              {content_blocks["new-cases-50-states-intro"].subtitle}
            </HFormat>
            <DataTimeFetched
              URL={
                "https://jhucoronavirus.azureedge.net/data/ts/new_cases_us.json"
              }
            />
          </div>
          <div className={styles.details}>
            {match.params.stateId ? (
              <>
                <div>
                  <ReactMarkdown
                    source={
                      content_blocks["new-cases-50-states-detail"].content
                    }
                  />
                </div>
                <div>
                  <img src={StateLegend} alt="How to read this graphic." />
                </div>
              </>
            ) : (
              <>
                <div>
                  <ReactMarkdown
                    source={content_blocks["new-cases-50-states-intro"].content}
                  />
                </div>
                <div>
                  <h3>How to use this graphic:</h3>
                  <h5>Click on a state to see more detail.</h5>
                  <div id={styles.USLegend1} className={styles.stateLegendItem}>
                    <img src={USLegend1} alt="Example" />
                    <p>
                      Line shows 7-day moving average of{" "}
                      <strong>new cases per day</strong> in this state. Dot
                      corresponds to most recent day.
                    </p>
                  </div>
                  <div id={styles.USLegend2} className={styles.stateLegendItem}>
                    <img src={USLegend2} alt="Example" />
                    <p>
                      The <span className={styles.greener}>greener</span> the
                      background, the bigger the <strong>downward trend</strong>{" "}
                      of new cases in this state.
                    </p>
                  </div>
                  <div id={styles.USLegend3} className={styles.stateLegendItem}>
                    <img src={USLegend3} alt="Example" />
                    <p>
                      The <span className={styles.redder}>redder</span> the
                      background, the bigger the <strong>upward trend</strong>{" "}
                      of new cases in this state.
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      }
    >
      <div className={styles.container}>
        <StateGrid stateId={stateId} />
        <DataSources dataSet="new-cases-50-states" />
        <NewCasesTable
          activeRow={{
            key: "regionName",
            value: stateId,
          }}
        />
      </div>
    </SideNarrative>
  );
};

export default NewCases50States;
