import PropTypes from "prop-types";

const data = [
  {
    id: "region-us",
    to: "/region/us/",
    exact: false,
    sections: [
      {
        id: "state-overview",
        name: "State Overview",
      },
      {
        id: "vaccine-tracker",
        name: "Vaccine Tracker",
      },
      {
        id: "data-timeline",
        name: "Data Timeline",
      },
      {
        id: "hospitalizations",
        name: "Hospitalizations",
      },
      {
        id: "data-notes",
        name: "Notes",
      },

      {
        id: "county-data-and-demographics",
        name: "County Data and Demographics",
      },
      {
        id: "civic-data",
        name: "Civic Data",
      },
      {
        id: "state-county-insights",
        name: "State & County Insights",
      },
    ],
  },
];

const formatPageSections = (path, pageData) => {
  if (!path || !pageData) return null;
  return pageData.sections?.map((section, i) => {
    return { ...section, to: `${path}#${section.id}` };
  });
};

export const getPageSections = (path) => {
  const pageData = data.find(
    (route) => (route.exact && route.to === path) || path.includes(route.to)
  );
  return formatPageSections(path, pageData);
};

export const pageSectionProps = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export const pageSectionsProps = PropTypes.arrayOf(pageSectionProps);
