import React from 'react';
import PropTypes from 'prop-types';

import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as LeftIcon } from 'images/icons/paginator/left.svg';
import { ReactComponent as RightIcon } from 'images/icons/paginator/right.svg';

import classNames from 'classnames/bind';
import styles from './Paginator.module.scss';
const cx = classNames.bind(styles);

// Parse a URL path into a URL object that has a pathname, search, hash, etc.
const parsePath = (path) => new URL(path, window.location.origin);

function Paginator({
  value,
  getPathForPage,
  max,
  onChange,
  hideNumbers,
  className,
  ...props
}) {
  const min = 1;
  const change = (newVal) => onChange(Math.min(Math.max(newVal, min), max));

  // If we pass 'getPathForPage' instead of 'value', we're in link mode
  const isLinkMode = !!getPathForPage && !value;

  // Is a given number active?
  const location = useLocation();
  const isNumActive = (num) => {
    if (!isLinkMode) return value === num;
    else {
      const { pathname, search } = parsePath(getPathForPage(num));
      return pathname === location.pathname && search === location.search;
    }
  };
  // Which number is active?
  const activeNum = isLinkMode
    ? new Array(max)
        .fill(0)
        .map((_, i) => i + 1)
        .find((n) => isNumActive(n))
    : value;

  // In link mode, return props to add the proper link to a given number
  const linkTo = (pageNum) => {
    if (!isLinkMode) return {};
    const { pathname, search } = parsePath(getPathForPage(pageNum || min));
    return { to: { pathname, search, state: { noScroll: true } } };
  };

  // Render a link in link mode, and a button otherwise
  const ItemComponent = isLinkMode ? Link : 'button';

  // Should the left arrow button be disabled?
  const isLeftDisabled = activeNum <= min;
  // Should the right arrow button be disabled?
  const isRightDisabled = activeNum >= max;

  return (
    <div className={`${cx('base')} ${className}`} {...props}>
      <ItemComponent
        className={cx('arrow', { disabled: isLeftDisabled })}
        {...linkTo(Math.max(activeNum - 1, min))}
        onClick={() => !isLeftDisabled && change(activeNum - 1)}
        disabled={!isLinkMode && isLeftDisabled}
      >
        <LeftIcon />
      </ItemComponent>

      {new Array(hideNumbers ? 0 : max)
        .fill(null)
        .map((_, i) => i + 1)
        .map((num) => (
          <ItemComponent
            key={num}
            className={cx('number', { active: num === activeNum })}
            {...linkTo(num)}
            onClick={() => change(num)}
          >
            {num}
          </ItemComponent>
        ))}

      <ItemComponent
        className={cx('arrow', { disabled: isRightDisabled })}
        {...linkTo(Math.min(activeNum + 1, max))}
        onClick={() => !isRightDisabled && change(activeNum + 1)}
        disabled={!isLinkMode && isRightDisabled}
      >
        <RightIcon />
      </ItemComponent>
    </div>
  );
}

Paginator.propTypes = {
  // Control the component with these
  value: (props, propName, componentName, ...args) => {
    if (props[propName] && props.getPathForPage) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. You can't specify both \`${propName}\` and \`getPathForPage\`.`
      );
    }
    if (!props[propName] && !props.getPathForPage) {
      return new Error(
        `Invalid props supplied to \`${componentName}\`. Exactly one of \`${propName}\` and \`getPathForPage\` is required.`
      );
    }
    return PropTypes.number(props, propName, componentName, ...args);
  },
  // Or this
  getPathForPage: (props, propName, componentName, ...args) => {
    if (props[propName] && props.value) {
      return new Error(
        `Invalid prop \`${propName}\` supplied to \`${componentName}\`. You can't specify both \`value\` and \`${propName}\`.`
      );
    }
    return PropTypes.func(props, propName, componentName, ...args);
  },

  onChange: PropTypes.func,
  max: PropTypes.number.isRequired,
  hideNumbers: PropTypes.bool,
  className: PropTypes.string,
};

Paginator.defaultProps = {
  value: null,
  onChange: () => {},
  hideNumbers: false,
  className: '',
};

export default Paginator;
