import React, {useCallback} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Map from "./Map";
import CustomSelect from "../Shared/CustomSelect";
import {COLORS} from "../../../../../Shared/constants";
import hoverHandImage from "../../../../assets/images/hover-hand.svg";
import Legend from "./Legend";
import {useDispatch, useSelector} from "react-redux";
import selectedCumulativeLayerSelector from "../../../../redux/selectors/cumulative.layer.selector";
import {updateSelectedCumulativeLayer} from "../../../../redux/modules/global";
import {max} from "d3-array";
import * as topojson from "topojson-client";

const useStyles = makeStyles((theme) => ({
    visualizationContainer: {
        display: "flex",
        flexDirection: 'row',
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse'
        },
        height: '100%'
    },
    outerLegendContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: -theme.spacing(1),
        marginRight: '20px',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0'
        },
        maxWidth: '425px'
    },
    legendContainer: {
        display: "flex",
        flexDirection: 'column',
        backgroundColor: '#fbfbfb',
        border: '1px solid #f0f0f0',
        padding: 20
    },
    source: {
        fontSize: '13px',
        color: '#56586e',
        textAlign: 'left',
        marginTop: '18px'
    },
    title: {
        marginTop: 19,
        color: COLORS.blueDark,
        fontWeight: 'bold',
        fontSize: 18,
        marginLeft: 8
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&:first-child': {
            marginRight: 5
        },
        [theme.breakpoints.down('sm')]: {
            '&:first-child': {
                marginBottom: 10,
            }
        },
        margin: '25px 11px 10px 11px'
    },
    description: {
        fontSize: 16,
        lineHeight: '18px',
        color: COLORS.blueDark,
        marginLeft: 10,
        fontStyle: 'italic'
    },
    visualization: {
        width: "60%",
        margin: '20px 0 20px 30px',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'auto',
            marginLeft: '0'
        },
    },
}));

const CumulativeData = ({selectedState}) => {
    const data = useSelector(state => state.equityAccessToTesting.data);
    const classes = useStyles();
    const dispatch = useDispatch();
    const cumulativeLayers = useSelector(state => state.equityAccessToTesting.cumulativeLayers);
    let selectedCumulativeLayer = useSelector(selectedCumulativeLayerSelector);
    const updateCumulativeLayer = useCallback(id => dispatch(updateSelectedCumulativeLayer(id)), [dispatch]);

    let counties;
    try {
        counties = topojson.feature(data, data.objects?.counties);
    } catch (error) {
        console.log(error);
        return null;
    }

    // Set the color scale
    const cumulativeAccessor = d => +d.properties[selectedCumulativeLayer.name];
    selectedCumulativeLayer.colorScale = selectedCumulativeLayer.colorScale.copy()
        .domain([0, max(counties.features, cumulativeAccessor)]);

    return (
        <div className={classes.wrapper}>
            <div className={classes.visualizationContainer}>
                <div className={classes.outerLegendContainer}>
                    <div className={classes.legendContainer}>
                        <div className="cumulative__data__layer__select">
                            <CustomSelect
                                items={cumulativeLayers}
                                selectedItem={selectedCumulativeLayer}
                                labels={[]}
                                id="cumulative-data-layer-select"
                                onChange={updateCumulativeLayer}
                            />
                        </div>
                        <div className={classes.title}>
                            {selectedCumulativeLayer.label}
                        </div>
                        <Legend colorScale={selectedCumulativeLayer.colorScale}/>
                        <div className={classes.innerContainer}>
                            <img src={hoverHandImage}
                                 alt="A hand showing how to interact with the map visualizations"/>
                            <p className={classes.description}>
                                Hover over the map to see county’s data, and click to
                                see detailed infographic of all county metrics
                            </p>
                        </div>
                    </div>
                    <div className={classes.source}>
                        Data Source: Cases and deaths data from <a
                        href="https://github.com/CSSEGISandData/COVID-19" target="_blank" rel="noopener noreferrer">JHU
                        CSSE</a>
                    </div>
                </div>
                <div className={classes.visualization}>
                    <Map selectedState={selectedState} selectedCumulativeLayer={selectedCumulativeLayer}/>
                </div>
            </div>
        </div>
    );
};
export default CumulativeData;
