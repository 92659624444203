import React from "react";

import styles from "./TestingTable.module.scss";
import TFormat from "components/common/TFormat";

const TestingTable = ({ data }) => {
  if (!data.data?.length) return null;
  return (
    <div className={styles.base}>
      <TFormat tableColumns={data.tableColumns} tableData={data.data} />
      <div className={styles.legend}>
        {data.legends.map(({ label, text }) => (
          <div key={label}>
            <strong>{label}:</strong>
            <span> {text}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TestingTable;
