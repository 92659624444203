import {createSelector} from 'reselect';

const cumulativeLayersSelector = state => state.equityAccessToTesting.cumulativeLayers;

const selectedCumulativeLayerSelector = createSelector([cumulativeLayersSelector],
    cumulativeLayers => {
        return cumulativeLayers.find(d => d.isActive);
    });

export default selectedCumulativeLayerSelector;
