import React, { useState } from "react";
import {
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
} from "components/layout/ArticleGrid";
import { getArticleCardsBySection } from "components/layout/ArticleCard/utils";

const VaccineBlogBlade = () => {
  const [isSingleColumn, setIsSingleColumn] = useState(false);
  const articleLimit = isSingleColumn ? 1 : 3; //Infinity;
  const latestCards = getArticleCardsBySection(
    "Vaccine Blogs",
    { category: "Report" },
    articleLimit
  );
  return (
    <ArticleGridLayoutEngine
      articles={latestCards}
      columnLayout={[[9, 3], [8, 4], [6, 6], [12]]}
      gap={{ column: 32, row: 0 }}
      onLayoutChange={(layout) => setIsSingleColumn(layout.length === 1)}
    >
      <ArticleGridLayoutTemplate.SingleCard
        articleCardProps={{
          major: true,
          containImage: true,
          imageSrc: "/images/JHU-Blog-Brand-JHUUW-02.png",
        }}
      />
      <ArticleGridLayoutTemplate.CardStack
        withDividers
        limit={4}
        articleCardProps={{
          major: false,
          imageSrc: null,
        }}
      />
    </ArticleGridLayoutEngine>
  );
};

export default VaccineBlogBlade;
