import React from "react";
import styles from "./GraphContent.module.scss";
import GraphContent from "components/layout/GraphContent";

const GraphContentGuide = (props) => {
  const placeHolder = (slug) => (
    <div>
      Graph Component Goes Here using this slug: <br /> {slug}
    </div>
  );
  const graphData = [
    {
      slug: "staff-720",
      renders: placeHolder("staff-720"),
    },
    {
      slug: "staff-cases-720",
      renders: placeHolder("staff-cases-720"),
    },
    {
      slug: "num-ct-data",
      renders: placeHolder("num-ct-data"),
    },
    {
      slug: "data-ct-data",
      renders: placeHolder("data-ct-data"),
    },
    {
      slug: "digital-ct-map-720",
      renders: placeHolder("digital-ct-map-720"),
    },
  ];
  return (
    <div className={styles.container}>
      <GraphContent
        graphData={graphData}
        content={`## GraphContent component:  \n\n  ${graphData
          .map((data) => `> ${data.slug}\n\n`)
          .join(` `)}`}
      />
    </div>
  );
};

export default GraphContentGuide;
