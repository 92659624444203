import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import ReactMarkdown from "react-markdown/with-html";
import StoppedAlert from "components/common/StoppedAlert";
import { useRouteMatch } from "react-router-dom";

import styles from "./InteriorPageHeader.module.scss";
import HFormat from "components/common/HFormat";
import Share from "components/common/Share";
import { Popup } from "components/common/Popup";

const cx = classNames.bind(styles);

const InteriorPageHeader = ({
  section,
  title,
  subtitle,
  description,
  imgSrc,
  mediaSrc,
  classNames,
  showShare,
}) => {
  const containerRef = useRef(null);
  const iframeRef = useRef(null);
  const [mediaHeight, setMediaHeight] = useState(354);
  const match = useRouteMatch();

  useEffect(() => {
    const handleResize = () => {
      if (!!mediaSrc && iframeRef && containerRef && containerRef.current) {
        const newHeight = containerRef.current.clientWidth / 1.78;
        setMediaHeight(newHeight);
      }
    };

    if (containerRef && containerRef.current && !mediaSrc) {
      containerRef.current.style.backgroundImage = `url(${imgSrc})`;
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [imgSrc, mediaSrc]);

  const containerVideoHeightAdjust = {
    height: `${mediaHeight}px`,
  };

  return (
    <div className={styles.outter}>
      <div className={cx("base", classNames)}>
        <div className={styles.textContentWrapper}>
          <div className="small-heading">{section}</div>
          <HFormat size={1} allCaps noMarginTop>
            {title}
          </HFormat>
          <HFormat size={3} noMarginTop>
            {subtitle}
          </HFormat>
          {!!description && (
            <ReactMarkdown
              source={description}
              escapeHtml={false}
              linkTarget="_blank"
            />
          )}
          {!!showShare && (
            <div className={styles.shareContainer}>
              <span>Share</span>
              <Share label="" />
            </div>
          )}
        </div>
        {!!mediaSrc || !!imgSrc ? (
          <div
            ref={containerRef}
            className={styles.imageContainer}
            style={!!mediaSrc ? containerVideoHeightAdjust : null}
          >
            <iframe
              ref={iframeRef}
              title="WebCast"
              src={mediaSrc}
              style={{ border: 0 }}
              allowFullScreen
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              gesture="media"
              frameBorder="0"
            ></iframe>
          </div>
        ) : null}
      </div>
      {section === "Maps & Trends" && (
        <>
          <StoppedAlert type="DEMOGRAPHIC" className={styles.stoppedAlert} />
          <Popup type="DEMOGRAPHIC" />
        </>
      )}
      {(match.url.split("/")?.[2] === "differences-in-positivity-rates" || match.url.split("/")?.[2] === "jhu-covid-19-testing-data") && (
        <>
          <Popup />
        </>
      )}
    </div>
  );
};

InteriorPageHeader.propTypes = {
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  mediaLinkSrc: PropTypes.string,
  classNames: PropTypes.string,
  showShare: PropTypes.bool,
};
InteriorPageHeader.defaultProps = {
  showShare: true,
};

export default InteriorPageHeader;
