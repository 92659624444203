import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import * as GTM from "util/gtm";
import styles from "./USMapFAQ.module.scss";
import { getPage } from "data/util";
import Page from "components/layout/Page";
import { useFetch } from "util/hooks/useFetch";

const USMapFAQ = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  const [data, isDataLoading] = useFetch(
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/county_caih_update.json"
  );

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentCountry: "united-states",
    });
  }, []);

  const TribalNationsDateTime = () => {
    if (!data?.last_updated) return null;

    const tsDate = new Date(data.last_updated);
    const dateString = tsDate.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      time: "numeric",
      timeZone: "America/New_York",
    });
    const timeString = tsDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
      timeZone: "America/New_York",
    });
    const format = `${dateString}, at ${timeString}`;
    return (
      <p>
        <strong>
          The Tribal Nations layer of the U.S. Map updates weekly and was last
          updated on {format}.
        </strong>
      </p>
    );
  };

  const contents = {
    sectionAContent:
      content_blocks["faq-covid-19-united-states-cases-by-county"]?.content,
    sectionBTitle:
      content_blocks["faq-covid-19-united-states-cases-by-county-part2"]
        ?.subtitle,
    sectionBContent:
      content_blocks["faq-covid-19-united-states-cases-by-county-part2"]
        ?.content,
  };
  const rMarkdownSharedProps = {
    className: styles.items,
    linkTarget: "_blank",
  };

  return (
    <Page>
      <div className={styles.container}>
        <ReactMarkdown
          {...rMarkdownSharedProps}
          source={
            content_blocks["faq-covid-19-united-states-cases-by-county"]
              ?.content
          }
        />
        {contents.sectionBTitle && <h3>{contents.sectionBTitle}</h3>}
        {!isDataLoading && <TribalNationsDateTime />}
        <ReactMarkdown
          {...rMarkdownSharedProps}
          source={
            content_blocks["faq-covid-19-united-states-cases-by-county-part2"]
              ?.content
          }
        />
      </div>
    </Page>
  );
};

export default USMapFAQ;
