import React, { FC } from "react";
import cx from "classnames";
import styles from "./StateGraphicRow.module.scss";
import GraphicBlock, { GraphicBlockProps } from "../GraphicBlock";
import JoinBlock from "../JoinBlock";

export type StateGraphicRowProps = {
  stateCode: string;
  blocks: string[];
  className?: string;
};

const StateGraphicRow: FC<StateGraphicRowProps> = ({
  blocks = [],
  stateCode,
  className,
}) => (
  <div className={cx(styles.base, className)}>
    <div className={styles.stateCode}>
      <span className={styles.title}>{stateCode}</span>
    </div>
    <div className={styles.blockWrap}>
      {blocks.map((ele, j) => {
        const nextBlock = blocks[j + 1];
        if (typeof ele === "string") {
          return (
            <GraphicBlock
              key={`graphic-block${stateCode}${ele}`}
              stateCode={stateCode}
              variant={ele as GraphicBlockProps["variant"]}
              joinNext={nextBlock === "JOIN"}
            />
          );
        } else {
          return (
            <JoinBlock
              className={styles.joinGroup}
              key={`joinBlock${stateCode}${ele}${j}`}
              blocks={ele}
              stateCode={stateCode}
            />
          );
        }
      })}
    </div>
  </div>
);

export default StateGraphicRow;
