import React from "react";
import Button from "../Button";
import styles from "./TextBanner.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type TTextBannerProps = {
  content: string;
  title?: string;
  href?: string;
  targetBlank?: boolean;
  label?: string;
  theme?: "default" | "alert" | "homepage";
  className?: string;
};

const TextBanner = ({
  label = "Sign Up",
  theme = "default",
  title,
  content,
  href,
  targetBlank,
  className,
}: TTextBannerProps) => {
  return (
    <div className={cx(styles.base, styles[theme], className)}>
      <div className={styles.text}>
        {title && <h3 className={styles.title}>{title}</h3>}
        {content && <p className={styles.content}>{content}</p>}
      </div>
      {href && (
        <div className={styles.button}>
          <Button href={href} targetBlank={targetBlank} icon={theme === "homepage"? "right": null}>
            {label}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TextBanner;
