import React from 'react';
import Cell from './Cell';
import { ROWS } from '../../../../constants';
import HiddenCell from './HiddenCell';

const Row = ({ row, i, gutter, cellSize, selectedDataSet }) => {
  return (
    <div className="map__row">
      {row.map((cell, j) => {
        const item = {
          ...cell,
          style: {
            width: cellSize,
            height: cellSize,
            borderRight: j !== row.length - 1 ? `${gutter / 2}px solid #fff` : 'none',
            borderLeft: !j ? 'none' : `${gutter / 2}px solid #fff`,
            borderTop: !i ? 'none' : `${gutter / 2}px solid #fff`,
            borderBottom: i !== ROWS - 1 ? `${gutter / 2}px solid #fff` : 'none',
            backgroundColor: cell.value ?
                selectedDataSet.colorScale(cell.value) :
                selectedDataSet.name === 'Percent positive' && cell.id ? '#D3D3D3' : 'none',
          },
        };
        return cell.id ? (
          <Cell key={`row-${i}-cell-${j}`} cell={item} selectedDataSet={selectedDataSet} />
        ) : (
          <HiddenCell key={`row-${i}-cell-${j}`} cell={item} selectedDataSet={selectedDataSet} />
        );
      })}
    </div>
  );
};
Row.defaultProps = {
  row: [],
  i: 0,
  selectedDataSet: {},
  gutter: 0,
  cellSize: 10,
};
export default Row;
