import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./OverviewDataNote.module.scss";
import { shouldShowDrafts } from "components/common/DraftGuard";
import Button from "components/common/Button";
import { getRegionNotesPagePath } from "data/geo";

export type TOverviewDataNoteProps = {
  notes: Array<any>;
  regionCode?: string;
  date?: string | Date;
  viewAllLink?: boolean;
  isCountry?: boolean;
  isEmbed?: boolean;
  draft?: boolean;
};

const OverviewDataNote = ({
  notes = [],
  regionCode,
  date,
  isCountry,
  draft,
  isEmbed,
  viewAllLink,
}: TOverviewDataNoteProps) => {
  return (
    (!draft || shouldShowDrafts === draft) && (
      <div className={styles.regionDataContainer}>
        <div className={`small-heading ${styles.noteDate}`}>
          {!!date
            ? new Date(date).toLocaleDateString(undefined, {
                timeZone: "UTC",
                day: "numeric",
                month: "long",
                year: "numeric",
              })
            : "Undated"}
        </div>
        <ul>
          {/* If undated / permaNote, dont limit the displayed notes */}
          {(!!date ? notes.slice(0, 2) : notes)?.map((note, i) => (
            <li key={`${date}-${i}`}>
              <ReactMarkdown source={note} />
            </li>
          ))}
        </ul>
        {viewAllLink && !isEmbed && (
          <Button
            buttonStyle="underlined"
            href={
              !regionCode
                ? "/region-data-notes"
                : getRegionNotesPagePath(
                    isCountry ? "countries" : "states",
                    regionCode
                  )
            }
          >
            View All Data Notes
          </Button>
        )}
      </div>
    )
  );
};

export default OverviewDataNote;
