import React, { useState, useRef, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import * as GTM from "util/gtm";

import { Link } from "react-router-dom";
import { isAbsoluteUrl } from "components/common/Button";

import classNames from "classnames/bind";
import styles from "./ArticleCard.module.scss";
import { Waypoint } from "react-waypoint";
const cx = classNames.bind(styles);

const ArticleCard = ({
  category,
  date,
  title,
  body,
  href,

  major,
  vertical: verticalProp,
  dark,
  new: legacyIsNew,
  hasVideo,

  imageSrc,
  containImage,
  noImagePadding,

  className: propClass,
  ...props
}) => {
  // Measure component width
  const baseRef = useRef(null);
  const [componentWidth, setComponentWidth] = useState(480);
  const [loadImg, setLoadImg] = useState(false);
  useLayoutEffect(() => {
    const update = () => {
      requestAnimationFrame(() => {
        if (!baseRef.current) return;
        setComponentWidth(baseRef.current.offsetWidth);
      });
    };
    update();
    // Update twice to make sure we don't use stale measurements
    const doUpdate = () => {
      update();
      setTimeout(update, 0);
    };
    window.addEventListener("resize", doUpdate);
    return () => window.removeEventListener("resize", doUpdate);
  }, []);

  // Ignore "vertical" prop for "major" ArticleCards.
  // Major ArticleCards’ orientation is always determined by size
  const vertical = major
    ? componentWidth < 768 - 32 // 768 is $sm breakpoint; 32px for padding
    : verticalProp;
  // Tight layout when we have less than 400px of width
  const isTight = componentWidth < 400;

  // Class names!
  // Important invariants here:
  //   - An ArticleCard will never be “major,” “horizontal,” AND “tight” simultaneously
  //   - An ArticleCard will never be “contain-image, “horizontal,” and “tight” simultaneously
  const orient = vertical ? "layout-vertical" : "layout-horizontal";
  const majMin = major ? "major" : "minor";
  const tight = { "layout-tight": isTight };
  const newCls = { new: legacyIsNew };
  const contain = { "contain-image": containImage };
  const darkCls = { dark };

  const baseClass = cx("base", orient, majMin, tight, newCls, contain, darkCls);
  const className = [baseClass, propClass].filter((c) => !!c).join(" ");

  date = new Date(date);
  const hasDate = !isNaN(date);
  let dateText = "";
  if (hasDate) {
    try {
      dateText = date.toLocaleDateString(undefined, { dateStyle: "long" });
    } catch (e) {
      dateText = date.toLocaleString();
    }
  }

  let BaseElement = "article";

  const handleClick = (e) => {
    // Push verticals, if any, to the GTM dataLayer:
    GTM.push({
      event: "ARTICLE_CLICK",
      contentVerticals: props.verticals || [],
    });

    // Trigger potential onClick provided via props:
    if (typeof props.onClick === "function") {
      props.onClick(e);
    }
  };

  if (href) {
    const newTab = { target: "_blank", rel: "noopener noreferrer" };
    BaseElement = isAbsoluteUrl(href)
      ? React.forwardRef((props, ref) => (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a
            ref={ref}
            href={href}
            {...newTab}
            {...props}
            data-tracking-id="article-card"
            onClick={handleClick}
          />
        ))
      : React.forwardRef((props, ref) => (
          <Link
            ref={ref}
            to={href}
            {...props}
            featured={props.featured ? "true" : null}
            data-tracking-id="article-card"
            onClick={handleClick}
          />
        ));
  }

  return (
    <BaseElement
      aria-label={hasDate ? `${dateText}, ${title}` : title}
      role="link"
      ref={baseRef}
      {...{ className, ...props }}
    >
      {/* Image */}
      {imageSrc && !containImage && (
        <Waypoint onEnter={() => setLoadImg(true)} scrollableAncestor={window}>
          <div
            className={cx("image", { "has-video": hasVideo })}
            style={{ backgroundImage: loadImg ? `url('${imageSrc}')` : "none" }}
          />
        </Waypoint>
      )}
      {imageSrc && containImage && (
        <div
          className={cx(
            "image",
            { "has-video": hasVideo },
            { noImagePadding: noImagePadding }
          )}
        >
          <img src={imageSrc} alt="" loading="lazy" />
        </div>
      )}
      {/* Text content */}
      <div className={cx("text")}>
        <div className={cx("text-content-wrapper")}>
          {legacyIsNew && <span className={cx("new-badge-inline")}>New</span>}
          <header>
            {/* Category and/or date go at the top of the header */}
            {(category || date) && (
              <p className={cx("category")}>
                {category}
                {category && hasDate ? " | " : ""}
                {dateText}
              </p>
            )}
            <h3>{title}</h3>
          </header>
          {body}
        </div>
      </div>
    </BaseElement>
  );
};

ArticleCard.propTypes = {
  major: PropTypes.bool,
  vertical: PropTypes.bool,
  dark: PropTypes.bool,
  new: PropTypes.bool,
  hasVideo: PropTypes.bool,

  category: PropTypes.node, // node for category is often a link
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  title: PropTypes.node.isRequired,
  body: PropTypes.oneOfType([
    // not a string or number - should be one or more react elements (usually p tags)
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  href: PropTypes.string,

  // imageSrc is required for major components but not otherwise
  imageSrc: (...args) => {
    const [props, propName, componentName] = args;
    const errorMessage = `Invalid prop \`${propName}\` supplied to \`${componentName}\`. \`${propName}\` is required when \`major\` is true.`;
    if (!props[propName] && props.major) return new Error(errorMessage);
    return PropTypes.string(...args);
  },
  containImage: PropTypes.bool,
  noImagePadding: PropTypes.bool,

  className: PropTypes.string,
};
ArticleCard.defaultProps = {
  major: false,
  vertical: false,
  dark: false,
  new: false,
  hasVideo: false,

  category: undefined,
  date: undefined,
  body: null,
  href: null,

  imageSrc: null,
  containImage: false,
  noImagePadding: false,

  className: undefined,
};

export default ArticleCard;
