import React, { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import Tour from "reactour";

import styles from "./AcceleratedTimelineDiagram.module.scss";
import phases from "../../../data/phases";
import PhaseCard from "../../../components/PhaseCard";
import TimeSpan from "../../../components/TimeSpan";
import AcceleratedTimelineMap from "../AcceleratedTimelineMap";
import TourPoint from "../../../components/TourPoint";

const stepOptions = {
  position: "top",
};

const steps = [
  // Special placement:
  {
    ...stepOptions,
    id: "phase-trials-group",
    selector: `.${styles["phase-trials-group"]}`,
    content:
      "Clinical trial phases can be combined. Some of the ongoing COVID-19 clinical trials have combined phases 1 and 2 to assess safety and the immune responses, and others have combined phases 2 and 3.",
  },
].concat(
  // The rest are phase-specific:
  phases
    .filter((p) => !!p.tour)
    .map((p) => p.tour.map((t) => ({ ...t, id: p.id })))
    .flat()
    .map(({ id, content }) => ({
      id,
      ...stepOptions,
      selector: `.${styles[`phase-${id}`]}`,
      content,
    }))
);

const AcceleratedTimelineDiagram = ({
  currentPhase,
  setCurrentPhase,
  isTourOpen,
  setIsTourOpen,
}) => {
  const [currentStepPhase, setCurrentStepPhase] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);

  const updateStep = (current) => {
    setCurrentStepPhase(steps[current].id);
    setCurrentStep(current);
  };

  const onClose = () => {
    setCurrentStepPhase(null);
  };

  const gotoTourPoint = (stepIndex) => {
    updateStep(stepIndex);
    setIsTourOpen(true);
  };

  const renderPhaseCard = ({ id, icon, name, tour }) => (
    <PhaseCard
      key={id}
      id={id}
      icon={icon}
      title={name}
      className={cx(styles.phase, styles[`phase-${id}`])}
      onClick={() => setCurrentPhase(id)}
      active={currentPhase === id}
      hasTourPoint={!!tour}
      tourPointActive={currentStepPhase === id}
      gotoTourPoint={gotoTourPoint}
      stepIndex={steps.findIndex((s) => s.id === id)}
    />
  );

  return (
    <div className={styles.base}>
      <div className={styles.timeSpan}>
        <TimeSpan label="1-2 Years" />
      </div>
      <div className={styles.timeline}>
        {renderPhaseCard(phases[0])}
        {/* need to group these for tour point: */}
        <div className={styles["phase-trials-group"]}>
          <TourPoint
            active={currentStepPhase === "phase-trials-group"}
            onClick={gotoTourPoint}
            onClickParam={0}
          />
          {renderPhaseCard(phases[1])}
          {renderPhaseCard(phases[2])}
          {renderPhaseCard(phases[3])}
        </div>
        {renderPhaseCard(phases[4])}
        {renderPhaseCard(phases[5])}
        {renderPhaseCard(phases[6])}
      </div>
      <Tour
        className={styles.reactourHelper}
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        maskSpace={0}
        rounded={6}
        badgeContent={(curr, tot) => `${curr} of ${tot}`}
        getCurrentStep={updateStep}
        onBeforeClose={onClose}
        startAt={currentStep}
        goToStep={currentStep}
      />
    </div>
  );
};

AcceleratedTimelineMap.propTypes = {
  currentPhase: PropTypes.string,
  setCurrentPhase: PropTypes.func,
};

export default AcceleratedTimelineDiagram;
