import React from 'react';
import { format } from "d3-format";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    hide: {
      fontSize: "90%",
      visibility: 'hidden'
    },
    show: {
      fontSize: "90%",
      visibility: 'visible'
    }

}));

const Annotation = ({datapoint, formattype, x, y, hovered, first}) => {
  const classes = useStyles();

  return (<g className="annotation">
    <text x={x} y={y} dy={4} className={hovered || first ? classes.show : classes.hide}>
      {formattype ? format(formattype)(datapoint) : datapoint}
    </text>
  </g>);
};

export default Annotation;
