import React, { FC, useRef, useState, useEffect } from "react";
import cx from "classnames";
import styles from "./HomePageCarousel.module.scss";
import Slide from "./Slide";
import CarouselNav from "../CarouselNav";

export type THomePageCarouselProps = {
  slides?: any[];
  className?: string;
};

const HomePageCarousel: FC<THomePageCarouselProps> = ({
  slides = [],
  className,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const scrollContainerRef = useRef<HTMLUListElement>(null!);
  const [currentSlideIX, setSlideIX] = useState(0);
  const [lock, setLock] = useState(false);

  const handleOnNavClick = (slideIX = 0) => {
    if (slideIX === currentSlideIX) return;
    setLock(true);
    setSlideIX(slideIX);
    const container = scrollContainerRef.current;
    container.scrollTo({
      left: container.offsetWidth * slideIX,
      behavior: "smooth",
    });
  };

  // useEffect(() => {
  //   if (lock) {
  //     return;
  //   }
  //   let resetTimeout;
  //   const rotate = () => {
  //     let newIndex: number;
  //     if (currentSlideIX < slides.length - 1) {
  //       newIndex = currentSlideIX + 1;
  //     } else {
  //       resetTimeout = setTimeout(() => setLock(false), 300);
  //       newIndex = 0;
  //     }
  //     setLock(true);
  //     setSlideIX(newIndex);
  //     const container = scrollContainerRef.current;
  //     container.scrollTo({
  //       left: container.offsetWidth * newIndex,
  //       behavior: "smooth",
  //     });
  //   };
  //   const rotator = setTimeout(rotate, 8000);
  //   return () => {
  //     clearTimeout(rotator);
  //     clearTimeout(resetTimeout);
  //   };
  // }, [lock, currentSlideIX, slides.length]);

  useEffect(() => {
    const container = scrollContainerRef.current;
    const handleScroll = () => {
      const { scrollLeft, offsetWidth } = container;
      const newIndex =
        Math.floor((scrollLeft - offsetWidth / 2) / offsetWidth) + 1;
      if (lock) {
        if (newIndex === currentSlideIX) {
          setLock(false);
        }
      } else if (newIndex !== currentSlideIX) {
        setLock(true);
        setSlideIX(newIndex);
      }
    };
    container.addEventListener("scroll", handleScroll);
    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [lock, currentSlideIX]);

  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.slideContainer}>
        <ul ref={scrollContainerRef} className={styles.scrollContainer}>
          {!!slides.length &&
            slides.map((slide, i) => {
              return (
                <Slide
                  id={`${i}`}
                  key={`${i}`}
                  selected={currentSlideIX === i}
                  content={slide}
                />
              );
            })}
        </ul>
        <div className={styles.scrollCover} />
      </div>
      <div className={styles.navContainer}>
        <CarouselNav
          variant="hero"
          slideIndex={currentSlideIX}
          slideCount={slides.length}
          manageIXInternally={false}
          onNavClick={handleOnNavClick}
        />
      </div>
    </div>
  );
};

export default HomePageCarousel;
