import { request } from "redux/util/fetch";
import addThousandsSeparator from "util/addThousandsSeparator";
import { static_asset_path } from "util/hooks/useFetch";

export const cumulativeCasesCountryInfoURL =
  "https://jhucoronavirus.azureedge.net/data/cumulative_country_info.json";

export const getNewCasesCountryInfo = async () => {
  const data = await request(cumulativeCasesCountryInfoURL).catch((error) =>
    console.log(error)
  );
  return data;
};

export const cumulativeCasesCountryData = (countryInfo) => {
  return Object.keys(countryInfo.days_since_1st_conf).map((country, i) => ({
    name: country,
    days_since_1st_conf: countryInfo.days_since_1st_conf[country],
    date_first_confirmed: new Date(
      countryInfo.date_first_confirmed[country]
    ).toLocaleDateString(),
    confirmed_newcases: addThousandsSeparator(
      countryInfo.confirmed_newcases[country]
    ),
    deaths_newcases: addThousandsSeparator(
      countryInfo.deaths_newcases[country]
    ),
    src: `${static_asset_path}timeline_date_${i}.html`,
  }));
};

export default cumulativeCasesCountryData;
