import moment from 'moment';
import {ALL_STATE_META, COLORS, valueAccessor} from "../Shared/constants";

export const STATE_META = ALL_STATE_META
    .filter(d => d.abbr !== 'PR');
export const dateAccessor = (d: any) => d.dt;

export const NEW_CONFIRMED_CASES = 'new-confirmed-cases';
export const NEW_DEATHS = 'new-deaths';

export const DEATHS = 'deaths';
export const TEST_POSITIVITY = 'test-positivity';
export const HOSPITALIZATION = 'hospitalization';
export const VACCINATION = 'vaccination';

export const DEFAULT_DATASET = NEW_CONFIRMED_CASES;
export const DEFAULT_EVENT_ID = 0;
export const GLOBAL_ROOT_ID = 'jh-state-timeline';
export const AXES_MOMENT_DATE_FORMAT = (dt: string) => {
    if (typeof window !== undefined && window.innerWidth > 1023) {
        return moment(dt).format('MMM D, YYYY');
    } else {
        return moment(dt).format('MMM D');
    }
};
export const FULL_DAYS_RANGE = 56;
export const RADIUS = 3.5;

export const BAR_CHART_COLORS = {
    [NEW_CONFIRMED_CASES]: {
        line: COLORS.orange,
        bar: '#FDF1E3'
    },
    [NEW_DEATHS]: {
        line: '#7E7E7E',
        bar: '#DEDEDE'
    },
    [DEATHS]: {
        line: "#E87563",
        bar: "#FBE9E7",
    },
    [TEST_POSITIVITY]: {
        line: '#7A86DF',
        bar: '#DDE0EF',
    },
    [HOSPITALIZATION]: {
        line: "#74BAE1",
        bar: "#E9F4FA",
    },
    [VACCINATION]: {
        line: "#A4BF58",
        bar: "#F3F7E3",
    }
};

export const OTHER = 'OTHER';
export const EVENT_TYPE_COLORS = {
    OPENING: COLORS.green,
    CLOSING: COLORS.red,
    [OTHER]: COLORS.grayAlt,
    DEFER_TO_COUNTY: COLORS.blue,
};

export const BASE_STATE_TIMELINE_ROUTE = '/data/state-timeline';

const subRoutes = [];
const datasets = [
    NEW_CONFIRMED_CASES,
    NEW_DEATHS,
    DEATHS,
    TEST_POSITIVITY,
    HOSPITALIZATION,
    VACCINATION,
];
for (let j = 0; j < datasets.length; j++) {
    for (let i = 0; i < STATE_META.length; i++) {
        const state = STATE_META[i];
        const dataset = datasets[j];
        subRoutes.push(`${BASE_STATE_TIMELINE_ROUTE}/${dataset}/${valueAccessor(state)}`);
    }
}
export const SUB_ROUTES = subRoutes;
export const DATASET_HELPER_TEXT = {
    [NEW_CONFIRMED_CASES]: {
        actual: ' cases',
        cumulative: ' cumulative cases',
        average: ' - 7 Day Average',
    },
    [DEATHS]: {
        actual: ' deaths',
        cumulative: ' cumulative deaths',
        average: ' - 7 Day Average',
    },
    [TEST_POSITIVITY]: {
        actual: ' total tests',
        average: '% Positive',
    },
    [HOSPITALIZATION]: {
        actual: ' hospitalizations',
        cumulative: ' cumulative hospitalizations',
        average: ' - 7 Day Average',
    },
    [VACCINATION]: {
        actual: ' doses',
        cumulative: ' cumulative doses',
        average: ' - 7 Day Average',
    },
};
export const DOWNLOAD_URL = "https://storage.googleapis.com/crc-assets/state-timeline/images/downloads";
