import React, { useEffect, useState } from "react";
import styles from "./SummaryTable.module.scss";
import { formatTableData } from "./util";
import TFormat from "components/common/TFormat";
import stateList from "data/data-viz/vaccine-us-states/state-list.json";

const SummaryTable = ({ data }) => {
  const [isMobile, setIsMobile] = useState(window?.innerWidth <= 768);
  const [tableData, setTableData] = useState(null);
  const dataSet = {
    data: tableData,
    tableColumns: {
      regionName: isMobile ? "State" : "State Name",
      cases: "Cases",
      deaths: "Deaths",
      tests: "Tests",
      vaccines: "Vaccines",
    },
  };

  useEffect(() => {
    // setTableData(formatTableData(null, isMobile)); //Temp: using local dev json file
    setTableData(formatTableData(data, isMobile));
  }, [data, isMobile]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window?.innerWidth <= 768;
      setIsMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    tableData && (
      <TFormat
        className={styles.summaryTable}
        tableData={dataSet?.data}
        tableColumns={dataSet?.tableColumns}
        regionList={stateList}
        regionNoLinkList={null}
        path="/region/us/"
        pageSection="#data-timeline"
        rowLimit={12}
        mobileOptions={{
          useRegionCodeType: "state",
          useNameShortening: true,
        }}
      />
    )
  );
};

export default SummaryTable;
