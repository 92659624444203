import DonutChart from "components/common/DonutChart";
import EmbedFrame from "components/common/EmbedFrame";
import USStateMap from "components/common/USStateMap";
import GraphContent from "components/layout/GraphContent";
import React from "react";
import MediaQuery from "react-responsive";
import styles from "./CareResourceCoordination.module.scss";

const CareResourceCoordination = ({ content_blocks }) => {
  const dataPath = "/datasets/contact_tracing";
  const dataSources = {
    needs: `${dataPath}/social_needs.json`,
    screen: `${dataPath}/social_screen.json`,
    tracking: `${dataPath}/social_tracking.json`,
    connection: `/embed/contact-tracing-data/social_connection_web.html`,
    connectionMobile: `/embed/contact-tracing-data/social_connection_mobile.html`,
    followUp: `${dataPath}/social_followup.json`,
    formalProcess: `${dataPath}/social_formalprocess.json`,
  };

  const graphData = [
    {
      slug: "social-screen",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap dataSrc={dataSources.screen} width={437} height={315} />
          <DonutChart dataSrc={dataSources.screen} width={315} height={315} />
        </div>
      ),
    },
    {
      slug: "social-connection",
      renders: (
        <div className={styles.chartFrame}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={dataSources.connectionMobile}
              width={360}
              height={540}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame src={dataSources.connection} width={720} height={450} />
          </MediaQuery>
        </div>
      ),
    },
    {
      slug: "social-followup",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap dataSrc={dataSources.followUp} width={437} height={315} />
          <DonutChart dataSrc={dataSources.followUp} width={315} height={315} />
        </div>
      ),
    },
    {
      slug: "social-tracking",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap dataSrc={dataSources.tracking} width={437} height={315} />
          <DonutChart dataSrc={dataSources.tracking} width={315} height={315} />
        </div>
      ),
    },
  ];

  return (
    <>
      <GraphContent
        graphData={graphData}
        content={
          content_blocks[
            "state-survey-results-care-resource-coordination-review"
          ].content
        }
      />
    </>
  );
};

export default CareResourceCoordination;
