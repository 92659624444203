import React, { FC } from "react";
import cx from "classnames";
import styles from "./GraphicInfo.module.scss";

export type GraphicInfoProps = {
  title: string;
  subtitle?: string;
  groups?: string[];
  onHover?: boolean;
  modal?: boolean;
  className?: string;
};

const GraphicInfo: FC<GraphicInfoProps> = ({
  subtitle = "Eligibility Groups",
  onHover = true,
  groups = [],
  modal,
  title,
  className,
}) => {
  return (
    <div
      className={cx(
        styles.base,
        onHover ? styles.onHover : "",
        modal ? styles.modal : "",
        className
      )}
    >
      <span className={styles.title}>{title}</span>
      <span className={styles.subtitle}>{subtitle}</span>
      {!!groups.length && (
        <ul className={styles.listContainer}>
          {groups.map((item, i) => (
            <li key={`group-item${i}${item}`}>{item}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GraphicInfo;
