import React from 'react';
import {
  NEGATIVE_TREND,
  NEUTRAL_TREND,
  POSITIVE_TREND,
  THIS_WEEK,
  NEW_CASES,
  TESTS,
  PERCENT_POSITIVE,
  LAST_WEEK,
} from '../../../../../constants';

const imageLookup = {
  [NEW_CASES]: {
    [POSITIVE_TREND]: '/testing-tracker/images/cases_trend_up.svg',
    [NEGATIVE_TREND]: '/testing-tracker/images/cases_trend_down.svg',
    [NEUTRAL_TREND]: '/testing-tracker/images/no_change.svg',
  },
  [TESTS]: {
    [POSITIVE_TREND]: '/testing-tracker/images/testing_trend_up.svg',
    [NEGATIVE_TREND]: '/testing-tracker/images/testing_trend_down.svg',
    [NEUTRAL_TREND]: '/testing-tracker/images/no_change.svg',
  },
  [PERCENT_POSITIVE]: {
    [POSITIVE_TREND]: '/testing-tracker/images/cases_trend_up.svg',
    [NEGATIVE_TREND]: '/testing-tracker/images/cases_trend_down.svg',
    [NEUTRAL_TREND]: '/testing-tracker/images/no_change.svg',
  },
};

const TrendColumn = ({ weeklyTrends, type }) => {
  let thisWeekImage = imageLookup[type][weeklyTrends[THIS_WEEK]];
  let lastWeekImage = imageLookup[type][weeklyTrends[LAST_WEEK]];

  return (
    <div className="table__trend">
      <div className="table__trend__column">
        <div className="this__week">THIS WEEK</div>
        <img alt="The trend direction of this week" src={thisWeekImage} width="31px" height="31px" />
      </div>
      <div className="table__trend__column">
        <div className="last__week">LAST WEEK</div>
        <img alt="The trend direction of last week" src={lastWeekImage} width="31px" height="31px" />
      </div>
    </div>
  );
};
TrendColumn.defaultProps = {
  weeklyTrends: {},
};
export default TrendColumn;
