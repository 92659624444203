import React from 'react';
import Grid from '@material-ui/core/Grid';

const HiddenCell = ({ cell }) => {

  return (
      <Grid item xs={1}>
        <div style={cell.style}>
          <span style={{...cell.spanStyle, color:"#666"}}>{cell["alpha-2"]}</span>
        </div>
      </Grid>
  );
};
export default HiddenCell;
