import { json } from "d3-fetch";

const insertElement = (element, array = [], index) => {
  const temp = array.slice(0, index);
  temp.push(element);
  return temp.concat(array.slice(index));
};

export const getData = () => {
  return new Promise((resolve, reject) => {
    json(
      "https://jhucoronavirus.azureedge.net/jhucoronavirus/testing/jh-covid-tool.v4.json"
    )
      .then((data) => {
        //This inserted mock is meant to maintiant the 5 column row structure of the data table,
        //this mock is a filler for the 3rd column of data NO LONGER SUPPORTED and covered by an overlay.
        const mock = { approachId: 3, selected: false, values: [] };
        const insertIX = 2;

        data = data.map((d) => {
          const res = { ...d };
          res.values.MAP_PERCENT_POSITIVE = insertElement(
            mock,
            res.values.MAP_PERCENT_POSITIVE,
            insertIX
          );
          res.values.MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE = insertElement(
            mock,
            res.values.MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE,
            insertIX
          );
          res.values.PERCENT_POSITIVE = insertElement(
            mock,
            res.values.PERCENT_POSITIVE,
            insertIX
          );

          return res;
        });
        return resolve(data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};

export const getMetaData = () => {
  return new Promise((resolve, reject) => {
    json(
      "https://jhucoronavirus.azureedge.net/jhucoronavirus/testing/positivity_metadata.json"
    )
      .then((data) => {
        const mock = {
          approachId: 3,
          denominator: "Total People",
          numerator: "Positive People",
          count: 11,
          info: null,
        };

        data.approaches = insertElement(mock, data.approaches, 2);

        return resolve(data);
      })
      .catch((err) => {
        console.log(err);
        return reject(err);
      });
  });
};
