import { createSelector } from 'reselect';

const statesSelector = state => state.equityAccessToTesting.states;

const selectedStateSelector = stateCode =>
  createSelector([statesSelector], states => {
    return states.find(d => d.abbr.toLowerCase() === stateCode);
  });

export default selectedStateSelector;
