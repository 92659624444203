import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from "../constants";

const useStyles = makeStyles(() => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '25%',
        '& circle': {
            stroke: COLORS.blueDark
        },
    },
}));

const Spinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <CircularProgress/>
        </div>
    );
};

export default Spinner;
