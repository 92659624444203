import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import classNames from "classnames/bind";
import styles from "./Nav.module.scss";
import NavGroup from "./NavGroup";
import { getGroupedRoutes, sectionProps } from "data/navigation";
import { isMobileSize } from "util/isMobile";
import SectionLink from "./SectionLink";
import MobileSubNav from "./MobileSubNav";
import NavRegionSearch from "./NavRegionSearch";
const cx = classNames.bind(styles);

export default function Nav({ routes, isOpen, onClose }) {
  const { pathname } = useLocation();
  const [isMobile, setIsMobile] = useState(isMobileSize());

  //Get & Organize grouped routes if NOT at mobile width
  const groups = isMobile ? [] : getGroupedRoutes();

  //Filter non-grouped routes
  const surfaceRoutes = isMobile
    ? routes
    : routes.filter((route) => !route?.group);

  //Place any grouped routes after the first route: '/home'
  const newRoutes = !groups.length
    ? surfaceRoutes
    : [surfaceRoutes[0]].concat(groups).concat(surfaceRoutes.slice(1));

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileSize());
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={cx("base", { open: isOpen })}>
      <ul className={cx("container")}>
        {newRoutes.map((section) => {
          return (
            <li key={section.id}>
              {section?.isGroup ? (
                <NavGroup
                  id={section.id}
                  name={section.name}
                  pathname={pathname}
                  routes={section.routes}
                  onClose={onClose}
                />
              ) : (
                <SectionLink
                  section={section}
                  pathname={pathname}
                  onClose={onClose}
                />
              )}
              <MobileSubNav
                section={section}
                pathname={pathname}
                onClose={onClose}
              />
            </li>
          );
        })}
        {!isMobile && (
          <li>
            <NavRegionSearch />
          </li>
        )}
      </ul>
    </div>
  );
}

Nav.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.shape(sectionProps)),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
