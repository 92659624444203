import React, { useCallback, useEffect } from "react";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
// import classNames from "classnames/bind";
import styles from "./TrackerWeeklyChangeMap.module.scss";
import Page from "components/layout/Page";
import smartquotes from "smartquotes";

import TileMap from "../TestingTracker/components/App/TileMap";
import { useDispatch, useSelector } from "react-redux";
import { retrieveData } from "../TestingTracker/redux/modules/global";
import Spinner from "components/common/Spinner";
import ReactMarkdown from "react-markdown";
// const cx = classNames.bind(styles);

const TrackerWeeklyChangeMap = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.testingTracker.isLoading);
  const getData = useCallback(() => dispatch(retrieveData()), [dispatch]);

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-international-comparison",
    });
  }, []);

  useEffect(() => {
    getData();
    smartquotes().listen();
  }, [getData]);

  if (isLoading) return <Spinner />;

  return (
    <Page wide>
      <div className={styles.container}>
        <div id="jh-testing-tool" className={styles.container}>
          <TileMap
            disableFilter={true}
            disableToggles={true}
            datasetOverride={"weekly-change-in-new-cases"}
          />
          <br />
          <br />
          <ReactMarkdown
            source={content_blocks["tracker-tile-map-about-content"].content}
            linkTarget="_blank"
          />
        </div>
      </div>
    </Page>
  );
};

export default TrackerWeeklyChangeMap;
