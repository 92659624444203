import { combineReducers } from "redux";
import mapReducer from "./map";
import settingsReducer from "./settings";
import testingToolReducer from "components/content/Testing/TestingTracker/redux/modules/global";
import stateTimelineReducer from "components/content/DataCenter/charts/StateTimeline/redux/modules/global";
import equitableAccessToTestingReducer from "components/content/DataCenter/charts/EquitableAccessToTesting/redux/modules/global";

export default combineReducers({
  map: mapReducer,
  settings: settingsReducer,
  testingTracker: testingToolReducer,
  stateTimeline: stateTimelineReducer,
  equityAccessToTesting: equitableAccessToTestingReducer
});
