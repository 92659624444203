import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useSmoothHashScroll (anchorName) {
  const location = useLocation();

  useLayoutEffect(() => {
    if (location.hash === "#" + anchorName) {
      window.requestAnimationFrame(() => {
        const el = document.getElementById(location.hash.slice(1));
        if (el === null) {
          return;
        }

        const offsetTop = el
          ? el.getBoundingClientRect().top + window.pageYOffset
          : 0;

        window.scrollTo({
          top: offsetTop - 160,
          behavior: "smooth",
        });
      });
    }
  }, [location.hash, anchorName]);
}
