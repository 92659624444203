import React, { useState } from "react";
import { request } from "redux/util/fetch";
import { formatDateTitleString } from "data/data-viz";
import localDailyVidData from "./daily-video.json";

const DAILY_VID_FETCH_URL = `https://jhucoronavirus.azureedge.net/jhucoronavirus/daily-video.json`;

export const DAILY_VID_FETCH_SRC = {
  url: DAILY_VID_FETCH_URL,
  local: localDailyVidData,
  useLocalSrc: false,
};

export const useDailyVideoData = async ({
  setData,
}: {
  setData: (data: any) => void;
}) => {
  if (DAILY_VID_FETCH_SRC.useLocalSrc) {
    setData(DAILY_VID_FETCH_SRC.local);
    // console.log("Using local daily-video.json");
  } else {
    return await request(DAILY_VID_FETCH_SRC.url).then((response) => {
      setData(response);
    });
  }
};

/**
 * Fetch daily video data:
 * - published at date
 * - url of daily video thumbnail
 */
const DataInMotionCard = (props) => {
  const [data, setData] = useState<any>();

  useDailyVideoData({
    setData: (response) => {
      if (!data && !!response && !!response.videoId) {
        setData(response);
        // console.log(response);
      }
    },
  });

  return {
    category: "Data in Motion",
    title: data?.publishedAt
      ? `COVID-19 Data in Motion: ${formatDateTitleString(data.publishedAt)}`
      : "COVID-19 Data in Motion",
    body: (
      <p>
        A 60-second, daily summary of the most important data on COVID-19 in the
        U.S., updated every morning.
      </p>
    ),
    href: "/covid-19-daily-video",
    imageSrc: data?.imageUrl,
    containImage: true,
    noImagePadding: true,
    new: false,
  };
};

export default DataInMotionCard;
