import React from "react";
import styles from "./TooltipBadge.module.scss";
import TooltipBadge from "components/common/TooltipBadge/index";
import TooltipIcon, {
  TTooltipIconProps,
} from "components/common/TooltipBadge/TooltipIcon";

const icons: TTooltipIconProps["type"][] = [
  "positive-specimens-total-specimens",
  "positive-specimens-total-encounters",
  "positive-people-total-people",
  "positive-people-total-encounters",
  "positive-people-total-specimens",
];

const tooltips = [
  {
    title: "Viral Positive Specimens /\n Viral Total Specimens",
    content:
      "The number of positive molecular (PCR) tests divided by the total number of molecular tests.",
    type: icons[0],
  },
  {
    title: "Viral Positive Specimens /\n Viral Total Encounters",
    content:
      "The number of positive molecular (PCR) tests divided by the total number of molecular tests given within a specified timeframe.*",
    type: icons[1],
  },
  {
    title: "Viral Positive People /\n Viral Total People",
    content:
      "The number of people who test positive with molecular (PCR) tests divided by the total number of people tested with molecular tests.",
    type: icons[2],
  },
  {
    title: "Viral Positive People /\n Viral Total Encounters",
    content:
      "The number of people who test positive with molecular (PCR) tests divided by total number of molecular (PCR) tests given within a specified timeframe.*",
    type: icons[3],
  },
  {
    title: "Viral Positive People /\n Viral Total Specimens",
    content:
      "The number of people who test positive with molecular (PCR) tests divided by total number of molecular (PCR) tests.",
    type: icons[4],
  },
];

const TooltipBadgeStyleGuide = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles["styleguide-annotation"]}>Tooltip Icons</h1>
      {icons.map((tooltip, i) => (
        <div key={i}>
          <span>type: "{tooltip}"</span>
          <TooltipIcon key={i} type={tooltip} />
        </div>
      ))}

      <br />

      <h1 className={styles["styleguide-annotation"]}>Tooltip Badge</h1>
      <br />
      {tooltips.map((e, i) => (
        <div key={i}>
          <TooltipBadge
            key={i}
            {...e}
            category={"Positivity Calculation"}
            linkText={"How we calculate positivity…"}
            href={"/"}
          />
          <br />
        </div>
      ))}
      <br />
    </div>
  );
};

export default TooltipBadgeStyleGuide;
