import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import MediaQuery from "react-responsive";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./CumulativeCases.module.scss";
import { DataTimeFetched } from "components/common/DataMeta";
import CountryPlot from "./CountryPlot";
import EmbedFrame from "components/common/EmbedFrame";
import Button from "components/common/Button";
import SideNarrative from "components/layout/SideNarrative";
import { static_asset_path, useFetch } from "util/hooks/useFetch";
import cumulativeCasesCountryData, {
  cumulativeCasesCountryInfoURL,
} from "data/data-viz/cumulative-cases";

const CumulativeCases = (props) => {
  const page = getPage(props.location.pathname);
  const { content_blocks } = page;
  const [limit, setLimit] = useState(10);

  const [countryInfo, isLoading] = useFetch(cumulativeCasesCountryInfoURL);
  let data;
  if (!isLoading) {
    data = cumulativeCasesCountryData(countryInfo);
  }

  const expandOrCollapse = data ? !limit < data.length : true;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "cumulative-cases",
    });
  }, []);

  return (
    <SideNarrative
      narrative={
        <>
          <DataTimeFetched
            URL={
              "https://jhucoronavirus.azureedge.net/data/ts/cumulative_cases.json"
            }
          />
          <ReactMarkdown
            source={content_blocks["how-is-the-outbreak-growing"].content}
          />
        </>
      }
    >
      <div className={styles.container}>
        {/* TODO: Fix heading types in CMS content */}
        <ReactMarkdown
          source={content_blocks["cumulative-cases-days"].content}
        />

        <div className={styles.chartFrame}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}timeline_days_360.html`}
              width={337}
              height={375}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}timeline_days_720.html`}
              width={720}
              height={450}
            />
          </MediaQuery>
        </div>

        <ReactMarkdown
          source={content_blocks["cumulative-cases-date"].content}
        />

        <div className={styles.chartFrame}>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}timeline_date_360.html`}
              width={337}
              height={375}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}timeline_date_720.html`}
              width={720}
              height={450}
            />
          </MediaQuery>
        </div>

        <br />
        <br />
        {!isLoading && !!data && (
          <div className={styles.countryPlots}>
            {data.slice(0, limit).map((country) => (
              <CountryPlot key={country.name} {...country} />
            ))}
          </div>
        )}
        <Button
          icon={expandOrCollapse ? "down" : "x"}
          onClick={() => setLimit(expandOrCollapse ? Infinity : 10)}
        >
          {expandOrCollapse ? "View all" : "View less"}
        </Button>
        <br />
        <br />
      </div>
    </SideNarrative>
  );
};

export default CumulativeCases;
