import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { useHistory } from "react-router-dom";
import cx from "classnames";

import styles from "./ModuleCard.module.scss";
// import { ReactComponent as ArrowIcon } from "images/icons/arrow-right.svg";
import Button from "components/common/Button";

const stop = (e) => e.stopPropagation();

const ModuleCard = ({ course, slug, int, title, bio, status }) => {
  const allowDraft = process.env.REACT_APP_DRAFT === "true";
  const published = allowDraft
    ? status !== "unavailable"
    : status === "published";

  const history = useHistory();

  const go = () =>
    published && history.push(`/covid-19-basics/${course}/${slug}`);

  return (
    <div
      className={cx(styles.base, { [styles.active]: published })}
      onClick={go}
    >
      <div className={styles.moduleHeader}>
        <div className={styles.moduleHeaderLeft}>
          <h4>Module {int}:</h4>
          <h3>{title}</h3>
        </div>
        {published ? (
          <div className={styles.moduleHeaderRight}>
            <Button
              href={`/covid-19-basics/${course}/${slug}`}
              onClick={stop}
              icon="right"
              buttonStyle="bordered"
            >
              Start Module
            </Button>
          </div>
        ) : (
          <div className={cx(styles.button, styles.disabled)}>
            <span>Coming soon...</span>
          </div>
        )}
      </div>
      <div className={styles.moduleBio}>
        {!!bio.img && (
          <div className={styles.modulePortrait}>
            <img src={bio.img} alt="Claire Marie Filone, PhD" />
          </div>
        )}
        <div className={styles.moduleBioText}>
          <h4>Your Instructor: {bio.name}</h4>
          <ReactMarkdown source={bio.content} renderers={{ link: MDLink }} />
        </div>
      </div>
    </div>
  );
};

ModuleCard.propTypes = {
  course: PropTypes.string,
  slug: PropTypes.string,
  int: PropTypes.number,
  title: PropTypes.string,
  bio: PropTypes.object,
  status: PropTypes.string,
};

export default ModuleCard;

const MDLink = ({ href, children }) => {
  return (
    <a href={href} target="_blank" onClick={stop} rel="noopener noreferrer">
      {children}
    </a>
  );
};
