import React, { useEffect, useRef } from "react";
import { select } from "d3-selection";
import { axisLeft, axisTop } from "d3-axis";
import styles from "./SequencingByCountryAxes.module.scss";

const Axes = ({ xScale, xFormat, yScale, margin, innerWidth, innerHeight }) => {
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);

  useEffect(() => {
    let yAxis;
    const renderYAxis = () => {
      yAxis = select(yAxisRef.current);
      yAxis
        .call(
          axisLeft(yScale)
        );

      yAxis.select(".domain").remove();
      yAxis.selectAll(".tick line").remove();
    };

    let xAxis;
    const renderXAxis = () => {
      xAxis = select(xAxisRef.current);
      xAxis.selectAll('#xAxisLabel').remove()
      xAxis.call(axisTop(xScale).tickValues([xScale.domain()[0],0.00075,0.01,0.1,1]).tickFormat(d => xFormat(d)));
      xAxis.append('text')
        .attr('id','xAxisLabel')
        .attr("text-anchor","end")
        .attr("fill","black")
        .text("Percent Sequenced (natural log scale)")
        .attr("x", innerWidth+margin.right)
        .attr("y", -(margin.top/2))

    };

    if (xScale) {
      renderXAxis();
    }
    if (yScale) {
      renderYAxis();
    }

    return () => {
      xAxis.selectAll('g').remove();
      yAxis.selectAll('g').remove();
    };
  }, [xScale, xFormat, yScale, margin.top, margin.right, innerWidth]);

  return (
    <g className="axis">
      <g className={styles.xAxis} ref={xAxisRef} />
      <g className={styles.yAxis} ref={yAxisRef} />
    </g>
  );
};

export default Axes;
