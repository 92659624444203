import React, { FC, useState } from "react";
import styles from "./SingleSelect.module.scss";
import cx from "classnames";
import Option from "./Option";
import useSingleSelect from "util/hooks/useSingleSelect";
import { TFilterOption } from "../util";
import DropdownOverlay from "../DropdownOverlay/DropdownOverlay";
import SelectButton from "./SelectButton/SelectButton";

export type TSingleSelectProps = {
  options: string[] | TFilterOption[];
  currentSelect?: TFilterOption["value"] | number;
  placeholder?: string;
  onChange?: (selected: TFilterOption) => void;
  allOption?: boolean;
  children?: any;
  className?: string;
  optionClassName?: string;
};

const SingleSelect: FC<TSingleSelectProps> = ({
  allOption = true,
  currentSelect = allOption ? null : 0,
  options,
  placeholder,
  onChange,
  className,
  optionClassName,
}) => {
  const [isOpen, setOpenState] = useState(false);
  const {
    allOptions,
    requestSelect,
    selected,
    placeholderText,
  } = useSingleSelect(options, {
    allOption,
    currentSelect,
    placeholder,
    onSelect: (selectedOption) => {
      setOpenState(false);
      if (onChange) onChange(selectedOption);
    },
  });

  return (
    <div
      className={cx(
        {
          [styles.base]: true,
          [styles.open]: isOpen,
        },
        className
      )}
    >
      <div
        className={cx({
          [styles.selectContainer]: true,
          [styles.open]: isOpen,
        })}
      >
        <SelectButton
          className={""}
          placeholder={placeholderText}
          isOpen={isOpen}
          onClicked={() => setOpenState(!isOpen)}
          children={null}
        />
        <div className={styles.optionsContainer}>
          {allOptions.map((option, i) => (
            <Option
              key={`select-option#${option.value}#${i}`}
              className={optionClassName}
              option={option}
              onClick={requestSelect}
              isActive={option.value === selected?.value}
            />
          ))}
        </div>
      </div>
      <DropdownOverlay isOpen={isOpen} onClick={() => setOpenState(false)} />
    </div>
  );
};

export default SingleSelect;
