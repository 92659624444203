import React, { useEffect, useState } from "react";
import styles from "./ContributorImages.module.scss";
import classNames from "classnames/bind";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";
import headshots from "./headshots.js";
const cx = classNames.bind(styles);

type TContributorImagesProps = {
  header?: string;
  className?: string;
};

const HeadshotGrid = ({
  headshotBlocks,
  startOpacity = 1,
  targetOpacity = 0,
  cycleIntervalSeconds = 2,
  index,
}) => {
  const [opacity, setOpacity] = useState(startOpacity);

  useEffect(() => {
    setOpacity(startOpacity);

    const increment =
      (targetOpacity - startOpacity) / (cycleIntervalSeconds * 60);
    const timer = setInterval(() => {
      setOpacity((prevOpacity) => {
        const newOpacity = prevOpacity + increment;
        if (increment > 0 && newOpacity >= targetOpacity) {
          clearInterval(timer);
          return targetOpacity;
        } else if (increment < 0 && newOpacity <= targetOpacity) {
          clearInterval(timer);
          return targetOpacity;
        } else {
          return newOpacity;
        }
      });
    }, 16);

    return () => clearInterval(timer);
  }, [index, startOpacity, targetOpacity, cycleIntervalSeconds]);

  return (
    <div
      className={cx(styles.grid, opacity === 0 && styles.fadeOut)}
      style={{ opacity }}
    >
      {headshotBlocks}
    </div>
  );
};

const ContributorImages = ({ className }: TContributorImagesProps) => {
  const [initialLoad, setInitialLoad] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(
    headshots.length - 1
  );
  const [prevImageIndex, setPrevImageIndex] = useState(-1);
  const [visibleCells, setVisibleCells] = useState(32);
  const cycleIntervalSeconds = 5; // Cycle every 5 seconds

  useEffect(() => {
    const totalHeadshots = headshots.length;
    const timer = setInterval(() => {
      setPrevImageIndex(currentImageIndex);
      if (currentImageIndex > 0) {
        setCurrentImageIndex((currentImageIndex - 1) % totalHeadshots);
      } else {
        setCurrentImageIndex(totalHeadshots - 1);
      }
    }, cycleIntervalSeconds * 1000);
    return () => clearInterval(timer); // Clean up the timer when the component unmounts
  }, [currentImageIndex]);

  useEffect(() => {
    const breakpoints = {
      desktop: 1280,
      tablet: 1024,
      mobile: 768,
    };
    const handleResize = () => {
      if (window.innerWidth <= breakpoints.mobile) {
        setVisibleCells(12);
      } else if (window.innerWidth <= breakpoints.tablet) {
        setVisibleCells(24);
      } else {
        setVisibleCells(32);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [visibleCells]);

  const responsiveImageProps = {
    maxHeight: "104px",
    maxWidth: visibleCells === 32 ? "104px" : null,
  };

  const prevHeadShotBlocks = new Array(visibleCells).fill(null).map((_, i) => {
    const imageIndex = (prevImageIndex + i) % headshots.length;
    return (
      <div key={i} className={styles.headShotBlock}>
        <ResponsiveImage
          imageUrl={headshots[imageIndex]}
          {...responsiveImageProps}
        />
      </div>
    );
  });
  const currentHeadShotBlocks = new Array(visibleCells)
    .fill(null)
    .map((_, i) => {
      const imageIndex = (currentImageIndex + i) % headshots.length;
      return (
        <div key={i} className={styles.headShotBlock}>
          <ResponsiveImage
            imageUrl={headshots[imageIndex]}
            {...responsiveImageProps}
          />
        </div>
      );
    });

  const headShotBlocks = (
    <div className={styles.gridBlock} onLoad={() => setInitialLoad(true)}>
      <HeadshotGrid
        headshotBlocks={prevHeadShotBlocks}
        startOpacity={1}
        targetOpacity={0}
        cycleIntervalSeconds={3}
        index={prevImageIndex}
      />
      <HeadshotGrid
        headshotBlocks={currentHeadShotBlocks}
        startOpacity={1}
        targetOpacity={1}
        index={currentImageIndex}
      />
    </div>
  );

  return (
    <div className={cx(styles.base, className)}>
      <div
        className={styles.initialBG}
        style={{ opacity: initialLoad ? 0 : 1 }}
      />
      {headShotBlocks}
    </div>
  );
};

export default ContributorImages;
