import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  marker: {
    fill: '#60C6C6',
    stroke: 'none',
  },
}));

const Marker = ({ marker }) => {
  const classes = useStyles();
  return <circle className={classes.marker} cx={marker.cx} cy={marker.cy} r={5} />;
};
Marker.defaultProps = {
  marker: {
    cx: 0,
    cy: 0,
  },
};
export default Marker;
