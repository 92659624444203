import React from 'react';

import Page from 'components/layout/Page';
import ColumnLayout from 'components/layout/ColumnLayout';
import ArticleCard from 'components/layout/ArticleCard';
import Button from 'components/common/Button';
import ReactMarkdown from 'react-markdown';
import { getPage } from 'data/util';

import CourseraImage1 from './images/coursera-03.png';
import CourseraImage2 from './images/coursera-04.png';

import classNames from 'classnames/bind';
import styles from './TracingHome.module.scss';
import NewsFeed from 'components/content/News/NewsFeed';
const cx = classNames.bind(styles);

const courseraUrl =
  'https://www.coursera.org/learn/covid-19-contact-tracing?edocomorp=covid-19-contact-tracing';

const TracingHome = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  return (
    <Page wide>
      <div className={cx('container')}>
        {/* Contact tracing ethics */}
        <article className={cx('ethics-section')}>
          <ReactMarkdown
            source={content_blocks['contact-tracing-ethics']?.content}
            renderers={{
              heading: 'h2',
              // If an image's src is of the form {{media[i]}}, replace it with
              // the i-th piece of uploaded media
              image: ({ alt, src }) => {
                const uploadedMedia =
                  content_blocks['contact-tracing-ethics']?.media || [];
                if (/^{{media\[[0-9]+\]}}$/.test(src)) {
                  src = uploadedMedia[parseInt(src.match(/[0-9]+/)[0], 10)];
                }
                return <img alt={alt} src={src} />;
              },
              // Transform the list to a ColumnLayout
              list: ({ children }) => (
                <ColumnLayout
                  layout={[
                    [2.4, 2.4, 2.4, 2.4, 2.4],
                    [4, 4, 4],
                    [6, 6],
                  ]}
                  breakpoint={180}
                >
                  {children}
                </ColumnLayout>
              ),
              listItem: ({ tight, ordered, index, children, ...props }) => (
                <div className={cx('ethics-principles-item')} {...props}>
                  <div className={cx('ethics-principles-item-content')}>
                    {children}
                  </div>
                </div>
              ),
            }}
          />

          <div className={cx('ethics-actions')}>
            <Button icon="right" href="/contact-tracing/principles">
              Core Principles
            </Button>
            <Button
              icon="right"
              href="https://muse.jhu.edu/chapter/2628662"
              targetBlank
            >
              Executive Summary
            </Button>
            <Button
              icon="right"
              href="https://muse.jhu.edu/book/75831"
              targetBlank
            >
              Full Report
            </Button>
          </div>
        </article>

        <hr />

        {/* Coursera promo */}
        <ArticleCard
          major
          className={cx('coursera-card')}
          category="Contact Tracing"
          title="COVID-19 Contact Tracing: A Course from Johns Hopkins"
          body={[
            <p key="copy">
              The COVID-19 crisis has created an unprecedented need for contact
              tracing across the country, requiring thousands of people to learn
              key skills quickly. A free, six-hour{' '}
              <a href={courseraUrl}>Coursera class</a> developed by the Johns
              Hopkins Bloomberg School of Public Health and supported by
              Bloomberg Philanthropies is now available to train contact tracers
              about how to do this work effectively—and help cities and states
              across the nation undertake these critical efforts.
            </p>,
            <Button key="button" href={courseraUrl} targetBlank icon="right">
              Take the Course
            </Button>,
          ]}
          imageSrc={CourseraImage2}
        />
        <ArticleCard
          major
          className={cx('coursera-card', styles.cardImageReverse)}
          category="Contact Tracing"
          title="Measuring and Maximizing Impact of COVID-19 Contact Tracing: A Course from Johns Hopkins"
          body={[
            <p key="copy">
This course aims to provide managers and developers of contact tracing programs guidance on the most important indicators of performance of a contact tracing program, and a tool that can be used to project the likely impact of improvements in specific indicators. Students who complete the course will be proficient in using the Contact Tracing Evaluation and Strategic Support Application (ConTESSA) to estimate the impact of their contact tracing program on transmission and strategizing about how to increase their program’s impact.
            </p>,
            <Button key="button" href={`https://www.coursera.org/learn/measuring-and-maximizing-impact-of-covid-19-contact-tracing`} targetBlank icon="right">
              Take the Course
            </Button>,
          ]}
          imageSrc={CourseraImage1}
        />
        <NewsFeed scopeVerticals="contact-tracing" chooseVerticals={false} />
      </div>
    </Page>
  );
};

export default TracingHome;
