import React, { useEffect } from "react";

import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import NewsFeed from "components/content/News/NewsFeed";
import ArticleCard from "components/layout/ArticleCard";
import styles from "./VaccineHome.module.scss";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";
import VideoFeed from "components/content/Webcast/VideoFeed";
import VaccineBlogBlade from "./VaccineBlogBlade";
import VaccinesNavContent from "../VaccinesNavContent";
import FAQBlade from "../VaccineFAQ/FAQBlade";
import ReportsBlade from "../VaccineReports/ReportsBlade";

const VaccineHome = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;
  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["vaccines"],
    });
  }, []);
  return (
    <Page wide>
      <div className={styles.container}>
        {/* VACCINE TRACKER */}
        <h2>VACCINE TRACKER</h2>
        <div className={styles.largeCards}>
          <VaccinesNavContent dark={false} drawer={true} />
        </div>

        {/* VACCINE INSIGHTS */}
        <ReactMarkdown
          source={content_blocks["vaccine-home-insights"]?.content}
        />
        <ArticleCard
          key={"developing-vaccines-quickly-and-safely"}
          major
          new={false}
          category="INTERACTIVE"
          title="Developing vaccines quickly and safely"
          body={
            <p>
              An interactive tool from our vaccine experts that explores how a
              vaccine is developed and the differences between a typical
              timeline and an accelerated timeline.
            </p>
          }
          href="/vaccines/timeline"
          imageSrc="/images/vaccine-timeline-thumbnail.png"
          containImage
        />

        <ArticleCard
          key={"understanding-covid-19"}
          className={styles.articleReverse}
          major
          new={false}
          category="UNDERSTANDING COVID-19"
          title="Johns Hopkins International Vaccine Access Center"
          body={
            <p>
              VIEW-hub is a publicly available interactive tool that displays
              up-to-date information on vaccine characteristics, and vaccine
              introduction and use globally. Vaccines include COVID-19 as well
              as many childhood vaccines in routine immunization programs.
            </p>
          }
          href="https://view-hub.org/covid-19/?set=current-vaccine-intro-status&group=vaccine-introduction&category=covid"
          imageSrc="/images/articles/IVAC.png"
          containImage
        />

        <ArticleCard
          key={"comit-covid-19-maternal-immunization-tracker"}
          major
          new={false}
          category="Understanding COVID-19"
          title="COMIT: COVID-19 Maternal Immunization Tracker"
          body={
            <p>
              COMIT, the COVID-19 Maternal Immunization Tracker, provides a
              global picture of national public health policies that influence
              access to COVID-19 vaccines for pregnant and lactating people.
            </p>
          }
          href="http://www.comitglobal.org/"
          imageSrc="/images/comit-global.jpg"
          containImage
        />

        <hr />

        {/* VACCINE FAQ */}
        <h2>Questions about Vaccines</h2>
        <FAQBlade />
        <hr />

        {/* VACCINE REPORTS SERIES */}
        <ReactMarkdown
          source={content_blocks["vaccine-home-report-series"]?.content}
        />
        <ReportsBlade />
        <hr />

        {/* VACCINE BLOG */}
        <ReactMarkdown
          source={
            content_blocks["vaccine-home-covid-19-vaccine-matters"]?.content
          }
        />
        <div className={styles.noBgColorNested}>
          <VaccineBlogBlade />
        </div>
        <br />
        <hr />

        {/* VACCINE SYMPOSIUM */}
        <ReactMarkdown
          source={content_blocks["vaccine-home-vaccine-symposium"]?.content}
        />
        <VideoFeed
          eventId={"covid-19-vaccine-symposium"}
          blade
          hasMajor={false}
          limit={4}
        />
        <hr />

        {/* VACCINE NEWS */}
        <NewsFeed scopeVerticals="vaccines" chooseVerticals={false} />
      </div>
    </Page>
  );
};

export default VaccineHome;
