import React, { useRef, useEffect, useState } from "react";
import { findStateCode } from "data/geo";
import { select } from "d3-selection";
import { scaleBand, scaleLinear, scaleOrdinal } from "d3-scale";
import { axisLeft, axisTop } from "d3-axis";
import { format } from "d3-format";
import { stack } from "d3-shape";
import { max } from "d3-array";
import Bar from "./Bar";
import { slugify } from "data/geo";

const BarChart = ({ data, dataSet, svgWidth }) => {
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [height, setHeight] = useState(450);
  const [outerHeight, setOuterHeight] = useState(null);
  const [margin, setMargin] = useState({
    top: 50,
    right: 90,
    bottom: 10,
    left: 110,
  });
  const [barData, setBarData] = useState(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    mobile
      ? setMargin({ top: 150, right: 120, bottom: 10, left: 110 })
      : setMargin({ top: 50, right: 90, bottom: 10, left: 110 });
  }, [mobile]);

  useEffect(() => {
    setHeight(mobile ? data.length * 13 : data.length * 20);
  }, [data, mobile]);

  useEffect(() => {
    setOuterHeight(height + margin.top + margin.bottom);
  }, [margin, height]);

  useEffect(() => {
    const svg = select(svgRef.current);
    const innerWidth = svgWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    let sorted = data.sort((a, b) => a.tests_100k - b.tests_100k);
    sorted.forEach(
      (d) => (d.tests_diff = d.tests_100k - d.cases_100k - d.deaths_100k)
    );
    let xScale = scaleLinear()
      .domain([0, max(sorted.map((d) => d.tests_100k))])
      .range([0, innerWidth])
      .nice();
    let yScale = scaleBand()
      .domain(sorted.map((s) => s.region))
      .range([innerHeight, 0])
      .padding(0.2);
    let colorScale = scaleOrdinal()
      .domain(["tests_diff", "cases_100k", "deaths_100k"])
      .range(["#719949", "#ff9e1b", "#002d72"]);
    let stacked = stack().keys(["tests_diff", "cases_100k", "deaths_100k"])(
      sorted
    );
    let barData = [];
    stacked.forEach((g) => {
      let group = { key: g.key, fill: colorScale(g.key), bars: [] };
      g.forEach((d) => {
        let content = (
          <>
            <b>{d.data.region}</b>
            <br />
            Tests: {format(".3s")(d.data.tests_100k)}
            <br />
            Confirmed: {format(".3s")(d.data.cases_100k)}
            <br />
            Deaths: {format(".3s")(d.data.deaths_100k)}
          </>
        );
        group.bars.push({
          x: xScale(d[0]),
          y: yScale(d.data.region),
          height: yScale.bandwidth(),
          width: xScale(d[1]) - xScale(d[0]),
          tippyContent: content,
          regionLink: `/region/us/${slugify(d.data.region)}`,
        });
      });
      barData.push(group);
    });
    setBarData(barData);

    let xAxis = (g) => g.call(axisTop(xScale).tickFormat(format(".3s")));
    let yAxis = (g) =>
      g.call(
        mobile
          ? axisLeft(yScale).tickFormat((d) => findStateCode(d))
          : axisLeft(yScale)
      );

    let yG = group.append("g").call(yAxis);
    let xG = group.append("g").call(xAxis);

    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth},${
          mobile ? -margin.top + 90 : -30
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Deaths per 100k population");
    xG.append("rect")
      .attr("fill", colorScale("deaths_100k"))
      .attr("height", 15)
      .attr("width", 15)
      .attr("y", mobile ? -margin.top + 78 : -42)
      .attr("x", mobile ? 5 : innerWidth - 170);
    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth / 1.5},${
          mobile ? -margin.top + 60 : -30
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Cases per 100k population");
    xG.append("rect")
      .attr("fill", colorScale("cases_100k"))
      .attr("height", 15)
      .attr("width", 15)
      .attr("y", mobile ? -margin.top + 48 : -42)
      .attr("x", mobile ? 5 : innerWidth / 1.5 - 165);
    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth / 3},${
          mobile ? -margin.top + 30 : -30
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Tests per 100k population");
    xG.append("rect")
      .attr("fill", colorScale("tests_diff"))
      .attr("height", 15)
      .attr("width", 15)
      .attr("y", mobile ? -margin.top + 18 : -42)
      .attr("x", mobile ? 5 : innerWidth / 3 - 160);
    yG.select("text").attr("style", "font-weight:300");

    xG.selectAll(".tick line").attr("stroke", "#aaa");

    group.selectAll(".domain").raise().attr("stroke", "#aaa");

    return () => {
      group.remove();
    };
  }, [svgWidth, mobile, height, margin, data]);

  return (
    <svg ref={svgRef} width={svgWidth} height={outerHeight}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {barData &&
          barData.map((g, i) => {
            return (
              <g key={`group${i}`}>
                {g.bars.map((d, ii) => {
                  return (
                    <Bar
                      key={`g${i}stateBar${ii}`}
                      x={d.x}
                      y={d.y}
                      height={isNaN(d.height) ? null : d.height}
                      width={isNaN(d.width) ? null : d.width}
                      fill={g.fill}
                      tippyContent={d.tippyContent}
                      regionLink={d.regionLink}
                      mobile={mobile}
                    />
                  );
                })}
              </g>
            );
          })}
      </g>
    </svg>
  );
};

export default BarChart;
