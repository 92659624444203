import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./TypicalTimelineMap.module.scss";
import phases from "../../../data/phases";
import Icon from "../../../components/Icon";

const TypicalTimelineMap = ({ currentPhase }) => {
  return (
    <div className={styles.base}>
      <div className={styles.heading}>
        <Icon icon="slow" />
        <span>Typical Timeline</span>
      </div>
      <div className={styles.timeline}>
        {phases.map((phase) => (
          <div
            key={phase.id}
            id={styles[phase.id]}
            className={cx(styles.phase, {
              [styles.active]: phase.id === currentPhase,
            })}
          >
            <Icon icon={phase.icon} light={phase.id === currentPhase} />
            <span>{phase.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

TypicalTimelineMap.propTypes = {
  currentPhase: PropTypes.string,
};

export default TypicalTimelineMap;
