import _countryDisplays from "./countries-display.json";
import _countryAbbreviations from "./countries.json";
import _stateAbbreviations from "./us-states.json";
import _usTerritoryAbbreviations from "./us-territories.json";
import _regionData from "./regions-data.json";
import _regionGeneralData from "./region-general-notes.json";

//This is a typescript wrapper to model the json data

type TRegionDisplayData = {
  name: string;
  displayName: string;
  hasPage?: boolean;
  code?: string;
  isCountry?: boolean;
};

export type TRegionNote = {
  notes: string[];
  date: string;
  name?: string;
  draft?: boolean;
};

export type TRegionDataNoteFormatted = {
  data: TRegionNote;
  name: string;
  code: string;
  path: string;
  slug: string;
  USA?: boolean;
};

export type TRegionNoteFilterProps = {
  regionType: "all" | "states" | "countries";
  filterOptions?: { types?: string | Array<any>; keyword?: string };
};

export const countryDisplays: {
  [code: string]: TRegionDisplayData;
} = _countryDisplays;

export const countryAbbreviations: {
  [code: string]: string;
} = _countryAbbreviations;

export const stateAbbreviations: {
  [code: string]: string;
} = _stateAbbreviations;

export const usTerritoryAbbreviations: {
  [code: string]: string;
} = _usTerritoryAbbreviations;

export const regionData: {
  [regionType: string]: { [code: string]: TRegionNote[] };
} = _regionData;

export const regionGeneralData: TRegionNote[] = _regionGeneralData;
