import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./Popup.module.scss";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";
import Button from "components/common/Button";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

export const Popup = ({ className, type }) => {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  const { search } = useLocation();

  //Check of page is in export mode
  const query = queryString.parse(search);
  const isExportView = query?.export;

  useEffect(() => {
    if (!isExportView) setOpen(true);
  }, [location.pathname, isExportView]);

  if (isExportView) return null;

  return (
    <div className={classNames(styles.base, !open && styles.hide, className)}>
      <div className={styles.block}>
        <h3 className={styles.title}>
          <WarningIcon />
          THE CRC HAS STOPPED COLLECTING {type || "TESTING"} DATA
        </h3>
        <p className={styles.content}>
          {type === "DEMOGRAPHIC" &&
            "This chart is officially out of date as many states have discontinued reporting all or some Covid-19 demographic data. The CRC data visualizations will remain available for past dates and reports, but will no longer have up-to-date information on cases, deaths, people tested, or vaccinated by demographics."}
          {!type &&
            "This chart is officially out of date as more than 30 states across the US have stopped reporting testing positivity or significantly scaled down their testing reports. The CRC data visualizations will remain available for past dates and reports, but will no longer have up to date information on testing positivity and criteria."}
        </p>
        <Button
          icon="right"
          buttonStyle="filled"
          onClick={() => setOpen(false)}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};
