import React, {useEffect, useRef} from 'react';
import {select} from "d3-selection";
import "d3-transition";
import {DURATION} from "../../../../../../../Shared/constants";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
    bubble: {
        stroke: 'none',
        fill: props => props.color,
        fillOpacity: .4,
        pointerEvents: 'none'
    }
}));

const Bubble = ({feature}) => {
    const classes = useStyles({color: feature.color});

    const bubbleRef = useRef(null);

    const {x, y, r} = feature;

    useEffect(() => {
        const curr = bubbleRef.current;
        select(curr)
            .transition()
            .duration(DURATION)
            .attr('r',Math.max(0, r))
        return () => {
            return select(curr).interrupt()
        }
    }, [r])

    return (
        <circle
            cx={x}
            cy={y}
            className={classes.bubble}
            ref={bubbleRef}
        />
    );
};

export default Bubble;
