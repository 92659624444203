import React, { useRef } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./TestingGraphAndTable.module.scss";
import TestingTable from "./TestingTable";
import { DataTime, DataSources } from "components/common/DataMeta";
import SideNarrative from "components/layout/SideNarrative";
import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import BarChart from "./BarChart";
import DiffChart from "./DiffChart";
import addThousandsSeparator from "util/addThousandsSeparator";

const formatTestingTotalsByPop = (data) => {
  const result = Object.keys(data.Province_State)?.map((key, i) => {
    const confirmed = data["Confirmed cases<br> per 100k pop"][i];
    const tests = data["Tests<br>per 100k pop."][i];
    const deathsPopulation = data["Deaths<br> per 100k pop"][i];

    return {
      regionName: data.Province_State[i],
      tests: tests !== "nan" ? addThousandsSeparator(tests) : "-",
      confirmed: confirmed !== "nan" ? addThousandsSeparator(confirmed) : "-",
      deathsPopulation:
        deathsPopulation !== "nan"
          ? addThousandsSeparator(deathsPopulation)
          : "-",
    };
  });
  return result;
};
const formatTestingWeeklyChange = (data) => {
  const result = Object.keys(data.State)
    .filter(
      (key) =>
        data["% positive tests last week"][key] !== null &&
        data["% positive tests two weeks ago"][key] !== null &&
        data["Difference"][key] !== null
    )
    .map((key) => {
      const percPositive = data["% positive tests last week"][key];
      const percPositive2 = data["% positive tests two weeks ago"][key];
      const percDifference = data["Difference"][key];

      const powerOften = Math.pow(10, 1);
      return {
        regionName: data.State[key],
        percPositive: percPositive
          ? `${((percPositive * 100 * powerOften) / powerOften).toFixed(1)}%`
          : "-",
        percPositive2: percPositive2
          ? `${((percPositive2 * 100 * powerOften) / powerOften).toFixed(1)}%`
          : "-",
        percDifference: percDifference
          ? `${((percDifference * 100 * powerOften) / powerOften).toFixed(1)}%`
          : "-",
      };
    });
  return result;
};

const TestingGraphAndTable = ({ dataSetId, contentBlocks, pageProps }) => {
  const ref = useRef(null);
  const [width] = useDimensions(ref);

  const [totalByPop, totalByPopLoading] = useFetch(
    "https://jhucoronavirus.blob.core.windows.net/api/v2/testing/state/testing_totals_bypop.json"
  );
  const [weeklyChange, weeklyChangeLoading] = useFetch(
    "https://jhucoronavirus.blob.core.windows.net/api/v2/testing/state/testing_weekly_change.json"
  );

  const dataSets = {
    "testing-state-totals-bypop": {
      title: "Testing Totals By Population",
      tableData: {
        data: totalByPopLoading ? null : formatTestingTotalsByPop(totalByPop),
        tableColumns: {
          regionName: "State",
          tests: "Tests",
          confirmed: "Confirmed\nCases",
          deathsPopulation: "Deaths",
        },
        legends: [
          {
            label: "Tests",
            text: "per 100k pop.",
          },
          {
            label: "Confirmed Cases",
            text: "per 100k pop",
          },
          {
            label: "Deaths",
            text: "per 100k pop",
          },
        ],
      },
      dataURL:
        "https://jhucoronavirus.azureedge.net/api/v2/testing/state/pop.json",
      content: contentBlocks["testing-viz-intro-2"].content,
    },
    "testing-state-weekly-change": {
      title: "Testing Weekly Change",
      tableData: {
        data: weeklyChangeLoading
          ? null
          : formatTestingWeeklyChange(weeklyChange),
        tableColumns: {
          regionName: "State",
          percPositive: "Last Week",
          percPositive2: "Two Weeks\nAgo",
          percDifference: "Difference",
        },
        legends: [
          {
            label: "Last Week",
            text: "% positive tests last week",
          },
          {
            label: "Two Weeks Ago",
            text: "% positive tests two weeks ago",
          },
        ],
      },
      dataURL:
        "https://jhucoronavirus.azureedge.net/api/v2/testing/state/positivity_comparison.json",
      content: contentBlocks["testing-viz-intro-3"].content,
    },
  };
  const useDataSetId = dataSetId || "testing-state-totals-bypop";
  const dataSet = dataSets[useDataSetId];
  const [data, isDataLoading] = useFetch(dataSet.dataURL);

  return (
    <SideNarrative
      pageProps={pageProps}
      classNames={{
        sidebar: styles.buffer,
      }}
      narrative={
        <>
          <DataTime dataSet={useDataSetId} />
          <ReactMarkdown source={dataSet.content} />

          <DataSources dataSet={useDataSetId} />
          <ReactMarkdown
            source={contentBlocks["testing-data-disclaimer"].content}
          />
        </>
      }
    >
      <div className={styles.base}>
        <div className={styles.buttons}>
          {Object.keys(dataSets).map((dataSetId) => (
            <Link
              key={dataSetId}
              to={`/testing/states-comparison/${dataSetId}`}
              className={cx(styles.button, {
                [styles.active]: dataSet.title === dataSets[dataSetId].title,
              })}
            >
              {dataSets[dataSetId].title}
            </Link>
          ))}
        </div>

        <div className={styles.graphAndChart}>
          <div
            id="testing-states-comparison-graph"
            className={styles.graph}
            ref={ref}
          >
            {useDataSetId === "testing-state-totals-bypop" &&
              !isDataLoading && (
                <BarChart
                  data={data.filter((d) => d["tests_100k"] !== null)}
                  dataSet={dataSet}
                  svgWidth={width}
                />
              )}
            {useDataSetId === "testing-state-weekly-change" &&
              !isDataLoading && (
                <DiffChart
                  data={data.filter(
                    (d) =>
                      d["7-day_positivity_now"] <= 1 &&
                      d.positivity_type !== null
                  )}
                  dataSet={dataSet}
                  svgWidth={width}
                />
              )}
          </div>
          {!isDataLoading && !totalByPopLoading && (
            <div className={styles.table}>
              <TestingTable data={dataSet.tableData} />
            </div>
          )}
        </div>
      </div>
    </SideNarrative>
  );
};

export default TestingGraphAndTable;
