import React from "react";
import PropTypes from "prop-types";
import MediaQuery from "react-responsive";

import embeds from "data/embeds";
import EmbedFrame from "components/common/EmbedFrame";

const Embed = ({ embedId }) => {
  const embedConfig = embeds.find((e) => e.id === embedId);
  if (!embedConfig) {
    return null;
  }

  return embedConfig.sizes.map((options, i) => {
    const key = `embed-${i}`;
    const frame = (
      <EmbedFrame
        key={key}
        src={options.path}
        width={options.width}
        height={options.height}
      />
    );

    switch (options.size) {
      case "mobile":
        return (
          <MediaQuery key={key} maxWidth={767}>
            {frame}
          </MediaQuery>
        );
      case "desktop":
        return (
          <MediaQuery key={key} minWidth={768}>
            {frame}
          </MediaQuery>
        );
      default:
        return frame;
    }
  });
};

Embed.propTypes = {
  embedId: PropTypes.string.isRequired,
};

export default Embed;
