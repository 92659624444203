import {format} from "d3-format";
import kebabCase from "lodash.kebabcase";

export const ALL_STATE_META = [
    {
        row: 6,
        col: 7,
        abbr: 'AL',
        name: 'Alabama',
        size: 'tall',
        fips: '01'
    },
    {
        row: 0,
        col: 0,
        abbr: 'AK',
        name: 'Alaska',
        size: 'medium',
        fips: '02'
    },
    {
        row: 5,
        col: 2,
        abbr: 'AZ',
        name: 'Arizona',
        size: 'tall',
        fips: '04'
    },
    {
        row: 5,
        col: 5,
        abbr: 'AR',
        name: 'Arkansas',
        size: 'medium',
        fips: '05'
    },
    {
        row: 4,
        col: 1,
        abbr: 'CA',
        name: 'California',
        size: 'tall',
        fips: '06'
    },
    {
        row: 4,
        col: 3,
        abbr: 'CO',
        name: 'Colorado',
        size: 'medium',
        fips: '08'
    },
    {
        row: 3,
        col: 10,
        abbr: 'CT',
        name: 'Connecticut',
        size: 'medium',
        fips: '09'
    },
    {
        row: 4,
        col: 10,
        abbr: 'DE',
        name: 'Delaware',
        size: 'tall',
        fips: '10'
    },
    {
        row: 5,
        col: 9,
        abbr: 'DC',
        name: 'District of Columbia',
        size: 'medium',
        fips: '11'
    },
    {
        row: 7,
        col: 9,
        abbr: 'FL',
        name: 'Florida',
        size: 'tall',
        fips: '12'
    },
    {
        row: 6,
        col: 8,
        abbr: 'GA',
        name: 'Georgia',
        size: 'medium',
        fips: '13'
    },
    {
        row: 7,
        col: 0,
        abbr: 'HI',
        name: 'Hawaii',
        size: 'medium',
        fips: '15'
    },
    {
        row: 2,
        col: 2,
        abbr: 'ID',
        name: 'Idaho',
        size: 'tall',
        fips: '16'
    },
    {
        row: 2,
        col: 6,
        abbr: 'IL',
        name: 'Illinois',
        size: 'tall',
        fips: '17'
    },
    {
        row: 3,
        col: 6,
        abbr: 'IN',
        name: 'Indiana',
        size: 'tall',
        fips: '18'
    },
    {
        row: 3,
        col: 5,
        abbr: 'IA',
        name: 'Iowa',
        size: 'medium',
        fips: '19'
    },
    {
        row: 5,
        col: 4,
        abbr: 'KS',
        name: 'Kansas',
        size: 'short',
        fips: '20'
    },
    {
        row: 4,
        col: 6,
        abbr: 'KY',
        name: 'Kentucky',
        size: 'short',
        fips: '21'
    },
    {
        row: 6,
        col: 5,
        abbr: 'LA',
        name: 'Louisiana',
        size: 'medium',
        fips: '22'
    },
    {
        row: 0,
        col: 11,
        abbr: 'ME',
        name: 'Maine',
        size: 'tall',
        fips: '23'
    },
    {
        row: 4,
        col: 9,
        abbr: 'MD',
        name: 'Maryland',
        size: 'short',
        fips: '24'
    },
    {
        row: 2,
        col: 11,
        abbr: 'MA',
        name: 'Massachusetts',
        size: 'short',
        fips: '25'
    },
    {
        row: 2,
        col: 8,
        abbr: 'MI',
        name: 'Michigan',
        size: 'tall',
        fips: '26'
    },
    {
        row: 2,
        col: 5,
        abbr: 'MN',
        name: 'Minnesota',
        size: 'tall',
        fips: '27'
    },
    {
        row: 6,
        col: 6,
        abbr: 'MS',
        name: 'Mississippi',
        size: 'tall',
        fips: '28'
    },
    {
        row: 4,
        col: 5,
        abbr: 'MO',
        name: 'Missouri',
        size: 'medium',
        fips: '29'
    },
    {
        row: 2,
        col: 3,
        abbr: 'MT',
        name: 'Montana',
        size: 'medium',
        fips: '30'
    },
    {
        row: 4,
        col: 4,
        abbr: 'NE',
        name: 'Nebraska',
        size: 'short',
        fips: '31'
    },
    {
        row: 3,
        col: 2,
        abbr: 'NV',
        name: 'Nevada',
        size: 'tall',
        fips: '32'
    },
    {
        row: 1,
        col: 11,
        abbr: 'NH',
        name: 'New Hampshire',
        size: 'tall',
        fips: '33'
    },
    {
        row: 3,
        col: 9,
        abbr: 'NJ',
        name: 'New Jersey',
        size: 'tall',
        fips: '34'
    },
    {
        row: 5,
        col: 3,
        abbr: 'NM',
        name: 'New Mexico',
        size: 'medium',
        fips: '35'
    },
    {
        row: 2,
        col: 9,
        abbr: 'NY',
        name: 'New York',
        size: 'medium',
        fips: '36'
    },
    {
        row: 5,
        col: 7,
        abbr: 'NC',
        name: 'North Carolina',
        size: 'short',
        fips: '37'
    },
    {
        row: 2,
        col: 4,
        abbr: 'ND',
        name: 'North Dakota',
        size: 'short',
        fips: '38'
    },
    {
        row: 3,
        col: 7,
        abbr: 'OH',
        name: 'Ohio',
        size: 'tall',
        fips: '39'
    },
    {
        row: 6,
        col: 4,
        abbr: 'OK',
        name: 'Oklahoma',
        size: 'short',
        fips: '40'
    },
    {
        row: 3,
        col: 1,
        abbr: 'OR',
        name: 'Oregon',
        size: 'tall',
        fips: '41'
    },
    {
        row: 3,
        col: 8,
        abbr: 'PA',
        name: 'Pennsylvania',
        size: 'medium',
        fips: '42'
    },
    {
        row: 7,
        col: 11,
        abbr: 'PR',
        name: 'Puerto Rico',
        size: 'medium',
        fips: '43'
    },
    {
        row: 2,
        col: 10,
        abbr: 'RI',
        name: 'Rhode Island',
        size: 'medium',
        fips: '44'
    },
    {
        row: 5,
        col: 8,
        abbr: 'SC',
        name: 'South Carolina',
        size: 'short',
        fips: '45'
    },
    {
        row: 3,
        col: 4,
        abbr: 'SD',
        name: 'South Dakota',
        size: 'medium',
        fips: '46'
    },
    {
        row: 5,
        col: 6,
        abbr: 'TN',
        name: 'Tennessee',
        size: 'short',
        fips: '47'
    },
    {
        row: 7,
        col: 4,
        abbr: 'TX',
        name: 'Texas',
        size: 'tall',
        fips: '48'
    },
    {
        row: 4,
        col: 2,
        abbr: 'UT',
        name: 'Utah',
        size: 'tall',
        fips: '49'
    },
    {
        row: 1,
        col: 10,
        abbr: 'VT',
        name: 'Vermont',
        size: 'tall',
        fips: '50'
    },
    {
        row: 4,
        col: 8,
        abbr: 'VA',
        name: 'Virginia',
        size: 'medium',
        fips: '51'
    },
    {
        row: 2,
        col: 1,
        abbr: 'WA',
        name: 'Washington',
        size: 'medium',
        fips: '53'
    },
    {
        row: 4,
        col: 7,
        abbr: 'WV',
        name: 'West Virginia',
        size: 'tall',
        fips: '54'
    },
    {
        row: 2,
        col: 7,
        abbr: 'WI',
        name: 'Wisconsin',
        size: 'tall',
        fips: '55'
    },
    {
        row: 3,
        col: 3,
        abbr: 'WY',
        name: 'Wyoming',
        size: 'medium',
        fips: '56'
    }
].map(d => ({...d, id: kebabCase(d.name)}));

export const COLORS = {
    grayLighter: '#f2f2f2',
    grayLight: '#e0e0e0',
    gray: '#a3a3a3',
    mapStateGray: '#E6EBEC',
    blueGray: '#5C6A80',
    grayAlt: '#CECECE',
    blueDark: '#06224f',
    blue: '#8391FA',
    royalBlue: '#0076FF',
    red: '#E8927C',
    green: '#4ABEA9',
    orange: '#F2A241',
    lightOrange: '#FDF3E8',
    brightOrange: '#DE6F00',
    darkOrange: '#E16360',
    textBlue: '#002961',
    white: '#fff'
};
export const DEFAULT_STATE_NAME = 'alabama';
export const valueAccessor = d => d.id;
export const nameAccessor = d => d ? d.name : null;
export const labelAccessor = d => d ? d.label : null;
export const DURATION = 1000;
export const COMMA_FORMATTER = format(',.0f');
export const SINGLE_DECIMAL_FORMATTER = format(',.1f');
export const PERCENT_FORMATTER = format(',.0%');
export const SINGLE_DECIMAL_PERCENT_FORMATTER = format(',.1%');
