import React, { useEffect, useState } from "react";
import * as GTM from "util/gtm";
import styles from "./DetailedDemographic.module.scss";
import { getPage } from "data/util";
import { findStateCode, states } from "data/geo";
import Page from "components/layout/Page";
import CustomSelect from "components/common/CustomSelect";
import { useFetch } from "util/hooks/useFetch";
import GroupedBar from "./GroupedBar";
import Tooltip, { TooltipContext } from "./Tooltip";
import { max } from "d3-array";
import { formatLastUpdatedDate } from "../../DataCenter/charts/USStateDataAvailability/util";

const DetailedDemographic = (props) => {
  const page = getPage(props.location.pathname);
  const demographicOptions = [
    { value: "age", label: "Age" },
    { value: "ethnicity", label: "Ethnicity" },
    { value: "gender_sex", label: "Gender or Sex" },
    { value: "race", label: "Race" },
    { value: "race_ethnicity", label: "Race and Ethnicity" },
  ];
  const [demographic, setDemographic] = useState(demographicOptions[0]);
  const stateOptions = states.map((s) => {
    return { value: findStateCode(s).toLowerCase(), label: s as string };
  });
  const [state, setState] = useState<{ value: string; label: string }>(
    stateOptions[0]
  );
  const [data, isLoading] = useFetch(
    `https://jhucoronavirus.azureedge.net/api/v2/regions/us/${state.value}.json`
  );
  const [dateUpdated, isDateLoading] = useFetch(
    "https://jhucoronavirus.azureedge.net/api/v2/demographics/age_demographics.json"
  );
  const [lastUpdated, setLastUpdated] = useState("");

  const [xMax, setXMax] = useState(0);
  const [tooltip, setTooltip] = useState({
    show: false,
    x: 0,
    y: undefined,
    content: undefined,
    dataset: undefined,
    innerwidth: 600,
    innerheight: 400,
    margintop: 40,
    marginleft: 160,
    bars: undefined,
    lines: 1,
  });
  const value = { state: tooltip, dispatch: setTooltip };

  useEffect(() => {
    if (dateUpdated && !isDateLoading) {
      const updated = formatLastUpdatedDate(dateUpdated?.vax_test_date);
      setLastUpdated(updated);
    }
  }, [dateUpdated, isDateLoading]);

  useEffect(() => {
    if (!isLoading) {
      let pop_max = 0,
        cases_max = 0,
        deaths_max = 0,
        tests_max = 0,
        vaccines_max = 0;
      if (data.demographic_populations) {
        pop_max = max(
          Object.entries(data.demographic_populations)
            .filter(([key, value]) => key.includes(demographic.value))
            .map(([key, value]) => value as number)
        );
      }
      if (data.confirmed_cases_demographics) {
        cases_max = max(
          Object.entries(data.confirmed_cases_demographics)
            .filter(([key, value]) => key.includes(demographic.value))
            .map(([key, value]) => value as number)
        );
      }
      if (data.deaths_demographics) {
        deaths_max = max(
          Object.entries(data.deaths_demographics)
            .filter(([key, value]) => key.includes(demographic.value))
            .map(([key, value]) => value as number)
        );
      }
      if (data.tests_demographics) {
        tests_max = max(
          Object.entries(data.tests_demographics)
            .filter(([key, value]) => key.includes(demographic.value))
            .map(([key, value]) => value as number)
        );
      }
      if (data.vaccines_demographics) {
        vaccines_max = max(
          Object.entries(data.vaccines_demographics)
            .filter(([key, value]) => key.includes(demographic.value))
            .map(([key, value]) => value as number)
        );
      }
      setXMax(
        max([pop_max, cases_max, deaths_max, tests_max, vaccines_max]) / 100
      );
    }
  }, [data, demographic, isLoading]);

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
  }, []);

  return (
    <Page page={page}>
      <div className={styles.container}>
        {lastUpdated && (
          <>
            <p>
              <strong>This page was last updated on {lastUpdated}.</strong>
            </p>
            <br />
          </>
        )}
        <div className={styles.selectorRow}>
          <div className={styles.selectContainer}>
            <CustomSelect
              noAllOption
              allOptionLabel={"Alabama"}
              classNames={styles.select}
              label="Select a State"
              multi={false}
              options={stateOptions}
              onSelect={(selected) => {
                setState(selected);
              }}
              isSearchable={false}
            />
          </div>
          <div className={styles.selectContainer}>
            <CustomSelect
              noAllOption
              allOptionLabel={demographicOptions[0].label}
              classNames={styles.select}
              label="Select a Demographic"
              multi={false}
              options={demographicOptions}
              onSelect={(selected) => {
                setDemographic(selected);
              }}
              isSearchable={false}
            />
          </div>
        </div>
        {!isLoading && (
          <div className={styles.barGrid}>
            <TooltipContext.Provider value={value}>
              <GroupedBar
                data={data.confirmed_cases_demographics}
                population={data.demographic_populations}
                xMax={xMax}
                svgWidth={400}
                demographic={demographic}
                state={state.label}
                dataset={{
                  value: "cases",
                  label: "Cases",
                  lightColor: "#EECA92",
                  darkColor: "#E88611",
                }}
              >
                <Tooltip
                  width={160}
                  height={44}
                  dataset={"cases"}
                  xMax={xMax}
                />
              </GroupedBar>
            </TooltipContext.Provider>
            <TooltipContext.Provider value={value}>
              <GroupedBar
                data={data.deaths_demographics}
                population={data.demographic_populations}
                xMax={xMax}
                svgWidth={400}
                demographic={demographic}
                state={state.label}
                dataset={{
                  value: "deaths",
                  label: "Deaths",
                  lightColor: "#E9AB96",
                  darkColor: "#DC3B20",
                }}
              >
                <Tooltip
                  width={160}
                  height={44}
                  dataset={"deaths"}
                  xMax={xMax}
                />
              </GroupedBar>
            </TooltipContext.Provider>
            <TooltipContext.Provider value={value}>
              <GroupedBar
                data={data.tests_demographics}
                population={data.demographic_populations}
                xMax={xMax}
                svgWidth={400}
                demographic={demographic}
                state={state.label}
                dataset={{
                  value: "tests",
                  label: "Tests",
                  lightColor: "#BFC4D9",
                  darkColor: "#3042C7",
                }}
              >
                <Tooltip
                  width={160}
                  height={44}
                  dataset={"tests"}
                  xMax={xMax}
                />
              </GroupedBar>
            </TooltipContext.Provider>
            <TooltipContext.Provider value={value}>
              <GroupedBar
                data={data.vaccines_demographics}
                population={data.demographic_populations}
                xMax={xMax}
                svgWidth={400}
                demographic={demographic}
                state={state.label}
                dataset={{
                  value: "vaccines",
                  label: "Vaccines",
                  lightColor: "#C0CA9A",
                  darkColor: "#7A9138",
                }}
              >
                <Tooltip
                  width={160}
                  height={44}
                  dataset={"vaccines"}
                  xMax={xMax}
                />
              </GroupedBar>
            </TooltipContext.Provider>
          </div>
        )}
        <p>
          <strong>Data Sources:</strong>{" "}
          <a href="https://github.com/govex/COVID-19/tree/master/data_tables/demographic_data">
            JHU CCI
          </a>
          . Data shown is only for cases, deaths, tests, and vaccines where
          racial data is reported.
        </p>
      </div>
    </Page>
  );
};

export default DetailedDemographic;
