import React, { FC } from "react";
import cx from "classnames";
import styles from "./EligibilityVisual.module.scss";
import { ReactComponent as Eligibility } from "../icon/earliest-eligibility.svg";

export type EligibilityVisualProps = {
  className?: string;
};

const EligibilityVisual: FC<EligibilityVisualProps> = ({ className }) => {
  return (
    <div className={cx(styles.base, className)}>
      <Eligibility className={styles.left} />
      <div className={styles.borderContainer}>
        <div className={styles.textContainer}>
          <div className={styles.textBlock}>
            <Eligibility className={styles.left} />
            <span>Earliest Eligibility</span>
          </div>
          <div className={styles.textBlock}>
            <Eligibility className={styles.right} />
            <span>LATEST ELIGIBILITY</span>
          </div>
        </div>
        <div className={styles.borderLine}>
          <hr />
        </div>
      </div>
      <Eligibility className={styles.right} />
    </div>
  );
};

export default EligibilityVisual;
