import React from 'react';
import styles from './CustomSelect.module.scss';
import CustomSelect from 'components/common/CustomSelect';
import SingleSelect from 'components/layout/shell/Filter/SingleSelect';
import { arrayToFilterOption } from 'util/hooks/useSingleSelect';


const options = [
  {
    value: "value 1",
    label: "Testing"
  },
  {
    value: "value 2",
    label: "Tracking"
  },
  {
    value: "value 3",
    label: "Contact Tracing"
  },
  {
    value: "value 4",
    label: "Schools"
  },
  {
    value: "value 5",
    label: "Resources"
  },
];

const mockSelectOptions = arrayToFilterOption([
  "Option 1",
  "Option 2",
  "Option 3",
  "Option 4",
  "Option 5",
]);

const mockSelect = {
  options: mockSelectOptions,
  allOption: true,
};

const CustomSelectStyleGuide = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles["styleguide-annotation"]}>Custom Select Components</h1>

      <h2>React-Select Based</h2>
      <div className={styles.selectContainer}>
        <CustomSelect
          multi={false}
          options={options}
          onSelect={() => { }}
        />

        <br />

        <CustomSelect
          multi
          options={options}
          onSelect={() => { }}
        />
      </div>

      <br />
      <br />

      <h2>Fully Custom Select</h2>
      <div className={styles.selectContainer}>
        <SingleSelect
          {...mockSelect}
        />
      </div>
      <br />
    </div>
  );
};

export default CustomSelectStyleGuide;