import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import * as GTM from "util/gtm";
import ReactMarkdown from "react-markdown";
import Page from "components/layout/Page";
import { getAuthors, getFilteredContent, getPage } from "data/util";
import { eventPageData, getEventState } from "./util";
import EventHeader from "./EventHeader";
import VideoFeed from "../VideoFeed";
import { getVideoLinkType, VideoDetailsButton } from "../Videos";
import RenderVideoFrame from "../RenderVideoFrame";
import HFormat from "components/common/HFormat";
import styles from "./Events.module.scss";
import { getEventMod } from "./EventMod";
import { EventContentHeaderContainer, EventQAEmbed } from "./EventAtoms";
import Speakers from "./Speakers";
import TextBanner from "components/common/TextBanner";

const Events = ({ eventId, videoId, pageData, info, dates }) => {
  pageData = pageData || getPage(`/live/events/${eventId}`);

  const videoContainerRef = useRef(null);
  const detailsContainer = useRef(null);
  const [currentEventId] = useState(eventId);
  const [currentEventState, setEventState] = useState(getEventState(dates));
  const [mediaHeight, setMediaHeight] = useState(354);

  const isPostState = currentEventState === "post";

  //Fetch all content attached to the event
  const eventContents = (
    getFilteredContent({ types: ["webcasts"] }) || []
  ).filter((content) => content.event === eventId);

  const useVideoId = !!videoId ? videoId : info?.defaultVideoId;
  const useVideo = eventContents.find((content) => content.slug === useVideoId);

  const setupActiveEvent = () => {
    const activeEvent = { ...info, ...useVideo };
    if (activeEvent && !isPostState) {
      // For reocurring events: fetch most recent content for the event
      const latestContent = eventContents.sort(
        (a, b) => new Date(b.date || null) - new Date(a.date || null)
      )?.[0];
      activeEvent.image =
        latestContent?.image || activeEvent.image || activeEvent.meta?.image;
      activeEvent.videoUrl = activeEvent.videoUrl || latestContent?.videoUrl;
    }
    return activeEvent;
  };

  const setupPageData = (pageData, activeEvent) => {
    if (!!activeEvent && !!pageData && !!pageData.meta) {
      pageData.meta.title = activeEvent.title || pageData.meta.title;
      pageData.subtitle = !isPostState
        ? activeEvent.subtitle || pageData.subtitle
        : pageData.caption || activeEvent.subtitle;
      pageData.caption = isPostState ? null : activeEvent.caption;
    }
    return pageData;
  };

  // If event is over, but no CMS video content is provided yet:
  const isPostInterim = isPostState && !useVideo;

  const activeEvent = setupActiveEvent();
  // const [activeEvent] = useState(setupActiveEvent());
  const [showDetails, setShowDetails] = useState(isPostInterim || !isPostState);

  // Check for custom Event Mod component by eventId and populate with activeEvent
  const eventAddon = getEventMod(eventId, activeEvent);
  const hasAddonContent = !!eventAddon?.content && !!eventAddon?.content();

  const currentURL = activeEvent
    ? activeEvent.videoUrl || activeEvent.image
    : null;

  const videoLinkType =
    activeEvent && !!activeEvent.videoUrl ? getVideoLinkType(currentURL) : null;

  const scrollEffect = (topPos) => {
    if (window?.scrollTo)
      window.scrollTo({ top: topPos, left: 0, behavior: "smooth" });
  };

  const handleCountdownComplete = (mid) => {
    setEventState(mid ? "mid" : "post");
  };

  useEffect(() => {
    GTM.clear();
    GTM.push({ pageVerticals: ["vaccines"] });
  }, []);

  useEffect(() => {
    if (currentEventState === "post") {
      scrollEffect(
        videoContainerRef && videoContainerRef.current
          ? videoContainerRef.current.offsetTop * 2
          : 0
      );
      if (isPostInterim) {
        setShowDetails(true);
      }
    } else {
      setShowDetails(true);
    }
    const handleResize = () => {
      if (videoContainerRef && videoContainerRef.current)
        setMediaHeight(videoContainerRef.current.clientWidth / 1.78);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [videoLinkType, activeEvent, currentEventState, isPostInterim]);

  useEffect(() => {
    setEventState(getEventState(dates));
  }, [eventId, detailsContainer, dates, currentEventId]);

  const videoContainerProps = {
    height: `${mediaHeight}px`,
    padding: videoLinkType === null ? "0" : null,
  };

  const speakers = getAuthors(activeEvent?.authors);

  const textBannerProps = {
    title: "Friday COVID-19 Expert Briefing",
    content:
      "The Johns Hopkins 30-minute COVID-19 Briefings welcome viewers to submit questions to top pandemic experts.",
    label: "Sign Up",
    href: "/live-briefing-sign-up",
    targetBlank: false,
  };

  return (
    <Page
      wide
      page={setupPageData(pageData, activeEvent)}
      interiorHeader={
        <EventHeader
          {...pageData}
          eventId={eventId}
          dates={dates}
          icsSrc={info?.icsSrc}
          currentEventState={currentEventState}
          handleCountdownComplete={handleCountdownComplete}
        />
      }
    >
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <p style={{ textAlign: "center" }}></p>
          <div
            className={styles.videoContainerRef}
            ref={videoContainerRef}
            style={videoContainerProps}
          >
            <RenderVideoFrame
              mediaHeight={mediaHeight}
              videoLinkType={videoLinkType}
              videoUrl={currentURL}
              iframeProps={{ controls: 0 }}
              isImage={currentURL && videoLinkType === null}
            />
          </div>
        </div>

        <p>Something not right? Try refreshing your browser.</p>

        <TextBanner className={styles.textBanner} {...textBannerProps} />

        {/*Render Questions & Answers iframe block during the event, if url is provided */}
        <EventQAEmbed
          {...info?.embedQA}
          eventState={currentEventState}
          dates={dates}
        />

        {!!eventAddon?.contentHeader
          ? eventAddon.contentHeader(currentEventState)
          : !!activeEvent && (
              <EventContentHeaderContainer
                eventState={currentEventState}
                eventRegistry={{ href: info.registerUrl }}
              >
                <HFormat size={3} noMarginBtm>
                  {activeEvent.title}
                </HFormat>
                {!!activeEvent.description && (
                  <ReactMarkdown source={activeEvent.description} />
                )}
              </EventContentHeaderContainer>
            )}
        <div ref={detailsContainer}>
          {showDetails &&
            (hasAddonContent
              ? eventAddon.content()
              : !!activeEvent?.content && (
                  <div>
                    <ReactMarkdown
                      className={styles.embedDescription}
                      source={activeEvent.content}
                    />
                    {!!speakers.length && <Speakers authors={speakers} />}
                  </div>
                ))}
          <br />
          {isPostState && (!!activeEvent?.content || hasAddonContent) && (
            <VideoDetailsButton
              onClick={() => setShowDetails(!showDetails)}
              show={showDetails}
            />
          )}
        </div>
        {isPostState && !isPostInterim && (
          <VideoFeed
            classNames={styles.feedContainer}
            currentVideoId={useVideoId}
            eventId={eventId}
            topSplit={false}
            hasMajor={false}
            hideNoContentsMessage
          />
        )}
      </div>
    </Page>
  );
};

Events.propTypes = {
  eventId: PropTypes.string.isRequired,
  pageData: PropTypes.shape(eventPageData).isRequired,
  dates: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired,
  videoId: PropTypes.string,
  info: PropTypes.object,
};

export default Events;
