import React from "react";
import PropTypes from "prop-types";
import { expandCountryCode, getRegionBanner, slugify } from "data/geo";
import classNames from "classnames/bind";
import styles from "./RegionPage.module.scss";
import Share from "components/common/Share";
import Meta from "components/common/Meta";
import Button from "components/common/Button";
import RegionMenu from "../RegionMenu";
import { shouldRenderAsEmbed } from "./util";
import PageNavigator from "components/common/PageNavigator";
import { pageSectionsProps } from "data/page-sections";
import TextBanner from "components/common/TextBanner";

const cx = classNames.bind(styles);

const RegionPage = ({
  children,
  regionCode,
  regionName,
  regionSlug,
  isState,
  regionMenuProps = {
    title: "",
    regions: [],
    path: "",
  },
  sections = [],
  className,
}) => {
  const renderHeader = (isState) => {
    const text = isState ? "United States" : "World Countries";
    return (
      <div>
        <div className="small-heading">{text}</div>
        <h1>{isState ? regionName : expandCountryCode(regionCode)}</h1>
      </div>
    );
  };

  const renderAsEmbed = shouldRenderAsEmbed();
  const regionBanner = getRegionBanner(regionCode, isState);

  return (
    <div className={cx(styles.base, className)}>
      <Meta
        title={regionName}
        description={`${regionName} - COVID New Cases, Deaths, Testing Data - Johns Hopkins Coronavirus Resource Center`}
        path={`/region/${regionSlug}`}
        image={
          isState
            ? "/images/state-page-meta-image.jpg"
            : "/images/region-meta-image.jpg"
        }
      />
      <div className={cx("header")}>
        {renderHeader(isState)}
        {renderAsEmbed && (
          <div className={styles.embedSiteLink}>
            <Button
              buttonStyle="underlined"
              icon="right"
              target="_blank"
              rel="noopener noreferrer"
              href={"https://coronavirus.jhu.edu"}
            >
              For more information visit coronavirus.jhu.edu
            </Button>
          </div>
        )}
        {!renderAsEmbed && <Share />}
      </div>

      {regionBanner && (
        <TextBanner className={styles.regionBanner} {...regionBanner} />
      )}

      <PageNavigator sections={sections} />

      {/* Render the inner contents for the page here */}
      {children}

      {regionMenuProps && !renderAsEmbed && (
        <RegionMenu
          title={regionMenuProps.title}
          items={regionMenuProps.regions.map((region) => ({
            name: region,
            to: `${regionMenuProps.path}${slugify(region)}`,
          }))}
        />
      )}
    </div>
  );
};

RegionPage.propTypes = {
  regionName: PropTypes.string.isRequired,
  regionSlug: PropTypes.string.isRequired,
  regionCode: PropTypes.string.isRequired,
  regionMenuProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    regions: PropTypes.array.isRequired,
    path: PropTypes.oneOf(["/region/us/", "/region/"]).isRequired,
  }),
  isState: PropTypes.bool,
  sections: pageSectionsProps,
  className: PropTypes.string,
};

export default RegionPage;
