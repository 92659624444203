import React from "react";
import NewCases from "./NewCases";
import Tests from "./Tests";
import PercentPositive from "./PercentPositive";
import BaseChart from "./BaseChart";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {
  COMMA_FORMATTER,
  NEW_CASES,
  PERCENT_FORMATTER,
  PERCENT_POSITIVE,
  SINGLE_DECIMAL_FORMATTER,
  STATE_NAME,
  TESTS,
} from "../../../../constants";
import TrendColumn from "./TrendColumn";
import DownloadLink from "../../DownloadLink";
import CalcMethod from "./CalcMethod";
import { useSelector } from "react-redux";
import { getPercentPositiveCurrentValue } from "../utils";
import NoData from "../../NoData";
import { ReactComponent as WarningIcon } from "images/icons/warning.svg";
import { static_screenshots_path } from "util/hooks/useFetch";

const StateRow = ({
  filteredData,
  stateCell = true,
  isStopped,
  stoppedDate,
}) => {
  // Get current values
  const newCases = filteredData.currentValues[NEW_CASES];
  const tests = filteredData.currentValues[TESTS];

  // Get the selected percent positive value and metadata, if available
  const selectedPercentPositiveApproach = filteredData.values[
    PERCENT_POSITIVE
  ].find((d) => d.selected);
  const metadata = useSelector((state) => state.testingTracker.metadata);

  const stateSlug = filteredData[STATE_NAME].replace(/\s+/g, "-").toLowerCase();

  return (
    <TableRow className={`table__row ${isStopped ? "stopped" : ""}`}>
      {stateCell && (
        <TableCell className="table__cell table__cell--state">
          <span className="table__state-name">{filteredData[STATE_NAME]}</span>
          <DownloadLink
            href={`${static_screenshots_path}overview-${stateSlug}.png`}
          />
        </TableCell>
      )}
      <TableCell className="table__cell">
        <div className="table__chart">
          <BaseChart className="visualization__container">
            <NewCases data={filteredData.values[NEW_CASES]} />
          </BaseChart>
          <div className="number number--teal">{`${
            newCases < 0 ? "-" : "+"
          }${COMMA_FORMATTER(Math.abs(newCases))} new`}</div>
        </div>
        <TrendColumn
          weeklyTrends={filteredData.weeklyTrends[NEW_CASES]}
          type={NEW_CASES}
        />
      </TableCell>
      {filteredData.values[TESTS].length ? (
        <TableCell className="table__cell">
          <div className="table__chart">
            <BaseChart className="visualization__container">
              <Tests data={filteredData.values[TESTS]} />
            </BaseChart>
            <div className="number number--black">{`${SINGLE_DECIMAL_FORMATTER(
              tests
            )} tests per 100k`}</div>
          </div>
          <TrendColumn
            weeklyTrends={filteredData.weeklyTrends[TESTS]}
            type={TESTS}
          />
        </TableCell>
      ) : (
        <NoData />
      )}
      {selectedPercentPositiveApproach ? (
        <TableCell className="table__cell">
          <div className="table__chart">
            <BaseChart className="visualization__container">
              <PercentPositive
                data={selectedPercentPositiveApproach.values}
                stateName={filteredData[STATE_NAME]}
              />
            </BaseChart>
            <div className="number number--teal">
              {`${PERCENT_FORMATTER(
                getPercentPositiveCurrentValue(selectedPercentPositiveApproach)
              )} positive`}
            </div>
          </div>
          <CalcMethod
            approach={metadata.find(
              (d) => d.approachId === selectedPercentPositiveApproach.approachId
            )}
          />
        </TableCell>
      ) : (
        <NoData />
      )}
      {isStopped && (
        <div className="stopped-reporting-text">
          <h3>{`${filteredData[
            STATE_NAME
          ].toUpperCase()} HAS STOPPED REPORTING TESTING DATA`}</h3>
          <p>{`We used to calculate ${filteredData[STATE_NAME]}’s positivity rate using Approach 3: Positive People / Total Specimens. ${filteredData[STATE_NAME]} has stopped reporting Positive People, so we can no longer provide an accurate statistic for ${filteredData[STATE_NAME]}’s positivity rates.`}</p>
          <p className="stopped-reporting-date">
            <WarningIcon /> STOPPED REPORTING: <span>{stoppedDate}</span>
          </p>
        </div>
      )}
    </TableRow>
  );
};
StateRow.defaultProps = {
  filteredData: {},
};
export default StateRow;
