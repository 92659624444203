import React from "react";
import styles from "./DropdownOverlay.module.scss";
import cx from "classnames";

export type TDropdownOverlayProps = {
  onClick?: () => void;
  isOpen?: boolean;
  className?: string;
};

export const DropdownOverlay = ({
  isOpen,
  onClick,
  className,
}: TDropdownOverlayProps) => {
  const handleOnClick = () => onClick?.();
  return (
    <div
      className={cx(styles.base, isOpen && styles.open, className)}
      onClick={handleOnClick}
    />
  );
};

export default DropdownOverlay;
