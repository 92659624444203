import useDimensions from "util/hooks/useDimensions";
import React, { useRef, useState } from "react";
import { Redirect } from "react-router-dom";
import MapSVG from "./MapSVG";
import USBlockMap from "./USBlockMap";
import Tooltip, { TooltipContext } from "./Tooltip";

const Map = ({ dataSet, field, data, mapData, tileMap, mobile }) => {
  const [redirect] = useState("");
  const [tooltip, setTooltip] = useState({
    show: false,
    x: 0,
    y: 0,
    content: "",
  });

  const ref = useRef(null);
  const [ width ] = useDimensions(ref);

  return (
    <div ref={ref}>
      {redirect && <Redirect to={redirect} push />}
      <TooltipContext.Provider value={{ ...tooltip, setTooltip }}>
      {mobile && dataSet.name === "US States"
        ? (<USBlockMap
            svgWidth={width}
            dataSet={dataSet}
            field={field}
            data={data}
            tileMap={tileMap} />)
        :  (<MapSVG
              svgWidth={width}
              dataSet={dataSet}
              field={field}
              data={data}
              mapData={mapData}
              ttDims={{width:225,height:150}}
            >
              <Tooltip width={225} height={150} />
            </MapSVG>)
      }
      </TooltipContext.Provider>
    </div>
  );
};

export default Map;
