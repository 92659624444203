import React, { useEffect, useState } from "react";
import { Link, Switch, Route } from "react-router-dom";
import Page from "components/layout/Page";
import styles from "./VaccineProcess.module.scss";
import { getPage } from "data/util";
import { useFetch } from "util/hooks/useFetch";
import {
  dataSets,
  fields as fieldsInternational,
  fieldsUSA,
} from "../constants";
import Map from "../Map";
import VaccinesTableTab from "../VaccinesTableTab";
import Section from "../Section";
import RaceToVaccine from "../RaceToVaccine";
import LayoutPlaceholder from "components/common/LayoutPlaceholder";
import Modal from "components/common/Modal";
import ReactMarkdown from "react-markdown";
import Meta from "components/common/Meta";
import USStatesTable from "../../VaccineUSStates/USStatesTable";
import InternationalTable from "../../VaccineInternational/InternationalTable";
import useVaccineDataInternational from "../../VaccineInternational/use-vaccine-data";

function findPos(obj) {
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [curtop];
  }
}

const ANCHORS = [
  {
    title: "Vaccination rollout across the U.S.",
    to: "us-states#vaccination-rollout-us",
  },
  {
    title: "The race to vaccinate the U.S",
    to: "us-states#race-to-vaccine-us",
  },
  {
    title: "Vaccination rollout across the World",
    to: "international",
  },
  {
    title: "The race to vaccinate the world",
    to: "international#race-to-vaccine-world",
  },
];

const VaccineProcess = ({ location }) => {
  const usPage = getPage("/vaccines/us-states");
  const worldPage = getPage("/vaccines/international");

  const pageContent = {
    title: "UNDERSTANDING VACCINATION PROGRESS",
  };

  const breakpoint = 768;

  /* State Management: */
  const usaDataSet = dataSets[0];
  const worldDataSet = dataSets[1];

  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);

  /* Data Loading: */
  const [mapUSData, isMapUSLoading] = useFetch("geo_us_states");
  const [baseUSData, isBaseUSData] = useFetch(usaDataSet.resourceKey);

  const [mapWorldData, isMapWorldLoading] = useFetch("geo_world");
  const [baseWorldData, isBaseWorldData] = useFetch(worldDataSet.resourceKey);

  const [tileMapData, isTileMapLoading] = useFetch("tile_map");

  const [dataWorld, isLoadingWorld] = useVaccineDataInternational();

  const loading =
    isMapUSLoading ||
    isMapWorldLoading ||
    isBaseUSData ||
    isBaseWorldData ||
    isTileMapLoading;

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < breakpoint);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mobile]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    // if (loading) return;
    let id;
    // default will end up pos = 0
    if (location.pathname === "/vaccines/us-states") {
      if (location.hash === "#race-to-vaccine-us") {
        id = ANCHORS[1].to;
      } else if (location.hash === "#vaccination-rollout-us") {
        id = ANCHORS[0].to;
      } else {
        id = ANCHORS[0].to;
      }
    } else if (location.pathname === "/vaccines/international") {
      if (location.hash === "#race-to-vaccine-world") {
        id = ANCHORS[3].to;
      } else {
        id = ANCHORS[2].to;
      }
    }

    const pos = id ? findPos(document.getElementById(id)) : 0;
    const currentPosition = document.documentElement.scrollTop;
    window.scrollTo({
      top: mobile ? pos - currentPosition - 120 : pos - currentPosition - 154,
      left: 0,
      behavior: "smooth",
    });
  }, [mobile, location.pathname, location.hash]);

  const [modal, setModal] = useState({
    content: "",
    show: false,
  });

  return (
    <Page
      wide
      page={{
        section: "Vaccines",
        title: pageContent.title,
        // subtitle: pageContent.description,
        meta: { title: pageContent.title },
      }}
    >
      <Switch>
        <Route
          path="/vaccines/us-states"
          render={() => <Meta {...usPage.meta} />}
        />
        <Route
          path="/vaccines/international"
          render={() => <Meta {...worldPage.meta} />}
        />
      </Switch>
      <Modal
        show={modal.show}
        dismiss={() => {
          setModal({
            ...modal,
            show: false,
          });
        }}
        x
      >
        <ReactMarkdown
          source={"## What do these metrics mean?\n\n" + modal.content}
        />
      </Modal>
      <div className={styles.anchorsContainer}>
        <h2>Skip to:</h2>
        <div className={styles.anchors}>
          {ANCHORS.map((anchor, i) => (
            <Link key={`anchor-${i}-${anchor.to}`} to={anchor.to}>
              <span>{anchor.title}</span>
            </Link>
          ))}
        </div>
      </div>

      {/* USA */}
      <Section
        anchor={ANCHORS[0]?.to}
        title={
          usPage.content_blocks[
            "vaccination-rollout-across-the-united-states-blurb"
          ].title
        }
        content={
          usPage.content_blocks[
            "vaccination-rollout-across-the-united-states-blurb"
          ].content
        }
        buttonProps={{
          text: "What do these metrics mean?",
          style: "bordered",
          onClick() {
            const source =
              usPage.content_blocks[
                "vaccination-rollout-across-the-united-states-popup"
              ].content;
            setModal({
              show: true,
              content: source,
            });
          },
        }}
      />
      <div className={styles.map}>
        {loading ? (
          <LayoutPlaceholder
            style={{
              width: "100%",
              height: "calc(100% / 1.75)",
              overflow: "hidden",
            }}
            layout="page"
          />
        ) : (
          <Map
            dataSet={usaDataSet}
            field={fieldsUSA[0]}
            data={baseUSData}
            mapData={mapUSData}
            tileMap={tileMapData}
            mobile={mobile}
          />
        )}
      </div>

      <Section
        anchor={ANCHORS[1]?.to}
        title={
          usPage.content_blocks[
            "the-race-to-vaccinate-in-the-united-states-blurb"
          ].title
        }
        content={
          usPage.content_blocks[
            "the-race-to-vaccinate-in-the-united-states-blurb"
          ].content
        }
        buttonProps={{
          text: "What do these metrics mean?",
          style: "bordered",
          onClick() {
            const source =
              usPage.content_blocks[
                "the-race-to-vaccinate-in-the-united-states-popup"
              ].content;
            setModal({
              show: true,
              content: source,
            });
          },
        }}
      />
      <RaceToVaccine
        baseData={baseUSData}
        dataset={usaDataSet}
        fields={fieldsUSA}
        mobile={mobile}
        loading={loading}
      />

      {/* International */}
      <Section
        anchor={ANCHORS[2]?.to}
        title={
          usPage.content_blocks["vaccination-progress-across-the-world-blurb"]
            .title
        }
        content={
          usPage.content_blocks["vaccination-progress-across-the-world-blurb"]
            .content
        }
        buttonProps={{
          text: "What do these metrics mean?",
          style: "bordered",
          onClick() {
            const source =
              usPage.content_blocks[
                "vaccination-progress-across-the-world-popup"
              ].content;
            setModal({
              show: true,
              content: source,
            });
          },
        }}
      />
      {mobile &&
        (isLoadingWorld ? (
          <div />
        ) : (
          <div className={styles.tableContainer}>
            <InternationalTable data={dataWorld} />
          </div>
        ))}
      <div className={styles.map}>
        {loading ? (
          <LayoutPlaceholder
            style={{
              width: "100%",
              height: "calc(100% / 1.75)",
              overflow: "hidden",
            }}
            layout="page"
          />
        ) : (
          !mobile && (
            <Map
              dataSet={dataSets[1]}
              field={fieldsInternational[0]}
              data={baseWorldData}
              mapData={mapWorldData}
            />
          )
        )}
      </div>

      <Section
        anchor={ANCHORS[3]?.to}
        title={
          usPage.content_blocks["the-race-to-vaccinate-the-world-blurb"].title
        }
        content={
          usPage.content_blocks["the-race-to-vaccinate-the-world-blurb"].content
        }
        buttonProps={{
          text: "What do these metrics mean?",
          style: "bordered",
          onClick() {
            const source =
              usPage.content_blocks["the-race-to-vaccinate-the-world-popup"]
                .content;
            setModal({
              show: true,
              content: source,
            });
          },
        }}
      />

      <RaceToVaccine
        baseData={baseWorldData}
        dataset={worldDataSet}
        fields={fieldsInternational}
        mobile={mobile}
        loading={loading}
      />

      {/* Table */}
      <Section
        anchor={"vaccination-statistics"}
        title={
          mobile
            ? "Summary of US vaccination statistics"
            : usPage.content_blocks["summary-of-vaccination-statistics-blurb"]
                .title
        }
        content={
          usPage.content_blocks["summary-of-vaccination-statistics-blurb"]
            .content
        }
        buttonProps={{
          text: "What do these metrics mean?",
          style: "bordered",
          onClick() {
            const source =
              usPage.content_blocks["summary-of-vaccination-statistics-popup"]
                .content;
            setModal({
              show: true,
              content: source,
            });
          },
        }}
      />
      {mobile ? (
        <div className={styles.tableContainer}>
          <USStatesTable />
        </div>
      ) : (
        <VaccinesTableTab />
      )}
      <div className={styles.section}>
        <ReactMarkdown
          source={
            usPage.content_blocks["vaccination-progress-data-source"].content
          }
        />
      </div>
    </Page>
  );
};

export default VaccineProcess;
