import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    bar: {
        stroke: 'none',
        pointerEvents: 'none',
        fill: props => props.color
    }
}));

const Bar = ({x, y, height, width, fill}) => {
    const classes = useStyles({color: fill});

    return (
        <rect className={classes.bar} x={x} width={width} y={y} height={height}/>
    );
};

export default Bar;
