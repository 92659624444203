import React from 'react';
import ColumnLayout from 'components/layout/ColumnLayout';
import ArticleCard from 'components/layout/ArticleCard';

import { getSnapshotForRoute } from 'data/util';

export default function TestingTrackerNavContent() {
  return (
    <ColumnLayout layout="quartet">
      <ArticleCard
        dark
        vertical
        category="Testing Insights"
        title="Which U.S. states meet WHO testing guidelines?"
        href="/testing/testing-positivity"
        imageSrc={getSnapshotForRoute('/testing/testing-positivity', true)}
        containImage
        body={
          <p>
            The WHO recommends 3-12% test positivity as an indicator that
            countries are testing enough people. Which states are testing enough
            to meet this goal?
          </p>
        }
      />
      <ArticleCard
        dark
        vertical
        category="Testing Insights"
        title="International Comparison of Testing Statistics"
        href="/testing/international-comparison"
        imageSrc={getSnapshotForRoute('/testing/international-comparison', true)}
        containImage
        body={
          <p>
            How do positivity rates and numbers of tests per capita compare
            across countries?
          </p>
        }
      />
      <ArticleCard
        dark
        vertical
        category="Testing Insights"
        title="Cases, Deaths, and Testing in all 50 States"
        href="/testing/states-comparison"
        imageSrc={getSnapshotForRoute('/testing/states-comparison', true)}
        containImage
        body={
          <p>
            See how testing data indicates whether the spread of the virus is
            slowing or growing.
          </p>
        }
      />
      <ArticleCard
        dark
        vertical
        category="Testing Insights"
        title="Daily State-by-State testing trends"
        href="/testing/individual-states"
        imageSrc={getSnapshotForRoute('/testing/individual-states', true)}
        containImage
        body={
          <p>
            See daily changes in tests performed and positivity rates for each
            state.
          </p>
        }
      />
    </ColumnLayout>
  );
}
