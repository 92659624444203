import { Dataset } from "./types";

export const dataKeys: { [key: string]: Dataset } = {
  facets: {
    id: "facets",
    source: "checkboxes",
    name: "Timeline Comparisons",
    tab: "Comparisons",
    barLabel: "none",
    lineLabel: "7-Day Average",
    facetLabel: "none",
    order: 0,
    checked: null,
  },
  vaccines: {
    id: "vaccination",
    source: "vaccines",
    name: "Number of Daily Doses",
    tab: "Vaccinations",
    barLabel: "Number of Daily Doses",
    lineLabel: "7-Day Average of Daily Doses",
    facetLabel: "Vaccine Doses",
    order: 1,
    checked: "vaccines",
  },
  cases: {
    id: "new-confirmed-cases",
    source: "cases",
    name: "Number of Daily Cases",
    tab: "Cases",
    barLabel: "Number of Daily Cases",
    lineLabel: "7-Day Average of Daily Cases",
    facetLabel: "Cases",
    order: 2,
    checked: "cases",
  },
  deaths: {
    id: "deaths",
    source: "deaths",
    name: "Number of Daily Deaths",
    tab: "Deaths",
    barLabel: "Number of Daily Deaths",
    lineLabel: "7-Day Average of Daily Deaths",
    facetLabel: "Deaths",
    order: 3,
    checked: "deaths",
  },
  hospitalization: {
    id: "hospitalization",
    source: "hospitalization",
    name: "Daily COVID-19 Hospitalizations",
    tab: "Hospitalizations",
    barLabel: "Daily COVID-19 Hospitalizations",
    lineLabel: "7-Day Average of Daily COVID-19 Hospitalizations",
    facetLabel: "Hospitalizations",
    order: 4,
    checked: "hospitalization",
  },
};
