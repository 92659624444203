import React, { useEffect } from "react";
import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import NewsFeed from "components/content/News/NewsFeed";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import ColumnLayout from "components/layout/ColumnLayout";
import ArticleCard from "components/layout/ArticleCard";
import Button from "components/common/Button";
import { trackerToolArticles, insightsArticles } from "./article-content";
import questionLists from "../TestingFAQ/questions";
import classNames from "classnames/bind";
import styles from "./TestingHome.module.scss";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";
import { Popup } from "components/common/Popup";
const cx = classNames.bind(styles);

const { questions: faqs } = questionLists[0];

const TestingHome = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
    });
  }, []);
  return (
    <Page wide>
      <Popup />
      <div className={styles.container}>
        <h2>Testing Tracker</h2>
        <ArticleGridLayouts.OffCenterList articles={trackerToolArticles} />
        <hr />

        <ReactMarkdown
          source={content_blocks["testing-home-paragraph-a"]?.content}
        />

        <ArticleGridLayouts.OffCenterList articles={insightsArticles} />
        <hr />

        <ReactMarkdown
          source={content_blocks["testing-home-paragraph-b"]?.content}
        />

        <ArticleCard
          className={styles.testingToolkit}
          major
          category="Johns Hopkins Experts"
          title="COVID-19 Testing Toolkit"
          body={
            <p>
              The Johns Hopkins Center for Health Security has created the
              COVID-19 Testing Toolkit to provide information about specific
              tests and testing service--how they work and what is known about
              their accuracy. COVID-19 testing is a fast-growing field that is
              evolving as our understanding of SARS-CoV-2 improves and more test
              manufacturers enter the market. Tests are important to identify in
              what communities the virus is spreading, who has the virus, and
              how the virus is spreading. Testing is equally crucial to treating
              patients and informing good public health decision making during
              the pandemic.
            </p>
          }
          href="https://www.centerforhealthsecurity.org/covid-19TestingToolkit/"
          imageSrc="/images/assets/8dddb3323bf74e54b6954d28cd936dc3.jpg"
        />

        <hr />

        <h2>Questions about Testing</h2>
        <ColumnLayout layout={[[3, 3, 3, 3], [6, 6], [12]]}>
          {faqs.slice(0, 4).map(({ slug, question }) => (
            <Button
              key={slug}
              className={cx("faq-button")}
              buttonStyle="plain"
              icon="right"
              href={`/testing/testing-faq/${questionLists[0].slug}#${slug}`}
            >
              {question}
            </Button>
          ))}
        </ColumnLayout>
        <Button
          buttonStyle="bordered"
          icon="right"
          href={`/testing/testing-faq/${questionLists[0].slug}`}
          style={{ paddingTop: "2px", marginTop: "1rem" }}
        >
          View all
        </Button>

        <hr />

        <NewsFeed scopeVerticals="testing" chooseVerticals={false} />
      </div>
    </Page>
  );
};

export default TestingHome;
