import React, {useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import SearchIcon from '@material-ui/icons/Search';
import {buttonStyle} from '../DatasetToggle';
import {useHistory, useParams} from "react-router-dom";
import {BASE_STATE_TIMELINE_ROUTE, DEFAULT_DATASET, DEFAULT_EVENT_ID} from "../../../constants";
import {DEFAULT_STATE_NAME, COLORS} from "../../../../Shared/constants";
import {useDispatch, useSelector} from "react-redux";
import {toggleZoom} from "../../../redux/modules/global";

const newButtonStyle = {
    ...buttonStyle,
    padding: '5px 10px',
    '&:first-child': {
        borderRadius: '5px 0 0 5px',
        borderRight: 'none'
    },
    '&:nth-child(2)': {
        borderLeft: `1px solid ${COLORS.grayLight}`
    },
    '& > span': {
        display: 'flex',
        alignItems: 'center',
        lineHeight: '20px',
        '& > :not(:last-child)': {
            marginRight: '10px'
        }
    }
};

const useStyles = makeStyles(() => ({
    buttonWrapper: {
        display: 'flex'
    },
    button: {
        ...newButtonStyle
    },
    buttonSelected: {
        ...newButtonStyle,
        border: `1px solid ${COLORS.blueDark}`,
        color: COLORS.blueDark,
        '&:nth-child(2)': {
            border: `1px solid ${COLORS.blueDark}`,
        },
    }
}));

const BUTTONS = ['Previous', 'Home', 'Next'];

const TimelineControls = ({maxEventId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const updateZoomEffect = useCallback(() => dispatch(toggleZoom()), [dispatch]);
    let {stateName = DEFAULT_STATE_NAME, dataset = DEFAULT_DATASET, eventId = DEFAULT_EVENT_ID} = useParams();
    const history = useHistory();
    const zoomEffect = useSelector(state => state.stateTimeline.zoomEffect)

    const getButtonContent = id => {
        // Get the content for each button
        switch (id) {
            case 'Previous':
                return (<span>
          <ArrowBackIcon fontSize="small"/>
          <span>Previous</span>
        </span>)
            case 'Home':
                return (<span>
          <SearchIcon fontSize="small"/>
        </span>)
            case 'Next':
            default:
                return (<span>
          <span>Next</span>
          <ArrowForwardIcon fontSize="small"/>
        </span>)
        }
    };

    const handleButtonAction = id => evt => {
        // Handle the on click functionality for each button
        const currentEventId = parseInt(eventId, 10);
        let pushEventId;
        switch (id) {
            case 'Previous':
                pushEventId = (currentEventId === 0) ? 0 : currentEventId - 1;
                return history.push(`${BASE_STATE_TIMELINE_ROUTE}/${dataset}/${stateName}/${pushEventId}`, {noScroll: true});
            case 'Next':
                pushEventId = (currentEventId < maxEventId) ? currentEventId + 1 : 0;
                return history.push(`${BASE_STATE_TIMELINE_ROUTE}/${dataset}/${stateName}/${pushEventId}`, {noScroll: true});
            default:
                return updateZoomEffect(); // handle zoom effect instead of simply going home
        }
    }

    return (
        <div className={classes.buttonWrapper}>
            {BUTTONS.map((id, i) => {
                return (
                    <button
                        type="button"
                        className={i === 1 && zoomEffect ? classes.buttonSelected : classes.button}
                        key={`button-for-${id}`}
                        onClick={handleButtonAction(id)}
                    >
                        {getButtonContent(id)}
                    </button>
                )
            })}
        </div>
    );
}
TimelineControls.defaultProps = {
    maxEventId: 0,
};
export default TimelineControls;
