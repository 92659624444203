import React, { useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import { Accordion, AccordionItem } from "components/common/Accordion";
import { DataTime, DataSources } from "components/common/DataMeta";
import TestingPositivityTable from "./TestingPositivityTable";
import { getPage } from "data/util";
import styles from "./TestingPositivity.module.scss";
import SideNarrative from "components/layout/SideNarrative";

import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import AllStatesBar from "./AllStatesBar"
import { format } from "d3-format";

const TestingPositivity = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-positivity",
    });
  }, []);

  const [barData, isBarDataLoading] = useFetch("https://jhucoronavirus.azureedge.net/api/v2/testing/state/positivity_today.json");
  const ref = useRef(null);
  const [width] = useDimensions(ref);

  const dataAbove = barData?.stats.above_5pct;
  const dataBelow = barData?.stats.below_5pct;
  const dataUndetermined = barData?.stats.undetermined;

  return (
    <SideNarrative
      classNames={{
        sidebar: styles.buffer,
      }}
      narrative={
        <>
          <DataTime dataSet="testing-positivity" />
          <ReactMarkdown
            source={content_blocks["testing-positivity-page-intro"]?.content}
          />
          <Accordion className={styles.accordionContainer} autoOpen={false}>
            <AccordionItem
              topContent={
                <div className={styles.accordionTriggerContent}>
                  How we calculate positivity
                </div>
              }
            >
              <ReactMarkdown
                source={content_blocks["postitivity-sidebar-explainer"].content}
                linkTarget="_blank"
              />
            </AccordionItem>
          </Accordion>
        </>
      }
    >
      <div className={styles.container} ref={ref}>

        {!isBarDataLoading && (
          <>
          <AllStatesBar
            data={barData.data.filter(d => d.positivity_7day !== null && d.positivity_7day >= 0)}
            svgWidth={width}
            annotation={`${barData.stats.above_5pct} States have`}
          />

          <div className={styles["tables-wrapper"]}>
            {dataBelow > 0 && (
              <TestingPositivityTable
                data={barData.data.filter(d => d.positivity_7day !== null && d.positivity_7day <= 0.05).map(d => {
                  return ({regionName: d.region, percPositive: format(".3p")(d.positivity_7day)})
                })}
                header={
                  <h5 className={`${styles["table-header"]} ${styles.negative}`}>
                    States that meet positivity{" "}
                    <nobr>
                      recommendations: <span>{dataBelow}</span>
                    </nobr>
                  </h5>
                }
              />
            )}
            {dataAbove > 0 && (
              <TestingPositivityTable
                data={barData.data.filter(d => d.positivity_7day !== null && d.positivity_7day > 0.05).map(d => {
                  return ({regionName: d.region, percPositive: format(".3p")(d.positivity_7day)})
                })}
                header={
                  <h5 className={`${styles["table-header"]} ${styles.positive}`}>
                    States above recommended{" "}
                    <nobr>
                      positivity: <span>{dataAbove}</span>
                    </nobr>
                  </h5>
                }
              />
            )}
            {dataUndetermined > 0 && (
              <TestingPositivityTable
                tableColumns={{
                  regionName: "State",
                }}
                data={barData.data.filter(d => d.positivity_7day == null).map(d => {
                  return ({regionName: d.region})
                })}
                header={
                  <h5 className={`${styles["table-header"]} ${styles.undetermined}`}>
                    States with undetermined{" "}
                    <nobr>
                      positivity: <span>{dataUndetermined}</span>
                    </nobr>
                  </h5>
                }
              />
            )}
          </div>
          </>
        )}

        <ReactMarkdown
          source={content_blocks["testing-positivity-page-visual"]?.content}
        />

        <DataSources dataSet="testing-positivity" />
        <ReactMarkdown
          source={content_blocks["testing-positivity-data-disclaimer"]?.content}
        />
        <ReactMarkdown
          source={content_blocks["testing-data-disclaimer"]?.content}
        />
      </div>
    </SideNarrative>
  );
};

export default TestingPositivity;
