import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RacialBreakdown from "./RacialBreakdown";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  fullWidth: {
    width: "100%"
  },
}));

const RacialBreakdownContainer = () => {
  const classes = useStyles();
  const data = useSelector(
    (state) => state.equityAccessToTesting.racialBreakdown
  );

  const ethnicitySectionLabels = new Set(['ethnicity_hispanic_latino', 'ethnicity_non_hispanic_latino', 'ethnicity_unknown']);
  return (
    <div className={classes.container}>
      <h2 className={classes.fullWidth}>Race</h2>
      {data.filter(d => !ethnicitySectionLabels.has(d.id) ).map((row, i) => (
        <RacialBreakdown
          key={`racial-breakdown-for-${row?.label}${i}`}
          row={row}
        />
      ))}
      <hr className={classes.fullWidth} />
      <h2 className={classes.fullWidth}>Ethnicity</h2>
      {data.filter(d => ethnicitySectionLabels.has(d.id) ).map((row, i) => (
        <RacialBreakdown
          key={`racial-breakdown-for-${row?.label}${i}`}
          row={row}
        />
      ))}
    </div>
  );
};
export default RacialBreakdownContainer;
