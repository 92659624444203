import React, { cloneElement } from 'react';
import { useDimensions } from '../../../../../utils';
import { scaleBand } from 'd3-scale';

const BaseChart = ({ margin, className, children }) => {
  const [visualizationRef, { width, height, isResized }] = useDimensions({
    width: 100,
    height: 50,
    isResized: false,
  });
  if (!margin) {
    margin = {
      left: 0,
      right: 5,
      top: 5,
      bottom: 5,
    };
  }
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;

  // Get data for the last n days for the bars and calculate the common xScale
  const data = children.props.data;
  const xScale = scaleBand()
    .paddingInner(0.25)
    .range([0, innerWidth])
    .domain(data.map(d => d.dt));

  return (
    <svg width={width} height={height} ref={visualizationRef} className={className}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {isResized &&
          cloneElement(children, {
            innerWidth,
            innerHeight,
            xScale,
          })}
      </g>
    </svg>
  );
};

export default BaseChart;
