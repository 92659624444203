import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLORS} from "../../../../../../../../Shared/constants";

const useStyles = makeStyles((theme) => ({
    bar: {
        width: '190px',
        height: '11px',
        borderRadius: '4px',
        background: props => `linear-gradient(90deg, ${props.minColor} 0%, ${props.minColor} ${props.percent}%, ${props.maxColor} ${props.percent}%, ${props.maxColor} 100%)`
    }
}));

const Bar = ({percent}) => {
    const classes = useStyles({
        minColor: COLORS.blueDark,
        maxColor: '#e8e8e8',
        percent: percent
    });

    return (
        <div className={classes.bar}/>
    );
};
export default Bar;
