import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";

import Button from "components/common/Button";
import DropDownMenu from "./DropDownMenu";

import styles from "./NavRegionSearch.module.scss";

const NavRegionSearch = () => {
  const history = useHistory();
  const inputRef = useRef();

  const [query, setQuery] = useState("");
  const [isOpen, setOpen] = useState(false);

  const handleChange = (event) => {
    setQuery(event.target.value);
  };

  const handleFocus = () => {
    setOpen(true);
  };
  const handleBlur = () => {
    setOpen(false);
  };

  const [to, setTo] = useState(null);
  const handleSelect = (to) => {
    setTo(to);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (to) {
      history.push(to);
      inputRef.current.blur();
    } else if (query) {
      history.push("/region", { query });
      inputRef.current.blur();
    }
  };

  return (
    <div className={styles.base}>
      <div className={styles.searchInput}>
        <form onSubmit={handleSubmit}>
          <input
            aria-label="Search Data by Region"
            role="search"
            ref={inputRef}
            type="text"
            className={styles.input}
            value={query}
            placeholder="Search Data by Region..."
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <Button type="submit" className={styles.button} icon="search" />
        </form>
      </div>
      <DropDownMenu isOpen={isOpen} query={query} onSelect={handleSelect} />
    </div>
  );
};

export default NavRegionSearch;
