import {createSelector} from 'reselect';

const demographicLayersSelector = state => state.equityAccessToTesting.demographicLayers;

const selectedDemographicLayerSelector = createSelector([demographicLayersSelector],
    demographicLayers => {
        return demographicLayers.find(d => d.isActive);
    });

export default selectedDemographicLayerSelector;
