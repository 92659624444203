import React, { useState } from "react";
import styles from "./SequencingByCountryBar.module.scss";
import Annotation from "./Annotation";

const Bar = ({x, y, height, width, highlight, data, mobile}) => {
  const [hover, setHover] = useState(false);

  return (
    <>
    <Annotation data={data} x={width+10} y={y+(height/2)} hovered={hover} first={false} mobile={mobile}/>
    <rect x={x} y={y} height={height} width={width} className={highlight ? styles.selected : hover ? styles.hovered : styles.default}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    />
    </>
  );
}

export default Bar
