import React from "react";
import Page from "components/layout/Page";
import styles from "./VaccineReports.module.scss";
import { getPage } from "data/util";
import ArticleFeed from "../ArticleFeed";
import { getNormalizedPathname } from "util/helper";

const VaccineReports = ({ location }) => {
  const path = getNormalizedPathname(location.pathname);
  const page = getPage(path);
  return (
    <Page page={page} wide>
      <div className={styles.container}>
        <ArticleFeed
          section={"Vaccine Reports"}
          topCardProps={{
            vertical: true,
            containImage: false,
            major: false,
            imageSrc: null,
          }}
        />
      </div>
    </Page>
  );
};

export default VaccineReports;
