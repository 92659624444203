import React from 'react';
import BGFormat from 'components/common/BGFormat';
import styles from './BGFormat.module.scss';

export default function BGFormatStyleGuide() {
  return (
    <div className={styles.container}>
      <h1 className={styles['styleguide-annotation']}>
        Background Cover/Contain Emulation
      </h1>

      <BGFormat imgSrc="/images/COVID-19-1600x800.jpg" fitType="cover">
        <h2>Testing</h2>
        <h2>Testing</h2>
        <h2>Testing</h2>
        <h2>Testing</h2>
        <h2>Testing</h2>
      </BGFormat>

      <br />
      <br />
    </div>
  );
};
