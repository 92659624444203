import React from "react";
import styles from "./SectionContent.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type TSectionContentProps = {
  title?: string;
  content?: string;
  className?: string;
};

const SectionContent = ({
  title,
  content,
  className,
}: TSectionContentProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <h3 className={styles.header}>{title}</h3>
      {content && <p>{content}</p>}
    </div>
  );
};

export default SectionContent;
