import React, { useRef, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import { select } from "d3-selection";
import * as geo from "d3-geo";
import "d3-svg-annotation";

import usStates from "data/geo/us-states.json";

const d3 = {
  select,
  ...geo,
};

let __stateDataLoader;
let __stateData;

function MercatorMap({ width, colorData, height, className }) {
  const refContainer = useRef(null);
  const [redirect, setRedirect] = useState("");
  const [stateData, setStateData] = useState(__stateData);

  useEffect(() => {
    if (__stateData) {
      if (__stateData !== stateData) {
        setStateData(__stateData);
      }
      return;
    }

    let mounted = true;

    if (__stateDataLoader) {
      __stateDataLoader.then((stateData) => {
        if (mounted) {
          setStateData(stateData);
        }
      });
    }

    __stateDataLoader = fetch("/datasets/geo/us-states-geo.json").then((res) =>
      res.json()
    );

    __stateDataLoader.then((stateData) => {
      __stateData = stateData;
      if (mounted) {
        setStateData(stateData);
      }
    });

    return () => {
      mounted = false;
    };
  }, [stateData]);

  useEffect(() => {
    if (!colorData || !stateData) return;
    let svg;

    function swap(json) {
      const ret = {};
      for (const key in json) {
        ret[json[key]] = key;
      }
      return ret;
    }
    const statesLookup = swap(usStates);

    const projection = d3
      .geoAlbersUsa()
      .translate([width / 2, height / 2])
      .scale([500]);

    const path = d3.geoPath().projection(projection);

    svg = d3
      .select(refContainer.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`);

    svg
      .selectAll("path")
      .data(stateData.features)
      .enter()
      .append("path")
      .attr("d", path)
      .style("stroke", "#fff")
      .style("stroke-width", "1")
      .style(
        "fill",
        (d) =>
          colorData?.[statesLookup[d?.properties?.name]]?.color || "#FF0000"
      )
      .style("cursor", (d) =>
        colorData?.[statesLookup[d?.properties?.name]]?.url
          ? "pointer"
          : "default"
      )
      .on("click", function (d) {
        const href = colorData?.[statesLookup[d?.properties?.name]]?.url;
        if (href) {
          if (href.indexOf("http") === 0) {
            window.open(href, "_blank");
          } else {
            setRedirect(href);
          }
        }
      })
      .on("mouseover", function (d) {
        if (colorData?.[statesLookup[d?.properties?.name]]?.url)
          d3.select(this).transition().duration(70).style("opacity", 0.7);
      })
      .on("mouseout", function (d) {
        if (colorData?.[statesLookup[d?.properties?.name]]?.url)
          d3.select(this).transition().duration(200).style("opacity", 1);
      });

    return () => {
      svg && svg.remove();
    };
  }, [width, height, colorData, stateData]);

  return (
    <>
      {redirect && <Redirect to={redirect} push />}
      <div ref={refContainer} className={className}></div>
    </>
  );
}

MercatorMap.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  colorData: PropTypes.object,
  className: PropTypes.string,
};

export default MercatorMap;
