import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import styles from "./Blog.module.scss";
import { checkShouldContentDisplay, getArticle, getAuthor } from "data/util";
import {
  AuthorBlocksContainer,
  AuthorTabContainer,
} from "components/common/Author/util";
import { formatMonthDate } from "util/helper";
import { getArticleImageEmbed } from "./data";
import RenderVideoFrame from "components/content/DataCenter/charts/VariantData/RenderVideoFrame";
import { Redirect } from "react-router-dom";
import ArticleEmbed from "../Article/ArticleEmbed";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type TArticleProps = {
  content: any;
  slug: string;
  date?: string | Date;
  verticals?: Array<string>;
  authors?: Array<any>;
};

type TBlogProps = {
  location: any;
  article: TArticleProps;
  match?: any;
};

const Blog = ({ location, article }: TBlogProps) => {
  article = article || getArticle(location.pathname);

  const { date, slug, verticals = [], authors = [] } = article;
  const legacy = !date && !authors.length;
  const mappedAuthors = authors?.map((authorSlug) => getAuthor(authorSlug));

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: !!verticals.length ? verticals : ["vaccines"],
    });
  }, [verticals]);

  if (!article?.slug || !checkShouldContentDisplay(article))
    return <Redirect to="/404" />;

  const Image = ({ alt, src }) => {
    if (alt === "__embed") return <ArticleEmbed embedId={src} />;

    const embedImageSrc = getArticleImageEmbed(slug, alt);
    if (!embedImageSrc)
      return <img key={alt} alt={alt} src={src} className={styles.image} />;
    return (
      <img key={alt} alt={alt} src={embedImageSrc} className={styles.image} />
    );
  };

  const BlockQuote = ({ children }) => {
    if (!children?.length) return children;
    return children.map((child, i) => {
      const contents = child?.props?.children.map((e, i) => {
        const { href, children } = e?.props;
        if (href) return { href };
        if (children) return { children };
        return;
      });
      return contents?.map((content, i) => {
        return content?.href ? (
          <RenderVideoFrame
            key={`embed-${i}${content?.href}`}
            videoUrl={content?.href}
            videoLinkType={1} //Youtube video is assumed
            iframeProps={{
              allowFullScreen: true,
              frameBorder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            }}
          />
        ) : (
          content?.children && (
            <div
              key={`${slug}-blockQuote-${i}-${content?.children}${content?.children}`}
              className={styles.blockQuote}
            >
              {content?.children}
            </div>
          )
        );
      });
    });
  };
  const displayDate = formatMonthDate(new Date(date));
  return (
    <>
      <Page page={article}>
        <AuthorTabContainer authors={mappedAuthors} />
        <div className={cx(styles.container, legacy && styles.legacy)}>
          {date && displayDate && (
            <span className={styles.date}>{displayDate}</span>
          )}
          <ReactMarkdown
            source={article.content}
            linkTarget="_blank"
            renderers={{ image: Image, blockquote: BlockQuote }}
            escapeHtml={false}
          />
        </div>
      </Page>
      <AuthorBlocksContainer authors={mappedAuthors} />
      <br />
    </>
  );
};

export default Blog;
