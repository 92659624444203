import React, { useEffect, useState } from "react";
import * as styles from "./PageNavigator.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { getPageSections } from "data/page-sections";
import { useLocation } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import { getScrollTargetValue, SCROLL_TARGET_CLASSNAME } from "./ScrollTarget";
import Portal from "./Portal";
import {
  findTargetPos,
  getHeaderPageNavigator,
  sectionsToOptions,
} from "./util";
import CustomSelect from "../CustomSelect";
const cx = classNames.bind(styles);

export const HEADER_PAGE_NAVIGATOR_ID = "header-page-navigator";

const PageNavigator = ({ inner = true, show = false, className }) => {
  const location = useLocation();
  const { pathname } = location;
  const [, setPath] = useState(pathname);
  const [isInnerVisible, setInnerVisible] = useState(true);
  const [showSticky, setShowSticky] = useState(false);
  const sections = getPageSections(pathname) || [];

  const [headerNavigatorEl, setHeaderNavigator] = useState(
    getHeaderPageNavigator()
  );
  const [scrollTargets, setScrollTargets] = useState(
    document.getElementsByClassName(SCROLL_TARGET_CLASSNAME)
  );
  const [sectionOptions, setSectionOptions] = useState(
    sectionsToOptions(sections)
  );
  const [currentSection, setCurrentSection] = useState(null);

  const toogleHeaderNav = (enable) => {
    if (inner) {
      setInnerVisible(!enable);
      if (headerNavigatorEl?.style) {
        headerNavigatorEl.style.display = enable ? "block" : "none";
        setShowSticky(enable);
      }
    }
  };

  const handleSelect = (selected) => {
    if (selected?.value && !!window.scrollTo) {
      location.hash = "#" + selected.value;
      const scrollTargetEl = document.getElementById(
        `scroll-target#${selected.value}`
      );
      const pos = scrollTargetEl ? findTargetPos(scrollTargetEl) - 160 : 0;
      window.scrollTo({
        top: pos,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  const handleOnScrollSpyEnter = (scrollTargetId) => {
    if (!inner && !!scrollTargetId) {
      const section = sectionOptions.find(
        (section) => section.value === getScrollTargetValue(scrollTargetId)
      );
      setCurrentSection(section);
    }
  };

  useEffect(() => {
    setScrollTargets(document.getElementsByClassName(SCROLL_TARGET_CLASSNAME));
    if (!sections.length !== !sectionOptions.length) {
      setSectionOptions(sectionsToOptions(sections));
    }
  }, [scrollTargets, sectionOptions, sections]);

  useEffect(() => {
    setPath(pathname);
    if (!headerNavigatorEl) {
      setHeaderNavigator(getHeaderPageNavigator());
    }
  }, [sections, pathname, location, headerNavigatorEl]);

  useEffect(() => {
    let mounted = true;

    const setDisplayed = (enable) => {
      if (mounted && !inner && headerNavigatorEl?.style) {
        headerNavigatorEl.style.display = enable ? "block" : "none";
        setShowSticky(enable);
      }
    };
    setDisplayed(show && !isInnerVisible);
    return () => {
      mounted = false;
    };
  }, [inner, show, isInnerVisible, headerNavigatorEl, setShowSticky]);

  // useScrollLocationHash({
  //   targetHashOverride: `scroll-target${location.hash}`,
  //   isEnabled: inner,
  // });

  useEffect(() => {
    let mounted = true;

    if (mounted && inner && location.hash) {
      const scrollTargetEl = document.getElementById(
        `scroll-target${location.hash}`
      );
      const pos = scrollTargetEl ? findTargetPos(scrollTargetEl) - 160 : 0;
      window.scrollTo({
        top: pos,
        left: 0,
        behavior: "smooth",
      });
    }
    return () => {
      mounted = false;
    };
  }, [inner, location]);

  if (!sections.length) {
    return null;
  }

  const Wrapper = inner ? Waypoint : "div";
  const headerProps = inner ? {} : { id: HEADER_PAGE_NAVIGATOR_ID };
  const wrapperProps = inner
    ? {
        onEnter: () => toogleHeaderNav(false),
        onLeave: () => toogleHeaderNav(true),
        topOffset: "20%",
      }
    : {};

  return (
    <>
      <Wrapper {...wrapperProps}>
        <div
          {...headerProps}
          className={cx(
            styles.base,
            !inner ? styles.sticky : "",
            showSticky && !show ? styles.active : "",
            className
          )}
        >
          <CustomSelect
            baseClassName={styles.selectBase}
            label="JUMP TO:"
            allOptionLabel="Select a Section"
            noAllOption={true}
            multi={false}
            options={sectionOptions}
            onSelect={handleSelect}
            currentSelected={currentSection}
            isSearchable={false}
          />
          {!inner && (
            <div id={"page-nav-portal-root"}>
              {!!scrollTargets &&
                [...scrollTargets].map((el, i) => (
                  <Portal key={el.id} id={el.id}>
                    <div id={`button-${el.id}_${i}`}>
                      <Waypoint
                        onEnter={() => handleOnScrollSpyEnter(el.id)}
                        bottomOffset={"50%"}
                      />
                    </div>
                  </Portal>
                ))}
            </div>
          )}
        </div>
      </Wrapper>
    </>
  );
};

PageNavigator.propTypes = {
  className: PropTypes.string,
};

export default PageNavigator;
