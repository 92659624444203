import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import * as modalStyles from './InfoModal.module.scss';
import LegendSVG from 'images/state_timelines_legend_v3.svg';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const InfoModal = () => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="how__to__read__graphic">
      <Button
        onClick={handleClickOpen}
        variant="contained"
        disableElevation
        className={modalStyles.button}
      >
        How to read this graphic
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="how to read this graphic"
        open={open}
        fullWidth={true}
        maxWidth={'md'}
        scroll="body"
        className={modalStyles.modal}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          How to Read this Graphic
        </DialogTitle>
        <DialogContent dividers>
          <div className={modalStyles.main}>
            <img src={LegendSVG} alt="How to use this graphic"/>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default InfoModal;
