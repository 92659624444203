import deburr from "lodash.deburr";
import stateCoords from "./us-states-coords.json";
import regionBanners from "./region-banners.json";
import regionContent from "./regions-content.json";
import countryDataSrc from "./country_data.json";
import { shouldShowDrafts } from "components/common/DraftGuard/index.js";
import {
  countryAbbreviations,
  countryDisplays,
  regionData,
  regionGeneralData,
  stateAbbreviations,
  TRegionDataNoteFormatted,
  TRegionNoteFilterProps,
  usTerritoryAbbreviations,
} from "./helpers";

export const countryMappings = Object.keys(countryDisplays)
  .map((k) => ({
    ...countryDisplays[k],
    regionCode: k,
  }))
  .sort((a, b) => {
    const nameA = a?.displayName;
    const nameB = b?.displayName;
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

export const countryData = countryDataSrc;
export const countries = Object.values(countryAbbreviations).sort();
export const states = Object.values(stateAbbreviations).sort();
export const statesAndTerritories = Object.values(stateAbbreviations)
  .concat(Object.values(usTerritoryAbbreviations))
  .sort();

// Used to make strings consistent for matching
//   - Removes diacritics
//   - makes all letters lowercase
//   - Replaces all characters besides alphanumerics, single quotes, and parentheses with "-"
export const slugify = (str) =>
  deburr(str)
    .toLowerCase()
    .replace(/[^a-z0-9-'()]/g, "-");

// Do two state/country names match?
// Normalizes strings for comparison by the rules above
export const matchName = (a, b) => slugify(a) === slugify(b);
export const partialMatch = (part, whole) =>
  slugify(whole).includes(slugify(part));

// Find key in an object to match a given search
// Ignores diacritics, capitalization, and non-alphanumeric characters
// Supports querying by the full name or by the abbreviation
const findKey = (map, search) => {
  return (
    Object.entries(map).find(
      ([abbr, name]) => matchName(search, name) || matchName(search, abbr)
    )?.[0] || null
  );
};

export const findCountryCode = (s) => findKey(countryAbbreviations, s);
export const expandCountryCode = (code, key = "displayName") =>
  countryDisplays[code.toUpperCase()][key];

export const findStateCode = (s) =>
  findKey({ ...stateAbbreviations, ...usTerritoryAbbreviations }, s);
export const expandStateCode = (s) =>
  ({ ...stateAbbreviations, ...usTerritoryAbbreviations }[s.toUpperCase()]);

export const findStateByCode = (code) => stateAbbreviations[code];

// Querying for geography
export const getStateCoords = (s) =>
  stateCoords[findKey(stateAbbreviations, s)];

export const getRegionPagePath = (
  regionType: "states" | "countries",
  regionCode: string
) => {
  if (!regionCode || !regionType) return "";
  if (regionType === "states")
    return `/region/us/${slugify(stateAbbreviations[regionCode])}`;
  if (regionType === "countries")
    return `/region/${slugify(countryDisplays[regionCode].name)}`;
};

export const getRegionNotesPagePath = (
  regionType: "states" | "countries",
  regionCode: string
) => {
  const basePath = "/region-data-notes";
  const regionName =
    regionType === "countries"
      ? countryAbbreviations?.[regionCode]
      : stateAbbreviations?.[regionCode];

  if (!regionName) return basePath;
  return `${basePath}/${slugify(regionName)}`;
};

// Formatting for region data notes array
export const formatRegionDataNotes = (
  regionType: "states" | "countries"
): TRegionDataNoteFormatted[] => {
  const regions = regionData[regionType];
  const codes = Object.keys(regions);
  let result = [];
  for (let index = 0; index < codes.length; index++) {
    const regionCode = codes[index];
    const regionData = regions[regionCode]
      // Filter out invalid / drafted notes
      .filter(
        (note) =>
          !!note?.date &&
          !!note?.notes.length &&
          (!note?.draft || shouldShowDrafts)
      )
      .map((data) =>
        Object.assign(
          {
            name:
              regionType === "countries"
                ? countryDisplays[regionCode].displayName
                : stateAbbreviations[regionCode],

            code: regionCode,
            path: getRegionPagePath(regionType, regionCode),
            slug: slugify(
              regionType === "countries"
                ? countryAbbreviations[regionCode]
                : stateAbbreviations[regionCode]
            ),
            USA: regionType === "states",
          },
          data
        )
      );
    result = result.concat(regionData);
  }
  return result;
};

export const countryDataNotes = () => formatRegionDataNotes("countries");
export const stateDataNotes = () => formatRegionDataNotes("states");

export const getSortedRegionDataNotes = (dataNotes) => {
  dataNotes = dataNotes || stateDataNotes().concat(countryDataNotes());
  return dataNotes.sort(
    (a, b) =>
      (new Date(b.date || null) as any) - (new Date(a.date || null) as any)
  );
};

export const getFilteredRegionDataNotes = ({
  regionType = "all",
  filterOptions = { types: [], keyword: "name" },
}: TRegionNoteFilterProps): Array<any> => {
  let { types = [], keyword = "name" } = filterOptions;

  let contents;
  if (regionType === "all") {
    contents = stateDataNotes().concat(countryDataNotes());
  } else {
    contents = regionType === "states" ? stateDataNotes() : countryDataNotes();
  }

  types = Array.isArray(types) ? types : !!types ? [types] : [];

  // Filter by types:
  if (!!types.length) {
    contents = contents.filter((c) => types.includes(c[keyword]));
    // contents = contents.filter(
    //   (c) =>
    //     !!(types as string[]).filter((type) =>
    //       typeof c === "string"
    //         ? c === type
    //         : c[keyword] === type && c.notes?.length > 0
    //     )
    // );
  }
  return getSortedRegionDataNotes(contents);
};

export const getRegionNarrativeNote = ({ regionCode, regionType }) => {
  return regionContent?.find(
    (content) => content?.code === regionCode && content?.type === regionType
  );
};

export const getRegionDataNotes = (
  regionCode,
  isState,
  withGeneralNotes = false
) => {
  const data =
    regionData?.[isState ? "states" : "countries"]?.[regionCode] || [];
  return (withGeneralNotes ? regionGeneralData.concat(data) : data)
    ?.filter(
      (data) => (!data.draft || shouldShowDrafts) && data.notes?.length > 0
    )
    .sort(
      (a, b) =>
        (new Date(b.date || null) as any) - (new Date(a.date || null) as any)
    );
};

export const getRegionGeneralNotes = (limit = Infinity) => {
  return regionGeneralData
    ?.filter(
      (data) => (!data.draft || shouldShowDrafts) && data.notes?.length > 0
    )
    .sort(
      (a, b) =>
        (new Date(b.date || null) as any) - (new Date(a.date || null) as any)
    )
    .slice(0, limit);
};

export const getRegionBanner = (regionCode, isState) => {
  const data = regionBanners?.[isState ? "states" : "countries"]?.[regionCode];
  return data?.[0];
};
