import React, { Component } from "react";
import { connect } from "react-redux";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import { toggleMapInfo } from "redux/map";
import styles from "./MapView.module.scss";
import { ReactComponent as CrossIcon } from "images/icons/cross.svg";
import isMobile from "util/isMobile";

import Meta from "components/common/Meta";
import { getPage } from "data/util";

class Map extends Component {
  dismissWarning = () => {
    this.setState({
      undisplayMapWarning: true,
    });
  };

  state = {
    undisplayMapWarning: false,
  };

  componentDidMount() {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "world-map",
    });
  }

  render() {
    const pageData = getPage(this.props.location.pathname);
    const { meta, content_blocks } = pageData;

    return (
      <div
        className={cx(styles.base, {
          [styles.displayInfoBox]: this.props.displayMapInfo,
          [styles.displayWarningBox]: this.props.displayMapWarning,
        })}
      >
        <Meta {...meta} />
        <div className={styles.container}>
          <div className={styles.embedContainer}>
            <iframe
              className={styles.iframe}
              title="Coronavirus COVID-19 Global Cases by Johns Hopkins CSSE"
              width="500"
              height="400"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={
                isMobile()
                  ? "https://www.arcgis.com/apps/opsdashboard/index.html#/85320e2ea5424dfaaa75ae62e5c06e61"
                  : "https://www.arcgis.com/apps/opsdashboard/index.html#/bda7594740fd40299423467b48e9ecf6"
              }
            ></iframe>
          </div>
        </div>

        {/* Warning Box */}
        <AnimatePresence>
          {this.props.displayMapWarning && !this.state.undisplayMapWarning && (
            <motion.div
              className={cx(styles.mapModal, styles.mapWarning)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className={styles.mapInfoBox}
                initial={{ x: "-50%", y: "-40%" }}
                animate={{ x: "-50%", y: "-50%" }}
                exit={{ x: "-50%", y: "-40%" }}
              >
                <CrossIcon
                  className={styles.closeInfoBox}
                  onClick={this.dismissWarning}
                />
                <p>
                  <strong>
                    Site maintenance today on the Johns Hopkins Coronavirus
                    Resource Center's Global Map and underlying datasets may
                    temporarily disable access to both features. We apologize
                    for any inconvenience.
                  </strong>
                </p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Info Box */}
        <AnimatePresence>
          {this.props.displayMapInfo && (
            <motion.div
              className={cx(styles.mapModal, styles.mapWarning)}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <motion.div
                className={styles.mapInfoBox}
                initial={{ x: "-50%", y: "-40%" }}
                animate={{ x: "-50%", y: "-50%" }}
                exit={{ x: "-50%", y: "-40%" }}
              >
                <CrossIcon
                  className={styles.closeInfoBox}
                  onClick={this.props.toggleMapInfo}
                />
                <ReactMarkdown
                  source={content_blocks["covid-19-map-information"].content}
                  renderers={{ link: Link }}
                />
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    displayMapInfo: state.map.displayMapInfo,
    displayMapWarning: state.settings.data.enableWorldMapWarning,
  }),
  { toggleMapInfo }
)(Map);

const Link = ({ href, children }) => {
  return (
    <a
      className={styles.mapButton}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
};
