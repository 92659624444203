import React, { useEffect, useState, useRef } from "react";
import * as GTM from "util/gtm";
import styles from "./StateReporting.module.scss";
import SideNarrative from "components/layout/SideNarrative";
import { getPage } from "data/util";
import { useFetch } from "util/hooks/useFetch";
import useWindowSize from "util/hooks/useWindowSize";
import TabBar from "components/layout/shell/TabBar";
import CustomSelect from "components/common/CustomSelect";
import Map from "./Map";
import Bar from "./Bar";
import Tooltip, { TooltipContext } from "./Tooltip";
import ReactMarkdown from "react-markdown";
import SummaryTable from "./SummaryTable";

const StateReporting = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;
  const tabs = ["Cases", "Deaths", "Tests", "Vaccines", "Summary"];
  const tabValues = ["cases", "deaths", "tests", "vaccines", "summary"];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const currentTabValue = tabValues[tabs.indexOf(selectedTab)];
  const ref = useRef(null);
  const [mapData, isMapLoading] = useFetch("geo_us_states");
  const [data, isDataLoading] = useFetch(
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/usreportingfrequency.json"
  );

  const windowSize = useWindowSize();
  const [tooltip, setTooltip] = useState({
    show: false,
    x: 0,
    y: 0,
    content: "",
    ttHeight: 0,
  });
  const value = { state: tooltip, dispatch: setTooltip };

  const handleTabChange = (tab) => {
    if (typeof tab === "object") {
      setSelectedTab(tab.label);
      return;
    }
    setSelectedTab(tab);
  };

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
  }, []);

  return (
    <SideNarrative pageProps={page}>
      <div className={styles.container} ref={ref}>
        <ReactMarkdown
          source={
            content_blocks?.["state-reporting-frequencies-title"]?.content
          }
        />
        <div className={styles.tabSection}>
          {/* Tab bar / select dropdown */}
          {windowSize.width >= 970 ? (
            <TabBar
              tabs={tabs}
              onChange={handleTabChange}
              forcedTab={selectedTab}
            />
          ) : (
            <CustomSelect
              noAllOption
              allOptionLabel={selectedTab}
              multi={false}
              options={tabs.map((tab, i) => {
                return { label: tab, value: tabValues[i] };
              })}
              onSelect={handleTabChange}
              isSearchable={false}
            />
          )}
        </div>
        {!isDataLoading && data && (
          <div className={styles.content}>
            {!isMapLoading && selectedTab !== "Summary" && (
              <TooltipContext.Provider value={value}>
                <Map
                  mapData={mapData}
                  data={data}
                  dataset={{ value: currentTabValue, label: selectedTab }}
                  ttWidth={150}
                >
                  <Tooltip
                    width={160}
                    selectedTab={selectedTab}
                    stoppedDate={null}
                  />
                </Map>
              </TooltipContext.Provider>
            )}
            {selectedTab === "Summary" ? (
              <SummaryTable data={data} />
            ) : (
              <Bar
                selectedTab={selectedTab}
                data={data}
                dataset={{ value: currentTabValue, label: selectedTab }}
              />
            )}
          </div>
        )}
        <br />
        <ReactMarkdown
          source={
            content_blocks?.["state-reporting-frequencies-data-source"]?.content
          }
        />
      </div>
    </SideNarrative>
  );
};

export default StateReporting;
