import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TooltipIcon from "../../../../../../../common/TooltipBadge/TooltipIcon";
import HtmlTooltip from "../../Table/HtmlTooltip";
import { useSelector } from "react-redux";
import { IconLookup } from "../../../../utils";

function TableCellInner({
  text,
  className,
  sortId,
  order,
  orderBy,
  createSortHandler,
}) {
  return (
    <TableSortLabel
      active={orderBy === sortId}
      direction={orderBy === sortId ? order : "asc"}
      onClick={createSortHandler(sortId)}
      className="table__sort"
    >
      <span className={className}>{text}</span>
    </TableSortLabel>
  );
}

const StateHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    HEADER_CELLS,
    STOPPED_APPROACH,
  } = props;
  const metadata = useSelector((state) => state.testingTracker.metadata);
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className="table__head">
      <TableRow className="table__row">
        {HEADER_CELLS.map(({ heading, info, count, iconType }, i) => {
          let approach;
          if (!!i) {
            approach = metadata.find((d) => d.approachId === i);
          }
          return (
            <TableCell
              className={`table__cell ${
                i === STOPPED_APPROACH ? "not-supported" : ""
              } positivity`}
              key={i}
            >
              {i === STOPPED_APPROACH && (
                <div className="not-supported-text">
                  <h3>Approach 3 (No Longer Updated):</h3>
                  <p>
                    Positive People / Total People. People who tested positive
                    with molecular (PCR) tests divided by the total number of
                    people tested with molecular (PCR) tests. The CRC ceased
                    supporting this calculation on July 28, 2022.
                  </p>
                  <p>
                    47 States did not report the values needed to calculate this
                    positivity type through July 28, 2022
                  </p>
                </div>
              )}
              {!!i && (
                <span className="approach__number">{`Approach #${i}`}</span>
              )}
              <div className="table__heading">
                {!!i ? (
                  <HtmlTooltip approach={approach}>
                    <TooltipIcon
                      className="approach__icon"
                      type={IconLookup.get(i)}
                    />
                  </HtmlTooltip>
                ) : (
                  <TableCellInner
                    text={heading}
                    sortId={heading}
                    order={order}
                    orderBy={orderBy}
                    createSortHandler={createSortHandler}
                  />
                )}
              </div>
              {!!i && (
                <div className="state__count--container">
                  <div className="state__count">
                    <b>{count}</b>&nbsp;States
                  </div>
                  <TableSortLabel
                    active={orderBy === heading}
                    direction={orderBy === heading ? order : "asc"}
                    onClick={createSortHandler(heading)}
                    className="table__sort"
                  />
                </div>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

StateHeader.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
};

StateHeader.defaultProps = {};
export default StateHeader;
