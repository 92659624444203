import React from 'react';
import styles from './TestingHome.module.scss';
import { getSnapshotForRoute } from 'data/util';

export const trackerToolArticles = [
         {
           category: "Testing tracker",
           title:
             "See changes in new cases and testing capacity in all 50 states",
           body: (
             <p>
               Understanding COVID-19 outbreaks across U.S. states and regions
               based on three important metrics.
             </p>
           ),
           imageSrc: getSnapshotForRoute("/testing/tracker/overview"),
           containImage: true,
           href: "/testing/tracker/overview",
         },
         {
           category: "Testing tracker",
           title: "New tests per 100k people",
           body: <p>A state-by-state comparison.</p>,
           href: "/testing/tracker/map",
         },
         {
           category: "Testing tracker",
           title: "Have new tests declined from previous week?",
           body: <p>A state-level look at weekly changes in testing rates.</p>,
           href: "/testing/tracker/map/weekly-change-in-testing",
         },
         {
           category: "Testing tracker",
           title: "Percent of new tests that are positive",
           body: (
             <p>
               See which states meet or exceed test positivity rates recommended
               by the World Health Organization.
             </p>
           ),
           href: "/testing/tracker/map/percent-positive",
         },
       ];

export const insightsArticles = [
  {
    category: 'Testing insights',
    title: 'Which U.S. states meet WHO-recommended testing positivity levels?',
    body: (
      <p>
        The World Health Organization recommends that states strive for 3-12%
        test positivity, as an indicator that states are conducting enough
        tests. Which states are testing enough to meet this goal?
      </p>
    ),
    imageSrc: getSnapshotForRoute('/testing/testing-positivity'),
    containImage: true,
    href: '/testing/testing-positivity',
    className: styles.wideHeadingCard,
  },
  {
    category: 'Testing insights',
    title: 'International comparison of positivity rates and tests per capita',
    href: '/testing/international-comparison',
  },
  {
    category: 'Testing insights',
    title: 'Cases, Deaths, and Testing in All 50 States',
    href: '/testing/states-comparison',
  },
  {
    category: 'Testing insights',
    title: 'Daily State-by-State Testing Trends',
    href: '/testing/individual-states',
  },
];
