import React from "react";
import MapSVG from "./MapSVG";

const Map = ({ dataSet, bin, data, mapData, tileMap, mobile, svgWidth, demographic, children }) => {

  return (
    <div>
      <MapSVG
        svgWidth={svgWidth}
        dataSet={dataSet}
        bin={bin}
        data={data}
        mapData={mapData}
        demographic={demographic}
      >
        {children}
      </MapSVG>
    </div>
  );
};

export default Map;
