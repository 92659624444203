import React from "react";
import { getSnapshotForRoute } from "data/util";
import { getCards } from "data/data-viz";
import { mapChartsToCards } from "components/layout/ArticleCard/utils";

export const TrackingCards = () => {
  const slugs = ["racial-data-transparency", "disparity-explorer"];
  const cards = getCards({ section: "Trends" })?.filter((card) =>
    slugs.includes(card.slug)
  );
  const results = mapChartsToCards(cards).map((card) => ({
    ...card,
    imageSrc: card.imageSrc || getSnapshotForRoute(card.href, true),
    containImage: true,
  }));
  return results;
};

export const WeeklyHospitalizationTends = {
  category: "Weekly Hospitalization Trends",
  imageSrc: getSnapshotForRoute("/data/hospitalization-7-day-trend"),
  containImage: true,
  title: "What is the trend in COVID-19 related hospitalizations?",
  body: (
    <p>
      A look at the 7-day trend in overall and Covid-19-specific.
      hospitalization occupancy data
    </p>
  ),
  href: "/data/hospitalization-7-day-trend",
  verticals: ["tracking"],
  new: false,
};

export const NewCases50StatesCard = {
  category: "New Cases of COVID-19 in US States",
  imageSrc: getSnapshotForRoute("/data/new-cases-50-states"),
  containImage: true,
  title: "Where are cases rising?",
  body: <p>See if new cases are rising or declining as states reopen.</p>,
  href: "/data/new-cases-50-states",
  verticals: ["tracking", "other"],
};

export const NewCasesCountriesCard = {
  category: "New Cases",
  imageSrc: getSnapshotForRoute("/data/new-cases"),
  title: "Have countries flattened the curve?",
  body: (
    <p>
      Daily confirmed new COVID-19 cases for the most affected countries over
      time.
    </p>
  ),
  href: "/data/new-cases",
  containImage: true,
  new: false,
  verticals: ["tracking"],
};

export default TrackingCards();
