import stateData from "./data.json";
import USTerritories from "../../../../data/geo/us-territories.json";

const statePlanDataDecoder = {
  HCLC: {
    icon: "H",
    legend: "Healthcare/LTCF",
    style: "hclc",
  },
  RSTL: { icon: "E", legend: "Essential Workers", style: "rstl" },
  "65+": { icon: "65+", legend: "65+ and High Risk", style: "sixtyfive" },
  CONG: {
    icon: "C",
    legend: "Individuals living in congregate settings",
    style: "cong",
  },
  TCHR: { icon: "T", legend: "Teachers", style: "tchr" },
  OTHR: { icon: "O", legend: "Other Groups", style: "othr" },
  RPOP: { icon: "P", legend: "Remaining Populations", style: "rpop" },
  JOIN: { icon: "", legend: "Essential Workers", style: "join" },
  SELegend: { icon: "", legend: "Simultaneously Eligible", style: "simultaneously-eligible" },
};

export const legendList = [
  "HCLC",
  "65+",
  "TCHR",
  "RSTL",
  "CONG",
  "OTHR",
  "RPOP",
  "SELegend",
];

export const decoder = (key, data) => statePlanDataDecoder[key][data];

export const formatStatePlanData = () => {
  const data = stateData;
  //Filter out us territories state codes
  const usTerritories = Object.keys(USTerritories);
  const keys = Object.keys(data).filter((key) => !usTerritories.includes(key));

  const rows = {};
  const JOIN = "JOIN";

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    rows[key] = [];
    let blocks = data[key];
    let index = 0;

    while (index < blocks.length) {
      const block = blocks[index];
      const nextBlock = data[key][index + 1];
      if (nextBlock !== JOIN) {
        rows[key] = rows[key].concat(block);
        index += 1;
      } else {
        let joinGroup = [block, nextBlock];
        let j = index + 2;
        let done = false;
        while (!done && j < blocks.length) {
          const curr = blocks[j];
          const next = data[key][j + 1];
          if (next === JOIN) {
            joinGroup = joinGroup.concat([curr, next]);
            j += 2;
          } else {
            joinGroup = joinGroup.concat(curr);
            j += 1;
            done = true;
          }
        }
        rows[key] = rows[key].concat([joinGroup]);
        index = j;
      }
    }
  }
  return rows;
};
