// import { loadState } from './persistence';

export const request = (url, options = {}) => {
  // const state = loadState();
  const defaults = {
    headers: {
      // "content-type": "application/json",
      // "Token": state && state.session.token
    },
    // "credentials": "same-origin"
  };

  // edit options to include defaults declared above
  // and make the default options overrideable
  let combinedOptions = {
    ...defaults,
    ...options,
    headers: {
      ...defaults.headers,
      // ...options.headers
    },
    // check if dontStringify is set
    body: options.dontStringify ? options.body : JSON.stringify(options.body)
  };

  // Remove content-type if flag is set
  // this is so the content type can be inferred when sending
  // formdata to the API.
  if (options.removeContentType) {
    delete combinedOptions.headers['content-type'];
  }

  // const apiHost = process.env.REACT_APP_API_HOST;

  // return fetch(`https://${ apiHost }${ url }`, combinedOptions)
  return fetch(url, combinedOptions)
    .then(response => response.json())
    .then(json => {
      if (json.error) {
        throw json.error_details;
      } else {
        return json;
      }
    })
    .catch(error => {
      if (error.json) {
        return error.json().then(error_json => {
          throw error_json;
        });
      } else {
        throw error;
      }
    });
};
