import React from 'react';
import InfoIcon from '@material-ui/icons/Info';
import Popover from '@material-ui/core/Popover';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root: {
        pointerEvents: 'none'
    },
    content: {
        maxWidth: '300px',
        padding: '10px 15px 0',
        lineHeight: '1.4'
    },
}));
const InfoPopover = ({text}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <div className="popover popover--info">
            <InfoIcon className="popover__icon" fontSize="small" onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}/>
            <Popover
                className={classes.root}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className={classes.content}>
                    <p>{text}</p>
                </div>
            </Popover>
        </div>
    );
};
InfoPopover.defaultProps = {
    text: '',
};
export default InfoPopover;