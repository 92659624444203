import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    bar: {
        stroke: 'none',
        cursor: 'pointer',
        fill: 'transparent'
    }
}));

const HoverBar = ({item, x, y, height, width, fill, setActiveEvent}) => {
    const classes = useStyles({color: fill});

    const onMouseOver = () => setActiveEvent(item);
    const onMouseOut = () => setActiveEvent(null);

    return (
        <rect className={classes.bar} x={x} width={width} y={y} height={height}
              onMouseMove={onMouseOver}
              onTouchMove={onMouseOver}
              onMouseLeave={onMouseOut}
              onTouchEnd={onMouseOut}
        />
    );
};

export default HoverBar;
