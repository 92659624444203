import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

import styles from "./BedsChart.module.scss";

const Ball = ({ one, two }) => (
  <div className={cx(styles.ball, styles[two])}>
    {one !== two && <div className={cx(styles.ball, styles.half, styles[one])} />}
  </div>
);

const BedsChart = ({ data, className }) => {
  const inp = [];
  const icu = [];
  const steps = 20;

  const inpCovid = Math.round((data.inpatient.occ_this_week / 100) * steps);
  const inpNonCovid = Math.round((data.inpatient['non-covid'] / 100) * steps);

  const icuCovid = Math.round((data.icu.occ_this_week / 100) * steps);
  const icuNonCovid = Math.round((data.icu['non-covid'] / 100) * steps);

  for (let i = 0; i < steps; i += 2) {
    inp.push({
      one: inpNonCovid > i ? "covid" : inpCovid > i ? "non-covid" : "empty",
      two: inpNonCovid > i + 1 ? "covid" : inpCovid > i + 1 ? "non-covid" : "empty",
    });
    icu.push({
      one: icuNonCovid > i ? "covid" : icuCovid > i ? "non-covid" : "empty",
      two: icuNonCovid > i + 1 ? "covid" : icuCovid > i + 1 ? "non-covid" : "empty",
    });
  }

  return (
    <table className={cx(styles.base, className)}>
      <thead>
        <tr className={styles.labels}>
          <td />
          <td className={styles.lastWeek}>
            Beds Occupied Last Week
          </td>
          <td className={styles.lastWeekSpacer}/>
          <td className={styles.twoWeeks}>
            2 Weeks Ago
          </td>
        </tr>
      </thead>
      <tbody>
        <tr className={styles.bedsRow}>
          <td className={styles.label}>
            Beds<br />
            <span className={styles.sub}>(ICU incl.)</span>
          </td>
          <td className={styles.balls}>
            {inp.map((props, i) => (
              <Ball key={i} {...props} />
            ))}
          </td>
          <td className={styles.totalLastWeek}>
            <span className={styles.valueLabel}>Beds Occupied Last Week</span>
            <span className={styles.value}>{Math.round(data.inpatient.occ_this_week)}%</span>
          </td>
          <td className={styles.totalTwoWeeks}>
            <span className={styles.valueLabel}>2 Weeks Ago</span>
            <span className={styles.value}>{Math.round(data.inpatient.occ_previous_week)}%</span>
          </td>
        </tr>
        <tr className={styles.icuRow}>
          <td className={styles.label}>ICU</td>
          <td className={styles.balls}>
            {icu.map((props, i) => (
              <Ball key={i} {...props} />
            ))}
          </td>
          <td className={styles.totalLastWeek}>
            <span className={styles.valueLabel}>Beds Occupied Last Week</span>
            <span className={styles.value}>{Math.round(data.icu.occ_this_week)}%</span>
          </td>
          <td className={styles.totalTwoWeeks}>
            <span className={styles.valueLabel}>2 Weeks Ago</span>
            <span className={styles.value}>{Math.round(data.icu.occ_previous_week)}%</span>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td colSpan="3" className={styles.legend}>
            <div className={styles.covid}>Non COVID-19</div>
            <div className={styles.nonCovid}>COVID-19</div>
            <div className={styles.empty}>Unoccupied</div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

BedsChart.propTypes = {
  className: PropTypes.string,
  data: PropTypes.object.isRequired,
};

export default BedsChart;
