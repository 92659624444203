import React from "react";
import { Link } from "react-router-dom";
import "./styles/index.scss";
import { SUB_ROUTES, BASE_STATE_TIMELINE_ROUTE } from "./constants";
import styles from "./StateTimeline.module.scss";
import App from "./components/App";
import { DataTime } from "components/common/DataMeta";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";
import SideNarrative from "components/layout/SideNarrative";

const StateTimeline = () => {
  const page = getPage("/data/state-timeline");
  const { content_blocks } = page;

  return (
    <SideNarrative
      pageProps={{ path: BASE_STATE_TIMELINE_ROUTE }}
      narrative={
        <>
          <DataTime dataSet="state-timeline-intro" />
          <ReactMarkdown
            source={content_blocks["state-timeline-intro"]?.content}
          />
        </>
      }
    >
      <div id="jh-state-timeline" className={styles.container}>
        <App />
        <div style={{ marginTop: "1rem" }}>
          <ReactMarkdown
            source={content_blocks["state-timeline-data-sources"]?.content}
          />
        </div>
      </div>

      <div className={styles.snapshotMenu}>
        {SUB_ROUTES.map((r) => (
          <Link key={r} to={r}>
            {r}
          </Link>
        ))}
      </div>

      <br/>
      <br/>
    </SideNarrative>
  );
};

export default StateTimeline;
