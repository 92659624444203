import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../../Shared/constants";
import { Button } from "@material-ui/core";
import CumulativeData from "./CumulativeData";
import DailyDataAndDemographics from "./DailyDataAndDemographics";
import { ReactComponent as CountyMapsIcon } from "./Icons/county-maps.svg";
import styles from "./Visualization.module.scss";
import classNames from "classnames/bind";
import { accessibilityProps_IMG } from "util/accessibility";
const cx = classNames.bind(styles);

const useStyles = makeStyles((theme) => ({
  app: {
    display: "flex",
    flexDirection: "column",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  nameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "15px",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    "& .MuiButtonBase-root": {
      color: COLORS.gray,
      fontWeight: "normal",
      fontSize: 16,
      borderRadius: 0,
      borderBottom: "none",
      textTransform: "none",
      paddingBottom: 11,
      paddingLeft: 0,
      "&.active": {
        color: COLORS.blueDark,
        borderBottom: `3px solid #2270b7`,
      },
    },
    borderBottom: `1px solid #e6e7e9`,
    marginBottom: 9,
    justifyContent: "space-between",
    "max-width": 400,
    flexWrap: "wrap",
  },
}));

const Visualization = ({
  selectedState,
  countyMapViews,
  setCountyMapViews,
}) => {
  const classes = useStyles();

  // Get active county view
  const activeCountyMapView = countyMapViews.find((d) => d.active);

  return (
    <div className={classes.app}>
      <div className={classes.headerContainer}>
        <div className={classes.nameContainer}>
          <h2 className={cx(styles.iconHeader)}>
            <CountyMapsIcon {...accessibilityProps_IMG("COUNTY MAPS")} />
            COUNTY MAPS
          </h2>
        </div>
        <div className={classes.buttonGroup}>
          {countyMapViews.map((view) => {
            return (
              <Button
                key={`button-for-${view.id}`}
                onClick={() =>
                  setCountyMapViews(
                    countyMapViews.map((view) => ({
                      ...view,
                      active: !view.active,
                    }))
                  )
                }
                className={view.active ? "active" : ""}
              >
                {view.name}
              </Button>
            );
          })}
        </div>
        <div>{activeCountyMapView.description}</div>
      </div>
      {activeCountyMapView.id === "cumulative-data" ? (
        <CumulativeData selectedState={selectedState} />
      ) : (
        <DailyDataAndDemographics selectedState={selectedState} />
      )}
    </div>
  );
};
export default Visualization;
