import BlockQuote from "components/common/BlockQuote";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import styles from "../PDIFAQ.module.scss";
import questionSheets from "../questions";

const PDIFAQSheet = ({ match, location }) => {
  const sheet = match.params.sheetId
    ? questionSheets.find((s) => s.slug === match.params.sheetId)
    : questionSheets[0];
  const questionList = sheet.questions;

  useEffect(() => {
    // Abort early in testing
    if (navigator.userAgent.indexOf("jsdom") > -1) return;

    if (!!location.hash) {
      const el = document.getElementById(location.hash.slice(1));
      if (el === null) {
        return;
      }

      const offsetTop = el
        ? el.getBoundingClientRect().top + window.pageYOffset
        : 0;

      window.scrollTo({
        top: offsetTop - 160,
        behavior: "smooth",
      });
    }
  }, [location.hash]);

  return (
    <div className={styles.container}>
      {questionList.map(({ slug, question, answer, pullquote }) => (
        <div key={slug} id={slug} className={styles.question}>
          <h2>{question}</h2>
          <ReactMarkdown
            source={answer}
            linkTarget="_blank"
            renderers={{ blockquote: BlockQuote }}
          />
        </div>
      ))}
    </div>
  );
};

export default PDIFAQSheet;
