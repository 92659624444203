import React from "react";
import PropTypes from "prop-types";
import Countdown, { zeroPad } from "react-countdown";
import classNames from "classnames/bind";
import Share from "components/common/Share";
import HFormat from "components/common/HFormat";
import styles from "./EventHeader.module.scss";
import EventCalendarButton from "../EventCalendarButton";
const cx = classNames.bind(styles);

const EventHeader = ({
  eventId,
  subtitle,
  caption,
  meta,
  currentEventState,
  dates,
  handleCountdownComplete,
}) => {
  const { title, description } = meta;
  const { start, end } = dates;
  const isPreState = currentEventState === "pre";
  const isPostState = currentEventState === "post";

  const onCountdownComplete = (isMid) => handleCountdownComplete(isMid);
  const countdown = ({ days, hours, minutes, seconds }) => (
    <div className={styles.countdownContainer}>
      <HFormat size={3} noMargins>
        Happening in
      </HFormat>

      <div className={styles.timerContainer}>
        {days > 0 && (
          <div className={styles.timerBlock}>
            <span className={styles.timerValue}>{zeroPad(days)}</span>
            <span className={styles.timerLabel}>days</span>
          </div>
        )}
        <div className={styles.timerBlock}>
          <span className={styles.timerValue}>{zeroPad(hours)}</span>
          <span className={styles.timerLabel}>hours</span>
        </div>
        <div className={styles.timerBlock}>
          <span className={styles.timerValue}>{zeroPad(minutes)}</span>
          <span className={styles.timerLabel}>minutes</span>
        </div>
        <div className={styles.timerBlock}>
          <span className={styles.timerValue}>{zeroPad(seconds)}</span>
          <span className={styles.timerLabel}>seconds</span>
        </div>
      </div>
    </div>
  );
  return (
    <div className={styles.interiorHeader}>
      <div
        className={cx([
          "interiorHeaderContainer",
          { live: currentEventState === "mid" },
        ])}
      >
        <div className={styles.contentLeft}>
          <HFormat size={1} noMargins>
            {title}
          </HFormat>
          <HFormat size={3} noMargins>
            {caption}
          </HFormat>
          <p>{subtitle}</p>
        </div>

        {!isPostState && (
          <div className={cx(["contentMiddle", { countdown: isPreState }])}>
            {isPreState ? (
              <Countdown
                // For testing
                // date={Date.now() + 4000}
                date={start}
                renderer={countdown}
                onComplete={() => onCountdownComplete(true)}
              />
            ) : (
              <div className={styles.liveNow}>
                <HFormat size={1} noMargins>
                  LIVE NOW
                </HFormat>
                <div className={styles.postCountdown}>
                  <Countdown
                    // For testing
                    // date={Date.now() + 12000}
                    date={end}
                    onComplete={() => onCountdownComplete(false)}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <div
          className={cx([
            "contentRight",
            { noCalendar: !isPreState },
            { postEvent: isPostState },
          ])}
        >
          <Share classNames={styles.eventShare} label="Share:" />
          {currentEventState && isPreState && (
            <EventCalendarButton
              eventId={eventId}
              start={start}
              end={end}
              title={title}
              description={description}
            />
          )}
        </div>
      </div>
    </div>
  );
};

EventHeader.propTypes = {
  subtitle: PropTypes.string,
  caption: PropTypes.string,
  meta: PropTypes.object,
  currentEventState: PropTypes.string,
  dates: PropTypes.object,
  handleCountdownComplete: PropTypes.func,
};

export default EventHeader;
