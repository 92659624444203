import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import RegionSelect from "./RegionSelect";
import VisualizationToggle from "../VisualizationToggle";
import StateHeader from "./StateHeader";
import StateRow from "./StateRow";
import { useSelector } from "react-redux";
import {
  NEW_CASES,
  STATE_NAME,
  TESTS,
  PERCENT_POSITIVE,
  valueAccessor,
  ALL_REGIONS_VISIBLE,
} from "../../../constants";
import { ascending, descending } from "d3-array";
import Table from "@material-ui/core/Table";
import selectedDataSelector from "../../../redux/selectors/data.selector";
import TableBody from "@material-ui/core/TableBody";
import ExportView from "./ExportView";
import { getPercentPositiveCurrentValue } from "./utils";
import StateOverviewModal from "./InfoModal/StateOverviewModal";
import stoppedReportingStates from "data/__mocks__/stopped-reporting-states.json";

export const HEADER_CELLS = [
  { heading: "State", subHeading: [], accessor: (d) => d[STATE_NAME] },
  {
    heading: "New Cases",
    subHeading: ["New daily cases", "Weekly trend"],
    info: "Use the arrow at right to sort by daily number of confirmed cases.",
    accessor: (d) => d.currentValues[NEW_CASES],
  },
  {
    heading: "Tests per 100k people",
    subHeading: ["Daily Tests", "Weekly trend"],
    info: "",
    accessor: (d) => d.currentValues[TESTS],
  },
  {
    heading: "Percent Positive",
    subHeading: ["Daily % positive", "Calc. method"],
    info:
      "Use the arrow at right to sort by the 7 day moving average rate of positivity. Hover over a bar to see the daily value. A low rate of positivity in testing data may be a sign that a state has sufficient testing capacity for the size of its epidemic. According to the WHO, positivity rates should remain under 5%.",
    accessor: (d) => {
      return getPercentPositiveCurrentValue(
        d.values[PERCENT_POSITIVE].find((d) => d.selected)
      );
    },
  },
];
const SORT_ORDER_LOOKUP = {
  asc: ascending,
  desc: descending,
};

const StateTable = (props) => {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState(HEADER_CELLS[0].heading);

  const tableRef = React.useRef();
  const tableScrollFadeRef = React.useRef();
  const updateTableScroll = ({ target }) => {
    // Showing / hiding the fade is handled imperatively because the rendering performance of
    // this component is very poor
    const { scrollLeft } = target;
    const { current: fadeEl } = tableScrollFadeRef;
    if (fadeEl) fadeEl.classList.toggle("hidden", scrollLeft > 10);
  };
  React.useEffect(() => {
    if (tableRef.current) {
      const tableEl = tableRef.current;
      tableEl.addEventListener("scroll", updateTableScroll);
      return () => tableEl.removeEventListener("scroll", updateTableScroll);
    }
  }, [tableRef]);

  const { region = ALL_REGIONS_VISIBLE } = useParams();
  const { search } = useLocation();
  const history = useHistory();

  // Retrieve state name from the url when trying to print
  const query = queryString.parse(search);
  const stateName = query.state;
  const exportView =
    { true: "default", alternate: "alternate" }[query.export] || false;

  const regions = useSelector((state) => state.testingTracker.regions);
  let routeRegion = regions.find((r) => valueAccessor(r) === region);

  let data = useSelector(
    selectedDataSelector({
      region: routeRegion,
      ...(exportView ? { stateName } : {}),
    })
  );

  // If the region is not found, then default to showing all
  if (!routeRegion) {
    history.push(`/testing/tracker/overview/${ALL_REGIONS_VISIBLE}`);
    return null;
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  // Handle the sort
  const selectedHeader = HEADER_CELLS.find((d) => d.heading === orderBy);
  data = data.sort((a, b) => {
    return SORT_ORDER_LOOKUP[order](
      selectedHeader.accessor(a),
      selectedHeader.accessor(b)
    );
  });

  return (
    <div className="table__wrapper">
      <div className="table__header">State Overview</div>
      <div className="table__filters">
        <RegionSelect />
        <StateOverviewModal />
        <VisualizationToggle />
      </div>
      {!exportView ? (
        <React.Fragment>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="table__scroll_indicator"
            viewBox="0 0 57 25"
          >
            <g fill="#3767AB" fillRule="evenodd">
              <path
                d="M27.2 1a2.8 2.8 0 00-2.7 2.8V13l-.8-.8a2.7 2.7 0 00-3.9 0c-.5.6-.8 1.3-.8 2 0 .8.3 1.5.8 2l5.8 5.9c1.2 1.3 3 2 4.7 2h1c3.7 0 6.7-3.1 6.7-6.9v-5.4c0-1.5-1.2-2.8-2.7-2.8-.6 0-1.2.2-1.6.5A2.7 2.7 0 0031.3 8c-.5 0-1 .1-1.3.3V3.8C30 2.3 28.8 1 27.3 1zm4 8.4c.7 0 1.3.6 1.3 1.3v1.8c0 .4.3.8.7.8.5 0 .8-.4.8-.8v-.8c0-.7.6-1.2 1.2-1.2.7 0 1.3.5 1.3 1.2v5.4c0 3-2.4 5.4-5.3 5.4h-.9c-1.4 0-2.7-.6-3.7-1.6L21 15c-.3-.2-.4-.5-.4-.9 0-.3.1-.7.4-.9a1.2 1.2 0 011.7 0l1.9 2v1.1c0 .5.3.8.7.8.5 0 .8-.3.8-.8V3.8c0-.7.6-1.3 1.2-1.3.7 0 1.3.6 1.3 1.3v8.7c0 .4.3.8.7.8.5 0 .8-.4.8-.8v-1.8c0-.7.6-1.3 1.2-1.3z"
                fillRule="nonzero"
              />
              <path d="M50.7 6.7l5.7 5.6v.4l-5.7 5.6-.7-.7 4.6-4.6H45v-1h9.6L50 7.4zM6.7 6.7L1 12.3v.4l5.7 5.6.7-.7L2.7 13h9.7v-1H2.7l4.7-4.6z" />
            </g>
          </svg>
          <div className="table__container">
            <Table className="table" size="small" ref={tableRef}>
              <StateHeader
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                HEADER_CELLS={HEADER_CELLS}
              />
              <TableBody className="table__body">
                {data.map((d) => {
                  return (
                    <StateRow
                      key={`state-row-for-${d[STATE_NAME]}`}
                      filteredData={d}
                      isStopped={!!stoppedReportingStates.filter(v => v.STATE_NAME === d[STATE_NAME])?.length}
                      stoppedDate={stoppedReportingStates.filter(v => v.STATE_NAME === d[STATE_NAME])[0]?.STOPPED_DATE}
                    />
                  );
                })}
              </TableBody>
            </Table>
            <div ref={tableScrollFadeRef} className="table__scroll_fade" />
          </div>
        </React.Fragment>
      ) : data.length ? (
        <ExportView
          exportData={data[0]}
          layout={exportView}
          headerCells={HEADER_CELLS}
        />
      ) : null}
    </div>
  );
};
StateTable.defaultProps = {};
export default StateTable;
