import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as styles from "./CustomSelect.module.scss";
import { default as ReactSelect, components } from "react-select";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export const CustomSelectWrapper = (props) => {
  const handleOnChange = (selected, event) => {
    if (selected !== null && selected.length > 0) {
      if (selected[selected.length - 1].value === props.allOption.value) {
        return props.onChange([props.allOption, ...props.options]);
      }
      let result = [];
      if (selected.length === props.options.length) {
        if (selected.includes(props.allOption) || props.multi) {
          result = selected.filter(
            (option) => option.value !== props.allOption.value
          );
        } else if (event.action === "select-option") {
          result = [props.allOption, ...props.options];
        }
        return props.onChange(result);
      }
    }
    return props.onChange(selected);
  };

  if (props.allowSelectAll) {
    const allOptions = [props.allOption, ...props.options];
    return (
      <ReactSelect
        {...props}
        options={allOptions}
        // Adding this will reverse the mulit-select effect
        // value={value && value.length > 0? value : allOptions}
        onChange={handleOnChange}
      />
    );
  }
  return <ReactSelect {...props} />;
};

const allOption = {
  label: "Select all",
  value: "*",
};

CustomSelectWrapper.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  allowSelectAll: PropTypes.bool,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};
CustomSelectWrapper.defaultProps = {
  allOption: {
    label: "All Content",
    value: "*",
  },
};

const Option = (props) => {
  return (
    <div>
      <components.Option {...props} className={styles.options}>
        {props.isMulti ? (
          <div className={props.isSelected ? styles.optionBoxSelected : null}>
            {""}
          </div>
        ) : null}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some((val) => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  } else {
    if (props.selectProps.limitMulitValueContainer && children[0]?.length > 1) {
      toBeRendered = [[children[0]?.slice(0, 1)], children[1]];
    }
  }
  return (
    <components.ValueContainer
      {...props}
      className={styles.valueContainerHideX}
    >
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = (props) => {
  const { data, selectProps } = props;
  let labelToBeDisplayed = `${data.label}${
    selectProps.limitMulitValueContainer ? "..." : ","
  }`;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className={styles.dropdownIndicator}></div>
    </components.DropdownIndicator>
  );
};

const CustomSelectProps = {
  isSearchable: PropTypes.bool,
  resetToAll: PropTypes.bool,
  noAllOption: PropTypes.bool,
  multi: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  classNames: PropTypes.string,
  currentSelected: PropTypes.object,
  optionsKeys: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  allOptionLabel: PropTypes.string,
  limitMulitValueContainer: PropTypes.bool,
};

const CustomSelect = ({
  options,
  baseClassName,
  classNames,
  onSelect,
  limitMulitValueContainer,
  currentSelected,
  isSearchable = true,
  resetToAll = false,
  noAllOption = false,
  multi = false,
  label = "Show Me:",
  allOptionLabel = "All",
  optionsKeys = {
    label: "label",
    value: "value",
  },
}) => {
  const allContentOption = [{ label: allOptionLabel, value: "all-content" }];
  const [selected, setSelected] = useState(
    resetToAll ? allContentOption[0] : currentSelected || null
  );

  if (
    resetToAll &&
    (!selected || selected[0]?.value !== allContentOption[0].value)
  ) {
    setSelected(allContentOption);
  }

  // value may be a single Option or an Array of options
  const handleOnChange = (value) => {
    setSelected(value);
    if (onSelect) onSelect(value);
  };

  const customStyles = {
    option: (provided, state) => {
      return {
        ...provided,
        backgroundColor: "#E5E7E9",
        color: state.isSelected ? "#092c74" : "black",
        display:
          multi && state.data.label === state.selectProps.allOption.label
            ? "none"
            : "block",
      };
    },
  };

  const className = [styles.base, multi ? styles.multi : null, classNames].join(
    " "
  );

  useEffect(() => {
    if (currentSelected && currentSelected !== selected) {
      setSelected(currentSelected);
    }
  }, [currentSelected, selected]);

  return (
    <div className={cx(styles.selectContainer, baseClassName)}>
      <p>{label}</p>
      <CustomSelectWrapper
        isMulti={!!multi}
        allowSelectAll={!!multi}
        closeMenuOnSelect={!multi}
        hideSelectedOptions={false}
        limitMulitValueContainer={limitMulitValueContainer}
        placeholder="All"
        selectedOptions={selected}
        options={
          !!multi || noAllOption ? options : allContentOption.concat(options)
        }
        value={!!multi ? selected : selected || allContentOption}
        components={{
          Option,
          MultiValue,
          ValueContainer,
          DropdownIndicator,
        }}
        isSearchable={isSearchable}
        onChange={handleOnChange}
        className={className}
        styles={customStyles}
      />
    </div>
  );
};

CustomSelect.propTypes = CustomSelectProps;

export default CustomSelect;
