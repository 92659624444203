import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./OverviewBlock.module.scss";
import { ReactComponent as UpperLimitIcon } from "../Icons/icon-upper-limit.svg";
import { formatDay, formatMonth, formatNumber, formatYear } from "../util";
import { ReactComponent as Warning } from "images/icons/warning.svg";
import StoppedAlert from "components/common/StoppedAlert";
import { useRouteMatch } from "react-router-dom";

export const OverviewBlock = ({
  title,
  value,
  averages,
  loading,
  isPercentage,
  timeScale,
  record,
  stoppedDate,
  ...props
}) => {
  const match = useRouteMatch();

  // Format date
  let recordDateString;
  if (timeScale !== "all") {
    // Convert date to Date object
    const recordDate = new Date(record?.date);
    // Format date for week, month, or day
    if (timeScale === "week") {
      const endDate = new Date(recordDate.getTime());
      endDate.setDate(endDate.getDate() + 6);
      recordDateString = `${formatDay(recordDate)}–${formatDay(
        endDate
      )}, ${formatYear(recordDate)}`;
    } else if (timeScale === "month") {
      recordDateString =
        formatMonth(recordDate) + ", " + formatYear(recordDate);
    } else {
      recordDateString = formatDay(recordDate) + ", " + formatYear(recordDate);
    }
  }

  return (
    <div {...props} className={classNames(styles.overviewBlock, {
      [styles.stopped]: !!stoppedDate,
    })}>
      <h3>{title}</h3>
      <div className={styles.statsBlock}>
        <span
          className={classNames(styles.statValue, {
            [styles.loading]: loading,
          })}
        >
          {loading ? "Loading..." : formatNumber(value, isPercentage)}
        </span>
        {averages && (
          <span
            className={classNames(styles.statAvg, {
              [styles.loading]: loading,
            })}
          >
            {loading
              ? "Loading..."
              : `${formatNumber(averages, false)} (7-Day Avg.)`}
          </span>
        )}
      </div>
      {timeScale !== "all" && !!(record || loading) && (
        <div className={styles.highestRecord}>
          <h4>
            <UpperLimitIcon />
            Record high:
          </h4>
          <div className={loading ? styles.loading : ""}>
            <span>
              {loading
                ? "Loading..."
                : formatNumber(record?.value, isPercentage)}
            </span>{" "}
            {!loading && <span>{recordDateString}</span>}
          </div>
        </div>
      )}
      {!!stoppedDate && (
        <div className={styles.stoppedDate}>
          <Warning />
          <h4>STOPPED REPORTING: <span>{stoppedDate}</span></h4>
          <StoppedAlert className={styles.alert} state={match.params?.countryId || match.params?.stateId} category={title} stoppedDate="09/21/2022" />
        </div>
      )}
    </div>
  );
};

OverviewBlock.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number,
  loading: PropTypes.bool,
  isPercentage: PropTypes.bool,
  timeScale: PropTypes.oneOf(["day", "week", "month", "all"]).isRequired,
  record: PropTypes.shape({
    value: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
  }),
};
