import React from "react";
import styles from "./TextBanner.module.scss";
import TextBanner from "components/common/TextBanner";

const TextBannerStyleGuide = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles["styleguide-annotation"]}>TextBanner</h1>

      <h2>Theme: Default</h2>
      <TextBanner title={"Title"} content={"Content"} />
      <br />
      <TextBanner title={"Title"} content={"Content"} href="/" />
      <br />
      <h2>Theme: Alert</h2>
      <TextBanner
        title={"This state does not satisfy JHU CRC’s data reporting standards"}
        content={
          "Florida only reports COVID-19 data once per week via their COVID-19 Weekly Situation Report, and does not provide a daily back-distribution of Case or Death data."
        }
        theme="alert"
      />
    </div>
  );
};

export default TextBannerStyleGuide;
