import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import { ReactComponent as ArrowIcon } from 'images/icons/arrow-right.svg';

import classNames from 'classnames/bind';
import styles from './Accordion.module.sass';
const cx = classNames.bind(styles);

export default function AccordionItem({ topContent, children, isOpen, activate }) {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);
  useEffect(() => {
    function update() {
      if (!contentRef.current) return;
      setContentHeight(contentRef.current.getBoundingClientRect().height);
    }
    update();
    window.addEventListener('resize', update);
    return () => window.removeEventListener('resize', update);
  }, [contentRef, isOpen]);

  const heightMotionValue = useMotionValue(isOpen ? contentHeight : 0);
  const expandProgress = useTransform(heightMotionValue, [0, contentHeight], [0, 1]);

  return (
    <div className={cx('item', { open: isOpen })}>
      <div
        className={cx('trigger')}
        onClick={activate}
      >
        { topContent }

        <motion.div
          className={cx('expand-arrow')}
          style={{ rotate: useTransform(expandProgress, [0, 1], [90, -90]) }}
        >
          <ArrowIcon />
        </motion.div>
      </div>

      <motion.div
        style={{ height: heightMotionValue }}
        animate={{ height: isOpen ? contentHeight : 0 }}
        initial={false}
        transition={{ stiffness: 250, damping: 15 }}
        className={cx('body')}
      >
        <div ref={contentRef} className={cx('content-wrapper')}>
          { children }
        </div>
      </motion.div>
    </div>
  );
}

AccordionItem.propTypes = {
  topContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool,
  activate: PropTypes.func,
};
AccordionItem.defaultProps = {
  isOpen: false,
  activate: () => {},
}
