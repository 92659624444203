import React, { useEffect } from "react";

import * as GTM from "util/gtm";
import styles from "./About.module.scss";
import Page from "components/layout/Page";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";
import { experts } from "./data";
import Button from "components/common/Button";

const ExpertsListItem = ({ children, index }) => {
  const getLinkContent = (linkData) => {
    return {
      href: linkData?.href,
      name: linkData?.children[0]?.props?.children,
    };
  };
  const { name } = getLinkContent(children[0]?.props);
  const imageSrc = experts.find((ele, i) => ele.name === name)?.src;
  return (
    <li key={`list-item-#${index}${name}`}>
      {!!imageSrc && <img src={imageSrc} alt="" />}
      <div>{children}</div>
    </li>
  );
};

const About = ({ location }) => {
  useEffect(GTM.clear, []);
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;
  return (
    <Page header>
      <div className={styles.container}>
        <ReactMarkdown
          source={
            content_blocks["covid-19-testing-insights-initiative"]?.content
          }
        />
        <div className={styles.expertsContainer}>
          <ReactMarkdown
            source={content_blocks["our-experts"]?.content}
            renderers={{ listItem: ExpertsListItem }}
          />
        </div>
        <ReactMarkdown
          source={content_blocks["about-us-our-experts-bottom"]?.content}
        />
        <Button
          href={`/region-data-notes`}
          buttonStyle="plain"
          className={styles.statePageLink}
          icon="right"
        >
          <span>See Data Notes</span>
        </Button>
        <div className={styles.expertsContainer}>
          <ReactMarkdown
            source={content_blocks["about-us-one-university-effort"]?.content}
            renderers={{ listItem: ExpertsListItem }}
          />
        </div>
      </div>
    </Page>
  );
};

export default About;
