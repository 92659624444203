import React from "react";

type TTimelineSourcesProps = {
  className?: string;
};

export const TimelineSources = ({ className }: TTimelineSourcesProps) => {
  return (
    <div className={className}>
      <p>
        <strong>Data Sources:</strong> Cases and deaths data from{" "}
        <a href="https://github.com/CSSEGISandData/COVID-19">JHU CSSE</a>;
        testing and vaccine data from{" "}
        <a href="https://github.com/govex/COVID-19/tree/master/data_tables">
          JHU CCI
        </a>
        ; and hospitalization data from the U.S. Department of{" "}
        <a href="https://healthdata.gov/Hospital/COVID-19-Reported-Patient-Impact-and-Hospital-Capa/g62h-syeh">
          Health and Human Services
        </a>
        .
      </p>
    </div>
  );
};
