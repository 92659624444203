import React, { useEffect } from "react";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import TestingGraphAndTable from "./TestingGraphAndTable";

const TestingViz = ({ match, location }) => {
  const page = getPage("/testing/states-comparison");
  const { content_blocks } = page;
  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-states-comparison",
    });
  }, []);
  return (
    <TestingGraphAndTable
      dataSetId={match.params.dataSetId}
      contentBlocks={content_blocks}
      pageProps={{ path: "/testing/states-comparison" }}
    />
  );
};

export default TestingViz;
