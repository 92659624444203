import React from "react";
import { Switch, Route } from "react-router-dom";

import StyleGuide from "./StyleGuide";
import Timeline from "./Timeline";

const VaccineTimeline = () => {
  return (
    <Switch>
      <Route path="/vaccines/timeline/style-guide" component={StyleGuide} />
      <Route path="/vaccines/timeline" component={Timeline} />
    </Switch>
  );
};

export default VaccineTimeline;
