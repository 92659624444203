import React, { FC, useState } from "react";
import styles from "./CarouselNav.module.scss";
import cx from "classnames";

export type TCarouselNavProps = {
  slideCount: number;
  slideIndex?: number;
  variant?: "hero" | "ring";
  activeDisplay?: "single" | "incremental";
  onNavClick?: (selectedIndex: number) => void;
  manageIXInternally?: boolean;
  className?: string;
};

const CarouselNav: FC<TCarouselNavProps> = ({
  slideCount = 0,
  slideIndex = null,
  onNavClick,
  variant = "hero",
  activeDisplay = "single",
  manageIXInternally = true,
  className,
}) => {
  const [currentIX, setCurrentIX] = useState<number>(0);
  const handleCarouselClick = (selectedIndex = 0) => {
    if (manageIXInternally) setCurrentIX(selectedIndex);
    if (onNavClick) onNavClick(selectedIndex);
  };

  const isActive = (index: number): boolean => {
    const current = manageIXInternally ? currentIX : slideIndex;
    if (activeDisplay === "incremental") {
      return current >= index;
    }
    return current === index;
  };

  return (
    <div className={cx(styles.base, className)}>
      {new Array(slideCount).fill(null).map((_, i) => (
        <div
          key={`carousel-nav-slide#${i}`}
          className={cx(
            styles.dot,
            isActive(i) ? styles.active : "",
            styles[variant]
          )}
          onClick={() => handleCarouselClick(i)}
        >
          <div className={styles.dotInner} />
        </div>
      ))}
    </div>
  );
};

export default CarouselNav;
