import React from "react";
import ReactMarkdown from "react-markdown";
import { getPage } from "data/util";
import styles from "./ManagmentMetrics.module.scss";
import Page from "components/layout/Page";
import { Accordion, AccordionItem } from "components/common/Accordion";

const ManagmentMetrics = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.intro}>
          <div>
            <ReactMarkdown
              className={styles.introText}
              source={content_blocks['management-metrics-for-cities-intro'].content}
            />
            <Accordion className={styles.accordionContainer} autoOpen={false}>
              <AccordionItem
                topContent={
                  <div className={styles.accordionTriggerContent}>More Details</div>
                }
              >
                <ReactMarkdown
                  source={
                    content_blocks["management-metrics-for-cities-b"].content
                  }
                  linkTarget="_blank"
                />
              </AccordionItem>
            </Accordion>
          </div>

          <div className={styles.introVideo}>
            <img
              alt="img" 
              src="/downloads/Management_Metrics_for_Cities_in_the_COVID19_Crisis_Cover.jpg" width="100%" height="500px">
            </img>
            <a
              className={styles.button}
              href="/downloads/COVID19_Management_Metrics_for_Cities.pdf"
              target="_blank"
              rel="noopener noreferrer"
              download
            >
              <strong>Download the PDF</strong>
            </a>
          </div>
        </div>   
      </div>     

      <div className={styles.container}>
          <ReactMarkdown
            className={styles.notes}
            source={content_blocks['management-metrics-for-cities-c'].content}
          />
      </div>

      <div className={styles.airTable}>
        <iframe 
          title="some title" 
          className="airtable-embed" 
          src="https://airtable.com/embed/shroJKgELNxsmPcyU?backgroundColor=cyan" 
          frameBorder="0" 
          width="100%" 
          height="533" 
          style={{background: "transparent", border: "1px solid #ccc" }}>
        </iframe>
      </div>    
    </Page>
  );
};

export default ManagmentMetrics;
