import React, {cloneElement, useEffect, useRef, useState, useMemo} from 'react';
import {useDimensions} from '../../../../../Shared/utils';
import {zoom as d3_zoom, zoomIdentity} from 'd3-zoom';
import {event, select} from "d3-selection";
import {scaleBand} from "d3-scale";
import 'd3-transition';
import {dateAccessor} from "../../../../constants";
import {DURATION} from "../../../../../Shared/constants";

const BaseChart = ({margin, className, children}) => {
    const groupRef = useRef(null);
    const [visualizationRef, {width, height, isResized}] = useDimensions({
        width: 100,
        height: 50,
        isResized: false,
    });
    if (!margin) {
        margin = {
            left: 75,
            right: 5, // 45,
            top: 75,
            bottom: 25,
        };
    }
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const [xScaleRange, setXScaleRange] = useState([0, innerWidth]);

    const xScale = useMemo(() => {
        return scaleBand()
            .paddingInner(0.25)
            .range([0, innerWidth])
            .domain(children.props.data.map(dateAccessor));
    }, [innerWidth, children.props.data])

    const backgroundXScale = useMemo(() => {
        return scaleBand()
            .range([0, innerWidth])
            .domain(children.props.data.map(dateAccessor))
    }, [innerWidth, children.props.data]);

    useEffect(() => {
        const zoomed = () => {
            const t = event.transform;
            setXScaleRange([0, innerWidth].map(d => t.applyX(d)));
        }

        const zoom = d3_zoom()
            .scaleExtent([1, 32])
            .translateExtent([[0, 0], [width, height]])
            .extent([[0, 0], [width, height]])
            .on("zoom", zoomed);

        const {dateExtent, zoomEffect} = children.props;
        const g = select(groupRef.current);
        const svg = g.select(function () {
            return this.parentNode;
        });

        const _svg = svg.call(zoom)
            .on("wheel.zoom", null)
            .on("dblclick.zoom", null)
            .on("mousedown.zoom", null)
            .on("touchstart.zoom", null)
            .on("touchmove.zoom", null)
            .on("touchend.zoom", null)
            .transition()
            .duration(DURATION);

        if (!zoomEffect) {
            _svg
                .call(zoom.transform, zoomIdentity
                    .scale(1)
                    .translate(0, 0)
                )
        } else {
            _svg
                .call(zoom.transform, zoomIdentity
                    .scale(width / (xScale(dateExtent[1]) - xScale(dateExtent[0])))
                    .translate(-xScale(dateExtent[0]), 0)
                )
        }

        return () => {
            svg.interrupt();
            zoom.on("zoom", null);
        }
    }, [children.props, width, height, xScale, innerWidth])

    // Handle clip path and event marker position
    const offset = margin.top / 2;

    useEffect(() => {
        setXScaleRange([0, innerWidth]);
    }, [innerWidth])

    return (
        <svg width={width} height={height} ref={visualizationRef} className={className}>
            <defs>
                <clipPath id="bar-chart-clip">
                    <rect width={innerWidth} height={height} x={0} y={-offset}/>
                </clipPath>
            </defs>
            <g transform={`translate(${margin.left},${margin.top})`} ref={groupRef}>
                {isResized &&
                cloneElement(children, {
                    innerWidth,
                    innerHeight,
                    margin,
                    xScale,
                    backgroundXScale,
                    xScaleRange,
                    offset
                })}
            </g>
        </svg>
    );
};

export default BaseChart;
