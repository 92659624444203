import React from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {MenuItem, FormControl, Select, InputBase} from '@material-ui/core';
import { COLORS, labelAccessor, nameAccessor, valueAccessor } from '../../../../../../Shared/constants';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
            color: COLORS.textBlue,
            fontWeight: '600',
            fontSize: '16px'
        },
    },
    input: {
        padding: '6.5px 26px 6.5px 16px',
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '380px',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - ${theme.spacing(2)}px)`,
        }
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    title: {
        color: '#56586e',
        fontSize: '14px'
    },
    select: {
        borderRadius: 5,
        marginTop: '10px',
        fontWeight: 'normal',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
        color: COLORS.textBlue,
        '.MuiSelect-select': {
            paddingLeft: theme.spacing(1),
        },
        '&:hover:not(.Mui-disabled):before, &:before': {
            display: 'none',
        },
        '&:after': {
            display: 'none',
        },
    },
}));

const CustomSelect = ({items, selectedItem, onChange, labels, id}) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            {
                labels.map(label => {
                    return (
                        <div id={`${id}-label-${label}`}
                                    key={`${id}-label-${label}`}
                                    className={classes.title}>
                            {label}
                        </div>
                    )
                })
            }

            <Select
                labelId={`${id}-label`}
                id={`${id}`}
                value={valueAccessor(selectedItem)}
                onChange={evt => onChange(evt.target.value)}
                className={classes.select}
                input={<BootstrapInput/>}
            >
                {items.map(item => {
                    return (
                        <MenuItem key={`region-menu-item-${valueAccessor(item)}`} value={valueAccessor(item)}>
                            {labelAccessor(item) || nameAccessor(item)}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
CustomSelect.defaultProps = {
    items: [],
    selectedItem: {},
    onChange: () => null,
    label: '',
    id: '',
};
export default CustomSelect;
