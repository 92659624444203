import React from 'react';
import {COLORS} from "../../../../../../../Shared/constants";
import {makeStyles} from "@material-ui/core/styles";
import {getTextWidth} from "../../../../../../../Shared/utils";

const useStyles = makeStyles(() => ({
    wrapper: {
        pointerEvents: 'none'
    },
    marker: {
        fill: COLORS.blueDark,
        stroke: 'none',
        pointerEvents: 'none'
    },
    text: {
        fill: COLORS.blueDark,
        stroke: 'none',
        fontSize: '14px'
    }
}));

const City = ({id, x, y, width}) => {
    const classes = useStyles();

    const textWidth = getTextWidth(id);
    const isTooWide = textWidth + x > width;

    return (
        <g className={classes.wrapper} transform={`translate(${x},${y})`}>
            <circle
                r={3}
                className={classes.marker}
            />
            <text className={classes.text} dx={isTooWide ? -5 : 5} dy={'-5px'}
                  style={{textAnchor: isTooWide ? 'end' : 'start'}}>
                {id}
            </text>
        </g>
    );
};

export default City;
