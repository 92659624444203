import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { parse } from "query-string";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import selectedDemographicLayerSelector from "../../../../redux/selectors/demographic.layer.selector";
import {
  updateSelectedBubbleLayer,
  updateSelectedDemographicLayer,
} from "../../../../redux/modules/global";
import selectedBubbleLayerSelector from "../../../../redux/selectors/bubble.layer.selector";
import BubbleLayerContainer from "./BubbleLayerContainer";
import DemographicLayerContainer from "./DemographicLayerContainer";
import Map from "./Map";
import { max, mean, min } from "d3-array";
import * as topojson from "topojson-client";
import { scaleSymlog } from "d3-scale";
import { COLORS } from "../../../../../Shared/constants";
import RacialBreakdownContainer from "./RacialBreakdownContainer";
import StoppedAlert from "components/common/StoppedAlert";

const useStyles = makeStyles((theme) => ({
  visualizationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
    height: "100%",
  },
  outerLegendContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: -theme.spacing(1),
    marginRight: "20px",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0",
    },
    maxWidth: "425px",
  },
  legendContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fbfbfb",
    border: "1px solid #f0f0f0",
  },
  source: {
    fontSize: "13px",
    color: "#56586e",
    textAlign: "left",
    marginTop: "18px",
  },
  headerRowContainer: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      marginBottom: 25,
      marginLeft: -8,
    },
    "& > *:first-child": {
      marginRight: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },
  visualization: {
    width: "60%",
    margin: "20px 0 20px 30px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "auto",
      marginLeft: "0",
    },
  },
  racialBreakdownContainer: {
    display: "flex",
    flexDirection: "column",
    "& h4": {
      color: COLORS.blueDark,
      fontSize: "22px",
    },
  },
  stoppedAlert: {
    position: "relative",
    marginLeft: 0,
    zIndex: 2000,
  },
}));

const DailyDataAndDemographics = ({ selectedState }) => {
  const data = useSelector((state) => state.equityAccessToTesting.data);
  const classes = useStyles();

  // Set selected layers from URL params if available:
  const dispatch = useDispatch();
  const { bubbleLayer, demographicLayer } = parse(useLocation().search);
  useEffect(() => {
    if (bubbleLayer) {
      dispatch(updateSelectedBubbleLayer(bubbleLayer));
    }
    if (demographicLayer) {
      dispatch(updateSelectedDemographicLayer(demographicLayer));
    }
  }, [dispatch, bubbleLayer, demographicLayer]);

  // Get selected layers for drop downs
  let selectedDemographicLayer = useSelector(selectedDemographicLayerSelector);
  let selectedBubbleLayer = useSelector(selectedBubbleLayerSelector);

  let counties;
  try {
    counties = topojson.feature(data, data.objects?.counties);
  } catch (error) {
    console.log(error);
    return null;
  }

  // Set color, bubble scales and get average values for markers
  const demographicAccessor = (d) =>
    +d.properties[selectedDemographicLayer.name];
  selectedDemographicLayer.colorScale = selectedDemographicLayer.colorScale
    .copy()
    .domain([0, max(counties.features, demographicAccessor)]);
  selectedDemographicLayer.averageValue = mean(
    counties.features,
    demographicAccessor
  );

  const bubbleAccessor = (d) => +d.properties[selectedBubbleLayer.name];
  const minValue = 1;
  const maxValue = 25;
  selectedBubbleLayer.bubbleScale = scaleSymlog()
    .domain([
      min(counties.features.map(bubbleAccessor)),
      max(counties.features.map(bubbleAccessor)),
    ])
    .range([minValue, maxValue]);
  selectedBubbleLayer.averageValue = mean(counties.features, bubbleAccessor);

  return (
    <div className={classes.wrapper}>
      <div className={classes.visualizationContainer}>
        <div className={classes.outerLegendContainer}>
          <div className={classes.legendContainer}>
            <BubbleLayerContainer selectedBubbleLayer={selectedBubbleLayer} />
            <DemographicLayerContainer
              selectedDemographicLayer={selectedDemographicLayer}
            />
          </div>
          <div className={classes.source}>
            <strong>Data Sources: </strong>
            County testing data from{" "}
            <a
              href="https://github.com/govex/COVID-19/tree/7d5a00cc03a28c747973cf71b4dd9fef43c9b264/data_tables"
              target="_blank"
              rel="noopener noreferrer"
            >
              JHU CCI
            </a>
            ; cases and deaths data from{" "}
            <a
              href="https://github.com/CSSEGISandData/COVID-19"
              target="_blank"
              rel="noopener noreferrer"
            >
              JHU CSSE
            </a>
            ; demographic data from{" "}
            <a
              href="https://www.census.gov/data/developers/data-sets/acs-5year.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              American Community Survey
            </a>
          </div>
        </div>
        <div className={classes.visualization}>
          <Map
            selectedBubbleLayer={selectedBubbleLayer}
            selectedDemographicLayer={selectedDemographicLayer}
            selectedState={selectedState}
            margin={{
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          />
        </div>
      </div>
      <div className={classes.racialBreakdownContainer}>
        <h4>Statewide Racial Breakdown of Cases, Deaths, and Vaccines</h4>
        <StoppedAlert type="DEMOGRAPHIC" className={classes.stoppedAlert} />
        <RacialBreakdownContainer />
        <div className={classes.source}>
          <strong>Data Sources: </strong>
          <a
            href="https://github.com/govex/COVID-19/tree/master/data_tables/demographic_data"
            target="_blank"
            rel="noopener noreferrer"
          >
            JHU CCI
          </a>
          . Data shown is only for cases, deaths, tests, and vaccines where
          racial data is reported.
          <br />
          <br />
          *State does not report racial breakdown of COVID-19 data.
        </div>
      </div>
    </div>
  );
};
export default DailyDataAndDemographics;
