import React, { useState, useEffect } from "react";
import styles from "./DropDownMenu.module.scss";
import cx from "classnames";
import { Link } from "react-router-dom";
import {
  states,
  countryMappings,
  slugify,
  partialMatch as match,
} from "data/geo";
import { ReactComponent as ChevronRight } from "images/icons/button/chevron-right.svg";

const DropDownMenu = ({ isOpen, query, queryThreshold = 3, onSelect }) => {
  const matchStates = states.filter((name) => match(query, name));
  const matchCountries = countryMappings.filter((country) =>
    match(query, country.name)
  );

  const [index, setIndex] = useState(-1);
  useEffect(() => {
    if (!isOpen) return;

    const handleSelect = (index) => {
      const to = matchStates[index]
        ? "/region/us/" + slugify(matchStates[index])
        : matchCountries[index]
        ? "/region/" + slugify(matchCountries[index].name)
        : null;
      if (to) {
        onSelect(to);
      }
    };

    const handleKeyDown = (event) => {
      event = event || window.event;
      switch (event.keyCode) {
        case 40: // down
          if (index < matchStates.length + matchCountries.length) {
            const i = index + 1;
            setIndex(i);
            if (onSelect) {
              handleSelect(i);
            }
          }
          break;
        case 38: // up
          if (index > 0) {
            const i = index - 1;
            setIndex(i);
            if (onSelect) {
              handleSelect(i);
            }
          }
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, index, matchStates, matchCountries, onSelect]);

  return (
    <div className={cx(styles.dropMenu, isOpen && styles.open)}>
      {queryThreshold <= query.length && matchStates.length > 0 && (
        <>
          <div className={styles.heading}>United States</div>
          {matchStates.map((name, i) => (
            <Link
              key={i}
              className={cx(styles.item, i === index && styles.active)}
              to={"/region/us/" + slugify(name)}
              onMouseOver={() => {
                setIndex(i);
              }}
            >
              {name} <ChevronRight />
            </Link>
          ))}
        </>
      )}
      {queryThreshold <= query.length && matchCountries.length > 0 && (
        <>
          <div className={styles.heading}>World Countries</div>
          {matchCountries.map((country, i) => (
            <Link
              key={i}
              className={cx(
                styles.item,
                i === index - matchStates.length && styles.active
              )}
              to={"/region/" + slugify(country.name)}
              onMouseOver={() => {
                setIndex(i + matchStates.length);
              }}
            >
              {country.displayName} <ChevronRight />
            </Link>
          ))}
        </>
      )}
      {matchStates.length + matchCountries.length === 0 && (
        <p className={styles.empty}>
          We're sorry, no results were found. Please check your spelling and try
          again.
        </p>
      )}
      <Link to="/region" className={styles.showAll}>
        <div>
          <span className={styles.mdHide}>Show</span> All Regions
        </div>{" "}
        <ChevronRight />
      </Link>
    </div>
  );
};

export default DropDownMenu;
