import React from "react";
import ReactMarkdown from "react-markdown";

import styles from "./PhaseContent.module.scss";
import Icon from "../Icon";
import HFormat from "components/common/HFormat";
import ComparisonNote from "../ComparisonNote";

function PhaseContent({
  title,
  content,
  comparisonNote,
  icon,
  className,
  compare,
  ...props
}) {
  return (
    <div className={styles.base}>
      {!!icon && <Icon icon={icon} />}
      <HFormat size={3} noMargins>
        {title}
      </HFormat>
      <ReactMarkdown source={content} />
      {!!comparisonNote && (
        <ComparisonNote content={comparisonNote} compare={compare} />
      )}
    </div>
  );
}

export default PhaseContent;
