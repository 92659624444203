import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { HEADER_PAGE_NAVIGATOR_ID } from ".";

export const sectionsToOptions = (sections = []) =>
  sections.map((section) => {
    return {
      label: section.name,
      value: section.id,
      to: section.to,
    };
  });

export const getHeaderPageNavigator = () => {
  return document.getElementById(HEADER_PAGE_NAVIGATOR_ID);
};

export const findTargetPos = (obj) => {
  var curtop = 0;
  if (obj.offsetParent) {
    do {
      curtop += obj.offsetTop;
    } while ((obj = obj.offsetParent));
    return [curtop];
  }
};

export const useScrollLocationHash = ({
  targetHashOverride,
  isEnabled,
  scrollToProps = {
    top: 0,
    left: 0,
    behavior: "smooth",
  },
}) => {
  const location = useLocation();
  targetHashOverride = targetHashOverride ? targetHashOverride : location.hash;

  useEffect(() => {
    let mounted = true;

    if (mounted && isEnabled && targetHashOverride) {
      const scrollTargetEl = document.getElementById(targetHashOverride);
      const pos = scrollTargetEl ? findTargetPos(scrollTargetEl) - 160 : 0;
      window.scrollTo({
        ...scrollToProps,
        top: pos,
      });
    }
    return () => {
      mounted = false;
    };
  }, [isEnabled, targetHashOverride, scrollToProps]);

  return {};
};
