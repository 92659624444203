import React, { useEffect } from "react";

import * as GTM from "util/gtm";
import styles from "./DataCenterHome.module.scss";
import { getSnapshotForRoute } from "data/util";
import { getCards } from "data/data-viz";
import { mapChartsToCards } from "components/layout/ArticleCard/utils";
import Page from "components/layout/Page";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import NewsFeed from "components/content/News/NewsFeed";

const DataCenterHome = (props) => {
  // const page = getPage(props.location.pathname);
  // const { content_blocks } = page;
  // const chartsBlock = content_blocks["critical-trends"];

  const cards = getCards({ section: "Trends" });
  const articles = mapChartsToCards(cards).map((card, i) => ({
    ...card,
    containImage: i === 0 ? true : card.containImage,
    imageSrc: card.imageSrc || getSnapshotForRoute(card.href, true),
  }));

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
  }, []);

  return (
    <Page>
      <div className={styles.container}>
        <ArticleGridLayouts.OffCenterList articles={articles.slice(0, 4)} />
        <br />
        <ArticleGridLayouts.Flexible articles={articles.slice(4)} />
        <br />
        <hr />
        <NewsFeed scopeVerticals="tracking" chooseVerticals={false} />
      </div>
    </Page>
  );
};

export default DataCenterHome;
