import React, { useEffect } from "react";
import * as GTM from "util/gtm";
import EmbedFrame from "components/common/EmbedFrame";
import { getPage } from "data/util";
import styles from "./InternalPositivity.module.scss";
import Page from "components/layout/Page";

const InternalPositivity = ({ location }) => {
  const pageData = getPage(location.pathname);

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-positivity",
    });
  }, []);

  return (
    <Page page={pageData}>
      <div className={styles.container}>
          <EmbedFrame
            src="/embed/testing-positivity-types/allPosTypes.html"
            width={720}
            height={400}
          />
      </div>
    </Page>
  );
};

export default InternalPositivity;
