import React from 'react';
import {useSelector} from 'react-redux';
import {BASE_STATE_TIMELINE_ROUTE, DEFAULT_EVENT_ID} from '../../../constants';
import {useHistory, useParams} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {COLORS, DEFAULT_STATE_NAME, nameAccessor, valueAccessor} from '../../../../Shared/constants';

export const buttonStyle = {
    fontSize: '15px',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: `1px solid ${COLORS.grayLight}`,
    padding: '6.5px 10px',
    color: COLORS.gray,
    cursor: 'pointer',
    transition: '0.2s background-color, 0.2s border-color, 0.2s color',
    '&:hover': {
        backgroundColor: COLORS.grayLighter,
        color: COLORS.blueDark
    },
    '&:first-child': {
        borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
        borderRadius: '0 5px 5px 0',
    },
    '&:focus': {
        outline: 'none'
    }
};

const useStyles = makeStyles(() => ({
    button: {
        ...buttonStyle,
        '&:first-child': {
            borderRadius: '5px 0 0 5px',
        },
    },
    buttonSelected: {
        ...buttonStyle,
        color: COLORS.blueDark,
        borderColor: COLORS.blueDark
    }
}));

const DatasetToggle = ({selectedDataset}) => {
    const {stateName = DEFAULT_STATE_NAME, eventId = DEFAULT_EVENT_ID} = useParams();
    const datasets = useSelector(state => state.stateTimeline.datasets);
    const history = useHistory();
    const routeToSelectedDataset = id => history.push(`${BASE_STATE_TIMELINE_ROUTE}/${id}/${stateName}/${eventId}`, {noScroll: true});
    const classes = useStyles();

    return (
        <div className="dataset__toggle">
            {datasets.map(dataset => {
                return (
                    <button
                        type="button"
                        key={`button-for-${valueAccessor(dataset)}`}
                        className={(valueAccessor(dataset) === valueAccessor(selectedDataset) ? classes.buttonSelected : classes.button)}
                        onClick={() => routeToSelectedDataset(valueAccessor(dataset))}
                    >
                        {nameAccessor(dataset)}
                    </button>
                );
            })}
        </div>
    );
};
DatasetToggle.defaultProps = {
    selectedDataset: {},
};
export default DatasetToggle;
