import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import cx from "classnames";

import styles from "./SquareMap.module.scss";

import usStates from "data/geo/us-states.json";
import rectData from "./SquareMapData.json";

const StateRect = ({ code, colorData, onClick }) => {
  const d = rectData[code];
  const c = colorData?.[code] ?? {};

  return (
    <rect
      x={d.x}
      y={d.y}
      width={d.width}
      height={d.height}
      className={cx(styles.shape, c.url && styles.hasLink)}
      style={{
        fill: c.color ?? "",
        cursor: c.url ? "pointer" : "",
      }}
      onClick={() => {
        onClick(code);
      }}
    />
  );
};

const SquareMap = ({ colorData, width, height, className }) => {
  const [redirect, setRedirect] = useState("");
  const handleClick = (state) => {
    function swap(json) {
      const ret = {};
      for (const key in json) {
        ret[json[key]] = key;
      }
      return ret;
    }
    const statesLookup = swap(usStates);
    const href = colorData?.[statesLookup[state]]?.url;
    if (href) {
      if (href.indexOf("http") === 0) {
        window.open(href, "_blank");
      } else {
        setRedirect(href);
      }
    }
  };

  return (
    <>
      {redirect && <Redirect to={redirect} push />}
      <svg
        width={width}
        height={height}
        viewBox="0 0 900 650"
        className={cx(styles.base, className)}
      >
        <g>
          {Object.keys(rectData).map((code) => (
            <StateRect
              code={code}
              colorData={colorData}
              onClick={handleClick}
            />
          ))}
          <polygon
            points="582.7,284.4 651,284.4 651,227.6 582.7,227.6"
            className={cx(
              styles.shape,
              colorData?.["PA"].url && styles.hasLink
            )}
            style={{
              fill: colorData ? colorData["PA"].color : "",
              cursor: colorData?.["PA"].url ? "pointer" : "",
            }}
            onClick={() => {
              handleClick("PA");
            }}
          />
          <polygon
            points="582.7,448.4 651,448.4 651,391.6 582.7,391.6"
            className={cx(
              styles.shape,
              colorData?.["SC"].url && styles.hasLink
            )}
            style={{
              fill: colorData ? colorData["SC"].color : "",
              cursor: colorData?.["SC"].url ? "pointer" : "",
            }}
            onClick={() => {
              handleClick("SC");
            }}
          />
          <polygon
            points="582.7,366.4 651,366.4 651,309.6 582.7,309.6"
            className={cx(
              styles.shape,
              colorData?.["VA"].url && styles.hasLink
            )}
            style={{
              fill: colorData ? colorData["VA"].color : "",
              cursor: colorData?.["VA"].url ? "pointer" : "",
            }}
            onClick={() => {
              handleClick("VA");
            }}
          />
          <polygon
            points="582.7,530.4 651,530.4 651,473.6 582.7,473.6"
            className={cx(
              styles.shape,
              colorData?.["GA"].url && styles.hasLink
            )}
            style={{
              fill: colorData ? colorData["GA"].color : "",
              cursor: colorData?.["GA"].url ? "pointer" : "",
            }}
            onClick={() => {
              handleClick("GA");
            }}
          />
        </g>
        <g>
          <text transform="matrix(1 0 0 1 53.5239 47)" className={styles.label}>
            AK
          </text>
          <text
            transform="matrix(1 0 0 1 451.5551 47)"
            className={styles.label}
          >
            WI
          </text>
          <text
            transform="matrix(1 0 0 1 688.2854 47)"
            className={styles.label}
          >
            VT
          </text>
          <text
            transform="matrix(1 0 0 1 765.2139 47)"
            className={styles.label}
          >
            NH
          </text>
          <text
            transform="matrix(1 0 0 1 846.9814 47)"
            className={styles.label}
          >
            ME
          </text>
          <text
            transform="matrix(1 0 0 1 51.7224 142)"
            className={styles.label}
          >
            WA
          </text>
          <text
            transform="matrix(1 0 0 1 136.5823 142)"
            className={styles.label}
          >
            ID
          </text>
          <text
            transform="matrix(1 0 0 1 213.5421 142)"
            className={styles.label}
          >
            MT
          </text>
          <text
            transform="matrix(1 0 0 1 292.3594 142)"
            className={styles.label}
          >
            ND
          </text>
          <text
            transform="matrix(1 0 0 1 369.815 142)"
            className={styles.label}
          >
            MN
          </text>
          <text
            transform="matrix(1 0 0 1 453.9509 142)"
            className={styles.label}
          >
            IL
          </text>
          <text
            transform="matrix(1 0 0 1 530.0378 142)"
            className={styles.label}
          >
            MI
          </text>
          <text
            transform="matrix(1 0 0 1 687.7097 142)"
            className={styles.label}
          >
            NY
          </text>
          <text
            transform="matrix(1 0 0 1 764.5342 142)"
            className={styles.label}
          >
            MA
          </text>
          <text
            transform="matrix(1 0 0 1 53.2554 224)"
            className={styles.label}
          >
            OR
          </text>
          <text
            transform="matrix(1 0 0 1 134.5584 224)"
            className={styles.label}
          >
            NV
          </text>
          <text
            transform="matrix(1 0 0 1 213.1077 224)"
            className={styles.label}
          >
            WY
          </text>
          <text
            transform="matrix(1 0 0 1 293.563 224)"
            className={styles.label}
          >
            SD
          </text>
          <text
            transform="matrix(1 0 0 1 373.8763 224)"
            className={styles.label}
          >
            IA
          </text>
          <text
            transform="matrix(1 0 0 1 452.1571 224)"
            className={styles.label}
          >
            IN
          </text>
          <text
            transform="matrix(1 0 0 1 528.6417 224)"
            className={styles.label}
          >
            OH
          </text>
          <text
            transform="matrix(1 0 0 1 608.9851 224)"
            className={styles.label}
          >
            PA
          </text>
          <text
            transform="matrix(1 0 0 1 688.782 224)"
            className={styles.label}
          >
            NJ
          </text>
          <text
            transform="matrix(1 0 0 1 767.6716 224)"
            className={styles.label}
          >
            CT
          </text>
          <text
            transform="matrix(1 0 0 1 850.0503 224)"
            className={styles.label}
          >
            RI
          </text>
          <text
            transform="matrix(1 0 0 1 53.6808 306)"
            className={styles.label}
          >
            CA
          </text>
          <text
            transform="matrix(1 0 0 1 134.9781 306)"
            className={styles.label}
          >
            UT
          </text>
          <text
            transform="matrix(1 0 0 1 213.3895 306)"
            className={styles.label}
          >
            CO
          </text>
          <text
            transform="matrix(1 0 0 1 293.0835 306)"
            className={styles.label}
          >
            NE
          </text>
          <text
            transform="matrix(1 0 0 1 370.2429 306)"
            className={styles.label}
          >
            MO
          </text>
          <text
            transform="matrix(1 0 0 1 451.5801 306)"
            className={styles.label}
          >
            KY
          </text>
          <text
            transform="matrix(1 0 0 1 527.9564 306)"
            className={styles.label}
          >
            WV
          </text>
          <text
            transform="matrix(1 0 0 1 608.8567 306)"
            className={styles.label}
          >
            VA
          </text>
          <text
            transform="matrix(1 0 0 1 686.2402 306)"
            className={styles.label}
          >
            MD
          </text>
          <text
            transform="matrix(1 0 0 1 767.5088 306)"
            className={styles.label}
          >
            DE
          </text>
          <text
            transform="matrix(1 0 0 1 135.3635 388)"
            className={styles.label}
          >
            AZ
          </text>
          <text
            transform="matrix(1 0 0 1 211.815 388)"
            className={styles.label}
          >
            NM
          </text>
          <text
            transform="matrix(1 0 0 1 294.0082 388)"
            className={styles.label}
          >
            KS
          </text>
          <text
            transform="matrix(1 0 0 1 372.5467 388)"
            className={styles.label}
          >
            AR
          </text>
          <text
            transform="matrix(1 0 0 1 450.6442 388)"
            className={styles.label}
          >
            TN
          </text>
          <text
            transform="matrix(1 0 0 1 528.9617 388)"
            className={styles.label}
          >
            NC
          </text>
          <text
            transform="matrix(1 0 0 1 609.1653 388)"
            className={styles.label}
          >
            SC
          </text>
          <text
            transform="matrix(1 0 0 1 687.3867 388)"
            className={styles.label}
          >
            DC
          </text>
          <text
            transform="matrix(1 0 0 1 292.2325 470)"
            className={styles.label}
          >
            OK
          </text>
          <text
            transform="matrix(1 0 0 1 372.4462 470)"
            className={styles.label}
          >
            LA
          </text>
          <text
            transform="matrix(1 0 0 1 450.0356 470)"
            className={styles.label}
          >
            MS
          </text>
          <text
            transform="matrix(1 0 0 1 530.4463 470)"
            className={styles.label}
          >
            AL
          </text>
          <text
            transform="matrix(1 0 0 1 608.5007 470)"
            className={styles.label}
          >
            GA
          </text>
          <text
            transform="matrix(1 0 0 1 55.4368 552)"
            className={styles.label}
          >
            HI
          </text>
          <text
            transform="matrix(1 0 0 1 294.1855 552)"
            className={styles.label}
          >
            TX
          </text>
          <text
            transform="matrix(1 0 0 1 689.6311 552)"
            className={styles.label}
          >
            FL
          </text>
          <text
            transform="matrix(1 0 0 1 848.5801 552)"
            className={styles.label}
          >
            PR
          </text>
        </g>
      </svg>
    </>
  );
};

export default SquareMap;
