import React from "react";
import styles from "./Contributors.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as ChevronRightIcon } from "images/icons/button/chevron-right.svg";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

const contributors_A: { label: string; href: string }[] = [
  {
    label: "Bloomberg Center for Government Excellence",
    href: "https://govex.jhu.edu/",
  },
  { label: "JHU Sheridan Libraries", href: "https://www.library.jhu.edu/" },
  { label: "JHU Applied Physics Lab", href: "https://www.jhuapl.edu/" },
  {
    label: "The Center for Health Security at BSPH",
    href: "https://www.centerforhealthsecurity.org/",
  },
  {
    label: "The Center for Systems Science and Engineering at JHU",
    href: "https://systems.jhu.edu/",
  },
  {
    label: "International Vaccine Access Center at BSPH",
    href: "https://www.jhsph.edu/ivac/",
  },
];

const contributors_B: { label: string; href: string }[] = [
  { label: "Bloomberg Philanthropies", href: "https://www.bloomberg.org/" },
  { label: "Stavros Niarchos Foundation", href: "https://www.snf.org/" },
];

const contributors_C: { label: string; href: string }[] = [
  { label: "ESRI", href: "https://www.esri.com/en-us/home" },
  { label: "FGS Global", href: "https://fgsglobal.com/" },
  { label: "Graphicacy", href: "https://graphicacy.com/" },
  { label: "National Public Radio", href: "https://www.npr.org/" },
];

type TContributorsProps = {
  className?: string;
};

const Contributors = ({ className }: TContributorsProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div>
        <h2 className={styles.header}>
          THANK YOU TO ALL CONTRIBUTORS TO THE JHU CRC TEAM
        </h2>
        <div className={cx(styles.grid, styles.top)}>
          {contributors_A.length > 0 &&
            contributors_A.map((item, i) => (
              <div key={i} className={styles.contributorBlock}>
                <Button
                  className={styles.button}
                  href={item.href}
                  targetBlank={true}
                >
                  {item.label}
                  <ChevronRightIcon className={styles.chevronRight} />
                </Button>
              </div>
            ))}
        </div>
      </div>
      <div>
        <h2 className={styles.header}>Thank you to our partners</h2>
        <div className={styles.grid}>
          {contributors_C.length > 0 &&
            contributors_C.map((item, i) => (
              <div key={i} className={styles.contributorBlock}>
                <Button
                  className={styles.button}
                  href={item.href}
                  targetBlank={true}
                >
                  {item.label}
                  <ChevronRightIcon className={styles.chevronRight} />
                </Button>
              </div>
            ))}
        </div>
      </div>

      <div>
        <h2 className={styles.header}>Thank you to our funders</h2>
        <div className={styles.grid}>
          {contributors_B.length > 0 &&
            contributors_B.map((item, i) => (
              <div key={i} className={styles.contributorBlock}>
                <Button
                  className={styles.button}
                  href={item.href}
                  targetBlank={true}
                >
                  {item.label}
                  <ChevronRightIcon className={styles.chevronRight} />
                </Button>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Contributors;
