import React, { useEffect, useRef } from "react";
import { select } from "d3-selection";
import { axisLeft, axisTop } from "d3-axis";
import { format } from "d3-format";
import styles from "./VaccineDataAxes.module.scss";

const Axes = ({
  height,
  width,
  data,
  datakey,
  fieldkey,
  xScale = null,
  yScale = null,
  xScaleTickFormat = null,
  yScaleTickFormat = null,
  xTicks = null,
  yTicks = null
}) => {
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);

  useEffect(() => {
    const renderYAxis = () => {
      const yAxis = select(yAxisRef.current);
      yAxis
        .call(
          axisLeft(yScale)
            .ticks(yTicks)
            .tickFormat(yScaleTickFormat)
        )
        .call((g) =>
          g
            .selectAll(".tick text")
            .attr("x", 0)
            .attr("dx", "-15px")
            .attr("dy", "5px")
            .attr("fill", "#000")
        );

      yAxis.select(".domain").remove();
      yAxis.selectAll(".tick line").remove();
    };

    const renderXAxis = () => {
      const xAxis = select(xAxisRef.current);
      const _xScaleTickFormat = xScaleTickFormat
        ? format(xScaleTickFormat)
        : xScaleTickFormat;
      xAxis.call(axisTop(xScale).ticks(xTicks).tickFormat(_xScaleTickFormat));

      xAxis.select(".domain").remove();
      xAxis.selectAll(".tick line").remove();
    };

    if (xScale) {
      renderXAxis();
    }
    if (yScale) {
      renderYAxis();
    }
  }, [xScale, yScale, xScaleTickFormat, yScaleTickFormat, xTicks, yTicks, data, datakey, fieldkey]);

  return (
    <g className="axis">
      {xScale && <g className={styles.xAxis} ref={xAxisRef} />}
      {yScale && <g className={styles.yAxis} ref={yAxisRef} />}
    </g>
  );
};

// const defaultProps = {
//     xScale: null,
//     yScale: null,
//     height: 0,
//     width: 0,
//     xScaleTickFormat: null,
//     yScaleTickFormat: null,
//     xTicks: null,
//     yTicks: null,
//     threshold: null,
//     data: null,
//     datakey: null,
// };
//
// Axes.defaultProps = defaultProps;

export default Axes;
