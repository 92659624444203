import React from "react";
import TFormat from "components/common/TFormat";

const TestingPer100kTable = ({ data }) => {
  return (
      <TFormat
        tableData={data}
        tableColumns={{
          regionName: "Country / Region",
          percPositive: "Daily Positivity",
          confirmed: "Daily Confirmed Cases",
        }}
        rowLimit={15}
       />
  );
};

export default TestingPer100kTable;
