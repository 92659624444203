import React from 'react';
import Cell from './Cell';
import HiddenCell from './HiddenCell';
import Grid from '@material-ui/core/Grid';

const Row = ({ row, i, datakey, color, threshold, field, cellSize }) => {
  // const handleFill = (d) => {
  //   let datapoint = filteredData.filter(p => p[dataSet.key] === d.properties.name)[0];
  //   if (datapoint === undefined){
  //       return 'url("#diagonalHatch")';
  //   } else {
  //     if (datapoint.data[field.value] === "Not Available") {
  //       return 'url("#diagonalHatch")';
  //     } else {
  //       return color(datapoint.data[field.value])
  //     }
  //   }
  // }

  return (
    <Grid container item xs={12} spacing={3}>
      {row.map((cell, j) => {
        const item = {
          ...cell,
          style: {
            width: cellSize,
            height: cellSize,
            textAlign: 'center',
            backgroundColor: cell.value ? color(cell.value) : 'none',
          },
          spanStyle: {
            color: cell.value > threshold ? "#eee" : "black",
            fontSize: '80%',
            pointerEvents: 'none',
            userSelect: 'none'
          }
        };
        return cell.value ? (
          <Cell key={`row-${i}-cell-${j}`} cell={item} datakey={datakey} field={field} />
        ) : (
          <HiddenCell key={`row-${i}-cell-${j}`} cell={item} />
        );
      })}
    </Grid>
  );
};
Row.defaultProps = {
  row: [],
  i: 0,
  selectedDataSet: {},
  cellSize: 30,
  color: null
};
export default Row;
