import React from "react";
import styles from "./LoadingVisual.module.scss";
import cx from "classnames";

type TLoadingVisualProps = {
  className?: string;
};

const LoadingVisual = ({ className }: TLoadingVisualProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.spinner}>
        {new Array(12).fill(null).map((_, index) => (
          <div key={index}></div>
        ))}
      </div>
    </div>
  );
};

export default LoadingVisual;
