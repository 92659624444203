import React from "react";
import styles from "./SunsetHomepage.module.scss";
import classNames from "classnames/bind";
import { CRCNowSection } from "../CRCNow/CRCNow";
import AccessingPastData from "../AccessingPastData/AccessingPastData";
import SectionContent from "../SectionContent/SectionContent";
import DataBannerContainer from "../DataBannerContainer/DataBannerContainer";
import Contributors from "../Contributors/Contributors";
import SpecialThanks from "../SpecialThanks/SpecialThanks";
import GreatestHits from "../GreatestHits/GreatestHits";
const cx = classNames.bind(styles);

const SunsetHomepage = () => {
  return (
    <div className={cx(styles.base)}>
      <CRCNowSection image="/images/sunset/crc-now.svg" />
      <AccessingPastData />

      <div className={styles.container}>
        <SectionContent title={"Government Resources"} />
        <DataBannerContainer />
        <br />
        <br />
        <br />
        <Contributors />
        <br />
        <br />
        <SpecialThanks />
        <GreatestHits />
        <br />
      </div>
    </div>
  );
};

export default SunsetHomepage;
