import React from "react";
import styles from "./PDINews.module.scss";
import ArticleFeed from "components/content/Vaccine/ArticleFeed";

const PDINews = ({ articles }) => (
  <div className={styles.container}>
    <ArticleFeed
      articles={articles}
      limit={8}
      blade={false}
      hasMajor={false}
      columnLayouts={{
        default: [[12]] as any,
        topCards: null,
      }}
      defaultCardProps={
        ({
          vertical: true,
          containImage: false,
          major: false,
          imageSrc: null,
        } as unknown) as any
      }
    />
  </div>
);

export default PDINews;
