import React, { useState } from 'react';
import PropTypes from "prop-types";
import { line as d3_line } from 'd3-shape';
import Line from './Line';
import { scaleLinear, scaleTime } from 'd3-scale';
import { extent, max } from 'd3-array';
import Bar from './Bar';
import Axes from './Axes';
import {
    AXES_MOMENT_DATE_FORMAT,
    BAR_CHART_COLORS,
    dateAccessor,
    RADIUS
} from '../constants';
import {
    valueAccessor
} from 'components/content/DataCenter/charts/Shared/constants';
import EventMarker from './EventMarker';
import TooltipLineAndMarker from './TooltipLineAndMarker';
import HoverBar from "./HoverBar";
import { format } from "d3-format";

const BarChart = ({ data, events, selectedEvent, selectedDataset, innerHeight, innerWidth, offset, xScale, backgroundXScale, xScaleRange }) => {
    const [activeEvent, setActiveEvent] = useState(null);

    // Calculate local scales to take into account the range changes
    const localXScale = xScale.copy()
        .range(xScaleRange);

    // Calculate a local background scale for hover events
    const localBackgroundXScale = backgroundXScale.copy()
        .range(xScaleRange);

    // Calculate a time scale for better axis label placement
    const xAxisScale = scaleTime()
        .range(localXScale.range())
        .domain(extent(data, dateAccessor));

    // Get the max value of the average (line) and the value (bars)
    const maxYValue = Math.max(
        // max(data, d => d.value),
        max(data, d => d.average),
    );

    // Calculate a y-scale
    const yScale = scaleLinear()
        .range([innerHeight, 0])
        .domain([0, maxYValue]);
    const line = d3_line()
        .x(d => localXScale(dateAccessor(d)) + localXScale.bandwidth() / 2)
        .y(d => yScale(d.average));

    // Get the color object, based on selected dataset
    const color = BAR_CHART_COLORS[valueAccessor(selectedDataset)];

    return (
        <g className="timeline__visualization">
            <Axes
                xScale={xAxisScale}
                yScale={yScale}
                height={innerHeight}
                width={innerWidth}
                offset={0}
                selectedDataset={selectedDataset}
                xScaleTickFormat={AXES_MOMENT_DATE_FORMAT}
                yScaleTickFormat={format('~s')}
            />
            <g className="bar__chart" clipPath="url(#bar-chart-clip)">
                <g className="bars__group">
                    {data.map(d => {
                        return (
                            <Bar
                                key={`bar-for-${dateAccessor(d)}`}
                                x={localXScale(dateAccessor(d))}
                                height={Math.abs(yScale(0) - yScale(d.value))}
                                y={yScale(Math.max(d.value, 0))}
                                width={localXScale.bandwidth()}
                                fill={color.bar}
                            />
                        );
                    })}
                </g>
                <Line d={line(data)} stroke={color.line} />
                <g className="hover--bars__group">
                    {data.map(d => {
                        return (
                            <HoverBar
                                item={d}
                                key={`hover-bar-for-${dateAccessor(d)}`}
                                x={localBackgroundXScale(dateAccessor(d))}
                                height={Math.abs(yScale(0) - yScale(d.value))}
                                y={yScale(Math.max(d.value, 0))}
                                width={localBackgroundXScale.bandwidth()}
                                setActiveEvent={setActiveEvent}
                            />
                        );
                    })}
                </g>
            </g>
            <g className="events__timeline" transform={`translate(0,${-offset})`} clipPath="url(#bar-chart-clip)">
                {events.map(event => {
                    return (
                        <EventMarker
                            key={`event-for-${dateAccessor(event).format()}`}
                            x={localXScale(dateAccessor(event)) + localXScale.bandwidth() / 2}
                            event={event}
                            height={innerHeight + offset}
                            r={RADIUS}
                        />
                    );
                })}
            </g>
            <g className="timeline__tooltip__container">
                <TooltipLineAndMarker
                    activeEvent={activeEvent}
                    selectedEvent={selectedEvent}
                    offset={offset - RADIUS}
                    xScale={localXScale}
                    yScale={yScale}
                    data={data}
                    innerWidth={innerWidth}
                    r={RADIUS}
                    selectedDataset={selectedDataset}
                    markerFill={color.line}
                    height={innerHeight}
                />
            </g>
        </g>
    );
};

BarChart.propTypes = {
    data: PropTypes.array,
    events: PropTypes.array,
    selectedEvent: PropTypes.any,
    selectedDataset: PropTypes.any,
    innerHeight: PropTypes.number,
    innerWidth: PropTypes.number,
    offset: PropTypes.number,
    xScale: PropTypes.func,
    backgroundXScale: PropTypes.func,
    xScaleRange: PropTypes.array,
};

export default BarChart;
