import React from "react";
import styles from "./ScrollIcon.module.scss";
import cx from "classnames";

const ScrollIcon = ({ className }) => (
  <div className={cx(styles.base, className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="table__scroll_indicator"
      viewBox="0 0 57 25"
    >
      <g fill="#3767AB" fillRule="evenodd">
        <path
          d="M27.2 1a2.8 2.8 0 00-2.7 2.8V13l-.8-.8a2.7 2.7 0 00-3.9 0c-.5.6-.8 1.3-.8 2 0 .8.3 1.5.8 2l5.8 5.9c1.2 1.3 3 2 4.7 2h1c3.7 0 6.7-3.1 6.7-6.9v-5.4c0-1.5-1.2-2.8-2.7-2.8-.6 0-1.2.2-1.6.5A2.7 2.7 0 0031.3 8c-.5 0-1 .1-1.3.3V3.8C30 2.3 28.8 1 27.3 1zm4 8.4c.7 0 1.3.6 1.3 1.3v1.8c0 .4.3.8.7.8.5 0 .8-.4.8-.8v-.8c0-.7.6-1.2 1.2-1.2.7 0 1.3.5 1.3 1.2v5.4c0 3-2.4 5.4-5.3 5.4h-.9c-1.4 0-2.7-.6-3.7-1.6L21 15c-.3-.2-.4-.5-.4-.9 0-.3.1-.7.4-.9a1.2 1.2 0 011.7 0l1.9 2v1.1c0 .5.3.8.7.8.5 0 .8-.3.8-.8V3.8c0-.7.6-1.3 1.2-1.3.7 0 1.3.6 1.3 1.3v8.7c0 .4.3.8.7.8.5 0 .8-.4.8-.8v-1.8c0-.7.6-1.3 1.2-1.3z"
          fillRule="nonzero"
        />
        <path d="M50.7 6.7l5.7 5.6v.4l-5.7 5.6-.7-.7 4.6-4.6H45v-1h9.6L50 7.4zM6.7 6.7L1 12.3v.4l5.7 5.6.7-.7L2.7 13h9.7v-1H2.7l4.7-4.6z" />
      </g>
    </svg>
  </div>
);

export default ScrollIcon;
