import { createSelector } from 'reselect';
import { IS_SELECTED } from '../../constants';

const regionsSelector = state => state.testingTracker.regions;

const selectedRegionSelector = createSelector([regionsSelector], regions => {
  return regions.find(region => region[IS_SELECTED]);
});

export default selectedRegionSelector;
