import { slugify } from "data/geo";
import React from "react";
import THead from "../THead";
import TRow from "../TRow";
import styles from "./TRender.module.scss";

type TTRenderProps = {
  items: Array<any>;
  refs: {
    tHeadRef: any;
    tHeadMobileRef: any;
    tFirstColumnRef: any;
    tBodyRef: any;
  };

  tHeadHeight: number;
  limit: number;
  tBodyId: string;
  tBodyMobileId: string;
  useMobileColumn: boolean;

  tableColumns: any;
  rowHeight: number;
  isInRegionList: (slug: string) => boolean;
  isInRegionNoLinkList: (slug: string) => boolean;
  addTDIfPresent: (item: any, key: string) => any;
  requestSort: any;
  getClassNamesFor: any;

  path?: string;
  pageSection?: string;
  isMobile?: boolean;
  mobileColumn?: boolean;
  useRegionCodes?: boolean;
  useRegionCodeType?: string;
  useNameShortening?: any;
  regionNameLinkStyle?: string;

  activeRow?: {
    key: string;
    value: string;
  };
  classNames?: {
    th?: string;
    tr?: string;
  };
};

const TRender = ({
  items,
  refs,
  tHeadHeight,
  limit,
  tBodyId,
  tBodyMobileId,
  useMobileColumn,
  mobileColumn,
  requestSort,
  getClassNamesFor,
  tableColumns,
  path,
  pageSection,
  useRegionCodes,
  useRegionCodeType,
  isMobile,
  useNameShortening,
  isInRegionList,
  isInRegionNoLinkList,
  addTDIfPresent,
  regionNameLinkStyle,
  rowHeight,
  activeRow,
  classNames,
}: TTRenderProps) => {
  const { tHeadRef, tHeadMobileRef, tBodyRef, tFirstColumnRef } = refs;

  const defaultTHProps = mobileColumn ? {} : { ref: tHeadRef };
  const moblieTRProps = mobileColumn
    ? {
        ref: tHeadMobileRef,
        style: { height: `${tHeadHeight}px` },
      }
    : {};
  const baseTableBodyProps = !mobileColumn
    ? {
        id: tBodyId,
        ref: tBodyRef,
      }
    : {
        id: tBodyMobileId,
      };

  // If using mobile column over main table, remove everything from first column on main table
  const hideFirstCol = !mobileColumn && useMobileColumn;

  return (
    <table className={mobileColumn ? null : styles.tableBase}>
      <thead {...defaultTHProps}>
        <tr {...moblieTRProps}>
          <THead
            className={classNames?.th}
            tFirstColumnRef={tFirstColumnRef}
            tableColumns={tableColumns}
            mobileColumn={mobileColumn}
            useRegionCodes={useRegionCodes}
            hideFirstCol={hideFirstCol}
            requestSort={requestSort}
            getClassNamesFor={getClassNamesFor}
          />
        </tr>
      </thead>
      <tbody {...baseTableBodyProps}>
        {items.slice(0, limit).map((item, i) => {
          const isActive =
            activeRow &&
            slugify(item[activeRow.key]?.toLowerCase()) ===
              activeRow.value?.toLowerCase();
          return (
            <TRow
              className={classNames?.tr}
              key={`${i}-table-row`}
              item={item}
              highlight={isActive}
              tableColumns={tableColumns}
              hideFirstCol={hideFirstCol}
              path={path}
              pageSection={pageSection}
              useRegionCodes={useRegionCodes}
              useRegionCodeType={useRegionCodeType}
              mobileColumn={mobileColumn}
              isMobile={isMobile}
              useNameShortening={useNameShortening}
              isInRegionList={isInRegionList}
              isInRegionNoLinkList={isInRegionNoLinkList}
              addTDIfPresent={addTDIfPresent}
              regionNameLinkStyle={regionNameLinkStyle}
              rowHeight={rowHeight}
            />
          );
        })}
      </tbody>
    </table>
  );
};

export default TRender;
