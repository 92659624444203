/* 
  Documentation:
  https://www.npmjs.com/package/react-share
*/

import React from "react";
import { useRouteMatch } from "react-router-dom";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";

import styles from "./Share.module.scss";

type TShareProps = {
  label?: string;
  classNames?: boolean;
};

const Share = ({ label = "Share This Page: ", classNames }: TShareProps) => {
  const match = useRouteMatch();
  const url = `https://coronavirus.jhu.edu${match.url}`;
  const iconProps = {
    size: 24,
    round: true,
  };

  const className = [styles.base, classNames].join(" ");

  return (
    <div className={className}>
      <span className={styles.label}>{label}</span>
      <FacebookShareButton url={url}>
        <FacebookIcon {...iconProps} />
      </FacebookShareButton>
      <TwitterShareButton url={url}>
        <TwitterIcon {...iconProps} />
      </TwitterShareButton>
      <LinkedinShareButton url={url}>
        <LinkedinIcon {...iconProps} />
      </LinkedinShareButton>
      <EmailShareButton url={url}>
        <EmailIcon {...iconProps} />
      </EmailShareButton>
    </div>
  );
};

export default Share;
