import React from "react";
import styles from "./TimelineHeader.module.scss";
import Icon from "../Icon";
import HFormat from "components/common/HFormat";
import ReactMarkdown from "react-markdown";

function TimelineHeader({ title, content, icon, light, className, ...props }) {
  return (
    <div className={`${styles.base} ${light ? styles.light : null}`}>
      <div className={styles.heading}>
        {!!icon && <Icon icon={icon} light={light} />}
        <HFormat size={2} noMargins>
          {title}
        </HFormat>
      </div>
      <div className={styles.headerContent}>
        <ReactMarkdown source={content} />
        {props.children}
      </div>
    </div>
  );
}

export default TimelineHeader;
