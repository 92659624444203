import React, { useState } from "react";
import PropTypes from "prop-types";
import TabBar from "components/layout/shell/TabBar";
import styles from "./VaccinesTableTab.module.scss";
import { dataSets } from "../constants";
import InternationalTable from "../../VaccineInternational/InternationalTable/index";
import useVaccineDataInternational from "../../VaccineInternational/use-vaccine-data";
import USStatesTable from "../../VaccineUSStates/USStatesTable";
import { TFormatCommonProps } from "components/common/TFormat/util";

const VaccinesTableTab = ({
  useTable = "default",
  limit = 18,
  tableProps = {
    regionNameLinkStyle: "button",
  },
}) => {
  const [dataSet, setDataSet] = useState(dataSets[0]);
  const [dataWorld, isLoadingWorld] = useVaccineDataInternational();

  if (isLoadingWorld) {
    return <div />;
  }

  return (
    <div className={styles.base}>
      <div className={styles.controls}>
        <TabBar
          tabs={dataSets.map((d) => d.name)}
          onChange={(selected) => {
            setDataSet(dataSets.find((d) => d.name === selected));
          }}
        />
        <br />
      </div>
      <div className={styles.content}>
        {dataSet.name === "International" ? (
          <InternationalTable
            data={dataWorld}
            className={styles.countryTable}
            {...tableProps}
            useTable={useTable}
            limit={limit}
          />
        ) : (
          <USStatesTable
            className={styles.stateTable}
            {...tableProps}
            useTable={useTable}
            limit={limit}
          />
        )}
      </div>
    </div>
  );
};

VaccinesTableTab.propTypes = {
  tableProps: PropTypes.shape(TFormatCommonProps),
};

export default VaccinesTableTab;
