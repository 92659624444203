import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

const useStyles = makeStyles(() => ({
  header: {
    fontSize: `12px`,
    color: "#588CC3",
    lineHeight: `14px`,
    textTransform: "uppercase",
    marginBottom: "3px",
  },
  subHeader: {
    fontSize: `16px`,
    color: "#0E3071",
    lineHeight: `18px`,
    textTransform: "uppercase",
    marginBottom: "7px",
  },
  description: {
    fontSize: `12px`,
    color: "#959595",
    lineHeight: `14px`,
  },
  childWrapper: {
    cursor: "pointer",
  },
}));

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#fff",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    textAlign: "left",
    padding: "8px",
  },
}))(Tooltip);

const HtmlTooltip = ({ approach, children }) => {
  const classes = useStyles();
  const { numerator, denominator, info } = approach;
  const [mouseOpen, setMouseOpen] = React.useState(false);
  const [focusOpen, setFocusOpen] = React.useState(false);

  return (
    <StyledTooltip
      placement="top"
      disableHoverListener
      disableTouchListener
      disableFocusListener
      open={mouseOpen || focusOpen}
      title={
        <React.Fragment>
          <div className={classes.header}>Positivity Calculation</div>
          <div className={classes.subHeader}>
            {`${numerator} /`}
            <br />
            {denominator}
          </div>
          <div className={classes.description}>{info}</div>
        </React.Fragment>
      }
    >
      <div
        onMouseEnter={() => setMouseOpen(true)}
        onMouseLeave={() => setMouseOpen(false)}
        onFocus={() => setFocusOpen(true)}
        onBlur={() => setFocusOpen(false)}
        className={classes.childWrapper}
      >
        {children}
      </div>
    </StyledTooltip>
  );
};
HtmlTooltip.defaultProps = {
  approach: {
    approachId: 1,
    info: " ",
  },
  children: <></>,
};
export default HtmlTooltip;
