import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as GTM from "util/gtm";
import ResearchArticle from "./ResearchArticle";
import ResearchPapersHome from "./ResearchPapersHome";

export const basePathResearchPapers = "/research-papers";

const ResearchPapers = () => {
  useEffect(() => {
    GTM.clear();
    GTM.push({ pageVerticals: [] });
  }, []);
  return (
    <Switch>
      <Route
        path={`${basePathResearchPapers}/:articleId`}
        component={ResearchArticle}
      />
      <Route
        path={`${basePathResearchPapers}`}
        component={ResearchPapersHome}
        exact
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default ResearchPapers;
