import React, { useContext } from "react";
import styles from "./StateReportingMapTooltip.module.scss";
import StoppedAlert from "components/common/StoppedAlert";

const TooltipContext = React.createContext(
  {
    state: {
      show: false,
      x: 0,
      y: 0,
      content: null,
      ttHeight: 0
    },
    dispatch: undefined
  });

const Tooltip = ({ width, selectedTab, stoppedDate }) => {
  const { state } = useContext(TooltipContext);

  return (
    <>
      {state.content && (
        <g
          transform={`translate(${state.x - (stoppedDate ? 40 : 0)}, ${state.y - (stoppedDate ? 40 : 0)})`}
          style={{
            visibility: state.show ? "visible" : "hidden",
            pointerEvents: "none",
          }}
        >
          <foreignObject width={stoppedDate ? "100%" : width} height={stoppedDate ? "100%" : state.ttHeight}>
            {stoppedDate ?
              <StoppedAlert className={styles.alert} state={state?.content?.props?.children?.[0].props.children} category={selectedTab} stoppedDate={stoppedDate} />
              :
              <div className={styles.tipBox} xmlns="http://www.w3.org/1999/xhtml">
                {state.content}
              </div>
            }
          </foreignObject>
        </g>
      )}
    </>
  );
};

export default Tooltip;
export { TooltipContext };
