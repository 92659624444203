import { createSelector } from 'reselect';
import selectedRegionSelector from './region.selector';
import { ALL_REGIONS_VISIBLE, STATE_NAME } from '../../constants';

const dataSelector = state => state.testingTracker.data;

const selectedDataSelector = (options = { region: null, stateName: null }) =>
  createSelector([dataSelector, selectedRegionSelector], (data, selectedRegion) => {
    // Handle screenshot piece when only want a single state to print
    if (options.stateName) {
      return data.filter(d => d[STATE_NAME] === options.stateName);
    } else {
      const region = options.region || selectedRegion;
      return region.id === ALL_REGIONS_VISIBLE ? data : data.filter(d => d.region === region.name);
    }
  });

export default selectedDataSelector;
