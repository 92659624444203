import React, { useEffect, useRef, useState } from "react";
import styles from "./TFormat.module.scss";
import PropTypes from "prop-types";
import { ReactComponent as ArrowIcon } from "images/icons/arrow-right.svg";
import useSortableData from "util/hooks/useSortableData";
import uuid from "uuid/v4";
import cx from "classnames";
import { isMobileSize } from "util/isMobile";
import ScrollIcon from "./ScrollIcon";
import { TFormatCommonProps } from "./util";
import TRender from "./TRender";

const TFormat = ({
  header,
  tableData,
  tableColumns,
  rowLimit = Infinity,
  hideViewAll = false,
  regionList,
  regionNoLinkList,
  path,
  pageSection = "",
  regionNameLinkStyle = null,
  mobileOptions = { useRegionCodeType: null, useNameShortening: false },
  customSortConfig = {},
  className = "",
  overflowClassName = "",
  hideButtonProps = { className: null },
  tdRenderCallback,
  activeRow,
}) => {
  const tHeadRef = useRef(null);
  const tHeadMobileRef = useRef(null);
  const tFirstColumnRef = useRef(null);
  const mobileContainerRef = useRef(null);
  const tBodyRef = useRef(null);
  const [tBodyMobileId] = useState("t-body-mobile" + uuid());
  const [tBodyId] = useState("t-body-" + uuid());

  const [tHeadHeight, setTHeadHeight] = useState(70);
  const [mobileFirstColumnWidth, setMobileFirstColumnWidth] = useState(0);
  const [rowHeight, setRowHeight] = useState(32);

  const [limit, setLimit] = useState(rowLimit || Infinity);
  const [isMobile, setIsMobile] = useState(isMobileSize());
  const useMobileColumn = isMobile && Object.keys(tableColumns)?.length > 2;
  const { useRegionCodeType, useNameShortening } = mobileOptions;
  const useRegionCodes =
    isMobile &&
    (useRegionCodeType === "state" || useRegionCodeType === "country");

  const { items, requestSort, sortConfig } = useSortableData(
    tableData,
    customSortConfig
  );

  const expandOrCollapse = limit < tableData.length;
  hideViewAll = hideViewAll || items?.length < rowLimit;

  const isInRegionList = (regionSlug) =>
    regionList ? Object.keys(regionList)?.indexOf(regionSlug) !== -1 : false;

  const isInRegionNoLinkList = (regionSlug) =>
    regionNoLinkList
      ? Object.keys(regionNoLinkList)?.indexOf(regionSlug) !== -1
      : false;

  const addTDIfPresent = (data, key) => {
    if (data) {
      return (
        <td key={key}>
          {tdRenderCallback ? tdRenderCallback({ data, key }) : data}
        </td>
      );
    }
  };

  const getClassNamesFor = (name) => {
    const inactive = "inactive";
    if (!sortConfig) return styles[inactive];
    const d =
      sortConfig.key === name ? styles[sortConfig.direction] : styles[inactive];
    return d;
  };

  // To DO: finish making row resizing dynamic
  useEffect(() => {
    if (tBodyRef?.current && !!tBodyId) {
      const tBodyMobileEl = document.getElementById(tBodyMobileId);
      const tBodyEl = document.getElementById(tBodyId);
      const useRowFirstCols =
        useMobileColumn && !!tBodyMobileEl
          ? tBodyMobileEl?.children
          : tBodyEl?.children;

      const tallestHeight = { height: null, element: null };

      for (let index = 0; index < useRowFirstCols.length; index++) {
        const tdFirst = useRowFirstCols[index].children?.[0];

        if (!tallestHeight.height || tallestHeight < tdFirst?.offsetHeight) {
          tallestHeight.height = tdFirst?.offsetHeight;
          tallestHeight.element = tdFirst;
        }
      }
      if (rowHeight < tallestHeight.height) {
        setRowHeight(tallestHeight.height + 8);
      }
    }
  }, [rowHeight, useMobileColumn, tBodyRef, tBodyId, tBodyMobileId]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && isMobile) {
      requestAnimationFrame(() => {
        // Sync the mobile tableheader height with the main table's
        if (tHeadRef?.current && tHeadMobileRef?.current) {
          if (
            tHeadMobileRef.current.offsetHeight !==
            tHeadRef.current.offsetHeight
          ) {
            setTHeadHeight(tHeadRef.current.offsetHeight);
          }
        }
      });
      requestAnimationFrame(() => {
        if (mobileContainerRef?.current && tFirstColumnRef?.current) {
          const mobileContainerWidth = mobileContainerRef.current.offsetWidth;
          const width = tFirstColumnRef.current.offsetWidth - 10;
          if (
            mobileFirstColumnWidth <= 0 ||
            mobileContainerWidth !== mobileFirstColumnWidth ||
            mobileContainerWidth !== width
          ) {
            setMobileFirstColumnWidth(
              width < mobileFirstColumnWidth ? mobileFirstColumnWidth : width
            );
          }
        }
      });
    }
    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line
  }, [isMobile]);

  useEffect(() => {
    const handleResize = () => {
      requestAnimationFrame(() => setIsMobile(isMobileSize()));
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tRenderProps = {
    refs: {
      tHeadRef,
      tHeadMobileRef,
      tBodyRef,
      tFirstColumnRef,
    },
    items,
    tHeadHeight,
    limit,
    tBodyId,
    tBodyMobileId,
    useMobileColumn,
    requestSort,
    getClassNamesFor,
    tableColumns,
    path,
    pageSection,
    useRegionCodes,
    useRegionCodeType,
    isMobile,
    useNameShortening,
    isInRegionList,
    isInRegionNoLinkList,
    addTDIfPresent,
    regionNameLinkStyle,
    rowHeight,
    activeRow,
  };

  return (
    <div className={cx(styles.main, className)}>
      {header}
      {useMobileColumn && <ScrollIcon />}
      <div className={styles.tableContainer}>
        {/* MOBILE ONLY  */}
        {useMobileColumn && (
          <div
            className={styles.mobileColumn}
            ref={mobileContainerRef}
            style={{ width: `${mobileFirstColumnWidth}px` }}
          >
            <TRender {...tRenderProps} mobileColumn={true} />
          </div>
        )}
        {/* FULL TABLE */}
        <div
          className={cx(styles.tableOverflow, overflowClassName, {
            [styles["useMobileColumn"]]: useMobileColumn,
          })}
        >
          <TRender {...tRenderProps} mobileColumn={false} />
        </div>
      </div>

      {hideViewAll || (rowLimit && rowLimit === Infinity) ? null : (
        <div className={styles.buttonContainer}>
          <button
            className={cx(
              styles[expandOrCollapse ? "expand-button" : "collapse-button"],
              hideButtonProps.className
            )}
            type="button"
            onClick={() =>
              setLimit(expandOrCollapse ? Infinity : rowLimit || 10)
            }
          >
            <React.Fragment>
              {expandOrCollapse ? "View All" : "View Less"} <ArrowIcon />
            </React.Fragment>
          </button>
        </div>
      )}
    </div>
  );
};

TFormat.propTypes = {
  ...TFormatCommonProps,

  header: PropTypes.node,
  tableColumns: PropTypes.shape({
    regionName: PropTypes.string.isRequired,
    "7-day_avg": PropTypes.string,
    count_increase: PropTypes.string,
    percent_increase: PropTypes.string,
  }).isRequired,

  tableData: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      "7-day_avg": PropTypes.string,
      count_increase: PropTypes.string,
      percent_increase: PropTypes.string,
      tests: PropTypes.string,
    })
  ).isRequired,

  regionList: PropTypes.object,
  pageSection: PropTypes.string,
  path: PropTypes.string,
  tdRenderCallback: PropTypes.func,

  activeRow: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
};

export default TFormat;
