import React from "react";
import styles from "./PDIArticle.module.scss";
import Blog from "components/layout/Blog";
import { getArticleBySlug } from "data/util";
import { Redirect } from "react-router-dom";
import { basePathPDI } from "..";

export const pdiNewsSectionURL = {
  "Pandemic Data Outlook": "data-outlook",
  "Expert Insight": "expert-insight",
};

const PDIArticle = (props) => {
  const { match } = props;
  const article = getArticleBySlug(match.params.articleId);

  if (!article?.slug) return <Redirect to={basePathPDI} />;

  return (
    <div className={styles.container}>
      <Blog article={article} {...props} />
    </div>
  );
};

export default PDIArticle;
