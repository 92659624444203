import React, { useEffect } from "react";
import { Switch, Route, Link } from "react-router-dom";

import * as GTM from "util/gtm";
import KitchenSink from "./KitchenSink";
import ArticleCardGuide from "./ArticleCard";
import ArticleGridGuide from "./ArticleGrid";
import InteriorPageHeaderGuide from "./InteriorPageHeader";
import BGFormatGuide from "./BGFormat";
import TeleworkabilityVisualization from "./TeleworkabilityVisualization";
import Charts from "./Charts";

import classNames from "classnames/bind";
import styles from "./StyleGuide.module.scss";
import CustomSelectStyleGuide from "./CustomSelect";
import TabBarStyleGuide from "./TabBar";
import GraphContentGuide from "./GraphContent";
import PositivityDropdownStyleGuide from "./PositivityDropdown";
import TooltipBadgeStyleGuide from "./TooltipBadge";
import TextBannerStyleGuide from "./TextBanner";
import CarouselStyleGuide from "./Carousel";
import SunsetStyleGuide from "./Sunset/Sunset";
const cx = classNames.bind(styles);

function loadLato() {
  if (document.getElementById("lato-loader")) return;
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.href =
    "https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap";
  link.id = "lato-loader";
  document.head.appendChild(link);
}

const baseURL = "/style-guide";

const links = [
  {
    label: "Kitchen Sink",
    component: KitchenSink,
    to: "/kitchen-sink",
  },
  {
    label: "ArticleCard",
    component: ArticleCardGuide,
    to: "/article-card",
  },
  {
    label: "ArticleGrid",
    component: ArticleGridGuide,
    to: "/article-grid",
  },
  {
    label: "Interior Page Heading",
    component: InteriorPageHeaderGuide,
    to: "/page-heading",
  },
  {
    label: "BGFormat",
    component: BGFormatGuide,
    to: "/bg-format",
  },
  {
    label: "Custom Select",
    component: CustomSelectStyleGuide,
    to: "/custom-select",
  },
  {
    label: "TabBar",
    component: TabBarStyleGuide,
    to: "/tab-bar",
  },
  {
    label: "Teleworkability Visualization",
    component: TeleworkabilityVisualization,
    to: "/teleworkability-visualization",
  },
  {
    label: "Charts",
    component: Charts,
    to: "/charts",
  },
  {
    label: "GraphContent",
    component: GraphContentGuide,
    to: "/graph-content",
  },
  {
    label: "TextBanner",
    component: TextBannerStyleGuide,
    to: "/text-banner",
  },
  {
    label: "Positivity Dropdown",
    component: PositivityDropdownStyleGuide,
    to: "/positivity-dropdown",
  },
  {
    label: "Tooltip Badge",
    component: TooltipBadgeStyleGuide,
    to: "/tooltip-badge",
  },
  {
    label: "Carousel",
    component: CarouselStyleGuide,
    to: "/carousel",
  },
  {
    label: "Sunset",
    component: SunsetStyleGuide,
    to: "/sunset",
  },
];

function StyleGuideIndex() {
  return (
    <div className={cx("container")}>
      <h1
        className={cx("styleguide-annotation")}
        style={{ textTransform: "uppercase" }}
      >
        JHU Design System 4.0
      </h1>
      <p className={cx("styleguide-annotation")}>
        This is the styleguide for version 4.0 of the the Johns Hopkins
        Coronavirus Resource Center design system. The pages below display the
        styles of many of the design system’s components, and, in many cases,
        serve as documentation of example usages.
      </p>
      <ul className={cx("styleguide-annotation")}>
        {links.map((link, i) => (
          <li key={`${i}-${link.to}`}>
            <Link to={`${baseURL}${link.to}`}>{link.label}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default function StyleGuideRouter() {
  loadLato();
  useEffect(GTM.clear, []);

  return (
    <Switch>
      {links.map((link, i) => (
        <Route
          id={`${link.to}`}
          key={`${link.to}`}
          path={`${baseURL}${link.to}`}
          component={link.component}
        />
      ))}
      <Route path="/style-guide" component={StyleGuideIndex} />
    </Switch>
  );
}
