import React, { useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { updateTableTooltip } from '../../../../../redux/modules/global';

const useStyles = makeStyles(theme => ({
  voronoi: {
    fill: 'transparent',
    stroke: 'transparent',
    strokeWidth: '1px',
  },
}));

const VoronoiCell = ({ d, item, formatter, additionalText = '', defaultCurrentPoint, setCurrentPoint }) => {
  const classes = useStyles();
  const cellRef = useRef(null);
  const dispatch = useDispatch();
  const setTooltip = useCallback(tooltip => dispatch(updateTableTooltip(tooltip)), [dispatch]);

  const onPathMouseOver = () => {
    const { top, left } = cellRef.current.getBoundingClientRect();
    setTooltip({
      ...item,
      formatter,
      top: top - 15,
      left,
      additionalText,
      accessor: d => d.average,
    });
    setCurrentPoint(item);
  };

  return (
    <path
      ref={cellRef}
      className={classes.voronoi}
      d={d}
      onMouseOver={onPathMouseOver}
      onMouseOut={() => {
        setTooltip(null);
        setCurrentPoint(defaultCurrentPoint);
      }}
    />
  );
};

export default VoronoiCell;
