import React, { useState } from "react";
import VisualizationContainer from "./components/VisualizationContainer";
import Tooltip from "./components/Tooltip";

const EquitableAccessToTesting = ({ stateCode, data }) => {
  const [countyMapViews, setCountyMapViews] = useState([
    {
      name: "Daily Data and Demographics",
      description:
        "This tool shows key county-level COVID-19 case and testing data with the option to overlay it against demographic information—including race/ethnicity and poverty rates.",
      id: "daily-data-and-demographics",
      active: true,
    },
    {
      name: "Cumulative Data",
      description:
        "This map shows cumulative numbers for the length of the pandemic. Demographic data can be selected from the demographic menu on the Daily Data and Demographics tab.",
      id: "cumulative-data",
      active: false,
    },
  ]);
  return (
    <div id="jh-equitable-access-to-testing">
      <VisualizationContainer
        countyMapViews={countyMapViews}
        setCountyMapViews={setCountyMapViews}
        stateCode={stateCode}
        data={data}
      />
      <Tooltip activeCountyMapView={countyMapViews.find((d) => d.active)} />
    </div>
  );
};

export default EquitableAccessToTesting;
