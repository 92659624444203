import { getFilteredRegionDataNotes } from "data/geo";
import { TRegionNote, TRegionNoteFilterProps } from "data/geo/helpers";
import { formatMonthDate } from "util/helper";

type TOption = {
  label: string;
  value: string;
  [key: string]: any;
};

export const getAvailableRegionNoteOptions = ({
  regionType,
  filterOptions,
}: TRegionNoteFilterProps): TOption[] => {
  const result: TOption[] = getFilteredRegionDataNotes({
    regionType,
    filterOptions: filterOptions,
  }).map((c) => ({ label: c.name, value: c.name, otherProps: { ...c } }));

  return result
    .filter((c, i) => result.findIndex((r) => r.label === c.label) === i)
    .sort((a, b) => {
      if (a.label > b.label) return 1;
      if (b.label > a.label) return -1;
      return 0;
    });
};

//Returns an Object with the notes organized by Date keys
export const getFormatedNoteData = (
  dataArray: TRegionNote[] = []
): { [key: string]: TRegionNote[] } => {
  const result = {};
  for (let index = 0; index < dataArray.length; index++) {
    const data = dataArray[index];
    const noteDate = !!data?.date
      ? formatMonthDate(new Date(data?.date))
      : "Undated";
    result[noteDate] = result[noteDate]
      ? result[noteDate].concat(data)
      : [data];
  }
  return result;
};
