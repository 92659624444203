import React from "react";
import styles from "./AccessingPastData.module.scss";
import classNames from "classnames/bind";
import HowToUseCard from "../HowToUseCard/HowToUseCard";
import ReactMarkdown from "react-markdown";
const cx = classNames.bind(styles);

type TAccessingPastDataProps = {
  className?: string;
};

const introContent =
  "Johns Hopkins maintains two data repositories for the information collected by the Coronavirus Resource Center between Jan. 22, 2020 and March 10, 2023. The first features [global cases and deaths data](https://github.com/CSSEGISandData/COVID-19) as plotted by the Center for Systems Science and Engineering. The second features [U.S. and global vaccination data](https://github.com/govex/COVID-19/tree/master/data_tables/vaccine_data), testing information and demographics that were maintained by Johns Hopkins University’s [Bloomberg Center for Government Excellence](https://govex.jhu.edu/about/).";

const AccessingPastData = ({ className }: TAccessingPastDataProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.intro}>
        <h2 className={styles.header}>Accessing past data</h2>
        <ReactMarkdown className={styles.content} source={introContent} />
      </div>
      <div className={styles.cardsBlockContainer}>
        <div className={styles.cardsBlock}>
          <HowToUseCard
            className={styles.card}
            title={"How to use the CSSE GitHub"}
            content={
              <ol>
                <li>
                  Click on csse_covid_19_data to access chronological 'time
                  series' data.
                </li>
                <li>
                  Click on csse_covid_19_time_series to access daily data for
                  cases and deaths.
                </li>
                <li>
                  Click on 'confirmed_US' for U.S. cases and “deaths_US” for
                  U.S. fatalities; and “confirmed_global” for international
                  cases and 'deaths_global' for international fatalities.
                </li>
                <li>Click “View Raw.”</li>
                <li>Left click to “Save As” as spreadsheet.</li>
              </ol>
            }
            href={"https://github.com/CSSEGISandData/COVID-19"}
            label={"CSSE GitHub"}
          />
          <HowToUseCard
            className={styles.card}
            title={"How to use the GovEx GitHub"}
            content={
              <ol>
                <li>
                  Visit the U.S. 'data dictionary' and the global 'data
                  dictionary' to understand the vaccine, testing and demographic
                  data available for your use.
                </li>
                <li>
                  Example: Click on either 'us_data' or 'global_data' for
                  vaccine information.
                </li>
                <li>
                  Click on 'time_series' to access daily reports for either the
                  U.S. or the world.
                </li>
                <li>
                  Select either 'vaccines' or “doses administered” for the U.S.
                  or the world.
                </li>
                <li>
                  For either database, click 'view raw' to view the data or to
                  save it to a spreadsheet.
                </li>
              </ol>
            }
            href={
              "https://github.com/govex/COVID-19/tree/master/data_tables/vaccine_data"
            }
            label={"GovEx GitHub"}
          />
        </div>
      </div>
    </div>
  );
};

export default AccessingPastData;
