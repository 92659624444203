import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import DemographicLayerSelect from "./DemographicLayerSelect";
import Legend from "./Legend";
import hoverHandImage from "../../../../../assets/images/hover-hand.svg";
import {COLORS} from "../../../../../../Shared/constants";

const useStyles = makeStyles((theme) => ({
    demographicContainer: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
        padding: '0 15px'
    },
    innerContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '&:first-child': {
            marginRight: 5
        },
        [theme.breakpoints.down('sm')]: {
            '&:first-child': {
                marginBottom: 10,
            }
        },
        margin: '25px 11px 10px 11px'
    },
    description: {
        fontSize: '16px',
        lineHeight: '20px',
        color: COLORS.blueDark,
        marginLeft: 10,
    },
}));

const DemographicLayerContainer = ({selectedDemographicLayer}) => {
    const classes = useStyles();
    const showLegend = !selectedDemographicLayer.hasOwnProperty('isEmpty');

    return (
        <div className={classes.demographicContainer}>
            <DemographicLayerSelect selectedDemographicLayer={selectedDemographicLayer}/>
            {showLegend && <Legend selectedDemographicLayer={selectedDemographicLayer}/>}
            <div className={classes.innerContainer}>
                <img src={hoverHandImage}
                     alt="A hand showing how to interact with the map and scatter gauge visualizations"/>
                <p className={classes.description}>
                    Hover or tap the map to see County’s data
                </p>
            </div>
        </div>
    );
};
export default DemographicLayerContainer;
