import React, { Component } from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./CountryPlot.module.scss";
import EmbedFrame from "components/common/EmbedFrame";

class CountryPlot extends Component {
  render() {
    const {
      name,
      trend,
      days_since_1st_conf,
      date_first_confirmed,
      confirmed,
      deaths,
      src,
    } = this.props;

    return (
      <div key={name} className={styles.countryItem}>
        <div className={styles.countryItemHeader}>
          <h3>{name}</h3>
          <p>New cases confirmed each day (7-day-average)</p>
          <div className={cx(styles.countryItemTrend, styles[trend])}>
            {trend}
          </div>
        </div>
        <p className={styles.story}>
          The first case of COVID-19 in {name} was reported{" "}
          {days_since_1st_conf} days ago on {date_first_confirmed}. Since then,
          the country has reported {confirmed} cases, and {deaths} deaths.
        </p>

        <div className={styles.countryItemPlot}>
          <EmbedFrame
            src={src}
            width={600}
            height={500}
          />
        </div>
      </div>
    );
  }
}

CountryPlot.propTypes = {
  name: PropTypes.string,
  trend: PropTypes.string,
  plot: PropTypes.object,
  containerWidth: PropTypes.number,
};

export default CountryPlot;
