import React, { useRef, useEffect, useState } from "react";
import { findStateCode } from "data/geo";
import { select } from "d3-selection";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisLeft, axisTop } from "d3-axis";
import { format } from "d3-format";
import { line } from "d3-shape";
import Lollipop from "./Lollipop";
import { slugify } from "data/geo";

const DiffChart = ({ data, svgWidth }) => {
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [height, setHeight] = useState(450);
  const [outerHeight, setOuterHeight] = useState(null);
  const [margin, setMargin] = useState({
    top: 90,
    right: 90,
    bottom: 10,
    left: 110,
  });
  const [barData, setBarData] = useState(null);
  const svgRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHeight(mobile ? data.length * 13 : data.length * 20);
  }, [data, mobile]);

  useEffect(() => {
    mobile
      ? setMargin({ top: 180, right: 120, bottom: 10, left: 110 })
      : setMargin({ top: 90, right: 90, bottom: 10, left: 110 });
  }, [mobile]);

  useEffect(() => {
    setOuterHeight(height + margin.top + margin.bottom);
  }, [margin, height]);

  useEffect(() => {
    const svg = select(svgRef.current);
    const markerBoxWidth = 5,
      markerBoxHeight = 5,
      refX = markerBoxWidth / 2,
      refY = markerBoxHeight / 2,
      // markerWidth = markerBoxWidth / 2,
      // markerHeight = markerBoxHeight / 2,
      arrowPoints = [
        [0, 0],
        [0, 5],
        [5, 2.5],
      ];
    const defs = svg.append("defs");
    defs
      .append("marker")
      .attr("id", "arrowIncrease")
      .attr("viewBox", [0, 0, markerBoxWidth, markerBoxHeight])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", line()(arrowPoints))
      .attr("stroke", "#f8a833")
      .attr("fill", "#f8a833");
    defs
      .append("marker")
      .attr("id", "arrowDecrease")
      .attr("viewBox", [0, 0, markerBoxWidth, markerBoxHeight])
      .attr("refX", refX)
      .attr("refY", refY)
      .attr("markerWidth", markerBoxWidth)
      .attr("markerHeight", markerBoxHeight)
      .attr("orient", "auto-start-reverse")
      .append("path")
      .attr("d", line()(arrowPoints))
      .attr("stroke", "#719949")
      .attr("fill", "#719949");
    const innerWidth = svgWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    let xvar = "7-day_positivity_now";
    let sorted = data.sort((a, b) => a[xvar] - b[xvar]);
    let xScale = scaleLinear().domain([0, 1]).range([0, innerWidth]).nice();
    let yScale = scaleBand()
      .domain(sorted.map((s) => s.region))
      .range([innerHeight, 0])
      .padding(0.2);

    let barData = [];
    sorted.forEach((d) => {
      let direction = d.diff > 0 ? "increased" : "decreased";
      let content = (
        <>
          <b>{d.region}</b>:<br />
          Positivity has {direction} from{" "}
          {format(".0%")(d["7-day_positivity_last_week"])} to{" "}
          {format(".0%")(d[xvar])}
          <br />
          <b>Positivity Type</b>:<br />
          {d.positivity_type}
        </>
      );
      barData.push({
        r: 5,
        cxNow: xScale(d[xvar]),
        cxLast: xScale(d["7-day_positivity_last_week"]),
        x1: d.diff < 0 ? xScale(d[xvar]) + 9 : xScale(d[xvar]) - 9,
        x2: xScale(d["7-day_positivity_last_week"]),
        y: yScale(d.region) + yScale.bandwidth() / 2,
        fill: d.diff < 0 ? "#719949" : "#f8a833",
        tippyContent: content,
        regionURL: `/region/us/${slugify(d.region)}`,
        markerStart:
          Math.abs(d.diff) < 0.01
            ? ""
            : d.diff < 0
            ? "url(#arrowDecrease)"
            : "url(#arrowIncrease)",
      });
    });
    setBarData(barData);

    let xAxis = (g) => g.call(axisTop(xScale).tickFormat(format(".0%")));
    let yAxis = (g) =>
      g.call(
        mobile
          ? axisLeft(yScale).tickFormat((d) => findStateCode(d))
          : axisLeft(yScale)
      );

    let yG = group.append("g").call(yAxis);
    let xG = group.append("g").call(xAxis);

    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 0 : innerWidth / 2},${
          mobile ? -margin.top + 30 : -60
        })`
      )
      .attr("text-anchor", mobile ? "start" : "middle")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Postivity Percentage (average of 7 days)");
    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth - 50},${
          mobile ? -margin.top + 120 : -34
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Past Positivity Value");
    xG.append("circle")
      .attr("fill", "#ccc")
      .attr("r", 7)
      .attr("cy", mobile ? -margin.top + 116 : -39)
      .attr("cx", mobile ? 10 : innerWidth - 170);
    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth / 1.5},${
          mobile ? -margin.top + 90 : -34
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Current Percent (Decrease)");
    xG.append("circle")
      .attr("fill", "#719949")
      .attr("r", 7)
      .attr("cy", mobile ? -margin.top + 86 : -39)
      .attr("cx", mobile ? 10 : innerWidth / 1.5 - 159);
    xG.append("text")
      .attr(
        "transform",
        `translate(${mobile ? 30 : innerWidth / 3},${
          mobile ? -margin.top + 60 : -34
        })`
      )
      .attr("text-anchor", mobile ? "start" : "end")
      .attr("fill", "#aaa")
      .style("font-size", "120%")
      .text("Current Percent (Increase)");
    xG.append("circle")
      .attr("fill", "#f8a833")
      .attr("r", 7)
      .attr("cy", mobile ? -margin.top + 56 : -39)
      .attr("cx", mobile ? 10 : innerWidth / 3 - 153);
    yG.select("text").attr("style", "font-weight:300");

    xG.selectAll(".tick line").attr("stroke", "#aaa");
    group.selectAll(".domain").raise().attr("stroke", "#aaa");

    return () => {
      group.remove();
    };
  }, [svgWidth, mobile, height, margin, data]);

  return (
    <svg ref={svgRef} width={svgWidth} height={outerHeight}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {barData &&
          barData.map((d, i) => {
            return (
              <Lollipop
                key={`state${i}`}
                r={d.r}
                cxNow={d.cxNow}
                cxLast={d.cxLast}
                x1={d.x1}
                x2={d.x2}
                y={d.y}
                fill={d.fill}
                tippyContent={d.tippyContent}
                regionURL={d.regionURL}
                markerStart={d.markerStart}
                mobile={mobile}
              />
            );
          })}
      </g>
    </svg>
  );
};

export default DiffChart;
