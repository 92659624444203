import { useRef, useEffect, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";


export default function NavigationScroller() {
  const location = useLocation();

  // Track scroll position
  const scrollPos = useRef([window.scrollX, window.scrollY]);
  useEffect(() => {
    const updateScroll = (e) => { scrollPos.current = [window.scrollX, window.scrollY] };
    window.addEventListener('wheel', updateScroll);
    return () => window.removeEventListener('wheel', updateScroll);
  }, []);

  // Fires when route changes
  useLayoutEffect(() => {
    // Abort early in testing
    if (navigator.userAgent.indexOf("jsdom") > -1) return;

    if (!location.hash) { // don't mess with scroll if there's a hash
      // unless we block with noScroll state flag, set the jump point the top of the page
      if (!location.state?.noScroll) scrollPos.current = [0, 0];
      window.scrollTo(...scrollPos.current);
    }
  }, [location]);

  return null;
}
