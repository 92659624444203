import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import styles from "./Timeline.module.scss";
import timelines from "../data/timelines.json";
import TimelineSplash from "./TimelineSplash";
import TypicalTimeline from "./TypicalTimeline";
import AcceleratedTimeline from "./AcceleratedTimeline";
import Meta from "components/common/Meta";

const Timeline = () => {
  // DEBUG: Set to start at: 0 = typical, 1 = accelerated
  const startingTimeline = timelines[0];

  const [splashPosition, setSplashPosition] = useState("");
  const [currentPhase, setCurrentPhase] = useState("");
  const [currentTimeline, setCurrentTimeline] = useState(startingTimeline.id);

  useEffect(() => {
    if (currentTimeline === "accelerated") {
      const phaseEl = document.getElementById(currentPhase);
      if (!!phaseEl) {
        const scrollToDistance = phaseEl.offsetTop;
        window.scrollTo({ top: scrollToDistance + window.innerHeight * 0.75 });
      }
    }
  }, [currentPhase, currentTimeline]);

  return (
    <div className={styles.base}>
      <Meta
        title="Vaccine Research & Development"
        description="How can COVID-19 vaccine development be done quickly and safely?"
        image="/images/vaccine-timeline-thumbnail.png"
        path="/vaccines/timeline"
      />
      <TimelineSplash setSplashPosition={setSplashPosition} />

      <TypicalTimeline
        splashPosition={splashPosition}
        currentPhase={currentPhase}
        currentTimeline={currentTimeline}
        setCurrentPhase={setCurrentPhase}
        setCurrentTimeline={setCurrentTimeline}
      />

      <AnimatePresence>
        {currentTimeline === "accelerated" && (
          <motion.div
            className={styles.acceleratedTimelineContainer}
            initial={{ x: "100vw" }}
            animate={{ x: 0 }}
            exit={{ x: "100vw" }}
            transition={{ type: "tween", ease: "easeOut" }}
          >
            <AcceleratedTimeline
              currentPhase={currentPhase}
              setCurrentPhase={setCurrentPhase}
              setCurrentTimeline={setCurrentTimeline}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Timeline;
