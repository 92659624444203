import {initialState} from '../state/global';
import {getData} from '../api';
import {valueAccessor} from "../../../Shared/constants";

// Actions
const TOGGLE_EQUITABLE_ACCESS_LOADING_ICON = 'TOGGLE_EQUITABLE_ACCESS_LOADING_ICON';
const UPDATE_EQUITABLE_ACCESS_DATA = 'UPDATE_EQUITABLE_ACCESS_DATA';
const UPDATE_SELECTED_DEMOGRAPHIC_LAYER = 'UPDATE_SELECTED_DEMOGRAPHIC_LAYER';
const UPDATE_SELECTED_BUBBLE_LAYER = 'UPDATE_SELECTED_BUBBLE_LAYER';
const UPDATE_SELECTED_CUMULATIVE_LAYER = 'UPDATE_SELECTED_CUMULATIVE_LAYER';
const SHOW_ERROR = 'SHOW_ERROR';
const SET_ACTIVE_MARKER = 'SET_ACTIVE_MARKER';

const appendPropertiesToFeatures = (data, featureCollection) => {
    const counties = featureCollection.objects.counties.geometries.map(county => {
        const matchingDataPoint = data.counties[county.properties["GEOID"]];
        return {
            ...county,
            "properties": {
                ...county.properties,
                ...matchingDataPoint
            }
        }
    })
    featureCollection.objects.counties.geometries = counties;
    return featureCollection;
}

const calculateRacialBreakdown = (data, featureCollection) => {
    // Calculate the racial breakdown for the CTP section of the equitable testing app
    // Remove the row if both cases and deaths are null
    return [
        // Race demographic data
        {label: 'Black or African American', id: 'race_black_african_american'},
        {label: 'American Indian / Alaska Native', id: 'race_american_indian_alaska_native'},
        {label: 'Asian', id: 'race_asian'},
        {label: 'Native Hawaiian/Pacific Islander', id: 'race_native_hawaiian_pacific_islander'},
        {label: 'White', id: 'race_white'},
        {label: 'Other', id: 'race_other'},
        {label: 'Two or More Races', id: 'race_two_or_more_races'},
        {label: 'Race Unknown', id: 'race_unknown'},
        // Ethnicity demographic data
        {label: 'Hispanic or Latino', id: 'ethnicity_hispanic_latino'},
        {label: 'Non-Hispanic or Latino', id: 'ethnicity_non_hispanic_latino'},
        {label: 'Ethnicity Unknown', id: 'ethnicity_unknown'},

    ].map(row => {
        const result = {
            ...row,
            values: [
                {
                    percent: data.demographic_populations[row.id],
                    suffix: 'population',
                    value: data.demographic_populations[row.id]
                },
                {
                    percent: data.confirmed_cases_demographics[row.id],
                    suffix: 'cases',
                    value: data.confirmed_cases_demographics[row.id]
                },
                {
                    percent: data.deaths_demographics[row.id],
                    suffix: 'deaths',
                    value: data.deaths_demographics[row.id]
                },
                {
                    percent: data.vaccines_demographics[row.id],
                    suffix: 'vaccines',
                    value: data.vaccines_demographics[row.id]
                }
            ]
        };

        return result;
    });
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_EQUITABLE_ACCESS_DATA:
            return ({
                ...state,
                data: appendPropertiesToFeatures(action.data, action.featureCollection),
                racialBreakdown: calculateRacialBreakdown(action.data, action.featureCollection)
            });
        case SHOW_ERROR:
            return {
                ...state,
                showError: true,
            };
        case SET_ACTIVE_MARKER:
            return {
                ...state,
                activeMarker: action.activeMarker,
            };
        case TOGGLE_EQUITABLE_ACCESS_LOADING_ICON:
            return {
                ...state,
                showError: false,
                isLoading: action.x,
            };
        case UPDATE_SELECTED_DEMOGRAPHIC_LAYER:
            return {
                ...state,
                demographicLayers: state.demographicLayers.map(demographicLayer => {
                    return {
                        ...demographicLayer,
                        isActive: valueAccessor(demographicLayer) === action.id,
                    };
                }),
            };
        case UPDATE_SELECTED_BUBBLE_LAYER:
            return {
                ...state,
                bubbleLayers: state.bubbleLayers.map(bubbleLayer => {
                    return {
                        ...bubbleLayer,
                        isActive: valueAccessor(bubbleLayer) === action.id,
                    };
                }),
            };
        case UPDATE_SELECTED_CUMULATIVE_LAYER:
            return {
                ...state,
                cumulativeLayers: state.cumulativeLayers.map(cumulativeLayer => {
                    return {
                        ...cumulativeLayer,
                        isActive: valueAccessor(cumulativeLayer) === action.id,
                    };
                }),
            };
        default:
            return state;
    }
}

function toggleLoadingIcon(x) {
    return {
        type: TOGGLE_EQUITABLE_ACCESS_LOADING_ICON,
        x,
    };
}

export function updateActiveMarker(activeMarker) {
    return {
        type: SET_ACTIVE_MARKER,
        activeMarker
    }
}

export function updateSelectedCumulativeLayer(id) {
    return {
        type: UPDATE_SELECTED_CUMULATIVE_LAYER,
        id
    }
}

export function updateSelectedDemographicLayer(id) {
    return {
        type: UPDATE_SELECTED_DEMOGRAPHIC_LAYER,
        id
    }
}

export function updateSelectedBubbleLayer(id) {
    return {
        type: UPDATE_SELECTED_BUBBLE_LAYER,
        id
    }
}

export function retrieveData(id, data) {
    return async dispatch => {
        dispatch(toggleLoadingIcon(true));

        try {
            const featureCollection = await getData(id);
            dispatch({
                type: UPDATE_EQUITABLE_ACCESS_DATA,
                data,
                featureCollection
            });
        } catch (err) {
            console.log(err);
            dispatch({type: SHOW_ERROR});
        }

        dispatch(toggleLoadingIcon(false));
    };
}
