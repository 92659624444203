import React, { useContext, useEffect, useRef, useState } from "react";
import { TooltipContext } from "../Tooltip";
import { select } from "d3-selection";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisLeft } from "d3-axis";
import { format } from "d3-format";

const GroupedBar = ({
  data,
  population,
  xMax,
  svgWidth,
  demographic,
  state,
  dataset,
  children,
}) => {
  const tooltipContext = useContext(TooltipContext);
  const ref = useRef(null);
  const parentRef = useRef(null);
  const [width, setWidth] = useState(svgWidth);
  const [height, setHeight] = useState(500);

  useEffect(() => {
    function handleResize() {
      requestAnimationFrame(() => {
        // If we're able to get the SVG's parent,
        // set the height and width to be the same as the parent's
        if (parentRef?.current) {
          const parent = parentRef.current.parentElement;
          const parentSize = {
            width:
              window.innerWidth < 970
                ? parent.offsetWidth
                : parent.offsetWidth * 0.5,
          };
          setWidth(parentSize.width);
        } else {
          // Else, set it to attempt to fit the chart
          // based on the size of the window
          const thisWidth =
            typeof window === "undefined"
              ? 100
              : window.innerWidth >= 1248
              ? 600
              : window.innerWidth < 970
              ? window.innerWidth
              : window.innerWidth * 0.5;
          setWidth(thisWidth);
        }
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [parentRef]);

  useEffect(() => {
    type Value = {
      key: string;
      label: string;
      value: number;
      order: number;
      pop: number;
      diff: any;
    };
    let bars: any;

    if (data && population) {
      switch (demographic.value) {
        case "age":
          bars = [
            {
              key: "age_0_9",
              label: "0-9",
              order: 1,
              pop: population["age_0_9"] / 100,
            },
            {
              key: "age_10_19",
              label: "10-19",
              order: 2,
              pop: population["age_10_19"] / 100,
            },
            {
              key: "age_20_29",
              label: "20-29",
              order: 3,
              pop: population["age_20_29"] / 100,
            },
            {
              key: "age_30_39",
              label: "30-39",
              order: 4,
              pop: population["age_30_39"] / 100,
            },
            {
              key: "age_40_49",
              label: "40-49",
              order: 5,
              pop: population["age_40_49"] / 100,
            },
            {
              key: "age_50_59",
              label: "50-59",
              order: 6,
              pop: population["age_50_59"] / 100,
            },
            {
              key: "age_60_69",
              label: "60-69",
              order: 7,
              pop: population["age_60_69"] / 100,
            },
            {
              key: "age_70_79",
              label: "70-79",
              order: 8,
              pop: population["age_70_79"] / 100,
            },
            {
              key: "age_80_older",
              label: "80+",
              order: 9,
              pop: population["age_80_100"] / 100,
            },
            {
              key: "age_unknown",
              label: "Unknown",
              order: 0,
              pop: -1,
            },
          ];
          break;
        case "ethnicity":
          bars = [
            {
              key: "ethnicity_hispanic_latino",
              label: "Hispanic or Latino",
              order: 1,
              pop: population["ethnicity_hispanic_latino"] / 100,
            },
            {
              key: "ethnicity_non_hispanic_latino",
              label: "Not Hispanic or Latino",
              order: 2,
              pop: population["ethnicity_non_hispanic_latino"] / 100,
            },
            {
              key: "ethnicity_unknown",
              label: "Unknown",
              order: 0,
              pop: -1,
            },
          ];
          break;
        case "gender_sex":
          bars = [
            {
              key: "gender_sex_female",
              label: "Female",
              order: 1,
              pop: population["gender_sex_female"] / 100,
            },
            {
              key: "gender_sex_male",
              label: "Male",
              order: 2,
              pop: population["gender_sex_male"] / 100,
            },
            {
              key: "gender_sex_unknown",
              label: "Unknown",
              order: 0,
              pop: -1,
            },
            {
              key: "gender_sex_other",
              label: "Other",
              order: 3,
              pop: -1,
            },
          ];
          break;
        case "race":
          bars = [
            {
              key: "race_american_indian_alaska_native",
              label: "American Indian or Alaska Native",
              order: 1,
              pop: population["race_american_indian_alaska_native"] / 100,
            },
            {
              key: "race_asian",
              label: "Asian",
              order: 2,
              pop: population["race_asian"] / 100,
            },
            {
              key: "race_black_african_american",
              label: "Black or African American",
              order: 3,
              pop: population["race_black_african_american"] / 100,
            },
            {
              key: "race_native_hawaiian_pacific_islander",
              label: "Native Hawaiian or Pacific Islander",
              order: 4,
              pop: population["race_native_hawaiian_pacific_islander"] / 100,
            },
            {
              key: "race_white",
              label: "White",
              order: 5,
              pop: population["race_white"] / 100,
            },
            {
              key: "race_other",
              label: "Other",
              order: 6,
              pop: population["race_other"] / 100,
            },
            {
              key: "race_two_or_more_races",
              label: "Two or More Races",
              order: 7,
              pop: population["race_two_or_more_races"] / 100,
            },
            {
              key: "race_unknown",
              label: "Unknown",
              order: 0,
              pop: -1,
            },
          ];
          break;
        case "race_ethnicity":
          bars = [
            {
              key:
                "race_ethnicity_american_indian_alaska_native-hispanic_latino",
              label: "American Indian and Alaska Native Hispanic or Latino",
              order: 4,
              pop:
                population[
                  "race_ethnicity_american_indian_alaska_native-hispanic_latino"
                ] / 100,
            },
            {
              key:
                "race_ethnicity_american_indian_alaska_native-non_hispanic_latino",
              label: "American Indian and Alaska Native Not Hispanic or Latino",
              order: 5,
              pop:
                population[
                  "race_ethnicity_american_indian_alaska_native-non_hispanic_latino"
                ] / 100,
            },
            {
              key: "race_ethnicity_american_indian_alaska_native-unknown",
              label: "American Indian and Alaska Native Unknown",
              order: 6,
              pop: -1,
            },
            {
              key: "race_ethnicity_asian-hispanic_latino",
              label: "Asian Hispanic or Latino",
              order: 7,
              pop: population["race_ethnicity_asian-hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_asian-non_hispanic_latino",
              label: "Asian Not Hispanic or Latino",
              order: 8,
              pop: population["race_ethnicity_asian-non_hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_asian-unknown",
              label: "Asian Unknown",
              order: 9,
              pop: -1,
            },
            {
              key: "race_ethnicity_black_african_american-hispanic_latino",
              label: "Black or African American Hispanic or Latino",
              order: 10,
              pop:
                population[
                  "race_ethnicity_black_african_american-hispanic_latino"
                ] / 100,
            },
            {
              key: "race_ethnicity_black_african_american-non_hispanic_latino",
              label: "Black or African American Not Hispanic or Latino",
              order: 11,
              pop:
                population[
                  "race_ethnicity_black_african_american-non_hispanic_latino"
                ] / 100,
            },
            {
              key: "race_ethnicity_black_african_american-unknown",
              label: "Black or African American Unknown",
              order: 12,
              pop: -1,
            },
            {
              key:
                "race_ethnicity_native_hawaiian_pacific_islander-hispanic_latino",
              label:
                "Native Hawaiian and Other Pacific Islander Hispanic or Latino",
              order: 13,
              pop:
                population[
                  "race_ethnicity_native_hawaiian_pacific_islander-hispanic_latino"
                ] / 100,
            },
            {
              key:
                "race_ethnicity_native_hawaiian_pacific_islander-non_hispanic_latino",
              label:
                "Native Hawaiian and Other Pacific Islander Not Hispanic or Latino",
              order: 14,
              pop:
                population[
                  "race_ethnicity_native_hawaiian_pacific_islander-non_hispanic_latino"
                ] / 100,
            },
            {
              key: "race_ethnicity_native_hawaiian_pacific_islander-unknown",
              label: "Native Hawaiian and Other Pacific Islander Unknown",
              order: 15,
              pop: -1,
            },
            {
              key: "race_ethnicity_white-hispanic_latino",
              label: "White Hispanic or Latino",
              order: 16,
              pop: population["race_ethnicity_white-hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_white-non_hispanic_latino",
              label: "White Not Hispanic or Latino",
              order: 17,
              pop: population["race_ethnicity_white-non_hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_white-unknown",
              label: "White Unknown",
              order: 18,
              pop: -1,
            },
            {
              key: "race_ethnicity_other-hispanic_latino",
              label: "Other Race Hispanic or Latino",
              order: 19,
              pop: population["race_ethnicity_other-hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_other-non_hispanic_latino",
              label: "Other Race Not Hispanic or Latino",
              order: 20,
              pop: population["race_ethnicity_other-non_hispanic_latino"] / 100,
            },
            {
              key: "race_ethnicity_other-unknown",
              label: "Other Unknown",
              order: 21,
              pop: -1,
            },
            {
              key: "race_ethnicity_two_or_more_races-hispanic_latino",
              label: "Two or More Races and Hispanic or Latino",
              order: 22,
              pop:
                population["race_ethnicity_two_or_more_races-hispanic_latino"] /
                100,
            },
            {
              key: "race_ethnicity_two_or_more_races-non_hispanic_latino",
              label: "Two or More Races Not Hispanic or Latino",
              order: 23,
              pop:
                population[
                  "race_ethnicity_two_or_more_races-non_hispanic_latino"
                ] / 100,
            },
            {
              key: "race_ethnicity_two_or_more_races-unknown",
              label: "Two or More Races Unknown",
              order: 24,
              pop: -1,
            },
            {
              key: "race_ethnicity_unknown-unknown",
              label: "Unknown",
              order: 0,
              pop: -1,
            },
          ];
          break;
        default:
          bars = [];
          break;
      }
    }

    const margin = {
      top: 70,
      right: 80,
      bottom: 30,
      left: demographic.value === "race_ethnicity" ? 165 : 90,
    };
    const values = bars
      ? bars
          .map((d: Value) =>
            Object.assign(
              {},
              d,
              (d.value =
                data[d.key] && isFinite(data[d.key]) ? data[d.key] / 100 : -1),
              (d.diff = d.value > -1 && d.pop > -1 ? d.value - d.pop : "NA")
            )
          )
          .sort((a, b) => b.order - a.order)
      : undefined;
    const unknowns = values
      ? values.find((v) => v.label === "Unknown").value
      : undefined;
    const filtered = values
      ? values.filter(
          (v) => v.label === "Unknown" || !(v.pop === -1 && v.value === -1)
        )
      : undefined;

    const svg = select(ref.current);
    const svgHeight = filtered
      ? 40 * filtered.length + margin.top + margin.bottom
      : 50 + margin.top + margin.bottom;
    setHeight(svgHeight);
    const innerwidth = width - margin.left - margin.right;
    const innerheight = svgHeight - margin.top - margin.bottom;
    const title = svg
      .append("text")
      .text(`${dataset.label} by ${demographic.label}`)
      .attr("fill", "#333")
      .style("font-size", "1.2em")
      .style("font-weight", "600")
      .attr("transform", "translate(0,30)");
    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    if (typeof filtered !== "undefined") {
      const valueset = new Set(filtered.map((d) => d.value));
      if (valueset.size === 1 && valueset.values().next().value === -1) {
        group
          .append("text")
          .text("No Data")
          .attr("fill", "#999")
          .attr("transform", `translate(${innerwidth / 2},25)`)
          .attr("text-anchor", "middle");
        setHeight(50 + margin.top + margin.bottom);
      } else {
        console.log(filtered);
        const pFormat = format(".2p");
        const x = scaleLinear().domain([0, xMax]).range([0, innerwidth]).nice();
        const y0 = scaleBand()
          .domain(filtered.map((d) => d.label))
          .range([innerheight, 0])
          .round(true)
          .padding(0.25);
        const y1 = scaleBand()
          .domain(["pop", "value"])
          .range([y0.bandwidth(), 0])
          .round(true);

        const bins = group
          .selectAll("g")
          .data(filtered)
          .join("g")
          .attr("transform", (d: Value) => `translate(0, ${y0(d.label)})`)
          .attr("id", (d: Value) => d.key);

        bins
          .selectAll(".rect")
          .data((d: Value) =>
            ["pop", "value"].map((key) => ({
              key: key,
              value: d[key],
              diff: d.diff,
            }))
          )
          .join("rect")
          .attr("height", y1.bandwidth())
          .attr("width", (d) => (d.value === -1 ? 0 : x(d.value)))
          .attr("x", 0)
          .attr("y", (d) => y1(d.key))
          .attr("fill", (d) =>
            d.key === "pop"
              ? "#ddd"
              : unknowns === -1 ||
                (unknowns > -1 && Math.abs(d.diff) > unknowns + 0.05)
              ? (d.diff < -0.05 &&
                  (dataset.value === "vaccines" ||
                    dataset.value === "tests")) ||
                (d.diff > 0.05 &&
                  (dataset.value === "cases" || dataset.value === "deaths"))
                ? dataset.darkColor
                : dataset.lightColor
              : dataset.lightColor
          );

        bins
          .selectAll(".label")
          .data((d: Value) =>
            ["pop", "value"].map((key) => ({ key: key, value: d[key] }))
          )
          .join("text")
          .attr("class", "label")
          .attr("alignment-baseline", "middle")
          .attr("font-size", "80%")
          .attr("text-anchor", "start")
          .attr("x", (d) => (d.value === -1 ? 4 : x(d.value) + 4))
          .attr("y", (d) => y1(d.key) + y1.bandwidth() / 2)
          .text((d) => (d.value === -1 ? "NA" : pFormat(d.value)));

        group
          .selectAll(".hovers")
          .data(filtered)
          .join("rect")
          .attr(
            "transform",
            (d: Value) => `translate(-${margin.left}, ${y0(d.label) - 6})`
          )
          .attr("height", 40)
          .attr("width", width)
          .attr("fill", "transparent")
          .attr("pointer-events", "all")
          .on("mouseenter touchstart", (d) => handleMouseEnter(d, unknowns))
          .on("mouseleave touchend", handleMouseLeave);

        const yAxis = axisLeft(y0);
        const yGroup = group.append("g").call(yAxis);
        yGroup
          .selectAll(".tick text")
          .call(wrap, margin.left - 10)
          .attr("transform", function (d) {
            let rect = (this as HTMLElement).getBoundingClientRect();
            return `translate(-10,${
              rect.height > 20 ? `-${rect.height / 3}` : 0
            })`;
          });
        const legend = svg
          .append("g")
          .classed("legend", true)
          .attr(
            "transform",
            `translate(${margin.left / 2}, ${margin.top / 2 + 10})`
          );
        legend
          .append("rect")
          .attr("fill", "#ddd")
          .attr("width", 15)
          .attr("height", 15);
        legend
          .append("text")
          .text("% of population")
          .attr("font-size", "70%")
          .attr("x", 20)
          .attr("y", 10);
        legend
          .append("rect")
          .attr("fill", dataset.lightColor)
          .attr("width", 15)
          .attr("height", 15)
          .attr("x", 140);
        legend
          .append("text")
          .text(`% of ${dataset.value}`)
          .attr("font-size", "70%")
          .attr("x", 160)
          .attr("y", 10);
        svg.select("#tooltipG").raise();
      }
    } else {
      group
        .append("text")
        .text("No Data")
        .attr("fill", "#999")
        .attr("transform", `translate(${innerwidth / 2},${innerheight / 2})`)
        .attr("text-anchor", "middle");
    }

    function handleMouseLeave(d) {
      tooltipContext.dispatch({
        show: false,
        dataset: dataset.value,
        content: undefined,
      });
    }

    function handleMouseEnter(d, unknowns) {
      let lines = 1;
      let content = undefined;
      if (unknowns === -1) {
        if (
          d.diff < -0.05 &&
          (dataset.value === "vaccines" || dataset.value === "tests")
        ) {
          content = `${format(".2p")(-d.diff)} less than population`;
        } else if (
          d.diff > 0.05 &&
          (dataset.value === "cases" || dataset.value === "deaths")
        ) {
          content = `${format(".2p")(d.diff)} more than population`;
        }
      } else if (unknowns > -1 && Math.abs(d.diff) > unknowns + 0.05) {
        lines = 2;
        if (
          d.diff < -(0.05 + unknowns) &&
          (dataset.value === "vaccines" || dataset.value === "tests")
        ) {
          content = `${format(".2p")(
            -(d.diff + unknowns)
          )} less than population (including unknowns)`;
        } else if (
          d.diff > 0.05 + unknowns &&
          (dataset.value === "cases" || dataset.value === "deaths")
        ) {
          content = `${format(".2p")(
            d.diff + unknowns
          )} more than population (including unknowns)`;
        }
      }

      if (content) {
        tooltipContext.dispatch({
          show: true,
          x: d.value,
          y: d.key,
          content: content,
          dataset: dataset.value,
          innerwidth: innerwidth,
          innerheight: innerheight,
          marginleft: margin.left,
          margintop: margin.top,
          bars: filtered.map((bar) => bar.key).reverse(),
          lines: lines,
        });
      }
    }

    return () => {
      group.remove();
      title.remove();
      svg.select(".legend").remove();
    };
  }, [data, population, xMax, demographic, width, dataset, tooltipContext]);

  function wrap(text, width) {
    text.each(function () {
      var text = select(this),
        words = text.text().trim().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", `${++lineNumber * lineHeight + dy}em`)
            .text(word);
        }
      }
    });
  }

  return (
    <div ref={parentRef}>
      <svg
        width={width}
        height={height}
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
      >
        {children}
      </svg>
    </div>
  );
};

export default GroupedBar;
