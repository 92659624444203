import React, { FC, useState } from "react";
import cx from "classnames";
import styles from "./JoinBlock.module.scss";
import GraphicBlock, { GraphicBlockProps } from "../GraphicBlock";
import GraphicInfo from "../GraphicInfo";
import { findStateByCode } from "data/geo";
import { decoder } from "../util";
import Modal from "components/common/Modal";

export type JoinBlockProps = {
  blocks: string[];
  stateCode?: string;
  className?: string;
};

const JoinBlock: FC<JoinBlockProps> = ({ blocks, stateCode, className }) => {
  const [show, setShow] = useState(false);
  const graphicBlockProps = {
    title: findStateByCode(stateCode),
    groups: blocks
      .filter((block) => block !== "JOIN")
      .map((block, i) => decoder(block, "legend")),
  };
  const handleShowModal = () => {
    if (window.innerWidth < 970) setShow(!show);
  };
  return (
    <div className={cx(styles.base, className)} onClick={handleShowModal}>
      {blocks.map((block, i) => {
        const nextBlock = blocks[i + 1];
        if (block === "JOIN") return null;
        return (
          <GraphicBlock
            key={`graphic-block${block}${i}`}
            variant={block as GraphicBlockProps["variant"]}
            joinNext={nextBlock === "JOIN"}
          />
        );
      })}
      <div className={styles.highlight} />
      {!!stateCode && (
        <>
          <GraphicInfo
            className={styles.infoBlock}
            {...graphicBlockProps}
            onHover={true}
          />
          <Modal
            className={styles.modal}
            show={show}
            x={true}
            dismiss={() => {
              setShow(false);
            }}
          >
            <GraphicInfo
              className={styles.infoBlockMobile}
              {...graphicBlockProps}
              onHover={false}
              modal={true}
            />
          </Modal>
        </>
      )}
    </div>
  );
};

export default JoinBlock;
