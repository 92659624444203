import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLORS} from "../../../../../../../Shared/constants";

const useStyles = makeStyles((theme) => ({
    legend: {
        width: "100%",
        height: "46px",
        margin: '15px auto',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            margin: '15px 0'
        },
    },
    label: {
        color: COLORS.blueDark,
        fontSize: '18px',
        fontWeight: 'bold',
        marginBottom: '5px'
    },
    colorScale: {
        display: 'flex',
        width: '100%',
        height: '100%'
    },
    gradientContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& :not(:last-child)': {
            marginRight: '5px'
        },
        textTransform: 'uppercase',
        fontSize: '14px',
        color: '#9697a0',
        alignItems: 'center',
        width: '100%',
    },
    gradient: {
        display: 'flex',
        width: '254px',
        height: '21px',
        backgroundImage: props => `linear-gradient(to right, ${props.minColor}, ${props.maxColor})`
    }
}));

const Legend = ({selectedDemographicLayer}) => {
    const [minColor, maxColor] = selectedDemographicLayer.colorScale.range();
    const classes = useStyles({
        minColor,
        maxColor
    });

    return (
        <div className={classes.legend}>
            <div className={classes.label}>
                {`Percent ${selectedDemographicLayer.label}`}
            </div>
            <div className={classes.gradientContainer}>
                <div>Less</div>
                <div className={classes.gradient}/>
                <div>more</div>
            </div>
        </div>
    );
};
export default Legend;
