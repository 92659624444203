import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import SectionHeading from 'components/layout/shell/SectionHeading';
import Button from 'components/common/Button';
import { mapModulesToCards } from 'components/layout/ArticleCard/utils';
import modules from 'components/content/Learn/modules';
import { ArticleGridLayouts } from 'components/layout/ArticleGrid';

import styles from './LearnTeaser.module.scss';
const cx = classNames.bind(styles);

const featureCard = [
  {
    title: 'Insights From Johns Hopkins University Experts',
    body: (
      <div>
        <p>Upon completing this course, you will be able to:</p>
        <ul>
          <li>
            Describe characteristics of the COVID-19 disease, including how it
            is diagnosed and treated
          </li>
          <li>
            Understand the development of the COVID-19 pandemic, and emerging
            trends throughout the world
          </li>
          <li>
            Discuss societal challenges raised by COVID-19 and opportunities to
            address them
          </li>
        </ul>
      </div>
    ),
    imageSrc: '/images/assets/75533d342e5641c5bbd974eed66b4774.jpg',
    href: '/covid-19-basics/understanding-covid-19',
  },
];

const learnCards = featureCard.concat(mapModulesToCards(modules));

const LearnTeaser = ({ heading }) => {
  const [isSingleColumn, setIsSingleColumn] = useState(false);
  const articleLimit = isSingleColumn ? 1 : Infinity;

  return (
    <div className={cx('base')}>
      <SectionHeading {...heading} />
      <ArticleGridLayouts.OffCenterList
        articles={learnCards.slice(0, articleLimit)}
        onLayoutChange={(layout) => setIsSingleColumn(layout.length === 1)}
        gap={{ column: 32, row: 0 }}
      />
      <br />
      <Button
        buttonStyle="bordered"
        icon="right"
        href="/covid-19-basics/understanding-covid-19"
      >
        Take Online Course
      </Button>
    </div>
  );
};

LearnTeaser.propTypes = {
  major: PropTypes.bool,
};

LearnTeaser.defaultProps = {
  heading: {
    isMajor: false,
    title: 'E-Learning: Understanding the COVID-19 Pandemic',
    description:
      'Johns Hopkins University is hosting a free, publicly available course titled Understanding the COVID-19 Pandemic: Insights from Johns Hopkins University Experts. This course is set up as a series of short modules to explore the COVID-19 pandemic. Registration is not required.',
  },
};

export default LearnTeaser;
