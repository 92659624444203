import React, { Component } from "react";

import styles from "./WebcastEmbed.module.scss";

class WebcastEmbed extends Component {
  render() {
    return (
      <div className={styles.base}>
        <div className={styles.videoEmbed}>
          <div className={styles.videoContainer}>
            <iframe
              title="WebCast"
              src="https://video.ibm.com/embed/22283603"
              style={{ border: 0 }}
              webkitallowfullscreen=""
              allowFullScreen
              frameBorder="no"
              width=""
              height=""
            ></iframe>
          </div>
        </div>
        <div className={styles.chatEmbed}>
          <iframe
            title="Q&A"
            src="https://video.ibm.com/socialstream/22283603?videos=0"
            style={{ border: 0 }}
            webkitallowfullscreen=""
            allowFullScreen
            frameBorder="no"
            width="320"
            height="302"
          ></iframe>
        </div>
      </div>
    );
  }
}

export default WebcastEmbed;
