import React, { useState } from 'react';
import { useHistory } from "react-router-dom";
import Annotation from "./Annotation";
import {makeStyles} from '@material-ui/core/styles';
import { slugify } from "data/geo";

const useStyles = makeStyles(theme => ({
    circle: {
        stroke: 'none',
        cursor: 'pointer'
    },
    below: {
      fill: '#E29360'
    },
    above: {
      fill: '#60C6C6'
    },
    line: {
        fill: 'none',
        strokeWidth: '1px',
        stroke: '#8E8E8E'
    },
    hover: {
      stroke: '#8E8E8E',
      cursor: 'pointer'
    }
}));

const Lollipops = ({r, x1, x2, y, labelFormat, labelText, first, threshold, mobile, region, regionType}) => {
  const history = useHistory();
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const regionURL = regionType === "state"
    ? `/region/us/${slugify(region)}`
    : `/region/${slugify(region)}`;

    const missingCodes = {
      Bermuda: "BM",
      "Cayman Islands": "KY",
      Gibraltar: "GI",
      Greenland: "GL",
      "Hong Kong": "HK",
      "Isle of Man": "IM",
      "Turks and Caicos Islands": "TC",
    };

  return (
      <g className="lolli">
          <line x1={x1} y1={y} x2={x2} y2={y} className={classes.line}/>
          <circle r={r} cx={x2} cy={y} className={`${mobile ? classes.circle : hover ? classes.hover : classes.circle} ${threshold === "below" ? classes.below : classes.above}`}
            onMouseOver={()=>setHover(true)}
            onMouseOut={()=>setHover(false)}
            onClick={missingCodes[region] ? null : ()=>history.push(regionURL)}/>
          <Annotation datapoint={labelText} formattype={labelFormat} x={x2+10} y={y} hovered={mobile ? true : hover} first={first}/>
      </g>
  );
};


export default Lollipops;
