import React from "react";
import styles from "./MortalityTable.module.scss";
import TFormat from "components/common/TFormat";

const MortalityTable = ({tableData}) => {
  return (
    <div className={styles.base}>
      <TFormat
        header={<h2>Cases and mortality by country</h2>}
        tableColumns={{
          regionName: "Country",
          confirmed: "Confirmed",
          deaths: "Deaths",
          percFatality: "Case-Fatality",
          deathsPopulation: "Deaths/100K pop.",
        }}
        tableData={tableData}
       />
    </div>
  );
};

export default MortalityTable;
