import React, { FC } from "react";
import styles from "./Slide.module.scss";
import cx from "classnames";
// import BasicCard from "components/common/cards/ContentCards/BasicCard";
import ArticleCard from "components/layout/ArticleCard";

export type TSlideProps = {
  id?: string;
  content?: any;
  href?: string;
  label?: string;
  selected?: boolean;
  className?: string;
};

const Slide: FC<TSlideProps> = ({
  content,
  id,
  href,
  className,
  selected = false,
}) => {
  return (
    <li className={styles.blade} data-id={id}>
      <div className={cx(styles.base, selected && styles.active, className)}>
        <div className={styles.contentContainer}>
          <div className={styles.blowOutOuter}>
            <div className={styles.blowOutContainer}>
              <div className={styles.card}>
                <ArticleCard
                  {...content}
                  major={true}
                  vertical={true}
                  containImage={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Slide;
