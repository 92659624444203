import React, { useRef, useState, useEffect } from "react";

import { getPage } from "data/util";
import Meta from "components/common/Meta";
import styles from "./Module.module.scss";
import Spinner from "components/common/Spinner";

const Module = ({ match, location }) => {
  const ifRef = useRef(null);
  const initialHash = location.hash;
  const [moduleLoaded, setModuleLoaded] = useState(false);

  useEffect(() => {
    const innerWindow = ifRef.current.contentWindow;

    // innerWindow is not available in jsdom/testing
    if (!innerWindow) return;

    const updateHash = () => {
      const hash = innerWindow.location.hash;
      if (window.history.pushState) {
        window.history.replaceState(null, null, hash);
      } else {
        location.hash = hash;
      }
    };

    const onLoad = () => setModuleLoaded(true);
    innerWindow.addEventListener("hashchange", updateHash);
    innerWindow.addEventListener("load", onLoad);
    return () => {
      innerWindow.removeEventListener("hashchange", updateHash);
      innerWindow.removeEventListener("load", onLoad);
    };
  }, [ifRef, location]);

  const page = getPage(location.pathname);

  return (
    <div className={styles.base}>
      <Meta {...page.meta} />
      <div className={styles.embed}>
        <iframe
          ref={ifRef}
          title="Example E-Learning Module"
          src={`/embed/${match.params.moduleId}/index.html${initialHash}`}
          style={{ border: 0 }}
          allowFullScreen
          frameBorder="no"
          width=""
          height=""
        />
      </div>
      {!moduleLoaded && (
        <div className={styles.loading}>
          <Spinner />
          <h5>Loading Module...</h5>
        </div>
      )}
    </div>
  );
};

export default Module;
