import React from "react";
import PropTypes from "prop-types";

import ArticleCard from "components/layout/ArticleCard";
import ColumnLayout from "components/layout/ColumnLayout";

import classNames from "classnames/bind";
import styles from "./ArticleGrid.module.scss";
const cx = classNames.bind(styles);

/**
 * These components comprise the ArticleGridLayoutEngine “group” templating
 * system.
 */

const SingleCard = () => null;

const CardStack = () => null;
CardStack.propTypes = {
  limit: PropTypes.number.isRequired,
  withDividers: PropTypes.bool,
};

const CustomGroup = () => null;
CustomGroup.propTypes = {
  content: PropTypes.node,
};

export const ArticleGridLayoutTemplate = {
  SingleCard,
  CardStack,
  CustomGroup,
};

/**
 * The ArticleGridLayoutEngine is the core component of the ArticleGrid layout
 * system.
 *
 * This component should only be used directly when creating custom layouts,
 * otherwise one of the pre-defined ArticleGridLayouts should be used instead,
 * as they provide an easier interface.
 *
 * The ArticleGridLayoutEngine is responsible for creating ArticleCards and
 * distributing them on a grid. The ArticleCards’ content is defined by the
 * 'articles' prop, and the grid layout is defined by a bespoke markup system in
 * two parts:
 *   1. The layout of the columns of the grid is defined by the columnLayout
 *      prop, according to the system outlined in the ColumnLayout component
 *   2. The layout of cards within each column is defined by a system of
 *      'groups' which are described by placing ArticleGridLayoutTemplate
 *      components placed as direct children of an ArticleGridLayoutEngine.
 * See the style guide for more detailed documentation.
 */
export function ArticleGridLayoutEngine({
  columnLayout, // - columnLayout defines the sizes of the columns
  children, //     - Children are 'template' markup elements that define
  //                 groups of cards as single cards or stacks of cards. Groups
  //                 will be distributed across the columns
  articles, //     - The articles are the content that is rendered
  ...props
}) {
  // Copy articles array
  articles = [...articles];
  const groups = [];

  // merge core "article" props and additional "override" props to create an
  // ArticleCard with a given key
  const getCard = (a, p, k) => a && <ArticleCard {...a} {...p} key={k} />;

  // Each child represents a group of cards. The groups are distributed across
  // the columns of the grid layout, and each group will be assigned one or more
  // cards, in order, depending on its type and parameters
  React.Children.forEach(children, ({ props: groupProps, type }) => {
    const group = { type, cards: [] };
    // populate cards into group
    if (group.type === SingleCard) {
      group.typeName = "SingleCard";
      // For a SingleCard, make a group with just one card
      const article = articles.shift();
      const { articleCardProps } = groupProps;
      const articleCard = getCard(article, articleCardProps, articles.length);
      group.cards.push(articleCard);
    } else if (group.type === CardStack) {
      group.typeName = "CardStack";
      // For a CardStack, make a group array with however many cards were
      // specified by the CardStack's limit prop
      const { limit: numItems, withDividers, articleCardProps } = groupProps;
      for (let i = 0; i < numItems; i++) {
        const article = articles.shift();
        const articleCard = getCard(article, articleCardProps, articles.length);
        group.cards.push(articleCard);
        // Add dividers if requested
        if (withDividers && i < numItems - 1 && articles.length) {
          group.cards.push(
            <div
              className={cx("divider", "horizontal")}
              key={`${articles.length}-divider`}
            />
          );
        }
      }
    } else if (group.type === CustomGroup) {
      group.typeName = "CustomGroup";
      // For a Custom card, render whatever content it has.
      const { content } = groupProps;
      group.cards = React.Children.toArray(content);
    }
    groups.push(group);
  });

  return (
    /* prettier-ignore */
    <ColumnLayout
      layout={columnLayout}
      gap={{ row: 64, column: 32 }}
      dividers={{ row: 'WHEN_FULL_WIDTH' }}
      {...props}
    >
      {/* Don't render groups that have no elements in them */}
      {groups.map(({ typeName, cards }, i) => cards[0] && (
        /* Render a div for each group */
        <div key={i} className={cx('group', typeName)} data-group-type={typeName}>
          {cards}
        </div>
      ))}
    </ColumnLayout>
  );
}

/**
 * These are the “out-of-the-box” ArticleGrid layouts
 */

export const ArticleGridLayouts = {
  OffCenter: (props) => (
    <ArticleGridLayoutEngine {...props} columnLayout="off-center">
      <SingleCard articleCardProps={{ major: true }} />
      <SingleCard articleCardProps={{ major: false, vertical: true }} />
    </ArticleGridLayoutEngine>
  ),
  OffCenterList: (props) => (
    <ArticleGridLayoutEngine
      {...props}
      columnLayout={[[9, 3], [8, 4], [6, 6], [12]]}
    >
      <SingleCard articleCardProps={{ major: true }} />
      <CardStack
        withDividers
        limit={4}
        articleCardProps={{
          major: false,
          vertical: true,
          imageSrc: null,
        }}
      />
    </ArticleGridLayoutEngine>
  ),
  Flexible: ({ limit, articleCardProps = {}, ...props }) => (
    <ArticleGridLayoutEngine {...props} gap={32} columnLayout="flexible">
      {new Array(limit || props.articles.length).fill(null).map((_, i) => (
        <SingleCard
          key={i}
          articleCardProps={{
            major: false,
            vertical: true,
            ...articleCardProps,
          }}
        />
      ))}
    </ArticleGridLayoutEngine>
  ),
  Custom: ({
    limit,
    articleCardProps = {},
    columnLayout = [[6, 6], [12]],
    ...props
  }) => (
    <ArticleGridLayoutEngine {...props} gap={32} columnLayout={columnLayout}>
      {new Array(limit || props.articles.length).fill(null).map((_, i) => (
        <SingleCard
          key={i}
          articleCardProps={{
            major: false,
            vertical: true,
            ...articleCardProps,
          }}
        />
      ))}
    </ArticleGridLayoutEngine>
  ),
};

// eslint-disable-next-line react/forbid-foreign-prop-types
const { propTypes: ArticleCardPropTypes } = ArticleCard;
ArticleGridLayoutEngine.propTypes = {
  articles: PropTypes.arrayOf(PropTypes.shape(ArticleCardPropTypes)).isRequired,
};
