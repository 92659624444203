import React from "react";
import cx from "classnames";

import styles from "./TestingInfographic.module.scss";
import serologyImg from "./images/serology.jpg";
import viralTest from "./images/test-viral.png";
import serologyTest from "./images/test-serology.png";
import questionIcon from "./images/icon-question.png";
import checkIcon from "./images/icon-check.png";
import crossIcon from "./images/icon-cross.png";
import fdaLogo from "./images/fda-logo.png";
import { ReactComponent as DownloadIcon } from "images/icons/download.svg";

const TestingInfographic = () => {
  return (
    <div className={styles.base}>
      <div className={cx(styles.container, styles.intro)}>
        <div className={styles.introCol1}>
          <h3>The Facts About COVID-19 Tests</h3>
          <p>
            COVID-19 tests are critical to measuring the spread of the disease
            and determining how to handle the pandemic. The two types of
            COVID-19 test – serology tests and viral tests – serve different
            purposes and have different limitations.
          </p>
          <p>
            <strong>
              Knowing the facts about these tests is essential to making
              decisions that are best for the public’s health.
            </strong>
          </p>
          <a
            className={styles.button}
            href="/downloads/JHU_Facts_About_COVID-19_tests.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            <DownloadIcon />
            <span>Download PDF</span>
          </a>
        </div>
        <div className={styles.introCol2}>
          <img src={serologyImg} alt="Serology" />
        </div>
      </div>

      <div className={styles.container}>
        <h2>The Two Test Types</h2>
      </div>

      <div className={styles.tableHeader}>
        <div className={styles.container}>
          <div className={styles.tableCol1}>
            <span>Question</span>
          </div>
          <div className={styles.tableCol2}>
            <span>Viral Test</span>
            <img src={viralTest} alt="Viral Test" />
          </div>
          <div className={styles.tableCol3}>
            <span>Serology Test</span>
            <img src={serologyTest} alt="Serology Test" />
          </div>
        </div>
      </div>

      <div className={cx(styles.container, styles.tableBody)}>
        <div className={styles.questionRow}>
          <div className={styles.tableCol1}>
            <img src={questionIcon} alt="Question Icon" />
            <span>What is the test?</span>
          </div>
          <div className={styles.tableCol2}>
            <h6>Viral Test:</h6>A viral test is an oral or nasal swab or saliva
            test that looks for{" "}
            <strong>evidence of an active viral infection</strong>. There are
            two major types: a PCR test and an antigen test.
          </div>
          <div className={styles.tableCol3}>
            <h6>Serology Test:</h6>A serology test is a blood test that looks
            for <strong>evidence of someone’s prior infection</strong> with the
            virus.
          </div>
        </div>
        <div className={styles.questionRow}>
          <div className={styles.tableCol1}>
            <img src={checkIcon} alt="Check Icon" />
            <span>What does the test do?</span>
          </div>
          <div className={styles.tableCol2}>
            <h6>Viral Test:</h6>
            PCR tests look for the{" "}
            <strong>presence of a virus’s genetic material</strong>, while
            antigen tests look for{" "}
            <strong>specific proteins on a virus’s surface</strong>. Antigen
            tests produce results more quickly, but may be less sensitive.
          </div>
          <div className={styles.tableCol3}>
            <h6>Serology Test:</h6>
            The test provides evidence that someone may have been exposed to the
            virus in the past, potentially even if they did not have symptoms,
            by <strong>detecting antibodies specific to the virus</strong>.
          </div>
        </div>
        <div className={styles.questionRow}>
          <div className={styles.tableCol1}>
            <img src={crossIcon} alt="Cross Icon" />
            <span>What doesn't the test do?</span>
          </div>
          <div className={styles.tableCol2}>
            <h6>Viral Test:</h6>
            Viral tests do not indicate{" "}
            <strong>whether someone was infected in the past</strong>.
          </div>
          <div className={styles.tableCol3}>
            <h6>Serology Test:</h6>
            The test does not{" "}
            <strong>
              diagnose an active infection or identify who is protected from
              reinfection
            </strong>{" "}
            (antibodies have not been proven to guarantee immunity).
          </div>
        </div>
        <div className={styles.questionRow}>
          <div className={styles.tableCol1}>
            <img src={fdaLogo} alt="FDA Logo" />
            <span>How does the FDA handle the test?</span>
          </div>
          <div className={styles.tableCol2}>
            <h6>Viral Test:</h6>
            The FDA formally <strong>evaluates these tests prior to use</strong>
            .
          </div>
          <div className={styles.tableCol3}>
            <h6>Serology Test:</h6>
            The FDA{" "}
            <strong>does not formally evaluate these tests prior to use</strong>
            , though a few have Emergency Use Authorization.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestingInfographic;
