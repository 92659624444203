import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import VaccineHome from "./VaccineHome";
import VaccineFAQ from "./VaccineFAQ";
import VaccineArticle from "./VaccineArticle";
import VaccineTimeline from "./VaccineTimeline";
import VaccineBlogs from "./VaccineBlogs";
import VaccineReports from "./VaccineReports";
import VaccineDataVisualization from "./VaccineDataVisualization";
import VaccineProcess from "./VaccineDataVisualization/VaccineProcess";
import VaccineStatePlans from "./VaccineStatePlans";
import DraftGuard from "components/common/DraftGuard";

export const basePathVaccine = "/vaccines";

const Vaccine = () => {
  return (
    <Switch>
      <Route
        key="timeline"
        path="/vaccines/timeline"
        component={VaccineTimeline}
      />
      <Route
        key="vaccine-state-plans"
        path="/vaccines/vaccine-state-plans"
        component={VaccineStatePlans}
      />
      <Route
        key="vaccine-blog"
        path="/vaccines/blog/:articleId"
        component={VaccineArticle}
      />
      <Route
        key="vaccine-blogs"
        path="/vaccines/blog"
        component={VaccineBlogs}
      />

      <Route
        key="vaccine-report"
        path="/vaccines/report/:articleId"
        component={VaccineArticle}
      />
      <Route
        key="vaccine-reports"
        path="/vaccines/report"
        component={VaccineReports}
      />

      <Route
        key="vaccines-faq"
        path="/vaccines/vaccines-faq"
        component={VaccineFAQ}
      />
      <Route
        key="q-n-a"
        path="/vaccines/q-n-a/:articleId"
        component={VaccineArticle}
      />

      <Route
        key="us-states"
        path="/vaccines/us-states"
        component={VaccineProcess}
      />
      <Route
        key="international"
        path="/vaccines/international"
        component={VaccineProcess}
      />

      {DraftGuard({
        children: [
          <Route
            key="data-visualization"
            path="/vaccines/data-visualization"
            component={VaccineDataVisualization}
          />,
        ],
      })}

      <Route
        key="vaccines-home"
        path="/vaccines"
        exact
        component={VaccineHome}
      />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Vaccine;
