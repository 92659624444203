import React from "react";
import { Waypoint } from "react-waypoint";

import styles from "./TimelineSplash.module.scss";
import timelines from "../../data/timelines.json";
import Icon from "../../components/Icon";

const TimelineSplash = ({ setSplashPosition }) => {
  const set = ({ currentPosition }) => setSplashPosition(currentPosition);

  return (
    <div className={styles.base}>
      <div className={styles.coverImage} />
      <div className={styles.container}>
        <h1>
          <span>Vaccine Research</span>{" "}
          <span className={styles.ampersand}>&</span> <span>Development</span>
        </h1>
        <h2>
          How can Covid-19 vaccine development be done{" "}
          <strong>
            quickly <u>and</u> safely?
          </strong>
        </h2>
        <hr />
        {timelines.map((timeline) => (
          <TimelineSummary {...timeline} key={timeline.id} />
        ))}
        <div className={styles.scrollPrompt}>
          <Icon icon="scroll" />
          <span>SCROLL TO EXPLORE DEVELOPMENT PHASES</span>
        </div>
      </div>
      <div className={styles.lowerBoundary}>
        <Waypoint onPositionChange={set} topOffset={0} />
      </div>
    </div>
  );
};

export default TimelineSplash;

const TimelineSummary = ({ icon, subtitle, summary }) => {
  return (
    <div className={styles.timelineSummary}>
      <Icon icon={icon} />
      <div>
        <h3>{subtitle}</h3>
        <ul>
          {summary.map((item, i) => (
            <li key={i}>{item}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
