import React, { useEffect, useState } from "react";
import styles from "./NewCasesTable.module.scss";
import TFormat from "components/common/TFormat";
import stateList from "data/data-viz/vaccine-us-states/state-list.json";
import territories from "data/data-viz/vaccine-us-states/state-no-page-list.json";
import stateNoPageList from "data/data-viz/vaccine-us-states/state-no-page-list.json";
import cx from "classnames";
import { TFormatCommonProps } from "components/common/TFormat/util";
import { findStateCode } from "data/geo";
import addThousandsSeparator from "util/addThousandsSeparator";
import moment from "moment";

const NewCasesTable = ({
  regionNameLinkStyle,
  limit = Infinity,
  hideViewAll = false,
  customSortConfig,
  mobileOptions = { useRegionCodeType: null, useNameShortening: true },
  className = "",
  overflowClassName,
  activeRow,
}) => {
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const setupData = async () => {
      if (mounted) {
        const stateData = {};
        const usTerritories = Object.keys(territories);

        // Filter out territories
        const states = Object.keys(stateList).filter(
          (region) => !usTerritories.includes(region)
        );

        // Fetch state case data
        let results = await Promise.all(
          states.map((s) => {
            const stateCode = findStateCode(s);
            if (!stateCode) return {};
            return Promise.all([
              fetch(
                `https://jhucoronavirus.azureedge.net/api/v3/timeseries/us/cases/${stateCode}.json`
              )
                .then((response) => response.json())
                .then((response) => response),
              fetch(
                `https://jhucoronavirus.azureedge.net/api/v2/regions/us/${stateCode.toLocaleLowerCase()}.json`
              )
                .then((response) => response.json())
                .then((response) => {
                  const result = response?.["confirmed_cases"]?.week;
                  return result;
                }),
            ]).then((response) => {
              const result = {
                name: stateList[s],
                data: response[0],
                pastWeek: response[1],
              };
              return result;
            });
          })
        );

        // Map to Object
        for (let i = 0; i < results.length; i++) {
          const region = results[i];
          if (!stateData[region.name]) stateData[region.name] = {};
          stateData[region.name] = {
            movingAvgData: region.data,
            pastWeek: region.pastWeek,
          };
        }

        const unavailable = "-";
        const hasData = (dataSet) =>
          typeof dataSet === "number" &&
          !isNaN(dataSet) &&
          Math.abs(dataSet) !== Infinity;

        const yesterday = moment().subtract(1, "d");
        const yesterdaysDate = yesterday.format("yyyy-MM-DD");
        const lastWeek = yesterday.subtract(7, "d");
        const lastWeeksDate = lastWeek.format("yyyy-MM-DD");

        const data = Object.entries(stateData).map(([stateName, data]) => {
          const { movingAvgData, pastWeek } = data;

          const todaysRawPositives =
            movingAvgData[yesterdaysDate]?.raw_positives;
          const lastWeeksRawPositives =
            movingAvgData[lastWeeksDate]?.raw_positives;

          let countIncrease, percentChange;
          countIncrease = todaysRawPositives - lastWeeksRawPositives;
          percentChange = (countIncrease / todaysRawPositives) * 100;

          return {
            regionName: stateName,
            "7-day_avg": hasData(movingAvgData[yesterdaysDate]?.["7-day_avg"])
              ? Math.ceil(
                  Number(movingAvgData[yesterdaysDate]["7-day_avg"])
                ).toString()
              : unavailable,
            count_increase: hasData(pastWeek)
              ? addThousandsSeparator(pastWeek)
              : unavailable,
            percent_increase: hasData(percentChange)
              ? percentChange.toFixed(2) + "%"
              : unavailable,
          };
        });

        setTableData(data);
        setLoading(false);
      }
    };
    setupData();
    mounted = false;
  }, []);

  if (isLoading) {
    return <div />;
  } else {
    const tableColumns = {
      regionName: "State Name",
      "7-day_avg": "7-day moving average",
      count_increase: "Count Increase\n(Past Week)",
      percent_increase: "% Increase\n(Past Week)",
    };

    return (
      <div className={cx(styles.base, className)}>
        <h3>Daily new cases</h3>
        <TFormat
          className={styles.tFormatTable}
          tableData={tableData}
          tableColumns={tableColumns}
          regionList={stateList}
          regionNoLinkList={stateNoPageList}
          mobileOptions={mobileOptions}
          customSortConfig={customSortConfig}
          path="/data/new-cases-50-states/"
          rowLimit={limit}
          regionNameLinkStyle={regionNameLinkStyle}
          hideViewAll={hideViewAll}
          overflowClassName={overflowClassName}
          hideButtonProps={{ className: styles.hideButton }}
          activeRow={activeRow}
        />
      </div>
    );
  }
};

NewCasesTable.propTypes = {
  ...TFormatCommonProps,
};

export default NewCasesTable;
