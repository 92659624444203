import { request } from "redux/util/fetch";

export const mortalityTableDataURL =
  "https://jhucoronavirus.azureedge.net/data/mortality_table.json";

export const getMortalityTableData = async () => {
  const data = await request(mortalityTableDataURL).catch((error) =>
    console.log(error)
  );
  return data;
};

const processTableData = (table) => {
  const {
    Country: regionName,
    Confirmed: confirmed,
    Deaths: deaths,
    "Case-Fatality": percFatality,
    "Deaths/100k pop.": deathsPopulation,
  } = table;

  return Object.keys(table.Country).map((i) => {
    return {
      regionName: regionName[i],
      confirmed: confirmed[i],
      deaths: deaths[i],
      percFatality: percFatality[i],
      deathsPopulation: deathsPopulation[i],
    };
  });
};

export const getMortalityDataset = (tableData) => processTableData(tableData);
