import React, { useState } from "react";
import Page from "components/layout/Page";
import { getArticlesByVertical, getPage } from "data/util";
import PDINews from "..";
import { pdiNewsSectionURL } from "../../PDIArticle";
import { getNormalizedPathname } from "util/helper";
// import styles from "./DataOutlook.module.scss";

const DataOutlook = ({ location }) => {
  const path = getNormalizedPathname(location.pathname);
  const page = getPage(path);

  const [pdiArticles] = useState(
    getArticlesByVertical(["pandemic-data-initiative"])
  );

  let filteredArticles = pdiArticles
    .filter(
      (article) =>
        !!article?.section && article.section === "Pandemic Data Outlook"
    )
    ?.map((article) => {
      article.meta.path = article.meta.path.replace(
        "news",
        pdiNewsSectionURL["Pandemic Data Outlook"]
      );
      return article;
    });

  if (!filteredArticles?.length) {
    filteredArticles = pdiArticles;
  }

  return (
    <Page page={page} wide>
      <PDINews articles={filteredArticles} />
    </Page>
  );
};

export default DataOutlook;
