import React, { FC } from "react";
import cx from "classnames";
import styles from "./GraphicLegend.module.scss";
import { legendList } from "../util";
import GraphicBlock, { GraphicBlockProps } from "../GraphicBlock";

export type GraphicLegendProps = {
  className?: string;
};

const GraphicLegend: FC<GraphicLegendProps> = ({ className }) => (
  <div className={cx(styles.base, className)}>
    {legendList.map((block, i) => {
      if (block === "SELegend") {
        return (
          <div key={`graphic-legend${block}${i}`} className={styles.joinLegend}>
            <GraphicBlock
              key={`graphic-legend${block}${i}`}
              variant={"HCLC"}
              type="legend"
              joinNext={true}
              noIcon={true}
              legendTextOverride={true}
            />
            <GraphicBlock
              key={`graphic-legend${block}${i + 1}`}
              variant={"RPOP"}
              type="legend"
              noIcon={true}
              legendTextOverride={"Simultaneously Eligible"}
            />
          </div>
        );
      }
      return (
        <GraphicBlock
          key={`graphic-legend${block}${i}`}
          className={styles.blockLegend}
          variant={block as GraphicBlockProps["variant"]}
          type="legend"
        />
      );
    })}
  </div>
);

export default GraphicLegend;
