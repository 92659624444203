import React, { useState, useRef, useEffect } from "react";
import stateList from "data/data-viz/vaccine-us-states/state-list.json";
import { extent } from "d3-array";
import Page from "components/layout/Page";
import CustomSelect from "components/common/CustomSelect";
import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import AllStateTimelines from "./AllStateTimelines";
import SelectedStateArea from "./SelectedStateArea";
import styles from "./VaccineStory.module.scss";
import { getPage } from "data/util";
import ReactMarkdown from "react-markdown";

const states = Object.entries(stateList).map(([value, label]) => {
  return { value: value, label: label };
});

const VaccineStory = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  const dataSets = [
    {
      name: "US States Timeline",
      key: "state",
      resourceKey: "vaccine_story_all_state_timelines",
    },
    {
      name: "US States Bar",
      key: "state",
      resourceKey: "vaccine_story_all_state_bar",
    },
    {
      name: "Selected State Area",
      key: "state",
      resourceKey: "vaccine_story_selected_state_area",
    },
  ];

  const [stateOptions, setStateOptions] = useState(null);
  const [stateSelected, setStateSelected] = useState(states[0]);
  const [stateCompared, setStateCompared] = useState(null);
  const [timelineData, isTLDataLoading] = useFetch(dataSets[0].resourceKey);
  const [barData, isBarDataLoading] = useFetch(dataSets[1].resourceKey);
  const [areaData, isAreaDataLoading] = useFetch(dataSets[2].resourceKey);

  useEffect(() => {
    if (!isBarDataLoading) {
      let dataStates = barData.map((d) => d.state);
      setStateOptions(states.filter((d) => dataStates.includes(d.label)));
    }
  }, [barData, isBarDataLoading]);

  const ref = useRef(null);
  const [width] = useDimensions(ref);

  const customSelectPrimaryProps = stateOptions
    ? {
        multi: false,
        noAllOption: true,
        allOptionLabel: stateOptions[0].label,
        options: stateOptions,
        currentSelected: stateSelected,
        onSelect: (selected) => setStateSelected(selected),
        classNames: styles.selector,
        label: "Select a primary state to highlight",
      }
    : {};
  const customSelectCompareProps = stateOptions
    ? {
        multi: false,
        resetToAll: stateCompared === null,
        allOptionLabel: "Select a state",
        options: stateOptions.filter((d) => d.label !== stateSelected.label),
        currentSelected: stateCompared,
        onSelect: (selected) => setStateCompared(selected),
        classNames: styles.selector,
        label: "Select another state to compare",
      }
    : {};

  return (
    <Page pageData={pageData}>
      <div className={styles.container}>
        <div className={styles.textContent}>
          <ReactMarkdown
            source={
              content_blocks?.["compare-state-vaccination-progress"]?.content
            }
          />
        </div>
        <div className={styles.controls}>
          {stateOptions && (
            <div className={styles.selectorRow}>
              <CustomSelect {...customSelectPrimaryProps} />
              <CustomSelect {...customSelectCompareProps} />
            </div>
          )}
        </div>
        <div className={styles.content} ref={ref}>
          <div>
            {isTLDataLoading ? (
              <p>... Loading</p>
            ) : (
              <AllStateTimelines
                states={states}
                data={timelineData}
                stateSelected={stateSelected}
                stateCompared={stateCompared}
                svgWidth={width}
              />
            )}
          </div>

          <div className={styles.textContent}>
            <ReactMarkdown
              source={
                content_blocks?.["examine-how-many-people-are-fully-vaccinated"]
                  ?.content
              }
            />
            {stateOptions && (
              <div className={styles.selectorRow}>
                <CustomSelect {...customSelectPrimaryProps} />
              </div>
            )}
          </div>
          <div>
            {isAreaDataLoading ? (
              <p>... Loading</p>
            ) : (
              <SelectedStateArea
                data={areaData.find((d) => d.state === stateSelected.label)}
                timeExtent={extent(
                  [
                    ...new Set(
                      areaData.map((d) => d.data.map((e) => e.date)).flat()
                    ),
                  ].sort()
                )}
                svgWidth={width}
              />
            )}
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
    </Page>
  );
};

export default VaccineStory;
