import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./RenderVideoFrame.module.scss";

const RenderVideoFrame = ({
  videoLinkType = 1,
  videoUrl,
  mediaHeight,
  iframeProps,
  isImage = false,
}) => {
  const containerRef = useRef(null);
  const frameRef = useRef(null);
  const [useMediaHeight, setMediaHeight] = useState(mediaHeight || 272);

  const ifrmProps = {
    style: { border: 0, height: `${useMediaHeight}px`, width: "100%" },
    allowFullScreen: true,
    allow:
      "webkitallowfullscreen accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture",
    frameBorder: "0",
    gesture: "media",
    ...iframeProps,
  };

  useEffect(() => {
    if (isImage && frameRef?.current) {
      frameRef.current.style.background = `url(${videoUrl})`;
      frameRef.current.style.backgroundRepeat = "no-repeat";
      frameRef.current.style.backgroundSize = "contain";
      frameRef.current.style.backgroundPosition = "center";
    }
  }, [frameRef, isImage, ifrmProps, videoUrl]);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef?.current) {
        setMediaHeight(containerRef.current.clientWidth * 0.6);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [containerRef]);

  if (!videoUrl) return null;
  if (isImage)
    return <div ref={frameRef} className={styles.image} {...ifrmProps} />;

  const containerVideoHeightAdjust = {
    height: `${useMediaHeight}px`,
  };

  if (!videoUrl) return null;
  if (isImage)
    return <div ref={frameRef} className={styles.image} {...ifrmProps} />;

  let fullProps;

  if (videoLinkType === 0) {
    fullProps = {
      id: "UStreamPlayer",
      title: "IBM",
      src: videoUrl,
      ...ifrmProps,
      frameBorder: "no",
    };
  } else {
    let lk = videoUrl.replace("youtu.be/", "youtube.com/embed/");
    // Fix the embed link to start from the supplied time
    lk = lk.replace("t=", "start=");
    fullProps = {
      title: "Youtube/Vimeo",
      src: lk,
      ...ifrmProps,
    };
  }
  return (
    <div
      ref={containerRef}
      className={styles.iframeContainer}
      style={containerVideoHeightAdjust}
    >
      <iframe title={fullProps.title} {...fullProps}></iframe>
    </div>
  );
};

RenderVideoFrame.propTypes = {
  videoUrl: PropTypes.string.isRequired,
  mediaHeight: PropTypes.number,
  iframeProps: PropTypes.object,
  isImage: PropTypes.bool,
};

export default RenderVideoFrame;
