import React, { useEffect } from "react";

import * as GTM from "util/gtm";
import styles from "./AboutTestingData.module.scss";
import { getPage } from "data/util";
import Page from "components/layout/Page";
import ReactMarkdown from "react-markdown";

const AboutTestingData = (props) => {
  const page = getPage(props.location.pathname);
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["about"],
    });
  }, []);

  return (
    <Page page={page}>
      <div className={styles.container}>
        <ReactMarkdown
          source={
            content_blocks?.["about-testing-data-announcement-content"]
              ?.content
          }
        />
      </div>
    </Page>
  );
};

export default AboutTestingData;
