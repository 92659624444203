import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames/bind";

import Button from "components/common/Button";
import styles from "./RegionSearchCTA.module.scss";
const cx = classNames.bind(styles);

const RegionSearchCTA = () => {
  const [query, setQuery] = useState("");
  const change = (e) => setQuery(e.target.value);
  const trapClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const history = useHistory();
  const go = (e) => {
    e.preventDefault();
    history.push("/region", { query });
  };

  return (
    <div className={cx("base")} onClick={trapClick}>
      <form onSubmit={go}>
        <input
          className={cx("input")}
          aria-label="Country or state"
          role="search"
          type="text"
          value={query}
          onChange={change}
          placeholder="Country or state..."
        />
        <Button onClick={go} className={cx("button")} icon="right" />
      </form>
    </div>
  );
};

export default RegionSearchCTA;
