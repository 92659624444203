import React from "react";
import ColumnLayout from "components/layout/ColumnLayout";
import ArticleCard from "components/layout/ArticleCard";
import { getSnapshotForRoute } from "data/util";
import { RegionSearchCard, TestingTrendsToolCard } from "../Home/IntroGrid/featured-content";
import { NewCases50StatesCard, NewCasesCountriesCard } from "../Home/Tracking/articles";

export default function ContentOptions() {
  return (
    <ColumnLayout layout="quartet">
      <ArticleCard {...NewCases50StatesCard} vertical containImage={false} />
      <ArticleCard
        {...TestingTrendsToolCard}
        vertical
        containImage={false}
        imageSrc={getSnapshotForRoute(TestingTrendsToolCard.href)}
        body={<p>See an overview of key testing metrics in each U.S. state</p>}
      />
      <ArticleCard
        {...NewCasesCountriesCard}
        vertical
        containImage={false}
        imageSrc={getSnapshotForRoute(NewCasesCountriesCard.href)}
      />
      <ArticleCard {...RegionSearchCard} />
    </ColumnLayout>
  );
};
