import React, { useContext } from "react";
import { format } from "d3-format";
import { fields as fieldsInternational, fieldsUSA } from "../../constants";
import styles from "./VaccineDataMapTooltip.module.scss";

const TooltipContext = React.createContext({
  show: false,
  x: 0,
  y: 0,
  datakey: null,
  datapoint: null,
  field: null,
});

const Tooltip = ({ width, height }) => {
  const { x, y, show, datakey, datapoint, field } = useContext(TooltipContext);
  let content;

  if (datapoint) {
    let textValue;
    if (typeof datapoint.data[field.value] === "string") {
      textValue = `${field.label}: Not Available`;
    } else if (field.value === "percent_full_vac") {
      textValue = `${field.label}: ${format(".1%")(
        datapoint.data[field.value] / 100
      )}`;
    } else {
      textValue = `${field.label}: ${format(",")(datapoint.data[field.value])}`;
    }

    const fields = datakey === "state" ? fieldsUSA : fieldsInternational;

    let spans = fields.map((f, i) => {
      if (f.value !== field.value) {
        let spantext = "";
        if (datapoint.data[f.value] === "Not Available") {
          spantext = `${f.label}: Not Available`;
        } else {
          spantext =
            f.value === "percent_full_vac"
              ? `${f.label}: ${format(".1%")(datapoint.data[f.value] / 100)}`
              : `${f.label}: ${format(",")(datapoint.data[f.value])}`;
        }
        return (
          <span key={`${i}`} className={styles.span}>
            {spantext}
          </span>
        );
      }
      return null;
    });
    content = (
      <>
        <span className={`${styles.span} ${styles.place}`}>
          {datapoint[datakey]}
        </span>
        <span className={styles.span}>{textValue}</span>
        {spans}
      </>
    );
  }

  return (
    <>
      {content && (
        <g
          transform={`translate(${x}, ${y})`}
          style={{
            visibility: show ? "visible" : "hidden",
            pointerEvents: "none",
          }}
        >
          <foreignObject width={width} height={height}>
            <div className={styles.tipBox} xmlns="http://www.w3.org/1999/xhtml">
              {content}
            </div>
          </foreignObject>
        </g>
      )}
    </>
  );
};

export default Tooltip;
export { TooltipContext };
