import React from 'react';
import classNames from "classnames";
import styles from "./StoppedAlert.module.scss";
import { ReactComponent as Warning } from "images/icons/warning.svg";

const StoppedAlert = ({
	className,
	type = null,
	state,
	states = [],
	category,
	stoppedDate,
}) => {
	return (
		<div className={classNames(styles.base, className)}>
			{/* <h3 className={styles.title}>{`${state} HAS STOPPED REPORTING COVID ${category} DATA`}</h3>
			<p className={styles.content}>{`We can no longer display an up-to-date statistic for COVID ${category} in ${states.length ? `following states: ${states.map(v => v)}` : state}.`}</p>
			{!!stoppedDate && <p className={styles.date}>Stopped Reporting: <span>{stoppedDate}</span></p>} */}
			<h3>THE CRC HAS STOPPED COLLECTING {type || "TESTING"} DATA</h3>
			<p>
				{type === "DEMOGRAPHIC" && "This chart is officially out of date as many states have discontinued reporting all or some Covid-19 demographic data. The CRC data visualizations will remain available for past dates and reports, but will no longer have up-to-date information on cases, deaths, people tested, or vaccinated by demographics."}
				{!type && "This chart is officially out of date as more than 30 states across the US have stopped reporting testing positivity or significantly scaled down their testing reports. The CRC data visualizations will remain available for past dates and reports, but will no longer have up to date information on testing positivity and criteria."}
			</p>
			<p className={styles.stoppedReportingDate}><Warning /> STOPPED REPORTING: <span>
				{type === "DEMOGRAPHIC" && "09/2/2022"}
				{!type && "09/21/2022"}
			</span></p>
		</div>
	);
};

export default StoppedAlert;
