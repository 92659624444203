import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import MediaQuery from "react-responsive";

import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./AnimatedWorldMap.module.scss";
import { DataTimeFetched } from "components/common/DataMeta";
import EmbedFrame from "components/common/EmbedFrame";
import SideNarrative from "components/layout/SideNarrative";
import { static_asset_path } from "util/hooks/useFetch";

const AnimatedWorldMap = (props) => {
  const page = getPage(props.location.pathname);
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "animated-world-map",
    });
  }, []);

  return (
    <SideNarrative
      narrative={
        <>
          <DataTimeFetched
            URL={
              "https://jhucoronavirus.azureedge.net/data/ts/animated_maps.json"
            }
          />
          <h4>{content_blocks["where-are-covid-19-cases-increasing"].title}</h4>
          <ReactMarkdown
            source={
              content_blocks["where-are-covid-19-cases-increasing"].content
            }
          />
        </>
      }
    >
      <div className={styles.container}>
        <h2>{content_blocks["cumulative-cases-over-time"].title}</h2>
        <h3>{content_blocks["cumulative-cases-over-time"].subtitle}</h3>
        <ReactMarkdown
          source={content_blocks["cumulative-cases-over-time"].content}
        />
        <MediaQuery maxWidth={767}>
          <EmbedFrame
            src={`${static_asset_path}cumulative-cases-map-mobile.html`}
            width={340}
            height={270}
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <EmbedFrame
            src={`${static_asset_path}cumulative-cases-map.html`}
            width={660}
            height={480}
            resize={false}
          />
        </MediaQuery>
        <h2>{content_blocks["cumulative-cases-over-time-1"].title}</h2>
        <h3>{content_blocks["cumulative-cases-over-time-1"].subtitle}</h3>
        <ReactMarkdown
          source={content_blocks["cumulative-cases-over-time-1"].content}
        />
        <MediaQuery maxWidth={767}>
          <EmbedFrame
            src={`${static_asset_path}daily-cases-map-mobile.html`}
            width={340}
            height={270}
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <EmbedFrame
            src={`${static_asset_path}daily-cases-map.html`}
            width={660}
            height={480}
            resize={false}
          />
        </MediaQuery>
      </div>
    </SideNarrative>
  );
};

export default AnimatedWorldMap;
