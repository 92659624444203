import PropTypes from "prop-types";
import { shouldShowDrafts } from "components/common/DraftGuard";
import TestingTrackerNavContent from "components/content/Testing/TestingTracker/ContentNav";
import TestingInsightsNavContent from "components/content/Testing/InsightsContentNav";
import ChartsNavContent from "components/content/DataCenter/ChartsNavContent";
import VaccinesNavContent from "components/content/Vaccine/VaccinesNavContent";
import VaccineFAQNavContent from "components/content/Vaccine/VaccineFAQ/ContentNav";

const getLiveRouteType = () => {
  if (
    window &&
    window.location &&
    window.location.pathname.includes("/live/events/")
  ) {
    return {
      exact: true,
    };
  }
  return {};
};

const groups = [
  {
    name: "Topics",
    id: "topics",
  },
];

const data = [
  {
    name: "Home",
    id: "home",
    to: "/",
    exact: true,
  },
  {
    name: "Tracking",
    id: "tracking",
    to: "/data",
    group: "topics",
    matchesRoutes: [
      "/data",
      "/tracking",
      "/map.html",
      "/us-map",
      "/covid-19-daily-video",
      "/data/demographic",
    ],
    sub: [
      {
        id: "tracking",
        name: "Tracking Home",
        to: "/data",
        exact: true,
        isBase: true,
      },
      {
        id: "global-map",
        name: "Global Map",
        to: "/map.html",
      },
      {
        id: "us-map",
        name: "U.S. Map",
        to: "/us-map",
      },
      {
        id: "critical-trends",
        name: "Data Visualizations",
        matchesRoutes: [
          "/data/new-cases",
          "/data/mortality",
          "/data/cumulative-cases",
          "/data/animated-world-map",
          "/data/racial-data-transparency",
          "/data/hubei-timeline",
          "/data/new-cases-50-states/:stateId?",
          "/data/state-timeline",
          "/data/variant-data",
          "/data/tracker/new-confirmed-per-100k-map",
          "/data/tracker/weekly-change-in-new-cases-map",
        ],
        navContent: {
          title: "Tracking Critical Data",
          component: ChartsNavContent,
        },
      },
      {
        id: "daily-covid",
        name: "Data in Motion",
        to: "/covid-19-daily-video",
      },
      {
        id: "tracking-faq",
        name: "Tracking FAQ",
        to:
          "/tracking/q-n-a/covid-19-transmission-linked-to-behavioral-not-meteorological-factors",
      },
    ],
  },
  {
    name: "Testing",
    id: "testing",
    to: "/testing",
    sub: [
      // The link labeled with isBase appears first in the list always
      {
        id: "testing",
        name: "Testing Home",
        to: "/testing",
        exact: true,
        isBase: true,
      },
      // After the “base” link comes links that are “contentful”
      {
        id: "testing-tracker",
        name: "Testing Tracker",
        to: "/testing/tracker/overview",
        // Since contentful nav elements often link to multiple pages, which
        // sometimes don't share a common base path, you should pass an array of
        // matchesRoutes instead of a single “to” route. “exact” still applies.
        matchesRoutes: ["/testing/tracker/overview", "/testing/tracker/map"],
        navContent: {
          title:
            "Key metrics for understanding the reach and severity of COVID-19 in a given area",
          component: TestingTrackerNavContent,
        },
      },
      {
        id: "testing-insights",
        name: "Data Visualizations",
        matchesRoutes: [
          "/testing/states-comparison",
          "/testing/individual-states",
          "/testing/testing-positivity",
          "/testing/international-comparison",
        ],
        navContent: {
          title: "Select a graph below",
          component: TestingInsightsNavContent,
        },
      },
      // After the contentful links comes other static single-route links.
      // NOTE: the order of these three “groups” of subnav items is
      // automatically enforced, but
      {
        id: "testing-faq",
        // name: "Questions about Testing",
        name: "FAQ",
        to: "/testing/testing-faq",
      },
      {
        id: "changes-to-test-positivity-tracking-efforts",
        name: "Differences in Positivity Rates",
        to: "/testing/differences-in-positivity-rates",
      },
      {
        id: "about-testing-data",
        name: "About Our Testing Data",
        to: "/testing/jhu-covid-19-testing-data",
      },
    ],
  },
  {
    name: "Contact Tracing",
    id: "tracing",
    to: "/contact-tracing",
    group: "topics",
    sub: [
      {
        id: "tracing",
        name: "Tracing",
        to: "/contact-tracing",
        exact: true,
      },
      {
        id: "principles",
        name: "Ethics of Digital Contact Tracing: Principles",
        to: "/contact-tracing/principles",
      },
      {
        id: "results",
        name: "JHCHS Survey Results",
        to: "/contact-tracing/state-survey-results",
      },
    ],
  },
  {
    name: "Vaccines",
    id: "vaccines",
    to: "/vaccines",
    group: "topics",
    matchesRoutes: ["/live/events/covid-19-vaccine-symposium"],
    sub: [
      // The link labeled with isBase appears first in the list always
      {
        id: "vaccines",
        name: "Vaccines",
        to: "/vaccines",
        exact: true,
        isBase: true,
      },
      {
        id: "vaccines-tracker",
        name: "Data Visualizations",
        matchesRoutes: ["/vaccines/us-states", "/vaccines/international"],
        navContent: {
          title: "Tracking Vaccines Data",
          component: VaccinesNavContent,
        },
      },
      // After the “base” link comes links that are “contentful”
      {
        id: "vaccines-faq",
        name: "Vaccines FAQ",
        to: "/vaccines/vaccines-faq",
        // Since contentful nav elements often link to multiple pages, which
        // sometimes don't share a common base path, you should pass an array of
        // matchesRoutes instead of a single “to” route. “exact” still applies.
        matchesRoutes: ["/vaccines/vaccines-faq", "/vaccines/q-n-a"],
        navContent: {
          title: "COVID-19 Vaccines Q & A",
          component: VaccineFAQNavContent,
        },
      },
      {
        id: "vaccines-report",
        name: "Primer on COVID-19 Vaccine",
        to: "/vaccines/report",
      },
      {
        id: "vaccines-blog",
        name: "COVID-19 Vaccine Matters",
        to: "/vaccines/blog",
      },
    ],
  },
  {
    name: "Pandemic Data Initiative",
    id: "pandemic-data-initiative",
    to: "/pandemic-data-initiative",
    group: "topics",
    sub: [
      {
        id: "pandemic-data-initiative",
        name: "Pandemic Data Initiative",
        to: "/pandemic-data-initiative",
        exact: true,
        isBase: true,
      },
      {
        id: "pandemic-data-faq",
        name: "FAQ",
        to: "/pandemic-data-initiative/pandemic-data-faq",
      },
      {
        id: "pdi-expert-forum",
        name: "Expert Forum",
        to: "/live/events/pandemic-data-initiative-expert-forum",
      },
      {
        id: "pandemnic-data-outlook",
        name: "Data Outlook",
        to: "/pandemic-data-initiative/data-outlook",
      },
      {
        id: "pandemnic-expert-insight",
        name: "Expert Insight",
        to: "/pandemic-data-initiative/expert-insight",
      },
    ],
  },
  {
    name: "By Region",
    id: "region",
    to: "/region",
    matchesRoutes: ["/region", "/region-data-notes"],
    sub: [
      {
        id: "all-regions",
        name: "All Regions",
        to: "/region",
        exact: true,
        isBase: true,
      },
      {
        id: "region-notes",
        name: "Data Notes",
        to: "/region-data-notes",
      },
    ],
  },
  {
    name: "Events & News",
    id: "news-and-information",
    to: "/live",
    matchesRoutes: [
      "/news",
      "/live",
      "/covid-19-basics",
      "/from-our-experts",
      "/research-papers",
    ],
    sub: [
      {
        id: "live",
        name: "Webcasts & Videos",
        isBase: true,
        to: "/live",
        ...getLiveRouteType(),
      },
      {
        id: "covid-19-at-hopkins",
        name: "30-Minute COVID-19 Briefing",
        to: "/live/events/covid-19-briefing-expert-insights",
      },
      {
        id: "learn",
        name: "E-Learning",
        to: "/covid-19-basics/understanding-covid-19",
      },
      { id: "news", name: "All News", to: "/news" },
      {
        id: "research-papers",
        name: "Research Papers",
        to: "/research-papers",
      },
    ],
  },
  {
    name: "About",
    id: "about",
    to: "/about",
    matchesRoutes: ["/about", "/map-faq", "/us-map-faq"],
    sub: [
      {
        id: "about",
        name: "About Us",
        isBase: true,
        to: "/about",
      },
      {
        id: "map-faq",
        name: "World Map FAQ",
        to: "/map-faq",
      },
      {
        id: "us-map-faq",
        name: "U.S. Map FAQ",
        to: "/us-map-faq",
      },
      {
        id: "how-to-use-our-data",
        name: "How to Use Our Data",
        to: "/about/how-to-use-our-data",
      },
    ],
  },
];

// Include sections that are not drafts, or, if REACT_APP_DRAFT === 'true', include all routes
const removeDrafts = ({ draft: isDraft }) => !isDraft || shouldShowDrafts;

const fitleredData = data.filter(removeDrafts).map((section) => ({
  ...section,
  ...(section?.sub && {
    sub: section.sub.filter(removeDrafts),
  }),
}));

export default fitleredData;

export const suppressedFooterRoutes = [
  {
    path: "/covid-19-basics/understanding-covid-19/module-",
    exact: false,
  },
  {
    path: "/vaccines/timeline",
    exact: true,
  },
];

export const getGroupedRoutes = () => {
  const result = groups?.map((group) => {
    const routes = data?.filter((route) => route.group === group.id);
    return (
      routes?.length && {
        ...group,
        isGroup: true,
        routes,
      }
    );
  });
  return result || [];
};

export const sectionProps = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  to: PropTypes.string,
  group: PropTypes.string,
  matchesRoutes: PropTypes.array,
  sub: PropTypes.array,
};

export const sectionGroupProps = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape(sectionProps)).isRequired,
  isGroup: PropTypes.bool,
};
