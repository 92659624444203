import React from "react";
import styles from "./PositivityDropdown.module.scss";
import { arrayToFilterOption, TFilterOption } from "util/hooks/useSingleSelect";
import PositivityDropdown from "components/common/filters/PositivityDropdown";

export const createMockSelectOptions: (
  num: number,
  label?: string
) => TFilterOption[] = (num, label = "Option"): TFilterOption[] => {
  return arrayToFilterOption(
    new Array(num).fill(null).map((_, index) => `${label} ${index + 1}`)
  );
};

const PositivityDropdownStyleGuide = (props) => {
  return (
    <div className={styles.container}>
      <h1 className={styles["styleguide-annotation"]}>
        Test Positivity Dropdown
      </h1>

      <br />

      <div style={{ maxWidth: 408 }}>
        <PositivityDropdown />
      </div>
      <br />
    </div>
  );
};

export default PositivityDropdownStyleGuide;
