import { frequencyText } from "../Map/util";
import testdata from "./test-data.json";

type TDataSet = {
  days?: number;
  frequency?: string;
  updated?: string;
};
type TStateData = {
  regionName: string;
  cases?: TDataSet;
  deaths?: TDataSet;
  tests?: TDataSet;
  vaccines?: TDataSet;
};

export const formatTableData = (tableData = testdata, isMobile = false) => {
  tableData = tableData || testdata;
  let formattedData = {};
  for (let i = 0; i < tableData.length; i++) {
    const data = tableData[i];
    const regionName = data.state;

    if (!formattedData[regionName]) {
      formattedData[regionName] = {};
    }
    formattedData[regionName][data.dataset.toLowerCase()] =
      frequencyText(data, isMobile) || "-";
  }

  const result = Object.keys(formattedData)?.map((key, i) => {
    return {
      ...formattedData[key],
      regionName: key,
    };
  });

  return result;
};
