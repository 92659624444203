import React from "react";
import { Link, useHistory } from "react-router-dom";
import styles from "./StateGraph.module.scss";
import stateList from "data/data-viz/testing/state-list";
// import stateListFormated from "data/data-viz/testing/state-list-formated.json"; //Adds underscore to multi-word states to match their html files
import { findStateCode, getRegionDataNotes } from "data/geo";
import Button from "components/common/Button";
import DataSelection from "./DataSelection"

const StateGraph = ({ stateId }) => {
  const history = useHistory();
  const handleChoice = (e) => {
    history.push(`/testing/individual-states/${e.currentTarget.value}`, {
      noScroll: true,
    });
  };

  const state = stateId || "usa";
  const allStates = state === "usa";
  const hasStatePage = !!findStateCode(state);
  const hasRegionNotes =
    allStates || getRegionDataNotes(findStateCode(state), true)?.length > 0;

  return (
    <div className={styles.base}>
      <div className={styles.controlsContainer}>
        <div className={styles.chooser}>
          <select value={state} onChange={handleChoice}>
            {Object.keys(stateList).map((stateId) => (
              <option key={stateId} value={stateId}>
                {stateList[stateId]}
              </option>
            ))}
          </select>
        </div>
        {!!hasStatePage && (
          <Button
            buttonStyle="underlined"
            className={styles.statePageLink}
            icon="right"
            href={`/region/us/${state}`}
          >
            <span>State Profile</span>
          </Button>
        )}
        {!!hasRegionNotes && (
          <Button
            buttonStyle="underlined"
            className={styles.statePageLink}
            icon="right"
            href={`/region-data-notes${allStates ? "" : `/${state}`}`}
          >
            <span>Data Notes</span>
          </Button>
        )}
      </div>

      <DataSelection stateId={findStateCode(stateId)} />

      <div className={styles.snapshotMenu}>
        {Object.keys(stateList).map((stateId) => (
          <Link key={stateId} to={`/testing/individual-states/${stateId}`}>
            {stateList[stateId]}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StateGraph;
