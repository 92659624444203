export const percentFullVacLabels = {
  state: "% of People Fully Vaccinated",
  country: "% of Population receiving at least 1 dose",
};
export const rawFullVacLabels = {
  state: "People Fully Vaccinated",
  country: "People receiving at least 1 dose",
};

export const dataSets = [
  {
    name: "US States",
    key: "state",
    resourceKey: "state_vaccination_rates",
  },
  {
    name: "International",
    key: "country",
    resourceKey: "global_vaccines",
  },
];

export const fields = [
  {
    label: percentFullVacLabels.country,
    value: "percent_full_vac",
  },
  { label: "Doses Administered", value: "doses_admin" },
  { label: rawFullVacLabels.country, value: "raw_full_vac" },
];

export const fieldsUSA = [
  {
    label: percentFullVacLabels.state,
    value: "percent_full_vac",
  },
  { label: "Doses Administered", value: "doses_admin" },
  { label: rawFullVacLabels.state, value: "raw_full_vac" },
];

export const vizTypes = [
  { label: "Graph", value: "Graph" },
  { label: "Map", value: "Map" },
];
