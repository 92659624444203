import PropTypes from 'prop-types';

export const shouldShowDrafts = process.env.REACT_APP_DRAFT === 'true';

export default function DraftGuard({ children }) {
  return shouldShowDrafts
    ? children
    : null;
}

DraftGuard.propTypes = {
  children: PropTypes.node.isRequired,
};
