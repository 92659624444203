import React from "react";
import styles from "./Hformat.module.scss";
import PropTypes from "prop-types";

const HFormat = ({
  size,
  noMargins,
  noMarginBtm,
  noMarginTop,
  noWrap,
  allCaps,
  children,
  classNames,
}) => {
  const className = [
    styles.base,
    classNames,
    noWrap ? styles.noWrap : null,
    allCaps ? styles.allCaps : null,
    noMargins ? styles.noMargins : null,
    !noMargins && noMarginBtm ? styles.noMarginBtm : null,
    !noMargins && noMarginTop ? styles.noMarginTop : null,
  ].join(" ");

  switch (size) {
    case 1:
      return <h1 className={className}>{children}</h1>;
    case 2:
      return <h2 className={className}>{children}</h2>;
    case 3:
      return <h3 className={className}>{children}</h3>;
    case 4:
      return <h4 className={className}>{children}</h4>;
    default:
      return <h5 className={className}>{children}</h5>;
  }
};

HFormat.propTypes = {
  size: PropTypes.number.isRequired,
  allCaps: PropTypes.bool,
  noWrap: PropTypes.bool,
  noMargins: PropTypes.bool,
  noMarginBtm: PropTypes.bool,
  noMarginTop: PropTypes.bool,
  children: PropTypes.any,
  classNames: PropTypes.string,
};

export default HFormat;
