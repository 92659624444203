import React from "react";
import { Link } from "react-router-dom";
import styles from "./Section.module.scss";
import cx from "classnames";
import Button from "components/common/Button";

const Section = ({
  title,
  content,
  className,
  anchor,
  buttonProps = {
    text: "What do these metrics mean?",
    href: null,
    onClick: null,
    style: null,
  },
  children,
}) => {
  return (
    <div key={anchor} className={cx(styles.base, className)}>
      <div className={styles.sectionText}>
        {!!anchor ? (
          <Link id={anchor} href={anchor} to="">
            <h3>{title}</h3>
          </Link>
        ) : (
          <h3>{title}</h3>
        )}
        <p>{content}</p>
        {!!children && <div className={styles.children}>{children}</div>}
        {!!buttonProps && (
          <div className={styles.buttonContainer}>
            <Button
              icon="right"
              href={buttonProps.href}
              buttonStyle={buttonProps.style}
              onClick={buttonProps.onClick}
              targetBlank
            >
              {buttonProps.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section;
