import React from "react";
import { Redirect, useParams, withRouter } from "react-router-dom";
import { getEvent, getPage } from "data/util";
import Events from "../index.js";
import { EventInfo, EventPageData } from "../util/index.js";

export const EventSwitch = () => {
  const { eventId, videoId } = useParams();
  const baseURL = `/live/events/${eventId}`;
  const eventPage = getPage(baseURL);

  const event = getEvent(eventId);
  if (!event) return <Redirect to="/404" />;

  const eventData = EventInfo(getEvent(eventId));
  const eventInfo = !!eventData
    ? {
        ...eventData,
        ...eventPage,
        title: eventPage?.title || eventData?.title,
        subtitle: eventPage?.subtitle || eventData?.subtitle,
        caption: eventPage?.caption || eventData?.caption,
        content:
          eventPage?.content_blocks?.[Object.keys(eventPage.content_blocks)[0]]
            ?.content || eventData?.content,
      }
    : undefined;

  if (!eventInfo) return <Redirect to="/404" />;

  return (
    <Events
      eventId={eventId}
      videoId={videoId}
      pageData={EventPageData(eventInfo)}
      info={eventInfo}
      dates={eventInfo.dates}
    />
  );
};

export default withRouter(EventSwitch);
