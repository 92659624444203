import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";

import { ReactComponent as CrossIcon } from "images/icons/cross.svg";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";
import styles from "./SearchField.module.scss";
const cx = classNames.bind(styles);

const SearchField = ({
  defaultQuery,
  placeholder,
  context,
  onChange,
  onClick,
  onClose,
  onKeyPress,
}) => {
  const [query, setQuery] = useState(defaultQuery);

  //Fixes persisting query after option selected
  useEffect(() => {
    if (query && !defaultQuery) {
      setQuery("");
    }
  }, [query, defaultQuery]);

  const clear = (e) => {
    setQuery("");
    onChange("", true);
    onClose && onClose(e);
  };

  const change = (e) => {
    setQuery(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className={cx("base", cx(context))} onClick={onClick}>
      <SearchIcon
        className={cx("icon-search", {
          invisible: context === "nav" && onClose,
        })}
      />
      {!!placeholder && !query && (
        <span className={cx("placeholder")}>{placeholder}</span>
      )}
      <input
        type="text"
        value={query}
        onChange={change}
        className={cx("input")}
        onKeyPress={onKeyPress}
      />
      <CrossIcon
        onClick={clear}
        className={cx("icon-clear", {
          visible: (context === "nav" && onClose) || !!query.length,
        })}
      />
    </div>
  );
};

SearchField.propTypes = {
  defaultQuery: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  context: PropTypes.oneOf(["page", "nav"]),
};

SearchField.defaultProps = {
  defaultQuery: "",
  onChange: () => null,
  placeholder: "Search...",
};

export default SearchField;
