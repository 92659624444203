import React, { Component } from "react";
import { withRouter, Link, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import styles from "./Header.module.scss";
import Nav from "../Nav";
import NavButton from "../NavButton";
import ContentNav from "../Nav/ContentNav";
import routes from "data/navigation";
import CVLogo from "images/jhu-logo-white-horizontal.svg";
import RegionSearch from "components/content/ByRegion/RegionSearch";
import PageNavigator from "components/common/PageNavigator";

// Route matching functions
export const routeMatches = (currentPath, r, exact) =>
  !!matchPath(currentPath, { path: r, exact });

export const anyMatches = (currentPath, rs, exact) =>
  (rs || []).some((r) => routeMatches(currentPath, r, exact));

// prettier-ignore
export const navItemMatches = (currentPath, navItem) => (
  // Case 1: the current route matches the marked "sub route"
  routeMatches(currentPath, navItem.to, navItem.exact)
  ||
  // Case 2: the current route matches one of the “matches routes” but none of the “avoids routes”
  (
    anyMatches(currentPath, navItem.matchesRoutes, navItem.exact)
    && !anyMatches(currentPath, navItem.avoidRoutes, navItem.exact)
  )
)
const cx = classNames.bind(styles);

class Header extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  state = {
    isMenuOpen: false,
    isRegionSearchOpen: false,
  };

  handleMenuClose = () => {
    this.setState({ isMenuOpen: false });
  };

  handleMenuOpen = () => {
    this.setState({ isMenuOpen: true });
  };

  handleRegionSearchMenu = (isOpen) => {
    this.setState({ isRegionSearchOpen: isOpen });
  };

  render() {
    const { isMenuOpen, isRegionSearchOpen } = this.state;
    const { location } = this.props;
    const { pathname } = location;

    const isEmbed =
      location?.search && location.search.split("?").includes("embed");

    // Kind of hacky way to prevent the double underline issue with subnavs the point to /live

    const navigationRoutes = routes.filter((route) => route.id !== "testing");

    const getActiveSubs = () => {
      const matchSubs = (navItem) =>
        navItem?.sub?.length && navItemMatches(pathname, navItem);
      const getActiveTopicSubs = () => {
        let activeSubs;
        for (let index = 0; index < navigationRoutes.length; index++) {
          const navItem = navigationRoutes[index];
          if (navItem?.type === "group") {
            activeSubs = navItem.children.find((item) => matchSubs(item))?.sub;
            if (!!activeSubs) return activeSubs;
          }
        }
      };

      let activeSubs =
        navigationRoutes.find((navItem) => matchSubs(navItem))?.sub ||
        getActiveTopicSubs();

      if (pathname.includes("/live/events/")) {
        activeSubs = activeSubs.map((sub) => {
          return sub.to === "/live" ? { ...sub, exact: true } : sub;
        });
      }
      return activeSubs;
    };
    const activeSubNav = getActiveSubs();

    const logo = (
      <img
        className={styles.brand}
        src={CVLogo}
        role="banner"
        alt="Johns Hopkins Coronavirus Resource Center"
      />
    );

    return (
      <header className={styles.base}>
        <div className={`${styles.container} headerbuff-include`}>
          {isEmbed ? (
            //Remove home link for ?embed
            <div className={styles.banner}>{logo}</div>
          ) : (
            <Link to="/" alt="" className={styles.banner}>
              {logo}
            </Link>
          )}

          {!isEmbed && (
            <>
              <NavButton
                isOpen={isMenuOpen}
                onClose={this.handleMenuClose}
                onOpen={this.handleMenuOpen}
              />
              <Nav
                routes={navigationRoutes}
                isOpen={isMenuOpen}
                onClose={this.handleMenuClose}
              />
            </>
          )}
        </div>

        {/* Render ContentNav below main nav whenever we're in a route with a subnav */}
        {!isEmbed && activeSubNav && (
          <>
            <ContentNav routes={activeSubNav} />
            <RegionSearch
              className={cx(
                styles.regionSearchContainer,
                !isRegionSearchOpen ? "headerbuff-include" : ""
              )}
              onMenuChange={this.handleRegionSearchMenu}
              context="nav"
            />
            <div className={styles.pageNavContainer}>
              <PageNavigator
                className={styles.pageNav}
                show={!isRegionSearchOpen}
                inner={false}
              />
            </div>
          </>
        )}
      </header>
    );
  }
}

export default withRouter(Header);
