import { useState, useLayoutEffect } from "react";

const useDimensions = (ref, defaultWidth = 800, defaultHeight = 600) => {
  const [dims, setDims] = useState([
    defaultWidth,
    defaultHeight,
  ]);
  useLayoutEffect(() => {
    const handleResize = () => {
      setDims([
        ref.current.clientWidth,
        ref.current.clientHeight,
      ]);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref]);

  return dims;
};

export default useDimensions;
