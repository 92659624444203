import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';

const Cell = ({ cell }) => {
  const tipStyle = {
    backgroundColor: "#fff",
    opacity: ".8",
    padding: "10px",
    border: "solid 1px black",
    borderRadius: "10px",
    color: "#000",
    textAlign: "center",
    margin: cell["alpha-2"] === "AK" || cell["alpha-2"] === "HI" ? "50px 0 0 -10px" : "50px 0 0 10px"
  }

  const LightTooltip = withStyles((theme) => ({
    tooltip: tipStyle,
    popperArrow: {
      marginLeft: "20px"
    }
  }))(Tooltip);
  return (
    <LightTooltip
      arrow
      placement="top"
      enterTouchDelay={300}
      leaveTouchDelay={3000}
      title={
        <React.Fragment>
          {cell.tiptext}
        </React.Fragment>
      }
    >
      <Grid item xs={1}>
        <div style={cell.style}>
          <span style={cell.spanStyle}>{cell["alpha-2"]}</span>
        </div>
      </Grid>
    </LightTooltip>
  );
};

export default Cell;
