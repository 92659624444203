import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { getFilteredContent } from 'data/util';
import { default as allVerticals } from 'data/verticals';

import {
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
  ArticleGridLayouts,
} from 'components/layout/ArticleGrid';
import { mapContentsToCards } from 'components/layout/ArticleCard/utils';
import getPlaceholderImage from 'util/placeholder-image';
import classNames from 'classnames/bind';
import styles from './NewsFeed.module.scss';
import Button from 'components/common/Button';
import CustomSelect from 'components/common/CustomSelect';
const cx = classNames.bind(styles);

const NewsFeed = ({
  // Can display fist few items in a featured grid:
  feature,
  // In case you want to start with N pages visible:
  page,
  pageSize,
  // You can provide a list of verticals/types that are "pre-selected".
  // These can not be un-selected by the user.
  scopeVerticals,
  scopeTypes,
  // Allow/disallow the user to select verticals/types.
  // Does not override scopeVerticals/scopeTypes.
  chooseVerticals,
  chooseTypes,
}) => {

  const allVerticalContent = getFilteredContent();
  const [allVerticalOptions] = useState(allVerticals.filter(
    (v) =>
      !!allVerticalContent.find((c) => !!c.verticals.find((cv) => cv === v.slug))
    ));

  // Filter State:
  const [currentVerticals, setCurrentVerticals] = useState(scopeVerticals);
  const [currentTypes, setCurrentTypes] = useState(scopeTypes);
  const [childSelectReset, setChildSelectResetState] = useState(false);

  useEffect(() => {
    if (childSelectReset){
      setChildSelectResetState(false);
    };
  }, [childSelectReset]);

  // Select one vertical:
  const toggleVertical = (vertical) => {
    if (!vertical.slug || currentVerticals.indexOf(vertical.slug) !== -1) {
      setCurrentVerticals([]);
    } else {
      setCurrentVerticals([vertical.slug]);
    };
    setCurrentTypes([]);
    setChildSelectResetState(true)
  };

  // Multi-select types
  const toggleType = (types) => {
    if (!types.label || types.value === "all-content" || currentTypes.indexOf(types.label) !== -1) {
      setCurrentTypes([]);
    } else {
      setCurrentTypes([types.label]);
    }
  };

  // Pagination State:
  const [currentPage, setCurrentPage] = useState(page);
  const incrementPage = () => setCurrentPage(currentPage + 1);

  // Select content to render:
  const contents = getFilteredContent({
    verticals: currentVerticals,
    types: currentTypes,
  });

  const contentCards = mapContentsToCards(
    contents.map((content) => ({
      ...content,
      image: content.image || getPlaceholderImage(content.id),
    }))
  );

  const visibleContents = contentCards.slice(0, pageSize * currentPage + (feature ? 3 : 0));
  const isMore = contentCards.length - visibleContents.length > 0;

  // Split up content if "feature" is true:
  const featuredContents = visibleContents.slice(0, 3);
  const visibleGridContents = feature
    ? visibleContents.slice(3)
    : visibleContents;


  // Types filter options: Based on currently selected content
  const types = contents
    .map((c) => c.type)
    .filter((t) => !!t)
    .filter((t, i, a) => a.indexOf(t) === i);

  const showTypes = chooseTypes && !!types.length;

  let allTypeOptions = {};
  getFilteredContent({
    verticals: currentVerticals,
    types: scopeTypes,
  }).forEach(c => {
    allTypeOptions[c.type] = c.type;
  })
  allTypeOptions = Object.keys(allTypeOptions)
    .map((type) => { return { 
      label: type, 
      value: type
    };}
  );

  const verticalOptions = allVerticalOptions.map((vertical) => {
    const newV = Object.assign({}, vertical);
    newV.label = vertical.title;
    newV.value = vertical.slug;
    return newV;
  });

  return (
    <div className={cx("base")}>
      <h2>Latest News & Resources</h2>
      <div className={cx("controls")}>
        {chooseVerticals && (
          <CustomSelect
            multi={false}
            label="Show Me"
            allOptionLabel= "All Topics"
            options={verticalOptions}
            onSelect={toggleVertical}
          />
        )}
        {showTypes && (
          <CustomSelect
            multi={false}
            label="Filter By"
            allOptionLabel= "All"
            options={allTypeOptions}
            onSelect={toggleType}
            resetToAll={childSelectReset}
          />
        )}
      </div>
      <div className={cx("content")}>
        {!contents.length && <p>There is nothing to display.</p>}
        {feature && (
          <>
            <ArticleGridLayoutEngine
              columnLayout="duet"
              articles={featuredContents}
            >
              <ArticleGridLayoutTemplate.SingleCard
                articleCardProps={{ major: true }}
              />
              <ArticleGridLayoutTemplate.CardStack
                limit={2}
                withDividers
                articleCardProps={{
                  major: false,
                  vertical: false,
                  imageSrc: null,
                }}
              />
            </ArticleGridLayoutEngine>
            <br />
          </>
        )}
        <ArticleGridLayouts.Flexible articles={visibleGridContents} />
        {isMore && (
          <div className={cx("grid-footer")}>
            <Button
              icon="down"
              shape="rounded"
              className={cx("paginator")}
              onClick={incrementPage}
            >
              Show More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

NewsFeed.propTypes = {
  feature: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  scopeVerticals: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  scopeTypes: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showVerticals: PropTypes.bool,
  showTypes: PropTypes.bool,
};

NewsFeed.defaultProps = {
  feature: false,
  page: 1,
  pageSize: 12,
  scopeVerticals: [],
  chooseVerticals: true,
  scopeTypes: [],
  chooseTypes: true,
};

export default NewsFeed;
