import React, { useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import selectedStateSelector from "../../redux/selectors/state.selector";
import { retrieveData } from "../../redux/modules/global";
import Spinner from "../../../Shared/Spinner";
import Visualization from "./Visualization";

const useStyles = makeStyles((theme) => ({
  app: {
    margin: "0 auto",
    "& p": {
      paddingBottom: 0,
    },
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingBottom: 32,
  },
}));

const VisualizationContainer = ({
  stateCode,
  data,
  countyMapViews,
  setCountyMapViews,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state) => state.equityAccessToTesting.isLoading
  );
  const getData = useCallback((id, xs) => dispatch(retrieveData(id, xs)), [
    dispatch,
  ]);

  // Get selected values from query params
  const selectedState = useSelector(
    selectedStateSelector(stateCode.toLowerCase())
  );

  useEffect(() => {
    getData(stateCode.toLowerCase(), data);
  }, [getData, stateCode, data]);

  return (
    <div className={classes.app}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Visualization
          selectedState={selectedState}
          countyMapViews={countyMapViews}
          setCountyMapViews={setCountyMapViews}
        />
      )}
    </div>
  );
};
export default VisualizationContainer;
