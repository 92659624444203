import React from "react";
import { ReactComponent as ThumbUp } from "../Icons/icon-thumbs-up.svg";
import { ReactComponent as ThumbDown } from "../Icons/icon-thumbs-down.svg";
import { ReactComponent as LearnMoreIcon } from "../Icons/learn-more.svg";
import { matchName } from "data/geo";
import classNames from "classnames/bind";
import styles from "./LearnMore.module.scss";
import Button from "components/common/Button";
import HFormat from "components/common/HFormat";
import { accessibilityProps_IMG } from "util/accessibility";
import { AvailablilityStatus } from "../../RegionPage/util";
import { useFetch } from "util/hooks/useFetch";
const cx = classNames.bind(styles);

// 1 for ascending; -1 for descending
const formatTableData = (
  { Number, States, Positivity },
  ascOrDesc = 1,
  undetermined = false
) => {
  return new Array(Number)
    .fill(null)
    .map((_, i) => {
      if (undetermined) {
        return {
          regionName: States[i],
        };
      }
      return {
        regionName: States[i],
        percPositive: Positivity[i],
      };
    })
    .sort(
      (a, b) =>
        (parseFloat(a.percPositive) - parseFloat(b.percPositive)) * ascOrDesc
    );
};

const allpositivityData = (data) => {
  return {
    above: formatTableData(data.Above, -1),
    below: formatTableData(data.Below, 1),
    undetermined: formatTableData(data.Undetermined, -1, true),
  };
};

const LearnMore = ({ stateName, racialData, renderAsEmbed }) => {
  const { testing, confirmed, death } = racialData;

  const [tableData, tableDataLoading] = useFetch(
    "https://jhucoronavirus.blob.core.windows.net/api/v2/testing/state/testing_positivity_3_12.json"
  );

  if (tableDataLoading) return null;

  const positivityData = allpositivityData(tableData);
  const isPositivityAtRecommended = positivityData?.below.find(
    ({ regionName }) => matchName(regionName, stateName)
  );
  return (
    <div className={styles.contentSection}>
      <h2 className={styles.iconHeader}>
        <LearnMoreIcon
          {...accessibilityProps_IMG("Learn More", { labeladdon: "Icon" })}
        />
        Learn More
      </h2>
      <div className={cx("learn-more-row")}>
        <div className={styles.learnMoreColumn}>
          <HFormat size={3} noMarginBtm>
            COVID-19 Racial Data Availability
          </HFormat>
          <div className={styles.learnMoreList}>
            <ul>
              <li>
                Testing:{" "}
                <AvailablilityStatus
                  isAvailable={!!testing.find((n) => matchName(n, stateName))}
                />
              </li>
              <li>
                Confirmed Cases:{" "}
                <AvailablilityStatus
                  isAvailable={!!confirmed.find((n) => matchName(n, stateName))}
                />
              </li>
              <li>
                Deaths:{" "}
                <AvailablilityStatus
                  isAvailable={!!death.find((n) => matchName(n, stateName))}
                />
              </li>
            </ul>
          </div>
          <div>
            {!renderAsEmbed && (
              <Button
                buttonStyle="underlined"
                icon="right"
                href="/data/racial-data-transparency"
              >
                View All
              </Button>
            )}
          </div>
        </div>
        <div className={styles.learnMoreColumn}>
          <HFormat size={3} noMarginBtm>
            Does {stateName} meet WHO recommended testing criteria?
          </HFormat>
          <div
            className={cx("recommendedContainer", {
              negative: !isPositivityAtRecommended,
            })}
          >
            {isPositivityAtRecommended ? (
              <>
                <ThumbUp />
                <span className={styles.available}>Yes</span>
              </>
            ) : (
              <>
                <ThumbDown />
                <span className={styles.unavailable}>No</span>
              </>
            )}
          </div>
          <div>
            {!renderAsEmbed && (
              <Button
                buttonStyle="underlined"
                icon="right"
                href="/testing/testing-positivity"
              >
                View All
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
