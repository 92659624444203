import React from "react";
import styles from "./Tab.module.scss";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

const Tab = ({
  type = "highlight",
  label,
  activeTab,
  onChange,
  isNew,
  className,
}) => {
  const handleClick = () => {
    if (onChange) onChange(label);
  };
  return (
    <div
      className={cx(
        styles.base,
        styles[type],
        activeTab === label ? styles.active : styles.inactive,
        className
      )}
      onClick={handleClick}
    >
      {isNew && <div className={styles.newTag}>New</div>}
      <button>{label}</button>
    </div>
  );
};

export const TabBaseProps = {
  type: PropTypes.oneOf(["highlight", "plain"]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  isNew: PropTypes.bool,
};

Tab.propTypes = {
  ...TabBaseProps,
  label: PropTypes.string.isRequired,
  activeTab: PropTypes.string,
};

export default Tab;
