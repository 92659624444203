import React from "react";
import ReactMarkdown from "react-markdown";
import Speakers from "../../Speakers";
import baseStyles from "../EventMod.module.scss";

export const HealthPolicyForum_2021 = ({
  slug = "health-policy-forum-05-04-2021",
  contentSrc = "The Johns Hopkins Health Policy Forum -- a quarterly series of discussions with leaders at the forefront of health policy development and implementation",
}) => {
  const speakerPath = `/images/vaccine-events/speakers/health-policy-forum`;
  const speakers = [
    {
      name: "Dr. Rochelle P. Walensky",
      title: "Guest",
      image: `${speakerPath}/rochelle-p-walensky.jpg`,
    },
    {
      name: "Dr. Paul Rothman",
      title: "Host",
      image: `${speakerPath}/paul-rothman.jpg`,
    },
  ];

  const content = () =>
    !!contentSrc && (
      <div>
        <ReactMarkdown
          className={baseStyles.embedDescription}
          source={contentSrc}
        />
        <Speakers authors={speakers} />
      </div>
    );

  return {
    slug,
    content,
  };
};
