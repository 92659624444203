import { getArticleCardsBySection } from "components/layout/ArticleCard/utils";
import { getSnapshotForRoute } from "data/util";
import React from "react";
import ReactMarkdown from "react-markdown/with-html";

export const formatFAQCards = () => {
  const contents = getArticleCardsBySection(
    "Vaccines Q & A",
    {},
    Infinity,
    false
  ).map((card) => ({
    ...card,
    date: "",
    new: card.new,
    slug: card.slug,
    title: card.title,
    category: "Vaccines Q & A",
    href: card.href,
    imageSrc: null,
    vertical: true,
    dark: false,
    verticals: ["vaccines"],
    body: <ReactMarkdown source={card?.meta?.description} />,
  }));
  return contents || [];
};

// const basePathQA = "/vaccines/q-n-a";
// export const cards = [
//   {
//     slug: "/breakthrough-infections-are-rare-but-expected",
//     title: "Q&A: Breakthrough Infections Are Rare But Expected",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/breakthrough-infections-are-rare-but-expected`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/breakthrough-infections-are-rare-but-expected`,
//       true
//     ),
//     body: (
//       <p>
//         Recent news that nine vaccinated baseball players for the New York
//         Yankees tested positive for SARS-CoV-2, the virus that causes COVID-19,
//         has thrust the concept of “breakthrough infections” to the forefront of
//         public discourse about U.S. vaccination efforts.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "as-its-cases-ebb-u-s-can-shift-to-global-aid",
//     title: "Q&A: As Its Cases Ebb, U.S. Can Shift To Global Aid",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/as-its-cases-ebb-u-s-can-shift-to-global-aid`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/as-its-cases-ebb-u-s-can-shift-to-global-aid`,
//       true
//     ),
//     body: (
//       <p>
//         While cases continue to decline in the United States amid its successful
//         mass vaccination efforts, many nations are struggling with new surges.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "u-s-vaccine-supply-appears-to-be-surpassing-demand",
//     title: "Q&A: U.S. Vaccine Supply Appears To Be Surpassing Demand",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/u-s-vaccine-supply-appears-to-be-surpassing-demand`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/u-s-vaccine-supply-appears-to-be-surpassing-demand`,
//       true
//     ),
//     body: (
//       <p>
//         The United States government and partners in the pharmaceutical industry
//         developed, manufactured, and evaluated safe and effective COVID-19
//         vaccines in record time. One last monumental task awaits: Getting
//         vaccines to the hardest-to-reach individuals and those who are hesitant
//         to be vaccinated, allowing vaccine supplies to exceed demand.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "what-johnson-and-johnson-pause-says-about-vaccine-safety",
//     title: "Q&A: What Johnson & Johnson ‘Pause’ Says About Vaccine Safety",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/what-johnson-and-johnson-pause-says-about-vaccine-safety`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/what-johnson-and-johnson-pause-says-about-vaccine-safety`,
//       true
//     ),
//     body: (
//       <p>
//         Six adverse events out of 7 million people is ‘extremely rare,’
//         demonstrating strength of U.S. monitoring.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "pausing-astrazeneca-vaccinations-in-europe-a-tragic-decision",
//     title:
//       "Q&A: Pausing Astrazeneca Vaccinations In Europe A ‘Tragic’ Decision",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/pausing-astrazeneca-vaccinations-in-europe-a-tragic-decision`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/pausing-astrazeneca-vaccinations-in-europe-a-tragic-decision`,
//       true
//     ),
//     body: (
//       <p>
//         Many European nations – including France, Germany, Italy, Portugal, and
//         Spain – recently paused the use of AstraZeneca’s vaccine due to alleged
//         adverse reactions.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug:
//       "people-hesitant-to-receive-vaccines-need-empathy-from-medical-providers",
//     title:
//       "Q&A: People Hesitant To Receive Vaccines Need Empathy From Medical Providers",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/people-hesitant-to-receive-vaccines-need-empathy-from-medical-providers`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/people-hesitant-to-receive-vaccines-need-empathy-from-medical-providers`,
//       true
//     ),
//     body: (
//       <p>
//         A history of formal medical exploitation and decades of institutional
//         and cultural racism have entrenched that mistrust and fear, according to
//         research co-authored by Rupali Limaye.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "vaccine-trials-forged-new-ground-in-speed-diversity-and-methods",
//     title:
//       "Q&A: Vaccine Trials Forged New Ground In Speed, Diversity And Methods",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/vaccine-trials-forged-new-ground-in-speed-diversity-and-methods`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/vaccine-trials-forged-new-ground-in-speed-diversity-and-methods`,
//       true
//     ),
//     body: (
//       <p>
//         These FAQs provide answers to some of the most frequently asked
//         questions on COVID-19 vaccines.
//       </p>
//     ),
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
//   {
//     slug: "vaccination-plans-must-include-disability-community-needs",
//     title: "Q&A: Vaccination Plans Must Include Disability Community’s Needs",
//     category: "Vaccines Q & A",
//     href: `${basePathQA}/vaccination-plans-must-include-disability-community-needs`,
//     imageSrc: getSnapshotForRoute(
//       `${basePathQA}/vaccination-plans-must-include-disability-community-needs`,
//       true
//     ),
//     body: <p>See an overview of key testing metrics in each U.S. state</p>,
//     vertical: true,
//     dark: false,
//     verticals: ["vaccines"],
//   },
// ];

// export default cards;

export const FAQ = {
  title: "Vaccines FAQ",
  category: "FAQ",
  href: "/vaccines/vaccines-faq",
  imageSrc: getSnapshotForRoute("/vaccines/vaccines-faq", true),
  body: (
    <p>
      These FAQs provide answers to some of the most frequently asked questions
      on COVID-19 vaccines.
    </p>
  ),
  vertical: true,
  dark: false,
  verticals: ["vaccines"],
};
