import React, { useEffect } from "react";
import { NavLink, Switch, Route, Redirect } from "react-router-dom";

import * as GTM from "util/gtm";
import Page from "components/layout/Page";
import styles from "./PDIFAQ.module.scss";
import questionSheets from "./questions";
import PDIFAQSheet from "./PDIFAQSheet";
import { AuthorTabContainer } from "components/common/Author/util";
import { getAuthor } from "data/util";

const PDIFAQ = () => {
  const basePath = "/pandemic-data-initiative/pandemic-data-faq";
  const authors = [getAuthor("joshua-e-porterfield-ph-d")];

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["pandemic-data-initiative"],
    });
  }, []);

  return (
    <Page id={styles.pageId} path={basePath}>
      <AuthorTabContainer authors={authors} />
      <div className={styles.sheetMenu}>
        <div className={styles.container}>
          {questionSheets.map(({ slug, title }) => (
            <NavLink
              key={slug}
              to={`${basePath}/${slug}`}
              className={styles.sheetLink}
              activeClassName={styles.active}
            >
              {title}
            </NavLink>
          ))}
        </div>
      </div>
      <Switch>
        <Route path={`${basePath}/:sheetId`} component={PDIFAQContent} />
        <Redirect to={`${basePath}/${questionSheets[0].slug}`} />
      </Switch>
    </Page>
  );
};

export default PDIFAQ;

const PDIFAQContent = ({ match, location }) => {
  const components = {
    overview: <PDIFAQSheet match={match} location={location} />,
  };
  return components[match.params.sheetId];
};
