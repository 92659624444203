import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
// import moment from "moment";
import { line as d3_line } from 'd3-shape';
import { format } from "d3-format";
import Line from './Line';
import { scaleLinear, scaleTime } from 'd3-scale';
import { min, max, extent } from 'd3-array';
import Axes from './Axes';
import {
  AXES_MOMENT_DATE_FORMAT,
} from '../constants';
// import EventMarker from './EventMarker';
import TooltipLine from './TooltipLine';
import HoverBar from "./HoverBar";

const FacetChart = ({ data, events, enumeratedDates, selectedEvent, innerHeight, innerWidth, xScale, backgroundXScale, xScaleRange, setStopppedData }) => {
  const [activeEvent, setActiveEvent] = useState(null);
  const [yAxes, setYAxes] = useState(null);

  const backgroundYScale = scaleLinear()
    .range([innerHeight, 0])
    .domain([0, 1])

  const xAxisScale = scaleTime()
    .range(xScaleRange)
    .domain(extent(enumeratedDates))

  useEffect(() => {
    let localXScale = scaleTime()
      .range(xScaleRange)
      .domain(extent(enumeratedDates))

    let axes = [];
    let facetMargins = { top: 30, right: 0, bottom: 30, left: 0 }
    data.forEach((d, i) => {
      // Get the max value of the average (line)
      let maxYValue = d.datasetSource === "testing" ? max(d.data, e => e.averageTT) : max(d.data, e => e.average);
      let minYValue = d.datasetSource === "testing" ? min(d.data, e => e.averageTT) : 0
      let facetHeight = ((innerHeight / data.length) + 30) - facetMargins.top - facetMargins.bottom;
      let facetOffset = (innerHeight / data.length) * i;
      let facetRange = [
        (innerHeight) - facetOffset,
        (innerHeight) - facetOffset - facetHeight
      ]
      let facetCenter = innerHeight - ((facetHeight / 2) + facetOffset)
      // Calculate a y-scale
      let yScale = scaleLinear()
        .range(facetRange)
        .domain([minYValue, maxYValue])
        .nice();

      let line = d3_line()
        .x(d => localXScale(d.dt))
        .y(e => yScale(d.datasetSource === "testing" ? e.averageTT : e.average));

      axes.push({ scale: yScale, innerHeight: facetHeight, offset: facetOffset, labelText: d.datasetLabel, labelCenter: facetCenter, key: d.datasetId, colors: d.colors, path: line(d.data), data: d.data })

    })

    setYAxes(axes.reverse());

  }, [data, innerHeight, xScaleRange, enumeratedDates])

  return (
    <g>
      <Axes
        xScale={xAxisScale}
        height={innerHeight}
        width={innerWidth}
        offset={0}
        selectedDatasets="timelines"
        xScaleTickFormat={AXES_MOMENT_DATE_FORMAT}
      />
      <g className="hover--bars__group">
        {enumeratedDates.map(d => {
          return (
            <HoverBar
              item={d}
              key={`hover-bar-for-${d.format('YYYY-MM-DD')}`}
              x={backgroundXScale(d)}
              height={backgroundYScale(0)}
              y={backgroundYScale(1)}
              width={backgroundXScale.bandwidth()}
              setActiveEvent={setActiveEvent}
            />
          );
        })}
      </g>
      {yAxes && yAxes.map((d, i) => {
        return (
          <g key={d.key}>
            <Axes
              stoppedDate={d.key === "deaths" && "09/21/2022"}
              index={i}
              yScale={d.scale}
              height={d.innerHeight}
              width={innerWidth}
              offset={d.offset}
              selectedDatasets={d.labelText}
              yScaleTickFormat={format('~s')}
              colors={d.colors}
              labelCenter={d.labelCenter}
              labelOffset={yAxes.length > 2 && i % 2 === 0 ? false : true}
              setStopppedData={setStopppedData}
            />
            <g clipPath="url(#bar-chart-clip)">
              <Line d={d.path} stroke={d.colors.line} />
            </g>
          </g>
        );
      })}
      <g className="timeline__tooltip__container">
        {yAxes && <TooltipLine
          activeEvent={activeEvent}
          xScale={xScale}
          yScale={backgroundYScale}
          yData={yAxes}
          height={innerHeight}
          innerWidth={innerWidth}
        />}
      </g>
    </g>
  );
};

FacetChart.propTypes = {
  data: PropTypes.array,
  events: PropTypes.array,
  enumeratedDates: PropTypes.any,
  selectedEvent: PropTypes.any,
  innerHeight: PropTypes.number,
  innerWidth: PropTypes.number,
  xScale: PropTypes.func,
  backgroundXScale: PropTypes.func,
  xScaleRange: PropTypes.array,
};

export default FacetChart;
