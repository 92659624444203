import React from 'react';
import ReactMarkdown from 'react-markdown';
import Speakers from '../../Speakers';
import baseStyles from "../EventMod.module.scss";

export const Covid19Briefing_11_20 = ({
  slug = "covid-19-briefing-expert-insights",
  contentSrc = null,
}) => {  
  const speakers = [
    {
      name: "Beth Blauer",
      title: "Executive Director, Centers for Civic Impact",
      image: "/images/vaccine-events/speakers/beth-blauer.jpg"
    },
    {
      name: "Dr. Bill Moss",
      title: "Executive Director, International Vaccine Access Center",
      image: "/images/vaccine-events/speakers/bill-moss.jpg"
    }
  ];

  const content = () =>  (
    !!contentSrc && (
      <div>
        <ReactMarkdown
          className={baseStyles.embedDescription}
          source={contentSrc}
        />
        <Speakers useImg={false} authors={speakers}/>
      </div>
    )
  );
  return {
    slug,
    content
  };
};
