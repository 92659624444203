import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

const EmbedFrame = ({ title, src, width, height, resize, resizeWhenShort }) => {
  // Account for 8px margin inside iFrame:
  const frameWidth = width + 16;
  const frameHeight = height + 16;

  // Monitor width of container div and window height:
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(frameWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerWidth);

  // Update all measurements on resize
  useEffect(() => {
    const handleResize = () => {
      setContainerWidth(containerRef.current.offsetWidth);
      setWindowHeight(window.innerHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Ensure the window isn't too short for tall charts
  const isShort = resizeWhenShort
    ? false
    : containerWidth >= 768 && windowHeight < 900;

  // Find relative scale difference between original width and container
  const scale = resize && !isShort ? containerWidth / frameWidth : 1;

  // Style container to fill parent and scale height
  const containerStyle = {
    width: "100%",
    height: frameHeight * scale,
    overflow: "hidden"
  };

  // Style iFrame to transform based on scale to fill container
  const frameStyle = {
    border: 0,
    transform: `scale(${scale})`,
    transformOrigin: "top left",
  };

  return (
    <div ref={containerRef} style={containerStyle}>
      <iframe
        title={title}
        src={src}
        style={frameStyle}
        width={frameWidth}
        height={frameHeight}
        allowFullScreen
        frameBorder="no"
      />
    </div>
  );
};

EmbedFrame.propTypes = {
  title: PropTypes.string,
  src: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  resize: PropTypes.bool.isRequired,
  resizeWhenShort: PropTypes.bool.isRequired,
};

EmbedFrame.defaultProps = {
  title: "Embed",
  resize: true,
  resizeWhenShort: false,
};

export default EmbedFrame;
