import React from "react";
import styles from "./SectionDivider.module.scss";

const SectionDivider = () => {
  return (
    <div className={styles.base}>
      <hr />
    </div>
  );
};

export default SectionDivider;
