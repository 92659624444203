import React, { FC } from "react";
import styles from "./TabBar.module.scss";
import cx from "classnames";
import Tab from "./Tab";
import { TFilterOption } from "../util";
import useSingleSelect from "util/hooks/useSingleSelect";

export type TTabBarProps = {
  options: string[] | TFilterOption[];
  currentSelect?: TFilterOption["value"] | number;
  onChange?: (selected: TFilterOption) => void;
  allOption?: boolean;
  newTabs?: string[];
  className?: string;
  optionClassName?: string;
  mobileClassName?: string;
};

const TabBar: FC<TTabBarProps> = ({
  allOption = true,
  options = [],
  newTabs = [],
  currentSelect = allOption ? null : 0,
  onChange,
  className,
  optionClassName,
}) => {
  const { allOptions, requestSelect, selected } = useSingleSelect(options, {
    allOption,
    currentSelect,
    onSelect: (selectedOption) => onChange?.(selectedOption),
  });
  return (
    <div className={cx(styles.base, className)}>
      {!!allOptions.length &&
        allOptions.map((tab, i) => (
          <Tab
            key={`tab-option#${tab.value}#${i}`}
            className={optionClassName}
            onChange={requestSelect}
            isNew={!!newTabs?.find((t) => t === tab.value)}
            isActive={tab.value === selected?.value}
            option={tab}
          />
        ))}
    </div>
  );
};

export default TabBar;
