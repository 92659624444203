import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import * as GTM from "util/gtm";
import TracingHome from "./TracingHome";
import TracingPrinciples from "./TracingPrinciples";
import TracingResults2021 from "./TracingResults2021";

const Tracing = () => {
  useEffect(() => {
    GTM.clear();
    GTM.push({ pageVerticals: ["tracing"] });
  }, []);

  return (
    <Switch>
      <Route
        path="/contact-tracing/principles"
        exact
        component={TracingPrinciples}
      />
      <Route
        path="/contact-tracing/state-survey-results"
        exact
        component={TracingResults2021}
      />

      <Route path="/contact-tracing" exact component={TracingHome} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Tracing;
