import React from "react";
import PropTypes from "prop-types";
import styles from "./EventAtoms.module.scss";
import Button from "components/common/Button";
import { shouldShowQA } from "../util";

export const EventContentHeaderContainer = ({
  eventState = "post",
  eventRegistry = { href: null },
  children,
}) => (
  <div className={styles.contentHeader}>
    <div className={styles.eventHeader}>{children}</div>
    {eventState !== "post" && !!eventRegistry?.href && (
      <EventRegisterButton href={eventRegistry.href} />
    )}
  </div>
);
EventContentHeaderContainer.propTypes = {
  eventState: PropTypes.string.isRequired,
  eventRegistry: PropTypes.shape({
    href: PropTypes.string,
  }),
};

export const EventQAEmbed = ({
  src,
  iframeProps,
  eventState = "post",
  dates,
}) => (
  <>
    {!!src && (eventState === "mid" || shouldShowQA(dates)) && (
      <iframe
        className={styles.cCapIframe}
        id="live-asr-embed-12070"
        src={src}
        frameBorder={0}
        title="caption"
        style={{ border: 0, width: "100%" }}
        {...iframeProps}
      />
    )}
  </>
);
EventQAEmbed.propTypes = {
  src: PropTypes.string.isRequired,
  eventState: PropTypes.string.isRequired,
  iframeProps: PropTypes.object,
};

export const EventRegisterButton = ({ href, label = "Register Now" }) => (
  <>
    <div className={styles.regiserButtonContainer}>
      <a href={href} target="_blank" rel="noopener noreferrer">
        <Button shape="rounded">{label}</Button>
      </a>
    </div>
  </>
);
EventRegisterButton.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
};
