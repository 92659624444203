import React from "react";
import { Switch, Route } from "react-router-dom";
import LearnHome from "./LearnHome";
import Module from "./Module";

const Learn = () => (
  <Switch>
    <Route
      path="/covid-19-basics/understanding-covid-19/:moduleId"
      component={Module}
    />
    <Route
      path="/covid-19-basics/understanding-covid-19"
      exact
      component={LearnHome}
    />
  </Switch>
);

export default Learn;
