import React from 'react';
import styles from './TabBar.module.scss';
import TabBar from 'components/layout/shell/TabBar';
import TabBarUpdated from 'components/layout/shell/Filter/TabBar';
import HFormat from 'components/common/HFormat';
import ResponsiveFilterBar from 'components/layout/shell/Filter/ResponsiveFilterBar';

const tabs = [
  "Past Day",
  "Past Week",
  "Past Month",
  "All Time",
];

const TabBarStyleGuide = (props) => {
  const handleOnChange = (selected) => { };
  return (
    <div className={styles.container}>
      <h1 className={styles["styleguide-annotation"]}>TabBar</h1>

      <HFormat size={2}>Highlight</HFormat>
      <div className={styles.selectContainer}>
        <TabBar tabs={tabs} onChange={handleOnChange} />
      </div>
      <br />
      <br />
      <HFormat size={2}>Updated</HFormat>
      <div className={styles.selectContainer}>
        <TabBarUpdated allOption={false} options={tabs} onChange={handleOnChange} />
      </div>
      <br />
      <br />
      <HFormat size={2}>Responsive Filter Bar</HFormat>
      <div className={styles.selectContainer}>
        <ResponsiveFilterBar allOption={false} options={tabs} onChange={handleOnChange} />
      </div>
      <br />
      <br />
      <HFormat size={2}>Legacy</HFormat>
      <div className={styles.selectContainer}>
        <TabBar type="plain" tabs={tabs} onChange={handleOnChange} />
      </div>
      <br />
      <br />
    </div>
  );
};

export default TabBarStyleGuide;
