import React from "react";
import styles from "./ResponsiveFilterBar.module.scss";
import SingleSelect from "../SingleSelect";
import TabBar from "../TabBar";
import { TFilterOption } from "../util";

export type TResponsiveFilterBarProps = {
  options: string[] | TFilterOption[];
  currentSelect?: TFilterOption["value"] | number;
  onChange?: (selected: TFilterOption) => void;
  allOption?: boolean;
  placeholder?: string;
  className?: string;
  optionClassName?: string;
};

const ResponsiveFilterBar = (props: TResponsiveFilterBarProps) => {
  const { onChange } = props;
  const handleOnChange = (selectedOption) => {
    if (onChange) onChange(selectedOption);
  };
  // const props = {
  //   options,
  //   currentSelect,
  //   allOption,
  //   placeholder,
  //   onChange,
  //   className,
  // };
  return (
    <div className={styles.base}>
      <TabBar className={styles.tabbar} {...props} onChange={handleOnChange} />
      <SingleSelect
        className={styles.select}
        {...props}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default ResponsiveFilterBar;
