import React from "react";
import PropTypes from "prop-types";
import stateList from "data/data-viz/testing/state-list";
import TFormat from "components/common/TFormat";

const TestingPositivityTable = ({
  data,
  header,
  tableColumns = {
    regionName: "State",
    percPositive: "Percentage of\nPositive Tests",
  },
}) => {
  return (
    <TFormat
      header={header}
      tableData={data}
      tableColumns={tableColumns}
      rowLimit={8}
      regionList={stateList}
      path="/testing/individual-states/"
    />
  );
};

TestingPositivityTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      regionName: PropTypes.string.isRequired,
      percPositive: PropTypes.string,
    })
  ).isRequired,
  header: PropTypes.node.isRequired,
};

export default TestingPositivityTable;
