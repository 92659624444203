import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styles from "./TimeSpan.module.scss";

function TimeSpan({ label, light }) {
  const containerRef = useRef(null);
  const [arrowWidth, setArrowWidth] = useState(36);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef && containerRef.current) {
        setArrowWidth(containerRef.current.clientWidth / 2);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const arrowResize = {
    borderWidth: `${arrowWidth - 10}px ${arrowWidth}px 0 ${arrowWidth}px`,
  };

  return (
    <div
      ref={containerRef}
      className={`${styles.base} ${light ? styles.light : styles.dark}`}
    >
      <div className={styles.arrowTail} style={arrowResize} />
      <p>{label}</p>
      <div className={styles.arrowHeadContainer}>
        <div className={styles.arrowHead} style={arrowResize} />
      </div>
    </div>
  );
}

export default TimeSpan;
