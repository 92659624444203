import React from "react";
import MaybeLink from "components/common/MaybeLink";
import { ReactComponent as ArrowIcon } from "images/icons/arrow-right.svg";
import _camelCase from "lodash/camelCase";

export function cleanApiData(data) {
  if (Array.isArray(data)) {
    return data.map(cleanApiData);
  } else if (data !== null && typeof data === "object") {
    return Object.entries(data)
      .map(([key, value]) => [_camelCase(key), cleanApiData(value)]) // For objects, camelcase the keys and recur for the values
      .reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
  }

  return data;
}

// Is a URL internal or to an outside site?
export const isAbsoluteUrl = (href) => {
  try {
    return !!new URL(href);
  } catch (e) {
    return false;
  }
};

// Check out /src/components/common/MaybeLink
// Render either a <Link> or an <a> with button styles
export const renderButtonLink = ({ href, children }, className) => (
  <MaybeLink url={href} className={`button ${className}`}>
    {children}
    <ArrowIcon />
  </MaybeLink>
);

export const limitCharacters = (text, charsShown = 30) => {
  if (!text || text.length === 0) {
    return "";
  }
  return text.length <= charsShown ? text : text.slice(0, charsShown) + "...";
};

export const formatRegionSlug = (regionSlug) => {
  const fragments = regionSlug.split("-");
  const regionName = fragments
    .map((part) => part[0].toUpperCase() + part.slice(1).toLowerCase())
    .join(" ");
  return fragments.length > 2 ? `the ${regionName}` : regionName;
};

export const truncateFloat = (value, limit) => {
  value = Math.floor(value * 100) / 100;
  return value.toFixed(2);
};

export const formatMonthDate = (date) =>
  date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

//Removes the '/' from end of the pathname if present
export const getNormalizedPathname = (pathname = "") => {
  if (pathname[pathname.length - 1] === "/")
    return pathname.slice(0, pathname.length - 1);
  return pathname;
};
