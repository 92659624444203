import React, { useEffect } from "react";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./VaccineStatePlans.module.scss";
import ReactMarkdown from "react-markdown";
import { formatStatePlanData } from "./util";
import EligibilityVisual from "./EligibilityVisual";
import GraphicLegend from "./GraphicLegend";
import StateGraphicRow from "./StateGraphicRow";
import Page from "components/layout/Page";

const VaccineStatePlans = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;
  const stateRows = formatStatePlanData();

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["vaccines"],
    });
  }, []);

  return (
    <Page pageData={pageData}>
      <div className={styles.container}>
        <ReactMarkdown
          source={content_blocks["vaccine-state-plans-intro"]?.content}
        />

        <GraphicLegend className={styles.legendContainer} />
        <EligibilityVisual className={styles.eligibilityVisual} />

        <div className={styles.blockContainer}>
          {Object.keys(stateRows).map((stateCode, i) => (
            <StateGraphicRow
              key={`state-row${stateCode}${i}`}
              stateCode={stateCode}
              blocks={stateRows[stateCode]}
            />
          ))}
        </div>
      </div>
    </Page>
  );
};

export default VaccineStatePlans;
