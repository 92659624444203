import React from "react";

import ColumnLayout from "components/layout/ColumnLayout";
import ArticleCard from "components/layout/ArticleCard";
import { getSnapshotForRoute } from "data/util";

export default function TestingTrackerNavContent() {
  return (
    <ColumnLayout layout="quartet">
      <ArticleCard
        dark
        vertical
        category="Testing Trends Tool"
        title="State Overview"
        href="/testing/tracker/overview"
        imageSrc={getSnapshotForRoute("/testing/tracker/overview", true)}
        body={<p>See an overview of key testing metrics in each U.S. state</p>}
      />

      <ArticleCard
        dark
        vertical
        category="Testing Trends: Maps"
        title="Percentage of New Tests that Are Positive"
        href="/testing/tracker/map/percent-positive"
        imageSrc={getSnapshotForRoute(
          "/testing/tracker/map/percent-positive",
          true
        )}
        body={
          <p>
            How many of the new tests issued in each state are coming back
            positive?
          </p>
        }
      />

      <ArticleCard
        dark
        vertical
        category="Testing Trends: Maps"
        title="Weekly Change in Testing"
        href="/testing/tracker/map/weekly-change-in-testing"
        imageSrc={getSnapshotForRoute(
          "/testing/tracker/map/weekly-change-in-testing",
          true
        )}
        body={
          <p>
            Has the number of new tests being administered in each state
            declined from the previous week?
          </p>
        }
      />
    </ColumnLayout>
  );
}
