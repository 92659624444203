import kebabCase from "lodash.kebabcase";
import { scaleLinear, scaleQuantize } from "d3-scale";
import {
  ALL_STATE_META,
  COLORS,
  COMMA_FORMATTER,
  PERCENT_FORMATTER,
  SINGLE_DECIMAL_FORMATTER,
} from "../Shared/constants";

export const STATE_META = ALL_STATE_META;

export const DEFAULT_STATE_NAME = "alabama";
export const idAccessor = (f) => f.GEOID;

const ETHNICITY_SCALE = scaleLinear().range(["#F4FAFF", "#6A98B1"]);
const NO_DATA_SCALE = scaleLinear().range(["#ebebeb", "#ebebeb"]);

export const BUBBLE_LAYERS = [
  {
    name: "Cases",
    mapColor: "#D15A5A",
    legendColor: "#E8ACAC",
    label: "New daily cases",
    format: SINGLE_DECIMAL_FORMATTER,
    tooltipLabel: " new daily cases",
  },
  {
    name: "Cases Per Capita",
    mapColor: "#806559",
    legendColor: "#BFB2AC",
    label: "New daily cases per 100,000 people",
    format: SINGLE_DECIMAL_FORMATTER,
    tooltipLabel: " new daily cases per 100,000 people",
  },
  {
    name: "Deaths",
    mapColor: "#7E3160",
    legendColor: "#BE98AF",
    label: "New daily deaths",
    format: SINGLE_DECIMAL_FORMATTER,
    tooltipLabel: " new daily deaths",
  },
  {
    name: "Deaths Per Capita",
    mapColor: "#5F571A",
    legendColor: "#AFAB8C",
    label: "New daily deaths per 100,000 people",
    format: SINGLE_DECIMAL_FORMATTER,
    tooltipLabel: " new daily deaths per 100,000 people",
  },
].map((d, i) => ({ ...d, id: kebabCase(d.name), isActive: i === 2 }));

const CASES_COLORS = [
  "#fffbd7",
  "#f7e3b8",
  "#efcaa0",
  "#e7b18b",
  "#de9979",
  "#d47f68",
  "#c96658",
  "#be4a4a",
];
const DEATHS_COLORS = [
  "#fcfae4",
  "#dce9bb",
  "#bdd79f",
  "#a2c393",
  "#87af87",
  "#6c9c7c",
  "#518970",
  "#377665",
];
export const CUMULATIVE_LAYERS = [
  {
    name: "Total cases",
    colorScale: scaleQuantize().range(CASES_COLORS),
    format: COMMA_FORMATTER,
    tooltipLabel: " total cases",
  },
  {
    name: "Total cases per capita",
    colorScale: scaleQuantize().range(CASES_COLORS),
    format: SINGLE_DECIMAL_FORMATTER,
    label: "Total cases per 100,000 people",
    tooltipLabel: " total cases per 100,000 people",
  },
  {
    name: "Total deaths",
    colorScale: scaleQuantize().range(DEATHS_COLORS),
    format: COMMA_FORMATTER,
    tooltipLabel: " total deaths",
  },
  {
    name: "Total deaths per capita",
    colorScale: scaleQuantize().range(DEATHS_COLORS),
    format: SINGLE_DECIMAL_FORMATTER,
    label: "Total deaths per 100,000 people",
    tooltipLabel: " total deaths per 100,000 people",
  },
].map((d, i) => ({
  ...d,
  id: kebabCase(d.name),
  label: d.label || d.name,
  isActive: !i,
}));

export const DEMOGRAPHIC_LAYERS = [
  {
    name: "No demographic layer selected",
    color: COLORS.blueGray,
    format: PERCENT_FORMATTER,
    colorScale: NO_DATA_SCALE,
    isEmpty: true,
  },
  {
    name: "Non-White",
    color: COLORS.blueGray,
    format: PERCENT_FORMATTER,
    label: "Non-white",
    colorScale: ETHNICITY_SCALE,
    tooltipLabel: "of population is non-white",
  },
  {
    name: "Black or African American",
    color: COLORS.blueGray,
    format: PERCENT_FORMATTER,
    label: "Black or African-American",
    colorScale: ETHNICITY_SCALE,
    tooltipLabel: "of population is Black or African-American",
  },
  {
    name: "Hispanic or Latino",
    color: COLORS.blueGray,
    format: PERCENT_FORMATTER,
    label: "Latino or Hispanic",
    colorScale: ETHNICITY_SCALE,
    tooltipLabel: "of population is Latino or Hispanic",
  },
  {
    name: "American Indian and Alaska Native",
    color: COLORS.blueGray,
    format: PERCENT_FORMATTER,
    label: "American Indian or Alaska Native",
    colorScale: ETHNICITY_SCALE,
    tooltipLabel: "of population is American Indian or Alaska Native",
  },
  {
    name: "Below Poverty Level",
    color: COLORS.blueGray,
    domain: [0, 0.5],
    label: "Below poverty line",
    format: PERCENT_FORMATTER,
    colorScale: scaleLinear().range(["#FFFFE0", "#7BBFA2"]),
    tooltipLabel: "of population is below poverty line",
  },
].map((d, i) => ({
  ...d,
  id: kebabCase(d.name),
  label: d.label || d.name,
  isActive: !i,
}));
