import {BUBBLE_LAYERS, DEMOGRAPHIC_LAYERS, CUMULATIVE_LAYERS, STATE_META} from '../../constants';

export const initialState = {
    data: {},
    showError: false,
    isLoading: true,
    states: STATE_META,
    demographicLayers: DEMOGRAPHIC_LAYERS,
    bubbleLayers: BUBBLE_LAYERS,
    cumulativeLayers: CUMULATIVE_LAYERS,
    activeMarker: null,
    racialBreakdown: []
};
