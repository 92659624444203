import Button from "components/common/Button";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import RenderVideoFrame from "../RenderVideoFrame";
import styles from "./VideoContent.module.scss";

const VideoContent = ({ content_blocks }) => {
  const [showDetails, setShowDetails] = useState(false);
  const showButtonProps = {
    className: styles.showButton,
    shape: "square",
    buttonStyle: "underlined",
  };
  return (
    <>
      <div className={styles.mainContent}>
        <div className={styles.vidoeFrame}>
          <RenderVideoFrame
            videoUrl={"https://youtu.be/AQmatrVG5VA"}
            videoLinkType={1}
            iframeProps={{
              allowFullScreen: true,
              frameBorder: "0",
              allow:
                "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            }}
          />
        </div>
        <div className={styles.textContainer}>
          <ReactMarkdown
            source={content_blocks["variant-data-description"]?.content}
          />
          {!showDetails && (
            <Button
              {...showButtonProps}
              onClick={() => setShowDetails(true)}
            >
              {"Read more..."}
            </Button>
          )}
        </div>
      </div>
      {showDetails && (
        <div className={styles.bottomContent}>
          <ReactMarkdown
            source={content_blocks["variant-data-description-2"]?.content}
          />
          <Button
            {...showButtonProps}
            onClick={() => setShowDetails(false)}
          >
            {"Show less"}
          </Button>
        </div>
      )}
    </>
  );
};

export default VideoContent;
