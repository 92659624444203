import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import styles from "./InfoModal.module.scss";

const dialogueTitleStyles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

type TDialogTitleProps = {
  id?: string;
  onClose?: any;
  children?: any;
  classes?: any;
};

const DialogTitle = withStyles(dialogueTitleStyles as unknown)(
  (props: TDialogTitleProps) => {
    const { children, classes, onClose, ...other } = (props as unknown) as any;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  }
);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const InfoModalWrapper = ({ title = "How to read this graphic", children }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="how__to__read__graphic">
      <button onClick={handleClickOpen}>{title}</button>
      <Dialog
        onClose={handleClose}
        aria-labelledby={title}
        open={open}
        fullWidth={true}
        maxWidth={"lg"}
        scroll="body"
        className={styles.modal}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          <div className={styles.base}>{children}</div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default InfoModalWrapper;
