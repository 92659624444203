import React from "react";
import PropTypes from "prop-types";

import { Link, NavLink } from "react-router-dom";

// The creat-react-app ReactComponent SVG import system was causing rendering
// issues, so we import all the icons as base64-encoded inline 'data:' URLs
/* eslint-disable import/no-webpack-loader-syntax */
import rightIcon from "!url-loader!images/icons/button/chevron-right.svg";
import downIcon from "!url-loader!images/icons/button/chevron-down.svg";
import downloadIcon from "!url-loader!images/icons/button/download.svg";
import playIcon from "!url-loader!images/icons/button/play.svg";
import xIcon from "!url-loader!images/icons/button/x.svg";
/* eslint-enable */

import searchIcon from "images/icons/search.svg";

import classNames from "classnames/bind";
import styles from "./Button.module.scss";
const cx = classNames.bind(styles);

export const isAbsoluteUrl = (href) => {
  try {
    return !!new URL(href);
  } catch (e) {
    return false;
  }
};

const newTab = { target: "_blank", rel: "noopener noreferrer" };
/* eslint-disable jsx-a11y/anchor-has-content */
const BaseComponents = {
  Link: {
    External: (props) => <a {...props} />,
    NewTab: (props) => <a {...newTab} {...props} />,
    Internal: ({ href, ...props }) => <Link to={href} {...props} />,
    Nav: ({ href, ...props }) => (
      <NavLink
        to={href}
        activeClassName={cx("active", props.activeClassName)}
        {...props}
      />
    ),
  },
  Button: ({ href, ...props }) => <button type="button" {...props} />,
};
/* eslint-enable */

const ButtonProps = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  href: PropTypes.string,
  absoluteUrl: PropTypes.bool,
  targetBlank: PropTypes.bool,
  navMode: PropTypes.bool,
  active: PropTypes.bool,
  buttonStyle: PropTypes.oneOf([
    "filled",
    "filled-light",
    "bordered",
    "plain",
    "underlined",
    "tip",
  ]),
  shape: PropTypes.oneOf(["square", "rounded"]),
  icon: PropTypes.oneOf([
    "right",
    "left",
    "up",
    "down",
    "download",
    "play",
    "x",
    "search",
    null,
  ]),
  className: PropTypes.string,
};

function Button({
  onMouseOver,
  active,
  children = null,
  onClick = () => {},
  href = null,
  absoluteUrl = null,
  targetBlank = false,
  navMode = false,
  buttonStyle = "filled",
  shape = "square",
  icon = null,
  className = null,
  ...props
}) {
  const iconKey = icon === "up" ? "down" : icon;
  const iconUrl = {
    right: rightIcon,
    left: rightIcon,
    down: downIcon,
    download: downloadIcon,
    play: playIcon,
    x: xIcon,
    search: searchIcon,
  }[iconKey];

  // Note: not using <MaybeLink> in order to implement slightly different logic
  let Component;
  // If the href is an absolute URL or we're opening in a new tab, use an a tag
  if (href) {
    const absolute =
      absoluteUrl === true || absoluteUrl === false
        ? absoluteUrl
        : isAbsoluteUrl(href);
    if (targetBlank) Component = BaseComponents.Link.NewTab;
    else if (absolute) Component = BaseComponents.Link.External;
    else if (navMode) Component = BaseComponents.Link.Nav;
    else Component = BaseComponents.Link.Internal;
  } else {
    Component = BaseComponents.Button;
  }

  return (
    <Component
      href={href}
      onClick={onClick}
      onMouseOver={onMouseOver}
      className={
        cx([
          "base",
          `style-${buttonStyle}`,
          `shape-${shape}`,
          { "has-icon": !!icon },
          { "icon-only": !!icon && !children },
          { active: active },
        ]) + ` ${className}`
      }
      aria-label={props?.["aria-label"] ? props["aria-label"] : children}
      {...props}
    >
      {icon !== "left" && children}
      {icon ? (
        <img
          className={cx("icon", styles[`icon-${icon}`])}
          style={{ transform: icon === "up" ? "rotate(180deg)" : null }}
          src={iconUrl}
          alt=""
        />
      ) : null}
      {icon === "left" && children}
    </Component>
  );
}

Button.propTypes = ButtonProps;

export default Button;
