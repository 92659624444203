import React from "react";
import Button from "components/common/Button";
import styles from "./TRow.module.scss";
import cx from "classnames";
import { findCountryCode, findStateCode } from "data/geo";
import { Link } from "react-router-dom";
import { shortenRegionName } from "../util";
import { ReactComponent as Warning } from "images/icons/warning.svg";

type TTRowProps = {
  item: any;
  tableColumns: any;
  rowHeight: number;
  isInRegionList: (slug: string) => boolean;
  isInRegionNoLinkList: (slug: string) => boolean;
  addTDIfPresent: (item: any, key: string) => any;
  path?: string;
  pageSection?: string;
  hideFirstCol?: boolean;
  isMobile?: boolean;
  mobileColumn?: boolean;
  useRegionCodes?: boolean;
  useRegionCodeType?: string;
  useNameShortening?: any;
  regionNameLinkStyle?: string;
  highlight?: boolean;
  className?: string;
};

const TRow = ({
  item,
  tableColumns,
  hideFirstCol,
  path,
  pageSection,
  useRegionCodes,
  useRegionCodeType,
  mobileColumn,
  isMobile,
  useNameShortening,
  isInRegionList,
  isInRegionNoLinkList,
  addTDIfPresent,
  regionNameLinkStyle,
  rowHeight,
  highlight,
  className,
}: TTRowProps) => {
  const { regionName, regionSlug = "", URL = "" } = item;
  const stoppedDate = null;
  const renderTD = () => {
    if (hideFirstCol) return <td key={regionName}></td>;
    const slug = regionSlug || regionName?.toLowerCase().replace(/\s/g, "-");
    let hasLink;
    if (isInRegionList(slug) && !isInRegionNoLinkList(slug)) {
      // Override provided path with URL if included
      // This is meant to handle us territories being included in country data
      path = URL ? URL : path;
      hasLink = true;
    }
    const href = `${path}${slug}${pageSection}`;

    let displayName = regionName;
    if (useRegionCodes) {
      if (useRegionCodeType === "state") {
        displayName = findStateCode(regionName.toLowerCase());
      } else if (useRegionCodeType === "country") {
        displayName = findCountryCode(regionName.toLowerCase());
      }
    } else {
      if (mobileColumn && useNameShortening) {
        displayName = shortenRegionName(displayName);
      }
    }
    return (
      <td className={styles.item} key={regionName}>
        {hasLink ? (
          regionNameLinkStyle === "button" ? (
            <Button
              href={href}
              icon={isMobile ? null : "right"}
              buttonStyle="underlined"
            >
              {displayName}
            </Button>
          ) : (
            <Link to={href}>{displayName}</Link>
          )
        ) : (
          displayName
        )}
        {!!stoppedDate && (
          <div className={styles.stoppedDate}>
            <Warning />
            <h4>STOPPED REPORTING: <span>{stoppedDate}</span></h4>
          </div>
        )}
      </td>
    );
  };
  return (
    <tr
      key={regionName}
      className={cx(styles.base, highlight && styles.active, !!stoppedDate && styles.stopped, className)}
      style={{ height: `${rowHeight}px` }}
    >
      {renderTD()}

      {/* DEFAULT ONLY: discard the first key(regionName) to prevent duplicates, it is always first */}
      {!mobileColumn &&
        Object.keys(tableColumns)
          .slice(1)
          .map((key) => addTDIfPresent(item[key], key))}
    </tr>
  );
};

export default TRow;
