import React, { useCallback } from "react";
import { geoPath, geoMercator } from "d3-geo";
import * as topojson from "topojson-client";
import { idAccessor } from "../../../../../constants";
import { nameAccessor } from "../../../../../../Shared/constants";
import Path from "../../Shared/Path";
import ActivePath from "./ActivePath";
import { useDispatch, useSelector } from "react-redux";
import Bubble from "./Bubble";
import { ascending } from "d3-array";
import City from "./City";
import { updateActiveMarker } from "../../../../../redux/modules/global";
import { useDimensions } from "../../../../../../Shared/utils";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  map: {
    width: "100%",
    height: "100%",
  },
}));

export const Map = ({
  selectedBubbleLayer,
  selectedState,
  selectedDemographicLayer,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.equityAccessToTesting.data);
  const counties = topojson.feature(data, data.objects.counties);
  const [visualizationRef, { width }] = useDimensions({
    width: 100,
  });

  const activeMarker = useSelector(
    (state) => state.equityAccessToTesting.activeMarker
  );
  const setActiveMarker = useCallback(
    (activeMarker) => dispatch(updateActiveMarker(activeMarker)),
    [dispatch]
  );

  const colorScale = selectedDemographicLayer.colorScale;
  const bubbleScale = selectedBubbleLayer.bubbleScale;

  // Handle Alaska being cut off at the -85 clip extent for Mercator
  let _projection;
  if (nameAccessor(selectedState) === "Alaska") {
    _projection = geoMercator().rotate([-80, 0]);
  } else {
    _projection = geoMercator();
  }

  // Since width is known, find height using ratio of height to width
  const extent = geoPath(_projection).bounds(counties);
  const ratio = (extent[1][1] - extent[0][1]) / (extent[1][0] - extent[0][0]);
  const height = Math.min(500, width * ratio * 1.1);
  const projection = _projection.fitSize([width, height], counties);
  const path = geoPath().projection(projection);

  const getCentroidOfActiveMarker = (feature) => {
    // Get centroid of active polygon
    const activePolygon = counties.features.find(
      (polygon) => polygon.properties.GEOID === idAccessor(feature)
    );
    setActiveMarker({
      ...feature,
      centroid: path.centroid(activePolygon),
    });
  };

  return (
    <svg
      width={width}
      height={height}
      ref={visualizationRef}
      className={classes.map}
    >
      <g className="map">
        <g className="county-paths" onMouseOut={() => setActiveMarker(null)}>
          {counties.features.map((county) => {
            const id = idAccessor(county.properties);
            return (
              <g key={`county-path-for-${id}`}>
                <Path
                  feature={{
                    ...county.properties,
                    color: colorScale(
                      +county.properties[selectedDemographicLayer.name] || 0
                    ),
                  }}
                  d={path(county)}
                  setActiveMarker={getCentroidOfActiveMarker}
                />
              </g>
            );
          })}
        </g>
        <g className="active-county-path">
          {activeMarker &&
            counties.features
              .filter((county) => {
                return (
                  idAccessor(county.properties) === idAccessor(activeMarker)
                );
              })
              .map((county) => {
                const id = idAccessor(county.properties);
                return (
                  <g key={`active-county-path-for-${id}`}>
                    <ActivePath d={path(county)} />
                  </g>
                );
              })}
        </g>
        <g className="map-bubbles">
          {counties.features
            .filter((county) => county.properties[selectedBubbleLayer.name])
            .sort((a, b) =>
              ascending(
                a.properties[selectedBubbleLayer.name],
                b.properties[selectedBubbleLayer.name]
              )
            )
            .map((county) => {
              const centroid = path.centroid(county);
              const id = idAccessor(county.properties);
              return (
                <Bubble
                  key={`bubble-for-${id}`}
                  feature={{
                    x: centroid[0],
                    y: centroid[1],
                    r: bubbleScale(county.properties[selectedBubbleLayer.name]),
                    color: selectedBubbleLayer.mapColor,
                  }}
                />
              );
            })}
        </g>
        {data.objects.places && (
          <g className="city-markers">
            {data.objects.places.geometries.slice(0, 3).map((city) => {
              const [x, y] = projection(city.coordinates);
              return (
                <City
                  id={city.id}
                  width={width}
                  x={x}
                  y={y}
                  key={`marker-for-city-${city.id}`}
                />
              );
            })}
          </g>
        )}
      </g>
    </svg>
  );
};

export default Map;
