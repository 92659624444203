import React, { useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { updateTableTooltip } from "../../../../../redux/modules/global";

const useStyles = makeStyles((theme) => ({
  bar: {
    fill: "#e5e5e5",
    stroke: "none",
    "&:hover": {
      fill: "#515c6a",
    },
  },
}));

const Bar = ({ x, y, height, width, item, formatter, additionalText = "", accessor = d => d.value }) => {
  const classes = useStyles();
  const barRef = useRef(null);
  const dispatch = useDispatch();
  const setTooltip = useCallback(
    (tooltip) => dispatch(updateTableTooltip(tooltip)),
    [dispatch]
  );

  const onBarMouseOver = () => {
    const { top, left } = barRef.current.getBoundingClientRect();
    setTooltip({
      ...item,
      formatter,
      top,
      left,
      additionalText,
      accessor
    });
  };

  return (
    <rect
      ref={barRef}
      className={classes.bar}
      x={x}
      width={width}
      y={y}
      height={Math.max(height, 0)}
      onMouseOver={item ? onBarMouseOver : null}
      onMouseOut={() => setTooltip(null)}
    />
  );
};

export default Bar;
