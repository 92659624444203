import React from "react";
import TrendColumn from "../StateRow/TrendColumn";
import BaseChart from "../StateRow/BaseChart";
import Tests from "../StateRow/Tests";
import PercentPositive from "../StateRow/PercentPositive";
import { updatedOnDateAndTime } from "data/data-viz";
import {
  COMMA_FORMATTER,
  NEW_CASES,
  PERCENT_FORMATTER,
  PERCENT_POSITIVE,
  SINGLE_DECIMAL_FORMATTER,
  STATE_NAME,
  TESTS,
} from "../../../../constants";
import NewCases from "../StateRow/NewCases";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { getPercentPositiveCurrentValue } from "../utils";
import CalcMethod from "../StateRow/CalcMethod";

const ExportView = ({ exportData, headerCells, layout }) => {
  const newCases = exportData.currentValues[NEW_CASES];
  const tests = exportData.currentValues[TESTS];

  // Get the selected percent positive value and metadata
  const selectedPercentPositiveApproach = exportData.values[
    PERCENT_POSITIVE
  ].find((d) => d.selected);
  const metadata = useSelector((state) => state.testingTracker.metadata);

  if (!selectedPercentPositiveApproach) return null;

  const matchingMetadata = metadata?.find(
    (d) => d.approachId === selectedPercentPositiveApproach.approachId
  );

  // Get the most recent value for the percent positive text
  const percentPositive = getPercentPositiveCurrentValue(
    selectedPercentPositiveApproach
  );

  const alternateLayout = layout === "alternate";

  return (
    <div
      className={classNames("export-view", {
        "layout-alternate": alternateLayout,
      })}
      id="export-view"
    >
      {!alternateLayout && (
        <div className="export-view__header">
          <img
            src="/images/jhum-crc-logo-dark.png"
            alt="Johns Hopkins University & Medicine | Coronavirus Resource Center"
          />
        </div>
      )}
      <div className="export-view__table">
        {!alternateLayout && (
          <div className="export-view__col export-view__col--labels">
            {headerCells.map(({ heading }, i) => {
              return (
                <div
                  key={`label-${i}`}
                  className="export-view__cell export-view__cell--label"
                >
                  <span>{heading}</span>
                </div>
              );
            })}
          </div>
        )}
        <div className="export-view__col export-view__col--values">
          {!alternateLayout && (
            <div className="export-view__cell export-view__cell--value">
              <span>{exportData.STATE_NAME}</span>
            </div>
          )}

          <div className="export-view__cell export-view__cell--value">
            <div className="export-view__chart">
              <div className="export-view__label">
                {headerCells[1].subHeading[0]}
              </div>
              <BaseChart className="visualization__container">
                <NewCases data={exportData.values[NEW_CASES]} />
              </BaseChart>
              <div className="number number--teal">{`${
                newCases < 0 ? "-" : "+"
              }${COMMA_FORMATTER(Math.abs(newCases))} new`}</div>
            </div>
            <div className="export-view__trends">
              <div className="export-view__label">
                {headerCells[1].subHeading[1]}
              </div>
              <TrendColumn
                weeklyTrends={exportData.weeklyTrends[NEW_CASES]}
                type={NEW_CASES}
              />
            </div>
          </div>

          <div className="export-view__cell export-view__cell--value">
            <div className="export-view__chart">
              <div className="export-view__label">
                {headerCells[2].subHeading[0]}
              </div>
              <BaseChart className="visualization__container">
                <Tests data={exportData.values[TESTS]} />
              </BaseChart>
              <div className="number number--black">{`${SINGLE_DECIMAL_FORMATTER(
                tests
              )} tests per 100k`}</div>
            </div>
            <div className="export-view__trends">
              <div className="export-view__label">
                {headerCells[2].subHeading[1]}
              </div>
              <TrendColumn
                weeklyTrends={exportData.weeklyTrends[TESTS]}
                type={TESTS}
              />
            </div>
          </div>

          <div className="export-view__cell export-view__cell--value">
            <div className="export-view__chart">
              <div className="export-view__label">
                {headerCells[3].subHeading[0]}
              </div>
              <BaseChart className="visualization__container">
                <PercentPositive
                  data={selectedPercentPositiveApproach.values}
                  stateName={exportData[STATE_NAME]}
                />
              </BaseChart>
              <div className="number number--teal">{`${PERCENT_FORMATTER(
                percentPositive
              )} positive`}</div>
            </div>
            <div className="export-view__trends">
              <div className="export-view__label">
                {headerCells[3].subHeading[1]}
              </div>
              <CalcMethod approach={matchingMetadata} />
            </div>
          </div>
        </div>
      </div>
      <div className="export-view__footer">
        <p>
          This graphic was last updated on <br />
          {updatedOnDateAndTime()}.
        </p>
        {!alternateLayout && (
          <>
            <p className="export-view__attribution">
              <a href="/" alt="footer-root">
                coronavirus.jhu.edu
              </a>
            </p>
            <p>
              <a
                href="https://coronavirus.jhu.edu/"
                target="_blank"
                rel="noopener noreferrer"
              >
                &copy; 2020 Johns Hopkins University. All rights reserved.
              </a>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

ExportView.defaultProps = {};
export default ExportView;
