import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { nameAccessor, valueAccessor } from '../../../constants';
import InputBase from '@material-ui/core/InputBase';

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    padding: '6.5px 26px 6.5px 16px',
  },
}))(InputBase);

const useStyles = makeStyles(theme => ({
  formControl: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    '&.Mui-focused': {
      color: '#000',
    },
  },
  select: {
    borderRadius: 5,
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.2)',
    '.MuiSelect-select': {
      paddingLeft: theme.spacing(1),
    },
    '&:hover:not(.Mui-disabled):before, &:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
  },
}));

const CustomSelect = ({ items, selectedItem, onChange, label, id }) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={`${id}-label`} className={classes.label}>
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={`${id}`}
        value={valueAccessor(selectedItem)}
        onChange={evt => onChange(evt.target.value)}
        className={classes.select}
        input={<BootstrapInput />}
      >
        {items.map(item => {
          return (
            <MenuItem key={`region-menu-item-${valueAccessor(item)}`} value={valueAccessor(item)}>
              {nameAccessor(item)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
CustomSelect.defaultProps = {
  items: [],
  selectedItem: {},
  onChange: () => null,
  label: '',
  id: '',
};
export default CustomSelect;
