import React, {useEffect, useRef, useState} from 'react';
import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import styles from "./DataSelection.module.scss";
import DualAxis from "./DualAxis";

const DataSelection = ({stateId}) => {
  const ref = useRef(null);
  const [data, isDataLoading] = useFetch(`https://jhucoronavirus.azureedge.net/api/v2/testing/state/${stateId ? stateId : `US`}.json`);
  const [width] = useDimensions(ref);
  const [options, setOptions] = useState([
    {
      value: 'default',
      text: `Positivity: Cases / Tests`,
      order: 2
    },
  ])
  const [selected, setSelected] = useState(options[0].value)
  const handleChoice = (e) => {
    setSelected(e.currentTarget.value)
  }
  useEffect(() => {
    if (!isDataLoading) {
      let choices = [
        {
          value: 'default',
          text: `Positivity: Cases / Tests`,
          order: 2
        },
      ];
      if (data.positivity_type || stateId === null) {
        choices.push({
          value: '7-day',
          text: `Positivity${stateId ? `: ${data.positivity_type}` : ': Hierarchy'}`,
          order: 1
        })
        choices.push({
          value: 'tests',
          text: `Total Tests${stateId ? `: ${data.positivity_type.split(' / ')[1]}` : ''}`,
          order: 3
        })
      }
      setOptions(choices.sort((a,b) => a.order - b.order))
    }
  }, [data, isDataLoading, stateId])

  useEffect(() => {
    setSelected(options[0].value)
  },[options])

    return (
      <>
      {options &&
        <div className={styles.outer}>
          <div className={styles.dropdown}>
            <select value={selected} onChange={handleChoice}>
              {options.map((opt) => (
                <option key={`option_${opt.value}`} value={opt.value}>
                  {opt.text}
                </option>
              ))}
            </select>
          </div>
        </div>}
        <div id="individual-states-data-selected" ref={ref}>
          {!isDataLoading &&
            <DualAxis data={data} dataSelection={selected} width={width} />
          }
        </div>
      </>
    );
};

export default DataSelection;
