import React from "react";
import ReactMarkdown from "react-markdown/with-html";
import { updatedOnDateAndTime, getConfig } from "data/data-viz";
import styles from "./DataSources.module.scss";
import { useFetch } from "util/hooks/useFetch";

export const DataSources = ({ dataSet }) => {
  const config = getConfig(dataSet);

  return config.sources ? (
    <div className={styles.base}>
      <div>
        <strong>
          {config.sources.length === 1 ? "Data Source:" : "Data Sources:"}
        </strong>
      </div>
      {config.sources.map((source, i) => (
        <span key={`source-${i}`}>
          <ReactMarkdown
            source={source}
            escapeHtml={false}
            linkTarget="_blank"
          />
          {i < config.sources.length - 1 ? "; " : ""}
        </span>
      ))}
    </div>
  ) : null;
};

export const DataTime = ({ dataSet }) => {
  return (
    <p>
      <strong>
        This page was last updated on {updatedOnDateAndTime(dataSet)}.
      </strong>
    </p>
  );
};

export const DataTimeFetched = ({ URL }) => {
  const [data, isLoading] = useFetch(URL);

  if (isLoading || !data) return null;

  const formatDate = ({ last_update, name }) => {
    const tsDate = new Date(last_update);
    const dateString = tsDate.toLocaleString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      time: "numeric",
      timeZone: "America/New_York",
    });
    const timeString = tsDate.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
      timeZone: "America/New_York",
    });
    return `${dateString} at ${timeString}`;
  };

  const formatted = formatDate(data);
  if (!formatted) return null;

  return (
    <p>
      <strong>This page was last updated on {formatted}.</strong>
    </p>
  );
};
