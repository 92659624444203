import React, { FC } from "react";
import cx from "classnames";
import styles from "./BlockQuote.module.scss";

export type BlockQuoteProps = {
  slug?: string;
  children?: any;
  className?: string;
};

const BlockQuote: FC<BlockQuoteProps> = ({ slug, children, className }) => {
  if (!children?.length) return children;
  return children.map((child, i) => {
    const { value } = child?.props?.children?.[0]?.props;
    return (
      <div
        className={cx(styles.base, className)}
        key={`${slug}-blockQuote#${i}`}
      >
        {value}
      </div>
    );
  });
};

export default BlockQuote;
