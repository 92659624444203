import React from "react";
import RegionSearchCTA from "components/content/ByRegion/RegionSearchCTA";
import { getSnapshotForRoute } from "data/util";
import { getArticleCardsByVerticals } from "components/layout/ArticleCard/utils";

export const RegionSearchCard = {
  category: "By Region",
  title: "See the latest data in your region",
  body: (
    <div>
      <p>Explore stats and trends specific to your country or U.S. state</p>
      <RegionSearchCTA />
    </div>
  ),
  new: false,
  href: "/region",
};

export const NewCases50StatesCard = {
  category: "New Cases of COVID-19 in US States",
  imageSrc: getSnapshotForRoute("/data/new-cases-50-states"),
  containImage: true,
  title: "Have states flattened the curve?",
  body: <p>See if new cases are rising or declining as states reopen.</p>,
  href: "/data/new-cases-50-states",
  verticals: ["tracking", "other"],
};

export const TestingTrendsToolCard = {
  category: "Testing Trends Tool",
  title: "Track trends in COVID-19 cases and tests",
  href: "/testing/tracker/overview",
  verticals: ["testing"],
};

export const VaccineTmelineCard = {
  category: "INTERACTIVE",
  title: "Developing vaccines quickly and safely",
  body: (
    <p>
      An interactive tool from our vaccine experts that explores how a vaccine
      is developed and the differences between a typical timeline and an
      accelerated timeline.
    </p>
  ),
  href: "/vaccines/timeline",
  imageSrc: "/images/vaccine-timeline-thumbnail.png",
  containImage: true,
  new: false,
};

export const FeaturedArticleCards = () => {
  // const slugs = [
  //   // "preserving-the-life-cycle-of-pandemic-data",
  //   // "q-and-a-addressing-latino-health-disparities-with-pandemic-data",
  //   "detailed-demographic-data-highlights-health-disparities",
  // ];
  const content = getArticleCardsByVerticals(
    ["pandemic-data-initiative", "vaccines"],
    {
      overrides: {},
      limit: 1,
      authorsInBody: false,
      sectionToCaption: true,
    }
  )
    // .filter((card) => slugs.includes(card.slug))
    .map((card) => ({
      ...card,
      date: "",
      containImage: false,
      new: false,
    }));
  return content;
};

export const VaccineQACard = {
  category: "Vaccines Q & A",
  title: "Pfizer Moves Toward Booster Approval & Vaccine for Children",
  body: (
    <p>
      The FDA and CDC are expected to finalize their decisions about Pfizer
      booster doses in the same week the company annof
    </p>
  ),
  href:
    "/vaccines/q-n-a/pfizer-moves-toward-booster-approval-and-vaccine-for-children",
  imageSrc: null,
  containImage: false,
  new: false,
};

export const StateTimelineCard = {
  category: "State Timeline",
  imageSrc: "/images/temp-4.0/state-timeline-preview.png",
  title: "Impact of opening and closing decisions by state",
  body: (
    <p>
      A look at how social distancing measures may have influenced trends in
      COVID-19 cases and deaths
    </p>
  ),
  new: false,
  href: "/data/state-timeline",
  verticals: ["tracking"],
  containImage: true,
};

export default [
  ...FeaturedArticleCards(),
  TestingTrendsToolCard,
  StateTimelineCard,
];
