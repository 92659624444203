export const formatDay = (date) =>
  date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    day: "numeric",
    month: "short",
  });

export const formatMonth = (date) =>
  date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    month: "long",
  });

export const formatYear = (date) =>
  date.toLocaleDateString(undefined, {
    timeZone: "UTC",
    year: "numeric",
  });

export const formatNumber = (value, isPercentage) => {
  if (!isPercentage && typeof value !== "number" && !value) return "N/A";
  return isPercentage
    ? `${(value * 100).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}%`
    : value.toLocaleString();
};
