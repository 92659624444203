import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { EventSwitch } from "./Events/EventSwitch";
import Videos from "./Videos";

const Webcast = () => {
  return (
    <Switch>
      <Redirect
        from="/live/health-policy-forum"
        to="/live/events/health-policy-forum-05-04-2021"
      />
      <Redirect
        from="/live/events/health-policy-forum"
        to="/live/events/health-policy-forum-05-04-2021"
      />
      <Redirect
        exact
        from="/live/events/johns-hopkins-health-policy-forum"
        to="/live/events/health-policy-forum-05-04-2021"
      />

      <Route path="/live/events/:eventId/:videoId?" component={EventSwitch} />
      <Route path="/live/:videoId?" component={Videos} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default Webcast;
