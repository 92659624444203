import React from 'react';
import Axes from '../Axes';
import { scaleLinear } from 'd3-scale';
import { extent } from 'd3-array';

const ContinuousLegend = ({ selectedDataSet, width }) => {
  const { colorScale, formatter } = selectedDataSet;

  // Create an artificial x-scale for displaying the legend
  const xScale = scaleLinear()
    .domain(extent(colorScale.domain()))
    .rangeRound([0, width]);

  return (
    <div className="map__legend-wrapper">
      <div className="map__legend">
        <svg width={width} height={35}>
          <g className="legend" transform={`translate(${0},${0})`}>
            {colorScale
              .range()
              .map(d => colorScale.invertExtent(d))
              .map((d, i) => {
                const [start, end] = d;
                return (
                  <rect
                    key={`legend-rect-${i}`}
                    height={11.3}
                    x={xScale(start)}
                    width={xScale(end) - xScale(start)}
                    fill={colorScale(start)}
                  />
                );
              })}
            <Axes colorScale={colorScale} xScale={xScale} xScaleTickFormat={formatter} height={8} />
          </g>
        </svg>
      </div>
    </div>
  );
};

export default ContinuousLegend;
