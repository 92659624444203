import { getArticlesBySection, getAuthor } from "data/util";
import React from "react";
import ReactMarkdown from "react-markdown";
import getPlaceholderImage from "util/placeholder-image";
import { getArticlesByVertical } from "../../../../data/util";

export const contentToCard = ({
  id,
  title,
  description,
  url,
  image,
  source,
  date,
  type,
  legacyIsNew,
  hasVideo,
  verticals,
}) => ({
  id,
  category: type,
  title,
  body: <p>{description}</p>,
  href: url,
  imageSrc: image,
  new: legacyIsNew,
  hasVideo,
  source,
  date,
  verticals,
});

export const mapContentsToCards = (contents) => contents.map(contentToCard);

export const chartToCard = ({
  baseRoute,
  slug,
  title,
  subtitle,
  caption,
  legacyIsNew,
  image,
  ...others
}) => ({
  category: title,
  title: subtitle,
  body: <p>{caption}</p>,
  href: `${baseRoute}/${slug}`,
  imageSrc: image || "",
  new: legacyIsNew,
  ...others,
});

export const mapChartsToCards = (contents) => contents.map(chartToCard);

export const moduleToCard = ({ course, slug, int, title }) => ({
  category: `Module ${int}`,
  title,
  href: `/covid-19-basics/${course}/${slug}`,
  imageSrc: "",
});

export const mapModulesToCards = (modules) => modules.map(moduleToCard);

export const articleToCard = (
  {
    baseRoute,
    slug,
    title,
    subtitle,
    caption,
    legacyIsNew,
    image,
    meta,
    authors,
    section,
    isDrafted,
    ...others
  },
  authorsInBody,
  sectionToCaption
) => ({
  category: sectionToCaption ? section : subtitle,
  title,
  href: meta?.path,
  imageSrc: image || meta?.image || "",
  new: legacyIsNew,
  meta,
  caption,
  slug,
  isdrafted: isDrafted ? "true" : "false", // Fixes a console warning
  // Body = authors or caption or null
  body:
    authorsInBody && !!authors?.length ? (
      <p>
        {"By "}
        {authors
          .map((authorSlug) => getAuthor(authorSlug))
          .map((author) => author.name)
          .join(", ")}
      </p>
    ) : !!caption ? (
      <ReactMarkdown source={caption} />
    ) : null,
  ...others,
});

export const mapArticlesToCards = (contents, authorsInBody, sectionToCaption) =>
  contents.map((content) =>
    articleToCard(content, authorsInBody, sectionToCaption)
  );

export const getArticleCardsBySection = (
  section,
  overrides = {},
  limit = Infinity,
  authorsInBody = true
) =>
  mapArticlesToCards(
    getArticlesBySection(section)
      .slice(0, limit)
      .map((card) => ({
        ...card,
        ...overrides,
      })),
    authorsInBody
  );

export const getArticleCardsByVerticals = (
  verticals = [],
  options = {
    overrides: {},
    limit: Infinity,
    authorsInBody: false,
    sectionToCaption: false,
  }
) =>
  mapArticlesToCards(
    getArticlesByVertical(verticals)
      .slice(0, options?.limit)
      .map((card) => ({
        ...card,
        imageSrc:
          card.attachments?.[0] ||
          card.meta?.image ||
          getPlaceholderImage(card.id),
        ...options?.overrides,
      })),
    options.authorsInBody,
    options.sectionToCaption
  );
