import React, { useState } from "react";
import PropTypes from "prop-types";
import { getArticlesBySection } from "data/util";
import {
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
} from "components/layout/ArticleGrid";
import { mapArticlesToCards } from "components/layout/ArticleCard/utils";
import classNames from "classnames/bind";
import styles from "./ArticleFeed.module.scss";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

const ArticleFeed = ({
  section = "",
  articles,
  limit = 6,
  hasMajor = true,
  hideNoContentsMessage = false,
  topSplit = false,
  blade,
  page,
  classNames,

  topCardProps = {
    vertical: true,
    containImage: true,
    major: true,
  },
  defaultCardProps = {
    major: false,
    vertical: true,
  },
  columnLayouts = {
    default: "flexible",
    topCards: null,
  },
}) => {
  // Pagination State:
  const [currentPage, setCurrentPage] = useState(page);
  const incrementPage = () => setCurrentPage(currentPage + 1);

  const selectedArticles = articles ? articles : getArticlesBySection(section);
  const contents = mapArticlesToCards(selectedArticles, true);
  const contentCount = contents.length;

  const topCards =
    hasMajor || contentCount < 6
      ? contents
      : !hasMajor
      ? []
      : contents.slice(0, topSplit ? 2 : 1);
  const otherCards = contents.slice(topCards.length);
  const visibleContents = otherCards.slice(0, limit * currentPage);
  const isMore = otherCards.length - visibleContents.length > 0;

  const layoutProps = {
    topCards: columnLayouts?.topCards
      ? columnLayouts?.topCards
      : topSplit && contents.length > 1
      ? [[6, 6]]
      : [[12]],
    default: columnLayouts?.default ? columnLayouts?.default : "flexible",
  };

  return (
    <div className={cx("base", classNames)}>
      <div className={cx("content")}>
        {!contentCount && !hideNoContentsMessage && (
          <p>There is nothing to display.</p>
        )}

        {!!topCards.length && (
          <ArticleGridLayoutEngine
            articles={topCards}
            columnLayout={layoutProps.topCards}
            gap={32}
          >
            {new Array(topCards.length).fill(topCards).map((_, i) => {
              return (
                <ArticleGridLayoutTemplate.SingleCard
                  key={i}
                  articleCardProps={{
                    ...topCardProps,
                  }}
                />
              );
            })}
          </ArticleGridLayoutEngine>
        )}

        {!!visibleContents.length && (
          <>
            <ArticleGridLayoutEngine
              articles={visibleContents}
              columnLayout={layoutProps.default}
              gap={32}
            >
              {new Array(visibleContents.length)
                .fill(visibleContents)
                .map((_, i) => {
                  return (
                    <ArticleGridLayoutTemplate.SingleCard
                      key={i}
                      containImage={true}
                      href="not a real URL"
                      articleCardProps={{
                        major: false,
                        vertical: true,
                        ...defaultCardProps,
                      }}
                    />
                  );
                })}
            </ArticleGridLayoutEngine>

            {!blade && isMore && (
              <div className={cx("grid-footer")}>
                <Button
                  icon={"down"}
                  shape="square"
                  buttonStyle="bordered"
                  onClick={incrementPage}
                >
                  Load More
                </Button>
              </div>
            )}
          </>
        )}
        {blade && (
          <Button
            buttonStyle="bordered"
            icon="right"
            href={topCards[0].href}
            style={{ paddingTop: "2px", marginTop: "1rem" }}
          >
            View all
          </Button>
        )}
      </div>
    </div>
  );
};

ArticleFeed.propTypes = {
  section: PropTypes.string,
  feature: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  classNames: PropTypes.string,
};

ArticleFeed.defaultProps = {
  feature: false,
  page: 1,
  pageSize: 12,
};

export default ArticleFeed;
