import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AccordionItem from './AccordionItem';

import classNames from 'classnames/bind';
import styles from './Accordion.module.sass';
const cx = classNames.bind(styles);

export { default as AccordionItem } from './AccordionItem';


export function Accordion({ children: childrenProp, className, autoOpen }) {
  const children = React.Children.toArray(childrenProp);

  const initialActiveKey = autoOpen ? children[0].key : "";
  const [activeKey, setActiveKey] = useState(initialActiveKey);

  // If there are no children, return just the wrapper element
  if (!children.length) return <div className={classNames(className, cx('base'))} />;
  // Check for non-AccordionItem children
  const hasRogueChildren = !children.every((child) => child.type === AccordionItem);
  if (hasRogueChildren) throw new Error('Every direct child of Accordion must be an AccordionItem');

  return (
    <div className={classNames(className, cx('base'))}>
      { children.map((child) => React.cloneElement(child, {
        isOpen: child.key === activeKey,
        activate: () => setActiveKey(activeKey === child.key ? null : child.key),
      })) }
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  autoOpen: PropTypes.bool,
};

Accordion.defaultProps = {
  autoOpen: true
}
