import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import * as GTM from "util/gtm";
import WorldCountry from "./WorldCountry";
import USState from "./USState";
import Regions from "./Regions";

const ByRegion = () => {
  useEffect(GTM.clear, []);

  return (
    <Switch>
      <Redirect from="/region/us/" exact={true} to="/region" />
      <Route path="/region/us/:stateId" component={USState} />
      <Route path="/region/:countryId" component={WorldCountry} />
      <Route path="/region" exact component={Regions} />
    </Switch>
  );
};

export default ByRegion;
