import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";
import { ReactComponent as CrossIcon } from "images/icons/cross.svg";
import cx from "classnames";
import styles from "./Modal.module.scss";

const modalRoot = document.getElementById("modal-root");

const ModalContent = ({ children }) => {
  return ReactDOM.createPortal(children, modalRoot);
};

const Modal = ({ children, show, dismiss, x, className }) => {
  return (
    <AnimatePresence>
      {show && (
        <ModalContent>
          <motion.div
            className={styles.base}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={dismiss}
          >
            <motion.div
              className={cx(styles.modalContent, className)}
              initial={{ x: "-50%", y: "-40%" }}
              animate={{ x: "-50%", y: "-50%" }}
              exit={{ x: "-50%", y: "-40%" }}
            >
              {x && (
                <CrossIcon
                  className={styles.closeModalContent}
                  onClick={dismiss}
                />
              )}
              {children}
            </motion.div>
          </motion.div>
        </ModalContent>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  show: PropTypes.bool,
  dismiss: PropTypes.func,
  x: PropTypes.bool,
  className: PropTypes.string,
};

export default Modal;
