import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {COLORS} from "../../../../../../../Shared/constants";

const useStyles = makeStyles(() => ({
    path: {
        stroke: COLORS.blueDark,
        strokeWidth: '2px',
        fillOpacity: 0,
        pointerEvents: 'none',
    }
}));

const ActivePath = ({d}) => {
    const classes = useStyles();
    return (
        <path className={classes.path} d={d}/>
    )
};

export default ActivePath;
