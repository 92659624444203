import React, { useState, useRef, useEffect, useContext } from "react";
import { geoPath } from "d3-geo";
import * as albersT from "geo-albers-usa-territories";
import { scaleOrdinal } from "d3-scale";
import { TooltipContext } from "../Tooltip";
import styles from "./StateReportingMap.module.scss";
import {
  daysLabels,
  daysLabelsMobile,
  getFrequency,
  isYes,
  ReportFrequency,
} from "./util";

let fillcolor: any;
let legendbox: any;

const labelText = "Reporting data: ";

const Map = ({
  mapData,
  data,
  dataset: { value, label },
  ttWidth,
  children,
}) => {
  const svgContainerRef = useRef(null);
  const [width, setWidth] = useState(600);
  const [height, setHeight] = useState(600);
  /**
   * Screen and window resize manager
   */
  useEffect(() => {
    function handleResize() {
      requestAnimationFrame(() => {
        // If we're able to get the SVG's parent,
        // set the height and width to be the same as the parent's
        if (svgContainerRef?.current) {
          const parent = svgContainerRef.current.parentElement;
          const parentSize = {
            width:
              window.innerWidth < 970
                ? parent.offsetWidth * 0.9
                : parent.offsetWidth * 0.5,
          };

          setWidth(parentSize.width);
          setHeight(parentSize.width * 0.9);
        } else {
          // Else, set it to attempt to fit the chart
          // based on the size of the window
          setWidth(
            typeof window === "undefined"
              ? 100
              : window.innerWidth >= 1248
              ? 600
              : window.innerWidth < 970
              ? window.innerWidth
              : window.innerWidth * 0.5
          );
          setHeight(600);
        }
      });
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [svgContainerRef]);

  const margin = { top: 30, right: 0, bottom: 0, left: width < 600 ? 40 : 0 };
  const innerwidth = width - margin.left - margin.right;
  const innerheight = height - margin.top - margin.bottom;
  const projection = albersT
    .geoAlbersUsaTerritories()
    .scale(innerwidth * 1.35)
    .translate([innerwidth / 2, innerheight / 2 + margin.top]);
  const path = geoPath(projection);
  const chroma = [
    "#d73027",
    "#f46d43",
    "#fdae61",
    "#fee08b",
    "#d9ef8b",
    "#a6d96a",
    "#66bd63",
    "#1a9850",
  ];
  const values = ["0", "1", "2", "3", "4", "5", "6", "7"];

  const color = scaleOrdinal().domain(values).range(chroma).unknown("#ccc");
  const handleFill = (feature) => {
    return color(
      data
        .filter((d) => d.dataset === label)
        .find((d) => d.state === feature.properties.name)?.days
    );
  };
  const tooltipContext = useContext(TooltipContext);

  function handleMouseOver(feature) {
    let datapoint = data
      .filter((d) => d.dataset === label)
      .find((d) => d.state === feature.properties.name);
    if (datapoint) {
      const [x, y] = path.centroid(feature);
      const spanHeight =
        16 *
        (datapoint.days === "7" || datapoint.days === "0"
          ? 1
          : parseInt(datapoint.days));
      let spans: any;

      if (datapoint.days === "7") {
        spans = [
          <p key="daily" className={styles.day}>
            {labelText}
            <br />
            <strong>{ReportFrequency["daily"]}</strong>
          </p>,
        ];
      } else if (datapoint.days === "0") {
        spans = [
          <p key="none" className={styles.day}>
            {labelText}
            <br />
            <strong>Does not publish</strong>
          </p>,
        ];
      } else {
        const { sat, sun } = datapoint;
        if (datapoint.days === "5" && !isYes(sat) && !isYes(sun)) {
          spans = [
            <p key="daily" className={styles.day}>
              {labelText}
              <br />
              <strong>{ReportFrequency["weekdays"]}</strong>
            </p>,
          ];
        } else {
          if (datapoint.days === "1") {
            spans = [
              <span>
                {labelText} <br />
              </span>,
            ].concat(
              daysLabelsMobile.map((day, i) => {
                if (datapoint[day.toLowerCase()] === "Y") {
                  return (
                    <p key={day} className={styles.day}>
                      <strong>{daysLabels[i]}</strong>
                    </p>
                  );
                }
                return null;
              })
            );
          } else {
            spans = [
              <p key="none" className={styles.day}>
                {labelText}
                <br />
                <strong>{getFrequency(datapoint)}</strong>
              </p>,
            ];
          }
        }
      }
      const content = (
        <div>
          <p className={styles.statename}>{datapoint.state}</p>
          {spans}
          <p className={styles.updated}>Last updated: {datapoint.updated}</p>
        </div>
      );
      tooltipContext.dispatch({
        show: true,
        x: x + ttWidth > innerwidth ? x - ttWidth : x,
        y: y + ttWidth > innerheight ? y - (spanHeight + 30) : y,
        content: content,
        ttHeight: spanHeight + 110,
      });
    }
  }
  function handleMouseOut() {
    tooltipContext.dispatch({ show: false });
  }

  return (
    <div ref={svgContainerRef}>
      <svg className={"state-reporting-map"} width={width} height={height}>
        <g
          transform={
            width < 600
              ? `translate(0,${innerheight / 2 - 80})`
              : `translate(22,60)`
          }
        >
          {width < 600 && (
            <text dy={-7} fill="#666">
              Days
            </text>
          )}

          {values.map((n, i) => {
            legendbox = color(n);
            return (
              <g key={`days${n}`}>
                <rect
                  fill={legendbox}
                  width={15}
                  height={15}
                  x={width < 600 ? 0 : 75 * i}
                  y={width < 600 ? 22 * i : 0}
                />
                <text
                  x={width < 600 ? 17 : 75 * i + 17}
                  y={width < 600 ? 22 * i : 0}
                  dy={12}
                  fill="#666"
                >
                  {n} {width >= 600 && "days"}
                </text>
              </g>
            );
          })}
        </g>
        <g transform={`translate(${margin.left},${margin.top})`}>
          {mapData.features.map((feature) => {
            fillcolor = handleFill(feature);
            return (
              <path
                d={path(feature)}
                key={feature.id}
                style={{ fill: fillcolor, stroke: "white" }}
                onMouseLeave={() => handleMouseOut()}
                onMouseOver={() => handleMouseOver(feature)}
              />
            );
          })}
        </g>
        {children}
      </svg>
    </div>
  );
};

export default Map;
