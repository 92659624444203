import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./NavGroup.module.scss";
import { navItemMatches } from "../../Header";
import SectionLink from "../SectionLink";
import { sectionNav } from "../util";
import { sectionGroupProps } from "data/navigation";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const NavGroup = ({ id, name, routes = [], onClose, pathname, isActive }) => {
  const [isOpen, setOpenState] = useState(false);

  let activeGroup;
  const renderRoutes = routes.map((route, i) => {
    const isActive = navItemMatches(pathname, route);
    if (!activeGroup) {
      activeGroup = isActive;
    }
    return (
      <Link key={route.id} to={route.to}>
        <li key={`${route.id}-${i}`} className={cx("", { active: isActive })}>
          <SectionLink
            section={route}
            isActive={isActive}
            isGrouped={true}
            onClose={onClose}
          />
        </li>
      </Link>
    );
  });

  const handleClick = () => setOpenState(!isOpen);
  return (
    <div
      className={cx(styles.base, { open: isOpen })}
      onClick={handleClick}
      onMouseLeave={() => setOpenState(false)}
    >
      <div className={styles.groupHeaderContainer}>
        <span
          className={cx(styles.groupName, { active: activeGroup })}
          aria-label={name}
        >
          {name}
        </span>
        {/* This div removes the dropdown flicker when the cursor moves from the <span> to the <ul> */}
        <div />
      </div>
      <ul className={cx(styles.sectionGroup, { open: isOpen })}>
        {renderRoutes}
      </ul>
    </div>
  );
};

NavGroup.propTypes = {
  ...sectionGroupProps,
  ...sectionNav,
};

export default NavGroup;
