import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { getPage } from "data/util";
import styles from "./TracingResults2021.module.scss";
import SideNarrative from "components/layout/SideNarrative";
import TabBar from "components/layout/shell/TabBar";
import ContactTracers from "./ContactTracers/index";
import CareResourceCoordination from "./CareResourceCoordination";

const tabOptions = ["Contact Tracers", "Care Resource Coordination"];

const TracingResults2021 = ({ location }) => {
  const [tab, setTab] = useState(tabOptions[0]);
  const handleTabChange = (selectedTab) => setTab(selectedTab);
  const page = getPage("/contact-tracing/state-survey-march-2021");
  const { content_blocks } = page;
  const aboutContentSlug =
    tab === tabOptions[0]
      ? "state-survey-results-contact-tracers-about-review"
      : "state-survey-results-care-resource-coordination-about-review";

  return (
    <SideNarrative
      pageProps={{ path: "/contact-tracing/state-survey-march-2021" }}
      narrative={
        <ReactMarkdown source={content_blocks?.[aboutContentSlug]?.content} />
      }
    >
      <div className={styles.container}>
        <TabBar
          className={styles.tabBar}
          onChange={handleTabChange}
          tabs={tabOptions}
          type="highlight"
        />
        {tab === tabOptions[0] ? (
          <ContactTracers content_blocks={content_blocks} />
        ) : (
          <CareResourceCoordination content_blocks={content_blocks} />
        )}
        <br />
      </div>
    </SideNarrative>
  );
};

TracingResults2021.propTypes = {
  location: PropTypes.object,
};

export default TracingResults2021;
