import React, { useEffect, useRef } from "react";
import { select } from "d3-selection";
import { axisBottom, axisRight, axisTop } from "d3-axis";
import styles from "./FacetAxes.module.scss";
// import {nameAccessor} from 'components/content/DataCenter/charts/Shared/constants';
// import { ReactComponent as Warning } from "images/icons/warning.svg";
// import { useRouteMatch } from "react-router-dom";

const Axes = ({
  xScale,
  yScale,
  xScaleTickFormat,
  selectedDatasets,
  yScaleTickFormat,
  colors,
  height,
  width,
  isOnTop,
  offset,
  labelCenter,
  labelOffset,
  index,
  stoppedDate,
  setStopppedData,
}) => {
  // const match = useRouteMatch();
  const xAxisRef = useRef(null);
  const yAxisRef = useRef(null);

  // console.log(selectedDatasets);

  useEffect(() => {
    if (xScale) {
      renderXAxis();
    }
    if (!isOnTop && yScale) {
      renderYAxis();
    }
  });

  const renderYAxis = () => {
    const yAxis = select(yAxisRef.current);
    yAxis
      .call(
        axisRight(yScale)
          .tickValues(yScale.ticks(2).concat(0))
          .tickSize(width)
          .tickFormat(yScaleTickFormat)
      )
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("x", 0)
          .attr("dx", "-5px")
          .attr("dy", "5px")
      )
      .call((g) => g.selectAll(".tick line").attr("stroke", colors.bar));

    yAxis.select(".domain").remove();
  };

  const renderXAxis = () => {
    const xAxis = select(xAxisRef.current);

    if (!isOnTop) {
      xAxis.call(axisBottom(xScale).tickFormat(xScaleTickFormat).ticks(5));
    } else {
      xAxis.call(axisTop(xScale).tickFormat(xScaleTickFormat).ticks(5));
    }

    xAxis.select(".domain").remove();
    xAxis.selectAll(".tick line").remove();
    xAxis
      .selectAll(".tick")
      .filter((d, i, list) => i === list.length - 1)
      .select("text")
      .attr("transform", "translate(-16)");
  };

  return (
    <g className={styles.axis}>
      {xScale && (
        <g
          className="x--axis"
          transform={`translate(0,${height - offset})`}
          ref={xAxisRef}
          clipPath="url(#bar-chart-clip)"
        />
      )}
      {!isOnTop && yScale && (
        <g
          className={styles.yaxis}
          transform={`translate(0,0)`}
          ref={yAxisRef}
        />
      )}
      {!isOnTop && yScale && (
        <text
          className="axis-title left"
          style={{ fill: colors.line }}
          transform={`translate(${
            labelOffset ? "-40" : "-60"
          },${labelCenter})rotate(-90)`}
        >
          {selectedDatasets}
        </text>
      )}
      {/* <g>
                {!!stoppedDate && (
                    <foreignObject y={(height + 30) * index + 30} x={width - 188} width="200" height={height} >
                        <div className={styles.alert} >
                            <div
                                className={styles.stoppedDate}
                                onMouseOver={() => setStopppedData({ state: match.params?.countryId || match.params?.stateId, category: selectedDatasets, stoppedDate })}
                                onMouseLeave={() => setStopppedData({})}
                            >
                                <Warning />
                                <h4>STOPPED REPORTING: <span>{stoppedDate}</span></h4>
                            </div>
                            <div className={styles.line} style={{ height }}>
                                <span></span>
                            </div>
                        </div>
                    </foreignObject>
                )}
            </g> */}
    </g>
  );
};

const defaultProps = {
  xScale: null,
  yScale: null,
  height: 0,
  width: 0,
  xScaleTickFormat: null,
  yScaleTickFormat: null,
  tickColor: "lightgray",
  isOnTop: false,
  offset: 0,
  labelCenter: 0,
  labelOffset: false,
};

Axes.defaultProps = defaultProps;

export default Axes;
