import React, { useEffect } from "react";

import * as GTM from "util/gtm";
import styles from "./News.module.scss";
import Page from "components/layout/Page";
import NewsFeed from "./NewsFeed";
import LearnTeaser from "../Learn/LearnTeaser";
import SectionDivider from "components/common/SectionDivider";

/* TODO: Don't hack CMS data... */

const News = ({ location }) => {
  useEffect(GTM.clear, []);
    return (
    <Page>
      <LearnTeaser />
      <SectionDivider />
      <div className={styles.container}>
        <NewsFeed showVerticals={false} showTypes={false} />
      </div>
    </Page>
  );
};

export default News;