// modified from https://observablehq.com/@d3/color-legend
import React, { useRef, useEffect } from "react";

import { axisBottom } from "d3-axis";
import { range, quantile } from "d3-array";
import { format } from "d3-format";
import { scaleSequential } from "d3-scale";
import { interpolateRgb } from "d3-interpolate";
import { select } from "d3-selection";

function ramp(color, ticks, colorList) {
  const canvas = document.createElement("canvas");
  canvas.style.width = 300;
  canvas.style.height = 100;
  const context = canvas.getContext("2d");
  let gradient = context.createLinearGradient(0, 0, 300, 0);
  let step = colorList.length > 2 ? colorList.length : ticks;
  for (var i = 0; i < step; i++) {
    if (colorList.length > 2) {
      gradient.addColorStop(i / (step-1), colorList[i]);
    } else {
      gradient.addColorStop(
        i / step,
        interpolateRgb(colorList[0], colorList[1])(i / step + 0.1)
      );
    }
  }
  context.fillStyle = gradient;
  context.fillRect(0, 0, 300, 100);

  return canvas;
}

const Legend = ({ color, category, bin, margin, width, innerHeight, demographic, dataDate }) => {
  const refLegend = useRef(null);

  useEffect(() => {
    let leg = select(refLegend.current).append("g");
    leg.attr("id", "legendG");

    if (color) {
      if (bin === 'all-content') {
        leg.append('rect').attr('fill', color('Has Data')).attr('width', 15).attr('height', 15)
        leg.append('text').text(`Has ${category.label} Data by ${demographic}`).attr("fill", "currentColor")
                      .attr("text-anchor", "start")
                      .attr("font-weight", "bold")
                      .attr('dy', 13)
                      .attr('dx', 20)
      } else {
        let tickSize = 2,
          height = 50 + tickSize,
          marginTop = 18,
          marginRight = 0,
          marginBottom = 16 + tickSize,
          marginLeft = 0,
          innerWidth = 320 - marginLeft - marginRight,
          tickValues;

        let tickFormat = format(".2p");
        let ticks = 5;
        let colorList = ["#F2F3E0", category.color];

        let tickAdjust = (g) =>
          g
            .selectAll(".tick line")
            .attr("y1", marginTop + marginBottom - height)
            .attr("stroke", "#fff");
        let x = scaleSequential()
          .domain(color.domain())
          .range([0, innerWidth]);

        leg
          .append("image")
          .attr("x", marginLeft)
          .attr("y", marginTop)
          .attr("width", innerWidth)
          .attr("height", height - marginTop - marginBottom)
          .attr("preserveAspectRatio", "none")
          .attr("xlink:href", ramp(color, ticks, colorList).toDataURL());

        // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
        if (!x.ticks) {
          if (tickValues === undefined) {
            const n = Math.round(ticks + 1);
            tickValues = range(n).map((i) => quantile(color.domain(), i / (n - 1)));
          }
          if (typeof tickFormat !== "function") {
            tickFormat = format(tickFormat === undefined ? ",f" : tickFormat);
          }
        }

        leg
          .append("g")
          .attr("transform", `translate(0,${height - marginBottom})`)
          .call(
            axisBottom(x)
              .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
              .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
              .tickSize(tickSize)
              .tickValues(tickValues)
          )
          .call(tickAdjust)
          .call((g) => g.select(".domain").remove())
          .call((g) =>
            g
              .append("text")
              .attr("x", marginLeft)
              .attr("y", marginTop + marginBottom - height - 6)
              .attr("fill", "currentColor")
              .attr("text-anchor", "start")
              .attr("font-weight", "bold")
              .attr("class", "title")
              .text(category.label)
          )
          .call((g) =>
            g
              .append("text")
              .attr("x", innerWidth)
              .attr("y", marginTop + marginBottom - height - 6)
              .attr("fill", "currentColor")
              .attr("text-anchor", "end")
              .attr("font-weight", "bold")
              .attr("class", "date")
              .text(dataDate)
          );
      }
    }
    return () => {
      leg && leg.remove();
    };

  }, [color, category, bin, demographic, dataDate]);

  return (
    <g
      transform={`translate(${width / 2 - 320 / 2},${
        width > 550
          ? -margin.top
          : innerHeight + 40
      })`}
      ref={refLegend}
    ></g>
  );
};

export default Legend;
