import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { useHistory } from "react-router-dom";

import * as GTM from "util/gtm";
import {
  countryMappings,
  findCountryCode,
  expandCountryCode,
  slugify,
} from "data/geo";
import { request } from "redux/util/fetch";
import Share from "components/common/Share";
import styles from "./WorldCountry.module.scss";
import RegionMenu from "../RegionMenu";
import RegionOverview from "../RegionOverview";
import Meta from "components/common/Meta";
import OverviewNotes from "../RegionNotes/OverviewNotes";
import ScrollTarget from "components/common/PageNavigator/ScrollTarget";
import DataTimeline from "components/content/DataCenter/charts/RegionDataTimeline";

const cx = classNames.bind(styles);

const WorldCountryValidator = (props) => {
  // Bounce if country is not found...
  const countrySlug = props.match.params.countryId;
  const countryCode = findCountryCode(countrySlug);
  const history = useHistory();
  if (countryCode === null) {
    history.replace("/404");
    return null;
  } else {
    return <WorldCountry key={`${countryCode}-${countrySlug}`} {...props} />;
  }
};

const setGTM = (countrySlug) => {
  GTM.push({
    contentCountry: countrySlug,
    contentState: undefined,
  });
};

const WorldCountry = ({ match }) => {
  // Assign state
  const countrySlug = match.params.countryId;
  const countryCode = findCountryCode(countrySlug);
  const countryName = expandCountryCode(countryCode);

  const [data, setData] = useState();
  setGTM(countrySlug);

  const [vaccineData, setVaccineData] = useState(null);
  useEffect(() => {
    setGTM(countrySlug);

    (async () =>
      await request(
        `https://jhucoronavirus.azureedge.net/api/v2/regions/${countryCode.toLowerCase()}.json`
      ).then((response) => setData(response)))();

    (async () =>
      await request(
        `https://jhucoronavirus.azureedge.net/api/v3/vaccines/global.json`
      ).then((response) => {
        response.map((d) => {
          if (d.country === "US") {
            d.country = "United States";
          }
          return d;
        });

        const stateVaccineData = response.find(
          (item) => item.country === countryName
        );
        setVaccineData(stateVaccineData);
      }))();
  }, [countryCode, countrySlug, countryName]);

  return (
    <div className={cx("container")}>
      <Meta
        title={countryName}
        description={`${countryName} - COVID New Cases, Deaths, Testing Data - Johns Hopkins Coronavirus Resource Center`}
        path={`/region/${countrySlug}`}
        image="/images/region-meta-image.jpg"
      />
      <div className={cx("header")}>
        <div>
          <div className="small-heading">World Countries</div>
          <h1>{expandCountryCode(countryCode)}</h1>
        </div>
        <Share />
      </div>
      <div className={cx("country-overview")}>
        <RegionOverview
          data={data}
          renderStats={["confirmed_cases", "deaths"]}
          regionName={countryName}
          regionCode={countryCode}
          regionSlug={countrySlug}
          regionType={"country"}
          vaccineData={vaccineData}
        />
      </div>

      <div className={styles.sectionsContainer}>
        {/* DATA TIMELINE */}
        <ScrollTarget id={`data-timeline`} />
        <DataTimeline isGlobal={true} regionCode={countryCode} />

        <OverviewNotes
          key={`region-about-${countryName}`}
          regionName={countryName}
          regionCode={countryCode}
          regionType={"country"}
        />
      </div>

      <RegionMenu
        title="World Countries"
        limit={40}
        items={countryMappings.map((country) => ({
          name: country.displayName,
          to: `/region/${slugify(country.name)}`,
        }))}
      />
    </div>
  );
};

export default WorldCountryValidator;
