import React from "react";
import TableCell from "@material-ui/core/TableCell";
import Popover from "@material-ui/core/Popover";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    root: {
        pointerEvents: 'none'
    },
    content: {
        width: '180px',
        padding: '11px 5px',
        lineHeight: '18px',
        fontSize: '15px',
        color: '#707070',
        fontStyle: 'italic'
    },
}));

const NoData = (props) => {
    const classes = useStyles();
    const {infoText} = props;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (
        <TableCell className="table__cell no__data positivity" onMouseOver={handlePopoverOpen} onMouseOut={handlePopoverClose}>
            <img alt="a circle with an x in it" src={require('../PositivityComparison/StateRow/assets/cancel_icon.svg')}/>
            <img alt="a dark circle with an x in it" src={require('../PositivityComparison/StateRow/assets/cancel_icon-hover.svg')}/>
            <Popover
                className={classes.root}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                transformOrigin={{vertical: 'bottom', horizontal: 'center'}}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <div className={classes.content}>
                    {infoText}
                </div>
            </Popover>
        </TableCell>
    );
};
NoData.defaultProps = {
    infoText: 'State doesn’t report the values needed to calculate this positivity type'
};
export default NoData;
