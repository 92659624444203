import React from "react";
import styles from "./FooterList.module.scss";
import HFormat from "components/common/HFormat";

const FooterList = ({ title, classNames, children }) => {
    const className = [
        styles.base,
        classNames
    ].join(" ");
    return (
        <div className={className}>
            <HFormat size={3} noMarginTop noWrap allCaps>{title}</HFormat>
            <ul>
                {children}
            </ul>
        </div>);
};

export default FooterList;
