import {createSelector} from 'reselect';

const bubbleLayersSelector = state => state.equityAccessToTesting.bubbleLayers;

const selectedBubbleLayerSelector = createSelector([bubbleLayersSelector],
    bubbleLayers => {
        return bubbleLayers.find(d => d.isActive);
    });

export default selectedBubbleLayerSelector;
