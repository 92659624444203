import React from "react";
import classNames from "classnames/bind";

import Share from "components/common/Share";
import Meta from "components/common/Meta";
import RegionSearch from "../RegionSearch";

import styles from "./Regions.module.scss";
const cx = classNames.bind(styles);

const Regions = () => {
  return (
    <div className={cx("container")}>
      <Meta
        title="See the latest data in your region"
        description="Explore stats and trends specific to your country or U.S. state"
        path="/region"
        image="/images/region-meta-image.jpg"
      />
      <div className={cx("header")}>
        <div>
          <div className="small-heading">By Region</div>
          <h1>See the latest data in your region</h1>
        </div>
        <Share />
      </div>
      <RegionSearch />
    </div>
  );
};

export default Regions;
