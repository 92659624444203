import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {RADIUS} from '../../../../constants';
import {COLORS} from '../../../../../Shared/constants';

const useStyles = makeStyles(() => ({
    listItem: {
        fontSize: '14px',
        color: COLORS.blueDark,
        marginBottom: '10px',
        paddingLeft: '15px',
        '&::before': {
            content: "\"\\2022\"",
            color: props => props.color,
            fontWeight: "bold",
            display: "inline-block",
            width: RADIUS * 2,
            fontSize: "1.8em",
            lineHeight: '20px',
            left: "0",
            position: "absolute"
        }
    }
}));

const EventDescription = (props) => {
    const classes = useStyles(props);

    return (
        <li className={classes.listItem}>{props.description}</li>
    );
};
export default EventDescription;
