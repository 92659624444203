import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import MediaQuery from "react-responsive";

import * as GTM from "util/gtm";
import { getPage } from "data/util";
import data from "data/data-viz/hubei-cases";
import styles from "./HubeiCases.module.scss";
import EmbedFrame from "components/common/EmbedFrame";
import SideNarrative from "components/layout/SideNarrative";

const HubeiCases = ({ location }) => {
  const page = getPage(location.pathname);
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "hubei-timeline"
    });
  }, []);

  return (
    <SideNarrative
      narrative={
        <>
          <h4>{content_blocks["how-events-unfolded-at-the-outset"].title}</h4>
          <ReactMarkdown
            source={content_blocks["how-events-unfolded-at-the-outset"].content}
          />
        </>
      }
    >
      <div className={styles.container}>
        <MediaQuery maxWidth={767}>
          <EmbedFrame
            src="/embed/hubei-timeline/hubei_360.html"
            width={360}
            height={375}
          />
        </MediaQuery>
        <MediaQuery minWidth={768}>
          <EmbedFrame
            src="/embed/hubei-timeline/hubei_720.html"
            width={720}
            height={550}
          />
        </MediaQuery>

        <h4>Timeline Of Events</h4>

        <div className={styles.table}>
          {data.map((row, i) => (
            <TableRow key={i} {...row} />
          ))}
        </div>

        <ReactMarkdown
          className={styles.sources}
          source={content_blocks["hubei-timline-data-sources"].content}
        />
      </div>
    </SideNarrative>
  );
};

HubeiCases.propTypes = {
  location: PropTypes.object,
};

export default HubeiCases;

const TableRow = ({ Date, Event, Importance }) => {
  return (
    <div className={styles.row}>
      <span
        className={
          [null, styles.rowUnImportant, styles.rowImportant][Importance]
        }
      />
      <span className={styles.rowDate}>{formatDate(Date)}</span>
      <span className={styles.rowEvent}>{Event}</span>
    </div>
  );
};

const formatDate = (date) => {
  const theDate = new Date(date);
  return theDate.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    timeZone: "America/New_York",
  });
};
