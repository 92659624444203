import React, { useRef, useEffect } from "react";
import * as styles from "./BGFormat.module.scss";
import PropTypes from 'prop-types';

const BGFormat = ({imgSrc, fitType, classNames, forceHeight, children }) => {
    const containerRef = useRef(null);
    const img = new Image();
    img.src = imgSrc;

    useEffect(() => {
      // img.onload = function () {
      //   alert(`Image dimensions: ${this.width}x${this.height}`);
      // };
      if (!containerRef) {
        return;
      }
      containerRef.current.style.backgroundImage = `url(${imgSrc})`;
      if (forceHeight){
        containerRef.current.style.height = `${forceHeight}px`;
      };
    }, [imgSrc, img, forceHeight]);

    const fit = {
      contain: styles.contain,
      cover: styles.cover,
    };

    const className = [
      styles.base,
      fit[fitType],
      classNames,
    ].join(" ");

    return (
        <div className={className} ref={containerRef}>
          {children}
        </div>)
};

BGFormat.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  fitType: PropTypes.string.isRequired,
  forceHeight: PropTypes.number,
  classNames: PropTypes.string,
};

export default BGFormat;