import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import styles from "./AcceleratedTimeline.module.scss";
import timelines from "../../data/timelines.json";
import TimelineHeader from "../../components/TimelineHeader";
import CompareButton from "../../components/CompareButton";
import TypicalTimelineMap from "../TypicalTimeline/TypicalTimelineMap";
import AcceleratedTimelineDiagram from "./AcceleratedTimelineDiagram";
import Button from "components/common/Button";
import TourPoint from "../../components/TourPoint";

const AcceleratedTimeline = ({
  currentPhase,
  setCurrentPhase,
  setCurrentTimeline,
}) => {
  const tl = timelines[1];
  const compare = () => setCurrentTimeline(timelines[0].id);
  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    document.body.classList.add("freeze-scroll");
    return () => document.body.classList.remove("freeze-scroll");
  }, []);

  return (
    <div className={styles.base}>
      <div className={styles.container}>
        <div className={styles.aside}>
          <TypicalTimelineMap currentPhase={currentPhase} />
          <CompareButton
            light
            onClick={compare}
            direction="left"
            title="COMPARE"
            caption="to the Typical Timeline"
          />
        </div>
        <div className={styles.main}>
          <div className={styles.mainHeader}>
            <TimelineHeader light {...tl}>
              <div className={styles.tourCTA}>
                <TourPoint />
                <Button
                  className={styles.startButton}
                  onClick={() => setIsTourOpen(true)}
                  icon="right"
                >
                  Learn How
                </Button>
              </div>
            </TimelineHeader>
          </div>
          <AcceleratedTimelineDiagram
            currentPhase={currentPhase}
            setCurrentPhase={setCurrentPhase}
            isTourOpen={isTourOpen}
            setIsTourOpen={setIsTourOpen}
          />
        </div>
      </div>
    </div>
  );
};

AcceleratedTimeline.propTypes = {
  currentPhase: PropTypes.string,
  setCurrentPhase: PropTypes.func,
  setCurrentTimeline: PropTypes.func,
};

export default AcceleratedTimeline;
