import { useState, useEffect } from "react";
import { formatTableData } from "data/data-viz/vaccine-us-states/state-totals-bypop";

const useVaccineDataInternational = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);
    fetch("https://jhucoronavirus.azureedge.net/api/v3/vaccines/global.json")
      .then((raw) => raw.json())
      .then((data) => {
        if (mounted) {
          setData(formatTableData(data));
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    return () => {
      mounted = false;
    };
  }, []);

  return [data, isLoading];
};
export default useVaccineDataInternational;
