import React from "react";
import styles from "./TooltipIcon.module.scss";
import { ReactNodeLike } from "prop-types";
import { ReactComponent as IconPositiveSpecimensTotalSpecimens } from "./icons/positive_specimens_total_specimens.svg";
import { ReactComponent as IconPositiveSpecimensTotalEncounters } from "./icons/positive_specimens_total_encounters.svg";
import { ReactComponent as IconPositivePeopleTotalPeople } from "./icons/positive_people_total_people.svg";
import { ReactComponent as IconPositivePeopleTotalEncounters } from "./icons/positive_people_total_encounters.svg";
import { ReactComponent as IconPositivePeopleTotalSpecimens } from "./icons/positive_people_total_specimens.svg";
import cx from "classnames";

export type TTooltipIconProps = {
  type?:
    | "positive-specimens-total-specimens"
    | "positive-specimens-total-encounters"
    | "positive-people-total-people"
    | "positive-people-total-encounters"
    | "positive-people-total-specimens";

  className?: string;
  children?: ReactNodeLike;
};

const TooltipIcon = ({
  type = "positive-specimens-total-specimens",
  className,
  children,
}: TTooltipIconProps) => {
  return (
    <div className={cx(styles.base, className)}>
      {children && children}
      {type === "positive-specimens-total-specimens" && (
        <IconPositiveSpecimensTotalSpecimens className={styles.icon} />
      )}
      {type === "positive-specimens-total-encounters" && (
        <IconPositiveSpecimensTotalEncounters className={styles.icon} />
      )}
      {type === "positive-people-total-people" && (
        <IconPositivePeopleTotalPeople className={styles.icon} />
      )}
      {type === "positive-people-total-encounters" && (
        <IconPositivePeopleTotalEncounters className={styles.icon} />
      )}
      {type === "positive-people-total-specimens" && (
        <IconPositivePeopleTotalSpecimens className={styles.icon} />
      )}
    </div>
  );
};

export default TooltipIcon;
