import React, { useState } from "react";
import PropTypes from "prop-types";
import { getFilteredContent } from "data/util";
import {
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
} from "components/layout/ArticleGrid";
import { mapContentsToCards } from "components/layout/ArticleCard/utils";
import classNames from "classnames/bind";
import styles from "./VideoFeed.module.scss";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

const VideoFeed = ({
  currentVideoId = null,
  hasMajor = true,
  limit,
  topSplit,
  blade,
  eventId,
  page,
  hideNoContentsMessage = false,
  classNames,
  pageSize,
}) => {
  const screenWidth = window.innerWidth;
  limit = limit
    ? limit
    : screenWidth < 970
    ? 2
    : hasMajor || topSplit
    ? 4
    : screenWidth < 1440
    ? 6
    : 8;

  // Pagination State:
  const [currentPage, setCurrentPage] = useState(page);
  const incrementPage = () => setCurrentPage(currentPage + 1);
  const contents = mapContentsToCards(
    getFilteredContent({ types: ["webcasts"] })
      .filter(
        (content) =>
          content.slug !== currentVideoId &&
          content.url !== window.location.pathname &&
          (!eventId || content.event === eventId)
      )
      .map((content) => {
        return {
          ...content,
          url: content.slug
            ? !!eventId
              ? `/live/events/${eventId}/${content.slug}`
              : content.slug === content.event
              ? `/live/events/${content.event}`
              : `/live/${content.slug}`
            : content.url,
        };
      })
  );

  const topCard = contents.slice(0, topSplit ? 2 : 1);
  const otherCards = contents.slice(hasMajor ? (topSplit ? 2 : 1) : 0);
  const visibleContents = otherCards.slice(0, limit * currentPage);
  const isMore = otherCards.length - visibleContents.length > 0;
  return (
    <div className={cx("base", classNames)}>
      <div className={cx("content")}>
        {!contents.length && !hideNoContentsMessage && (
          <p>There is nothing to display.</p>
        )}
        {!!hasMajor && (
          <ArticleGridLayoutEngine
            articles={topCard}
            columnLayout={topSplit && contents.length > 1 ? [[6, 6]] : [[12]]}
            gap={32}
          >
            {new Array(topCard.length).fill(topCard).map((video, i) => {
              return (
                <ArticleGridLayoutTemplate.SingleCard
                  key={i}
                  href="not a real URL"
                  articleCardProps={{
                    containImage: true,
                    major: true,
                    vertical: true,
                  }}
                />
              );
            })}
          </ArticleGridLayoutEngine>
        )}
        <ArticleGridLayoutEngine
          articles={visibleContents}
          columnLayout="flexible"
          gap={32}
        >
          {new Array(visibleContents.length)
            .fill(visibleContents)
            .map((video, i) => {
              return (
                <ArticleGridLayoutTemplate.SingleCard
                  key={i}
                  containImage={true}
                  href="not a real URL"
                  articleCardProps={{
                    major: false,
                    vertical: true,
                    body: null,
                  }}
                />
              );
            })}
        </ArticleGridLayoutEngine>
        {!blade && isMore && (
          <div className={cx("grid-footer")}>
            <Button
              icon={"down"}
              shape="square"
              buttonStyle="bordered"
              onClick={incrementPage}
            >
              Load More
            </Button>
          </div>
        )}
        {blade && (
          <Button
            buttonStyle="bordered"
            icon="right"
            href={topCard[0].href}
            style={{ paddingTop: "2px", marginTop: "1rem" }}
          >
            View all
          </Button>
        )}
      </div>
    </div>
  );
};

VideoFeed.propTypes = {
  currentVideoId: PropTypes.string,
  feature: PropTypes.bool,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  classNames: PropTypes.string,
};

VideoFeed.defaultProps = {
  feature: false,
  page: 1,
  pageSize: 12,
};

export default VideoFeed;
