import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as GTM from "util/gtm";
import { getPage } from "data/util";
import styles from "./USStateDataAvailability.module.scss";
import Page from "components/layout/Page";
import ReactMarkdown from "react-markdown";
import DemographicUpdate from "../../DemographicUpdate";
import { demographicTableData } from "../../DemographicUpdate/util";
import TFormat from "components/common/TFormat";
import stateList from "data/data-viz/vaccine-us-states/state-list.json";
import stateNoPageList from "data/data-viz/vaccine-us-states/state-no-page-list.json";
import { findStateCode } from "data/geo";
import { ReactComponent as CheckMarkIcon } from "images/icons/checkmark.svg";
import { ReactComponent as CrossIcon } from "images/icons/crossmark.svg";
import { formatLastUpdatedDate } from "./util";

const excludedUSTerritories = {
  "american-samoa": "American Samoa",
  "federated-states-of-micronesia": "Federated States of Micronesia",
  "marshall-islands": "Marshall Islands",
  "republic-of-palau": "Republic of Palau",
};

const USStateDataAvailability = ({ location }) => {
  const page = getPage(location.pathname);
  const pageReview = getPage("/data/racial-data-transparency-review");
  const { content_blocks } = page;

  const [selectedFilters, setSelectedFilters] = useState({
    tab: "age",
    bin: { value: "all-content", label: "All" },
  });

  const handleOnSelectChange = (tab, bin) => setSelectedFilters({ tab, bin });

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "us-state-data-availability",
    });
  }, []);

  const [allData, setAllData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [dataSetKeys] = useState(["cases", "deaths", "tests", "vaccines"]);
  const [lastUpdated, setLastUpdated] = useState();

  useEffect(() => {
    let mounted = true;

    const setupData = async () => {
      if (mounted) {
        // const fetchURLs = demographicOptions.map((option) => option.url);
        const fetchURLs = demographicTableData.map((url) => url);

        // Filter out territories
        const territoryKeys = Object.keys(excludedUSTerritories);
        const states = Object.keys(stateList).filter(
          (region) => !territoryKeys.includes(region)
        );

        let lastUpdated;

        // Fetch & map data
        let results = await Promise.all(
          fetchURLs.map((url) => {
            return fetch(url)
              .then((response) => response.json())
              .then((response) => response);
          })
        ).then((response) => {
          // Map state data
          const stateData = states.map((state) => {
            const stateCode = findStateCode(state);
            if (!stateCode) return {};

            const results = response.map((res) => {
              const v = {
                [res.demographic]: Object.assign(
                  {},
                  ...res.dataset.map((data) => {
                    const binData = {};
                    dataSetKeys.forEach((k) => {
                      const d = data[k]?.find(
                        (stateEntry) => stateEntry.state === stateCode
                      )?.value;
                      if (typeof d === "number") {
                        binData[k] = d;
                      }
                    });
                    if (Object.keys(binData).length) {
                      return Object.assign(
                        {},
                        {
                          [data.bin]: binData,
                        }
                      );
                    }
                    return null;
                  })
                ),
              };
              return { ...v };
            });

            return {
              regionName: stateList[state],
              ...Object.assign({}, ...results),
            };
          });

          if (!lastUpdated) {
            lastUpdated = formatLastUpdatedDate(response[0]?.vax_test_date);
          }
          return stateData;
        });

        setLastUpdated(lastUpdated);
        setAllData(results);
        setLoading(false);
      }
    };

    if (!allData?.length) {
      setupData();
    }

    mounted = false;
  }, [allData, selectedFilters, dataSetKeys]);

  useEffect(() => {
    if (allData?.length) {
      const { tab, bin } = selectedFilters;

      if (tab || bin) {
        const filteredData = allData.map((region) => {
          const tabData = region[tab.toLowerCase()];
          const dt = dataSetKeys.map((key) => {
            const d = { [key]: "0" };
            if (bin?.value === "all-content") {
              const keyData = Object.values(tabData)?.find(
                (v) => typeof v[key] === "number"
              )?.[key];
              d[key] = typeof keyData === "number" ? "Y" : "N";
            } else {
              const val = tabData?.[bin.value]?.[key];
              d[key] =
                typeof val === "number" ? (val * 100).toFixed(2) + "%" : "-";
            }
            return d;
          });

          const d = Object.assign(
            {
              regionName: region.regionName,
            },
            ...dt
          );
          return d;
        });
        setTableData(filteredData);
      }
    }
  }, [allData, selectedFilters, dataSetKeys]);

  const tableColumns = {
    regionName: "State",
    cases: "Cases",
    deaths: "Deaths",
    tests: "Tests",
    vaccines: "Vaccines",
  };

  return (
    <Page id={"us-state-data-availability"} page={pageReview}>
      <div className={styles.base}>
        <div className={styles.container}>
          <div className={styles.topContent}>
            <ReactMarkdown
              source={content_blocks["state-covid-19-data-by-race"].content}
            />
            {lastUpdated && (
              <p>
                <strong>This page was last updated on {lastUpdated}.</strong>
              </p>
            )}
          </div>
          <DemographicUpdate onChange={handleOnSelectChange} />
          {!isLoading && (
            <TFormat
              tableData={tableData}
              tableColumns={tableColumns}
              regionList={stateList}
              regionNoLinkList={stateNoPageList}
              regionNameLinkStyle={"button"}
              path="/region/us/"
              rowLimit={25}
              hideViewAll={false}
              hideButtonProps={{ className: styles.hideButton }}
              mobileOptions={{
                useRegionCodeType: "state",
                useNameShortening: true,
              }}
              tdRenderCallback={({ data, key }) => {
                if (data === "Y" || data === "N") {
                  return data === "Y" ? (
                    <CheckMarkIcon className={styles.checkIcon} />
                  ) : (
                    <CrossIcon className={styles.crossIcon} />
                  );
                } else {
                  return data ? data : "-";
                }
              }}
            />
          )}
        </div>
      </div>
    </Page>
  );
};

USStateDataAvailability.propTypes = {
  location: PropTypes.object,
};

export default USStateDataAvailability;
