import React from "react";
import styles from "./THead.module.scss";
import uuid from "uuid/v4";

type TTHeadProps = {
  tableColumns: any;
  tFirstColumnRef: any;
  requestSort: (key: string) => void;
  getClassNamesFor: (name: string) => string;
  mobileColumn?: boolean;
  useRegionCodes?: boolean;
  hideFirstCol?: boolean;
  className?: string;
};

const THead = ({
  tableColumns,
  tFirstColumnRef,
  mobileColumn,
  useRegionCodes,
  hideFirstCol,
  requestSort,
  getClassNamesFor,
}: TTHeadProps) => {
  return (
    <>
      {(mobileColumn
        ? Object.keys(tableColumns).slice(0, 1)
        : Object.keys(tableColumns)
      ).map((dataKey, index) => {
        const lineBreaks = tableColumns[dataKey]?.split("\n")?.map((line) => (
          <React.Fragment key={`${index}_${uuid()}`}>
            {line}
            <br />
          </React.Fragment>
        ));
        const col = lineBreaks.length > 1 ? lineBreaks : tableColumns[dataKey];
        const firstRef =
          index === 0
            ? {
                ref: tFirstColumnRef,
                className:
                  mobileColumn && useRegionCodes ? styles.firstCol : "",
              }
            : {};

        return (
          <th
            key={dataKey}
            onClick={() => requestSort(`${dataKey}`)}
            {...firstRef}
          >
            {(!hideFirstCol || index !== 0) && (
              <div className={getClassNamesFor(dataKey)}>
                <div>{hideFirstCol && index === 0 ? "" : col}</div>
              </div>
            )}
          </th>
        );
      })}
    </>
  );
};

export default THead;
