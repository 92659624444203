import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import ArticleCard from 'components/layout/ArticleCard';

import classNames from 'classnames/bind';
import styles from './ArticleCard.module.scss';
const cx = classNames.bind(styles);

export default function ArticleCardStyleGuide() {
  const [showNewBadge, setShowNewBadge] = useState(false);
  const [hasVideo, setHasVideo] = useState(false);
  const [containImage, setContainImage] = useState(false);

  const basicArticleContent = {
    category: (
      <>
        Understanding <span style={{ whiteSpace: 'nowrap' }}>COVID-19</span>
      </>
    ),
    title: 'Insights from Johns Hopkins University Experts',
    body: (
      <p>
        Johns Hopkins University is hosting a free, publicly available course
        titled Understanding the COVID-19 Pandemic: Insights from Johns Hopkins
        University Experts. This course is set up as a series of short modules
        to explore the COVID-19 pandemic. Registration is not required.
      </p>
    ),
    href: '/',
    imageSrc: '/images/people-visual.png',
    new: showNewBadge,
    containImage,
    hasVideo,
  };
  const shorterArticleContent = {
    ...basicArticleContent,
    body: (
      <p>
        A report from the Center for Health Security on critical research needed
        to fill in the blanks of our knowledge as schools in the United States.
      </p>
    ),
    href: 'https://google.com/',
  };

  return (
    <div className={cx('container')}>
      <h1 className={cx('styleguide-annotation')}>ArticleCard component</h1>
      <p className={cx('styleguide-annotation')}>
        The ArticleCard component is used to display modular content and is
        often arranged in <Link to="/style-guide/article-grid">grids</Link>.
      </p>
      <p className={cx('styleguide-annotation')}>
        Every card has two primary components, the text component and the image
        component. The text component is defined by a <code>category</code>{' '}
        (optional), <code>title</code> (required) and <code>body</code>{' '}
        (required).
      </p>
      {/* Checkbox options to apply to all examples */}
      <p className={cx('styleguide-annotation')}>
        <label>
          <input
            type="checkbox"
            value={showNewBadge}
            onChange={(e) => setShowNewBadge(e.target.checked)}
          />
          &nbsp;&nbsp;
          <span style={{ fontWeight: 600 }}>
            Display “NEW” badge on ArticleCards
          </span>
        </label>
        <br/>
        <label>
          <input
            type="checkbox"
            value={hasVideo}
            onChange={(e) => setHasVideo(e.target.checked)}
          />
          &nbsp;&nbsp;
          <span style={{ fontWeight: 600 }}>
            Apply “hasVideo” style to ArticleCards
          </span>
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            value={containImage}
            onChange={(e) => setContainImage(e.target.checked)}
          />
          &nbsp;&nbsp;
          <span style={{ fontWeight: 600 }}>
            Apply “contain” style to images
          </span>
        </label>
      </p>

      {/* Major */}

      <h2 className={cx('styleguide-annotation')}>Major ArticleCards</h2>
      <p
        className={cx('styleguide-annotation')}
        style={{ marginBottom: '2rem' }}
      >
        The vertical/horizontal orientation of <code>major</code> ArticleCards
        is determined by their size. They always require an{' '}
        <code>imageSrc</code>. When major ArticleCards are displayed
        horizontally, they get a subtle background color.
      </p>

      <h3 className={cx('styleguide-annotation')}>Full width</h3>
      <ArticleCard major {...basicArticleContent} />

      <div className={cx('major-row-2')}>
        <div>
          <h3 className={cx('styleguide-annotation')}>2&frasl;3 width</h3>
          <ArticleCard major {...basicArticleContent} />
        </div>
        <div>
          <h3 className={cx('styleguide-annotation')}>1&frasl;3 width</h3>
          <ArticleCard major {...shorterArticleContent} />
        </div>
      </div>

      {/* Minor */}

      <h2 className={cx('styleguide-annotation')}>Minor ArticleCards</h2>
      <p
        className={cx('styleguide-annotation')}
        style={{ marginBottom: '2rem' }}
      >
        The vertical/horizontal orientation of <code>minor</code> ArticleCards
        is configurable by a prop. An <code>imageSrc</code> is optional. Minor
        ArticleCards are never full width.
      </p>

      <div className={cx('minor-row-1')}>
        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;2 width; horizontal
          </h3>
          <ArticleCard {...basicArticleContent} />
        </div>

        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;2 width; vertical
          </h3>
          <ArticleCard vertical {...shorterArticleContent} />
        </div>
      </div>

      <hr style={{ margin: '44px 0 0' }} />

      <div className={cx('minor-row-2')}>
        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;4 width; horizontal
          </h3>
          <ArticleCard {...shorterArticleContent} />
        </div>

        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;4 width; vertical
          </h3>
          <ArticleCard vertical {...shorterArticleContent} />
        </div>

        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;4 width; vertical; no image
          </h3>
          <ArticleCard vertical {...basicArticleContent} imageSrc="" />
        </div>

        <div>
          <h3 className={cx('styleguide-annotation')}>
            1&frasl;4 width; horizontal; no image
          </h3>
          <ArticleCard {...basicArticleContent} imageSrc="" />
        </div>
      </div>
    </div>
  );
}
