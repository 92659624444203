import { useState, useMemo } from "react";

const emptyPlaceholder = "-";

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const isNumericDate = (value) => {
    if (value === emptyPlaceholder) return false;
    if (value === undefined) return false;
    if (typeof value === "string" && value?.includes("/")) {
      //simple check for date
      return false;
    }
    return !isNaN(parseFloat(value));
  };
  const extractNumericValue = (str) => {
    if (typeof str === "number") return str;
    return parseFloat(str.split(",").join(""));
  };

  const sortedItems = useMemo(() => {
    if (!items) return [];
    let sortableItems = [...items];

    if (sortConfig !== null && Object.keys(sortConfig)?.length > 0) {
      sortableItems.sort((a, b) => {
        let valueA = a[sortConfig.key];
        let valueB = b[sortConfig.key];

        valueA = isNumericDate(valueA)
          ? extractNumericValue(valueA)
          : valueA === emptyPlaceholder
          ? -1
          : valueA;
        valueB = isNumericDate(valueB)
          ? extractNumericValue(valueB)
          : valueB === emptyPlaceholder
          ? -1
          : valueB;

        //Check for date string
        valueA =
          typeof valueA === "string" && valueA?.includes("/")
            ? new Date(valueA)
            : valueA;
        valueB =
          typeof valueB === "string" && valueB?.includes("/")
            ? new Date(valueB)
            : valueB;

        if (valueA < valueB) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (valueA > valueB) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 1;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let updateConfig = {
      key,
      direction: "descending",
    };
    if (sortConfig && sortConfig.key === key) {
      if (sortConfig.direction === "ascending") {
        updateConfig = null;
      }
      if (sortConfig.direction === "descending") {
        updateConfig.direction = "ascending";
      }
    }
    setSortConfig(updateConfig);
  };

  return {
    items: sortedItems,
    requestSort,
    sortConfig,
  };
};

export default useSortableData;
