import React from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';

const DownloadLink = ({href}) => {
    return (
        <a href={href} className="download-link" download>
            <GetAppIcon fontSize="default" className="download-link__icon"/>
        </a>
    );
};
export default DownloadLink;
