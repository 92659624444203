import React from "react";
import PropTypes from "prop-types";
import * as styles from "./ScrollTarget.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

export const SCROLL_TARGET_ID_HEAD = "scroll-target#";
export const SCROLL_TARGET_CLASSNAME = "page-nav-scroll-target";

export const getScrollTargetValue = (id) =>
  id?.split(SCROLL_TARGET_ID_HEAD)?.slice(1).join("");

const ScrollTarget = ({ id, path, children }) => (
  <div
    id={`${SCROLL_TARGET_ID_HEAD}${id}`}
    className={cx(`${SCROLL_TARGET_CLASSNAME}`, styles.base)}
  >
    <a className="anchor" href={`${path ? path : ""}#${id}`} id={id}>
      <div />
    </a>
  </div>
);

ScrollTarget.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ScrollTarget;
