import React from "react";
import styles from "./RegionPage.module.scss";

export const shouldRenderAsEmbed = () =>
  !!window &&
  window.location?.search &&
  window.location.search.split("?").includes("embed");

export const AvailablilityStatus = ({ isAvailable }) => (
  <span className={isAvailable ? styles.available : styles.unavailable}>
    {isAvailable ? "Available" : "Unavailable"}
  </span>
);
