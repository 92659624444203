import {
  ALL_REGIONS_VISIBLE,
  DOUBLE_DECIMAL_FORMATTER,
  IS_SELECTED,
  MAP_NEW_CASES,
  MAP_NEW_TESTS,
  MAP_VIEW,
  MAP_WEEKLY_CHANGE_IN_NEW_CASES,
  MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE,
  MAP_WEEKLY_CHANGE_IN_TESTING,
  NEGATIVE_TREND,
  NEUTRAL_TREND,
  PERCENT_FORMATTER,
  PERCENT_POSITIVE,
  POSITIVE_TREND,
  POSITIVITY_COMPARISON_VIEW,
  SCALE_TYPES,
  SINGLE_DECIMAL_FORMATTER,
  TABLE_VIEW,
  THIS_WEEK,
} from '../../constants';
import kebabCase from 'lodash.kebabcase';
import { scaleOrdinal, scaleQuantile, scaleThreshold } from 'd3-scale';
import {getPercentPositiveCurrentValue} from "../../components/App/Table/utils";

export const initialState = {
  data: null,
  metadata: null,
  isLoading: true,
  showError: false,
  tooltip: null,
  domain: [],
  datasets: [
    {
      name: 'New cases per 100k people',
      label: 'New confirmed cases per 100k people',
      accessor: d => d.currentValues[MAP_NEW_CASES],
      colorScale: scaleQuantile().range(['#ffffe0', '#ffe0c4', '#fdc1a5', '#f7a289', '#ee8371', '#e16360', '#cf4260']),
      formatter: DOUBLE_DECIMAL_FORMATTER,
      scaleType: SCALE_TYPES.QUANTILE,
      preCalculatedDomain: false,
    },
    {
      name: 'Weekly change in new cases',
      label: 'Have new cases declined from previous week?',
      accessor: d => d.weeklyTrends[MAP_WEEKLY_CHANGE_IN_NEW_CASES][THIS_WEEK],
      colorScale: scaleOrdinal()
        .range(['#E39260', '#60C6C6', '#DED7D7'])
        .domain([POSITIVE_TREND, NEGATIVE_TREND, NEUTRAL_TREND]),
      colorMap: {
        [POSITIVE_TREND]: 'Increasing',
        [NEGATIVE_TREND]: 'Decreasing',
        [NEUTRAL_TREND]: 'No change',
      },
      scaleType: SCALE_TYPES.ORDINAL,
      preCalculatedDomain: false,
    },
    {
      name: 'Percent positive',
      label: 'Percent of new tests that are positive',
      accessor: d => {
        const selectedApproach = d.values[PERCENT_POSITIVE]
            .find(d => d.selected);
        return selectedApproach ? getPercentPositiveCurrentValue(selectedApproach) : null
      },
      colorScale: scaleThreshold()
        .range(['#60C6C6', '#ffffe0', '#ffe0c4', '#fdc1a5', '#f7a289', '#ee8371', '#e16360'])
        .domain([0.05, 0.1, 0.15, 0.2, 0.25, 0.3]),
      formatter: PERCENT_FORMATTER,
      scaleType: SCALE_TYPES.THRESHOLD,
      preCalculatedDomain: true,
      hasNoDataOption: true
    },
    {
      name: 'Weekly change percent positive',
      label: 'Weekly change percent positive',
      accessor: d => d.weeklyTrends[MAP_WEEKLY_CHANGE_IN_PERCENT_POSITIVE][THIS_WEEK],
      colorScale: scaleOrdinal()
        .range(['#E39260', '#60C6C6', '#DED7D7'])
        .domain([POSITIVE_TREND, NEGATIVE_TREND, NEUTRAL_TREND]),
      colorMap: {
        [POSITIVE_TREND]: 'Increasing',
        [NEGATIVE_TREND]: 'Decreasing',
        [NEUTRAL_TREND]: 'No change',
      },
      scaleType: SCALE_TYPES.ORDINAL,
      preCalculatedDomain: false,
    },
    {
      name: 'New tests per 100k people',
      label: 'New tests per 100k people',
      accessor: d => d.currentValues[MAP_NEW_TESTS],
      colorScale: scaleQuantile().range(['#ffffe0', '#ecf6da', '#d8edd4', '#c4e3ce', '#b0dac8', '#9bd1c2', '#60C6C6']),
      formatter: SINGLE_DECIMAL_FORMATTER,
      scaleType: SCALE_TYPES.QUANTILE,
      preCalculatedDomain: false,
    },
    {
      name: 'Weekly change in testing',
      label: 'Have new tests declined from previous week?',
      accessor: d => d.weeklyTrends[MAP_WEEKLY_CHANGE_IN_TESTING][THIS_WEEK],
      colorScale: scaleOrdinal()
        .range(['#60C6C6', '#E39260', '#DED7D7'])
        .domain([POSITIVE_TREND, NEGATIVE_TREND, NEUTRAL_TREND]),
      colorMap: {
        [POSITIVE_TREND]: 'Increasing',
        [NEGATIVE_TREND]: 'Decreasing',
        [NEUTRAL_TREND]: 'No change',
      },
      scaleType: SCALE_TYPES.ORDINAL,
      preCalculatedDomain: false,
    },
  ].map((d, i) => ({
    ...d,
    [IS_SELECTED]: !i,
    id: kebabCase(d.name),
  })),
  visualizations: [
    {
      id: MAP_VIEW,
      name: 'Map view',
      [IS_SELECTED]: false,
    },
    {
      id: TABLE_VIEW,
      name: 'Table view',
      [IS_SELECTED]: true,
    },
    {
      id: POSITIVITY_COMPARISON_VIEW,
      name: 'Positivity comparison',
      [IS_SELECTED]: false,
    },
  ],
  regions: [
    {
      id: ALL_REGIONS_VISIBLE,
      name: 'Show All',
      [IS_SELECTED]: true,
    },
  ],
  grid: [],
  tableTooltip: null,
};
