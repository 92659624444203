import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {COLORS} from "../../../Shared/constants";
import {useSelector} from "react-redux";
import selectedDemographicLayerSelector from "../../redux/selectors/demographic.layer.selector";
import selectedBubbleLayerSelector from "../../redux/selectors/bubble.layer.selector";
import {sum} from "d3-array";
import selectedCumulativeLayerSelector from "../../redux/selectors/cumulative.layer.selector";

const useStyles = makeStyles(() => ({
    tooltip: {
        textAlign: "left",
        zIndex: "1000",
        position: "absolute",
        display: "block",
        pointerEvents: 'none'
    },
    tooltipContainer: {
        position: "absolute",
        padding: "8px 10px",
        top: "150px",
        minWidth: "350px",
        lineHeight: "18px",
        background: "#fff",
        color: "darkgray",
        boxShadow: "0 4px 8px 0 rgba(61, 65, 72, 0.15)",
        MozBoxShadow: "0 4px 8px 0 rgba(61, 65, 72, 0.15)",
        border: "solid 1px #ddd",
        textAlign: "left",
        fontSize: 16,
    },
    tooltipLabel: {
        color: COLORS.blueDark,
        fontWeight: 'bold',
        marginBottom: 5,
        '&:last-child': {
            marginBottom: 0
        },
        '&.normal': {
            fontWeight: 'normal'
        }
    },
    source: {
        color: COLORS.blueDark,
        fontWeight: 'normal',
        fontStyle: 'italic',
        borderTop: `1px solid rgba(0, 0, 0, 0.16)`,
        marginTop: 25,
        paddingTop: 8
    }
}));

const Tooltip = ({activeCountyMapView}) => {
    const classes = useStyles();

    let selectedDemographicLayer = useSelector(selectedDemographicLayerSelector);
    let selectedBubbleLayer = useSelector(selectedBubbleLayerSelector);
    let selectedCumulativeLayer = useSelector(selectedCumulativeLayerSelector);
    let activeMarker = useSelector(state => state.equityAccessToTesting.activeMarker);
    const isCumulativeDataView = activeCountyMapView.id === 'cumulative-data';

    if (!activeMarker) return null;

    // Get the top positions of the global selector for the tool
    let globalYOffset = 0;
    const globalRootRect = document.getElementById('root');
    if (globalRootRect) {
        globalYOffset = globalRootRect.getBoundingClientRect().top + 175;
    }

    const noDataAvailable = !selectedDemographicLayer.hasOwnProperty('isEmpty');

    let {top, left} = activeMarker;

    const bubbleLayerLabel = `${selectedBubbleLayer.format(activeMarker[selectedBubbleLayer.name])} ${selectedBubbleLayer.tooltipLabel}`;
    const demographicLayerLabel = `${selectedDemographicLayer.format(activeMarker[selectedDemographicLayer.name])} ${selectedDemographicLayer.tooltipLabel}`;
    const cumulativeLayerLabel = `${selectedCumulativeLayer.format(activeMarker[selectedCumulativeLayer.name])} ${selectedCumulativeLayer.tooltipLabel}`;

    const bubbleDomainSum = isCumulativeDataView ? 0 : sum(selectedBubbleLayer.bubbleScale.domain(), d => +d);

    return (
        <div className={classes.tooltip} style={{top: top - globalYOffset, left}}>
            <div className={classes.tooltipContainer}>
                <div className={classes.tooltipLabel}>
                    {activeMarker.Name}
                </div>
                {!!bubbleDomainSum && <div className={`${classes.tooltipLabel} normal`}>
                    {bubbleLayerLabel}
                </div>}
                {noDataAvailable && <div className={`${classes.tooltipLabel} normal`}>
                    {demographicLayerLabel}
                </div>}
                {isCumulativeDataView && <div className={`${classes.tooltipLabel} normal`}>
                    {cumulativeLayerLabel}
                </div>}
                {isCumulativeDataView && <div className={`${classes.source} normal`}>
                    Click to see county details infographic
                </div>}
            </div>
        </div>
    );
};

export default Tooltip;
