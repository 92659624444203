import {useState, useCallback, useLayoutEffect} from 'react';
import debounce from 'lodash.debounce';

function getDimensionObject(node) {
    const rect = node.getBoundingClientRect();

    return {
        width: rect.width,
        height: rect.height,
        top: 'x' in rect ? rect.x : rect.top,
        left: 'y' in rect ? rect.y : rect.left,
        x: 'x' in rect ? rect.x : rect.left,
        y: 'y' in rect ? rect.y : rect.top,
        right: rect.right,
        bottom: rect.bottom,
    };
}

export const useDimensions = initialDimensions => {
    const [dimensions, setDimensions] = useState(initialDimensions);
    const [node, setNode] = useState(null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = debounce(
                () => window.requestAnimationFrame(() => setDimensions({...getDimensionObject(node), isResized: true})),
                250,
            );
            measure();

            window.addEventListener('resize', measure);

            return () => {
                window.removeEventListener('resize', measure);
            };
        }
    }, [node]);

    return [ref, dimensions, node];
};

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "14px verdana").
 *
 * @see http://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
export function getTextWidth(text, font) {
    // if given, use cached canvas for better performance
    // else, create new canvas
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}
