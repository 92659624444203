import React from "react";
import classNames from "classnames";

import styles from "./ReportsBlade.module.scss";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import { getArticleCardsBySection } from "components/layout/ArticleCard/utils";

const cx = classNames.bind(styles);

const ReportsBlade = ({ dark = true }) => {
  const articleCardProps = {
    category: "REPORT",
    imageSrc: "/images/virus-placeholders/covid-1.jpg",
    containImage: false,
    vertical: false
  };
  const latestReports = getArticleCardsBySection(
    "Vaccine Reports",
    { category: "COVID-19 Vaccine Matters" },
    4
  ).map((report) => {
    return {
      ...report,
      ...articleCardProps,
    };
  });
  return (
    <div className={cx("base", styles.heightFix)}>
      <ArticleGridLayouts.Flexible
        articles={latestReports.slice(0,2)}
        articleCardProps={articleCardProps}
      />
      <ArticleGridLayouts.Flexible
        articles={latestReports.slice(2)}
        articleCardProps={articleCardProps}
      />
    </div>
  );
};

export default ReportsBlade;
