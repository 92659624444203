import { mapContentsToCards } from 'components/layout/ArticleCard/utils';
import HomePageCarousel from 'components/layout/shell/Carousel/HomePageCarousel';
import { getFilteredContent } from 'data/util';
import React from 'react';
import styles from './Carousel.module.scss';

export default function CarouselStyleGuide() {

  const contents = mapContentsToCards(
    getFilteredContent({ types: ["webcasts"] })).slice(0, 4)

  return (
    <div className={styles.container}>
      <h1 className={styles['styleguide-annotation']}>
        Carousel
      </h1>

      <HomePageCarousel slides={contents} />

      <br />
      <br />
    </div>
  );
};
