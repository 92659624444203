import React, { FC } from "react";
import styles from "./Option.module.scss";
import cx from "classnames";
import { TFilterOption } from "../../util";

export type OptionProps = {
  option: TFilterOption;
  onClick: (option: TFilterOption) => void;
  isActive?: boolean;
  children?: any;
  context?: "tabbar" | "filterbar";
  className?: string;
};

const Option: FC<OptionProps> = ({
  option,
  isActive,
  onClick,
  context,
  className,
}) => {
  const handleOnClick = () => onClick && onClick(option);
  return (
    <div
      className={cx(
        styles.base,
        isActive && styles.active,
        styles[context],
        className
      )}
      onClick={handleOnClick}
    >
      {option?.label}
    </div>
  );
};

export default Option;
