import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => {
  const width = window.innerWidth;
  let margin;
  if (width < 460){
    margin = 5;
  }else {
    margin = 37.8;
  }

  return {
    rect: {
      width: "16px",
      height: "16px",
      borderRadius: "3px",
    },
    increasing: {
      marginRight: `${margin}px`,
    },
    decreasing: {
      marginRight: `${margin}px`,
    },
  };});

const DiscreteLegend = ({ selectedDataSet }) => {
  const classes = useStyles();
  const { colorScale } = selectedDataSet;
  return (
    <div className="map__legend-wrapper">
      <div className="map__legend">
        <div className="map__legend-items">
          <div className={classes.rect} style={{ backgroundColor: colorScale.range()[0] }} />
          <span className={clsx('map__legend-label', `map__legend-label-increasing`)}>INCREASING</span>
          <div className={classes.rect} style={{ backgroundColor: colorScale.range()[1] }} />
          <span className={clsx('map__legend-label', `map__legend-label-decreasing`)}>DECREASING</span>
          <div className={classes.rect} style={{ backgroundColor: colorScale.range()[2] }} />
          <span className="map__legend-label">NO CHANGE</span>
        </div>
      </div>
    </div>
  );
};

export default DiscreteLegend;
