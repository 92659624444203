import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import styles from "./RegionHospitalization.module.scss";
import useSmoothHashScroll from "util/hooks/useSmoothHashScroll";
import { findStateCode } from "data/geo";
import BedsChart from "./BedsChart";

// TODO: Maybe move this to Redux
let isLoading = false;
let loadedStateData = null;

const RegionHospitalization = ({ regionName }) => {
  const stateCode = findStateCode(regionName);

  const anchorName = "hospitalization";
  useSmoothHashScroll(anchorName);

  const [data, setData] = useState(null);

  useLayoutEffect(() => {
    let mounted = true;

    const getData = () =>
      loadedStateData.find(item =>
        item.state === stateCode)?.data;

    if (loadedStateData) {
      setData(getData());
    } else if (!isLoading) {
      isLoading = true;
      fetch('https://jhucoronavirus.azureedge.net/jhucoronavirus/hospitalization_by_state.json')
        .then((raw) => raw.json())
        .then((data) => {
          loadedStateData = data;
          isLoading = false;
          if (mounted) {
            setData(getData);
          }
        })
        .catch((err) => {
          console.error(err);
          isLoading = false;
        });
    }

    return () => {
      mounted = false;
    };
  }, [stateCode]);

  // TODO: Replace with more a robust Loading component
  if (isLoading) {
    return <div />
  }

  return (
    <div className={styles.base} id={anchorName}>
      <div>
        <div className={styles.title}>
          <div className={styles.titleIcon}>
            <img src="/images/resources/hospital-icon.png"
              width="40"
              height="40"
              alt="icon of hospital bed"
            />
          </div>
          <div className={styles.titleContent}>
            <h2>Hospitalization</h2>
          </div>
        </div>
        <div className={styles.blurb}>
          <p>This tool contains the most updated information on hospital inpatient bed and ICU bed occupancy rates for last week and the week prior.</p>
        </div>
      </div>
      <div className={styles.content}>
        {isLoading ?
          'Loading...'
        : !!data ? (
          <BedsChart className={styles.chart} data={data} />
        ) : (
          <p>Data for this region is not yet available.</p>
        )}
      </div>
    </div>
  );
};

RegionHospitalization.propTypes = {
  regionName: PropTypes.string,
};

export default RegionHospitalization;
