import React, { useEffect, useState } from "react";
import { scaleBand, scaleLog } from "d3-scale";
import { min } from "d3-array";
import Axes from "./Axes";
import Bar from "./Bar";

const BarChart = ({data, selectedCountry, width}) => {
  const xvar = "percentSequenced";
  const sorted = data.sort((a,b) => a[xvar] - b[xvar]);
  const filtered = sorted.filter(e => e[xvar] !== undefined && e[xvar] > 0)
  const [highlightedCountry, setHighlightedCountry] = useState(selectedCountry ? selectedCountry : {label: "United States", value: "USA"})
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const yvar = mobile ? "ISO3" : "Country"
  const margin = {top: 60, right: 120, bottom: 20, left: mobile ? 60 : 180};
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = filtered.length * 20;
  const height = innerHeight + margin.top + margin.bottom;
  const yScale = scaleBand()
    .domain(filtered.map(s => s[yvar]))
    .range([innerHeight,0])
    .padding(0.1);
  const xScale = scaleLog().base(Math.E)
    .domain([min(filtered.map(s => s[xvar])),1])
    .range([0,innerWidth]);
  const xFormat = xScale.tickFormat(Infinity, ".3p");

  useEffect(() => {
    if (!selectedCountry || selectedCountry.value === "all-content") {
      setHighlightedCountry({label: "United States", value: "USA"})
    } else {
      setHighlightedCountry(selectedCountry)
    }
  }, [selectedCountry])

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg">
      <g transform={`translate(${margin.left},${margin.top})`} >
        <Axes
          xScale={xScale}
          xFormat={xFormat}
          yScale={yScale}
          margin={margin}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
        />
        {filtered.map(d => {
          return (
            <Bar x={0} y={yScale(d[yvar])} height={yScale.bandwidth()} width={xScale(d[xvar])} key={d.ISO3} highlight={d[yvar] === highlightedCountry.value || d[yvar] === highlightedCountry.label} data={d} mobile={mobile} />
          );
        })}
      </g>
    </svg>
  );
}

export default BarChart;
