import React from "react";
import styles from "./CRCNow.module.scss";
import classNames from "classnames/bind";
import TopPages from "../TopPages/TopPages";
import ReactMarkdown from "react-markdown";
import ContributorImages from "../ContributorImages/ContributorImages";
const cx = classNames.bind(styles);

type TCRCNowProps = {
  image: string;
  className?: string;
};

const CRCNowContent_P1 =
  "## What is the JHU CRC Now?\nThe Johns Hopkins Coronavirus Resource Center established a new standard for infectious disease tracking by publicly providing pandemic data in near real time. It began Jan. 22, 2020 as the [COVID-19 Dashboard](https://www.jhuapl.edu/FeatureStory/210426-JHU-COVID-dashboard-oral-history), operated by the [Center for Systems Science and Engineering and the Applied Physics Laboratory](https://systems.jhu.edu/). But the map of red dots quickly evolved into the global go-to hub for monitoring a public health catastrophe. By March 3, 2020, Johns Hopkins expanded the site into a comprehensive collection of raw data and independent expert analysis known as the Coronavirus Resource Center (CRC) – an enterprise that harnessed the world-renowned expertise from across Johns Hopkins University & Medicine.";

const CRCNowContent_P2 =
  "### Why did we shut down?\nAfter three years of 24-7 operations, the CRC is ceasing its data collection efforts due to an increasing number of U.S. states slowing their reporting cadences. In addition, the federal government has improved its pandemic data tracking enough to warrant the CRC’s exit. From the start, this effort should have been provided by the U.S. government. This does not mean Johns Hopkins believes the pandemic is over. It is not. The institution remains committed to maintaining a leadership role in providing the public and policymakers with cutting edge insights into COVID-19. See details below.";

const topPageContent = [
  {
    title: "News",
    content:
      "The Hub — the news and information website for Johns Hopkins — publishes the latest updates on COVID-19 research about vaccines, treatments, and public health measures.",
    href: "https://hub.jhu.edu/",
  },
  {
    title: "Public Health",
    content:
      "The Johns Hopkins Bloomberg School of Public Health maintains the COVID-19 Projects and Initiatives page to share the latest research and practice efforts by Bloomberg faculty.",
    href: "https://publichealth.jhu.edu/",
  },
  {
    title: "Health Security",
    content:
      "The Johns Hopkins Center for Health Security has been at the forefront of providing policymakers and the public with vital information on how to mitigate disease spread.",
    href: "https://www.centerforhealthsecurity.org/",
  },
  {
    title: "Vaccines",
    content:
      "The Johns Hopkins International Vaccine Access Center offers an online, interactive map-based platform for easy navigation of hundreds of research reports into vaccine use and impact.",
    href: "https://view-hub.org/",
  },
  {
    title: "Treatment",
    content:
      "Johns Hopkins Medicine provides various online portals that provide information about COVID-19 patient care, vaccinations, testing and more.",
    href: "https://www.hopkinsmedicine.org/CORONAVIRUS",
  },
];

export const CRCNowSection = ({ image, className }: TCRCNowProps) => {
  return (
    <div className={cx(styles.crcNowWrapper, className)}>
      <div className={styles.imageBlockOuter}>
        <ContributorImages />
      </div>
      <div className={styles.crcNowContainer}>
        <CRCNow className={styles.crcNowBlock} image={image} />
        <TopPages items={topPageContent} />
      </div>
    </div>
  );
};

const CRCNow = ({ image, className }: TCRCNowProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.imageBlock}>
        <ContributorImages />
      </div>
      <div className={styles.contentBlock}>
        <ReactMarkdown source={CRCNowContent_P1} />
        <ReactMarkdown source={CRCNowContent_P2} />
      </div>
    </div>
  );
};

export default CRCNow;
