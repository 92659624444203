import React, { useState, useEffect } from "react";

import Page from "components/layout/Page";
import TabBar from "components/layout/shell/TabBar";
import CustomSelect from "components/common/CustomSelect";
// import Blocks from "./Blocks";
import Map from "./Map";
import Graph from "./Graph";

import styles from "./VaccineDataVisualization.module.scss";
import { dataSets, fields, vizTypes } from "./constants";
import { useFetch } from "util/hooks/useFetch";

const VaccineDataVisualization = (props) => {
  /* State Management: */
  const [dataSet, setDataSet] = useState(dataSets[0]);
  const [field, setField] = useState(fields[0]);
  const [vizType, setVizType] = useState(vizTypes[0]);
  const breakpoint = 768;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [svgWidth, setSvgWidth] = useState(window.innerWidth * 0.75);

  /* Data Loading: */
  const [mapData, isMapLoading] = useFetch(
    dataSet.name === "International" ? "geo_world" : "geo_us_states"
  );
  const [data, isLoading] = useFetch(dataSet.resourceKey);
  const loading = isMapLoading || isLoading;

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth < breakpoint);
      setSvgWidth(window.innerWidth * 0.75);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Page title="Vaccine Data Visualization">
      <div className={styles.base}>
        <div className={styles.controls}>
          <TabBar
            tabs={dataSets.map((d) => d.name)}
            onChange={(selected) => {
              setDataSet(dataSets.find((d) => d.name === selected));
            }}
          />
          <br />
          <div className={styles.selectorRow}>
            <CustomSelect
              noAllOption
              multi={false}
              allOptionLabel={fields[0].label}
              options={fields}
              onSelect={(selected) => setField(selected)}
              classNames={styles.selector}
            />
            <CustomSelect
              noAllOption
              multi={false}
              allOptionLabel={vizTypes[0].label}
              options={vizTypes}
              onSelect={(selected) => setVizType(selected)}
              classNames={styles.selector}
            />
          </div>
        </div>
        <div className={styles.content}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {vizType.value === "Graph" && (
                <Graph dataSet={dataSet} field={field} data={data} mobile={mobile} svgWidth={svgWidth}/>
              )}
              {vizType.value === "Map" && (
                <Map
                  dataSet={dataSet}
                  field={field}
                  data={data}
                  mapData={mapData}
                  svgWidth={svgWidth}
                />
              )}
              {/* {vizType.value === "Map" && mobile && (
                <Blocks
                  dataSet={dataSet}
                  field={field}
                  data={data}
                />
              )} */}
              {/* <Table dataSet={dataSet} field={field} data={data} /> */}
            </>
          )}
        </div>
      </div>
    </Page>
  );
};

export default VaccineDataVisualization;
