import React from "react";
import styles from "./PhaseCard.module.scss";
import PropTypes from "prop-types";
import cx from "classnames";
import Icon from "../Icon";
import HFormat from "components/common/HFormat";
import TourPoint from "../../components/TourPoint";

function PhaseCard({
  id,
  title,
  icon,
  className,
  active,
  onClick,
  hasTourPoint,
  tourPointActive,
  gotoTourPoint,
  stepIndex
}) {
  return (
    <div
      className={cx(styles.base, className, { [styles.active]: active })}
      onClick={onClick}
    >
      {hasTourPoint && (
        <TourPoint
          active={tourPointActive}
          onClick={gotoTourPoint}
          onClickParam={stepIndex}
        />
      )}
      <div className={styles.header}>
        <Icon icon={icon} />
        <HFormat size={3} noMargins>
          {title}
        </HFormat>
      </div>
    </div>
  );
}

PhaseCard.prototype = {
  className: PropTypes.string,
  id: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  hasTourPoint: PropTypes.bool,
  tourPointActive: PropTypes.bool,
  gotoTourPoint: PropTypes.func,
};

export default PhaseCard;
