import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
    BASE_STATE_TIMELINE_ROUTE,
    DEFAULT_DATASET,
    EVENT_TYPE_COLORS,
    OTHER
} from '../../../../../constants';
import {
    DEFAULT_STATE_NAME,
    valueAccessor,
} from '../../../../../../Shared/constants';
import {useHistory, useParams} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    marker: {
        stroke: 'none',
        cursor: 'pointer',
    },
    line: {
        fill: 'none',
        strokeWidth: '1.5px',
        strokeDasharray: '7 7'
    },
    actionLine: {
        fill: 'none',
        stroke: 'transparent',
        strokeWidth: '5px',
        cursor: 'pointer',
    }
}));

const EventMarker = ({x, r, event, height}) => {
    const classes = useStyles();

    const {stateName = DEFAULT_STATE_NAME, dataset = DEFAULT_DATASET} = useParams();
    const history = useHistory();
    const routeToSelectedEventId = id => history.push(`${BASE_STATE_TIMELINE_ROUTE}/${dataset}/${stateName}/${id}`, {noScroll: true});

    return (
        <g className="event__marker" transform={`translate(${x},0)`}>
            <circle
                r={r}
                className={classes.marker}
                style={{fill: EVENT_TYPE_COLORS[event.type]}}
                onClick={() => routeToSelectedEventId(valueAccessor(event))}
            />
            {event.type !== OTHER &&
            <line x={x} y1={height} y2={0} className={classes.line}
                  style={{stroke: EVENT_TYPE_COLORS[event.type]}}/>}
            {event.type !== OTHER && <line x={x} y1={height} y2={0}
                                           className={classes.actionLine}
                                           onClick={() => routeToSelectedEventId(valueAccessor(event))}/>}
        </g>
    );
};

export default EventMarker;
