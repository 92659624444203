import React from "react";
import { Helmet } from "react-helmet";
import { formatRegionSlug } from "util/helper";

const SEO_URLS = [
  "/contact-tracing",
  "/live/eschool-initiative-webinar-series-equity-and-k-12-school-re-opening",
  "/live/keeping-elections-and-voters-safe",
  "/live/protecting-both-public-health-and-civil-liberties",
  "/live/webcast-climate-food-insecurity-and-covid-19",
  "/live/webcast-crisis-security-and-covid-19",
  "/live/webcast-listening-to-rural-and-urban-voices",
  "/live/webcast-the-interplay-between-technology-and-democracy",
  "/live/webcast-will-covid-19-end-globalization-as-we-know-it",
  "/covid-19-basics/understanding-covid-19/module-5-public-health-responses-to-covid-19",
];

const defaultTitle = "Johns Hopkins Coronavirus Resource Center";

const Meta = ({
  title,
  description = "Johns Hopkins experts in global public health, infectious disease, and emergency preparedness have been at the forefront of the international response to COVID-19.",
  image = "/images/jhu_covid_19_generic.png",
  path = "/",
  match,
}) => {
  let region;
  if (!!match && !!match.params) {
    if (!!match.params.stateId) {
      region = formatRegionSlug(match.params.stateId);
    } else if (!!match.params.countryId) {
      region = formatRegionSlug(match.params.stateId);
    }
  }
  const fullPath = window.location.pathname;
  const fragments = fullPath.split("/");

  const catchDefaultRegionName = (regionName) => {
    if (!region || fullPath === path) {
      region = regionName;
    };
  };
  let useSEOTitle = false;

  switch (path) {
    case "/data/new-cases-50-states": {
      catchDefaultRegionName("the US");
      title = `Have we flattened the curve in ${region}?`;
      description = `Daily Confirmed Case Trends (7-Day Moving Average) in ${region}`;
      useSEOTitle = true;
      break;
    }
    case "/testing/individual-states": {
      catchDefaultRegionName("the US");
      title = `Daily Testing Trends in ${region}`;
      description = `See daily changes in tests performed and positivity rates in ${region}`;
      useSEOTitle = true;
      break;
    }
    case "/data/state-timeline": {
      useSEOTitle = true;
      let endText = "";
      if (fullPath.includes("new-deaths")) {
        region = fragments[fragments.indexOf("new-deaths") + 1];
        endText = ", Deaths";
      } else if (fullPath.includes("new-confirmed-cases")) {
        region = fragments[fragments.indexOf("new-confirmed-cases") + 1];
        endText = ", New Cases";
      }
      catchDefaultRegionName("Alabama");
      title = `Impact of Opening and Closing Decisions in ${formatRegionSlug(
        region
      )}${endText}`;
      description = `A look at how social distancing measures may have influenced trends in COVID-19 cases and deaths in ${region}`;
      break;
    }
    default: {
      if (fullPath.includes("region/")) {
        useSEOTitle = true;
        title += " - COVID-19 Overview";
      } else if (fullPath.includes("/covid-19-basics")) {
        useSEOTitle = true;
      }
      else {
        if (SEO_URLS.includes(fullPath)) {
          useSEOTitle = true;
        };
      };
      break;
    }
  };

  if (!!title && useSEOTitle) {
    title += " - Johns Hopkins";
  } else {
    if (!title) {
      title = defaultTitle;
    } else if (title !== defaultTitle) {
      title += ` - ${defaultTitle}`;
    };
  };

  return (
    <Helmet>
      <title>
        {title}
      </title>
      <meta name="description" content={description} />

      <link rel="canonical" href={`https://coronavirus.jhu.edu${path}`} />

      <meta property="og:type" content="website" />
      <meta
        property="og:site_name"
        content="Johns Hopkins Coronavirus Resource Center"
      />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={`https://coronavirus.jhu.edu${path}`} />
      <meta property="og:description" content={description} />

      {image && [
        <meta
          key="image"
          property="og:image"
          content={`https://coronavirus.jhu.edu${image}`}
        />,
        <meta
          key="image_secure"
          property="og:image:secure_url"
          content={`https://coronavirus.jhu.edu${image}`}
        />,
      ]}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@JohnsHopkins" />
      <meta name="twitter:creator" content="@JohnsHopkins" />
    </Helmet>
  );
};

export default Meta;
