import { countryMappings, slugify } from "data/geo";
import PropTypes from "prop-types";

//TEMP
// export const countriesTEMP = () => {
//   const result = {};
//   const keys = Object.keys(countryAbbreviations).sort();
//   for (let i = 0; i < keys.length; i++) {
//     const k = keys[i];
//     result[k] = {
//       name: countryAbbreviations[k],
//       displayName: countryAbbreviations[k],
//     };
//   }
//   console.log(result);
//   return result;
// };

export const formatTableCountryData = (data) => {
  if (!data || typeof data != "object") return {};

  const countryMappingData = countryMappings;
  const regionList = {};
  const noPageList = {};
  const formattedData = [];

  const mapped = {};

  // Fix rename of US region name from the endpoint
  data = data.map((d) => {
    if (d.regionName === "US") {
      d.regionName = "United States";
    }
    return d;
  });

  for (let i = 0; i < countryMappingData.length; i++) {
    const m = countryMappingData[i];
    const ele = data.find(
      (d) => d.regionName === m.name || d.regionName === m.displayName
    );
    if (!!ele) {
      mapped[m.displayName] = true;

      const slug = slugify(m.name);
      regionList[slug] = m.displayName;
      if (!m.hasPage) {
        noPageList[slug] = m.displayName;
      }
      formattedData.push({
        ...ele,
        regionSlug: slug,
        regionCode: m.regionCode,
        URL: m.URL,
      });
    }
  }
  // const missingInList = data.filter((d) => !mapped[d.regionName]);
  // console.log(missingInList);

  return {
    regionList,
    formattedData,
    noPageList,
  };
};

export const shortenRegionName = (regionName) => {
  const searchChar = " of ";
  if (regionName?.length > 28 && regionName?.includes(searchChar)) {
    return regionName.split(searchChar).slice(1)?.join("") || regionName;
  }
  return regionName;
};

export const shortenRegionNames = (regionTableData) => {
  return regionTableData.map((data) => {
    return { ...data, regionName: shortenRegionName(data.regionName) };
  });
};

export const TFormatCommonProps = {
  customSortConfig: PropTypes.shape({
    key: PropTypes.string,
    direction: PropTypes.oneOf(["ascending", "descending"]),
  }),
  mobileOptions: PropTypes.shape({
    useRegionCodeType: PropTypes.oneOf(["state", "country", null]),
    useNameShortening: PropTypes.bool,
  }),
  regionNameLinkStyle: PropTypes.oneOf([null, "button"]),
  rowLimit: PropTypes.number,
  hideViewAll: PropTypes.bool,
  className: PropTypes.string,
  overflowClassName: PropTypes.string,
};
