import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import smartquotes from "smartquotes";
import { Route } from "react-router-dom";

import Spinner from '../../../Shared/Spinner';
import Timeline from "./Timeline";
import { BASE_STATE_TIMELINE_ROUTE } from "../../constants";
import { retrieveData } from '../../redux/modules/global';

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.stateTimeline.isLoading);
  const getData = useCallback(() => dispatch(retrieveData()), [dispatch]);

  useEffect(() => {
    getData();
    smartquotes().listen();
  }, [getData]);

  if (isLoading) return <Spinner />;

  return (
    <div className="page">
      <Route
        path={`${BASE_STATE_TIMELINE_ROUTE}/:dataset?/:stateName?/:eventId?`}
        component={Timeline}
      />
    </div>
  );
};
export default App;
