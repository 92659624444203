// modified from https://observablehq.com/@d3/color-legend
import React, { useRef, useEffect } from "react";

import { axisBottom } from "d3-axis";
import { range, quantile } from "d3-array";
import { format } from "d3-format";
import { scaleSequential } from "d3-scale";
import { interpolateRgb } from "d3-interpolate";
import { schemeYlGnBu, schemeBlues } from "d3-scale-chromatic";
import { select } from "d3-selection";

function ramp(color, ticks, colorList) {
  const canvas = document.createElement("canvas");
  canvas.style.width = 300;
  canvas.style.height = 100;
  const context = canvas.getContext("2d");
  let gradient = context.createLinearGradient(0, 0, 300, 0);
  let step = colorList.length > 2 ? colorList.length : ticks;
  for (var i = 0; i < step; i++) {
    if (colorList.length > 2) {
      gradient.addColorStop(i / (step - 1), colorList[i]);
    } else {
      gradient.addColorStop(
        i / step,
        interpolateRgb(colorList[0], colorList[1])(i / step + 0.1)
      );
    }
  }

  context.fillStyle = gradient;
  context.fillRect(0, 0, 300, 100);

  return canvas;
}

const Legend = ({ color, field, margin, width }) => {
  const refLegend = useRef(null);

  useEffect(() => {
    let leg;
    let tickSize = 2,
      height = 50 + tickSize,
      marginTop = 18,
      marginRight = 20,
      marginBottom = 16 + tickSize,
      marginLeft = 20,
      innerWidth = width - marginLeft - marginRight,
      tickValues;

    let tickFormat =
      field.value === "percent_full_vac" ? format(".2s") : format(".0s");
    let ticks = field.value === "percent_full_vac" ? 10 : 5;
    let colorList =
      field.value === "percent_full_vac"
        ? schemeYlGnBu[9]
        : [schemeBlues[9][1], schemeBlues[9][8]];

    // append a child g element, and render in to that, so that
    // when its removed in the cleanup handler, it won't remove
    // the react managed reference.
    leg = select(refLegend.current).append("g");
    leg.attr("id", "legendG");

    let tickAdjust = (g) =>
      g
        .selectAll(".tick line")
        .attr("y1", marginTop + marginBottom - height)
        .attr("stroke", "#fff");
    let x = scaleSequential().domain(color.domain()).range([0, width]);

    leg
      .append("image")
      .attr("x", marginLeft)
      .attr("y", marginTop)
      .attr("width", innerWidth)
      .attr("height", height - marginTop - marginBottom)
      .attr("preserveAspectRatio", "none")
      .attr("xlink:href", ramp(color, ticks, colorList).toDataURL());

    // scaleSequentialQuantile doesn’t implement ticks or tickFormat.
    if (!x.ticks) {
      if (tickValues === undefined) {
        const n = Math.round(ticks + 1);
        tickValues = range(n).map((i) => quantile(color.domain(), i / (n - 1)));
      }
      if (typeof tickFormat !== "function") {
        tickFormat = format(tickFormat === undefined ? ",f" : tickFormat);
      }
    }

    leg
      .append("g")
      .attr("transform", `translate(0,${height - marginBottom})`)
      .call(
        axisBottom(x)
          .ticks(ticks, typeof tickFormat === "string" ? tickFormat : undefined)
          .tickFormat(typeof tickFormat === "function" ? tickFormat : undefined)
          .tickSize(tickSize)
          .tickValues(tickValues)
      )
      .call(tickAdjust)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .append("text")
          .attr("x", marginLeft)
          .attr("y", marginTop + marginBottom - height - 6)
          .attr("fill", "currentColor")
          .attr("text-anchor", "start")
          .attr("font-weight", "bold")
          .attr("class", "title")
          .text(field.label)
      );

    return () => {
      leg && leg.remove();
    };
  }, [color, field, width]);

  return <g ref={refLegend} />;
};

export default Legend;
