import React from "react";
import classNames from "classnames";
import styles from "./FAQBlade.module.scss";
import { ArticleGridLayouts } from "components/layout/ArticleGrid";
import { formatFAQCards } from "../cards";
const cx = classNames.bind(styles);

const FAQBlade = ({ dark = false, subnav = false }) => {
  const content = (subnav
    ? formatFAQCards()
    : formatFAQCards().slice(0, 4)
  ).map((card) => {
    return {
      ...card,
      dark: false,
      vertical: true,
      imageSrc: null,
    };
  });
  return (
    <div className={cx("base")}>
      <ArticleGridLayouts.Flexible articles={content} />
    </div>
  );
};

export default FAQBlade;
