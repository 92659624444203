import { createSelector } from 'reselect';
import { valueAccessor } from '../../../Shared/constants';

const statesSelector = state => state.stateTimeline.states;

const selectedStateSelector = selectedStateName =>
  createSelector([statesSelector], states => {
    return states.find(d => valueAccessor(d) === selectedStateName);
  });

export default selectedStateSelector;
