import React from "react";
import classNames from "classnames/bind";
import styles from "./SectionLink.module.scss";
import { Link } from "react-router-dom";
import { navItemMatches } from "../../Header";
import { sectionNav } from "../util";

const cx = classNames.bind(styles);

const SectionLink = ({ section, pathname, isActive, isGrouped, onClose }) => {
  //If grouped, change <Link> to <div> to prevent nested <a> tag warnings
  const Component = isGrouped ? "div" : Link;
  return (
    <Component
      key={section.id}
      to={section?.to}
      className={cx(
        "link",
        { active: isActive || navItemMatches(pathname, section) },
        { grouped: isGrouped }
      )}
      onClick={() => {
        if (onClose) onClose();
      }}
      aria-label={section.name}
    >
      {section.name}
    </Component>
  );
};

SectionLink.propTypes = sectionNav;

export default SectionLink;
