import {dateAccessor, FULL_DAYS_RANGE} from './constants';
import moment from 'moment';
import {extent} from "d3-array";

export const getZoomedInDateExtent = (selectedValues, selectedEvent, accessor) => {
  let dateExtent = extent(selectedValues, accessor);
  const leftDifference = accessor(selectedEvent).diff(dateExtent[0], 'days');
  const rightDifference = dateExtent[1].diff(accessor(selectedEvent), 'days')
  if (leftDifference >= (FULL_DAYS_RANGE / 2)) { // test left side
    dateExtent[0] = accessor(selectedEvent).clone().subtract(FULL_DAYS_RANGE / 2, 'days');
    if (rightDifference >= (FULL_DAYS_RANGE / 2)) {
      dateExtent[1] = accessor(selectedEvent).clone().add(FULL_DAYS_RANGE / 2, 'days');
    }
  } else {
    dateExtent[1] = accessor(selectedEvent).clone().add(FULL_DAYS_RANGE - leftDifference, 'days');
  }

  if (rightDifference >= (FULL_DAYS_RANGE / 2)) { // test right side
    dateExtent[1] = accessor(selectedEvent).clone().add(FULL_DAYS_RANGE / 2, 'days');
  }
  else{
    dateExtent[0] = accessor(selectedEvent).clone().subtract(FULL_DAYS_RANGE - rightDifference, 'days');
  }
  return dateExtent;
}

export const getMatchingValue = (values, dt) => values.find(d => moment(dateAccessor(d), 'YYYY-MM-DD').isSame(dt));
