import PropTypes from "prop-types";
export const EVENT_STATES = ["pre", "mid", "post"];

// Represented In minutes before/after the event
export const EVENT_QA_LIVE_TIMER = {
  pre: 30,
  post: 30,
};

export const shouldShowQA = (dates = { start: "", end: "" }) => {
  function addMinutes(date, minutes) {
    return new Date(date.getTime() + minutes * 60000);
  }
  const { start, end } = dates;
  if (!start || !end) return false;

  const startDate = addMinutes(new Date(start), EVENT_QA_LIVE_TIMER.pre * -1);
  const endDate = addMinutes(new Date(end), Math.abs(EVENT_QA_LIVE_TIMER.post));
  const should =
    new Date(Date.now()) >= startDate && new Date(Date.now()) <= endDate;
  return should;
};

export const getEventState = (dates) => {
  if (!dates || !dates?.start || !dates?.end) return EVENT_STATES[2];

  const { start, end } = dates;
  if (start && end) {
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (startDate >= Date.now()) {
      return EVENT_STATES[0];
    } else if (endDate <= Date.now()) {
      return EVENT_STATES[2];
    } else {
      return EVENT_STATES[1];
    }
  }
};

export const EventPageData = ({ subtitle, caption, content, meta }) => ({
  subtitle,
  caption,
  content,
  meta,
});
export const eventPageData = {
  subtitle: PropTypes.string,
  caption: PropTypes.string,
  content: PropTypes.string,
  meta: PropTypes.shape({
    title: PropTypes.string,
  }),
};
EventPageData.propTypes = eventPageData;

export function EventInfo({
  id,
  slug,
  meta,
  startDate,
  endDate,

  embedQAUrl,
  embedQA = {
    src: embedQAUrl,
    iframeProps: {
      style: {
        border: 0,
        webkitallowfullscreen: true,
        allowfullscreen: true,
        frameborder: false,
        referrerpolicy: "no-referrer-when-downgrade",
        width: "100%",
        height: 300,
      },
    },
  },
  registerUrl,

  url,
  videoUrl,
  title,
  subtitle,
  description,
  content,
  image,
  icsSrc,
  speakers = [],
  defaultVideoId = null,
  hasVideo = true,
}) {
  return {
    id,
    slug,
    meta,
    dates: { start: startDate, end: endDate },
    embedQAUrl,
    embedQA: { ...embedQA, src: embedQA.src || embedQAUrl },
    registerUrl,

    defaultVideoId: defaultVideoId || slug,
    url: url || `/live/events/${slug}`,
    image:
      !!image && image.includes("/")
        ? image
        : `/images/vaccine-events/${image}`,
    icsSrc: `/downloads/ICS/${icsSrc || `${slug}.ics`}`,

    videoUrl,
    title,
    subtitle,
    description,
    content,
    speakers,

    hasVideo,
    section: "vaccines",
    type: "webcasts",
  };
}
EventInfo.propTypes = {
  slug: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,

  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,

  embedQAUrl: PropTypes.string,
  embedQA: PropTypes.shape({
    src: PropTypes.string,
    iframeProps: PropTypes.object,
  }),

  registerUrl: PropTypes.string,
  videoUrl: PropTypes.string,
  defaultVideoId: PropTypes.string,

  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  image: PropTypes.string,
  icsSrc: PropTypes.string,
  date: PropTypes.string,
  hasVideo: PropTypes.bool,

  speakers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      src: PropTypes.string,
    })
  ).isRequired,
};
