import React from "react";
import { max, extent } from "d3-array";
import { scaleLinear, scaleQuantize } from "d3-scale";
import { schemeYlGnBu, schemeBlues } from "d3-scale-chromatic";
import { interpolateRgb } from "d3-interpolate";
import { states } from "data/geo";
import { format } from "d3-format";
import { fields } from "../../constants";
import Row from './Row';
import Legend from "./Legend";

const USBlockMap = ({ svgWidth, dataSet, field, data, tileMap, children }) => {
  const filteredData = data.filter((d) => states.includes(d.state));

  const color =
    field.value === "percent_full_vac"
      ? scaleQuantize(schemeYlGnBu[9])
      : scaleLinear().range([schemeBlues[9][1],schemeBlues[9][8]]).interpolate(interpolateRgb)
  const dataExtent = extent(filteredData.filter(d => d.data[field.value] !== "Not Available"), d => d.data[field.value])

  color.domain(dataExtent)

  // math: min+(max-min)*0.5 = 6.5  where 0.5=50% is the threshold
  const threshold = dataExtent[0]+(dataExtent[1]-dataExtent[0])*0.5

  const columns = max(tileMap[dataSet.key].map(d => d.coordinates ? d.coordinates[0] : 0))+1;
  const rows = max(tileMap[dataSet.key].map(d => d.coordinates ? d.coordinates[1] : 0))+1;
  const gridData = []
  for (let i = 0; i < rows; i++) {
    let row = [];
    for (let j = 0; j < columns; j++) {
      let place = tileMap[dataSet.key].find(d => {
        if (d.coordinates) {
          return d.coordinates[1] === i && d.coordinates[0] === j
        } else {
          return null
        }
      });
      let datapoint = place ? data.find(d => d[dataSet.key] === place.name) : null
      let value = datapoint ? datapoint.data[field.value] : null
      let content;

      if (datapoint) {
        let textValue;
        if (typeof datapoint.data[field.value] === "string") {
          textValue = `${field.label}: Not Available`;
        } else if (field.value === "percent_full_vac") {
          textValue = `${field.label}: ${format(".1%")(
            datapoint.data[field.value] / 100
          )}`;
        } else {
          textValue = `${field.label}: ${format(",")(datapoint.data[field.value])}`;
        }

        let spans = fields.map((f) => {
          if (f.value !== field.value) {
            let spantext = "";
            if (datapoint.data[f.value] === "Not Available") {
              spantext = `${f.label}: Not Available`;
            } else {
              spantext =
                f.value === "percent_full_vac"
                  ? `${f.label}: ${format(".1%")(datapoint.data[f.value] / 100)}`
                  : `${f.label}: ${format(",")(datapoint.data[f.value])}`;
            }
            return <span key={f.value} style={{display:"block"}}>{spantext}</span>;
          }
          return null;
        });
        content = (
          <>
            <span style={{display:"block",fontSize:"130%",fontWeight:"600"}}>{datapoint[dataSet.key]}</span>
            <span style={{display:"block"}}>{textValue}</span>
            {spans}
          </>
        );
      }

      row.push({...place, value: value, tiptext: content})
    }
    gridData.push(row)
  }

  return (
    <div style={{width:"100%"}}>
      {gridData.map((row, i) => {
        return (
          <Row
            key={`row-for-${i}`}
            i={i}
            row={row}
            color={color}
            threshold={threshold}
            field={field}
            datakey={dataSet.key}
          />
        );
      })}
      <svg width="100%" height="100" style={{marginTop:"20px"}}>
        <Legend
          color={color}
          field={field}
          margin={{top:0,left:0,bottom:0,right:0}}
          width={svgWidth}
        />
      </svg>
    </div>
  );
}

export default USBlockMap;
