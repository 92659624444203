import {NEW_CONFIRMED_CASES, NEW_DEATHS, STATE_META} from '../../constants';

export const initialState = {
    data: {},
    showError: false,
    isLoading: true,
    datasets: [
        {
            id: NEW_CONFIRMED_CASES,
            name: 'New Confirmed Cases',
            accessor: NEW_CONFIRMED_CASES,
        },
        {
            id: NEW_DEATHS,
            name: 'New Deaths',
            accessor: NEW_DEATHS,
        },
    ],
    states: STATE_META,
    legendItems: [
        {label: 'Restriction/closing', id: 'CLOSING'},
        {label: 'Opening', id: 'OPENING'},
        {label: 'Deferring decisions to county', id: 'DEFER_TO_COUNTY'},
        {label: 'Other', id: 'OTHER'},
    ].map(d => ({...d, isActive: true})),
    zoomEffect: false
};
