import React from "react";
import styles from "./HowToUseCard.module.scss";
import classNames from "classnames/bind";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

type THowToUseCardProps = {
  title: string;
  content: any;
  label: string;
  href: string;
  className?: string;
};

const HowToUseCard = ({
  title,
  content,
  href,
  label,
  className,
}: THowToUseCardProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.contentBlock}>
        <h3 className={styles.title}> {title}</h3>
        <div className={styles.content}>{content}</div>
      </div>
      <div className={styles.buttonBlock}>
        <Button
          className={styles.button}
          icon={"right"}
          shape="rounded"
          href={href}
          targetBlank={true}
        >
          {label}
        </Button>
      </div>
    </div>
  );
};

export default HowToUseCard;
