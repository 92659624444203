import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { scaleTime, scaleLinear, scaleOrdinal } from "d3-scale";
import { extent, min, max } from "d3-array";
import { line, curveBasis } from "d3-shape";
import { axisLeft, axisBottom } from "d3-axis";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { select, mouse } from "d3-selection";
import { legendColor } from "d3-svg-legend";
import styles from "./AllStateTimelines.module.scss";

const AllStateTimelines = ({
  states,
  data,
  stateSelected,
  stateCompared,
  svgWidth,
}) => {
  const [innerHtml, setInnerHtml] = useState(null);
  const [tipX, setTipX] = useState(0);
  const [tipY, setTipY] = useState(0);
  const [showTip, setShowTip] = useState(false);
  const svgRef = useRef(null);
  const viz1y = "doses_admin_100k";
  const tipHeight = 45;
  const tipWidth = 110;
  const breakpoint = 970;
  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);
  const [height, setHeight] = useState(450);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setHeight(mobile ? 300 : 450);
  }, [data, mobile]);

  useEffect(() => {
    const compare = stateCompared
      ? stateCompared
      : { label: "All", value: "all" };
    const allDates = [
      ...new Set(data.map((d) => d.data.map((e) => e.date)).flat()),
    ].sort();
    const timeExtent = extent(allDates);
    const margin = { top: 20, right: mobile ? 60 : 120, bottom: 40, left: 80 };
    const width = svgWidth - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const labelTimeFormat = timeFormat("%Y-%m-%d");
    const svg = select(svgRef.current);

    // time scale and axis
    const timeScale = scaleTime()
      .domain([moment(timeExtent[0]), moment(timeExtent[1])])
      .range([0, width]);

    const timeAxis = axisBottom(timeScale).ticks(8, timeFormat("%b %Y"));

    const y = scaleLinear()
      .domain([
        min(
          data
            .map((d) =>
              d.data.map((e) => {
                if (e[viz1y] !== "Not Available") {
                  return e[viz1y];
                }
                return null;
              })
            )
            .flat()
        ),
        max(
          data
            .map((d) =>
              d.data.map((e) => {
                if (e[viz1y] !== "Not Available") {
                  return e[viz1y];
                }
                return null;
              })
            )
            .flat()
        ),
      ])
      .range([innerHeight, 0])
      .nice();

    const yAxis = axisLeft(y);

    if (mobile) {
      yAxis.ticks(6);
      timeAxis.ticks(3, timeFormat("%b %Y"));
    }

    const lineGen = line()
      .x((d) => timeScale(moment(d.date)))
      .y((d) => y(d[viz1y]))
      .curve(curveBasis);

    const group = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    group
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(timeAxis);

    const yG = group.append("g").call(yAxis);
    yG.append("text")
      .attr(
        "transform",
        `translate(-${margin.left - 20},${innerHeight / 2}) rotate(270)`
      )
      .attr("text-anchor", "middle")
      .attr("fill", "#aaa")
      .style("font-size", mobile ? "110%" : "140%")
      .text("Total Doses Administered Per 100k Population");

    const dot = group.append("g").attr("pointer-events", "none");

    const circle = dot.append("circle").attr("display", "none").attr("r", 4);

    const label = dot
      .append("text")
      .attr("display", "none")
      .attr("text-anchor", "end");

    const legendScale = scaleOrdinal().range(["#FFA500", "#008080", "#800080"]);
    stateCompared && stateCompared.label !== "Select a state"
      ? legendScale.domain([
          "United States",
          stateSelected.label,
          stateCompared.label,
        ])
      : legendScale.domain(["United States", stateSelected.label]);

    const legendOrdinal = legendColor()
      .shapeWidth(30)
      .shapeHeight(3)
      .cells(stateCompared ? 3 : 2)
      .scale(legendScale);

    const legendG = group
      .append("g")
      .attr("class", "legendSequential")
      .attr("transform", "translate(20,5)")
      .call(legendOrdinal);

    legendG.selectAll(".label").attr("fill", "#555");

    function drawPaths(group, data) {
      select("#stateTimelines").remove();
      group
        .append("g")
        .attr("id", "stateTimelines")
        .attr("stroke-linejoin", "round")
        .attr("stroke-linecap", "round")
        .selectAll("path")
        .data(data)
        .join("path")
        .attr("stroke", (d) =>
          d.state === stateSelected.label
            ? "#008080"
            : d.state === "United States"
            ? "#FFA500"
            : d.state === compare.label
            ? "#800080"
            : "#eee"
        )
        .attr("stroke-width", (d) =>
          d.state === stateSelected.label ||
          d.state === compare.label ||
          d.state === "United States"
            ? "3px"
            : "1.5px"
        )
        .style("mix-blend-mode", "multiply")
        .attr("fill", "none")
        .attr("d", (d) =>
          lineGen(d.data.filter((e) => e[viz1y] !== "Not Available"))
        )
        .on("mousemove", mobile ? null : mousemoved)
        .on("mouseleave", mobile ? null : left);
    }

    function mousemoved() {
      let m = mouse(this),
        p = closestPoint(this, m),
        info = select(this).data()[0],
        dps = info.data.find(
          (d) => d.date === labelTimeFormat(timeScale.invert(p[0]))
        ),
        end = info.data.find((d) => d.date === timeExtent[1]);

      if (
        info.state === stateSelected.label ||
        info.state === compare.label ||
        info.state === "United States"
      ) {
        label
          .attr("display", null)
          .attr("x", p[0] - 4)
          .attr("y", p[1] - 4)
          .text(format(",.0f")(dps[viz1y]))
          .attr(
            "fill",
            info.state === stateSelected.label
              ? "#008080"
              : info.state === compare.label
              ? "#800080"
              : "#black"
          );
        circle
          .attr("cx", p[0] - 1)
          .attr("cy", p[1] - 1)
          .attr("display", null);
        select(this).raise();
      } else {
        let thisLine = select(this).attr("stroke");
        if (thisLine) {
          select(this).attr("stroke", "#bbb");
        }
      }

      let content = (
        <>
          <p>{info.state}</p>
        </>
      );
      setInnerHtml(content);
      setTipY(y(end[viz1y]) + 5);
      setTipX(width + tipWidth - 20);
      setShowTip(true);
    }

    function left() {
      let info = select(this).data()[0];
      if (
        info.state === stateSelected.label ||
        info.state === compare.label ||
        info.state === "United States"
      ) {
        circle.attr("display", "none");
        label.attr("display", "none");
      } else {
        let thisLine = select(this).attr("stroke");
        if (thisLine) {
          select(this).attr("stroke", "#eee");
        }
      }
      setShowTip(false);
    }

    // credit: https://bl.ocks.org/mbostock/8027637
    function closestPoint(pathNode, point) {
      let pathLength = pathNode.getTotalLength(),
        precision = 8,
        best,
        bestLength,
        bestDistance = Infinity;

      // linear scan for coarse approximation
      for (
        let scan, scanLength = 0, scanDistance;
        scanLength <= pathLength;
        scanLength += precision
      ) {
        if (
          (scanDistance = distance2(
            (scan = pathNode.getPointAtLength(scanLength))
          )) < bestDistance
        ) {
          best = scan;
          bestLength = scanLength;
          bestDistance = scanDistance;
        }
      }

      // binary search for precise estimate
      precision /= 2;
      while (precision > 0.5) {
        let before,
          after,
          beforeLength,
          afterLength,
          beforeDistance,
          afterDistance;
        if (
          (beforeLength = bestLength - precision) >= 0 &&
          (beforeDistance = distance2(
            (before = pathNode.getPointAtLength(beforeLength))
          )) < bestDistance
        ) {
          best = before;
          bestLength = beforeLength;
          bestDistance = beforeDistance;
        } else if (
          (afterLength = bestLength + precision) <= pathLength &&
          (afterDistance = distance2(
            (after = pathNode.getPointAtLength(afterLength))
          )) < bestDistance
        ) {
          best = after;
          bestLength = afterLength;
          bestDistance = afterDistance;
        } else {
          precision /= 2;
        }
      }

      best = [best.x, best.y];
      best.distance = Math.sqrt(bestDistance);
      return best;

      function distance2(p) {
        var dx = p.x - point[0],
          dy = p.y - point[1];
        return dx * dx + dy * dy;
      }
    }

    if (mobile) {
      let mobileData = data.filter((d) => {
        return (
          d.state === "United States" ||
          d.state === stateSelected.label ||
          d.state === compare.label
        );
      });
      drawPaths(group, mobileData);

      let ys = mobileData.map((d) => {
        return y(d.data.find((e) => e.date === timeExtent[1])[viz1y]);
      });
      let greatest = Math.max(...ys);
      let least = Math.min(...ys);
      let tips = group.append("g").attr("pointer-events", "none");
      tips
        .selectAll(".annotation")
        .data(mobileData)
        .join("text")
        .attr("display", "none")
        .attr("text-anchor", "start")
        .attr("fill", (d) =>
          d.state === stateSelected.label
            ? "#008080"
            : d.state === compare.label
            ? "#800080"
            : "#black"
        )
        .attr("display", null)
        .attr(
          "x",
          (d) =>
            timeScale(
              moment(d.data.find((e) => e.date === timeExtent[1]).date)
            ) + 4
        )
        .attr("y", (d, i) => {
          let offset = 4;
          // let thisValue = d.data.find(e => e.date === timeExtent[1])[viz1y]
          let thisY = y(d.data.find((e) => e.date === timeExtent[1])[viz1y]);
          let otherys = ys.filter((x) => x !== thisY);
          if (otherys.length > 1) {
            let offBelow = Math.max(0, greatest - thisY);
            let offAbove = Math.max(0, thisY - least);
            let offMax = greatest - least;
            let offOther =
              thisY - ys.find((x) => x !== greatest && x !== least);
            if (
              offBelow === 0 &&
              (offMax < 20 || offOther < 20 || offAbove < 20)
            ) {
              offset = 18;
            } else if (
              offAbove === 0 &&
              (offMax < 20 || (offOther < 20) | (offBelow < 20))
            ) {
              offset = -12;
            }
          } else {
            let greater = Math.max(thisY, otherys);
            let off = Math.max(
              0,
              greater - (greater === thisY ? otherys : thisY)
            );
            if (off < 25 && greater === thisY) {
              offset = 15;
            }
          }
          return thisY + offset;
        })
        .text((d) =>
          format(",.0f")(d.data.find((e) => e.date === timeExtent[1])[viz1y])
        );
    } else {
      drawPaths(group, data);
    }

    yG.selectAll(".tick line").attr("stroke", "#aaa");
    group.selectAll(".domain").raise().attr("stroke", "#aaa");

    return () => {
      group.remove();
    };
  }, [data, stateSelected, stateCompared, svgWidth, mobile, height]);

  return (
    <svg ref={svgRef} width={svgWidth} height={height}>
      {innerHtml && (
        <g
          transform={`translate(${tipX}, ${tipY})`}
          style={{
            visibility: showTip ? "visible" : "hidden",
            pointerEvents: "none",
          }}
        >
          <foreignObject height={tipHeight} width={tipWidth}>
            <div
              className={styles.tooltip}
              xmlns="http://www.w3.org/1999/xhtml"
            >
              {innerHtml}
            </div>
          </foreignObject>
        </g>
      )}
    </svg>
  );
};

export default AllStateTimelines;
