import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import styles from "./MapFAQ.module.scss";
import { getPage } from "data/util";
import Page from "components/layout/Page";

class MapFAQ extends Component {
  componentDidMount() {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
  }

  render() {
    const page = getPage(this.props.location.pathname);

    return (
      <Page>
        <div className={styles.container}>
          <ReactMarkdown
            className={styles.items}
            source={page.content_blocks["map-faq-content"].content}
            linkTarget="_blank"
          />
        </div>
      </Page>
    );
  }
}

export default MapFAQ;
