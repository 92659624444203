import React from "react";
import {
  states,
  getFilteredRegionDataNotes,
  slugify,
  countryMappings,
} from "data/geo";
import styles from "./RegionNotes.module.scss";
import Share from "components/common/Share";
import Meta from "components/common/Meta";
import classNames from "classnames/bind";
import { useState } from "react";
import CustomSelect from "components/common/CustomSelect";
import HFormat from "components/common/HFormat";
import { getAvailableRegionNoteOptions, getFormatedNoteData } from "./util";
import { useHistory } from "react-router";
import GeneralNotes from "./GeneralNotes";
import PrimaryNotes from "./PrimaryNotes";
const cx = classNames.bind(styles);

const RegionNotes = ({ match }) => {
  const history = useHistory();
  const mainPath = `/region-data-notes`;
  const regionName = match.params?.regionName;
  const [allRegionOptions] = useState([
    {
      label: "States",
      options: getAvailableRegionNoteOptions({
        regionType: "states",
      }),
    },
    {
      label: "Countries",
      options: getAvailableRegionNoteOptions({ regionType: "countries" }),
    },
  ]);
  const allRegions = states.concat(
    countryMappings.map((m) => (m.displayName ? m.displayName : m.name))
  );

  const [currentRegions, setCurrentRegions] = useState(
    regionName
      ? allRegions.find((region) => {
          let name;
          if (typeof region === "string") {
            name = slugify(region);
          }
          return name === regionName;
        })
      : allRegions
  );

  let currentSelect;
  if (regionName && typeof currentRegions === "string") {
    for (let index = 0; index < allRegionOptions.length; index++) {
      const regionType = allRegionOptions[index];
      const currentOption = regionType.options.find(
        (option) => option.label.toLowerCase === regionName
      );
      if (currentOption) currentSelect = currentOption;
    }
  }

  const contents = getFilteredRegionDataNotes({
    regionType: "all",
    filterOptions: {
      types: currentRegions,
    },
  });

  const limit = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const incrementPage = () => setCurrentPage(currentPage + 1);
  const visibleContents = contents.slice(0, limit * currentPage);
  const isMore = contents.length - visibleContents.length > 0;
  const result = getFormatedNoteData(visibleContents);

  //Fixes the filter not resetting after clicking the sub-nav link
  if (!regionName && currentRegions?.length <= 1) {
    setCurrentRegions(allRegions);
  }

  const handleRegionSelect = (selected: any = {}) => {
    const { label, value, otherProps = {} } = selected;
    if (value === "all-content" || !otherProps?.path) {
      setCurrentRegions(allRegions);
      history.push(mainPath, {
        noScroll: true,
      });
    } else {
      setCurrentRegions([label]);
      history.push(`${mainPath}/${selected.otherProps?.slug}`, {
        noScroll: true,
      });
    }
  };

  return (
    <div className={styles.container}>
      <Meta
        title={`Region Data Notes`}
        description={`Region Data Notes - Johns Hopkins Coronavirus Resource Center`}
        path={`/region-data-notes`}
        match=""
      />
      <div className={cx("header")}>
        <div>
          <div className="small-heading">By Region</div>
          <HFormat size={1} noMarginBtm>
            Data Notes
          </HFormat>
        </div>
        <Share />
      </div>

      <GeneralNotes />
      <div>
        <h3 className={styles.regionNotesHeader}>Region Notes</h3>
        <div className={cx("controls")}>
          <CustomSelect
            label="Choose a World Country or U.S. State"
            allOptionLabel="All Regions"
            currentSelected={currentSelect}
            options={allRegionOptions as any}
            onSelect={handleRegionSelect}
            multi={false}
            resetToAll={!regionName}
          />
        </div>
        <PrimaryNotes
          noteData={result}
          onShowMore={incrementPage}
          isMore={isMore}
        />
      </div>
      <br />
    </div>
  );
};

export default RegionNotes;
