import React from "react";
import styles from "./EventCalendarButton.module.scss";
import Button from "components/common/Button";
import ical from "ical-generator";
import FileSaver from "file-saver";

export const EventCalendarButton = ({
  eventId,
  start,
  end,
  title,
  description,
}) => {
  const timeZone = "America/New_York";
  const vTimeInsert = `BEGIN:VTIMEZONE\nTZID:${timeZone}\nTZURL:http://tzurl.org/zoneinfo-outlook/${timeZone}\nX-LIC-LOCATION:${timeZone}\nBEGIN:DAYLIGHT\nTZOFFSETFROM:-0500\nTZOFFSETTO:-0400\nTZNAME:EDT\nDTSTART:19700308T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nEND:DAYLIGHT\nBEGIN:STANDARD\nTZOFFSETFROM:-0400\nTZOFFSETTO:-0500\nTZNAME:EST\nDTSTART:19701101T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nEND:STANDARD\nEND:VTIMEZONE`;
  // const vTimeInsert = `BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//sebbo.net//ical-generator//EN\nBEGIN:VTIMEZONE\nTZID:${timeZone}\nTZURL:http://tzurl.org/zoneinfo-outlook/${timeZone}\nX-LIC-LOCATION:${timeZone}\nBEGIN:DAYLIGHT\nTZOFFSETFROM:-0500\nTZOFFSETTO:-0400\nTZNAME:EDT\nDTSTART:19700308T020000\nRRULE:FREQ=YEARLY;BYMONTH=3;BYDAY=2SU\nEND:DAYLIGHT\nBEGIN:STANDARD\nTZOFFSETFROM:-0400\nTZOFFSETTO:-0500\nTZNAME:EST\nDTSTART:19701101T020000\nRRULE:FREQ=YEARLY;BYMONTH=11;BYDAY=1SU\nEND:STANDARD\nEND:VTIMEZONE`;

  const cal = ical({
    events: [
      {
        start,
        end,
        timestamp: new Date(),
        timezone: timeZone,
        location: timeZone,
        summary: title,
        description,
      },
    ],
  });

  // const calendar = vTimeInsert + "\nBEGIN:VEVENT" + cal.toString().split("BEGIN:VEVENT")?.[1];
  const calendar = cal.toString()
    .split("BEGIN:VEVENT")
    .join(vTimeInsert + "\nBEGIN:VEVENT");

  const handleClick = () => {
    const resultBlob = new Blob([calendar], {
      type: "text/calendar",
    });
    FileSaver.saveAs(resultBlob, `${eventId}.ics`);
  };

  return (
    <div className={styles.base} onClick={handleClick}>
      <Button shape="rounded">Add To Calendar</Button>
    </div>
  );
};

export default EventCalendarButton;
