import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {getMatchingValue} from '../../../../../utils';
import {
    AXES_MOMENT_DATE_FORMAT, DATASET_HELPER_TEXT, dateAccessor,
} from "../../../../../constants";
import {
    COLORS,
    COMMA_FORMATTER,
    valueAccessor
} from "../../../../../../Shared/constants";

const useStyles = makeStyles(() => ({
    line: {
        fill: 'none',
        strokeWidth: '5px',
        strokeOpacity: .14,
        stroke: COLORS.royalBlue,
        pointerEvents: 'none'
    },
    label: {
        textAnchor: 'middle',
        fill: COLORS.textBlue,
        stroke: "none",
        fontSize: '14px',
        textRendering: 'optimizeLegibility'
    },
    tooltipLabel: {
        textAnchor: 'left',
        fill: COLORS.textBlue,
        stroke: "none",
        fontSize: '16px',
        textRendering: 'optimizeLegibility',
        fontWeight: 'bold'
    },
    tooltipCumulativeLabel: {
        textAnchor: 'left',
        fill: COLORS.gray,
        stroke: "none",
        fontSize: '14px',
        textRendering: 'optimizeLegibility'
    },
    background: {
        fill: '#fff',
        stroke: 'none'
    },
    tooltipBackground: {
        fill: '#fff',
        stroke: '#000',
        strokeOpacity: .4
    }
}));

const TooltipLineAndMarker = ({data, selectedEvent, activeEvent, xScale, yScale, r, height, offset, markerFill, selectedDataset, innerWidth}) => {
    const classes = useStyles();
    const tooltipWidth = 160;
    const tooltipLabelWidth = 42;

    let x,
        y,
        dateLabel;
    if (activeEvent) {
        x = xScale(dateAccessor(activeEvent)) + xScale.bandwidth() / 2;
        y = getMatchingValue(data, dateAccessor(activeEvent));
        dateLabel = dateAccessor(activeEvent);
    } else {
        x = selectedEvent ? xScale(dateAccessor(selectedEvent)) + xScale.bandwidth() / 2 : 0;
        y = selectedEvent ? getMatchingValue(data, dateAccessor(selectedEvent)) : 0
        dateLabel = dateAccessor(selectedEvent);
    }

    // Adjust the tooltip rect based on where we are on the chart, i.e. don't allow for it getting cropped
    let xTooltipTransform = -tooltipWidth / 2;
    if (x + tooltipWidth > innerWidth) {
        xTooltipTransform -= tooltipWidth / 2;
    }
    let tooltipLabelTransform = 0;
    if (x + (tooltipLabelWidth / 2) > innerWidth) {
        tooltipLabelTransform -= tooltipLabelWidth / 2;
    }

    return (
        <g className="timeline__tooltip">
            <g className="tooltip__group" transform={`translate(${x},0)`}>
                <line y1={height} y2={-offset} className={classes.line}/>
                <g className="tooltip__rect" transform={`translate(${xTooltipTransform},${yScale(y.value) - 75})`}>
                    <rect className={classes.tooltipBackground} width={tooltipWidth} height={50}/>
                    <g className="tooltip__text" transform={`translate(5,20)`}>
                        <text className={classes.tooltipLabel} style={{fill: markerFill}}>
                            {`${COMMA_FORMATTER(y.value)} ${DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].actual}`}
                        </text>
                        <text className={classes.tooltipCumulativeLabel} dy={22}>
                            {`${COMMA_FORMATTER(y.cumulative)} ${DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].cumulative}`}
                        </text>
                    </g>
                </g>
                <rect className={classes.background} width={46} height={18} y={height + 7} x={-21}
                      transform={`translate(${tooltipLabelTransform})`}/>
                <text className={classes.label} y={height + 18}
                      transform={`translate(${tooltipLabelTransform})`}>{AXES_MOMENT_DATE_FORMAT(dateLabel)}</text>
            </g>
        </g>
    );
};

export default TooltipLineAndMarker;
