import React from "react";
import styles from "./Speakers.module.scss";
import HFormat from "components/common/HFormat";
import classNames from "classnames/bind";
import { StringifyOptions } from "querystring";
const cx = classNames.bind(styles);

type TSpeakerProps = {
  name: string;
  title?: string;
  image?: string;
  url?: StringifyOptions;
};

type TSpeakersProps = {
  authors: TSpeakerProps[];
  title?: string;
  useImg?: boolean;
  className?: string;
};

export const Speakers = ({
  authors = [],
  title = "Speakers",
  useImg = true,
  className,
}: TSpeakersProps) => (
  <div className={cx(styles.base, className)}>
    <HFormat size={3} classNames={styles.speakerHeader}>
      {title}
    </HFormat>
    <div className={styles.speakersCards}>
      {authors?.map((card, i) => (
        <div
          key={`speaker-card-${i}-${card.name}`}
          className={styles.speakerCard}
        >
          {card.image && (
            <div className={styles.portrait}>
              {useImg ? (
                <img src={card.image} alt="Speaker card" />
              ) : (
                <div style={{ backgroundImage: `url(${card.image})` }} />
              )}
            </div>
          )}
          <div>
            <HFormat size={4} noMargins>
              {card.name}
            </HFormat>
            <p>{card.title}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default Speakers;
