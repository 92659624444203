import React, { useCallback, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import Row from "./Row";
import { useDispatch, useSelector } from "react-redux";
import VisualizationToggle from "../VisualizationToggle";
import DatasetSelect from "./DatasetSelect";
import ContinuousLegend from "./ContinuousLegend";
import DiscreteLegend from "./DiscreteLegend";
import {
  COLUMNS,
  DEFAULT_MAP_DATASET,
  MAX_CELL_SIZE,
  SCALE_TYPES,
  valueAccessor,
} from "../../../constants";
import ThresholdLegend from "./ThresholdLegend";
import DownloadLink from "../DownloadLink";
import { useDimensions } from "../../../utils";
import { updateDataset } from "../../../redux/modules/global";
import { useLocation } from "react-router-dom";
import { updatedOnDateAndTime } from "data/data-viz";
import queryString from "query-string";
import { static_screenshots_path } from "util/hooks/useFetch";

const TileMap = ({
  gutter,
  cellSize,
  disableFilter,
  disableToggles,
  datasetOverride,
}) => {
  const dispatch = useDispatch();
  const [mapRef, { width, isResized }] = useDimensions({
    width: 100,
    isResized: false,
  });
  const getNewGrid = useCallback((d) => dispatch(updateDataset(d)), [dispatch]);

  // When the dataset changes from the router, update the grid and other related variables
  const datasets = useSelector((state) => state.testingTracker.datasets);
  const {
    dataset = datasetOverride ? datasetOverride : DEFAULT_MAP_DATASET,
  } = useParams();
  let selectedDataSet = datasets.find((d) => valueAccessor(d) === dataset);

  useEffect(() => {
    getNewGrid(selectedDataSet);
  }, [dataset, getNewGrid, selectedDataSet]);

  // Grab grid and domain from redux
  const grid = useSelector((state) => state.testingTracker.grid);
  const domain = useSelector((state) => state.testingTracker.domain);

  // Assign the domain if the selected data set has a continuous domain
  if (
    [SCALE_TYPES.THRESHOLD, SCALE_TYPES.QUANTILE].includes(
      selectedDataSet.scaleType
    ) &&
    !selectedDataSet.preCalculatedDomain
  ) {
    selectedDataSet.colorScale.domain([0, Math.max(...domain)]);
  }

  const getLegendType = () => {
    // Depending on the map, grab the correct legend component
    switch (selectedDataSet.scaleType) {
      case SCALE_TYPES.THRESHOLD:
        return (
          <ThresholdLegend selectedDataSet={selectedDataSet} width={325} />
        );
      case SCALE_TYPES.QUANTILE:
        return (
          <ContinuousLegend selectedDataSet={selectedDataSet} width={325} />
        );
      default:
        return <DiscreteLegend selectedDataSet={selectedDataSet} />;
    }
  };

  // Reduce gutter size for XS mobile views
  gutter = window.innerWidth < 360 ? 3 : gutter;

  // Dynamically calculate cell size
  // Max of a starting cell size and a min of 28 px
  cellSize = Math.max(
    Math.min((width - (COLUMNS - 1) * gutter) / COLUMNS, MAX_CELL_SIZE),
    28
  );

  const { search } = useLocation();
  const forExport = queryString.parse(search).export === "true";

  return (
    <div id="map" className={"page__map" + (forExport ? " for-export" : "")}>
      <div className="map" ref={mapRef}>
        <div className="filter">
          {forExport ? (
            <div className="filter__row filter__row--branding">
              <img
                src="/images/jhum-crc-logo-dark.png"
                style={{ width: "calc(100% - 3rem)" }}
                alt="Johns Hopkins University & Medicine | Coronavirus Resource Center"
              />
            </div>
          ) : (
            <Fragment />
          )}
          <div className="filter__row">
            <div className="filter__header-section">
              <div className="filter__header">{selectedDataSet.label}</div>
            </div>
          </div>
          <div className="filter__row filter__row--nav">
            <div className="download__wrapper">
              {!disableFilter && <DatasetSelect />}
              <DownloadLink
                href={`${static_screenshots_path}map-${selectedDataSet.id}.png`}
              />
            </div>
            {!disableToggles && <VisualizationToggle />}
          </div>
          <div className="filter__legend">{getLegendType()}</div>
        </div>

        {isResized && (
          <div className="map__inner">
            {grid.map((row, i) => {
              return (
                <Row
                  key={`row-for-${i}`}
                  i={i}
                  cellSize={cellSize}
                  gutter={gutter}
                  row={row}
                  selectedDataSet={selectedDataSet}
                />
              );
            })}
          </div>
        )}
        {forExport ? (
          <div className="export-view__footer">
            <br />
            <p>
              This graphic was last updated on <br />
              {updatedOnDateAndTime()}.
            </p>
            <p className="export-view__attribution">
              <a href="/" alt="export">
                coronavirus.jhu.edu
              </a>
            </p>
          </div>
        ) : (
          <Fragment />
        )}
      </div>
    </div>
  );
};
TileMap.defaultProps = {
  cellSize: MAX_CELL_SIZE,
  gutter: 5,
};
export default TileMap;
