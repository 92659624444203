import React from 'react';
// import moment from "moment";
import {makeStyles} from '@material-ui/core/styles';
import {
    DATASET_HELPER_TEXT
} from "../../constants";
import {
    COLORS,
    COMMA_FORMATTER,
} from "components/content/DataCenter/charts/Shared/constants";

const useStyles = makeStyles(() => ({
    line: {
        fill: 'none',
        strokeWidth: '5px',
        strokeOpacity: .14,
        stroke: COLORS.royalBlue,
        pointerEvents: 'none'
    },
    label: {
        textAnchor: 'middle',
        fill: COLORS.textBlue,
        stroke: "none",
        fontSize: '14px',
        textRendering: 'optimizeLegibility'
    },
    tooltipLabel: {
        textAnchor: 'left',
        fill: COLORS.textBlue,
        stroke: "none",
        fontSize: '16px',
        textRendering: 'optimizeLegibility',
        fontWeight: 'bold'
    },
    tooltipCumulativeLabel: {
        textAnchor: 'left',
        fill: COLORS.gray,
        stroke: "none",
        fontSize: '14px',
        textRendering: 'optimizeLegibility'
    },
    background: {
        fill: '#fff',
        stroke: 'none'
    },
    tooltipBackground: {
        fill: '#fff',
        stroke: '#000',
        strokeOpacity: .4
    }
}));

const TooltipLine = ({activeEvent, xScale, yScale, yData, height, innerWidth}) => {
    const classes = useStyles();
    const tooltipWidth = 285;
    const tooltipLabelWidth = 42;
    let x = 0,
      y = yScale(0.5),
      dateLabel = "",
      yValues = [];

    if (activeEvent) {
        x = xScale(activeEvent);
        dateLabel = activeEvent.format('MMM D, YYYY');
        yData.forEach(d => {
          let point = d.data.find(e => e.date === activeEvent.format("YYYY-MM-DD"))
          if (point && isFinite(point.averageTT)){
            let ttText = ""
            if (d.key === "test-positivity") {
              ttText = `${COMMA_FORMATTER(point.averageTT)}${DATASET_HELPER_TEXT[d.key].average}`
            } else {
              ttText = `${COMMA_FORMATTER(point.averageTT)} ${d.labelText}${DATASET_HELPER_TEXT[d.key].average}`
            }
            yValues.push(
              <text className={classes.tooltipLabel} style={{fill:d.colors.line}}>{ttText}</text>
            )
          }
        })
    } else {
        return null;
    }

    // Adjust the tooltip rect based on where we are on the chart, i.e. don't allow for it getting cropped
    let xTooltipTransform = x-(tooltipWidth / 2);
    let tooltipLabelTransform = tooltipWidth/2;
    if (x + tooltipWidth > innerWidth) {
        xTooltipTransform -= tooltipWidth / 2;
        tooltipLabelTransform += tooltipWidth/2;
    } else if (x - tooltipWidth/2 < 0) {
      xTooltipTransform += tooltipWidth/2;
      tooltipLabelTransform -= tooltipWidth/2;
    }
    if (x + (tooltipLabelWidth) > innerWidth) {
      tooltipLabelTransform -= tooltipLabelWidth
    }

    return (
      <g className="timeline__tooltip">
        <line y1={yScale(1)} y2={yScale(0)} x1={x} x2={x} className={classes.line}/>
        <g className="tooltip__group" transform={`translate(${xTooltipTransform},0)`}>
          <g className="tooltip__rect" transform={`translate(0,${y})`}>
            <rect className={classes.tooltipBackground} width={tooltipWidth} height={10 + (20*yValues.length)}/>
            <g className="tooltip__text" transform={`translate(5,20)`}>
              {yValues.reverse().map((text,i) => {
                return (
                  <g key={`text-line-${i}`} transform={`translate(0,${20*i})`}>
                    {text}
                  </g>
                )
              })}
            </g>
          </g>
          <rect className={classes.background} width={46} height={18} y={7} x={-21}
            transform={`translate(${tooltipLabelTransform},0)`}/>
          <text className={classes.label} y={18} transform={`translate(${tooltipLabelTransform},0)`}>{dateLabel}</text>
        </g>
      </g>
    );
};

export default TooltipLine;
