import React, { FC } from "react";
import styles from "./Option.module.scss";
import cx from "classnames";
import { TFilterOption } from "util/hooks/useSingleSelect";
import { Link } from "react-router-dom";
import TooltipIcon from "components/common/TooltipBadge/TooltipIcon";

export type OptionProps = {
  option: TFilterOption;
  onClick: (option: TFilterOption) => void;
  isActive?: boolean;
  children?: any;
  context?: "tabbar" | "filterbar";
  defaultMetric?: boolean;
  linkText?: string;
  className?: string;
};

const Option: FC<OptionProps> = ({
  option,
  isActive,
  onClick,
  context,
  linkText = "Learn more...",
  className,
}) => {
  const handleOnClick = () => onClick && onClick(option);
  const { label, data } = option;
  return (
    <div
      className={cx(
        styles.base,
        isActive ? styles.active : "",
        styles[context],
        className
      )}
      onClick={handleOnClick}
    >
      {data?.icon && (
        <div className={styles.icon}>
          <TooltipIcon type={data?.icon} />
        </div>
      )}
      <div className={styles.textBlock}>
        <div className={styles.label}>
          {label?.includes("\n")
            ? label.split("\n").map((section, i) => {
                if (!section) return null;
                return <div key={`${section}${i}`}>{section}</div>;
              })
            : label}
        </div>
        {data?.defaultMetric && (
          <div className={styles.defaultMetric}>{"CRC'S DEFAULT METRIC"}</div>
        )}
        {data?.content && <div className={styles.content}>{data.content}</div>}
        {data?.href && (
          <Link className={styles.link} to={data.href}>
            {linkText}
          </Link>
        )}
      </div>
    </div>
  );
};

export default Option;
