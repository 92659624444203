import React, { useRef, useLayoutEffect } from "react";
import cx from "classnames";
import { entries } from "d3-collection";
import { scaleOrdinal } from "d3-scale";
import { select } from "d3-selection";
import { arc, pie } from "d3-shape";
import * as geo from "d3-geo";
import "d3-svg-annotation";

import styles from "./DonutChart.module.scss";

const d3 = {
  arc,
  entries,
  pie,
  scaleOrdinal,
  select,
  ...geo,
};

export default function ({ width, height, dataSrc, className }) {
  const refContainer = useRef(null);

  useLayoutEffect(() => {
    let svg, mounted = true;
    (async () => {
      const colorData = await fetch(dataSrc).then((res) => res.json());

      if (!mounted) return;

      const pieData = colorData.pie;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      const radius = (Math.min(width, height) / 2) * 0.7;

      const container = d3
        .select(refContainer.current)
        .attr("class", styles.container);

      // append the svg object to the div called 'my_dataviz'
      const svg = container
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", `0 0 ${width} ${height}`);
      const g = svg
        .append("g")
        .attr("transform", "translate(" + Math.round(width / 2) + "," + Math.round(height / 2) + ")");
      const tooltipDef = svg
        .append("def")
        .append("g")
        .attr("id", "tooltip")
        .attr("class", styles.tooltip);
      tooltipDef
        .append("rect")
        .attr("transform", "rotate(45, 20, 0)")
        .attr("width", 20)
        .attr("height", 20)
        .attr("x", 10)
        .attr("y", -10)
        .attr("fill", "#f5f5f5");
      tooltipDef
        .append("rect")
        .attr("width", 60)
        .attr("height", 50)
        .attr("x", 15)
        .attr("y", -25)
        .attr("fill", "#f5f5f5");

      // Create dummy data
      const keys = Object.keys(pieData);
      const data = keys.reduce((prev, current) => {
        prev[current] = pieData[current].percent;
        return prev;
      }, {});

      // Compute the position of each group on the pie:
      const pie = d3.pie().value(function (d) {
        return d.value;
      });
      const dataReady = pie(d3.entries(data));

      // HACKY
      const tooltipLookup = [];

      const arc = d3
        .arc()
        .innerRadius(33) // This is the size of the donut hole
        .outerRadius(radius);

      // Each part of the pie is a path that we build using the arc function.
      const $g = g.selectAll("whatever")
        .data(dataReady)
        .enter();
      $g
        .append("path")
        .attr("d", arc)
        .attr("fill", function (d) {
          return pieData[d.data.key].color;
        })
        .attr("stroke", "#FFF")
        .style("stroke-width", "1px")
        .on("mouseover", function (d) {
          tooltipLookup
            .forEach(tt => {
              if (tt.d === d) {
                tt.elm.style.opacity = "1";
              }
            });
          d3.select(this)
            .transition()
            .duration(70)
            .style("opacity", 0.7);
        })
        .on("mouseout", function (d) {
          tooltipLookup
            .forEach(tt => {
              if (tt.d === d) {
                tt.elm.style.opacity = "0";
              }
            });
          d3.select(this)
            .transition()
            .duration(200)
            .style("opacity", 1);
        });
      $g
        .append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "11px")
        .attr("pointer-events", "none")
        .attr("transform", function (d) {
          let [x, y] = arc.centroid(d);
          const data = pieData[d.data.key];
          if (data.percent < 5) {
            x *= 1.85;
            y *= 1.85;
          }
          return "translate(" + x + ", " + y + ")";
        })
        .text(function (d) {
          return d.data.key;
        });
      $g
        .append("text")
        .attr("text-anchor", "middle")
        .attr("font-size", "12px")
        .attr("pointer-events", "none")
        .attr("transform", function (d) {
          let [x, y] = arc.centroid(d);
          const data = pieData[d.data.key];
          if (data.percent < 5) {
            x *= 1.85;
            y *= 1.85;
          }
          return "translate(" + x + ", " + (y + 14) + ")";
        })
        .text(function (d) {
          const data = pieData[d.data.key];
          return Math.round(data.percent * 10) / 10 + "%";
        });
      $g
        .append("use")
        .attr("xlink:href", "#tooltip")
        .attr("class", styles.tooltip)
        .style("opacity", 0)
        .attr("transform", function (d) {
          tooltipLookup.push({d, elm: this});
          let [x, y] = arc.centroid(d);
          const data = pieData[d.data.key];
          if (data.percent >= 5) {
            x += 15;
          }
          return "translate(" + x + ", " + y + ")";
        })
      $g
        .append("text")
        .attr("class", styles.tooltip)
        .style("opacity", 0)
        .attr("transform", function (d) {
          tooltipLookup.push({d, elm: this});
          let [x, y] = arc.centroid(d);
          const data = pieData[d.data.key];
          if (data.percent >= 5) {
            x += 15;
          }
          return "translate(" + x + ", " + y + ")";
        })
        .append("tspan")
        .attr("x", "20")
        .attr("dy", "-0.9em")
        .text(function (d) {
          return d.data.key;
        })
        .select(function () { return this.parentNode; })
        .append("tspan")
        .attr("x", "20")
        .attr("dy", "1.2em")
        .text(function (d) {
          return pieData[d.data.key].num;
        })
        .select(function () { return this.parentNode; })
        .append("tspan")
        .attr("x", "20")
        .attr("dy", "1.2em")
        .text(function (d) {
          const data = pieData[d.data.key];
          return  Math.round(data.percent * 10) / 10 + "%";
        });
    })();
    return () => {
      svg && svg.remove();
      mounted = false;
    };
  }, [width, height, dataSrc]);

  return <div ref={refContainer}
    className={cx(styles.container, className)}
  />;
}
