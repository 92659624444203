import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ArticleGridLayoutEngine,
  ArticleGridLayoutTemplate,
} from "components/layout/ArticleGrid";
import classNames from "classnames/bind";
import styles from "./PDIFeed.module.scss";
import { getArticleCardsByVerticals } from "../../../layout/ArticleCard/utils";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

const PDIFeed = ({
  featureType = "default",
  limit = 4,
  articles,
  page,
  hideNoContentsMessage = false,
  blade,
  pageSize,
  classNames,
}) => {
  const [isSingleColumn, setIsSingleColumn] = useState(false);
  const featureLimit = isSingleColumn ? 1 : 4; //Infinity;

  //Fetch articles by vertical and remove date from cards
  const contents = (articles
    ? articles
    : getArticleCardsByVerticals(["pandemic-data-initiative"], {
        overrides: {},
        limit: Infinity,
        authorsInBody: false,
        sectionToCaption: true,
      })
  ).map((card) => ({
    ...card,
    date: "",
  }));

  let featureCards = [];
  if (featureType) {
    if (featureType === "default") {
      featureCards = contents.slice(0, featureLimit);
    } else {
      featureCards = contents.slice(0, featureType === "single" ? 1 : 2);
    }
  }

  // Pagination State:
  const [currentPage, setCurrentPage] = useState(page);
  const incrementPage = () => setCurrentPage(currentPage + 1);
  const otherCards = contents.slice(featureCards.length);
  const visibleContents = otherCards.slice(0, limit * currentPage);
  const isMore = otherCards.length - visibleContents.length > 0;

  return (
    <div className={cx("base", classNames)}>
      <div className={cx("content")}>
        {!contents.length && !hideNoContentsMessage && (
          <p>There is nothing to display.</p>
        )}
        {!!featureType && (
          <div className={styles.featuredContainer}>
            {featureType === "default" && (
              <ArticleGridLayoutEngine
                articles={featureCards}
                columnLayout={[[9, 3], [8, 4], [6, 6], [12]]}
                gap={{ column: 32, row: 0 }}
                onLayoutChange={(layout) =>
                  setIsSingleColumn(layout.length === 1)
                }
              >
                <ArticleGridLayoutTemplate.SingleCard
                  articleCardProps={{
                    major: true,
                    containImage: true,
                  }}
                />
                <ArticleGridLayoutTemplate.CardStack
                  withDividers
                  limit={featureLimit}
                  articleCardProps={{
                    major: false,
                    imageSrc: null,
                  }}
                />
              </ArticleGridLayoutEngine>
            )}
            {(featureType === "dual" || featureType === "single") && (
              <ArticleGridLayoutEngine
                articles={featureCards}
                columnLayout={
                  featureType === "dual" && contents.length > 1
                    ? [[6, 6]]
                    : [[12]]
                }
                gap={32}
              >
                {new Array(featureCards.length)
                  .fill(featureCards)
                  .map((_, i) => {
                    return (
                      <ArticleGridLayoutTemplate.SingleCard
                        key={i}
                        href="not a real URL"
                        articleCardProps={{
                          containImage: true,
                          major: true,
                          vertical: true,
                        }}
                      />
                    );
                  })}
              </ArticleGridLayoutEngine>
            )}
          </div>
        )}
        <ArticleGridLayoutEngine
          articles={visibleContents}
          columnLayout="flexible"
          gap={32}
        >
          {new Array(visibleContents.length)
            .fill(visibleContents)
            .map((_, i) => {
              return (
                <ArticleGridLayoutTemplate.SingleCard
                  key={i}
                  containImage={true}
                  href="not a real URL"
                  articleCardProps={{
                    major: false,
                    vertical: true,
                  }}
                />
              );
            })}
        </ArticleGridLayoutEngine>
        {!blade && isMore && (
          <div className={cx("grid-footer")}>
            <Button
              icon={"down"}
              shape="square"
              buttonStyle="bordered"
              onClick={incrementPage}
            >
              Load More
            </Button>
          </div>
        )}
        {/* {blade && (
          <Button
            buttonStyle="bordered"
            icon="right"
            href={topCard[0].href}
            style={{ paddingTop: "2px", marginTop: "1rem" }}
          >
            View all
          </Button>
        )} */}
      </div>
    </div>
  );
};

PDIFeed.propTypes = {
  featureType: PropTypes.oneOf(["default", "single", "dual", null]),
  page: PropTypes.number,
  pageSize: PropTypes.number,
  classNames: PropTypes.string,
};

PDIFeed.defaultProps = {
  limit: 6,
  page: 1,
  pageSize: 12,
};

export default PDIFeed;
