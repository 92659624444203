import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./Footer.module.scss";
import { getPage } from "data/util";
import { Link, NavLink, withRouter } from "react-router-dom";
import CVLogo from "images/jhu-logo-white-horizontal.svg";
import FooterList from "components/layout/shell/Footer/FooterList";
import data from "../../../../data/navigation";
import { connect } from "react-redux";
import ColumnLayout from "components/layout/ColumnLayout";
import { suppressedFooterRoutes } from "data/navigation";

const Footer = ({ location }) => {
  const page = getPage("/");

  const suppressed =
    !!suppressedFooterRoutes.find((route) =>
      route.exact
        ? location.pathname === route.path
        : location.pathname.indexOf(route.path) !== -1
    ) ||
    (location?.search && location.search.split("?").includes("embed"));

  const bloombergLink = "https://www.bloomberg.org/";
  const snfLink = "https://www.snf.org/";
  const newTabHref = {
    target: "_blank",
    rel: "noopener noreferrer",
  };
  return (
    !suppressed && (
      <footer className={styles.base}>
        <div className={styles.container}>
          <div className={styles.footerContent}>
            <div className={styles.description}>
              <Link to="/"  alt="footer-logo" className={styles.banner}>
                <img
                  className={styles.brand}
                  src={CVLogo}
                  alt="Johns Hopkins Coronavirus Resource Center"
                  loading="lazy"
                />
              </Link>
              <div>
                <ReactMarkdown
                  className={styles.content}
                  source={page.content_blocks["introduction"].content}
                />
              </div>
              <div className={styles.donorsSection}>
                <p className={styles.donorText}>
                  This Project is supported by{" "}
                  <a href={bloombergLink} {...newTabHref}>
                    Bloomberg Philanthropies
                  </a>{" "}
                  and the{" "}
                  <a href={snfLink} {...newTabHref}>
                    Stavros Niarchos Foundation (SNF)
                  </a>
                  .
                </p>

                <div className={styles.donorImage}>
                  <a href={bloombergLink} {...newTabHref}>
                    <img
                      className={styles.brand}
                      src={"/images/donors/bp-white-logo-footer.png"}
                      alt="Bloomberg Philanthropies"
                      loading="lazy"
                    />
                  </a>
                  <a href={snfLink} {...newTabHref}>
                    <img
                      className={styles.brand}
                      src={"/images/donors/snf-white-logo-footer.png"}
                      alt="Stavros Niarchos Foundation"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            </div>
            <ColumnLayout
              layout={"quartet"}
              gap={{ row: 64, column: 32 }}
              dividers={{ row: "WHEN_FULL_WIDTH" }}
            >
              {data.slice(1).map((section) => {
                return (
                  <div key={section.id}>
                    <FooterList
                      key={section.id}
                      classNames={styles.footerList}
                      title={section.name}
                      to={section.to ? section.to : "/#" + section.id}
                    >
                      {section.sub && section.sub.length ? (
                        section.sub.map((subSection) => {
                          return (
                            <li key={subSection.id}>
                              <NavLink
                                exact={subSection.exact}
                                to={subSection.to || ""}
                              >
                                {subSection.name}
                              </NavLink>
                            </li>
                          );
                        })
                      ) : (
                        <React.Fragment />
                      )}
                    </FooterList>
                  </div>
                );
              })}
            </ColumnLayout>
          </div>

          <div>
            <div className={styles.infoLinks}>
              <ul>
                <li>
                  <a
                    href="https://it.johnshopkins.edu/policies/privacystatement"
                    {...newTabHref}
                  >
                    Privacy Statement
                  </a>
                </li>
              </ul>
            </div>
            <p className={styles.info}>
              JHU.edu Copyright © {new Date().getFullYear()} by Johns Hopkins
              University & Medicine. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    )
  );
};

export default connect(null)(withRouter(Footer));
