import React, { FC } from "react";
import styles from "./SelectButton.module.scss";
import cx from "classnames";

type TSelectButtonProps = {
  placeholder?: string;
  onClicked?: () => void;
  isOpen?: boolean;
  children?: any;
  className?: string;
};

export const SelectButton: FC<TSelectButtonProps> = ({
  placeholder = "",
  isOpen,
  onClicked,
  children,
  className,
}) => {
  const handleOnClick = () => onClicked?.();
  return (
    <div className={cx(styles.base, className)} onClick={handleOnClick}>
      <div className={styles.selectDisplay}>{placeholder}</div>
      {!!children ? (
        children
      ) : (
        <div className={cx(styles.triangleIcon, isOpen && styles.open)} />
      )}
    </div>
  );
};

export default SelectButton;
