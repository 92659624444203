// /* global UstreamEmbed */
import React, { useEffect, useState, useRef } from "react";
import { useParams, withRouter } from "react-router-dom";
import * as GTM from "util/gtm";
import styles from "./Videos.module.scss";
import { getPage, getFilteredContent } from "data/util";
import Page from "components/layout/Page";
import HFormat from "components/common/HFormat";
import Share from "components/common/Share";
import ReactMarkdown from "react-markdown";
import Button from "components/common/Button";
import VideoFeed from "../VideoFeed";
import ColumnLayout from "components/layout/ColumnLayout";
import RenderVideoFrame from "../RenderVideoFrame";

const InteriorHeader = ({ meta }) => {
  const { title, description } = meta;
  return (
    <div className={styles.container}>
      <ColumnLayout
        layout={[[5, 7], [12]]}
        gap={{ row: 64, column: 32 }}
        dividers={{ row: "WHEN_FULL_WIDTH" }}
      >
        <div className={styles.contentLeft}>
          <HFormat size={1}>{title}</HFormat>
          <Share label="Share" />
        </div>
        <div className={styles.contentRight}>
          <ReactMarkdown className={styles.introText} source={description} />
        </div>
      </ColumnLayout>
    </div>
  );
};
/**
 * ## Notes
 * There are a couple of different npm packages, and one API that IBM's docs reference
 *
 * - ustream-embedapi - controls embed, but is 4 years since updated. Doesn't have a playlist API.
 * - ustream-sdk - recently updated, but looks like it requires auth tokens and isn't really for the embed side.
 *   has a playlist API.
 *
 * I didn't use either of these, instead opting for the IBM code (which is wired in in /public/index.html)
 *
 * IBM's example with our own CMS managed list of videos is probably the way to go:
 * https://developers.video.ibm.com/player-api/player-multiview.html
 *
 * General Player API:
 * https://developers.video.ibm.com/player-api/player.html
 */

export const videoLinkType = ["IBM", "youTube", "vimeo"];
export const videoLinkPartial = {
  IBM: ["video.ibm.com"],
  youTube: ["youtube.com", "youtu.be"],
  vimeo: ["vimeo"],
};
export const getVideoLinkType = (currentLink) => {
  for (let i = 0; i < videoLinkType.length; i++) {
    if (
      currentLink &&
      !!videoLinkPartial[videoLinkType[i]].find(
        (s) => currentLink.indexOf(s) !== -1
      )
    ) {
      return i;
    }
  }
};

export const VideoDetailsButton = ({ onClick, show }) => (
  <Button
    shape="square"
    buttonStyle="bordered"
    icon={!show? "down" : "x"}
    onClick={onClick}
  >
    {!show ? "More Details" : "Close"}
  </Button>
);


// Only use video objects that are off-site or have a value for videoUrl

const Videos = ({ location }) => {
  const baseURL = "/live"
  const { videoId } = useParams();
  const iframeContainer = useRef(null);

  const baseRouteData = getPage(baseURL)
  const contents = getFilteredContent({types: ['webcasts']});
  const filterCurrent = contents.filter((content) => content.slug === videoId);
  const activeVideo = filterCurrent[0] || contents[0];

  const currentLink = activeVideo.videoUrl || activeVideo.image;
  const videoLinkType = !!activeVideo.videoUrl ? getVideoLinkType(currentLink) : 0;
  const [showDetails, setShowDetails] = useState(false);
  const [mediaHeight, setMediaHeight] = useState(354);
  const [aVideo] = useState(activeVideo);

  const scrollEffect = (topPos) => {
    if (!!window.scrollTo) {
      window.scrollTo({ top: topPos, left: 0, behavior: "smooth" });
    };
  };
  
  useEffect(() => {
    scrollEffect(
      iframeContainer && iframeContainer.current
        ? iframeContainer.current.offsetTop * 2
        : 0
    );
    GTM.clear();
    const handleResize = () => {
      if (iframeContainer && iframeContainer.current) {
        const newHeight = iframeContainer.current.clientWidth / 1.78;
        setMediaHeight(newHeight);
      };
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [videoLinkType, aVideo]);

  const containerVideoHeightAdjust = {
    height: `${mediaHeight}px`,
  };

  const getPageData = () => (!!videoId && activeVideo)? {...baseRouteData, meta: {title: activeVideo.title}} : baseRouteData ;

  return (
    <Page
      page={getPageData()}
      interiorHeader={<InteriorHeader {...getPageData()} />}
    >
      <div className={styles.container}>
        {!!videoId && (
          <>
          <div className={styles.contentContainer}>
            <div
              ref={iframeContainer}
              className={styles.iframeContainer}
              style={containerVideoHeightAdjust}
            >
              <RenderVideoFrame
                mediaHeight={mediaHeight}
                videoLinkType={videoLinkType}
                videoUrl={currentLink || "https://video.ibm.com/combined-embed/22283603"}
              />
            </div>
            <div>
              <HFormat size={3}>{activeVideo.title}</HFormat>
              {!!activeVideo.description && (
                <ReactMarkdown
                  className={styles.embedDescription}
                  source={activeVideo.description}
                />
              )}
            </div>
          </div>
          <div>
            {showDetails && (
              <ReactMarkdown
                className={styles.embedDescription}
                source={activeVideo.content}
              />
            )}
            <br />
            {!!activeVideo.content && (
              <VideoDetailsButton onClick={() => setShowDetails(!showDetails)} show={showDetails}/>
            )}
          </div>
          <br/>
          <br/>
          </>
        )}
        <VideoFeed 
          classNames={styles.feedContainer}
          currentVideoId={videoId} 
          limit={8}/>
      </div>
    </Page>
  );
};

export default withRouter(Videos);