import React, { createContext, useContext, useEffect, useReducer } from "react";
import { request } from "redux/util/fetch";

export const static_asset_path =
  "https://jhucoronavirus.azureedge.net/static-assets/";

export const static_screenshots_path = static_asset_path + "screenshots/";
export const jhucoronavirus_asset_path =
  "https://jhucoronavirus.azureedge.net/jhucoronavirus/assets/";

/**
 * [key]: URL
 */
const URLs = {
  vaccine_story_all_state_timelines:
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/vaccine_story_all_state_timelines.dev.json",
  vaccine_story_all_state_bar:
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/vaccine_story_all_state_bar.dev.json",
  vaccine_story_selected_state_area:
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/vaccine_story_selected_state_area.dev.json",
  state_vaccination_rates:
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/state_vaccination_rates.dev.json",
  vaccine_data_comparisons:
    "https://jhucoronavirus.azureedge.net/jhucoronavirus/vaccine_data_comparisons.json",
  global_vaccines:
    "https://jhucoronavirus.azureedge.net/api/v3/vaccines/global.json",
  global_variants:
    "https://jhucoronavirus.azureedge.net/api/v2/variants/variants.json",
  geo_world: "/datasets/geo/worldmap.geojson",
  geo_us_states: "/datasets/geo/us-states-geo.json",
  tile_map: "/datasets/geo/Tile-Grid-Map-Full.json",
};

const CacheContext = createContext({});

const reducer = (state, action) => {
  switch (action.type) {
    case "fetch":
      return {
        isLoading: true,
        error: null,
      };
    case "loaded":
      return {
        isLoading: false,
        error: null,
      };
    case "error":
      return {
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
const initial = {
  isLoading: false,
  data: null,
  error: null,
};

export const useFetch = (resource) => {
  let [{ isLoading, error }, dispatch] = useReducer(reducer, initial);
  let data = null;
  const store = useContext(CacheContext);

  const url = URLs[resource] || resource;
  // In case the resource changes without a full lifecycle,
  // reset the state to loading from previous values.
  if (!isLoading && !store[url] && !error) {
    isLoading = true;
  }

  // If we have data cached, just go ahead with it
  if (store[url]) {
    isLoading = false;
    data = store[url];
    error = null;
  }

  useEffect(() => {
    if (store[url]) return;
    (async () => {
      try {
        dispatch({ type: "fetch" });
        const data = await request(url);
        store[url] = data;
        dispatch({ type: "loaded" });
      } catch (error) {
        dispatch({ type: "error", error });
      }
    })();
  }, [url, store]);

  return [data, isLoading, error];
};

const cache = {};
export const FetchProvider = ({ children }) => (
  <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>
);
