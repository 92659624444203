import React, { useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import { line as d3_line } from "d3-shape";
import { scaleLinear, scaleTime } from "d3-scale";
import { max, min } from "d3-array";
import { axisBottom, axisRight, axisLeft } from "d3-axis";
import { select, event as d3event } from "d3-selection";
import moment from "moment";
import { timeFormat } from "d3-time-format";
import { format } from "d3-format";
import { brushX } from "d3-brush";
import { zoom as d3zoom, zoomIdentity } from "d3-zoom";
import styles from "./DualAxis.module.scss";

const DualAxis = ({ data, width, dataSelection }) => {
  console.log(dataSelection, data);
  const ref = useRef(null);
  const height = 500;
  const varMap = {
    "7-day": {
      positivity: "7-day_positivity",
      "new daily total tests": "raw_tests",
      "new daily total tests per 100k": "100k_tests",
      "new daily positives": "raw_positives",
      "new daily positives per 100k": "100k_positives",
      "7-day avg new daily total tests": null,
      "7-day avg new daily total tests per 100k": null,
    },
    default: {
      positivity: "default_7-day_positivity",
      "new daily total tests": "default_raw_tests",
      "new daily total tests per 100k": "default_100k_tests",
      "new daily positives": "default_raw_positives",
      "new daily positives per 100k": "default_100k_positives",
      "7-day avg new daily total tests": null,
      "7-day avg new daily total tests per 100k": null,
    },
    tests: {
      positivity: null,
      "new daily total tests": "raw_tests",
      "new daily total tests per 100k": "100k_tests",
      "new daily positives": null,
      "new daily positives per 100k": null,
      "7-day avg new daily total tests": "7-day_tests",
      "7-day avg new daily total tests per 100k": "7-day_100k_tests",
    },
  };

  useEffect(() => {
    const svg = select(ref.current);
    const margin = { top: 40, right: 50, bottom: 130, left: 45 };
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const xBandwidth = innerWidth / (data.series.length+1)
    const yLine = scaleLinear()
      // .domain([0,1])
      .range([innerHeight, 0]);
    if (varMap[dataSelection]["positivity"] !== null) {
      yLine.domain([0, 1]);
    } else {
      yLine.domain([
        0,
        max(
          data.series.map(
            (d) => d[varMap[dataSelection]["7-day avg new daily total tests"]]
          )
        ),
      ]);
    }
    const yBar = scaleLinear()
      .range([innerHeight, 0])
      .domain([
        0,
        max(
          data.series.map(
            (d) => d[varMap[dataSelection]["new daily total tests"]]
          )
        ),
      ]);
    const timeExtent = [
      min(data.series.map((d) => moment(d.date))).format('YYYY-MM-DD'),
      max(data.series.map((d) => moment(d.date).add(1, 'days'))).format('YYYY-MM-DD')
    ]
    const x = scaleTime()
      .range([0, innerWidth])
      .domain(timeExtent.map(d => moment(d)));
    const lineGenerator = d3_line()
      .x((d) => x(moment(d.date))+(xBandwidth))
      .y((d) =>
        yLine(
          d[
            varMap[dataSelection]["positivity"]
              ? varMap[dataSelection]["positivity"]
              : varMap[dataSelection]["7-day avg new daily total tests"]
          ]
        )
      );
    const defs = svg.append("defs");
    defs
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("width", innerWidth + 1)
      .attr("height", innerHeight + 1);
    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    const focus = g.append("g").style("clip-path", "url(#clip)");

    const xAxis = g
      .append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(axisBottom(x));
    const yBarAxis = g
      .append("g")
      .attr("class", "barAxis")
      .call(axisLeft(yBar).ticks(10, ".0s"));
    const yLineAxis = g
      .append("g")
      .attr("class", "lineAxis")
      .attr("transform", `translate(${innerWidth},0)`);
    const orange = "#FF741E";
    const blue = "#002c72";

    if (varMap[dataSelection]["positivity"] !== null) {
      yLineAxis.call(axisRight(yLine).ticks(10, ".0p"));
      yLineAxis.select(".domain").attr("stroke", blue);
      yLineAxis.selectAll("line").attr("stroke", blue);
      yLineAxis.selectAll("text").attr("fill", blue);
      yBarAxis.select(".domain").attr("stroke", orange);
      yBarAxis.selectAll("line").attr("stroke", orange);
      yBarAxis.selectAll("text").attr("fill", orange);

      yLineAxis
        .append("text")
        .text(
          varMap[dataSelection]["positivity"]
            ? "7-day Positivity"
            : "7-day Average New Total Tests"
        )
        .style("text-anchor", "middle")
        .attr("fill", blue)
        .attr(
          "transform",
          `translate(${margin.right - 10},${innerHeight / 2}) rotate(90)`
        )
        .style("font-size", "130%")
        .style("font-weight", "600");
    }

    yBarAxis
      .append("text")
      .text("Number of Tests")
      .style("text-anchor", "middle")
      .attr("fill", orange)
      .attr(
        "transform",
        `translate(${-margin.left + 10},${innerHeight / 2}) rotate(270)`
      )
      .style("font-size", "130%")
      .style("font-weight", "600");

    const testBars = focus
      .append("g")
      .selectAll(".testbars")
      .data(data.series)
      .join("rect")
      .attr("x", (d) => x(moment(d.date))-(xBandwidth))
      .attr("y", (d) => yBar(d[varMap[dataSelection]["new daily total tests"]]))
      .attr("width", innerWidth / (data.series.length+1))
      .attr(
        "height",
        (d) =>
          innerHeight - yBar(d[varMap[dataSelection]["new daily total tests"]])
      )
      .attr("fill", orange)
      .attr("fill-opacity", 0.4);

    const posBars = focus
      .append("g")
      .selectAll(".posBars")
      .data(data.series)
      .join("rect")
      .attr("x", (d) => x(moment(d.date))-(xBandwidth))
      .attr("y", (d) => yBar(d[varMap[dataSelection]["new daily positives"]]))
      .attr("width", innerWidth / (data.series.length+1))
      .attr(
        "height",
        (d) =>
          innerHeight - yBar(d[varMap[dataSelection]["new daily positives"]])
      )
      .attr("fill", orange)
      .attr("fill-opacity", 0.8);

    const line = focus
      .append("path")
      .attr("d", lineGenerator(data.series))
      .style("fill", "none")
      .style("stroke", blue)
      .style("stroke-width", 2);

    const tooltip = focus
      .append("foreignObject")
      .attr("x", 40)
      .attr("width", 250)
      .attr("height", 130)
      .attr("class", styles.tooltip);

    const hoverBars = focus
      .append("g")
      .selectAll(".hoverBars")
      .data(data.series)
      .join("rect")
      .attr("x", (d) => x(moment(d.date))-(xBandwidth))
      .attr("y", 0)
      .attr("width", innerWidth / (data.series.length+1))
      .attr("height", innerHeight)
      .attr("fill", "none")
      .attr("pointer-events", "all")
      .on("mouseenter", (d) => {
        let ttX =
          x(moment(d.date)) + 130 > innerWidth
            ? x(moment(d.date)) - 250 - 40
            : x(moment(d.date)) - 130 < 0
            ? x(moment(d.date)) - 40
            : x(moment(d.date)) - 130 - 40;
        tooltip.attr("transform", `translate(${ttX}, ${innerHeight / 2})`);
        tooltip.transition().duration(300).style("opacity", 1);
        let content = Object.entries(varMap[dataSelection])
          .filter((p) => p[1])
          .map((p) => {
            return (
              <p className={styles.tooltipText}>
                {p[0]}:{" "}
                {p[0] === "positivity"
                  ? format(".3p")(d[p[1]])
                  : format(",.0f")(d[p[1]])}{" "}
              </p>
            );
          });
        tooltip.html(
          `<h5 class="${styles.tooltipH5}">${moment(d.date).format(
            "MMM D, YYYY"
          )}</h5>${ReactDOMServer.renderToStaticMarkup(content)}`
        );
      })
      .on("mouseleave", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });
    const legend = svg.append("g");
    legend
      .append("rect")
      .attr("height", margin.top - 5)
      .attr("width", width)
      .attr("fill", "#fff");
    const legElements = legend
      .append("g")
      .attr("transform", `translate(${innerWidth / 2 - 100})`);
    legElements
      .append("line")
      .attr("x1", 0)
      .attr("x2", 15)
      .attr("y1", margin.top / 2 - 4)
      .attr("y2", margin.top / 2 - 4)
      .style("stroke", blue)
      .style("stroke-width", 2);
    legElements
      .append("text")
      .text(
        varMap[dataSelection]["positivity"]
          ? "7-day Positivity"
          : "7-day Average New Total Tests"
      )
      .attr("x", 20)
      .attr("y", margin.top / 2)
      .style("font-size", "80%");
    if (varMap[dataSelection]["new daily positives"]) {
      legElements
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("x", 15 + 20 + 80)
        .attr("y", 8)
        .attr("fill", orange)
        .attr("fill-opacity", 0.4);
      legElements
        .append("text")
        .text("Total Tests")
        .attr("x", 15 + 20 + 80 + 20)
        .attr("y", margin.top / 2)
        .style("font-size", "80%");
      legElements
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("x", 15 + 20 + 80 + 20 + 70)
        .attr("y", 8)
        .attr("fill", orange)
        .attr("fill-opacity", 0.8);
      legElements
        .append("text")
        .text("Positive Tests")
        .attr("x", 15 + 20 + 80 + 20 + 70 + 20)
        .attr("y", margin.top / 2)
        .style("font-size", "80%");
    } else {
      legElements
        .append("rect")
        .attr("width", 15)
        .attr("height", 15)
        .attr("x", 15 + 120 + 80)
        .attr("y", 8)
        .attr("fill", orange)
        .attr("fill-opacity", 0.4);
      legElements
        .append("text")
        .text("Total Tests")
        .attr("x", 15 + 120 + 80 + 20)
        .attr("y", margin.top / 2)
        .style("font-size", "80%");
    }

    const margin2 = { top: 370, right: 30, bottom: 50, left: 50 };
    const height2 = 100;
    defs
      .append("clipPath")
      .attr("id", "clip2")
      .append("rect")
      .attr("width", innerWidth)
      .attr("height", height2 - margin2.bottom);

    const y2 = scaleLinear()
      // .domain([0,1])
      .range([height2 - margin2.bottom, 0]);
    if (varMap[dataSelection]["positivity"] !== null) {
      y2.domain([0, 1]);
    } else {
      y2.domain([
        0,
        max(
          data.series.map(
            (d) => d[varMap[dataSelection]["7-day avg new daily total tests"]]
          )
        ),
      ]);
    }

    const x2 = scaleTime()
      .range([0, innerWidth])
      .domain(timeExtent.map(d => moment(d)));
    const context = g
      .append("g")
      .attr("transform", `translate(0, ${margin2.top})`);
    context
      .append("g")
      .attr("transform", `translate(0,${height2 - margin2.bottom})`)
      .call(axisBottom(x2).ticks(innerWidth/150, timeFormat("%b %Y")));
    const lineGenerator2 = d3_line()
      .x((d) => x2(moment(d.date))+(xBandwidth))
      .y((d) =>
        y2(
          d[
            varMap[dataSelection]["positivity"]
              ? varMap[dataSelection]["positivity"]
              : varMap[dataSelection]["7-day avg new daily total tests"]
          ]
        )
      );
    context
      .append("path")
      .attr("d", lineGenerator2(data.series))
      .style("fill", "none")
      .style("stroke", blue)
      .style("stroke-width", 2)
      .style("clip-path", "url(#clip2)");

    const brush = brushX()
      .extent([
        [0, 0],
        [innerWidth, height2 - margin2.bottom],
      ])
      .on("brush end", brushed);

    const zoom = d3zoom()
      .scaleExtent([1, Infinity])
      .translateExtent([
        [0, 0],
        [innerWidth, innerHeight],
      ])
      .extent([
        [0, 0],
        [innerWidth, innerHeight],
      ])
      .on("zoom", zoomed);

    const gb = context.append("g").attr("class", "brush").call(brush);

    const handles = gb
      .selectAll(".handle--custom")
      .data([{ type: "w" }, { type: "e" }])
      .join("g")
      .attr("class", "handle--custom");
    handles
      .append("circle")
      .attr("r", 9)
      .attr("cx", 11)
      .attr("cy", 11)
      .attr("fill", "#fff")
      .attr("cursor", "ew-resize");
    handles
      .append("path")
      .attr("fill", "#666")
      .attr("cursor", "ew-resize")
      .attr(
        "d",
        "M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M16,15V13H8V15L5,12L8,9V11H16V9L19,12L16,15Z"
      );

    gb.call(brush.move, x.range());
    gb.selectAll(".overlay").on("mousedown touchstart", beforebrushed, true);

    function beforebrushed() {
      d3event.stopImmediatePropagation();
      select(this.parentNode).transition().call(brush.move, x.range());
    }

    function brushed() {
      if (d3event.sourceEvent && d3event.sourceEvent.type === "zoom") return; // ignore brush-by-zoom
      let s = d3event.selection || x2.range();
      x.domain(s.map(x2.invert, x2));
      let barData = data.series.filter(
        (d) =>
          moment(d.date) >= x.domain()[0] && moment(d.date) <= x.domain()[1]
      );
      lineGenerator.x(d => x(moment(d.date))+(innerWidth / (barData.length+1)))
      line.attr("d", lineGenerator(data.series));
      xAxis.call(axisBottom(x).ticks(innerWidth/150, timeFormat("%b %d '%y")));
      testBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      posBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      hoverBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      g.select(".zoom").call(
        zoom.transform,
        zoomIdentity.scale(innerWidth / (s[1] - s[0])).translate(-s[0], 0)
      );
      handles.attr(
        "transform",
        (d, i) => `translate(${s[i] - 12.5},${height2 / 2 - 35})`
      );
    }

    function zoomed() {
      if (d3event.sourceEvent && d3event.sourceEvent.type === "brush") return; // ignore zoom-by-brush
      let t = d3event.transform;
      x.domain(t.rescaleX(x2).domain());
      let barData = data.series.filter(
        (d) =>
          moment(d.date) >= x.domain()[0] && moment(d.date) <= x.domain()[1]
      );
      lineGenerator.x(d => x(moment(d.date))+(innerWidth / (barData.length+1)))
      line.attr("d", lineGenerator(data.series));
      xAxis.call(axisBottom(x));
      testBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      posBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      hoverBars
        .attr("width", innerWidth / (barData.length+1))
        .attr("x", (d) => x(moment(d.date)));
      context.select(".brush").call(brush.move, x.range().map(t.invertX, t));
    }

    return () => {
      svg.selectAll("g").remove();
      svg.select("defs").remove();
    };
  }, [data, width, dataSelection, varMap]);

  return <svg width={width} height={height} ref={ref} />;
};

export default DualAxis;
