import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";

import classNames from "classnames/bind";
import styles from "./ColumnLayout.module.scss";
const cx = classNames.bind(styles);

// Hook that returns a ref on whose target it will track horizontal size/padding
function useSizing() {
  const ref = useRef(null);
  const [measuredWidth, setWidth] = useState(1280 - 32);
  const [measuredPadding, setPadding] = useState(0);
  useLayoutEffect(() => {
    const update = () => {
      if (!ref.current) return;
      setWidth(ref.current.offsetWidth);
      const style = getComputedStyle(ref.current);
      const paddingLeft = parseFloat(style.getPropertyValue("padding-left"));
      const paddingRight = parseFloat(style.getPropertyValue("padding-right"));
      setPadding(paddingLeft + paddingRight || 0);
    };
    update();
    window.addEventListener("resize", update);
    return () => window.removeEventListener("resize", update);
  });
  return [ref, { measuredWidth, measuredPadding }];
}

/**
 * Renders a grid of a fixed size with the given children, layout, and gap.
 */
const Grid = React.forwardRef(
  (
    {
      children,
      columnLayout,
      gap,
      dividers: { column: columnDivider, row: rowDivider },
      className,
      style,
      ...props
    },
    ref
  ) => {
    // Make sure we don't render spaces for "false" children
    children = React.Children.toArray(children).filter((c) => !!c);
    // Establish CSS grid parameters.
    // We include gaps as separate columns/rows since IE doesn't support 'gap'
    const cgap = gap.column || gap;
    const rgap = gap.row || gap;
    const columnCount = columnLayout.length;
    const columns = columnLayout.map((size) => `${size}fr`).join(` ${cgap}px `);
    const rowCount = Math.ceil(children.length / columnCount) || 0;
    const rows = new Array(rowCount).fill("auto").join(` ${rgap}px `);

    const gridPos = (row, col) => ({
      msGridRow: row,
      gridRow: row,
      msGridColumn: col,
      gridColumn: col,
    });

    return (
      <div
        ref={ref}
        className={[cx("container"), className].filter((n) => !!n).join(" ")}
        style={{
          ...style,
          msGridColumns: columns,
          gridTemplateColumns: columns,
          msGridRows: rows,
          gridTemplateRows: rows,
        }}
        {...props}
      >
        {React.Children.map(children, (c, i) => {
          const columnPos = (i % columnCount) * 2 + 1;
          const rowPos = Math.floor(i / columnCount) * 2 + 1;
          return (
            c && (
              <React.Fragment>
                {React.cloneElement(c, {
                  style: {
                    ...c.props.style,
                    ...gridPos(rowPos, columnPos),
                  },
                })}
                {/* Vertical dividers between columns */}
                {columnDivider && columnPos > 1 && (
                  <div
                    className={cx("divider", "vertical")}
                    style={gridPos(rowPos, columnPos - 1)}
                  />
                )}
                {/* Horizontal dividers between wrapped rows */}
                {rowDivider && rowPos > 1 && (
                  <div
                    className={cx("divider", "horizontal")}
                    style={gridPos(rowPos - 1, columnPos)}
                  />
                )}
              </React.Fragment>
            )
          );
        })}
      </div>
    );
  }
);

// These are the 'out-of-the-box' layouts that work with ColumnLayout by name
const predefinedLayouts = {
  "off-center": [[8, 4], [12]],
  solo: [[12]],
  duet: [[6, 6], [12]],
  trio: [[4, 4, 4], [12]],
  quartet: [[3, 3, 3, 3], [4, 4, 4], [12]],
  flexible: "flexible",
};

/**
 * ColumnLayout renders its childen on a responsive multi-column grid
 */
export default function ColumnLayout({
  children,
  layout: passedLayoutConfig,
  breakpoint,
  onLayoutChange,
  gap,
  dividers: passedDividerConfig,
  className,
  ...props
}) {
  let layoutConfig =
    predefinedLayouts[passedLayoutConfig] || passedLayoutConfig;
  // If the layout we got is only one layout definition instead of an array of
  // layout definitions, wrap it in an array so that it takes the latter form
  layoutConfig =
    typeof layoutConfig[0] === "number" ? [layoutConfig] : layoutConfig;

  // Establish "flexible" layout
  let layoutSet;
  if (layoutConfig === "flexible") {
    const maxCols = React.Children.count(children);
    layoutSet = [];
    for (let i = maxCols; i >= 1; i--) {
      layoutSet.push(new Array(i).fill(12 / i));
    }
  } else {
    layoutSet = layoutConfig;
  }
  // When the special secret WHEN_FULL_WIDTH value is passed for the row divider
  // config, we rewrite the divider config to put dividers between rows only
  // when the columns are at a full-width size
  let dividerSet = new Array(layoutSet.length).fill(passedDividerConfig);
  if (passedDividerConfig.row === "WHEN_FULL_WIDTH") {
    dividerSet = dividerSet.map(({ column }, i) =>
      layoutSet[i][0] === 12 ? { row: true } : { column, row: false }
    );
  }

  const [baseRef, { measuredWidth, measuredPadding }] = useSizing();

  // Find the first layout in the list for which the smallest column will be at
  // least 'breakpoint' pixels wide. Use the last layout if none match.
  let layoutIndex = layoutSet.findIndex((layout) => {
    const totalGap = (gap.column || gap) * (layout.length - 1);
    const sumColumnWidth = measuredWidth - measuredPadding - totalGap;
    const columnWidths = layout.map((size) => (sumColumnWidth / 12) * size);
    const smallestColumn = Math.min(...columnWidths);
    return smallestColumn >= breakpoint;
  });
  // Use the last one if none match
  if (layoutIndex < 0) layoutIndex = layoutSet.length - 1;
  const columnLayout = layoutSet[layoutIndex] || [12];
  const dividers = dividerSet[layoutIndex] || {};

  // Notify when layout changes if a callback was passed
  const layoutCallback = useRef(onLayoutChange);
  layoutCallback.current = onLayoutChange;
  useEffect(() => {
    if (layoutCallback.current instanceof Function)
      requestAnimationFrame(() =>
        layoutCallback.current(layoutSet[layoutIndex])
      );
  }, [layoutSet, layoutIndex]);

  return (
    <Grid
      ref={baseRef}
      className={className}
      {...{ children, columnLayout, dividers, gap, ...props }}
    />
  );
}

// Lots of prop validation types!

const layoutDefinitionPropType = (...args) => {
  // Check it's an array of numbers
  const typeCheck = PropTypes.arrayOf(PropTypes.number)(...args);
  if (typeCheck instanceof Error) return typeCheck;
  // If that worked, then check it adds properly
  const [props, propName, componentName] = args;
  const errorMessage = `Invalid prop \`${propName}\` supplied to \`${componentName}\`. When \`${propName}\` is an array, its elements must sum to 12.`;
  const sum = props[propName].reduce((a, b) => a + b);
  if (sum !== 12) return new Error(errorMessage);
};

ColumnLayout.propTypes = {
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(predefinedLayouts)),
    layoutDefinitionPropType,
    PropTypes.arrayOf(layoutDefinitionPropType),
  ]).isRequired,
  breakpoint: PropTypes.number,
  onLayoutChange: PropTypes.func,
  gap: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.exact({
      column: PropTypes.number.isRequired,
      row: PropTypes.number.isRequired,
    }),
  ]),
  dividers: PropTypes.exact({
    column: PropTypes.bool,
    row: PropTypes.oneOf([true, false, "WHEN_FULL_WIDTH"]),
  }),
};

ColumnLayout.defaultProps = {
  breakpoint: 250,
  onLayoutChange: undefined,
  gap: 32,
  dividers: {
    column: false,
    row: false,
  },
};
