import React from "react";
import Page from "components/layout/Page";
import styles from "./LiveBriefingSignUp.module.scss";

const LiveBriefingSignUp = () => {
  return (
    <Page>
      <div className={styles.container}>
        <iframe
          width="100%"
          height="500"
          title="sendgrid-embed"
          className={styles.iframe}
          frameBorder="no"
          src="https://cdn.forms-content.sg-form.com/153c4e66-9353-11ec-a381-e697905582ac"
        />
      </div>
    </Page>
  );
};

export default LiveBriefingSignUp;
