import data from "./new-cases/country_info.json";
import hubeiCasesConfig from "./hubei-cases/config";
import testingConfig from "./testing/config";
import charts from "./charts";
import racialDataTransparency from './racial-data-transparency'

// Updated chart registry (formerly "Testing" specific):
export const getCards = (options = {}) => {
  const { section, limit = 999, featured } = options;

  const availableCharts = charts
    .filter((chart) => (!!section ? chart.section === section : true))
    .filter((chart) => (featured === true ? chart.featured : true))
    .filter((chart) =>
      process.env.REACT_APP_DRAFT === "true" ? true : !chart.draft
    );

  return availableCharts.slice(0, limit);
};

const configs = [hubeiCasesConfig, testingConfig];
// Legacy timestamp location:
const legacyConfig = {
  update: {
    method: "legacy",
    timestamp: data.last_update[Object.keys(data.last_update)[0]],
  },
};

export const getConfig = (dataSet) => {
  // Find config file containing requested item:
  const dataSetConfig = configs.find((config) =>
    config.find((item) => item.name === dataSet)
  );

  // If a config was found, return the specifically requested item
  return !!dataSetConfig
    ? dataSetConfig.find((item) => item.name === dataSet)
    : legacyConfig;
};

export const formatDateTitleString = (date) => {
  const tsDate = new Date(date);
  return tsDate.toLocaleString("en-US", {
     weekday: "long",
     year: "numeric",
     month: "long",
     day: "numeric",
     time: "numeric",
     timeZone: "America/New_York",
   });
};

export const updatedOnDateAndTime = (dataSet) => {
  var ts;
  if (dataSet === 'racial-data-transparency') {
    ts = racialDataTransparency
      .last_update
      .US_race_data_availability * 1000;
  } else {
    const config = getConfig(dataSet);
    ts = config.update && config.update.timestamp
        ? config.update.timestamp
        : legacyConfig.update.timestamp;
  }
  const tsDate = new Date(ts);
  const dateString = tsDate.toLocaleString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    time: "numeric",
    timeZone: "America/New_York",
  });
  const timeString = tsDate.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    timeZone: "America/New_York",
  });
  return `${dateString} at ${timeString}`;
};

export const updatedOnDate = (dataSet) => {
  const config = getConfig(dataSet);
  const ts =
    config.update && config.update.timestamp
      ? config.update.timestamp
      : legacyConfig.update.timestamp;
  return new Date(ts).toLocaleDateString("en-US", {
    timeZone: "America/New_York",
  });
};
