import { sectionProps } from "data/navigation";
import PropTypes from "prop-types";

export const sectionNav = {
  section: PropTypes.shape(sectionProps),
  pathname: PropTypes.string,
  onClose: PropTypes.func,
  isActive: PropTypes.bool,
  isGrouped: PropTypes.bool,
};
