import { getArticleBySlug } from "data/util";
import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import * as GTM from "util/gtm";
import PDIArticle, { pdiNewsSectionURL } from "./PDIArticle";
import PDIFAQ from "./PDIFAQ";
import PDIHome from "./PDIHome";
import DataOutlook from "./PDINews/DataOutlook";
import ExpertInsight from "./PDINews/ExpertInsight";

export const basePathPDI = "/pandemic-data-initiative";

const PandemicDataInitiative = () => {
  useEffect(() => {
    GTM.clear();
    GTM.push({ pageVerticals: ["pandemic-data-initiative"] });
  }, []);

  return (
    <Switch>
      <Route
        path={`${basePathPDI}/news/:articleId`}
        render={(routeProps) => {
          const { match } = routeProps;
          const article = getArticleBySlug(match.params.articleId);
          return (
            <Redirect
              to={`${basePathPDI}/${pdiNewsSectionURL[article.section]}/${
                match.params.articleId
              }`}
            />
          );
        }}
      />

      <Route
        path={`${basePathPDI}/${pdiNewsSectionURL["Pandemic Data Outlook"]}/:articleId`}
        component={PDIArticle}
      />
      <Route
        path={`${basePathPDI}/${pdiNewsSectionURL["Expert Insight"]}/:articleId`}
        component={PDIArticle}
      />
      <Route
        key="pandemic-data-outlook"
        path={`${basePathPDI}/data-outlook`}
        component={DataOutlook}
      />
      <Route
        key="pandemic-expert-insight"
        path={`${basePathPDI}/expert-insight`}
        component={ExpertInsight}
      />

      <Route
        key="pandemic-data-faq"
        path={`${basePathPDI}/pandemic-data-faq`}
        component={PDIFAQ}
      />
      <Route path={`${basePathPDI}`} exact component={PDIHome} />
      <Redirect to="/404" />
    </Switch>
  );
};

export default PandemicDataInitiative;
