import React from "react";
import ColumnLayout from "components/layout/ColumnLayout";
import ArticleCard from "components/layout/ArticleCard";
import { FAQ, formatFAQCards } from "./cards";

export default function VaccineFAQNavContent() {
  const content = formatFAQCards()
    .concat(FAQ)
    .map((card, i) => {
      const result = {
        ...card,
        vertical: true,
        dark: true,
      };
      return <ArticleCard key={`${card.slug}-${i}`} {...result} />;
    });
  return <ColumnLayout layout="quartet">{content}</ColumnLayout>;
}
