import React, { useRef, useEffect, useState } from "react";
import { scaleLog, scaleLinear, scaleSqrt } from "d3-scale";
import { format } from "d3-format";
import { extent } from "d3-array";
import { select, event as d3event } from "d3-selection";
import { axisLeft, axisBottom } from "d3-axis";
import { brush } from "d3-brush";
import styles from "./BubblePlot.module.scss";
import SvgIcon from '@material-ui/core/SvgIcon';

const BubblePlot = ({ data, selectedRegion, width }) => {
  const svgRef = useRef(null);

  const breakpoint = 970;
  const xvar = "7-day_tests_100k";
  const yvar = "7-day_positivity";
  const rvar = "7-day_cases";

  const [mobile, setMobile] = useState(window.innerWidth < breakpoint);

  const [{ clipWidth, clipHeight }, setClipDims] = useState({
    clipWidth: window.innerWidth,
    clipHeight: window.innerHeight,
  });

  useEffect(() => {
    const margin = { top: 40, right: 80, bottom: 20, left: 40 };
    const innerWidth = width - margin.left - margin.right;
    const height = mobile ? width : width/2
    const innerHeight = height - margin.top - margin.bottom;
    const adjustment = [30, 40]
    setClipDims({ clipWidth: innerWidth+adjustment[0], clipHeight: innerHeight+adjustment[1] });

    const updateChart = () => {
      let xSelection, ySelection;

      if (d3event.selection) {
        xSelection = [xScale.invert(d3event.selection[0][0]),xScale.invert(d3event.selection[1][0])];
        ySelection = [yScale.invert(d3event.selection[1][1]),yScale.invert(d3event.selection[0][1])];
      } else if (d3event.sourceEvent?.type === "mouseup" || d3event.sourceEvent?.type === "touchend") {
        xSelection = extent(data.filter((d) => d[xvar] !== null && d[xvar] > 0).map((d) => d[xvar]));
        ySelection = [0,1];
      }

      if (xSelection && ySelection) {
        xScale.domain(xSelection)
        yScale.domain(ySelection)

        xAxis.transition().duration(1000).call(axisBottom(xScale).ticks(5, ",.2s"))
        xAxis.selectAll(".tick text").transition().duration(1000).style("font-size", "110%")
        xAxis.selectAll(".tick line").transition().duration(1000).attr("stroke", "#eee").attr("y2", -innerHeight-adjustment[1])
        yAxis.transition().duration(1000).call(axisLeft(yScale).ticks(10, ".0%"))
        yAxis.selectAll(".tick text").transition().duration(1000).style("font-size", "110%")
        yAxis.selectAll(".tick line").transition().duration(1000).attr("stroke", "#eee").attr("x2", innerWidth)

        xAxis.select(".domain").remove();
        yAxis.select(".domain").remove();

        nodes
          .transition().duration(1000)
          .attr("cx", d => xScale(d[xvar]))
          .attr("cy", d => yScale(d[yvar]))
        labels
          .transition().duration(1000)
          .attr("x", d => xScale(d[xvar]))
          .attr("y", d => yScale(d[yvar]))

        select("#benchmark-label").transition().duration(1000).attr("transform", `translate(${innerWidth+15}, ${yScale(0.05)+4})`)
        select("#benchmark-line").transition().duration(1000).attr('y1',yScale(0.05)).attr('y2',yScale(0.05))

        group.transition().duration(1000).on("end", clearBrush)
      }
    }

    const clearBrush = () => {
      group.select(".brush").call(brushXY.move, null)
    }

    let filtered = data
      .filter((d) => d[xvar] !== null && d[xvar] > 0)

    const rScale = scaleSqrt()
      .domain(extent(filtered.map((d)=> d[rvar])))
      .range([5,30])
    const yScale = scaleLinear()
      .domain([0,1])
      .range([innerHeight, 0])
    const xScale = scaleLog()
      .base(Math.E)
      .domain(extent(filtered.map((d) => d[xvar])))
      .range([0, innerWidth])

    if (selectedRegion && selectedRegion !== "") {
      filtered = filtered.filter(d => d.region === selectedRegion)
    }

    const brushXY = brush()
      .extent([[0,-20],[innerWidth, innerHeight+adjustment[1]]])
      .on("end", updateChart)

    const svg = select(svgRef.current);

    svg.selectAll("g").remove();
    svg.selectAll("foreignObject").remove();

    const yAxis = svg
      .append("g")
      .attr("id", "yaxis")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    yAxis.call(axisLeft(yScale).ticks(10, ".0%"));
    yAxis
      .append("text")
      .attr("x", -margin.left)
      .attr("y", -27)
      .attr("text-anchor", "start")
      .attr("fill", "black")
      .text("Daily Percentage of Positive Tests (7-day moving average)");

    const xAxis = svg
      .append("g")
      .attr("id", "xaxis")
      .attr(
        "transform",
        `translate(${margin.left},${height})`
      );
    xAxis.call(axisBottom(xScale).ticks(5, ",.2s"));
    xAxis
      .append("text")
      .attr("transform", `translate(${innerWidth+20}, 33)`)
      .attr("text-anchor", "end")
      .attr("fill", "black")
      .text("Daily Number of Tests per 100,000 population");
    xAxis
      .append("text")
      .attr("transform", `translate(${innerWidth+20}, 46)`)
      .attr("text-anchor", "end")
      .attr("fill", "black")
      .text("(7-day moving average / natural log scale)");
    xAxis
      .append("line")
      .attr("x1",0)
      .attr("x2",20)
      .attr("y1",mobile ? 60 : 30)
      .attr("y2",mobile ? 60 : 30)
      .attr("stroke", "red")
    xAxis
      .append("text")
      .text("Maximum recommended positivity")
      .attr("transform", `translate(105, ${mobile ? 63 : 33})`)
      .attr("fill", "black")
    xAxis
      .append("circle")
      .attr("r", 6)
      .attr("fill", "#0E2C74")
      .style("fill-opacity", 0.4)
      .attr("cx", 205)
      .attr("cy", mobile ? 59 : 29)
    xAxis
      .append("text")
      .text("Countries")
      .attr("transform", `translate(238, ${mobile ? 63 : 33})`)
      .attr("fill", "black")
    xAxis
      .append("text")
      .text("(The sizes of the circles are proportional to the average daily confirmed new cases.)")
      .attr("transform", `translate(0, ${mobile ? 76 : 46})`)
      .attr("fill", "black")
      .style("text-anchor", "start")
    xAxis.selectAll(".tick text").style("font-size", "110%")
    yAxis.selectAll(".tick text").style("font-size", "110%")
    xAxis.select(".domain").remove();
    yAxis.select(".domain").remove();
    xAxis.selectAll(".tick line").attr("stroke", "#eee").attr("y2", -innerHeight-adjustment[1])
    yAxis.selectAll(".tick line").attr("stroke", "#eee").attr("x2", innerWidth)

    const group = svg.append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`)

    group.append("g")
      .attr("class", "brush")
      .call(brushXY);

    const nodes = group
      .append("g")
      .attr("id", "nodes")
      .style("clip-path", "url(#clip)")
      .selectAll("circle")
      .data(filtered)
      .join("circle")
      .attr("r", d => rScale(d[rvar]))
      .attr("cx", d => xScale(d[xvar]))
      .attr("cy", d => yScale(d[yvar]))
      .style("fill", "#0E2C74")
      .attr("fill-opacity", 0.4);

    const labels = group
      .append("g")
      .attr("id", "labels")
      .style("clip-path", "url(#clip)")
      .selectAll(".labels")
      .data(filtered)
      .join("text")
      .text((d) => d.country)
      .attr("x", d => xScale(d[xvar]))
      .attr("y", d => yScale(d[yvar]))
      .attr("dy", d => `${rScale(d[rvar])+6}px`)
      .style("text-anchor", "middle")
      .style("font-weight", "bold")
      .style("cursor", "default")
      .style("font-size", "6px")
      .style("font-family", "Arial")
      .style("fill", "black")
      .style("text-transform", "uppercase");

    group.append('line')
      .attr("id", "benchmark-line")
      .attr('x1',-5)
      .attr('x2',innerWidth+10)
      .attr('y1',yScale(0.05))
      .attr('y2',yScale(0.05))
      .attr("stroke", "red")

    group.append('text')
      .attr("id", "benchmark-label")
      .text("5%")
      .style("fill", "red")
      .attr("transform", `translate(${innerWidth+15}, ${yScale(0.05)+4})`)
      .style("font-size", "80%")

    const tooltip = svg
      .append("foreignObject")
      .attr("x", 40)
      .attr("width", 320)
      .attr("height", 90)
      .attr("class", styles.tooltip);

    nodes
      .on("mouseenter", (d) => {
        let ttX = xScale(d[xvar])+rScale(d[rvar])+320 > innerWidth
          ? xScale(d[xvar])-320-rScale(d[rvar])
          : xScale(d[xvar])+rScale(d[rvar])
        tooltip.attr("transform", `translate(${ttX}, ${yScale(d[yvar])})`)
        tooltip.transition().duration(300).style("opacity", 1);
        tooltip.html(`<h5 class="${styles.tooltipH5}">${d.country}</h5>
                  <p class="${styles.tooltipText}">7-day Percentage of Postive Tests: ${format(".3p")(d[yvar])}</p>
                  <p class="${styles.tooltipText}">7-day Average Daily Tests per 100k Population: ${format(",.0f")(d[xvar])}</p>
                  <p class="${styles.tooltipText}">7-day Average Daily Confirmed New Cases: ${format(",.0f")(d[rvar])}</p>`
                );
      })
      .on("mouseleave", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    labels.on("mouseenter", (d) => {
      let ttX = xScale(d[xvar])+rScale(d[rvar])+320 > innerWidth
        ? xScale(d[xvar])-320-rScale(d[rvar])
        : xScale(d[xvar])+rScale(d[rvar])
      tooltip.attr("transform", `translate(${ttX}, ${yScale(d[yvar])})`)
      tooltip.transition().duration(300).style("opacity", 1);
      tooltip.html(`<h5 class="${styles.tooltipH5}">${d.country}</h5>
                    <p class="${styles.tooltipText}">7-day Percentage of Postive Tests: ${format(".3p")(d[yvar])}</p>
                    <p class="${styles.tooltipText}">7-day Average Daily Tests per 100k Population: ${format(",.0f")(d[xvar])}</p>
                    <p class="${styles.tooltipText}">7-day Average Daily Confirmed New Cases: ${format(",.0f")(d[rvar])}</p>`
              );
      })
      .on("mouseleave", (d) => {
        tooltip.transition().duration(300).style("opacity", 0);
      });

    return () => {
      svg.selectAll("g").remove();
      svg.selectAll("foreignObject").remove();
    };
  }, [data, width, selectedRegion, mobile]);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth < breakpoint;
      setMobile(mobile);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={styles.howto}>
        <SvgIcon style={{verticalAlign: "top"}} fontSize="inherit">
          <path d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
        </SvgIcon>
        Click and drag to zoom
        <SvgIcon style={{verticalAlign: "top", marginLeft: "1em"}} fontSize="inherit">
          <path d="M10.76,8.69A0.76,0.76 0 0,0 10,9.45V20.9C10,21.32 10.34,21.66 10.76,21.66C10.95,21.66 11.11,21.6 11.24,21.5L13.15,19.95L14.81,23.57C14.94,23.84 15.21,24 15.5,24C15.61,24 15.72,24 15.83,23.92L18.59,22.64C18.97,22.46 19.15,22 18.95,21.63L17.28,18L19.69,17.55C19.85,17.5 20,17.43 20.12,17.29C20.39,16.97 20.35,16.5 20,16.21L11.26,8.86L11.25,8.87C11.12,8.76 10.95,8.69 10.76,8.69M15,10V8H20V10H15M13.83,4.76L16.66,1.93L18.07,3.34L15.24,6.17L13.83,4.76M10,0H12V5H10V0M3.93,14.66L6.76,11.83L8.17,13.24L5.34,16.07L3.93,14.66M3.93,3.34L5.34,1.93L8.17,4.76L6.76,6.17L3.93,3.34M7,10H2V8H7V10" />
        </SvgIcon>
        Click to reset
      </div>
      <svg id="international-comparison-bubble-plot" width={width} height={(mobile ? width : width/2)+80} ref={svgRef}>
        <defs>
          <clipPath id="clip">
            <rect x={-20} y={-20} height={clipHeight} width={clipWidth} />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default BubblePlot;
