import React from "react";
import styles from "./TopPages.module.scss";
import classNames from "classnames/bind";
import Button from "components/common/Button";
const cx = classNames.bind(styles);

type TTopPagesProps = {
  items: { title: string; content: string; href: string }[];
  className?: string;
};

const TopPages = ({ items = [], className }: TTopPagesProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <div className={styles.introBlock}>
        <h2 className={styles.header}>
          Ongoing Johns Hopkins COVID-19 Resources
        </h2>
      </div>

      {items.length > 0 && (
        <div className={styles.contentBlock}>
          {items.map((item, i) => {
            return (
              <div key={i} className={styles.pageBlock}>
                <Button
                  className={styles.button}
                  href={item.href}
                  icon={"right"}
                  targetBlank={true}
                >
                  {item.title}
                </Button>
                <p className={styles.content}>{item.content}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TopPages;
