import React, { Component } from "react";
import ReactMarkdown from "react-markdown";

import * as GTM from "util/gtm";
import styles from "./WebcastView.module.scss";
import WebcastEmbed from "../WebcastEmbed";
import { ReactComponent as FwdIcon } from "images/icons/arrow-right.svg";
import Meta from "components/common/Meta";
import { getPage } from "data/util";

class WebcastView extends Component {
  componentDidMount = () => {
    GTM.clear();
  };

  render() {
    const pageData = getPage(this.props.location.pathname);
    const { title, subtitle, meta, content_blocks } = pageData;

    return (
      <div className={styles.base}>
        <Meta {...meta} />
        <div className={styles.container}>
          <div className={styles.info}>
            <div className={styles.callout}>
              <h2>{subtitle}</h2>
              <h1>{title}</h1>
            </div>
          </div>
        </div>
        <div className={styles.embed}>
          <WebcastEmbed />
        </div>
        <div className={styles.container}>
          <ReactMarkdown
            className={styles.embedDescription}
            source={Object.values(content_blocks)[0].content}
            renderers={{ link: Link }}
          />
        </div>
      </div>
    );
  }
}

export default WebcastView;

const Link = ({ href, children }) => {
  const linkData =
    href.indexOf("newsletter") !== -1
      ? {
          className: styles.button,
          children: (
            <>
              <span>{children}</span>
              <FwdIcon />
            </>
          ),
        }
      : { className: "", children };
  return (
    <a
      className={linkData.className}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {linkData.children}
    </a>
  );
};
