import React, { useState, useRef } from "react";
import styles from "./VariantData.module.scss";
import Page from "components/layout/Page";
import { getPage } from "data/util";
import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import TabBar from "components/layout/shell/TabBar";
import CustomSelect from "components/common/CustomSelect";
import VideoContent from "./VideoContent/index.js";
import BarChart from "./SequencingByCountry";
import ScatterPlot from "./SequencingByGDP";
import cx from "classnames";

const tabOptions = ["Sequencing By Country", "Sequencing by GDP"];

const VariantData = () => {
  const page = getPage("/data/variant-data");
  const { content_blocks } = page;
  const [data, isLoading] = useFetch("global_variants");
  const [filteredData, setFilteredData] = useState(null);
  const [, setSelectedRegion] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countryReset, setCountryReset] = useState(false);
  const regions = data ? [...new Set(data["Country Data"].map(d => d.region))].sort() : [];
  const regionOptions = regions ? regions.map(d => { return {label: d, value: d}}) : [];
  const [countryOptions, setCountryOptions] = useState(null)
  const allCountries = data ? data["Country Data"].filter(d => d.percentSequenced !== undefined && d.percentSequenced > 0).sort((a,b) => (a.Country > b.Country) ? 1 : -1) : [];
  const allCountryOptions = allCountries ? allCountries.map(d => { return {label: d.Country, value: d.ISO3}}) : [];

  const [tab, setTab] = useState(tabOptions[0]);
  const handleTabChange = (selectedTab) => setTab(selectedTab);

  const [scatterPlotActive, setScatterPlotActive] = useState(true);

  const handleRegionSelect = (selected) => {
    if (selected?.value === "all-content") {
      let countries = data["Country Data"].sort((a,b) => (a.Country > b.Country) ? 1 : -1)
      setCountryOptions(countries.map(d => { return {label: d.Country, value: d.ISO3} }));
      setFilteredData(null);
      setSelectedRegion("");
      setSelectedCountry(selectedCountry);
      setCountryReset(false);
    } else {
      let filtered = data["Country Data"].filter(d => d.region === selected.value)
      let countries = filtered.sort((a,b) => (a.Country > b.Country) ? 1 : -1);
      setSelectedRegion(selected.value);
      setSelectedCountry(null);
      setCountryReset(true);
      setFilteredData(filtered);
      setCountryOptions(countries.map(d => { return {label: d.Country, value: d.ISO3} }));
    }
  };
  const handleCountrySelect = (selected) => {
    if (selected?.value === "all-content") {
      setSelectedCountry(null);
      setCountryReset(true);
    } else {
      setSelectedCountry(selected);
      setCountryReset(false);
    }
  };
  const ref = useRef(null);
  const [width] = useDimensions(ref);

  return (
    <Page page={page}>
      <div className={styles.base} onMouseDown={() => {
        // Disable scrolling of scatterplot
        setScatterPlotActive(true);
      }}>
        <div className={styles.container}>
          <div className={styles.videoContainer}>
            <VideoContent content_blocks={content_blocks} />
          </div>
          <br />
          <div className={styles.graphContainer}>
            <div className={styles.controlsContainer}>
              <TabBar
                className={styles.tabBar}
                tabs={tabOptions}
                type="highlight"
                onChange={handleTabChange}
              />
              <div className={styles.selectContainer}>
                <CustomSelect
                  classNames={styles.select}
                  label="Select a Region to filter"
                  allOptionLabel="All Regions"
                  noAllOption={false}
                  multi={false}
                  options={regionOptions}
                  onSelect={handleRegionSelect}
                />
                <CustomSelect
                  classNames={styles.select}
                  label="Select a Country to highlight"
                  allOptionLabel="All Countries"
                  noAllOption={false}
                  multi={false}
                  options={countryOptions ? countryOptions : allCountryOptions}
                  onSelect={handleCountrySelect}
                  resetToAll={countryReset}
                />
              </div>
              <div className={styles.contentContainer} ref={ref}>
                {tab === tabOptions[0] ? (
                  <div>
                    {/* "Sequencing By Country" */}
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <BarChart data={filteredData ? filteredData : data["Country Data"]} selectedCountry={selectedCountry ? selectedCountry : null} width={width}/>
                    )}
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      // Enable scroll on scatterplot
                      setScatterPlotActive(false);
                    }}
                    className={cx({
                      [styles.scatterPlotActive]: scatterPlotActive
                    })}
                   >
                    {/* "Sequencing By GDP" */}
                    {isLoading ? (
                      <p>Loading...</p>
                    ) : (
                      <ScatterPlot
                        data={
                          filteredData ? filteredData : data["Country Data"]
                        }
                        trendData={data["Country Data"]}
                        selectedCountry={
                          selectedCountry ? selectedCountry.value : null
                        }
                        width={width}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default VariantData;
