import React from 'react';
import { scaleLinear } from 'd3-scale';
import { max } from 'd3-array';
import Bar from '../Bar';
import TrendLine from '../TrendLine';
import { COMMA_FORMATTER } from '../../../../../constants';

const NewCases = ({ data, innerHeight, xScale }) => {
  // Calculate scales for displaying visualization elements
  const yScale = scaleLinear()
    .range([innerHeight, 0])
    .domain([0, max(data, d => d.value)]);

  return (
    <g className="new__cases_group">
      <g className="bars__group">
        {data.map(d => {
          return (
            <Bar
              item={d}
              key={`bar-for-new-cases-${d.dt}`}
              x={xScale(d.dt)}
              height={Math.abs(yScale(0) - yScale(d.value))}
              y={yScale(Math.max(d.value, 0))}
              width={xScale.bandwidth()}
              formatter={COMMA_FORMATTER}
              additionalText={'cases'}
            />
          );
        })}
      </g>
      <g className="trend__line__group">
        <TrendLine
          data={data.slice(0, data.length - 1).map((d, idx) => {
            const nextDataPoint = data[idx + 1];
            return {
              ...d,
              x1: xScale(d.dt) + xScale.bandwidth() / 2,
              x2: xScale(nextDataPoint.dt) + xScale.bandwidth() / 2,
              y1: yScale(d.average),
              y2: yScale(nextDataPoint.average),
              direction: nextDataPoint.change > 0 ? 'negative' : 'positive',
            };
          })}
        />
      </g>
    </g>
  );
};
NewCases.defaultProps = {
  data: [],
  innerHeight: 100,
  xScale: () => null,
  filteredDataPoints: [],
};
export default NewCases;
