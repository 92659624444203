import React, { useContext } from "react";
import styles from "./VaccineDataMapTooltip.module.scss";

const TooltipContext = React.createContext({
  state: {
    show: false,
    x: 0,
    y: 0,
    datakey: null,
    cases: null,
    deaths: null,
    tests: null,
    vaccines: null,
    bin: null,
    state: null,
  },
  dispatch: undefined,
});

const Tooltip = ({ width, height, dataset }) => {
  const { state } = useContext(TooltipContext);

  let textValue;
  if (!state[dataset] || typeof state[dataset].value === "string") {
    textValue = `Not Available`;
  } else {
    textValue = (state[dataset].value * 100).toFixed(2) + `% of ${dataset}`;
  }

  let content = (
    <>
      <span className={`${styles.span} ${styles.place}`}>{state.state}</span>
      <span className={styles.span}>{textValue}</span>
    </>
  );

  return (
    <>
      {content && (
        <g
          transform={`translate(${state.x}, ${state.y})`}
          style={{
            visibility: state.show ? "visible" : "hidden",
            pointerEvents: "none",
          }}
        >
          <foreignObject width={width} height={height}>
            <div className={styles.tipBox} xmlns="http://www.w3.org/1999/xhtml">
              {content}
            </div>
          </foreignObject>
        </g>
      )}
    </>
  );
};

export default Tooltip;
export { TooltipContext };
