import React from "react";
import styles from "./TourPointTip.module.scss";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

function TourPointTip({
  current,
  total,
  content,
  arrowPosition,
  onNext,
  onPrev,
  children,
}) {
  const hasNext = current < total;
  const hasPrev = current - 1 > 0;
  const handleOnPrev = () => onPrev();
  const handleOnNext = () => onNext();

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <span>
          {current + 1} of {total}
        </span>
        <div className={styles.navigation}>
          <Button
            className={`${!hasPrev ? styles.disabled : null}`}
            buttonStyle="tip"
            icon="left"
            onClick={handleOnPrev}
          >
            Previous
          </Button>
          <Button
            className={`${!hasNext ? styles.disabled : null}`}
            buttonStyle="tip"
            icon="right"
            onClick={handleOnNext}
          >
            Next
          </Button>{" "}
        </div>
      </div>
      <div className={styles.contentContainer}>
        <p>{content}</p>
      </div>
      <div className={styles.arrowContainer}>
        <div className={cx(["arrow", `position-${arrowPosition}`])}></div>
      </div>
    </div>
  );
}

TourPointTip.prototype = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  content: PropTypes.string,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  arrowPosition: PropTypes.oneOf(["right", "left", "center"]).isRequired,
  className: PropTypes.string,
};

TourPointTip.defaultProps = {
  arrowPosition: "center",
  onNext: () => {},
  onPrev: () => {},
};

export default TourPointTip;
