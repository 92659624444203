import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {sum} from 'd3-array';
import {COLORS} from "../../../../../../../Shared/constants";

const useStyles = makeStyles((theme) => ({
    legend: {
        width: "100%",
        margin: '20px auto',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 0'
        },
    },
    legendLabel: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    empty: {
        height: 'auto'
    },
    circleLegend: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: COLORS.blueDark,
        fontSize: '18px',
        fontWeight: 'bold',
        '& :first-child': {
            marginRight: '20px'
        },
        '& :last-child': {
            marginLeft: '5px'
        }
    },
    noData: {
        fontSize: '18px',
        fontFamily: "gentona, Tahoma, sans-serif",
        fontStyle: 'italic',
        textAlign: 'left',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    circle: {
        border: `2px solid ${COLORS.white}`,
        backgroundColor: props => props.color,
        borderRadius: '50%'
    }
}));

const Legend = ({selectedBubbleLayer}) => {
    const color = selectedBubbleLayer.legendColor;
    const classes = useStyles({color});
    const bubbleDomainSum = sum(selectedBubbleLayer.bubbleScale.domain(), d => +d);

    return (
        <div className={classes.legend + ' ' + (bubbleDomainSum ? '' : classes.empty)}>
            {bubbleDomainSum ? (<div className={classes.circleLegend}>
                <div className={classes.legendLabel}>{selectedBubbleLayer.label}</div>
                <div className={classes.circle} style={{width: '20px', height: '20px'}}/>
                <div className={`${classes.circle} large`} style={{width: '40px', height: '40px'}}/>
            </div>) : (
                <div className={classes.noData}>
                    No machine-readable county testing data available
                </div>
            )}
        </div>
    );
};
export default Legend;
