import React, { useState, useLayoutEffect, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  partialMatch as match,
  states as stateNames,
  countryMappings,
  slugify,
} from "data/geo";
import styles from "./RegionSearch.module.scss";
import classNames from "classnames/bind";
import RegionMenu from "../RegionMenu";
import SearchField from "components/common/SearchField";
const cx = classNames.bind(styles);

const states = stateNames.map((s) => ({
  name: s,
  slug: slugify(s),
  to: `/region/us/${slugify(s)}`,
}));
const countries = countryMappings.map((c) => ({
  name: c.displayName,
  slug: slugify(c.name),
  to: `/region/${slugify(c.name)}`,
}));

const RegionSearch = ({ context = "page", onMenuChange, className }) => {
  const location = useLocation();
  const defaultQuery = !!location.state?.query ? location.state.query : "";
  const history = useHistory();

  const [query, setQuery] = useState(defaultQuery);
  const handleChange = (value, closeMenu) => {
    if (!closeMenu) {
      history.replace("/region", { query: value });
    }
    setQuery(value);
  };

  const matchStates = states.filter(({ name }) => match(query, name));
  const matchCountries = countries.filter(({ name }) => match(query, name));

  // On mount, if only one result, re-route accordingly!
  useLayoutEffect(() => {
    if (matchCountries.length + matchStates.length === 1) {
      const [country] = matchCountries;
      const [state] = matchStates;
      if (country) history.replace(country.to);
      else if (state) history.replace(state.to);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [show, setShow] = useState(context === "page");
  const handleSearchFieldClick = () => {
    handleMenuState(true);
  };
  const handleSearchFieldClose = (event) => {
    event.stopPropagation();
    handleMenuState(false);
  };
  const handleMenuState = (show = false) => {
    setShow(show);
    if (onMenuChange) onMenuChange(show);
  };

  const [selected, setSelected] = useState({ index: -1, slug: null });
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    if (!isFocus) return;
    // :TODO: Move this handler logic to RegionMenu, so that we
    // can better support grid based layouts (left/right keys)
    // by inspecting the position of the elements in the DOM.
    const handleKeyPress = (event) => {
      event = event || window.event;
      switch (event.keyCode) {
        // down
        case 40:
          if (selected.index < matchStates.length + matchCountries.length) {
            setSelected({ ...selected, index: selected.index + 1 });
          }
          break;
        // up
        case 38:
          if (selected.index > 0) {
            setSelected({ ...selected, index: selected.index - 1 });
          }
          break;
        default:
          break;
      }
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [selected, matchStates.length, matchCountries.length, isFocus]);

  return (
    <div className={cx(styles.base, className)}>
      <SearchField
        defaultQuery={defaultQuery}
        placeholder={
          context === "nav"
            ? "Search data by region..."
            : "Find your U.S. State or country..."
        }
        context={context}
        onChange={handleChange}
        onFocus={() => {
          setIsFocus(true);
        }}
        onBlur={() => {
          setIsFocus(false);
        }}
        onClick={context === "nav" ? handleSearchFieldClick : null}
        onClose={context === "nav" && show ? handleSearchFieldClose : null}
      />
      {show && (
        <div className={context === "nav" ? styles.dropDown : null}>
          {matchStates.length > 0 && (
            <RegionMenu
              title="United States"
              items={matchStates}
              searchable={false}
              selected={selected}
              onSelectIndex={(index, slug) => {
                if (context === "nav") {
                  setSelected({ index, slug });
                  requestAnimationFrame(() => {
                    handleMenuState(false);
                    setQuery("");
                  });
                }
              }}
            />
          )}
          {matchCountries.length > 0 && (
            <RegionMenu
              title="World Countries"
              items={matchCountries}
              searchable={false}
              selected={{
                ...selected,
                index: selected.index - matchStates.length,
              }}
              onSelectIndex={(index, slug) => {
                if (context === "nav") {
                  setSelected({ index: index + matchStates.length, slug });
                  requestAnimationFrame(() => {
                    handleMenuState(false);
                    setQuery("");
                  });
                }
              }}
            />
          )}
          {matchStates.length + matchCountries.length === 0 && (
            <p className={styles.empty}>
              We're sorry, no results were found. Please check your spelling and
              try again.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default RegionSearch;
