import React from "react";
import styles from "./VaccineArticle.module.scss";
import Blog from "components/layout/Blog";
import { basePathVaccine } from "..";
import { Redirect } from "react-router-dom";
import { getArticle } from "data/util";

const VaccineArticle = (props) => {
  const article = getArticle(props?.location.pathname);

  if (!article?.slug) return <Redirect to={basePathVaccine} />;
  return (
    <div className={styles.container}>
      <Blog article={article} {...props} />
    </div>
  );
};

export default VaccineArticle;
