import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import TestingHome from "./TestingHome";
import TestingFAQ from "./TestingFAQ";
import TestingViz from "./TestingViz";
import TestingStates from "./TestingStates";
import TestingPositivity from "./TestingPositivity";
import TestingPer100k from './TestingPer100k';
import TestingTracker from './TestingTracker';
import Article from "components/layout/Article";

const Testing = () => {  
  return (
      <Switch>
        <Route path="/testing/testing-faq" component={TestingFAQ} />
        <Route path="/testing/states-comparison/:dataSetId?" component={TestingViz} />
        <Route path="/testing/individual-states/:stateId?" component={TestingStates} />
        <Route path="/testing/testing-positivity" component={TestingPositivity} />
        <Route path="/testing/international-comparison" component={TestingPer100k} />
        <Route path="/testing/tracker" component={TestingTracker}/>

        <Route path="/testing/:articleId" component={Article}/>

        <Route path="/testing" exact component={TestingHome} />
        <Redirect to="/404" />
      </Switch>
  );
};

export default Testing;
