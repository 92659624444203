import React, { useEffect, useCallback } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from './Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveData } from '../../redux/modules/global';
import TileMap from './TileMap';
import smartquotes from 'smartquotes';
import StateTable from './Table';
import Tooltip from './Table/Tooltip';
import PositivityComparison from "./PositivityComparison";

const App = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.testingTracker.isLoading);
  const getData = useCallback(() => dispatch(retrieveData()), [dispatch]);

  useEffect(() => {
    getData();
    smartquotes().listen();
  }, [getData]);

  if (isLoading) return <Spinner />;

  return (
    <div className="page">
      <Switch>
        <Route path="/testing/tracker/overview/:region?" component={StateTable} />
        <Route path="/testing/tracker/map/:dataset?" component={TileMap} />
        <Route path="/testing/tracker/positivity-comparison/:region?" component={PositivityComparison} />
        <Redirect from="/testing/tracker/" to="/testing/tracker/overview" />
      </Switch>
      <Tooltip />
    </div>
  );
};
export default App;
