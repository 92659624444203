import React from "react";
import PropTypes from "prop-types";
import styles from "./Icon.module.scss";

import approvalIcon from "./icons/approval.svg";
import bottlesIcon from "./icons/bottles.svg";
import fastIcon from "./icons/fast.svg";
import safetyIcon from "./icons/safety.svg";
import slowIcon from "./icons/slow.svg";
import syringeIcon from "./icons/syringe.svg";
import trials1Icon from "./icons/trials1.svg";
import trials2Icon from "./icons/trials2.svg";
import trials3Icon from "./icons/trials3.svg";
import scrollIcon from "./icons/scroll.svg";
import tubesIcon from "./icons/tubes.svg";
import { accessibilityProps_IMG } from "util/accessibility";

function Icon({ light, icon, className, alt, ...props }) {
  const iconUrl = {
    syringe: syringeIcon,
    bottles: bottlesIcon,
    trials1: trials1Icon,
    trials2: trials2Icon,
    trials3: trials3Icon,
    approval: approvalIcon,
    safety: safetyIcon,
    slow: slowIcon,
    fast: fastIcon,
    scroll: scrollIcon,
    tubes: tubesIcon,
  }[icon];

  return (
    <div
      className={`${styles.base} ${light ? styles.light : null} ${className}`}
    >
      <img src={iconUrl} alt="" {...accessibilityProps_IMG(alt)} />
    </div>
  );
}

Icon.propTypes = {
  light: PropTypes.bool,
  icon: PropTypes.oneOf([
    "syringe",
    "bottles",
    "trials1",
    "trials2",
    "trials3",
    "approval",
    "safety",
    "slow",
    "fast",
    "scroll",
    "tubes",
    null,
  ]).isRequired,
  className: PropTypes.string,
};

Icon.defaultProps = {
  light: false,
  icon: null,
  className: null,
};

export default Icon;
