import React from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { GLOBAL_ROOT_ID } from '../../../../constants';

const useStyles = makeStyles(() => ({
  tooltip: {
    textAlign: "center",
    zIndex: "3200",
    position: "absolute",
    display: "block",
  },
  tooltipContainer: {
    position: "absolute",
    padding: "5px",
    top: "190px",
    minWidth: "100px",
    lineHeight: "18px",
    borderRadius: "4px",
    background: "#fff",
    color: "darkgray",
    boxShadow: "0 4px 8px 0 rgba(61, 65, 72, 0.15)",
    MozBoxShadow: "0 4px 8px 0 rgba(61, 65, 72, 0.15)",
    border: "solid 1px lightgray",
    textAlign: "center",
  },
  title: {
    fontSize: "18px",
    color: "#002961",
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#002961",
  },
}));

const Tooltip = () => {
  const classes = useStyles();
  const tooltip = useSelector(state => state.testingTracker.tableTooltip);

  if (!tooltip) return null;

  // Get the top positions of the global selector for the tool
  let globalYOffset = 0;
  const globalRootRect = document.getElementById(GLOBAL_ROOT_ID);
  if (globalRootRect) {
    globalYOffset = globalRootRect.getBoundingClientRect().top;
  }

  const { left, top, dt, formatter, additionalText, accessor = d => d.value } = tooltip;

  return (
    <div className={classes.tooltip} style={{ top: top - globalYOffset, left }}>
      <div className={classes.tooltipContainer}>
        <div className={classes.title}>{moment(dt, 'YYYY-MM-DD').format('MMM DD')}</div>
        <div className={classes.subTitle}>{`${formatter(accessor(tooltip))} ${additionalText}`}</div>
      </div>
    </div>
  );
};
export default Tooltip;
