import React from "react";
import {scaleLinear} from "d3-scale";
import {max} from "d3-array";
import Bar from "../Bar";
import DashedLine from "../DashedLine";
import {
    PERCENT_FORMATTER,
    PERCENT_POSITIVE_BREAK_POINT,
} from "../../../../../constants";
import {line as d3_line} from "d3-shape";
import ClipPathLine from "../ClipPathLine";
import kebabCase from "lodash.kebabcase";

const PercentPositive = ({data, innerHeight, xScale, stateName}) => {
    // Calculate scales for displaying visualization elements

    // Get max of value and average
    const maxValue = Math.max(
        max(data, (d) => d.value || 0),
        max(data, (d) => d.average)
    );

    const yScale = scaleLinear().range([innerHeight, 0]).domain([0, maxValue]);

    const line = d3_line()
        .x((d) => xScale(d.dt) + xScale.bandwidth() / 2)
        .y((d) => yScale(d.average));

    const belowThresholdHeight =
        yScale(0) -
        yScale(Math.min(yScale.domain()[1], PERCENT_POSITIVE_BREAK_POINT)) || 0;
    const aboveThresholdHeight = Math.max(
        yScale(PERCENT_POSITIVE_BREAK_POINT),
        0
    ) || 0;
    const stateId = kebabCase(stateName);

    return (
        <g className="percent__positive_group">
            <defs>
                <clipPath id={`clip-below-threshold-for-${stateId}`}>
                    <rect
                        x={0}
                        width={xScale.range()[1]}
                        y={yScale(
                            Math.min(yScale.domain()[1], PERCENT_POSITIVE_BREAK_POINT)
                        ) || 0}
                        height={belowThresholdHeight}
                    />
                </clipPath>
                <clipPath id={`clip-above-threshold-for-${stateId}`}>
                    <rect
                        x={0}
                        width={xScale.range()[1]}
                        y={0}
                        height={aboveThresholdHeight}
                    />
                </clipPath>
            </defs>
            <g className="bars">
                {data.map((d) => {
                    return (
                        <Bar
                            item={d}
                            key={`bar-for-percent-positive-${d.dt}`}
                            x={xScale(d.dt)}
                            height={Math.abs(yScale(0) - yScale(d.value)) || 0}
                            y={yScale(Math.max(d.value, 0)) || 0}
                            formatter={PERCENT_FORMATTER}
                            width={xScale.bandwidth()}
                            additionalText={"positive"}
                            accessor={d => d.average}
                        />
                    );
                })}
            </g>
            <g className="trend__line__group">
                <ClipPathLine
                    d={line(data)}
                    direction={"below-threshold"}
                    stateId={stateId}
                />
                <ClipPathLine
                    d={line(data)}
                    direction={"above-threshold"}
                    stateId={stateId}
                />
            </g>
            {aboveThresholdHeight > 0 && (
                <g className="dashed__line__group">
                    <DashedLine
                        x1={0}
                        x2={xScale.range()[1]}
                        y1={yScale(PERCENT_POSITIVE_BREAK_POINT)}
                        y2={yScale(PERCENT_POSITIVE_BREAK_POINT)}
                    />
                </g>
            )}
        </g>
    );
};
PercentPositive.defaultProps = {
    data: [],
    innerHeight: 100,
    xScale: () => null,
    filteredDataPoints: [],
};
export default PercentPositive;
