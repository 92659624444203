import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

import * as GTM from "util/gtm";
import "./styles/index.scss";
import { SUB_ROUTES } from "./constants";
import { getPage } from "data/util";
import styles from "./TestingTracker.module.scss";
import App from "./components/App";
import { Accordion, AccordionItem } from "components/common/Accordion";
import { DataTimeFetched } from "components/common/DataMeta";
import SideNarrative from "components/layout/SideNarrative";
import Button from "components/common/Button";

const TestingTracker = () => {
  const page = getPage("/testing/tracker");
  const { content_blocks } = page;

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-tracker",
    });
  }, []);

  return (
    <SideNarrative
      classNames={{
        sidebar: styles.buffer,
      }}
      pageProps={{ path: "/testing/tracker" }}
      narrative={
        <>
          <DataTimeFetched
            URL={
              "https://jhucoronavirus.azureedge.net/data/ts/testing_tracker.json"
            }
          />

          <ReactMarkdown
            source={content_blocks["track-testing-trends-intro"].content}
            linkTarget="_blank"
          />
          <Accordion className={styles.accordionContainer} autoOpen={false}>
            <AccordionItem
              topContent={
                <div className={styles.accordionTriggerContent}>
                  More Details
                </div>
              }
            >
              <ReactMarkdown
                source={content_blocks["postitivity-sidebar-explainer"].content}
                linkTarget="_blank"
              />
              <ReactMarkdown
                source={
                  content_blocks["track-testing-trends-accordion-1"].content
                }
                linkTarget="_blank"
              />
            </AccordionItem>
            <AccordionItem
              topContent={
                <div className={styles.accordionTriggerContent}>
                  How we calculate positivity
                </div>
              }
            >
              <ReactMarkdown
                source={content_blocks["postitivity-sidebar-explainer"].content}
                linkTarget="_blank"
              />
            </AccordionItem>
          </Accordion>
          <div className={styles.dataNotesLinkContainer}>
            <ReactMarkdown
              source={
                content_blocks["data-notes-side-narrative-explainer"]?.content
              }
              linkTarget="_blank"
            />{" "}
            <Button
              className={styles.linkButton}
              href={`/region-data-notes`}
              buttonStyle="underlined"
              icon={null}
            >
              <span>See Data Notes</span>
            </Button>
          </div>
        </>
      }
    >
      <div id="jh-testing-tool" className={styles.container}>
        <App />
        <ReactMarkdown
          source={content_blocks["track-testing-trends-about-data"].content}
          linkTarget="_blank"
        />
      </div>

      <div className={styles.snapshotMenu}>
        {SUB_ROUTES.map((r) => (
          <Link key={r} to={r}>
            {r}
          </Link>
        ))}
      </div>
    </SideNarrative>
  );
};

export default TestingTracker;
