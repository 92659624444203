import React from "react";
import Polygons from "./Polygons";

const MapSVG = ({ svgWidth, dataSet, field, data, mapData, ttDims, children }) => {

  const margin = {
    left: 20,
    right: 100,
    top: 40,
    bottom: 100,
  };
  const innerWidth = svgWidth - margin.left - margin.right;
  const innerHeight = (svgWidth/1.75) - margin.top - margin.bottom;

  return (
    <svg id="mapSVG" width={svgWidth} height={svgWidth/1.75} viewBox={`0 0 ${svgWidth} ${svgWidth/1.75}`} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <pattern id="diagonalHatch" width="4" height="4" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
          <rect x="0" y="0" width="4" height="4" fill="#efefef" />
          <line x1="0" y1="0" x2="0" y2="4" style={{stroke:"#c7c7c7", strokeWidth:2}} />
        </pattern>
      </defs>
      <g transform={`translate(${margin.left + 30},${margin.top})`}>
        <Polygons
          svgWidth={svgWidth}
          margin={margin}
          innerWidth={innerWidth}
          innerHeight={innerHeight}
          dataSet={dataSet}
          field={field}
          data={data}
          mapData={mapData}
          ttDims={ttDims}
        />
      </g>
      {children}
    </svg>
  )
}

export default MapSVG;
