// /* global UstreamEmbed */
import React, { useRef, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as GTM from "util/gtm";
import styles from "./DataInMotion.module.scss";
import { getPage } from "data/util";
import Page from "components/layout/Page";
import HFormat from "components/common/HFormat";
import Share from "components/common/Share";
import ReactMarkdown from "react-markdown";
import ColumnLayout from "components/layout/ColumnLayout";
import ContentOptions from "./contentOptions";
import { formatDateTitleString } from "data/data-viz";
import { useDailyVideoData } from "./DataInMotionCard";
import TextBanner from "components/common/TextBanner";

const InteriorHeader = ({ data, pageData }) => {
  const { title } = pageData;
  return (
    <div className={styles.container}>
      <ColumnLayout layout={[[6, 6], [12]]}>
        <div className={styles.contentLeft}>
          <HFormat size={1} noMarginBtm>
            {title}
            {!!data ? `: ${formatDateTitleString(data.publishedAt)}` : null}
          </HFormat>
        </div>
        <div className={styles.contentRight}>
          <Share label="Share" />
        </div>
      </ColumnLayout>
    </div>
  );
};

const DataInMotion = ({ location }) => {
  const iframeContainer = useRef(null);
  const pageData = getPage(location.pathname);
  pageData.meta.image = "/images/thumbnails/covid-in-motion-thumb.jpg";
  const [data, setData] = useState();
  const [mediaHeight, setMediaHeight] = useState(354);
  const [windoWidth, setWindoWidth] = useState(1024);
  const headerData = {
    data,
    pageData,
  };

  useDailyVideoData({
    setData: (response) => {
      if (!data && !!response && !!response.videoId) {
        setData(response);
      }
    },
  });

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
    const handleResize = () => {
      if (iframeContainer && iframeContainer.current) {
        const newHeight = iframeContainer.current.clientWidth / 1.78;
        setMediaHeight(newHeight);
      }
      setWindoWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const containerVideoHeightAdjust = {
    height: `${mediaHeight}px`,
  };
  const currentLink = data
    ? `https://www.youtube.com/embed/${data.videoId}?rel=0`
    : null;

  const renderVideoFrame = () => {
    return (
      !!currentLink && (
        <iframe
          title="Youtube/Vimeo"
          src={currentLink}
          style={{ border: 0, height: `${mediaHeight}px` }}
          allowFullScreen
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
        ></iframe>
      )
    );
  };

  const textBannerProps = {
    title: "Friday COVID-19 Expert Briefing",
    content:
      "Please watch and participate in the Johns Hopkins 30-minute COVID-19 briefing on Fridays, from noon to 12:30pm to get expert insights on what you need to know now.",
    label: "Visit",
    href: "/live/events/covid-19-briefing-expert-insights",
    targetBlank: false,
  };

  return (
    <Page
      page={pageData}
      interiorHeader={<InteriorHeader {...headerData} />}
      flush
    >
      <div className={styles.container}>
        {windoWidth >= 768 && (
          <TextBanner className={styles.textBanner} {...textBannerProps} />
        )}
        <div className={styles.contentContainer}>
          <div
            ref={iframeContainer}
            className={styles.iframeContainer}
            style={containerVideoHeightAdjust}
          >
            {renderVideoFrame()}
          </div>
          {windoWidth < 768 && (
            <TextBanner className={styles.textBanner} {...textBannerProps} />
          )}
          <div>
            <HFormat size={3} noMarginBtm>
              More Information
            </HFormat>
            <ReactMarkdown
              className={styles.embedDescription}
              source={
                pageData.content_blocks["covid-19-daily-video-content"].content
              }
            />
          </div>
        </div>
        <div className={styles.cardOptions}>
          <ContentOptions />
        </div>
      </div>
    </Page>
  );
};

export default withRouter(DataInMotion);
