const baseArticleImagePath = "/images/articles";

// Keyed by article 'slug'
const localImagePaths = {
  "vaccination-transmission-and-masks": `${baseArticleImagePath}/blogs/24-MORNING-FLUVCOVID-articleLarge.jpg`,

  "building-trust-in-vaccination": {
    "Continuum of Vaccine Acceptance": `${baseArticleImagePath}/reports/continuum-vaccine-acceptance.png`,
    "Increasing Vaccination Model": `${baseArticleImagePath}/reports/increasing-vaccination-model.png`,
  },

  "science-in-the-face-of-fear-commentary-on-vaccine-hesitancy": {
    "Cumulative Incidence Curves of COVID-19 - Pfizer": `${baseArticleImagePath}/Pfizer-VRBPAC-12.10.jpeg`,
    "Cumulative Incidence Curves of COVID-19 - Moderna": `${baseArticleImagePath}/Moderna-VRBPAC-12.17.jpeg`,
  },

  "we-need-a-daily-data-dump": {
    "Frequency-Update-PDI-Blog-0608.jpg": `${baseArticleImagePath}/Frequency-Update-PDI-Blog-0608.jpg`,
    "Cheetah-Pixelation-opt.jpg": `${baseArticleImagePath}/Cheetah-Pixelation-opt.jpg`,
    "Michigan Hospitalizations.png": `${baseArticleImagePath}/Michigan_Hospitalizations.png`,
  },

  "long-term-covid-19-data-surveillance-is-an-international-responsibility": {
    "Data-Flow-2.jpg": `${baseArticleImagePath}/Data-Flow-2.jpg`,
    "Variant-Peak-Comparison.jpg": `${baseArticleImagePath}/Variant-Peak-Comparison.jpg`,
    "GISRS-Countries.jpg": `${baseArticleImagePath}/GISRS-Countries.jpg`,
  },

  "demographic-data-disarray-hurts-covid-19-policies": `${baseArticleImagePath}/tree-charts.jpg`,

  "new-data-method-drives-hospital-care-for-covid-19-patients": `${baseArticleImagePath}/SCARP-workflow-diagram.jpg`,

  "learning-from-failures-in-education-data": `${baseArticleImagePath}/eSchool-Reopening.jpg`,

  "data-streams-are-essential-no-matter-how-small": `${baseArticleImagePath}/County-Cases-0708.jpg`,

  "demystifying-the-delta-variant-with-data": {
    "Stacked-International-Graph.jpg": `${baseArticleImagePath}/Stacked-International-Graph.jpg`,
    "Nysa-US-Heat-Map.jpg": `${baseArticleImagePath}/Nysa-US-Heat-Map.jpg`,
    "US-Delta-and-Cases.jpg": `${baseArticleImagePath}/US-Delta-and-Cases.jpg`,
  },

  "complete-vaccination-data-a-moving-target": {
    "MD-Partially-Vax": `${baseArticleImagePath}/MD-Partially-Vax.jpg`,
    "Just-Flowchart": `${baseArticleImagePath}/Just-Flowchart.jpg`,
    "Current-Vaccine-Data-Flow-Crop": `${baseArticleImagePath}/Current-Vaccine-Data-Flow-Crop.jpg`,
    "Future-Vaccine-Data-Flow": `${baseArticleImagePath}/Future-Vaccine-Data-Flow.jpg`,
  },

  "state-data-reporting-reduction-continues-amidst-covid-19-resurgence": {
    "Cadence-Viz.jpg": `${baseArticleImagePath}/Cadence-Viz.jpg`,
    "Florida-Case-Data-modified.jpg": `${baseArticleImagePath}/Florida-Case-Data-modified.jpg`,
  },

  "determining-discrete-data-points-from-the-sex-and-gender-spectra": `${baseArticleImagePath}/Sex-Data-Availability-Map.jpg`,

  "preparing-state-data-systems-for-booster-doses-and-breakthrough-cases": `${baseArticleImagePath}/Mississippi-Booster-Data.jpg`,

  "the-state-of-state-level-breakthrough-case-reporting": {
    "US-Breakthrough-Data-Reporting-Map.jpg": `${baseArticleImagePath}/US-Breakthrough-Data-Reporting-Map.jpg`,
    "Wisconsin-Breakthrough-Data.jpg": `${baseArticleImagePath}/Wisconsin-Breakthrough-Data.jpg`,
  },
  "our-person-powered-public-data-machine": {
    "Race-Transparency-Map-Old.jpg": `${baseArticleImagePath}/Race-Transparency-Map-Old.jpg`,
    "Demo-Prototype.jpg": `${baseArticleImagePath}/Demo-Prototype.jpg`,
    "Comp-Image.jpg": `${baseArticleImagePath}/Comp-Image.jpg`,
    "CRC-Demo-Map-New.jpg": `${baseArticleImagePath}/CRC-Demo-Map-New.jpg`,
  },
  "filling-in-the-map-of-covid-19-demographic-data": {
    "All-States-Race.jpg": `${baseArticleImagePath}/All-States-Race.jpg`,
    "Unknowns-Vax.jpg": `${baseArticleImagePath}/Unknowns-Vax.jpg`,
    "SC-Vax-Labeled.jpg": `${baseArticleImagePath}/SC-Vax-Labeled.jpg`,
  },

  "equipping-educators-to-lead-with-data-for-safe-schools": {
    "All-States-Age.jpg": `${baseArticleImagePath}/All-States-Age.jpg`,
    "Global-Education-Map.jpg": `${baseArticleImagePath}/Global-Education-Map.jpg`,
    "Dallas-Data.jpg": `${baseArticleImagePath}/Dallas-Data.jpg`,
    "TX-County-Data.jpg": `${baseArticleImagePath}/TX-County-Data.jpg`,
    "School-Age-Vaccine-Data.jpg": `${baseArticleImagePath}/School-Age-Vaccine-Data.jpg`,
  },

  "collecting-comorbidity-data-to-identify-covid-19-correlations": {
    "diabetic-graph.jpg": `${baseArticleImagePath}/diabetic-graph.jpg`,
    "comorbidities-dashboard.jpg": `${baseArticleImagePath}/comorbidities-dashboard.jpg`,
  },

  "constrained-testing-efforts-require-data-on-testing-accessibility": {
    "Testing-Demographic-Reports.jpg": `${baseArticleImagePath}/Testing-Demographic-Reports.jpg`,
    "CDC-Vaccine-finder.jpg": `${baseArticleImagePath}/CDC-Vaccine-finder.jpg`,
  },

  "leveraging-the-law-with-public-health-data": {
    "DC-Life-Expectancies.jpg": `${baseArticleImagePath}/DC-Life-Expectancies.jpg`,
    "OBGYN-Deserts.jpg": `${baseArticleImagePath}/OBGYN-Deserts.jpg`,
    "Global-Vaccines-Labeled.jpg": `${baseArticleImagePath}/Global-Vaccines-Labeled.jpg`,
  },

  "vaccine-hesitancy-involves-more-than-distrust-of-science-and-government": {
    "Montana-CDC-Vaccine-Availability.jpg": `${baseArticleImagePath}/Montana-CDC-Vaccine-Availability.jpg`,
    "Vaccine-Behavior-Barriers.jpg": `${baseArticleImagePath}/Vaccine-Behavior-Barriers.jpg`,
    "Vaccine-Hesitancy-Reasons.jpg": `${baseArticleImagePath}/Vaccine-Hesitancy-Reasons.jpg`,
    "Lauren-Vax-Fig.jpg": `${baseArticleImagePath}/Lauren-Vax-Fig.jpg`,
  },

  "states-and-cdc-improve-breakthrough-and-booster-data-reporting": {
    "States-Booster-Composite.jpg": `${baseArticleImagePath}/States-Booster-Composite.jpg`,
    "CDC-Breakthrough-Case-and-Death-Overall.jpg": `${baseArticleImagePath}/CDC-Breakthrough-Case-and-Death-Overall.jpg`,
    "August-Risk-Reduction.jpg": `${baseArticleImagePath}/August-Risk-Reduction.jpg`,
  },

  "private-institutions-pioneer-covid-19-data-sharing": {
    "NYT-LTCF-Deaths.jpg": `${baseArticleImagePath}/NYT-LTCF-Deaths.jpg`,
    "Global-Dashboard.jpg": `${baseArticleImagePath}/Global-Dashboard.jpg`,
    "FL-Funding-Image.jpg": `${baseArticleImagePath}/FL-Funding-Image.jpg`,
  },

  "detailed-demographic-data-highlights-health-disparities": {
    "Indiana-Disparity-Explorer.jpg": `${baseArticleImagePath}/Indiana-Disparity-Explorer.jpg`,
    "Hispanic-Case-Disparities.jpg": `${baseArticleImagePath}/Hispanic-Case-Disparities.jpg`,
    "Hispanic-Testing-Disparities.jpg": `${baseArticleImagePath}/Hispanic-Testing-Disparities.jpg`,
  },

  "lack-of-test-positivity-standards-for-covid-demands-recalculation": {
    "Testing-Positivity-Iconography.jpg": `${baseArticleImagePath}/Testing-Positivity-Iconography.jpg`,
    "State-Positivity-with-Hierarchy.jpg": `${baseArticleImagePath}/State-Positivity-with-Hierarchy.jpg`,
  },

  "2021-pandemic-data-year-in-review": {
    "Global-Case-Deaths.jpg": `${baseArticleImagePath}/Global-Case-Deaths.jpg`,
    "Global-Vaccines.jpg": `${baseArticleImagePath}/Global-Vaccines.jpg`,
    "Race-Data.jpg": `${baseArticleImagePath}/Race-Data.jpg`,
    "US-Case-Deaths.jpg": `${baseArticleImagePath}/US-Case-Deaths.jpg`,
    "US-Vaccines.jpg": `${baseArticleImagePath}/US-Vaccines.jpg`,
  },

  "antiquated-data-infrastructure-delays-pandemic-reporting": {
    "COVID-Wkst.jpg": `${baseArticleImagePath}/COVID-Wkst.jpg`,
    "Data-Initiative-Roadmap.jpg": `${baseArticleImagePath}/Data-Initiative-Roadmap.jpg`,
    "CDC-Data-Flow-Image.jpg": `${baseArticleImagePath}/CDC-Data-Flow-Image.jpg`,
  },

  "mandated-hospitalization-data-is-a-burden-and-a-boon": {
    "Remdesivir-Image.jpg": `${baseArticleImagePath}/Remdesivir-Image.jpg`,
    "Maryland-Data.jpg": `${baseArticleImagePath}/Maryland-Data.jpg`,
    "Hospital-Data-Cleanliness.jpg": `${baseArticleImagePath}/Hospital-Data-Cleanliness.jpg`,
    "CRISP-Insights.jpg": `${baseArticleImagePath}/CRISP-Insights.jpg`,
    "California-Hospital-Dashboard.jpg": `${baseArticleImagePath}/California-Hospital-Dashboard.jpg`,
  },

  "home-tests-confound-covid-19-testing-data": {
    "Test-Options-Flow.jpg": `${baseArticleImagePath}/Test-Options-Flow.jpg`,
    "Arkansas-Home-Tests.jpg": `${baseArticleImagePath}/Arkansas-Home-Tests.jpg`,
  },

  "moving-goalposts-how-data-show-covid-19-impacted-the-nfl": {
    "Top-Teams.jpg": `${baseArticleImagePath}/Top-Teams.jpg`,
    "COVID-Surges.jpg": `${baseArticleImagePath}/COVID-Surges.jpg`,
    "COVID-Performance.jpg": `${baseArticleImagePath}/COVID-Performance.jpg`,
  },

  "data-modernization-improving-data-to-improve-health": {
    "DMI-Roadmap.jpg": `${baseArticleImagePath}/DMI-Roadmap.jpg`,
  },

  "comparing-cases-deaths-and-hospitalizations-indicates-omicron-less-deadly": {
    "US-Data-Waves.jpg": `${baseArticleImagePath}/US-Data-Waves.jpg`,
    "Hosp-Over-Cases.jpg": `${baseArticleImagePath}/Hosp-Over-Cases.jpg`,
    "Deaths-Over-Cases.jpg": `${baseArticleImagePath}/Deaths-Over-Cases.jpg`,
  },

  "globally-sourced-open-sequencing-data-predicts-pandemic-trajectory": {
    "Variant-Proportions.jpg": `${baseArticleImagePath}/Variant-Proportions.jpg`,
    "Total-Sequences.jpg": `${baseArticleImagePath}/Total-Sequences.jpg`,
  },

  "shifting-dosages-and-terminologies-limit-utility-of-vaccination-data": {
    "Unvax-Comparison": `${baseArticleImagePath}/Unvax-Comparison.jpg`,
    "Vaccine-Data-FlowChart": `${baseArticleImagePath}/Vaccine-Data-FlowChart.jpg`,
  },

  "reduce-data-reporting-cadence-for-an-endemic-disease-not-quite-yet": {
    "UK-Cases.jpg": `${baseArticleImagePath}/UK-Cases.jpg`,
    "March-Reporting-Cadence.jpg": `${baseArticleImagePath}/March-Reporting-Cadence.jpg`,
  },

  "endemic-disease-surveillance-requires-quality-testing-and-hospitalization-data": {
    "Data-Flow.jpg": `${baseArticleImagePath}/Data-Flow.jpg`,
  },

  "quantifying-community-resilience-to-equip-local-government": {
    "Maryland-Resilience.jpg": `${baseArticleImagePath}/Maryland-Resilience.jpg`,
    "Copewell-US.jpg": `${baseArticleImagePath}/Copewell-US.jpg`,
    "Baltimore-County-Copewell-Data.jpg": `${baseArticleImagePath}/Baltimore-County-Copewell-Data.jpg`,
  },

  "u-s-officially-surpasses-1-million-covid-19-deaths": {
    "top10100.jpg": `${baseArticleImagePath}/top10100.jpg`,
    "top10-total.jpg": `${baseArticleImagePath}/top10-total.jpg`,
  },

  "u-s-surpasses-100-million-reported-covid-19-cases": {
    "2022-12-20-table.jpg": `${baseArticleImagePath}/2022-12-20-table.jpg`,
  },
};

export const getArticleImageEmbed = (slug, key = "") => {
  return typeof localImagePaths[slug] === "string"
    ? localImagePaths[slug]
    : localImagePaths[slug]?.[key];
};
