import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import * as GTM from "util/gtm";
import TestingPer100kTable from "./TestingPer100kTable";
import { DataTime, DataSources } from "components/common/DataMeta";
import { getPage } from "data/util";
import classNames from "classnames/bind";
import styles from "./TestingPer100k.module.scss";
import SideNarrative from "components/layout/SideNarrative";

import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import BubblePlot from "./BubblePlot";
import addThousandsSeparator from "util/addThousandsSeparator";

const cx = classNames.bind(styles);

const formatInternationalPositivityData = (data) => {
  const {
    "State/Country": states,
    "Daily positivity": dailyPositivity,
    "Daily confirmed": dailyConfirmed,
  } = data;

  return Object.keys(states).map((i) => {
    const res = {
      regionName: states[i],
      percPositive: dailyPositivity[i]
        ? `${(100 * parseFloat(dailyPositivity[i])).toFixed(2)}%`
        : "-",
      confirmed: addThousandsSeparator(parseFloat(dailyConfirmed[i])),
    };
    return res;
  });
};

const TestingPer100k = ({ location }) => {
  const pageData = getPage(location.pathname);
  const { content_blocks } = pageData;

  const ref = useRef(null);
  const [width] = useDimensions(ref);

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["testing"],
      contentDataVisualization: "testing-international-comparison",
    });
  }, []);

  const [
    internationalPositivityData,
    internationalPositivityDataLoading,
  ] = useFetch(
    "https://jhucoronavirus.blob.core.windows.net/api/v2/testing/international/testing_positivity_int.json"
  );

  const [graphData, isGraphDataLoading] = useFetch(
    "https://jhucoronavirus.azureedge.net/api/v2/testing/international/testing.json"
  );

  const [selectedRegion, setSelectedRegion] = useState("");
  const handleChoice = (e) => {
    setSelectedRegion(e.currentTarget.value);
  };

  let tableData;
  if (!internationalPositivityDataLoading) {
    tableData = formatInternationalPositivityData(internationalPositivityData);
  }

  return (
    <SideNarrative
      classNames={{
        sidebar: styles.buffer,
      }}
      narrative={
        <>
          <DataTime dataSet="testing-per-100k-pop" />
          <ReactMarkdown
            source={content_blocks["testing-per-100k-page-visual"]?.content}
          />
          <ReactMarkdown
            source={content_blocks["testing-per-100k-page-intro"]?.content}
          />
          <ReactMarkdown
            source={content_blocks["testing-data-disclaimer"]?.content}
          />
          <DataSources dataSet="testing-per-100k-pop" />
        </>
      }
    >
      <div className={cx("container")}>
        <div className={styles.controlsContainer}>
          <div className={styles.chooser}>
            <select value={selectedRegion} onChange={handleChoice}>
              <option value={""}>Select a region</option>
              {!isGraphDataLoading &&
                [...new Set(graphData.map((d) => d.region))]
                  .sort()
                  .map((d, i) => (
                    <option key={`region${i}`} value={d}>
                      {d}
                    </option>
                  ))}
            </select>
          </div>
        </div>
        <div ref={ref}>
          {!isGraphDataLoading && (
            <BubblePlot
              data={graphData}
              selectedRegion={selectedRegion}
              width={width}
            />
          )}
          {!internationalPositivityDataLoading && !!tableData && (
            <div className={cx("table")}>
              <TestingPer100kTable data={tableData} />
            </div>
          )}
        </div>
      </div>
    </SideNarrative>
  );
};

export default TestingPer100k;
