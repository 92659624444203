import React from "react";
import styles from "./VaccineDataGraphLegend.module.scss";

const Legend = ({ datakey, svgWidth, gDims, margin, mobile }) => {
  return (
    <g width={mobile ? gDims[0] : margin.right} height="120" transform={`translate(${mobile ? gDims[0]/2 : gDims[0]+margin.right},${mobile ? 60 : margin.top+30})`}>
      {datakey === "state" &&
        <>
          <line x1={10} x2={30} y1={10} y2={10} className={styles.thresholdLine} />
          <text x={30} y={15} className={styles.legendText}>US Average</text>
        </>
      }
      <line x1={10} x2={30} y1={30} y2={30} className={styles.maxLine} />
      <text x={30} y={35} className={styles.legendText}>Max Value</text>
      <circle r={6} cx={20} cy={50} style={{fill:`#60C6C6`}} />
      <text x={30} y={55} className={styles.legendText}>Above {datakey === "state" ? "US" : "World"} Avg.</text>
      <circle r={6} cx={20} cy={70} style={{fill:`#E29360`}} />
      <text x={30} y={75} className={styles.legendText}>Below {datakey === "state" ? "US" : "World"} Avg.</text>
      <circle r={6} cx={20} cy={90} style={{fill:`#000`}} />
      <text x={30} y={95} className={styles.legendText}>Not Available</text>
    </g>
  )
};

export default Legend;
