import React, { Component } from "react";
import * as GTM from "util/gtm";
import ReactMarkdown from "react-markdown/with-html";

import styles from "./Article.module.scss";
import contentStyles from "./ContentStyles.module.scss";
import Page from "components/layout/Page";
import { checkShouldContentDisplay, getArticle } from "data/util";
import ArticleEmbed from "./ArticleEmbed";
import { Redirect } from "react-router-dom";

class Article extends Component {
  componentDidMount = () => {
    GTM.clear();
  };

  Image = ({ alt, src }) => {
    if (alt === "__embed") {
      return <ArticleEmbed embedId={src} />;
    }

    const article = getArticle(this.props.location.pathname);

    const getAttachmentSrc = (index) => article.attachments[index] || src;

    const useSrc =
      src.indexOf("attachment") === 0
        ? getAttachmentSrc(parseInt(src.split("attachment")[1]) - 1)
        : src;

    return <img alt={alt} src={useSrc} className={styles.image} />;
  };

  render() {
    const article = getArticle(this.props.location.pathname);

    if (!article?.slug || !checkShouldContentDisplay(article))
      return <Redirect to="/404" />;

    return (
      <Page page={article}>
        <div className={styles.container}>
          <ReactMarkdown
            className={contentStyles[article.slug]}
            source={article.content}
            linkTarget="_blank"
            renderers={{ image: this.Image }}
            escapeHtml={false}
          />
        </div>
      </Page>
    );
  }
  ReactMarkdown;
}

export default Article;
