import React, { useContext, useState, useEffect } from "react";
import styles from "./DetailedDemoBarTooltip.module.scss";
import { scaleBand, scaleLinear } from "d3-scale";

const TooltipContext = React.createContext(
  {state: {
    show: false,
    x: 0,
    y: undefined,
    content: undefined,
    dataset: undefined,
    innerwidth: 600,
    innerheight: 400,
    margintop: 40,
    marginleft: 160,
    bars: undefined,
    lines: 1
  },
  dispatch: undefined
  }
)

const Tooltip = ({ width, height, dataset, xMax }) => {
  // const [xMax, setXMax] = useState(0);
  const [yDomain, setYDomain] = useState([]);
  const [translateX, setTranslateX] = useState(0);
  const [translateY, setTranslateY] = useState(0);
  const { state } = useContext(TooltipContext);

  useEffect(() => {
    if (state.bars) {
      setYDomain(state.bars)
    }
  }, [state.bars])

  useEffect(() => {
    const x = scaleLinear().domain([0, xMax]).range([0,(state.innerwidth)]).nice();
    const y = scaleBand().domain(yDomain).range([state.innerheight, 0]).round(true).padding(0.25);
    // setTranslateX(x(state.x)+22+state.marginleft);
    // setTranslateY(state.innerheight+(state.margintop/2)-y(state.y));
    setTranslateX(x(state.x)+state.marginleft-(width/2)-10)
    setTranslateY(state.innerheight+(state.margintop/2)-y(state.y)-(state.lines === 1 ? 19 : 35))

  }, [xMax, yDomain, state.innerwidth, state.innerheight, state.margintop, state.marginleft, state.x, state.y, state.lines, width])

  return (
    <>
      {state && state.content && state.dataset === dataset && (
        <g
          transform={`translate(${translateX}, ${translateY})`}
          style={{
            visibility: state.show ? "visible" : "hidden",
            pointerEvents: "none",
          }}
          id="tooltipG"
        >
          <foreignObject width={width} height={height}>
            <div className={styles.tipBox} xmlns="http://www.w3.org/1999/xhtml">
              <p>{state.content}</p>
            </div>
          </foreignObject>
        </g>
      )}
    </>
  );
};

export default Tooltip;
export { TooltipContext };
