import React, { useContext } from "react";
import Legend from "./Legend";
import { TooltipContext } from "../../../Tooltip";
import { findStateCode } from "data/geo";
import { select, event } from "d3-selection";
import * as geo from "d3-geo";
import { scaleLinear, scaleOrdinal } from "d3-scale";
import { interpolateRgb } from "d3-interpolate";
import { extent } from "d3-array";
import * as albersT from "geo-albers-usa-territories";

import styles from "./DemographicsMapSVG.module.scss";

const d3 = {
  select,
  event,
  ...geo,
};

const Polygons = ({
  svgWidth,
  margin,
  innerWidth,
  innerHeight,
  dataSet,
  bin,
  data,
  mapData,
  demographic,
}) => {
  const dataDate =
    dataSet.value === "cases" || dataSet.value === "deaths"
      ? data.case_death_date
      : data.vax_test_date;
  const tooltipContext = useContext(TooltipContext);
  const projection = albersT
    .geoAlbersUsaTerritories()
    .translate([innerWidth / 2, innerHeight / 2])
    .scale([innerWidth]);

  const path = d3.geoPath().projection(projection);
  let color = null;
  let filteredData = null;

  if (data && data.dataset) {
    if (bin === "all-content") {
      filteredData = [
        ...new Set(
          data.dataset
            .map((d) => {
              const result = d[dataSet.value]?.map((e) => {
                if (typeof e.value === "number") return e.state;
                return null;
              });
              return result;
            })
            .flat()
        ),
      ];
      color = scaleOrdinal().range([dataSet.color]).domain(["Has Data"]);
    } else {
      filteredData = data.dataset.find((d) => d.bin === bin)
        ? data.dataset.find((d) => d.bin === bin)[dataSet.value]
        : [];
      if (filteredData) {
        color = scaleLinear()
          .range(["#F2F3E0", dataSet.color])
          .interpolate(interpolateRgb);
        color.domain(extent(filteredData.map((d) => d.value)));
      }
    }
  }

  function handleMouseOut(d) {
    tooltipContext.dispatch({ show: false });
  }

  function handleMouseOver(d) {
    if (bin !== "all-content") {
      let statecode = findStateCode(d.properties.name);
      let datapoint = data.dataset.find((d) => d.bin === bin);
      const [x, y] = path.centroid(d);
      tooltipContext.dispatch({
        show: true,
        x: x,
        y: y,
        datakey: dataSet,
        bin: bin,
        state: statecode,
        cases:
          datapoint && datapoint.cases
            ? datapoint.cases.find((p) => p.state === statecode)
            : null,
        deaths:
          datapoint && datapoint.deaths
            ? datapoint.deaths.find((p) => p.state === statecode)
            : null,
        tests:
          datapoint && datapoint.tests
            ? datapoint.tests.find((p) => p.state === statecode)
            : null,
        vaccines:
          datapoint && datapoint.vaccines
            ? datapoint.vaccines.find((p) => p.state === statecode)
            : null,
      });
    }
  }

  const handleFill = (d) => {
    let statecode = findStateCode(d.properties.name);
    let datapoint = filteredData?.find((p) =>
      bin === "all-content" ? p === statecode : p.state === statecode
    );
    if (datapoint === undefined || color === null) {
      return 'url("#diagonalHatch")';
    } else {
      if (datapoint.value === "null" || datapoint.value === null) {
        return 'url("#diagonalHatch")';
      } else {
        return bin === "all-content"
          ? color("Has Data")
          : color(datapoint.value);
      }
    }
  };

  return (
    <>
      <g id="polygons">
        {mapData &&
          mapData.features.map((d, i) => {
            return (
              <path
                key={`path_${i}`}
                id={`id_${i}`}
                d={path(d)}
                style={{ fill: handleFill(d) }}
                onMouseLeave={() => handleMouseOut(d)}
                onMouseOver={() => handleMouseOver(d)}
                className={styles.path}
              />
            );
          })}
      </g>
      <Legend
        color={color}
        category={dataSet}
        bin={bin}
        margin={margin}
        width={innerWidth}
        height={innerHeight}
        demographic={demographic}
        dataDate={dataDate}
      />
    </>
  );
};

export default Polygons;
