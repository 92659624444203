import React, { useEffect } from 'react';
import * as GTM from "util/gtm";
import { slugify } from "data/geo";
import {useHistory, useParams} from 'react-router-dom';
import DatasetToggle from '../DatasetToggle';
import TimelineControls from '../TimelineControls';
import StateSelect from './StateSelect';
import InfoModal from '../InfoModal';
import {useSelector} from 'react-redux';
import selectedStateSelector from '../../../redux/selectors/state.selector';
import {
    dateAccessor,
    EVENT_TYPE_COLORS,
    DEFAULT_DATASET,
    DEFAULT_EVENT_ID,
    NEW_CONFIRMED_CASES,
    NEW_DEATHS,
    BASE_STATE_TIMELINE_ROUTE,
    DOWNLOAD_URL
} from '../../../constants';
import {
    nameAccessor,
    COMMA_FORMATTER,
    DEFAULT_STATE_NAME,
    valueAccessor,
    COLORS
} from '../../../../Shared/constants';
import BaseChart from './BaseChart';
import BarChart from './BarChart';
import {getMatchingValue, getZoomedInDateExtent} from '../../../utils';
import moment from 'moment';
import {makeStyles} from '@material-ui/core/styles';
import selectedDatasetSelector from '../../../redux/selectors/dataset.selector';
import {extent, max} from 'd3-array';
import EventDescription from "./EventDescription";
import Legend from '../Legend';
import DownloadLink from "../../../../Shared/DownloadLink";
import * as styles from "./Timeline.module.scss"

const useStyles = makeStyles(() => ({
    title: {
        fontSize: '26px',
        fontWeight: 'bold',
        color: COLORS.textBlue,
        margin: '15px 0'
    },
    headerRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        flexWrap: "wrap",
    },
    stateSelect: {
        marginLeft: '-8px'
    },
    list: {
        listStyle: 'none',
        padding: "0",
        listStyleType: "none",
        position: 'relative'
    },
    eventsWrapper: {
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.16)',
        marginTop: '15px',
        borderRadius: '5px'
    },
    eventsHeading: {
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: `1px solid ${COLORS.blueDark}`
    },
    eventDate: {
        fontWeight: 'bold',
        color: COLORS.blueDark,
        fontSize: '14px',
    },
    eventCumulativeCounts: {
        fontSize: '14px',
        textTransform: 'uppercase',
        display: 'flex',
        color: COLORS.gray,
        '&> div:first-child': {
            margin: '0 5px 0 0'
        },
        '&> div:last-child': {
            margin: '0 0 0 5px'
        }
    },
    cumulativeCount: {
        fontWeight: 'bold',
    },
    eventDescriptionSection: {
        padding: '5px 10px',
    },
    nameContainer: {
        display: "flex",
        flexDirection: 'row'
    },
    downloadLink: {
        zIndex: 10,
        alignSelf: 'center',
        margin: '20px 0',
        paddingLeft: '10px',
    }
}));

const Timeline = ({ match }) => {
    const history = useHistory();
    const classes = useStyles();
    const zoomEffect = useSelector(state => state.stateTimeline.zoomEffect)
    const data = useSelector(state => state.stateTimeline.data);

    // Handle query params
    let {stateName = DEFAULT_STATE_NAME, dataset = DEFAULT_DATASET, eventId = DEFAULT_EVENT_ID} = useParams();

    useEffect(() => {
        GTM.clear();
        GTM.push({
            pageVerticals: "tracking",
            contentDataVisualization: "state-timeline",
            contentState: stateName ? slugify(stateName) : slugify(DEFAULT_STATE_NAME)
        })
    }, [stateName]);

    // Get selected values from query params
    const selectedState = useSelector(selectedStateSelector(stateName));
    const selectedDataset = useSelector(selectedDatasetSelector(dataset));

    // Get data and events for selected state
    const stateData = data[nameAccessor(selectedState)];
    const events = (!!stateData && stateData.events) ? stateData.events.map(d => ({
        ...d,
        dt: moment(dateAccessor(d), 'YYYY-MM-DD'),
    })) : [];

    // Query param validation
    eventId = parseInt(eventId, 10);
    if (eventId > max(events, valueAccessor)) {
        history.push(`${BASE_STATE_TIMELINE_ROUTE}/${valueAccessor(selectedDataset)}/${valueAccessor(selectedState)}`, {noScroll: true});
        return null;
    }

    const selectedEvent = events.find(d => valueAccessor(d) === eventId);

    // Get cumulative values for event list section
    const selectedNewCases = getMatchingValue(stateData.values[NEW_CONFIRMED_CASES], dateAccessor(selectedEvent));
    const selectedNewDeaths = getMatchingValue(stateData.values[NEW_DEATHS], dateAccessor(selectedEvent));

    // Get values for selected dataset
    const selectedValues = stateData.values[valueAccessor(selectedDataset)].map(d => ({
        ...d,
        dt: moment(dateAccessor(d)),
    }));

    // Let's get range of the centered event if we're zoomed in
    let dateExtent = extent(selectedValues, dateAccessor);
    if (zoomEffect) {
        dateExtent = getZoomedInDateExtent(selectedValues, selectedEvent, dateAccessor);
    }

    return (
        <div id="timeline" className="timeline">
            <div className={classes.nameContainer}>
                <h1 className={classes.title}>{nameAccessor(selectedState)}</h1>
                <div className={classes.downloadLink}>
                    <DownloadLink
                        href={`${DOWNLOAD_URL}/state-timeline-${dataset}-${stateName}-event-${eventId}.png`}/>
                </div>
            </div>
            <div className={styles.headerRowContainer}>
                <div className={styles.headerRow}>
                    <div className={classes.stateSelect}>
                        <StateSelect selectedState={selectedState}/>
                    </div>
                    <InfoModal/>
                    <DatasetToggle selectedDataset={selectedDataset}/>
                </div>
            </div>
            <div className={styles.headerRowControls}>
                <Legend/>
                <TimelineControls maxEventId={max(events, valueAccessor)}/>
            </div>
            <BaseChart className="visualization__container">
                <BarChart data={selectedValues} events={events}
                          dateExtent={dateExtent}
                          selectedEvent={selectedEvent}
                          zoomEffect={zoomEffect}
                          selectedDataset={selectedDataset}/>
            </BaseChart>
            <div className={classes.eventsWrapper}>
                <div className={classes.eventsHeading}>
                    <div className={classes.eventDate}>{dateAccessor(selectedEvent).format('MMM DD, YYYY')}</div>
                    <div className={classes.eventCumulativeCounts}>
                        <div>
                            <span
                                className={classes.cumulativeCount}>{`${COMMA_FORMATTER(selectedNewCases.cumulative)} `}</span>Cumulative
                            Cases
                        </div>
                        &nbsp;&#124;&nbsp;
                        <div>
                            <span
                                className={classes.cumulativeCount}>{`${COMMA_FORMATTER(selectedNewDeaths.cumulative)} `}</span>Cumulative
                            Deaths
                        </div>
                    </div>
                </div>
                <div className={classes.eventDescriptionSection}>
                    <ul className={classes.list}>
                        {selectedEvent.values.map((event, i) => {
                            return (
                                <EventDescription key={`event-item-for-${i}`} color={EVENT_TYPE_COLORS[event.type]}
                                                  description={event.description}/>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};
export default Timeline;
