import React, { useState, useEffect } from "react";
import cx from "classnames";
import moment from "moment";
import BaseChart from "./BaseChart";
import BarChart from "./BarChart";
import FacetChart from "./FacetChart";
import { BAR_CHART_COLORS } from "./constants";
import HFormat from "components/common/HFormat";
import { ReactComponent as GraphIcon } from "images/icons/chart-timeline.svg";
import styles from "./RegionDataTimeline.module.scss";
import TabBar from "components/layout/shell/TabBar";
import Button from "components/common/Button";
import useWindowSize from "util/hooks/useWindowSize";
import CustomSelect from "components/common/CustomSelect";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { accessibilityProps_IMG } from "util/accessibility";
import type { Data, Datasets, DataTimelineProps, TChecked } from "./util/types";
import { enumerateDaysBetweenDates } from "./util/utils";
import { dataKeys } from "./util/dataKeys";
import { TimelineSources } from "./TimelineSources";
import StoppedAlert from "components/common/StoppedAlert";

function getCurrentData(source: string, json: any, isGlobal: boolean) {
  let cumulative = 0;
  let dataset: Data[];
  switch (source) {
    case "vaccines":
      dataset = json.map((d: any) => {
        cumulative += d.doses_admin_daily;
        return {
          dt: moment(d.date),
          date: d.date,
          value: d.doses_admin_daily,
          average: d["7_day_avg"],
          averageTT: d["7_day_avg"],
          cumulative,
        };
      });
      break;
    case "testing":
      const values = Object.keys(json).map((key) => json[key].raw_tests);
      const max = Math.max(...values);
      const min = Math.min(...values);
      const delta = max - min;

      const avgKey = isGlobal ? "7day_avg_positivity" : "7-day_avg_positivity";

      dataset = Object.keys(json).map((key) => {
        cumulative += json[key].raw_tests;
        // avg value is sometimes null
        const avg = json[key][avgKey] ?? 0;
        return {
          dt: moment(key),
          date: key,
          value: json[key].raw_tests,
          average: delta * avg + min,
          averageTT: Math.round(avg * 100),
          cumulative,
        };
      });
      break;
    case "hospitalization":
      dataset = Object.keys(json).map((key) => {
        cumulative += json[key].inpatient_beds_used_covid;
        return {
          dt: moment(key),
          date: key,
          value: json[key].inpatient_beds_used_covid,
          average: json[key]["7_day_avg"],
          averageTT: json[key]["7_day_avg"],
          cumulative,
        };
      });
      break;
    case "deaths":
    case "cases":
    case "facets":
    default:
      dataset = Object.keys(json).map((key) => {
        cumulative += json[key].raw_positives;
        return {
          dt: moment(key),
          date: key,
          value: json[key].raw_positives,
          average: json[key]["7-day_avg"],
          averageTT: json[key]["7-day_avg"],
          cumulative,
        };
      });
      break;
  }
  return dataset;
}

const DataTimeline = ({ isGlobal, regionCode }: DataTimelineProps) => {
  const [data, setData] = useState<Data[]>(null!);
  const [datasets, setDatasets] = useState<Datasets[]>(null!);
  const [dateExtent, setDateExtent] = useState<any>(null!);
  const [enumeratedDates, setEnumeratedDates] = useState<any>(null!);
  const [loading, setLoading] = useState(false);
  const [localDataKeys, setDataKeys] = useState(() => ({ ...dataKeys }));
  const [selectedDataset, setSelected] = useState(localDataKeys.facets);
  const [zoomed, setZoomed] = useState(false);
  const [checked, setChecked] = useState<TChecked>({
    cases: true,
    deaths: true,
    testing: false,
    hospitalization: false,
    vaccines: false,
  });

  const [rawData, setRawData] = useState(null);
  const [processData, setProcessData] = useState(false);
  const [availableData, setAvailableData] = useState({
    cases: null,
    deaths: null,
    testing: null,
    hospitalization: null,
    vaccines: null,
  });
  const [stopppedData, setStopppedData] = useState({
    state: null,
    category: null,
    stoppedDate: null
  });

  const { cases, deaths, testing, hospitalization, vaccines } = checked;
  const checkedError =
    [cases, deaths, testing, hospitalization, vaccines].filter((v) => v)
      .length < 2;

  const windowSize = useWindowSize();

  useEffect(() => {
    let mounted = true;

    // reset data whenever new data will be loaded
    setRawData(null);
    setData(null);
    setDatasets(null);

    setLoading(true);

    (async () => {
      const geoType = isGlobal ? "global" : "us";
      let sets = Object.keys(dataKeys).filter(
        (x) => x !== "facets" && !(isGlobal && x === "vaccines")
      );

      const results = await Promise.all(
        sets.map((d) => {
          const url = `https://jhucoronavirus.azureedge.net/api/v3/timeseries/${geoType}/${d}/${regionCode}.json`;
          return fetch(url)
            .catch((res) => new Response())
            .then((response) => {
              if (response.status >= 400) return {};
              return response.json();
            })
            .then((res) => res);
        })
      )
        .catch((res) => {
          return res;
        })
        .then((res) => {
          return res;
        });

      if (!mounted) return;

      const filteredResults = [];
      const filteredDataKeys = {
        facets: dataKeys.facets,
      };
      sets.forEach((set, i) => {
        if (Object.keys(results[i]).length > 0) {
          filteredResults.push(results[i]);
          filteredDataKeys[set] = dataKeys[set];
        }
      });

      setDataKeys(filteredDataKeys);
      setRawData(filteredResults);
      setLoading(false);
      setProcessData(true);
    })();

    return () => {
      mounted = false;
    };
  }, [regionCode, isGlobal]);

  useEffect(() => {
    if (!rawData || !processData) return;

    let currentData: any;

    if (selectedDataset.source !== "checkboxes") {
      let setIx = Object.keys(localDataKeys)
        .filter((x) => x !== "facets" && !(isGlobal && x === "vaccines"))
        .findIndex((ele) => ele === selectedDataset.source);
      const result = setIx > -1 ? rawData[setIx] : {};

      currentData = getCurrentData(selectedDataset.source, result, isGlobal);
      setData(currentData);
      setDatasets(null);

      if (currentData?.length) {
        setDateExtent([
          currentData[currentData.length > 91 ? currentData.length - 91 : 0].dt,
          currentData[currentData.length - 1].dt,
        ]);
      }
      setLoading(false);
      setProcessData(false);
    } else {
      let sets = Object.keys(localDataKeys).filter(
        (x) => x !== "facets" && !(isGlobal && x === "vaccines")
      );
      let datas: any = [];

      // sets and rawData sort order will match at this point
      for (let i = 0; i < sets.length; i++) {
        const set = sets[i];
        const dataKey = localDataKeys[set];
        datas.push({
          datasetLabel: dataKey.facetLabel,
          datasetSource: dataKey.source,
          datasetId: dataKey.id,
          colors: BAR_CHART_COLORS[dataKey.id],
          data: getCurrentData(set, rawData[i], isGlobal),
          order: dataKey.order,
        });
      }

      setData(null);
      setDatasets(datas);

      const avail = Object.assign({}, availableData);
      sets.forEach((set, i) => {
        if (Object.keys(rawData[i]).length) {
          avail[set] = true;
        }
      });

      setAvailableData(avail);

      let facetMax = moment.max(
        datas.map((d) => moment.max(d.data.map((e) => e.dt)))
      );
      let zoomedExtentMin = moment(
        moment(facetMax).subtract(90, "days").format("YYYY-MM-DD")
      );
      setDateExtent([zoomedExtentMin, facetMax]);
      setEnumeratedDates(
        zoomed
          ? enumerateDaysBetweenDates([
            zoomedExtentMin,
            moment.max(datas.map((d) => moment.max(d.data.map((e) => e.dt)))),
          ])
          : enumerateDaysBetweenDates([
            moment.min(datas.map((d) => moment.min(d.data.map((e) => e.dt)))),
            moment.max(datas.map((d) => moment.max(d.data.map((e) => e.dt)))),
          ])
      );
      setLoading(false);
      setProcessData(false);
    }
  }, [
    selectedDataset.source,
    regionCode,
    zoomed,
    isGlobal,
    availableData,
    rawData,
    processData,
    localDataKeys,
  ]);

  const handleOnChange = (option: any) => {
    const lookup = Object.keys(localDataKeys).find(
      (key) =>
        localDataKeys[key].tab === option ||
        localDataKeys[key].tab === option.value
    );
    if (lookup && lookup !== selectedDataset.source) {
      setLoading(true);
      setSelected(localDataKeys[lookup]);
      setProcessData(true);
    }
  };

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
    setProcessData(true);
  };

  const tabs = Object.keys(localDataKeys)
    .filter((k, i) => k === "facets" || availableData[k])
    .map((key) => localDataKeys[key].tab);

  const ZoomControls = () => {
    return (
      <>
        <Button
          buttonStyle={zoomed ? "bordered" : "filled"}
          onClick={() => {
            requestAnimationFrame(() => {
              setZoomed(false);
              setProcessData(true);
            });
          }}
        >
          All Time
        </Button>
        <Button
          buttonStyle={zoomed ? "filled" : "bordered"}
          onClick={() => {
            requestAnimationFrame(() => {
              setZoomed(true);
              setProcessData(true);
              setEnumeratedDates(null);
            });
          }}
        >
          Last 90 Days
        </Button>
      </>
    );
  };

  if (!data && !datasets) return null;

  return (
    <div className={styles.base}>
      <div className={styles.title}>
        <div className={styles.titleIcon}>
          <GraphIcon {...accessibilityProps_IMG("Data Timeline")} />
        </div>
        <HFormat size={2}>Data Timeline</HFormat>
      </div>
      <div>
        <p>
          Explore the most vital information about how COVID-19 has affected
          your state since the pandemic first officially arrived in the United
          States in January 2020 – cases, deaths, test positivity,
          hospitalizations, and vaccinations.
        </p>
      </div>
      <div className={styles.selectContainer}>
        {windowSize.width >= 970 ? (
          <TabBar
            tabs={tabs}
            forcedTab={selectedDataset.tab}
            onChange={handleOnChange}
          />
        ) : (
          <CustomSelect
            classNames={styles.customSelect}
            allOptionLabel={selectedDataset.tab}
            multi={false}
            noAllOption
            onSelect={handleOnChange}
            options={tabs.map((tab, i) => {
              return { label: tab, value: tab };
            })}
          />
        )}
      </div>
      <h3 className={styles.subHeading}>{selectedDataset.name}</h3>
      {selectedDataset.id !== "facets" ? (
        <>
          <div className={styles.headerRow}>
            <div className={styles.legend}>
              <div className={styles.bar}>
                <div
                  className={styles.sample}
                  style={{
                    /* @ts-ignore */
                    backgroundColor: BAR_CHART_COLORS[selectedDataset.id].bar,
                  }}
                />
                <div className={styles.label}>{selectedDataset.barLabel}</div>
              </div>
              <div className={styles.line}>
                <div
                  className={styles.sample}
                  style={{
                    /* @ts-ignore */
                    backgroundColor: BAR_CHART_COLORS[selectedDataset.id].line,
                  }}
                />
                <div className={styles.label}>{selectedDataset.lineLabel}</div>
              </div>
            </div>
            <div className={styles.zoomControls}>
              <ZoomControls />
            </div>
          </div>
          <div className={styles.graph}>
            <div id="jh-state-timeline">
              {!loading && data && (
                <BaseChart
                  className="visualization__container"
                  dateExtent={dateExtent}
                  zoomEffect={zoomed}
                >
                  <BarChart
                    data={data}
                    events={[]}
                    selectedDataset={selectedDataset}
                  />
                </BaseChart>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.facetContainer}>
          <StoppedAlert className={cx(styles.popover, stopppedData?.state && styles.show)} state={stopppedData?.state} category={stopppedData?.category} stoppedDate={stopppedData?.stoppedDate} />
          <div id="jh-state-timeline" style={{ width: "100%" }}>
            {!loading && !checkedError && datasets && (
              <BaseChart
                className="facetSVG visualization__container"
                dateExtent={dateExtent}
                zoomEffect={zoomed}
              >
                <FacetChart
                  data={datasets
                    .filter((d) => checked[d.datasetSource])
                    .sort((a, b) => b.order - a.order)}
                  events={[]}
                  enumeratedDates={enumeratedDates}
                  setStopppedData={setStopppedData}
                />
              </BaseChart>
            )}
          </div>
          <div className={styles.facetControls}>
            <div className={styles.facetZoomControls}>
              <ZoomControls />
            </div>
            <div className={styles.facetSelector}>
              <FormControl error={checkedError}>
                <FormLabel>
                  Choose datasets to compare 7-day average timelines:
                </FormLabel>
                <FormGroup className={styles.facetCheckboxes}>
                  {Object.values(localDataKeys)
                    .filter(
                      (val) => !!val?.checked && availableData[val.source]
                    )
                    .map((val, i) => (
                      <FormControlLabel
                        key={i}
                        control={
                          <Checkbox
                            checked={checked[val.checked]}
                            name={val.checked}
                            onChange={handleCheck}
                            color="primary"
                          />
                        }
                        label={val.tab}
                      />
                    ))}
                </FormGroup>
                <FormHelperText>
                  You must choose at least two datasets.
                </FormHelperText>
              </FormControl>
            </div>
          </div>
        </div>
      )}
      <TimelineSources />
    </div>
  );
};

export default DataTimeline;
