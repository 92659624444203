import React from "react";
import styles from "./DataNote.module.scss";
import ReactMarkdown from "react-markdown";
import HFormat from "components/common/HFormat";
import { Link } from "react-router-dom";
import { TRegionNote } from "data/geo/helpers";

export type TDataNoteProps = TRegionNote & {
  path?: string;
  className?: string;
};

const DataNote = ({ name, notes, path = "" }: TDataNoteProps) => {
  const hasPath = path
    ? {
        to: path,
      }
    : { to: "" };
  return (
    <div className={styles.regionName}>
      <Link {...hasPath}>
        <HFormat size={4} noMargins aria-label={name}>
          {name}
        </HFormat>
      </Link>
      <ul>
        {notes.map((note, i) => {
          return (
            <li key={`note-${name}${i}`}>
              <ReactMarkdown
                //Add double quotes to words in single quotes
                source={note?.replace(/'/g, `"`)?.replace(/"s/g, "'s")}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DataNote;
