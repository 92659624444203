import React, { useEffect, useState, useRef } from "react";
import * as GTM from "util/gtm";
import styles from "./DemographicUpdate.module.scss";
import CustomSelect from "components/common/CustomSelect";
import { useFetch } from "util/hooks/useFetch";
import useDimensions from "util/hooks/useDimensions";
import Map from "./Map";
import Tooltip, { TooltipContext } from "./Tooltip";
import TabBar from "components/layout/shell/TabBar";
import useWindowSize from "util/hooks/useWindowSize";
import { datasets, demographicOptions } from "./util";

type TDemographicUpdateProps = {
  onChange: (selectedTab: string, bin: any) => void;
};

const DemographicUpdate = ({ onChange }: TDemographicUpdateProps) => {
  const [demographic, setDemographic] = useState(demographicOptions[0]);
  const windowSize = useWindowSize();
  const [data, isLoading] = useFetch(demographic.url);
  const binOptions = data
    ? data.dataset
        .map((d) => {
          return { value: d.bin, label: d.bin, order: d.sort };
        })
        .sort((a, b) => a.order - b.order)
    : [{ value: "all-content", label: "All" }];
  const [bin, setBin] = useState(binOptions[0]);
  const [binReset, setBinReset] = useState(false);
  const [mapData, isMapLoading] = useFetch("geo_us_states");
  const loading = isMapLoading || isLoading;
  const ref = useRef(null);
  const [width] = useDimensions(ref);
  const [svgWidth] = useState(width * 0.76);
  const [tooltip, setTooltip] = useState({
    show: false,
    x: 0,
    y: 0,
    datakey: null,
    cases: null,
    deaths: null,
    tests: null,
    vaccines: null,
    bin: null,
    state: null,
  });
  const value = { state: tooltip, dispatch: setTooltip };

  const handleOnChange = (tab: string, bin: any) => {
    if (onChange) onChange(tab, bin);
  };

  useEffect(() => {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
    });
  }, []);

  const tabs = [];
  const tabValues = [];
  demographicOptions.forEach((option) => {
    tabs.push(option.label);
    tabValues.push(option.value);
  });

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const handleTabChange = (selected) => {
    const selectedDemographicOption = demographicOptions.find(
      typeof selected === "string"
        ? (d) => selected === d.label
        : (d) => selected.label === d.label
    );
    const newBin = { value: "all-content", label: "All" };

    setDemographic(selectedDemographicOption);
    setSelectedTab(selectedDemographicOption.value);
    setBinReset(true);
    setBin(newBin);

    handleOnChange(selectedDemographicOption.value, newBin);
  };

  return (
    <div className={styles.base} ref={ref}>
      <div className={styles.optionsContainer}>
        <div className={styles.demographicSelector}>
          {windowSize.width >= 970 ? (
            <TabBar
              tabs={tabs}
              onChange={handleTabChange}
              forcedTab={selectedTab}
            />
          ) : (
            <CustomSelect
              noAllOption
              allOptionLabel={demographicOptions[0].label}
              classNames={styles.select}
              label="Select a Demographic"
              multi={false}
              options={demographicOptions}
              onSelect={handleTabChange}
              isSearchable={false}
            />
          )}
        </div>
        <div className={styles.categorySelector}>
          <CustomSelect
            noAllOption={false}
            classNames={styles.select}
            label="Select a Group"
            multi={false}
            options={binOptions}
            onSelect={(selected) => {
              setBin(selected);
              setBinReset(false);
              handleOnChange(selectedTab, selected);
            }}
            resetToAll={binReset}
            isSearchable={false}
          />
        </div>
      </div>

      <div id="demographic-update-maps" className={`${styles.mapGrid}`}>
        {!!data &&
          !loading &&
          ["cases", "deaths", "tests", "vaccines"].map((dataset, i) => (
            <TooltipContext.Provider value={value} key={`tool-tip-${i}`}>
              <Map
                dataSet={datasets.find((d) => d.value === dataset)}
                bin={bin.value}
                data={data}
                mapData={mapData}
                svgWidth={svgWidth}
                demographic={demographic.label}
                tileMap={null}
                mobile={null}
              >
                <Tooltip width={150} height={100} dataset={dataset} />
              </Map>
            </TooltipContext.Provider>
          ))}
      </div>
      <br />
    </div>
  );
};

export default DemographicUpdate;
