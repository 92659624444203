import React from "react";
import styles from "./ResearchArticle.module.scss";
import Blog from "components/layout/Blog";
import { getArticleBySlug } from "data/util";
import { Redirect } from "react-router-dom";
import { basePathResearchPapers } from "..";

const ResearchArticle = (props) => {
  const { match } = props;
  const article = getArticleBySlug(match.params.articleId);

  if (!article?.slug) return <Redirect to={basePathResearchPapers} />;
  return (
    <div className={styles.container}>
      <Blog article={article} {...props} />
    </div>
  );
};

export default ResearchArticle;
