import React from "react";
import styles from "./DataBannerContainer.module.scss";
import classNames from "classnames/bind";
import DataBanner, { TDataBannerProps } from "../DataBanner/DataBanner";
const cx = classNames.bind(styles);

const bannerContent: TDataBannerProps[] = [
  {
    images: ["/images/sunset/us-cases.svg"],
    title: "US CASES & DEATHS",
    content:
      "The U.S. Centers for Disease Control and Prevention maintains a national data tracker.",
    links: [
      {
        href: "https://covid.cdc.gov/covid-data-tracker/#datatracker-home",
        label: "Go to Website",
      },
    ],
  },
  {
    images: ["/images/sunset/who.svg"],
    title: "GLOBAL TRENDS",
    content:
      "The World Health Organization provides information about global spread.",
    links: [
      {
        href:
          "https://covid19.who.int/?adgroupsurvey=%7Badgroupsurvey%7D&gclid=Cj0KCQiAw8OeBhCeARIsAGxWtUxR9wuVfKmK8awaDLSRrSm65bKiEJOcvWX34XpP2LX4eD_sR9ZfUqAaArNPEALw_wcB",
        label: "Go to Website",
      },
    ],
  },
  {
    images: ["/images/sunset/us-cases.svg", "/images/sunset/oms-logo.svg"],
    title: "VACCINES",
    content:
      "The CDC also provides a vaccine data tracker for the U.S., while Our World In Data from Oxford University provides global vaccine information.",
    links: [
      {
        href:
          "https://covid.cdc.gov/covid-data-tracker/#vaccinations_vacc-total-admin-rate-total",
        label: "Go to CDC Website",
      },
      {
        href:
          "https://ourworldindata.org/coronavirus#explore-the-global-situation",
        label: "Go to Oxford Website",
      },
    ],
  },
  {
    images: ["/images/sunset/us-cases.svg", "/images/sunset/health-data.svg"],
    title: "HOSPITAL ADMISSIONS",
    content:
      "The CDC and the U.S. Department of Health and Human Services have provided hospital admission data in the United States.",

    links: [
      {
        href: "https://covid.cdc.gov/covid-data-tracker/#hospital-capacity",
        label: "Go to CDC Website",
      },
      {
        href:
          "https://healthdata.gov/Hospital/COVID-19-Hospital-Data-Coverage-Report/v4wn-auj8",
        label: "Go to DOH Website",
      },
    ],
  },
];

type TDataBannerContainerProps = {
  className?: string;
};

const DataBannerContainer = ({ className }: TDataBannerContainerProps) => {
  return (
    <div className={cx(styles.base, className)}>
      {bannerContent.length > 0 &&
        bannerContent.map((banner, i) => (
          <DataBanner className={styles.banner} key={i} {...banner} />
        ))}
    </div>
  );
};

export default DataBannerContainer;
