import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NEGATIVE_COLOR, POSITIVE_COLOR } from '../../../../../constants';

const useStyles = makeStyles(theme => ({
  line: {
    strokeWidth: '3px',
    fill: 'none',
  },
  positive: {
    stroke: POSITIVE_COLOR,
  },
  negative: {
    stroke: NEGATIVE_COLOR,
  },
}));

const TrendLine = ({ data }) => {
  const classes = useStyles();
  return (
    <g className={classes.line}>
      {data.map((d, i) => {
        const { x1, x2, y1, y2, direction } = d;
        return <line key={`line-segment-${i}`} x1={x1} x2={x2} y1={y1} y2={y2} className={classes[direction]} />;
      })}
    </g>
  );
};
TrendLine.defaultProps = {
  data: [],
};
export default TrendLine;
