import React from "react";

import BedsChart from "components/content/ByRegion/RegionHospitalization/BedsChart";
import USStateMap from "components/common/USStateMap";

import styles from "./Charts.module.scss";

const Charts = () => {
  return (
    <div className={styles.base}>
      <p>Hospital Beds Chart</p>
      <div style={{ width: "50%" }}>
        <BedsChart data={{
          "inpatient": {
            "covid": 16.542917924350473,
            "non-covid": 42.52672654588008,
            "occ_this_week": 59.06964447023056,
            "occ_previous_week": 58.12043392717832
          },
          "icu": {
            "covid": 37.403200826019614,
            "non-covid": 35.10583376355187,
            "occ_this_week": 72.50903458957148,
            "occ_previous_week": 73.573264781491
          }
        }} />
      </div>

      <div>
        <USStateMap
          dataSrc="/datasets/geo/data_ct_data.json"
          width={437}
          height={315}
        />
      </div>

      <div>
        <USStateMap
          dataSrc="/datasets/geo/data_ct_data.json"
          width={800}
          height={500}
          mode="square"
        />
      </div>
    </div>
  );
};

export default Charts;
