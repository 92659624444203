import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getMatchingValue } from "components/content/DataCenter/charts/StateTimeline/utils";
import {
  AXES_MOMENT_DATE_FORMAT,
  DATASET_HELPER_TEXT,
  dateAccessor,
} from "../../constants";
import {
  COLORS,
  COMMA_FORMATTER,
  valueAccessor,
} from "components/content/DataCenter/charts/Shared/constants";

const useStyles = makeStyles(() => ({
  line: {
    fill: "none",
    strokeWidth: "5px",
    strokeOpacity: 0.14,
    stroke: COLORS.royalBlue,
    pointerEvents: "none",
  },
  label: {
    textAnchor: "middle",
    fill: COLORS.textBlue,
    stroke: "none",
    fontSize: "14px",
    textRendering: "optimizeLegibility",
  },
  tooltipLabel: {
    textAnchor: "left",
    fill: COLORS.textBlue,
    stroke: "none",
    fontSize: "16px",
    textRendering: "optimizeLegibility",
    fontWeight: "bold",
  },
  tooltipCumulativeLabel: {
    textAnchor: "left",
    fill: COLORS.gray,
    stroke: "none",
    fontSize: "14px",
    textRendering: "optimizeLegibility",
  },
  background: {
    fill: "#fff",
    stroke: "none",
  },
  tooltipBackground: {
    fill: "#fff",
    stroke: "#000",
    strokeOpacity: 0.4,
  },
}));

const TooltipLineAndMarker = ({
  data,
  selectedEvent,
  activeEvent,
  xScale,
  yScale,
  r,
  height,
  offset,
  markerFill,
  selectedDataset,
  innerWidth,
}) => {
  const classes = useStyles();
  const tooltipWidth = 230;
  const tooltipLabelWidth = 42;

  let x = 0,
    y = 0,
    dateLabel = "";
  if (activeEvent) {
    x = xScale(dateAccessor(activeEvent)) + xScale.bandwidth() / 2;
    y = getMatchingValue(data, dateAccessor(activeEvent));
    dateLabel = dateAccessor(activeEvent);
  } else if (selectedEvent) {
    x = xScale(dateAccessor(selectedEvent)) + xScale.bandwidth() / 2;
    y = getMatchingValue(data, dateAccessor(selectedEvent));
    dateLabel = dateAccessor(selectedEvent);
  } else {
    return null;
  }

  // Adjust the tooltip rect based on where we are on the chart, i.e. don't allow for it getting cropped
  let xTooltipTransform = -tooltipWidth / 2;
  if (x + tooltipWidth > innerWidth) {
    xTooltipTransform -= tooltipWidth / 2;
  }
  let tooltipLabelTransform = 0;
  if (x + tooltipLabelWidth / 2 > innerWidth) {
    tooltipLabelTransform -= tooltipLabelWidth / 2;
  }

  return (
    <g className="timeline__tooltip">
      <g className="tooltip__group" transform={`translate(${x},0)`}>
        <line y1={height} y2={-offset} className={classes.line} />
        <g
          className="tooltip__rect"
          transform={`translate(${xTooltipTransform},0)`}
        >
          <rect
            className={classes.tooltipBackground}
            width={tooltipWidth}
            height={
              DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].cumulative
                ? 75
                : 50
            }
          />
          <g className="tooltip__text" transform={`translate(5,20)`}>
            <text className={classes.tooltipLabel} style={{ fill: markerFill }}>
              {`${COMMA_FORMATTER(y.value)} ${
                DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].actual
              }`}
            </text>
            {DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].cumulative && (
              <text className={classes.tooltipCumulativeLabel} dy={22}>
                {`${COMMA_FORMATTER(y.cumulative)} ${
                  DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].cumulative
                }`}
              </text>
            )}
            <text
              className={classes.tooltipCumulativeLabel}
              dy={
                DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].cumulative
                  ? 44
                  : 22
              }
            >
              {`${COMMA_FORMATTER(y.averageTT)} ${
                DATASET_HELPER_TEXT[valueAccessor(selectedDataset)].average
              }`}
            </text>
          </g>
        </g>
        <rect
          className={classes.background}
          width={46}
          height={18}
          y={-offset}
          x={-21}
          transform={`translate(${tooltipLabelTransform})`}
        />
        <text
          className={classes.label}
          y={-offset+7}
          transform={`translate(${tooltipLabelTransform})`}
        >
          {AXES_MOMENT_DATE_FORMAT(dateLabel)}
        </text>
      </g>
    </g>
  );
};

export default TooltipLineAndMarker;
