import React from "react";
import classNames from "classnames/bind";
import styles from "./StyleGuide.module.scss";
import TimeSpan from "../components/TimeSpan";
import TimelineHeader from "../components/TimelineHeader";
import Icon from "../components/Icon";
import TourPoint from "../components/TourPoint";
import PhaseContent from "../components/PhaseContent";
import CompareButton from "../components/CompareButton";
import TourPointTip from "../components/TourPointTip";
import PhaseCard from "../components/PhaseCard";
const cx = classNames.bind(styles);

export default function StyleGuideIndex() {
  return (
    <div className={cx("container")}>
      <h1
        className={cx("styleguide-annotation")}
        style={{ textTransform: "uppercase" }}
      >
        Vaccine Timeline Components
      </h1>

      <h2>Icons</h2>
      <div className={styles.row}>
        <Icon icon="syringe" />
        <Icon icon="bottles" />
        <Icon icon="safety" />
        <Icon icon="slow" />
        <Icon icon="fast" />
        <Icon icon="approval" />
        <Icon icon="trials1" />
        <Icon icon="trials2" />
        <Icon icon="trials3" />
      </div>
      <div className={styles.backdropDark}>
        <div className={styles.row}>
          <Icon icon="syringe" light />
          <Icon icon="bottles" light />
          <Icon icon="safety" light />
          <Icon icon="slow" light />
          <Icon icon="fast" light />
          <Icon icon="approval" light />
          <Icon icon="trials1" light />
          <Icon icon="trials2" light />
          <Icon icon="trials3" light />
        </div>
      </div>

      <br />

      <h2>TimeSpan</h2>
      <div className={styles.row}>
        <TimeSpan label={"Dark"} light={false} />
        <TimeSpan label={"Light"} light={true} />
      </div>

      <br />

      <h2>TimelineHeader</h2>
      <div className={styles.backdrop}>
        <TimelineHeader
          title={"Heading"}
          icon={"approval"}
          light={false}
          content={
            "At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
          }
        />
      </div>
      <div className={styles.backdropDark}>
        <TimelineHeader
          title={"Heading"}
          icon={"approval"}
          light={true}
          content={
            "At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
          }
        />
      </div>
      <br />

      <h2>Phase</h2>
      <div className={styles.backdrop}>
        <PhaseContent
          title={"Title"}
          icon={"syringe"}
          content={
            "At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
          }
        />
      </div>

      <h2>TourPoint</h2>
      <div className={styles.backdrop}>
        <div className={styles.row}>
          <TourPoint>
            <div className={styles.testBox}></div>
          </TourPoint>
          <TourPoint active>
            <div className={styles.testBox}></div>
          </TourPoint>
        </div>
      </div>

      <h2>TourPointTip</h2>
      <div className={styles.backdrop}>
        <div className={styles.backdropDark}>
          <div className={styles.row}>
            <TourPointTip
              current={1}
              total={10}
              arrowPosition="center"
              content="At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
            />

            <TourPointTip
              current={5}
              total={10}
              arrowPosition="left"
              content="At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
            />

            <TourPointTip
              current={10}
              total={10}
              arrowPosition="right"
              content="At magnum periculum adiit in quo enim ad id totum evertitur eo est laborum et voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et aut petat aut fugit, sed ut ad id totum evertitur eo est primum igitur, inquit."
            />
          </div>
        </div>
      </div>

      <h2>CompareButton</h2>
      <div className={styles.row}>
        <CompareButton title="Compare" caption="to the Accelerated Timeline" />
        <CompareButton
          title="Compare"
          caption="to the Accelerated Timeline"
          light
          direction="left"
        />
      </div>

      <h2>PhaseCard</h2>
      <div className={styles.row}>
        <PhaseCard title="Title" icon="trials1" />
        <PhaseCard title="Title" icon="trials1" active />
      </div>
    </div>
  );
}
