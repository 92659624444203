import {json} from 'd3-fetch';

export const getData = id => {
    return new Promise((resolve, reject) => {
       json(`/datasets/equal-access-to-testing/jh-covid-access-to-testing-${id.toUpperCase()}.json`)
            .then(featureCollection => resolve(featureCollection))
            .catch(err => {
                console.log(err);
                return reject(err);
            });
    });
};
