import React, { useState } from "react";
import PropTypes from "prop-types";
import { useRouteMatch } from "react-router-dom";

import { useLocation } from "react-router-dom";
import Page from "../Page";
import Button from "components/common/Button";
import { getPage } from "data/util";

import classNames from "classnames/bind";
import styles from "./SideNarrative.module.scss";
import Share from "components/common/Share";
import ReactMarkdown from "react-markdown";
import StoppedAlert from "components/common/StoppedAlert";
import { Popup } from "components/common/Popup";
const cx = classNames.bind(styles);

const SideNarrative = ({
  children,
  narrative,
  infoLabel,
  pageProps,
  classNames,
}) => {
  const location = useLocation();
  const path = pageProps && pageProps.path ? pageProps.path : location.pathname;
  const page = getPage(path);
  const [open, setOpen] = useState(false);
  const match = useRouteMatch();
  const isOnDataPage = match.path.split("/")[1] === "data";

  const closeSidebar = () => setOpen(false);
  const toggleSidebar = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Page header={false} flush {...pageProps}>
      {!isOnDataPage && <Popup />}
      <div
        className={cx("base", {
          "with-sidebar": !!narrative,
          "sidebar-open": open,
        })}
      >
        <div className={cx("content")} onClick={closeSidebar}>
          <div className={cx("content-header")}>
            <div className={cx("header-info")}>
              {!!page.section && (
                <h4 className="small-heading">{page.section}</h4>
              )}
              {!!page.title && <h1>{page.title}</h1>}
              {!!page.subtitle && <h3>{page.subtitle}</h3>}
              {!!page.caption && (
                <ReactMarkdown source={page.caption} linkTarget="_blank" />
              )}
              <Share label="Share:" />
            </div>
            {!!narrative && (
              <Button
                className={cx("toggle")}
                buttonStyle="bordered"
                icon={open ? "x" : "right"}
                onClick={toggleSidebar}
              >
                {open ? "Close" : infoLabel}
              </Button>
            )}
          </div>
          {!isOnDataPage && <StoppedAlert className={cx("stopped-alert")} />}
          {children}
        </div>
        {!!narrative && (
          <div className={cx("sidebar", classNames?.sidebar)}>
            <Button
              className={cx("sidebar-close")}
              icon="x"
              buttonStyle="bordered"
              shape="rounded"
              onClick={closeSidebar}
            />
            <h2 className={cx("sidebar-heading")}>About this page:</h2>
            {narrative}
          </div>
        )}
      </div>
    </Page>
  );
};

SideNarrative.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  narrative: PropTypes.element,
  infoLabel: PropTypes.string,
  pageProps: PropTypes.object,
  classNames: PropTypes.object,
};

SideNarrative.defaultProps = {
  infoLabel: "About This Page",
  pageProps: {},
};

export default SideNarrative;
