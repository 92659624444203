import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import cx from "classnames";
import styles from "./RegionVaccineTracker.module.scss";
import Icon from "../../Vaccine/VaccineTimeline/components/Icon";
import addThousandsSeparator from "util/addThousandsSeparator";
import useSmoothHashScroll from "util/hooks/useSmoothHashScroll";
import {
  percentFullVacLabels,
  rawFullVacLabels,
} from "components/content/Vaccine/VaccineDataVisualization/constants";

const RegionVaccineTracker = ({ vaccineData, regionName, isCountry }) => {
  const { doses_admin, /* raw_full_vac,*/ percent_full_vac } = vaccineData;

  const statistics = [
    {
      label: "Doses Administered",
      value: doses_admin,
      format: (value) =>
        !value || value === "Not Available"
          ? "N/A"
          : addThousandsSeparator(value),
    },
    {
      label: isCountry ? rawFullVacLabels.country : rawFullVacLabels.state,
      value: vaccineData?.raw_full_vac || vaccineData?.raw_full_vacc,
      format: (value) =>
        !value || value === "Not Available"
          ? "N/A"
          : addThousandsSeparator(value),
    },
    {
      label: isCountry
        ? percentFullVacLabels.country
        : percentFullVacLabels.state,
      value: percent_full_vac,
      format: (value) =>
        !value || value === "Not Available" ? "N/A" : `${value.toFixed(2)}%`,
    },
  ];

  const anchorName = "vaccine-tracker";
  useSmoothHashScroll(anchorName);

  if (!statistics.length) {
    return null;
  }

  return (
    <div className={cx(styles.base)} id={anchorName}>
      <div className={styles.title}>
        <Icon
          icon="bottles"
          className={styles.titleIcon}
          alt="Vaccine Tracker"
        />
        <div className={styles.titleContent}>
          <h2>Vaccine Tracker</h2>
          <Link
            to={`/vaccines/${isCountry ? "international" : "us-states"}`}
            className={styles.vaccinesLink}
          >
            Learn more about vaccines ›
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        {statistics.map(({ label, value, format }) => (
          <div key={label} className={styles.stat}>
            <h3 className={styles.statTitle}>{label}</h3>
            <div
              className={cx(styles.statValue, {
                [styles.unavailable]: value === "Not Available",
              })}
            >
              {format(value)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

RegionVaccineTracker.propTypes = {
  data: PropTypes.object,
  regionName: PropTypes.string,
};

export default RegionVaccineTracker;
