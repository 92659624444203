import React from "react";
import ReactMarkdown from "react-markdown";
import Speakers from "../../Speakers";
import baseStyles from "../EventMod.module.scss";

export const HealthPolicyForum = ({
  slug = "health-policy-forum-10-16-2020",
  contentSrc = "Johns Hopkins University welcomed Dr. Anthony Fauci to join Bloomberg School of Public Health Dean Ellen MacKenzie in a recorded, virtual conversation as part of the new Johns Hopkins University Health Policy Forum. Dean MacKenzie hosted the discussion with Fauci, director of the National Institute of Allergy and Infectious Diseases, who discussed the COVID-19 pandemic and reflected on his nearly 40-year career as a leader in public health policy. \n\nMore about the new Johns Hopkins University Health Policy Forum:\n\nThe Johns Hopkins University Health Policy Forum is a new quarterly series of discussions designed to bring together Johns Hopkins faculty, students and operational experts with policy makers to explore the development and implementation of health policy interventions.\n\nThe series is jointly sponsored by the Schools of Medicine, Nursing, and Public Health, and the Carey Business School. The first installment of the Health Policy Forum will be hosted by the School of Public Health, with hosting of subsequent events rotating among the sponsoring schools.\n\nPlease note: A link to watch the stream will be emailed to you closer to the event's start. Please register to receive the reminder and then also consider saving this event to your digital calendar to be sure you don't miss it. Thank you!",
}) => {
  const speakers = [
    {
      name: "Ellen MacKenzie",
      title: "Host",
      image: "/images/vaccine-events/speakers/mackenzie.png",
    },
    {
      name: "Anthony Fauci",
      title: "Guest",
      image: "/images/vaccine-events/speakers/fauci.png",
    },
  ];

  const content = () =>
    !!contentSrc && (
      <div>
        <ReactMarkdown
          className={baseStyles.embedDescription}
          source={contentSrc}
        />
        <Speakers authors={speakers} />
      </div>
    );

  return {
    slug,
    content,
  };
};
