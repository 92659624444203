import React from "react";
import styles from "./SectionHeading.module.scss";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown/with-html";

const SectionHeading = ({ isMajor, title, description, classNames }) => {
  const className = [
    styles.base,
    isMajor ? styles.isMajor : styles.isMinor,
    classNames,
  ].join(" ");

  return (
    <div className={className}>
      <div>
        <h2>{title}</h2>
      </div>
      <ReactMarkdown source={description} />
    </div>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  isMajor: PropTypes.bool.isRequired,
  classNames: PropTypes.string,
};

export default SectionHeading;
