import React from "react";
import PropTypes from "prop-types";
import { Waypoint } from "react-waypoint";

import timelines from "../../data/timelines.json";
import phases from "../../data/phases.json";
import timeSpans from "../../data/time-spans.json";
import styles from "./TypicalTimeline.module.scss";
import TimelineHeader from "../../components/TimelineHeader";
import isMobileDevice from "util/isMobile";
import PhaseContent from "../../components/PhaseContent";
import TimeSpan from "../../components/TimeSpan";
import CompareButton from "../../components/CompareButton";
import AcceleratedTimelineMap from "../AcceleratedTimeline/AcceleratedTimelineMap";

const TypicalTimeline = ({
  splashPosition,
  currentPhase,
  setCurrentPhase,
  currentTimeline,
  setCurrentTimeline,
}) => {
  const tl = timelines[0];
  const timeSpanGroups = phases
    // time span id's
    .map((phase) => phase.timeSpan)
    // unique values only
    .filter((value, index, self) => {
      return self.indexOf(value) === index;
    })
    // populate time span properties
    .map((groupId) => timeSpans.find((timeSpan) => timeSpan.id === groupId))
    // add list of phases to each
    .map((group) => ({
      ...group,
      phases: phases.filter((phase) => phase.timeSpan === group.id),
    }));

  const compare = () => setCurrentTimeline(timelines[1].id);

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div className={styles.container}>
          <TimelineHeader
            {...tl}
            content={
              splashPosition === "above" && isMobileDevice() ? null : tl.content
            }
          />
          <div className={styles.aside}>
            <div className={styles.asideContainer}>
              <AcceleratedTimelineMap currentPhase={currentPhase} />
              <CompareButton
                title="COMPARE"
                caption="to the Accelerated Timeline"
                onClick={compare}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.timelineContainer}>
        {timeSpanGroups.map((group, i) => (
          <div key={i} className={styles.phaseGroup}>
            <div className={styles.phaseGroupTimeSpan}>
              <TimeSpan label={group.label} light />
            </div>
            <div className={styles.phaseGroupPhases}>
              {group.phases.map((phase) => (
                <div
                  key={phase.id}
                  id={phase.id}
                  className={styles.phaseGroupPhase}
                >
                  <Waypoint
                    onEnter={() =>
                      currentTimeline === "typical" && setCurrentPhase(phase.id)
                    }
                    offsetTop={300}
                  />
                  <PhaseContent {...phase} compare={compare} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TypicalTimeline.propTypes = {
  splashPosition: PropTypes.string,
  currentPhase: PropTypes.string,
  setCurrentPhase: PropTypes.func,
  currentTimeline: PropTypes.string,
  setCurrentTimeline: PropTypes.func,
};

export default TypicalTimeline;
