import React from "react";
import { ReactComponent as OpenIcon } from "images/icons/icon-menu.svg";
import { ReactComponent as CloseIcon } from "images/icons/cross.svg";

import styles from "./NavButton.module.scss";

const NavButton = ({ isOpen, onOpen, onClose }) => {
  return (
    <div
      className={styles.base}
      onClick={(e) => {
        e.preventDefault();
        if (isOpen) {
          onClose();
        } else {
          onOpen();
        }
      }}
    >
      {isOpen ? <CloseIcon /> : <OpenIcon />}
    </div>
  );
};

export default NavButton;
