import React, { Component } from "react";

import * as GTM from "util/gtm";
import isMobile from "util/isMobile";
import { getPage } from "data/util";
import styles from "./USMapView.module.scss";
import Meta from "components/common/Meta";

class USMapView extends Component {
  componentDidMount() {
    GTM.clear();
    GTM.push({
      pageVerticals: ["tracking"],
      contentDataVisualization: "us-map",
      contentCountry: "united-states"
    });
  }

  render() {
    const pageData = getPage(this.props.location.pathname);
    const { meta } = pageData;

    return (
      <div className={styles.base}>
        <Meta {...meta} />
        <div className={styles.container}>
          <div className={styles.embedContainer}>
            <iframe
              ref={this.frameRef}
              className={styles.iframe}
              title="Coronavirus COVID-19 Global Cases by Johns Hopkins CSSE"
              width="500"
              height="400"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={
                isMobile()
                  ? "https://www.arcgis.com/apps/opsdashboard/index.html#/fa969576a57644ce8d3af53b73ac8de1"
                  : "https://www.arcgis.com/apps/opsdashboard/index.html#/409af567637846e3b5d4182fcd779bea"
              }
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default USMapView;
