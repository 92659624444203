const path = "/images/headshots";

export const experts = [
  {
    name: "Dr. Jennifer Nuzzo",
    src: `${path}/Nuzzo_JHU.jpg`,
  },
  {
    name: "Dr. Bill Moss",
    src: `${path}/BillMoss.jpg`,
  },
  {
    name: "Dr. Crystal Watson",
    src: `${path}/Watson-C-JHCHS-Portraits-9-25-19_224.jpg`,
  },
  {
    name: "Dr. Lainie Rutkow",
    src: `${path}/Lainie-Rutkow-3.jpg`,
  },
  {
    name: "Dr. Brian Garibaldi",
    src: `${path}/GARIBALDI-brian-001.jpg`,
  },
  {
    name: "Beth Blauer",
    src: `${path}/Beth-Blauer-Headshot.jpg`,
  },
  {
    name: "Dr. Lauren Gardner",
    src: `${path}/Gardner-horiz-2019.jpg`,
  },
];
