import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateVisualization } from '../../../redux/modules/global';
import {nameAccessor, valueAccessor, TABLE_VIEW, MAP_VIEW, POSITIVITY_COMPARISON_VIEW} from '../../../constants';

const VisualizationToggle = () => {
  const dispatch = useDispatch();
  const updateSelectedVisualization = useCallback(id => dispatch(updateVisualization(id)), [dispatch]);
  const visualizations = useSelector(state => state.testingTracker.visualizations);

  const slugs = {
    [TABLE_VIEW]: 'overview',
    [MAP_VIEW]: 'map',
    [POSITIVITY_COMPARISON_VIEW]: 'positivity-comparison'
  };

  return (
    <div className="visualization__toggle">
      {visualizations.map(visualization => {
        return (
          <NavLink
            activeClassName="is-selected"
            key={`button-for-${valueAccessor(visualization)}`}
            to={{
              pathname: `/testing/tracker/${slugs[valueAccessor(visualization)]}`,
              state: { noScroll: true }
            }}
            onClick={() => updateSelectedVisualization(valueAccessor(visualization))}
          >
            {nameAccessor(visualization)}
          </NavLink>
        );
      })}
    </div>
  );
};
VisualizationToggle.defaultProps = {};
export default VisualizationToggle;
