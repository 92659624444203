import React from "react";
import Application from "./components/layout/Application";
import store from "./util/store";
import { FetchProvider } from "./util/hooks/useFetch";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const AppContainer = () => (
  <Provider store={store}>
    <FetchProvider>
      <BrowserRouter>
        <Application />
      </BrowserRouter>
    </FetchProvider>
  </Provider>
);

export default AppContainer;
