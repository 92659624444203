import React, { FC, useState } from "react";
import cx from "classnames";
import styles from "./GraphicBlock.module.scss";
import GraphicInfo from "../GraphicInfo";
import { findStateByCode } from "data/geo";
import { decoder } from "../util";
import Modal from "components/common/Modal";

export type GraphicBlockProps = {
  stateCode?: string;
  variant?:
    | "HCLC"
    | "RSTL"
    | "65+"
    | "CONG"
    | "TCHR"
    | "OTHR"
    | "RPOP"
    | "JOIN";
  type?: "default" | "legend";
  joinNext?: boolean;
  noIcon?: boolean;
  legendTextOverride?: boolean | string;
  className?: string;
};

const GraphicBlock: FC<GraphicBlockProps> = ({
  type = "default",
  variant = "HCLC",
  stateCode,
  joinNext,
  noIcon,
  legendTextOverride,
  className,
}) => {
  const [show, setShow] = useState(false);
  const graphicBlockProps = {
    title: findStateByCode(stateCode),
    groups: [decoder(variant, "legend")],
  };
  const handleShowModal = () => {
    if (window.innerWidth < 970) setShow(!show);
  };
  return (
    <div className={cx(styles.base)}>
      <div
        className={cx(
          styles.block,
          styles[type],
          styles[decoder(variant, "style")],
          joinNext ? styles.joinNext : "",
          className
        )}
        onClick={handleShowModal}
      >
        {!noIcon && (
          <span className={styles.icon}>{decoder(variant, "icon")}</span>
        )}
      </div>
      {!!joinNext && <div className={cx(styles.joinLink, styles[type])} />}
      {!!stateCode && !joinNext && type !== "legend" && (
        <>
          <GraphicInfo
            className={styles.infoBlock}
            {...graphicBlockProps}
            onHover={true}
          />
          <Modal
            className={styles.modal}
            show={show}
            x={true}
            dismiss={() => {
              setShow(false);
            }}
          >
            <GraphicInfo
              className={styles.infoBlockMobile}
              {...graphicBlockProps}
              onHover={false}
              modal={true}
            />
          </Modal>
        </>
      )}

      {type === "legend" && typeof legendTextOverride !== "boolean" && (
        <span className={styles.legendText}>
          {legendTextOverride || decoder(variant, "legend")}
        </span>
      )}
    </div>
  );
};

export default GraphicBlock;
