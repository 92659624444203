import React from "react";
import styles from "./SpecialThanks.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(styles);

type TSpecialThanksProps = {
  className?: string;
};

const SpecialThanks = ({ className }: TSpecialThanksProps) => {
  return (
    <div className={cx(styles.base, className)}>
      <h3 className={styles.header}>Special Thanks To</h3>
      <p>
        Aaron Katz, Adam Lee, Alan Ravitz, Alex Roberts, Alexander Evelo, Amanda
        Galante, Amina Mahmood, Angel Aliseda Alonso, Anna Yaroslaski, Arman
        Kalikian, Beatrice Garcia, Breanna Johnson, Cathy Hurley, Christina
        Pikas, Christopher Watenpool, Cody Meiners, Cory McCarty, Dane Galloway,
        Daniel Raimi Zlatic, David Zhang, Doug Donovan, Elaine Gehr, Emily
        Camacho, Emily Pond, Ensheng Dong, Eric Forte, Ethel Wong, Evan Bolt,
        Fardin Ganjkhanloo, Farzin Ahmadi, Fernando Ortiz-Sacarello, George
        Cancro, Grant Zhao, Greta Kinsley, Gus Sentementes, Heather Bree, Hongru
        Du, Ian Price, Jan LaBarge, Jason Williams, Jeff Gara, Jennifer Nuzzo,
        Jeremy Ratcliff, Jill Rosen, Jim Maguire, John Olson, John Piorkowski,
        Jordan Wesley, Joseph Duva, Joseph Peterson, Josh Porterfield, Joshua
        Poplawski, Kailande Cassamajor, Kevin Medina Santiago, Khalil Hijazi,
        Krushi Shah, Lana Milman, Laura Asher, Laura Murphy, Lauren Kennell,
        Louis Pang, Mara Blake, Marianne von Nordeck, Marissa Collins, Marlene
        Caceres, Mary Conway Vaughan, Meg Burke, Melissa Leeds, Michael Moore,
        Miles Stewart, Miriam McKinney Gray, Mitch Smallwood, Molly Mantus, Nick
        Brunner, Nishant Gupta, Oren Tirschwell, Paul Nicholas, Phil Graff,
        Phillip Hilliard, Promise Maswanganye, Raghav Ramachandran, Reina Chano
        Murray, Roman Wang, Ryan Lau, Samantha Cooley, Sana Talwar, Sara Bertran
        de Lis, Sarah Prata, Sarthak Bhatnagar, Sayeed Choudury, Shelby Wilson,
        Sheri Lewis, Steven Borisko, Tamara Goyea, Taylor Martin, Teresa
        Colella, Tim Gion, Tim Ng, William La Cholter, Xiaoxue Zhou, Yael Weiss
      </p>
    </div>
  );
};

export default SpecialThanks;
