import React, { useState, useEffect } from "react";
import { format } from "d3-format";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  hide: {
    fontSize: "80%",
    visibility: "hidden",
  },
  show: {
    fontSize: "80%",
    visibility: "visible",
  },
  tooltip: {
    position: "relative",
    display: "inline-block",
  },
  tooltiptext: {
    width: "120px",
    backgroundColor: "#aaa",
    color: "#fff",
    textAlign: "center",
    fontWeight: "600",
    padding: "5px 0",
    borderRadius: "6px",
    top: "-5px",
    left: "105%",
    "&::after": {
      content: '""',
      position: "absolute",
      top: "50%",
      right: "100%",
      marginTop: "-5px",
      borderWidth: "5px",
      borderStyle: "solid",
      borderColor: "transparent #aaa transparent transparent",
    },
  },
}));

const Annotation = ({ data, x, y, hovered, first, mobile }) => {
  const classes = useStyles();
  const [content, setContent] = useState(null);

  useEffect(() => {
    setContent(
      <p className={classes.tooltiptext}>
        {mobile && (
          <span>
            {data.Country}
            <br />
          </span>
        )}
        Samples: {format(",d")(data.Count)}
        <br />
        Sequenced: {format(".3p")(data.percentSequenced)}
      </p>
    );
  }, [data, mobile, classes]);

  return (
    <g
      className="annotation"
      transform={`translate(${x}, ${y - 20})`}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      className={hovered || first ? classes.show : classes.hide}
    >
      <foreignObject width="200" height="100">
        <div xmlns="http://www.w3.org/1999/xhtml" className={classes.tooltip}>
          {content}
        </div>
      </foreignObject>
    </g>
  );
};

export default Annotation;
