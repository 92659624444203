import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import * as GTM from "util/gtm";
import ManagmentMetrics from "./ManagmentMetrics";
import NotFound from "components/layout/shell/NotFound";
import Home from "../Home";
import FOEArticle from "./FOEArticle";

const FromOurExperts = (props) => {
  useEffect(GTM.clear, []);
  const subPath = props.location.pathname.split("/").slice(2).join("");
  if (subPath === "management-metrics-for-cities-in-the-covid-19-crisis") {
    return (
      <Route
        path="/from-our-experts/management-metrics-for-cities-in-the-covid-19-crisis"
        component={ManagmentMetrics}
      />
    );
  }
  return (
    <Switch>
      <Route path="/from-our-experts/:articleId" component={FOEArticle} />
      <Route path="/404" component={NotFound} />
      <Route path="/" exact component={Home} />
    </Switch>
  );
};

export default FromOurExperts;
