import DonutChart from "components/common/DonutChart";
import EmbedFrame from "components/common/EmbedFrame";
import USStateMap from "components/common/USStateMap";
import GraphContent from "components/layout/GraphContent";
import React from "react";
import styles from "./ContactTracers.module.scss";

const ContactTracers = ({ content_blocks }) => {
  const embedPath = "/embed/contact-tracing-data";
  const graphData = [
    {
      slug: "staff-720",
      renders: (
        <div className={styles.Staff}>
          <EmbedFrame
            src={`${embedPath}/staff_720.html`}
            width={720}
            height={400}
          />
        </div>
      ),
    },
    {
      slug: "staff-cases-720",
      renders: (
        <div className={styles.Staff}>
          <EmbedFrame
            src={`${embedPath}/staff_cases_720.html`}
            width={720}
            height={400}
          />
        </div>
      ),
    },
    {
      slug: "priority-rank",
      renders: (
        <div className={styles.chartFrame}>
          <EmbedFrame
            src={`${embedPath}/priority_rank.html`}
            width={720}
            height={450}
          />
        </div>
      ),
    },
    {
      slug: "num-ct-data",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap
            dataSrc="/datasets/geo/num_ct_data.json"
            width={437}
            height={315}
          />
          <DonutChart
            dataSrc="/datasets/geo/num_ct_data.json"
            width={315}
            height={315}
          />
        </div>
      ),
    },
    {
      slug: "data-ct-data",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap
            dataSrc="/datasets/geo/data_ct_data.json"
            width={437}
            height={315}
          />
          <DonutChart
            dataSrc="/datasets/geo/data_ct_data.json"
            width={315}
            height={315}
          />
        </div>
      ),
    },
    {
      slug: "digital-ct-map-720",
      renders: (
        <div className={styles.mapPie}>
          <USStateMap
            dataSrc="/datasets/geo/digital_ct_data.json"
            width={437}
            height={315}
          />
          <DonutChart
            dataSrc="/datasets/geo/digital_ct_data.json"
            width={315}
            height={315}
          />
        </div>
      ),
    },
  ];

  return (
    <GraphContent
      graphData={graphData}
      content={
        content_blocks["state-survey-results-contact-tracers-review"].content
      }
    />
  );
};

export default ContactTracers;
