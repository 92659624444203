import React, { useContext } from "react";
import Legend from "./Legend";
import { TooltipContext } from "../../Tooltip";
import { useHistory } from "react-router-dom";
import { slugify, states } from "data/geo";

import { select, event } from "d3-selection";
import * as geo from "d3-geo";
import { scaleLinear, scaleQuantize } from "d3-scale";
import { schemeYlGnBu, schemeBlues } from "d3-scale-chromatic";
import { interpolateRgb } from "d3-interpolate";
import { extent } from "d3-array";

import styles from "./VaccineDataMapSVG.module.scss";

const d3 = {
  select,
  event,
  ...geo,
};

const Polygons = ({ svgWidth, margin, innerWidth, innerHeight, dataSet, field, data, mapData, ttDims }) => {
  const history = useHistory();
  const tooltipContext = useContext(TooltipContext);
  const filteredData =
    dataSet.name === "International"
      ? data.filter((d) => !(d.country === "World" || d.country === "European Union"))
      : data.filter((d) => states.includes(d.state));

  const color =
    field.value === "percent_full_vac"
      ? scaleQuantize(schemeYlGnBu[9])
      : scaleLinear().range([schemeBlues[9][1],schemeBlues[9][8]]).interpolate(interpolateRgb)

  color.domain(
    extent(filteredData.filter(d => d.data[field.value] !== "Not Available"), d => d.data[field.value])
  )

  const projection = dataSet.name === "International"
    ? d3.geoNaturalEarth1()
        .translate([innerWidth/2, (innerHeight/2)+80])
        .scale([svgWidth/5])
    : d3.geoAlbersUsa()
        .translate([innerWidth/2, innerHeight/2])
        .scale([innerWidth]);

  const path = d3.geoPath().projection(projection);

  function handleMouseOut(d) {
    tooltipContext.setTooltip({ show: false });
  }

  function handleMouseOver(d) {
    let datapoint = filteredData.find(p => p[dataSet.key] === d.properties.name);
    if (datapoint === undefined && d.properties.name === "United States") {
      datapoint = filteredData.find(p => p[dataSet.key] === "US");
    }
    const [x,y] = path.centroid(d);
    tooltipContext.setTooltip({
      show: true,
      x: x+ttDims.width > innerWidth ? x-(ttDims.width-margin.left-margin.right) : x,
      y: y+ttDims.width > innerHeight ? y-(ttDims.height-margin.top-margin.bottom) : y,
      datakey: dataSet.key,
      datapoint: datapoint,
      field: field
    })
  }

  function handleClick(d) {
    // let datapoint = filteredData.find(p => p[dataSet.key] === d.properties.name);
    // if (datapoint === undefined && d.properties.name === "United States") {
    //   datapoint = filteredData.find(p => p[dataSet.key] === "US");
    // }
    // console.log(slugify(datapoint[dataSet.key]), datapoint[dataSet.key])
    const regionURL = dataSet.key === "state"
      ? `/region/us/${slugify(d.properties.name)}`
      : `/region/${slugify(d.properties.name)}`;
    history.push(regionURL);
  }

  const handleFill = (d) => {
    let datapoint = filteredData.find(p => p[dataSet.key] === d.properties.name);
    if (datapoint === undefined && d.properties.name === "United States") {
      datapoint = filteredData.find(p => p[dataSet.key] === "US");
    }
    if (datapoint === undefined){
        return 'url("#diagonalHatch")';
    } else {
      if (datapoint.data[field.value] === "Not Available") {
        return 'url("#diagonalHatch")';
      } else {
        return color(datapoint.data[field.value])
      }
    }
  }

  return (
    <>
      <g id="polygons">
        {mapData.features.map((d) => {
          const id = d.id
            ? d.id
            : d.properties.iso_n3 + "" + d.properties.adm0_a3;
          return (
            <path
              key={`path${id}`}
              id={id}
              d={path(d)}
              style={{fill: handleFill(d)}}
              onMouseLeave={() => handleMouseOut(d)}
              onMouseOver={() => handleMouseOver(d)}
              onClick={() => handleClick(d)}
              className={styles.path}
            />
          )
        })}
      </g>
      <Legend
        color={color}
        field={field}
        margin={margin}
        width={innerWidth}
        height={innerHeight}
      />
    </>
  )
}

export default Polygons;
