import HFormat from 'components/common/HFormat';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { EventContentHeaderContainer } from '../../EventAtoms';

export const Covid19Symposium_11_20 = ({
  slug = "covid-19-symposium-at-hopkins-navigating-the-pandemic-when-effective-vaccines-are-in-the-policy-toolbox",
  subtitle = "Friday November 20, 2020 8:00-11:30 ET",
  contentSrc = null,
}) => {  
  
  const contentHeader = (eventState = "post") =>  (
    <EventContentHeaderContainer
      eventState={eventState}
      eventRegistry={{
        href: "https://jh.zoom.us/webinar/register/WN_lbTjrSA7RMOo18JAab15mg"
      }}
    >
      <HFormat size={3} noMarginBtm>
        COVID-19 Symposium at Hopkins: 
        <br/>
        Navigating the pandemic when effective vaccines are in the policy toolbox
      </HFormat>
      {!!subtitle && (
        <ReactMarkdown source={subtitle} />
      )}
    </EventContentHeaderContainer>
  );
  return {
    slug,
    contentHeader,
  };
};
