import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styles from "./Author.module.scss";
import HFormat from "components/common/HFormat";

const authorModel = {
    id: PropTypes.any,
    slug: PropTypes.string, 
    name: PropTypes.string,
    title: PropTypes.string, 
    description: PropTypes.string, 
    image: PropTypes.string,
};

export const AuthorTabContainer = ({ title = "Authors", authors = [] }) => (
    <>
        {!!authors.length && (
            <div className={styles.authorsTabContainer}>
                <div className={styles.title}>
                  <span>{title}:</span>
                </div>
                <div className={styles.authorsContainer}>
                  {!!authors.length && (authors.map((author, i) => <AuthorTab key={`author-tab-${i}`} {...author}/>))}
                </div>
            </div>
        )}
    </>
);
AuthorTabContainer.propTypes = {
  title: PropTypes.bool,
  authors: PropTypes.arrayOf(PropTypes.shape(authorModel).isRequired)
};


export const AuthorBlocksContainer = ({  authors = [] }) => (
    <>
      <div className={styles.authorBlocks}>
        {!!authors.length && (authors.map((author, i) => 
          <AuthorBlock key={`author-block-${i}`} {...author}/>)
        )}        
      </div>
    </>
);
AuthorBlocksContainer.propTypes = {
  authors: PropTypes.arrayOf(PropTypes.shape(authorModel).isRequired)
};


export const AuthorTab = ({ id, slug, name, title, image }) => (
  <div key={`author-${slug}-${id}`}className={styles.authorTab}>
    {!!image && (
      <div className={styles.portrait}>
        <div style={{backgroundImage: `url(${image})`}} />
      </div>
    )}
    <span>{`${name}${!!title? `, `+ title : ""}`}</span>
  </div>
);
AuthorTab.propTypes = {
    ...authorModel,
};


export const AuthorBlock = ({ id, slug, name, title, description, image }) => (
  <div key={`author-${slug}-${id}`}className={styles.authorBlockBase}>
    <div className={styles.authorBlockContainer}>
      {!!image && (
        <div className={styles.portrait}>
          <div style={{backgroundImage: `url(${image})`}} />
        </div>
      )}
      <div className={styles.authorDetails}>
        <HFormat size={4} noMargins>{`${name}${!!title? `, `+ title : ""}`}</HFormat>
        {!!description && (
          <ReactMarkdown source={description} />
        )}
      </div>
    </div>
  </div>
);
AuthorBlock.propTypes = {
    ...authorModel
};