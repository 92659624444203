/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import cx from "classnames";

import styles from "./StateGrid.module.scss";
import stateList from "data/data-viz/new-cases-50-states/state-list";
import parse, { domToReact } from "html-react-parser";
import { isMobileSize } from "util/isMobile";
import { findStateCode, getRegionDataNotes } from "data/geo";

import EmbedFrame from "components/common/EmbedFrame";
import Button from "components/common/Button";
import {
  jhucoronavirus_asset_path,
  static_asset_path,
} from "util/hooks/useFetch";
import LoadingVisual from "components/common/LoadingVisual/LoadingVisual";

const StateGrid = ({ stateId }) => {
  const history = useHistory();
  const mainPath = `/data/new-cases-50-states`;
  const toMainGrid = () => {
    history.push(mainPath, {
      noScroll: true,
    });
  };
  const handleChoice = (e) => {
    history.push(`${mainPath}/${e.currentTarget.value}`, {
      noScroll: true,
    });
  };

  const state = stateId || "usa";

  const allStates = state === "usa";
  const hasStatePage = !!findStateCode(state);
  const hasRegionNotes =
    allStates || getRegionDataNotes(findStateCode(state), true)?.length > 0;

  const [isMobile, setIsMobile] = useState(isMobileSize());

  const url = `${jhucoronavirus_asset_path}state_multiples_map_linked.svg`;
  const urlMobile = `${jhucoronavirus_asset_path}state_multiples_map_mobile_linked.svg`;

  const [StatesMapHTML, setStatesMapHTML] = useState(null);
  if (!StatesMapHTML) {
    fetch(url)
      .then((res) => res.text())
      .then((raw) => {
        setStatesMapHTML(parse(raw, parseOptions));
      });
  }
  const [StatesMapMobileHTML, setStatesMapMobileHTML] = useState(null);
  if (!StatesMapMobileHTML) {
    fetch(urlMobile)
      .then((res) => res.text())
      .then((raw) => {
        setStatesMapMobileHTML(parse(raw, parseOptions));
      });
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(isMobileSize());
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Render SVG charts replacing 'a' tags with react-router Links
  const parseOptions = {
    replace: ({ type, name, attribs, children }) => {
      if (
        type === "tag" &&
        name === "a" &&
        attribs.href &&
        attribs.href.startsWith("/")
      ) {
        return (
          <Link
            to={{
              pathname: attribs.href,
              state: { noScroll: true },
            }}
          >
            {domToReact(children, parseOptions)}
          </Link>
        );
      }
    },
  };

  const renderGrid = (state) => {
    if (state !== "usa") {
      const stateName = state
        .split("-")
        .map((part) =>
          part === "of"
            ? part
            : part[0].toUpperCase() + part.slice(1).toLowerCase()
        )
        .join("");

      return (
        <>
          <MediaQuery maxWidth={767}>
            <EmbedFrame
              src={`${static_asset_path}timeline_US_newcases_${stateName}.html`}
              width={352}
              height={380}
            />
          </MediaQuery>
          <MediaQuery minWidth={768}>
            <EmbedFrame
              src={`${static_asset_path}timeline_US_newcases_${stateName}.html`}
              width={600}
              height={500}
            />
          </MediaQuery>
        </>
      );
    } else if (isMobile) {
      return StatesMapMobileHTML ? StatesMapMobileHTML : <LoadingVisual />;
    } else {
      return StatesMapHTML ? StatesMapHTML : <LoadingVisual />;
    }
  };

  return (
    <div className={cx(styles.base, allStates ? styles.baseUSA : null)}>
      <div className={styles.buttonRow}>
        <div>
          <button
            className={allStates ? styles.activeState : styles.inactiveState}
            onClick={toMainGrid}
          >
            All States
          </button>
        </div>
        <div
          className={`${styles.chooser} " " ${
            !allStates ? styles.activeState : styles.inactiveState
          }`}
        >
          <select onChange={handleChoice} value={state}>
            <option value="usa" disabled>
              Choose A State
            </option>
            {Object.keys(stateList).map((s) => (
              <option key={s} value={s}>
                {stateList[s]}
              </option>
            ))}
          </select>
        </div>
        {!!hasStatePage && (
          <Button
            buttonStyle="underlined"
            className={styles.statePageLink}
            icon="right"
            href={`/region/us/${state}`}
          >
            <span>State Profile</span>
          </Button>
        )}
        {!!hasRegionNotes && (
          <Button
            buttonStyle="underlined"
            className={styles.statePageLink}
            icon="right"
            href={`/region-data-notes${allStates ? "" : `/${state}`}`}
          >
            <span>Data Notes</span>
          </Button>
        )}
      </div>
      <div
        id="new-cases-50-states-state-grid-container"
        className={cx(styles.chartContainer, styles[state])}
      >
        {renderGrid(state)}
      </div>
      <div className={styles.snapshotMenu}>
        {Object.keys(stateList).map((s) => (
          <Link key={s} to={`${mainPath}/${s}`}>
            {stateList[s]}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default StateGrid;
