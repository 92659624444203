import React from "react";
import ReactMarkdown from "react-markdown";
import styles from "./GraphContent.module.scss";
import cx from "classnames";

export type TGraphDataProps = {
  slug: string;
  renders: any;
};

export type TGraphContentProps = {
  graphData: TGraphDataProps[];
  content?: string;
  className?: string;
};

const GraphContent = ({
  graphData = [],
  content,
  className,
}: TGraphContentProps) => {
  const Graph = ({ children }) => {
    if (!children?.length) return children;

    return children.map((child, i) => {
      const { value } = child?.props?.children?.[0]?.props;
      const Component = graphData.find((data) => value === data.slug)?.renders;
      if (!Component) return null;
      return (
        <div
          className={styles.graphRenderContainer}
          key={`${value}-graphContainer#${i}`}
        >
          {/* Graph Component Goes Here using this slug: <br /> {value} */}
          {Component}
        </div>
      );
    });
  };
  return (
    <div className={cx(styles.base, className)}>
      <ReactMarkdown
        source={content}
        linkTarget="_blank"
        renderers={{ blockquote: Graph }}
        escapeHtml={false}
      />
    </div>
  );
};

export default GraphContent;
